import {Observable} from 'rxjs';
import {LoadingService} from "./loading.service";
import {GraphQLService} from "./graphql-service";
import {ResultIterator} from "../entities/result_iterator";
import {ApolloQueryResult} from '@apollo/client';
import {catchError, filter, take} from 'rxjs/operators';

export abstract class WabelService {

  constructor(
      protected graphQLService: GraphQLService,
      protected loadingService: LoadingService
  ) {
  }

  /**
   * Get call using GraphQL query.
   * @param graphQLQuery  The GraphQL Query build with gpl tag
   * @param data          The data used as variables parameter
   * @param fetchPolicy   Cache Management
   * @param repeat  Repeating the request after a certain number of time
   * @returns {Observable<T>}
   */
  query(graphQLQuery: any, data: any = null, fetchPolicy: string = 'cache-and-network', repeat?: number): Observable<ApolloQueryResult<any>> {
    this.loadingService.startRequest();
      return this.graphQLService.query(graphQLQuery, data, fetchPolicy)
          .valueChanges.pipe(
              filter(data => data.data !== undefined),
              catchError((error) => {
                  if (error && error.networkError && error.networkError.statusCode && error.networkError.statusCode === 503) {
                      this.graphQLService.backendInMaintenance(true);
                  }
                  throw error;
              })
          );
  }

  /**
   * Insert call using GraphQL mutation.
   * @param graphQLMutation  The GraphQL Query build with gpl tag
   * @param data          The data used as variables parameter
   * @param optimisticResponse The data object to use as an optimistic response
   * @returns {Observable<T>}
   */
  mutation(graphQLMutation: any, data: any = null, optimisticResponse: any = null): Observable<any> {
    this.loadingService.startRequest();
    return this.graphQLService.mutation(graphQLMutation, data, optimisticResponse)
        .pipe(
            take(1),
            catchError((error) => {
                if (error && error.networkError && error.networkError.statusCode && error.networkError.statusCode === 503) {
                    this.graphQLService.backendInMaintenance(true);
                }
                throw error;
            })
        );
  }

  processResponse<T>(data, selector) {
    let response = null;

    if (data.data && data.data[selector]) {
      const result = data.data[selector];
      if (result.items) {
        response = new ResultIterator<T>(result.count ? result.count : result.items.length, this.toObjects(result.items));
      } else if (Array.isArray(result)) {
        response = this.toObjects(result);
      } else {
        response = this.toObject(result);
      }
    }

    this.loadingService.endRequest();

    return response;
  }

  /**
   *
   * Method to transforms the response array of objects into an array of Wabel Entities
   * Uses the toObject method to implement
   * @param $objects
   */
  protected toObjects(objects) {
    return objects.map((object: any) => this.toObject(object));
  }

  /**
   * Method to extend that transforms the response object into the desired Wabel Entity
   * @param $object
   */
  abstract toObject(object);
}
