import {EventEmitter, Injectable, Output} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

import {map} from "rxjs/operators";
import {CompanyProfileSubmitDataType} from '../custom_types';
import {Company} from '../entities/company';
import {CompanyProfileFeedback} from '../entities/company_profile_feedback';
import {CompanyProfileSubmission} from '../entities/company_profile_submission';
import {CompanyProfileSubmissionGraphQL} from '../graphql/company_profile_submission.graphql';
import {GraphQLService} from './graphql-service';
import {LoadingService} from './loading.service';
import {WabelService} from './wabel-service.abstract';
import {FileToUpload} from "../entities/fileToUpload";
import {File} from "../entities/file";


@Injectable({
    providedIn: 'root'
})
export class CompanyProfileSubmissionService extends WabelService {


    // submission steps
    readonly MESSAGE_STEP = 'MESSAGE_STEP';
    readonly EXPORTING_COUNTRIES_STEP = 'EXPORTING_COUNTRIES_STEP';
    readonly CLIENT_PARTNER_STEP = 'CLIENT_PARTNER_STEP';
    readonly CATEGORIES_SELECTION_STEP = 'CATEGORIES_SELECTION_STEP';
    readonly FEATURED_PRODUCTS_STEP = 'FEATURED_PRODUCTS_STEP';
    readonly DOCUMENTS_UPLOAD_STEP = 'DOCUMENTS_UPLOAD_STEP';
    //this variable is only used for draft submissions(old submissions before deleting the credits logic) with step 'CREDIT_DEDUCTION_STEP'
    readonly CREDIT_DEDUCTION_STEP = 'CREDIT_DEDUCTION_STEP';
    readonly PREVIEW_PROFILE_STEP = 'PREVIEW_PROFILE_STEP';
    readonly SUBMISSION_COMPLETED = 'SUBMISSION_COMPLETED';
    public currentStep: BehaviorSubject<string> = new BehaviorSubject<string>(this.CATEGORIES_SELECTION_STEP);
    public doCheckStep = new EventEmitter<string>();
    private isCompany: BehaviorSubject<Company> = new BehaviorSubject<Company>(null);
    private showDiagnosisClientsStep = false;

    constructor(protected graphQLService: GraphQLService, protected loadingService: LoadingService) {
        super(graphQLService, loadingService);
    }

    public getCurrentStep(): Observable<string> {
        return this.currentStep;
    }

    public setInitialStep() {
        this.currentStep.next(this.CATEGORIES_SELECTION_STEP);
    }

    public setShowDiagnosisClientsStep(value: boolean) {
        this.showDiagnosisClientsStep = value;
    }

    public getShowDiagnosisClientsStep() {
        return this.showDiagnosisClientsStep;
    }

    public setCurrentStep(step: string) {
        this.currentStep.next(step);
    }

    public setPreviousStep() {
        switch (this.currentStep.getValue()) {
            case this.MESSAGE_STEP:
                this.setCurrentStep(this.CATEGORIES_SELECTION_STEP);
                break;
            case this.EXPORTING_COUNTRIES_STEP:
                this.setCurrentStep(this.MESSAGE_STEP);
                break;
            case this.CLIENT_PARTNER_STEP:
                this.setCurrentStep(this.EXPORTING_COUNTRIES_STEP);
                break;
            case this.FEATURED_PRODUCTS_STEP:
                this.setCurrentStep(this.CLIENT_PARTNER_STEP);
                break;
            case this.DOCUMENTS_UPLOAD_STEP:
                this.setCurrentStep(this.FEATURED_PRODUCTS_STEP);
                break;
            case this.PREVIEW_PROFILE_STEP:
                this.setCurrentStep(this.DOCUMENTS_UPLOAD_STEP);
                break;
        }
    }

    public openSubmissionModal(value: Company): void {
        this.isCompany.next(value);
    }

    public getSubmissionModal(): Observable<Company> {
        return this.isCompany;
    }

    toArrayOfObjects(objects: any[]): CompanyProfileSubmission[] {
        return objects.map((profile: any) => new CompanyProfileSubmission(profile));
    }

    toStringForDB(val: any): string {
        const data: string = JSON.stringify(val);
        return data;
    }

    toObject(object: any): CompanyProfileSubmission {
        return new CompanyProfileSubmission(object);
    }

    submitCompanyProfile(proposal: CompanyProfileSubmitDataType, eventCode: string = null) {
        return this.mutation(CompanyProfileSubmissionGraphQL.mutations.submitCompanyProfile, {
            reasonTemplate: proposal.reasonMessage,
            reason: proposal.reasonTitle,
            targetCompanyId: proposal.targetCompanyId,
            isExportingToBuyerCountry: proposal.isExportingToBuyerCountry,
            isFeaturedProducts: proposal.isFeaturedProducts,
            providedCategoryIds: Array.from(new Set(proposal.providedCategoryIds)), // make sure values are not duplicated in array
            status: proposal.status,
            providedCategories: proposal.providedCategories,
            featuredSelectedProducts: Array.from(new Set(proposal.featuredSelectedProducts.filter(x => x && x.iduserproduct !== null).map(x => x.iduserproduct))),
            featuredDiagnosisClients: Array.from(new Set(proposal.featuredDiagnosisClients.filter(item => item && item.idcompany !== null).map(item => item.idcompany))),
            step: proposal.step,
            exportingToCountries: proposal.exportingToCountries.map(x => x.tagLocation.code),
            idcompanyprofilesubmission: proposal.idcompanyprofilesubmission,
            companySubmissionFiles: proposal.submissionFiles.map((f: FileToUpload) => f.toGraphQlUploadFile()),
            oldFiles: proposal.oldFiles.map((f: File) => f.idfile),
            eventCode: eventCode
        })
            .pipe(map((data) => data.data && data.data.submitCompanyProfile ? this.toObject(data.data.submitCompanyProfile) : null));
    }

    updateCompanyProfileFeedback(idcompanyprofilesubmission: number, status: string, reason?: string) {
        return this.mutation(CompanyProfileSubmissionGraphQL.mutations.updateCompanyProfileFeedback, {
            idcompanyprofilesubmission,
            status,
            reason
        })
            .pipe(map((data) => data.data && data.data.updateCompanyProfileFeedback && data.data.updateCompanyProfileFeedback ? data.data.updateCompanyProfileFeedback.map(feedback => new CompanyProfileFeedback(feedback)) : []));
    }

    getCompanyProposalById(idcompanyprofilesubmission: number) {
        return this.query(CompanyProfileSubmissionGraphQL.queries.getCompanyProposalById, {idcompanyprofilesubmission})
            .pipe(map((data) => data.data && data.data.companyProposalById ? this.toObject(data.data.companyProposalById) : null));
    }

    getSubmissionsByFeedbackEntry() {
        return this.query(CompanyProfileSubmissionGraphQL.queries.getSubmissionsByFeedbackEntry, {}, 'no-cache')
            .pipe(map((data) => data.data && data.data.submissionsByFeedbackEntry && data.data.submissionsByFeedbackEntry ? this.toArrayOfObjects(data.data.submissionsByFeedbackEntry) : []));
    }

    deleteCompanyProfileSubmission(submisson: CompanyProfileSubmission) {
        return this.mutation(CompanyProfileSubmissionGraphQL.mutations.deleteCompanyProfileSubmission, {
            idcompanyprofilesubmission: +submisson.idcompanyprofilesubmission
        })
            .pipe(map((data) => data.data && data.data.deleteCompanyProfileSubmission ? true : false));
    }

}
