import {Injectable} from "@angular/core";
import {WabelService} from "./wabel-service.abstract";
import {Member} from "../entities/member";
import {PrivateMessage} from "../entities/private_message";
import {PrivateMessageThread} from "../entities/private_message_thread";
import {MessageGraphQL} from "../graphql/message.graphql";
import {GraphQLService} from "./graphql-service";
import {LoadingService} from "./loading.service";
import {Company} from "../entities/company";
import {Product} from "../entities/product";
import {count, map} from "rxjs/operators";
import {FileToUpload} from "../entities/fileToUpload";
import { userDefinedDataType } from "../custom_types";
import { VideoMeetingInvitations } from "../entities/video_meeting_invitations";
import { VideoMeetingInvitationGraphQL } from "../graphql/video_meeting_invitation.graphql";

@Injectable({
    providedIn: 'root'
})
export class MessageService extends WabelService {
    constructor(graphqlService: GraphQLService, loadingService: LoadingService) {
        super(graphqlService, loadingService);
    }

    toArrayOfObjects(objects: any[]): PrivateMessage[] {
        return objects.map((privateMessage: any) => new PrivateMessage(privateMessage));
    }
    threadsToArrayOfObjects(objects: any[]): PrivateMessageThread[] {
        return objects.map((privateMessageThread: any) => new PrivateMessageThread(privateMessageThread));
    }
    toObject(privateMessage: any): PrivateMessage {
        return new PrivateMessage(privateMessage);
    }

    getMyMessageThreads(limit: number = 20, offset: number = 0, fetchPolicy: string = 'network-only') {
        return this.query(MessageGraphQL.queries.myMessageThreads, {limit: limit, offset: offset}, fetchPolicy)
            .pipe(map((data) => ({
                items: data.data && data.data.me && data.data.me.privateMessageThreads && data.data.me.privateMessageThreads.items && data.data.me.privateMessageThreads.items.length > 0 ? this.threadsToArrayOfObjects(data.data.me.privateMessageThreads.items) : [],
                count: data.data && data.data.me && data.data.me.privateMessageThreads && data.data.me.privateMessageThreads.count ? data.data.me.privateMessageThreads.count : 0
            })));
    }

    updateStatusVideoMeetingInvitation( idvideomeetinginvitation: number , status: string) {
        return this.query( VideoMeetingInvitationGraphQL.mutations.updateStatusVideoMeetingInvitation, {idvideomeetinginvitation , status})
            .pipe(map((data) => data.data && data.data.updateStatusVideoMeetingInvitation ? new VideoMeetingInvitations(data.data.updateStatusVideoMeetingInvitation) :  null ));
    }

    convertToString( data: userDefinedDataType ){
        let converted: string = JSON.stringify( data )
        return converted
    }

    sendVideoMeetingInvitationMessage( userDefinedData: userDefinedDataType , threadId?: number, toCompanyId?: number, toMemberId?: number ){
        return this.mutation(MessageGraphQL.mutations.sendVideoMeetingInvitationMessage, {
            invitation_data: this.convertToString( userDefinedData ),
            threadId,
            toCompanyId,
            toMemberId
        })
            .pipe(map((data) => data.data ? data.data.sendVideoMeetingInvitationMessage : null));
    }

    replyToPrivateMessageThread(threadId: number, message: string, messageFiles: FileToUpload[] = [], eventCode: string = null) {
        return this.mutation(MessageGraphQL.mutations.replyToPrivateMessageThread, {
            threadId: threadId,
            message: message,
            messageFiles: messageFiles.map((f: FileToUpload) => f.toGraphQlUploadFile()),
            eventCode: eventCode
        })
            .pipe(map((data) => data.data ? new PrivateMessage(data.data.replyToPrivateMessageThread) : null));
    }

    markThreadAsRead(threadId: number) {
        return this.mutation(MessageGraphQL.mutations.markThreadAsRead, {
            threadId: threadId
        })
            .pipe(map((data) => data.data ? new Member(data.data.markThreadAsRead) : null));
    }

    sendPrivateMessageToCompany(companyIds: number[], subject: string, message: string, eventCode: string = null) {
        return this.mutation(MessageGraphQL.mutations.sendPrivateMessageToCompany, {
            companyIds: companyIds,
            subject: subject ? subject : '',
            message: message,
            eventCode: eventCode
        })
            .pipe(map((data) => data.data && data.data.sendPrivateMessageToCompany && data.data.sendPrivateMessageToCompany.length > 0 ? data.data.sendPrivateMessageToCompany.map((privateMessageThread: any) => new PrivateMessageThread(privateMessageThread)) : []));
    }

    askMoreInformationAboutProducts(products: Product[], message: string) {
        return this.mutation(MessageGraphQL.mutations.askMoreInformationAboutProducts, {
            productIds: products.map((p: Product) => p.id),
            content: message
        });
    }

    getPrivateMessagesByThreadAndMessageRange(threadId: number, limit: number = 10, messageId: number = null, operatorType: string = 'lt', fetchPolicy: string = 'network-only') {
        return this.query(MessageGraphQL.queries.messagesByThreadAndMessageRange, {
            threadId: threadId,
            limit: limit,
            messageId: messageId,
            operatorType: operatorType
        }, fetchPolicy)
            .pipe(map((data) => data.data && data.data.privateMessagesByThreadAndMessageRange && data.data.privateMessagesByThreadAndMessageRange.items && data.data.privateMessagesByThreadAndMessageRange.items.length > 0 ? data.data.privateMessagesByThreadAndMessageRange.items.map((privateMessage: any) => new PrivateMessage(privateMessage)) : []));
    }

    getThreadById(threadId: number, messagesLimit: number = 10, messageId: number = null, operatorType: string = 'lt', fetchPolicy: string = 'network-only') {
        return this.query(MessageGraphQL.queries.threadById, {
            threadId: threadId,
            messagesLimit: messagesLimit,
            messageId: messageId,
            operatorType: operatorType
        }, fetchPolicy)
            .pipe(map((data) => data.data && data.data.threadById ? new PrivateMessageThread(data.data.threadById) : null));
    }
}
