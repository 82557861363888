import {WabelEntity} from "./wabel-entity.abstract";
import {Member} from "./member";
import {PackagingUnit} from "./packaging_unit";
import {Tag} from "./tag";
import {SourcingRequestRecommendation} from "./sourcing_request_recommendation";
import {SourcingRequestMemberResponse} from "./sourcing_request_member_response";
import {SourcingRequestBlacklistedCompany} from "./sourcing_request_blacklisted_company";
import {SourcingRequestKeyword} from "./sourcing_request_keyword";
import {File} from "./file";

export class SourcingRequest extends WabelEntity {
    constructor(obj: any) {
        super(obj);

        if (obj) {
            this.memberBuyer = obj.memberBuyer ? new Member(obj.memberBuyer) : null;
            this.packagingUnit = obj.packagingUnit ? new PackagingUnit(obj.packagingUnit) : null;
            this.activities = obj.activities && obj.activities.items ? obj.activities.items.map(activity => new Tag(activity)) : [];
            this.pricePositioning = obj.pricePositioning && obj.pricePositioning.items ? obj.pricePositioning.items.map(pricePositioning => new Tag(pricePositioning)) : [];
            this.categories = obj.categories && obj.categories.items ? obj.categories.items.map(cat => new Tag(cat)) : [];
            this.distributionCountries = obj.distributionCountries && obj.distributionCountries.items ? obj.distributionCountries.items.map(country => new Tag(country)) : [];
            this.preferredSupplierCountries = obj.preferredSupplierCountries && obj.preferredSupplierCountries.items ? obj.preferredSupplierCountries.items.map(country => new Tag(country)) : [];
            this.sourcingRequestRecommendationByCompanyId = obj.sourcingRequestRecommendationByCompanyId ? new SourcingRequestRecommendation(obj.sourcingRequestRecommendationByCompanyId) : null;
            this.sourcingRequestMemberResponseByMemberId = obj.sourcingRequestMemberResponseByMemberId ? new SourcingRequestMemberResponse(obj.sourcingRequestMemberResponseByMemberId) : null;
            this.sourcingRequestRecommendations = obj.sourcingRequestRecommendations && obj.sourcingRequestRecommendations.items ? obj.sourcingRequestRecommendations.items.map(sourcingRequestRecommendation => new SourcingRequestRecommendation(sourcingRequestRecommendation)) : [];
            this.interestedSourcingRequestRecommendations = obj.interestedSourcingRequestRecommendations && obj.interestedSourcingRequestRecommendations.items ? obj.interestedSourcingRequestRecommendations.items.map(sourcingRequestRecommendation => new SourcingRequestRecommendation(sourcingRequestRecommendation)) : [];
            this.interestedSourcingRequestRecommendationsCount = obj.interestedSourcingRequestRecommendations ? obj.interestedSourcingRequestRecommendations.count : null;
            this.blacklistedCompanies = obj.blacklistedCompanies && obj.blacklistedCompanies.items ? obj.blacklistedCompanies.items.map(comp => new SourcingRequestBlacklistedCompany(comp)) : [];
            this.keywords = obj.keywords && obj.keywords.items ? obj.keywords.items.map(keyword => new SourcingRequestKeyword(keyword)) : [];
            this.productAttributes = obj.productAttributes && obj.productAttributes.items ? obj.productAttributes.items.map(pa => new Tag(pa)) : [];
            this.volumeTags = obj.volumeTags && obj.volumeTags.items ? obj.volumeTags.items.map(v => new Tag(v)) : [];
            this.certificationsAndLabels = obj.certificationsAndLabels && obj.certificationsAndLabels.items ? obj.certificationsAndLabels.items.map(cl => new Tag(cl)) : [];
            this.file = obj.file ? new File(obj.file) : null;
        }
    }

    id: number;
    name: string;
    memberBuyer: Member;
    createdDate: string;
    status: string;
    productSpec: string;
    volume: string;
    openToOthersLocations: boolean;
    packageWidth: number;
    packageHeight: number;
    packageLength: number;
    expiryDate: string;
    reviewEndDate: string;
    reviewStartDate: string;
    packagingValue: string;
    packagingUnit: PackagingUnit;
    activities: Tag[];
    categories: Tag[];
    pricePositioning: Tag[];
    certifications: Tag[];
    productAttributes: Tag[];
    certificationsAndLabels: Tag[];
    distributionChannels: string;
    distributionCountries: Tag[];
    preferredSupplierCountries: Tag[];
    sourcingRequestRecommendationByCompanyId: SourcingRequestRecommendation;
    sourcingRequestMemberResponseByMemberId: SourcingRequestMemberResponse;
    sourcingRequestRecommendations: SourcingRequestRecommendation[];
    interestedSourcingRequestRecommendations: SourcingRequestRecommendation[];
    keywords: SourcingRequestKeyword[];
    daysLeft: number;
    hoursLeft: number;
    blacklistedCompanies: SourcingRequestBlacklistedCompany[];
    purchasingPriceRange: string;
    volumeTags: Tag[];
    recommendationsToReviewCount: number;
    interestedSourcingRequestRecommendationsCount: number;
    isExpired: boolean;
    file: File;

    getReadableStatus(): string {
        switch (this.status) {
            case null:
                return null;
            case 'not_sent':
                return 'not sent';
            case 'not_validated':
                return 'to validate';
            case 'cancelled':
                return 'cancelled';
            case 'on':
                return 'on';
            case 'finished':
                return 'finished';
            default:
                return 'finished';
        }
    }

    getStatusColor(): string {
        switch (this.status) {
            case 'not_sent':
                return 'blue';
            case 'not_validated':
                return 'orange';
            case 'cancelled':
                return 'red';
            case 'on':
                return 'green';
            case 'finished':
                return 'grey';
            default:
                return 'grey';
        }
    }
}
