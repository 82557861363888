import {Injectable} from '@angular/core';
import {FavoriteGraphql} from "../graphql/favorite.graphql";
import {Favorites} from "../entities/favorites";
import {WabelService} from "./wabel-service.abstract";
import {GraphQLService} from "./graphql-service";
import {LoadingService} from "./loading.service";
import {AuthService} from "../../auth.service";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class FavoriteService extends WabelService {


    constructor(graphqlService: GraphQLService, loadingService: LoadingService, private authService: AuthService) {
        super(graphqlService, loadingService);
    }

    toObject(object: any): Favorites {
        return new Favorites(object);
    }

    toggleFavoriteCompany(idcompany: number) {
        return this.mutation(FavoriteGraphql.mutations.toggleFavoriteCompany, {idcompany: idcompany}).pipe(map((data) => {
            return data && data.data && data.data.toggleFavoriteCompany && data.data.toggleFavoriteCompany.items ? data.data.toggleFavoriteCompany.items.map(f => new Favorites(f)) : []
        }));
    }

    toggleFavoriteProduct(idproduct: number) {
        return this.mutation(FavoriteGraphql.mutations.toggleFavoriteProduct, {idproduct: idproduct}).pipe(map((data) => {
            return data && data.data && data.data.toggleFavoriteProduct && data.data.toggleFavoriteProduct.items ? data.data.toggleFavoriteProduct.items.map(f => new Favorites(f)) : []
        }));
    }

}
