import {Component, ContentChildren, HostBinding, Input, QueryList} from '@angular/core';
import {NdlInputBaseDirective} from '../input-base.directive';
import {NdlOptionDirective} from '../option.directive';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {NdlOptionGroupDirective} from '../optiongroup.directive';


@Component({
    selector: 'ndl-checkboxes-new',
    templateUrl: './checkboxes.component.html',
    styleUrls: ['./checkboxes.component.scss'],
    host: {
        'class': 'ndl-checkboxes'
    }
})
export class NdlCheckboxesNewComponent extends NdlInputBaseDirective {
    override defaultValue = [];

    @Input() identifier: string = null;
    @Input() maxHeight: string = null;
    @Input() height: string = null;

    @HostBinding('class.ndl-checkboxes-vertical')
    @Input() get vertical(): boolean {
        return this.groups.length > 0 ? true : this._vertical;
    }

    set vertical(vertical: boolean) {
        this._vertical = coerceBooleanProperty(vertical);
    }

    private _vertical = false;


    @Input() get searchable(): boolean {
        return this._searchable;
    }

    set searchable(searchable: boolean) {
        this._searchable = coerceBooleanProperty(searchable);
    }

    private _searchable = false;


    @Input() get displayMode(): boolean {
        return this._displayMode;
    }

    set displayMode(displayMode: boolean) {
        this._displayMode = coerceBooleanProperty(displayMode);
    }

    private _displayMode = false;

    @ContentChildren(NdlOptionDirective) options: QueryList<NdlOptionDirective>;
    @ContentChildren(NdlOptionGroupDirective) groups: QueryList<NdlOptionGroupDirective>;
}
