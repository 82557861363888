import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NdlXyChartComponent} from './xy-chart.component';
import {NdlGaugeChartComponent} from './gauge-chart.component';
import {NdlPieChartComponent} from './pie-chart.component';
import {NdlLegendChartComponent} from "./legend-chart/legend-chart.component";
import {MapChartComponent} from "./map-chart.component";

@NgModule({
    declarations: [
        NdlXyChartComponent,
        NdlGaugeChartComponent,
        NdlPieChartComponent,
        NdlLegendChartComponent,
        MapChartComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        NdlXyChartComponent,
        NdlGaugeChartComponent,
        NdlPieChartComponent,
        NdlLegendChartComponent,
        MapChartComponent
    ]
})
export class NeedlChartsModule { }
