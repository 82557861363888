import {Component, ElementRef, EventEmitter, Input, OnDestroy, Optional, Output, QueryList, Self} from '@angular/core';
import {MatFormFieldControl} from '@angular/material/form-field';
import {NdlOptionDirective} from '../option.directive';
import {NdlInputFieldBaseDirective} from '../input-field-base.directive';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as UI from '@ckeditor/ckeditor5-ui';


@Component({
    selector: 'ndl-wysiwyg-field',
    templateUrl: './wysiwyg-field.component.html',
    styleUrls: ['./wysiwyg-field.component.scss'],
    providers: [
        {provide: MatFormFieldControl, useExisting: NdlWysiwygFieldComponent}
    ],
    host: {
        id: `ndl-wysiwyg-field-${NdlWysiwygFieldComponent.nextId}`
    }
})
export class NdlWysiwygFieldComponent extends NdlInputFieldBaseDirective<string> {
    static nextId = 0;
    public editor = ClassicEditor;

    controlType = "ndl-wysiwyg-field";
    id = "ndl-wysiwyg-field-" + NdlWysiwygFieldComponent.nextId++;

    @Input('aria-describedby') userAriaDescribedBy: string;
    @Input() config: any;

    _listOfOptions = '';

    public onReady(editor) {
        // Uncomment to display options
        // this._listOfOptions = Array.from( editor.ui.componentFactory.names() ).join(', ');
    }

    setDescribedByIds(ids: string[]) {
        const controlElement = this._elementRef.nativeElement
            .querySelector('.ndl-wysiwyg-field');
        controlElement.setAttribute('aria-describedby', ids.join(' '));
    }

    onContainerClick(event: MouseEvent) {
    }
}
