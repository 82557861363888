import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'arrayToCommaSeparatedValues'
})
export class ArrayToCommaSeparatedValuesPipe implements PipeTransform {

    transform(array: any[], propertyToDisplay?: string, truncate?: number, callback?: any, callbackExtraParameter?: any): string {
        let result = '';
        let truncated = 0;

        if (truncate) {
            truncated = array.slice(truncate).length;
            array = array.slice(0, truncate);
        }

        if (propertyToDisplay) {
            result = array.map(i => i[propertyToDisplay] ? i[propertyToDisplay] : '-').join(', ');
        } else if (callback && typeof callback === 'function') {
            if (callbackExtraParameter) {
                result = array.map(i => callback(i, callbackExtraParameter)).join(', ');
            } else {
                result = array.map(i => callback(i)).join(', ');
            }
        } else {
            result = array.join(', ');
        }

        return result + (truncated ? ', +' + truncated : '');
    }

}
