<div class="container-fluid">
    <div class="row">
        <div class="col" style="height: 300px">
            <ndl-xy-chart [data]="xyData" [x]="XAxis" [y]="YAxis" [dataConfigs]="dataXYConfigs"></ndl-xy-chart>
        </div>
        <div class="col" style="height: 300px">
            <ndl-gauge-chart [value]="8"></ndl-gauge-chart>
        </div>
        <div class="col" style="height: 300px">
            <ndl-pie-chart [data]="pieData" valueParam="litres" categoryParam="country"></ndl-pie-chart>
        </div>
    </div>
    <div class="row">
        <div class="col" style="height: 300px">
            <ndl-map-chart [data]="mapData" [legend]="mapLegend"></ndl-map-chart>
        </div>
    </div>
</div>


<ndl-legend-chart #mapLegend></ndl-legend-chart>
