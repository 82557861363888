import { WabelEntity } from "./wabel-entity.abstract";
import { TagType } from "./tag_type";
import {TagLocation} from "./tag_location";

export class Tag extends WabelEntity {
    constructor(obj: any) {
        super(obj);
        if (obj) {
            if (obj.type) {
                this.tagType = obj.type ? new TagType(obj.type) : null;
            } else if (obj.tagType) {
                this.tagType = new TagType(obj.tagType);
            }
            this.parent = obj.parent ? new Tag(obj.parent) : null;
            if (obj.children) {
                if (obj.children.items) {
                    this.children = obj.children.items.map(t => new Tag(t));
                } else {
                    this.children = obj.children.map(t => new Tag(t));
                }
            }
            this.tagLocation = obj.tagLocation ? new TagLocation(obj.tagLocation) : null;
        }
    }

    id: number;
    name: string;
    tagType: TagType;
    parent: Tag;
    children: Tag[] = [];
    machineName: string;
    internalName: string;
    tagLocation: TagLocation;
    depth: number;

    rootTag() {
        let rootTag: Tag = this;
        while (rootTag.parent) {
            rootTag = rootTag.parent;
        }
        return rootTag;
    }
}
