import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Member} from "../../services/wabel-client/entities/member";
import {Subject} from "rxjs";
import {Company} from "../../services/wabel-client/entities/company";
import {KitFormSelectComponent} from "../../modules/shared-module/kit-form/kit-form-select/kit-form-select.component";
import {Router} from "@angular/router";
import {UtilsService} from "../../services/utils.service";
import {Tag} from "../../services/wabel-client/entities/tag";
import {TemplateService} from "../../services/template.service";

@Component({
    selector: 'signup-form',
    templateUrl: './signup-form.component.html',
    styleUrls: ['./signup-form.component.scss']
})
export class SignupFormComponent implements OnInit {

    @Input() step: number = 1;
    showBuyerScopePopup: boolean = true;
    showError: boolean = false;
    showErrorDescription: boolean = false;
    showErrorAddress: boolean = false;
    errorMessageAddress: any = {requiredError: 'This field is required', customError: null};
    showErrorSpecializations: boolean = false;
    showErrorTypeOfCompanyOther: boolean = false;
    showErrorLogo: boolean = false;
    showErrorCategories: boolean = false;
    showErrorActivities: boolean = false;
    showErrorEmail: boolean = false;
    showErrorWebsite: boolean = false;
    showErrorPhoneMember: boolean = false;
    showErrorPhoneCompany: boolean = false;
    doCheck1: boolean = false;
    doCheck2: boolean = false;
    doCheck3: boolean = false;
    isEmail: boolean = true;
    isUrl: boolean = true;
    isPhoneMember: boolean = true;
    isPhoneCompany: boolean = true;
    selectCompanyClicked: boolean = false;
    memberEmail: string = null;

    @Input() member: Member;
    @Input() companyListOptions: Company[] = [];
    @Output() selectedCompanyFromOption: EventEmitter<any> = new EventEmitter();
    @Input() companyInputSearch = new Subject<string>();
    @Input() companyLoading: boolean = false;
    @Input() defaultSelectedNoOption: Company;
    @Output() changeCompanyAddress: EventEmitter<any> = new EventEmitter();
    @Input() signUpType: string;
    @Output() stepValidate: EventEmitter<validStepValue> = new EventEmitter();
    @Output() changePhoneMember: EventEmitter<any> = new EventEmitter();
    @Output() changePhoneCompany: EventEmitter<any> = new EventEmitter();
    @Output() changeTypeOfCompanyOther: EventEmitter<any> = new EventEmitter();


    @Input() companyCategories: number[];
    @Input() categoriesOptions: Tag[];
    @Input() companyActivities: number[];
    @Input() activitiesOptions: Tag[];
    @Input() companySpecializations: number[];
    @Input() specializationOptions: Tag[];
    @Input() asynchronousButtonState: string = 'before';
    @Input() asynchronousButtonState2: string = 'before';
    @Input() asynchronousButtonState3: string = 'before';
    @Input() errorAfterSubmit: string = null;
    @Input() errorAfterSubmit2: string = null;
    @Input() errorAfterSubmit3: string = null;
    @Input() typeOfCompanyOther: string = null;
    @Input() inEventUniverse: boolean = false;


    password: string;
    passwordConf: string;

    passwordDiff: boolean = false;

    button1AlreadySubmitted: boolean = false;
    button2AlreadySubmitted: boolean = false;
    button3AlreadySubmitted: boolean = false;

    isPassword1Good: boolean = false;
    isPassword2Good: boolean = false;

    isAddressValid: boolean = false;
    companySearchInputDisabled: boolean = false;
    @ViewChild(KitFormSelectComponent, { static: true }) kitFormSelectComponent: KitFormSelectComponent;
    @ViewChild('selectCompanyContainer', { static: true }) selectCompanyContainer: ElementRef;
    defaultSelectedCompanyForManualRegMembers: Company = null;

    constructor(private router: Router, public utilsService: UtilsService, public templateService: TemplateService) {
    }

    ngOnInit() {
        if (this.signUpType == 'co-packer') {
            this.companySpecializations = [12];
        }
        if (this.member.company.idcompany && this.member.company.idcompany != -1) {
            this.defaultSelectedCompanyForManualRegMembers = this.member.company;
            this.companySearchInputDisabled = true;
        }

    }


    gotoPrevStep() {
        this.doCheck1 = false;
        this.doCheck2 = false;
        this.doCheck3 = false;
        window.scrollTo(0, 0);

        //If we have a company creation we have step2, if not, we don't have step 2 so directly step 1.
        if (this.step == 3) {
            if (this.member.company.idcompany === null) {
                this.step = 2;
            } else {
                this.step = 1;
            }
        } else {
            this.step--;
        }

        this.showError = false;
    }

    gotoNextStep() {
        this.errorAfterSubmit2 = null;
        switch (this.step) {
            case 1:
                this.button1AlreadySubmitted = true;
                if (!this.member.company.phone) {
                    this.member.company.phone = this.member.mobile;
                }
                break;
            case 2:
                this.button2AlreadySubmitted = true;
                break;
            case 3:
                this.button3AlreadySubmitted = true;
                break;
        }
        this.checkFormErrors();
        if (this.filesUploading()) {
            this.asynchronousButtonState2 = 'before';
            this.errorAfterSubmit2 = 'Some files are still uploading, please wait until complete upload';
            return;
        }
        if (this.showError) return;
        if (!this.showError && this.step === 3 && this.asynchronousButtonState3 === 'before') {
            let validStep = {step: 3, value: this.password};
            this.stepValidate.emit(validStep);
        }
        if (!this.showError && this.step === 2 && this.asynchronousButtonState2 === 'before') {
            let validStep = {step: 2, value: null};
            this.stepValidate.emit(validStep);
        }
        this.doCheck1 = false;
        this.doCheck2 = false;
        this.doCheck3 = false;

        if (this.step == 1 && this.asynchronousButtonState === 'before') {
            this.memberEmail = this.member.email;
            //if the company is already created, we skip the part 2 to go part 3 directly
            if (this.member.company.idcompany === null || this.member.company.idcompany === -1) {
                this.errorAfterSubmit = null;
                this.errorAfterSubmit2 = null;
                let validStep = {step: 1, value: null};
                this.stepValidate.emit(validStep);
            } else {
                let validStep = {step: null, value: 'jump'};
                this.stepValidate.emit(validStep);
            }
        }
    }


    checkFormErrors() {
        this.showError = false;
        if (this.step == 1 && this.button1AlreadySubmitted) {
            this.showError = !this.member.firstname || !this.member.name || !this.member.company.idcompany || !this.member.jobtitle;

            if (!this.member.email || this.member.email === '' || !this.isEmail) {
                this.showErrorEmail = true;
                this.showError = true;
            } else {
                this.showErrorEmail = false;
            }

            if (!this.member.mobile || this.member.mobile === '' || !this.isPhoneMember) {
                this.showErrorPhoneMember = true;
                this.showError = true;
            } else {
                this.showErrorPhoneMember = false;
            }

            if (this.showError) {
                this.doCheck1 = true;
            } else {
                this.doCheck1 = false;
            }
        }

        if (this.step == 2 && this.button2AlreadySubmitted) {
            this.showError = !this.member.company.name;
            if (!this.member.company.website || this.member.company.website === '' || !this.isUrl) {
                this.showErrorWebsite = true;
                this.showError = true;
            } else {
                this.showErrorWebsite = false;
            }

            if (this.signUpType === 'buyer') {
                if (!this.companySpecializations || this.companySpecializations.length === 0) {
                    this.showError = true;
                    this.showErrorSpecializations = true;
                } else {
                    this.showErrorSpecializations = false;
                }
            }

            if (this.signUpType === 'manufacturer') {
                if (!this.companyCategories || this.companyCategories.length === 0) {
                    this.showError = true;
                    this.showErrorCategories = true;
                } else {
                    this.showErrorCategories = false;
                }

                if (!this.companyActivities || this.companyActivities.length === 0) {
                    this.showError = true;
                    this.showErrorActivities = true;
                } else {
                    this.showErrorActivities = false;
                }
            }

            if (this.signUpType === 'other') {
                if (this.typeOfCompanyOther === '') {
                    this.showError = true;
                    this.showErrorTypeOfCompanyOther = true;
                } else {
                    this.showErrorTypeOfCompanyOther = false;
                }
            }

            if (!this.isAddressValid) {
                this.showError = true;
                this.showErrorAddress = true;
            } else {
                this.showErrorAddress = false;
            }

            if (!this.member.company.phone || this.member.company.phone === '' || !this.isPhoneCompany) {
                this.showErrorPhoneCompany = true;
                this.showError = true;
            } else {
                this.showErrorPhoneCompany = false;
            }

            if (this.showError) {
                this.doCheck2 = true;
            } else {
                this.doCheck2 = false;
            }
        }

        if (this.step == 3 && this.button3AlreadySubmitted) {
            if (this.isPassword1Good && this.isPassword2Good) {
                this.showError = false;
            } else {
                this.showError = true;
            }
        }
    }

    validatorEmail(value) {
        this.isEmail = value;
        this.checkFormErrors();
    }

    validatorUrl(value) {
        this.isUrl = value;
        this.checkFormErrors();
    }

    validatorPhoneMember(value) {
        this.isPhoneMember = value;
        this.checkFormErrors();
    }

    validatorPhoneCompany(value) {
        this.isPhoneCompany = value;
        this.checkFormErrors();
    }

    onDocumentClick(event) {
        if (!this.selectCompanyContainer || !this.selectCompanyContainer.nativeElement.contains(event.target)) {
            this.kitFormSelectComponent?.closeOtherFooter();
        }
    }

    private filesUploading(): boolean {
        if (this.member.company) {
            if (this.member.company.newCompanyLogo && this.member.company.newCompanyLogo.length) {
                for (let i = 0; i < this.member.company.newCompanyLogo.length; i++) {
                    if (this.member.company.newCompanyLogo[i].isUploading()) {
                        return true;
                    }
                }
            }
            if (this.member.company.newCompanyFiles && this.member.company.newCompanyFiles.length) {
                for (let i = 0; i < this.member.company.newCompanyFiles.length; i++) {
                    if (this.member.company.newCompanyFiles[i].isUploading()) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    validatorPassword1(value) {
        this.isPassword1Good = value;

        if (this.password !== this.passwordConf) {
            this.isPassword2Good = false;
        }

        this.checkFormErrors();
    }

    validatorPassword2(value) {
        this.isPassword2Good = value;
        this.checkFormErrors();
    }

    validatorAddress(value) {
        this.isAddressValid = value;
    }

    closeBuyerScopePopup(event) {
        this.showBuyerScopePopup = false;
    }
}

interface validStepValue {
    step: number;
    value: string;
}

