import {WabelEntity} from "./wabel-entity.abstract";
import {Member} from "./member";
import {Meeting} from "./meeting";
import {PrivateMessageThread} from "./private_message_thread";

export class MeetingRating extends WabelEntity {
    constructor(obj: any) {
        super(obj);

        if (obj) {
            this.member = obj.member ? new Member(obj.member) : null;
            this.meeting = obj.meeting ? new Meeting(obj.meeting) : null;
            this.threadBetweenCompanySupplierAndMemberBuyer = obj.threadBetweenCompanySupplierAndMemberBuyer ? new PrivateMessageThread(obj.threadBetweenCompanySupplierAndMemberBuyer) : null;
        }
    }

    idmeetingrating: number;
    member: Member;
    meeting: Meeting;
    ratingInternalName: string;
    rating: number;
    dateCreated: string;
    dateUpdated: string;
    threadBetweenCompanySupplierAndMemberBuyer: PrivateMessageThread;

    get id(): number {
        return this.idmeetingrating;
    }

    set id(id: number) {
        this.idmeetingrating = id;
    }
}

export const VIDEO_CALL_QUALITY_MEETING_RATING_INTERNAL_NAME = 'video_call_quality';
export const WANT_TO_FOLLOW_UP_MEETING_RATING_INTERNAL_NAME = 'want_to_follow_up';
export const PRODUCT_MATCH_CURRENT_NEEDS_MEETING_RATING_INTERNAL_NAME = 'products_match_current_needs';
export const PRODUCTS_ARE_APPEALING_MEETING_RATING_INTERNAL_NAME = 'products_are_appealing';
export const BUYER_HAD_TANGIBLE_PROJECT_MEETING_RATING_INTERNAL_NAME = 'buyer_had_tangible_purchasing_projects_for_my_category';
export const BUYER_TYPE_MATCH_MEETING_RATING_INTERNAL_NAME = 'buyer_type_match_target_distribution_channels';
export const TIMELINE_TO_FOLLOW_UP_INTERNAL_NAME = 'timeline_to_follow_up';
export const BUYER_SEEMS_INTERESTED_BY_MY_PRODUCTS_INTERNAL_NAME = 'buyer_seems_interested_by_my_products';
export const WANT_SAMPLE_REQUESTS_MEETING_RATING_INTERNAL_NAME = 'want_sample_requests';
