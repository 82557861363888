import {WabelEntity} from "./wabel-entity.abstract";
import {Product} from "./product";
import {Company} from "./company";

export class Favorites extends WabelEntity {

    idfavorite: number;
    userproduct: Product;
    company: Company;

    constructor(obj: any) {
        super(obj);
        if(obj) {
            this.idfavorite = obj.idfavorite;
            this.userproduct = obj.userproduct ? new Product(obj.userproduct) : null;
            this.company = obj.company ? new Company(obj.company) : null;
        }
    }



    get id(): number {
        return this.idfavorite;
    }

    set id(id: number) {
        this.idfavorite = id;
    }


}
