import {Directive, Input, TemplateRef} from '@angular/core';
import {coerceBooleanProperty} from "@angular/cdk/coercion";

@Directive({
    selector: '[ndlLazyLoadedTab]',
    host: {
        class: 'ndl-lazy-loaded-tab'
    },
})
export class NdlLazyLoadedTabDirective {

    @Input('ndlLazyLoadedTab') label: string;

    constructor(public content: TemplateRef<any>) {
    }
}
