import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ndl-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss'],
    host: {
      "class": "ndl-demo"
    }
})
export class NdlDemoComponent {
}
