<div class="date-picker">
    <div class="date-picker-input" [class.opened]="datePickerIsOpen" [class.error]="showError">
        <mat-datepicker-toggle [for]="matDatepicker" *ngIf="prefixIcon">
            <span class="material-icons" matDatepickerToggleIcon *ngIf="prefixIcon !== 'default'">{{prefixIcon}}</span>
        </mat-datepicker-toggle>
        <input [matDatepicker]="matDatepicker" class="date-picker-input-value" [min]="minDate" [max]="maxDate" placeholder="{{datePickerPlaceholder}}" (dateChange)="dateChange($event)" [(ngModel)]="selectedDate" (click)="matDatepicker.open()" [class.font-weight-bold]="!selectedDate">
        <mat-datepicker-toggle [for]="matDatepicker" *ngIf="suffixIcon">
            <span class="material-icons" matDatepickerToggleIcon *ngIf="suffixIcon !== 'default'">{{suffixIcon}}</span>
        </mat-datepicker-toggle>
    </div>
    <mat-datepicker #matDatepicker
                    (opened)="datePickerIsOpen = true"
                    (closed)="datePickerIsOpen = false"
                    [calendarHeaderComponent]="customDatePickerHeader"
                    startView="{{view}}">
        <mat-datepicker-actions *ngIf="showValidationButton">
            <div class="date-picker-actions">
                <span matDatepickerApply>{{validationButtonLabel}}</span>
            </div>
        </mat-datepicker-actions>
    </mat-datepicker>
    <p *ngIf="showError" class="text-danger">{{ errorText }}</p>
</div>
