<div class="select">
    <div class="select__input" (click)="isListOpened = !isListOpened" [class.opened]="isListOpened" [class.error]="showError" [class.disabled]="disabled" [class.with-icon]="materialIconBefore">
        <span class="select__input__icon material-icons">{{materialIconBefore}}</span>
        <span *ngIf="!selectedOption" class="select__input__placeholder">{{placeholder}}</span>
        <span *ngIf="selectedOption" class="select__input__option">{{selectedOption.label}}</span>
        <span class="select__input__arrow material-icons">{{ isListOpened ? 'arrow_drop_up' : 'arrow_drop_down' }}</span>
    </div>
    <div *ngIf="isListOpened" class="select__list">
        <div class="select__list__options">
            <div *ngFor="let option of options" class="select__list__options__option" title="{{option.label}}" (click)="selectOption(option)" [class.selected]="selectedOption && selectedOption.value === option.value">
                <span class="select__list__options__option__label" title="{{option.label}}">{{option.label}}</span>
            </div>
        </div>
    </div>
    <p *ngIf="showError" class="text-danger">{{ errorText }}</p>
</div>
