import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NdlTextAreaComponent} from './textarea.component';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {NeedlFormFieldModule} from '../form-field/form-field.module';



@NgModule({
  declarations: [
      NdlTextAreaComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    NeedlFormFieldModule
  ],
  exports: [
      NdlTextAreaComponent
  ]
})
export class NeedlTextAreaModule { }
