import {
    Component,
    OnInit,
    Input,
    HostListener,
    Output,
    EventEmitter
} from "@angular/core";

@Component({
    selector: "dropdown-radio-or-checkbox",
    templateUrl: "./dropdown-radio-or-checkbox.component.html",
    styleUrls: ["./dropdown-radio-or-checkbox.component.scss"]
})
export class DropdownRadioOrCheckboxComponent implements OnInit {
    @Input() buttonLabel: string;
    @Input() showDropDownArrow = false;
    @Input() inputName: string;
    @Input() type: "checkbox" | "radio";
    @Input() options: { label: string; value: string; children: any[] }[] = [];
    @Input() canBeSearch = false;
    @Input() noFiltersText = 'No filters';

    @Output() submit: EventEmitter<object> = new EventEmitter<object>();
    @Output() clear: EventEmitter<object> = new EventEmitter<object>();
    isOpened = false;
    valueChanged = false;
    displayChildren: any = {};
    filteredOptions: OptionDropdownRadioOrCheckbox[] = [];
    selectedOptions: OptionDropdownRadioOrCheckbox[] = [];

    clickWasInsideComponent;

    public radioValue = null;

    constructor() {
    }

    changeRadioValue(event: any) {
        this.valueChanged = true;
        this.radioValue = event;
    }

    ngOnInit() {
        this.options = this.options.map(opt => new OptionDropdownRadioOrCheckbox(opt));
        this.filteredOptions = Object.assign([], this.options.map(opt => new OptionDropdownRadioOrCheckbox(opt)));
        if (this.type === "radio") {
            this.radioValue = this.filteredOptions[0].value;
        } else {
            this.selectedOptions = [];
        }
    }

    @HostListener("click", ["$event"])
    clickInsideComponent(e) {
        this.clickWasInsideComponent = true;
    }

    @HostListener("document:click", ["$event"])
    clickOutsideComponent() {
        if (!this.clickWasInsideComponent) {
            this.isOpened = false;
            if (this.valueChanged) {
                this.valueChanged = false;
                if (this.type === 'radio') {
                    this.submit.emit(this.radioValue);
                } else {
                    this.submit.emit(this.selectedOptions);
                }
            }
        }
        this.clickWasInsideComponent = false;
    }

    ngForTrackByfn(index, item) {
        return item.value;
    }


    toggle() {
        if (!this.isOpened) {
            this.valueChanged = false;
        }
        this.isOpened = !this.isOpened;
        return null;
    }

    getNumberOfSelectedItems() {
        if (
            this.type === "radio" &&
            this.radioValue !== this.filteredOptions[0].value
        ) {
            return 1;
        }
        if (this.type === "checkbox" && this.selectedOptions) {
            return this.selectedOptions.length;
        }
    }

    onSubmit(event: any = null) {
        if (event) {
            event.preventDefault();
        }
        this.toggle();
        this.valueChanged = false;
        if (this.type === 'radio') {
            this.submit.emit(this.radioValue);
        } else {
            this.submit.emit(this.selectedOptions);
        }
    }

    onClear() {
        if (this.type === "radio") {
            this.radioValue = this.filteredOptions[0].value;
        } else {
            this.selectedOptions = [];
        }
        this.valueChanged = false;
        this.clear.emit(null);
    }

    switchDisplayChildren(value: string) {
        this.displayChildren[value] = !this.displayChildren[value];
    }

    selectOutsideCheckboxValue(value: string) {
        this.changeCheckState(new OptionDropdownRadioOrCheckbox({value: value}));
    }

    changeCheckState(option: OptionDropdownRadioOrCheckbox, parent = null) {
        let realParent = null;
        let realOption = null;
        if (parent) {
            realParent = this.getRealOption(parent);
            realOption = realParent.children.find(opt => opt.value === option.value);
        } else {
            realOption = this.getRealOption(option);
        }
        this.valueChanged = true;
        if (this.isChecked(realOption.value)) {
            const index = this.selectedOptions.findIndex(opt => opt.value === realOption.value);
            if (index > -1) {
                this.selectedOptions.splice(index, 1);
            }
            if (realOption.children) {
                for (const child of realOption.children) {
                    const indexChild = this.selectedOptions.findIndex(opt => opt.value === child.value);
                    if (indexChild > -1) {
                        this.selectedOptions.splice(indexChild, 1);
                    }
                }
            }
            if (realParent) {
                const indexParent = this.selectedOptions.findIndex(opt => opt.value === realParent.value);
                if (indexParent > -1) {
                    this.selectedOptions.splice(indexParent, 1);
                }
            }
        } else {
            this.selectedOptions.push(realOption);
            if (realOption.children) {
                for (const child of realOption.children) {
                    if (!this.isChecked(child.value)) {
                        this.selectedOptions.push(child);
                    }
                }
            }
            if (realParent && this.allChildrenChecked(realParent.children)) {
                this.selectedOptions.push(realParent);
            }
        }
    }

    isParentChecked(option: OptionDropdownRadioOrCheckbox) {
        return this.isChecked(option.value) || this.allChildrenChecked(option.children);
    }

    allChildrenChecked(children: OptionDropdownRadioOrCheckbox[]) {
        if (!children || children.length < 1) {
            return false;
        }
        for (const child of children) {
            if (!this.isChecked(child.value)) {
                return false;
            }
        }
        return true;
    }


    hasChildrenChecked(option: OptionDropdownRadioOrCheckbox) {
        if (!option.children || option.children.length === 0) {
            return false;
        }
        for (const child of option.children) {
            if (this.isChecked(child.value)) {
                return true;
            }
        }
        return false;
    }

    filterItem(value: string) {
        this.filteredOptions = [];
        for (const option of this.options) {
            const childrenMatched = [];
            if (option.children && option.children.length > 0) {
                for (const children of option.children) {
                    if (children.label.toLowerCase().indexOf(value.toLowerCase()) > -1) {
                        childrenMatched.push(children);
                    }
                }
            }

            if (childrenMatched.length < 1 && option.label.toLowerCase().indexOf(value.toLowerCase()) > -1) {
                const filteredOption = Object.assign({}, option);
                filteredOption.children = [];
                this.filteredOptions.push(filteredOption);
            }

            if (childrenMatched.length > 0) {
                const filteredOption = Object.assign({}, option);
                filteredOption.children = childrenMatched;
                this.filteredOptions.push(filteredOption);
                this.displayChildren[option.value] = true;
            }
        }
    }

    isChecked(value: string) {
        return this.selectedOptions.findIndex(opt => opt.value === value) > -1;
    }

    isDisplayChildren(value: string) {
        return this.displayChildren[value];
    }

    getRealOption(option: OptionDropdownRadioOrCheckbox) {
        return this.options.find(opt => opt.value === option.value);
    }
}

export class OptionDropdownRadioOrCheckbox {
    constructor(option: any) {
        this.label = option.label;
        this.value = option.value;
        this.children = option.children ? option.children.map(opt => new OptionDropdownRadioOrCheckbox(opt)) : [];
    }

    label: string;
    value: string;
    children: OptionDropdownRadioOrCheckbox[] = [];
}
