import {NgModule} from "@angular/core";
import {SharedModule} from "../shared-module/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {CommonModule} from "@angular/common";
import {AuthRoutingModule} from "./auth-routing.module";
import {SignUpPageComponent} from "./signup/sign-up-page.component";
import {UserComponent} from "./user/user.component";
import {FallbacksModule} from "../../pages/fallbacks/fallbacks.module";
import {RegistrationComponent} from "./registration/registration.component";
import {RegistrationPersonalInfoComponent} from "./registration/unlogged-registration-part/steps/registration-personal-info/registration-personal-info.component";
import {RegistrationProfessionalInfoComponent} from "./registration/unlogged-registration-part/steps/registration-professional-info/registration-professional-info.component";
import {RegistrationPasswordComponent} from "./registration/unlogged-registration-part/steps/registration-password/registration-password.component";
import {UnloggedRegistrationComponent} from "./registration/unlogged-registration-part/unlogged-registration.component";
import {LoggedRegistrationComponent} from "./registration/logged-registration-part/logged-registration.component";
import {RegistrationCompanyInfoComponent} from "./registration/logged-registration-part/steps/registration-company-info/registration-company-info.component";
import {NeedlAlertModule, NeedlPlaceholdersModule, NeedlStepperModule, NeedlSpinnersModule, NeedlSliderModule, NeedlBannersModule, NeedlSnackbarModule} from "needl-lib";
import {RegistrationCompanyVisualsComponent} from "./registration/logged-registration-part/steps/registration-company-visuals/registration-company-visuals.component";
import {RegistrationCompanyPositioningsComponent} from "./registration/logged-registration-part/steps/registration-company-positionings/registration-company-positionings.component";
import {DragulaModule} from "ng2-dragula";
import {SignInModule} from '../../components/sign-in/sign-in.module';
import {BusinessMappingModule} from "../../pages/business-mapping/business-mapping.module";
import {MatIconModule} from "@angular/material/icon";
import {GoogleTagManagerModule} from '../../directives/gtm/gtm.module';
@NgModule({
    declarations: [
        SignUpPageComponent,
        UserComponent,
        LoggedRegistrationComponent,
        UnloggedRegistrationComponent,
        RegistrationComponent,
        RegistrationPersonalInfoComponent,
        RegistrationProfessionalInfoComponent,
        RegistrationPasswordComponent,
        RegistrationCompanyInfoComponent,
        RegistrationCompanyVisualsComponent,
        RegistrationCompanyPositioningsComponent
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        SharedModule,
        CommonModule,
        FallbacksModule, // TODO:  To Avoid and Remove
        AuthRoutingModule,
        NeedlStepperModule,
        NeedlBannersModule,
        NeedlAlertModule,
        NeedlPlaceholdersModule,
        NeedlSliderModule,
        NeedlSpinnersModule,
        NeedlSnackbarModule,
        SignInModule,
        DragulaModule.forRoot(),
        BusinessMappingModule,
        MatIconModule,
        GoogleTagManagerModule
    ],
    exports: []
})

export class AuthModule {
}
