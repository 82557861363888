import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Member} from '../entities/member';
import {environment} from '../../../../environments/environment';
import {AuthService} from '../../auth.service';

@Injectable({
    providedIn: 'root'
})
export class HelpdeskService {
    elementId = 'zsiq_float';
    isHelpdeskLoaded = false;

    constructor(@Inject(DOCUMENT) private readonly document: any, private auth: AuthService) {
    }

    createHelpdesk(helpdeskKey: string = environment.salesIQKey): void {
        // We use a delay here otherwise the javascript loads too quickly and authService is empty
        setTimeout(() => {
            if (this.auth.getMember() && this.auth.getMember().isMasquerading) {
                return;
            }
            window['$zoho'] = {};
            let values = {};
            if (this.auth.getMember()) {
                values = {
                    isLogged: true,
                    name: this.auth.getMember().userInfoTagManager.visitorFullname,
                    email: this.auth.getMember().userInfoTagManager.visitorEmail,
                    id: this.auth.getMember().userInfoTagManager.visitorId,
                    companyType: this.auth.getMember().userInfoTagManager.visitorType,
                };
            } else {
                values['isLogged'] = false;
            }
            window['$zoho'].salesiq = {
                widgetcode: helpdeskKey,
                values: values,
                ready: function () {
                    this.setupHelpdesk();
                }.bind(this)
            };
            const script = this.document.createElement('script');
            script.type = `text/javascript`;
            script.id = 'zsiqscript';
            script.defer = true;
            script.src = 'https://salesiq.zoho.com/widget';
            this.document.querySelector('body').appendChild(script);
            setTimeout(() => {
                this.hideTooltip();
            }, 10000);
        }, 5000);
    }

    setupHelpdesk() {
        // Make sure data is prefilled
        if (this.auth.getMember()) {
            if (
                !window['$zoho'].salesiq.visitor.id() || !window['$zoho'].salesiq.visitor.email() ||
                (window['$zoho'].salesiq.visitor.email() && window['$zoho'].salesiq.visitor.email() !== this.auth.getMember().email)
            ) {
                window['$zoho'].salesiq.reset();
            }
            window['$zoho'].salesiq.visitor.id(this.auth.getMember().idmember);
            window['$zoho'].salesiq.visitor.name(this.auth.getMember().fullname);
            window['$zoho'].salesiq.visitor.email(this.auth.getMember().email);
            window['$zoho'].salesiq.visitor.contactnumber(this.auth.getMember().mobile_event);
            window['$zoho'].salesiq.visitor.info({companyType: this.auth.getMember().userInfoTagManager.visitorType, isLogged: true});
        }
        window['$zoho'].salesiq.visitor.question('Hey, I need assistance!');
        this.isHelpdeskLoaded = true;
    }

    hide() {
        try {
            window['$zoho'].salesiq.floatbutton.visible('hide');
        } catch (w) {
            console.warn(w);
        }
    }

    hideTooltip() {
        try {
            window['$zoho'].salesiq.floatbutton.coin.hidetooltip();
        } catch (w) {
            console.warn(w);
        }
    }

    // To open helpdesk from any component
    openHelpdesk(): void {
        this.document.getElementById(this.elementId) ? document.getElementById(this.elementId).click() : null;
    }

    // To check if helpdesk is fully loaded
    isLoaded(): boolean {
        return this.isHelpdeskLoaded;
    }
}
