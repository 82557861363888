<div class="ndl-list">
    <div *ngFor="let row of rowArray" class="row ndl-list-row pt-2 pb-3 mt-2">
        <div *ngIf="prefix" class="col-1">
            <ndl-placeholder [width]="prefixSize" [height]="prefixSize" align="center"></ndl-placeholder>
        </div>
        <div class="col d-flex align-items-center">
            <div [style.width]="width">
                <ndl-placeholder [height]="height" align="left"></ndl-placeholder>
                <ndl-placeholder class="mt-1" *ngIf="subRow" width="80%" [height]="subRowHeight" align="left"></ndl-placeholder>
            </div>
        </div>
        <div *ngIf="suffix" class="col-2">
            <ndl-placeholder [height]="height" align="right"></ndl-placeholder>
        </div>
    </div>
</div>
