import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { MemberAvailabilityService } from "../../../services/wabel-client/services/member_availability.service";
import { MessageService } from '../../../services/wabel-client/services/message.service';
import { DomSanitizer } from '@angular/platform-browser';
import { daysArrTypeReturned, selectedMonthYearType, monthDayListType, userDefinedDataType, CalendarFreeBusyType } from "../../../services/wabel-client/custom_types";
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as isToday from 'dayjs/plugin/isToday';
import * as timezone from 'dayjs/plugin/timezone';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(utc);
dayjs.extend(isToday);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
import { VideoMeetingInvitations } from "../../../services/wabel-client/entities/video_meeting_invitations";
import { VideoMeetingInvitationService } from "../../../services/wabel-client/services/video_meeting_invitation.service";
import { UtilsService } from "../../../services/utils.service";
import { MemberService } from "../../../services/wabel-client/services/member.service";
import { OAuthService } from "../../../services/wabel-client/services/oauth.service";
import { Member } from "../../../services/wabel-client/entities/member";
import { AuthService } from "../../../services/auth.service";
import { Company } from "../../../services/wabel-client/entities/company";

@Component({
  selector: 'video-meeting-request-scheduler',
  templateUrl: './video-meeting-request.component.html',
  styleUrls: ['./video-meeting-request.component.scss']
})


export class VideoMeetingRequestComponent implements OnInit {

  @Input() toCompany: Company;
  @Input() toMember: Member = null;
  @Input() threadId: number;
  @Input() representator = false;

  @Output() closeEvent: EventEmitter<any> = new EventEmitter();

  public date: Date = new Date();
  public step = 2;
  public loadingNext = false;
  public monthList: Array<string> = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  public dayList: Array<string> = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  public buttonTextList: Array<Array<string>> = [['', ''], ['Schedule the meeting', '248px'], ['Pick slots', '235px'], ['Send the invite', '200px'], ['Close', '140px']];
  public selectedMonthList: Array<string> = [];
  public selectedMonthYear: selectedMonthYearType;
  public remainingDays: Array<monthDayListType> = [];
  public myTimeZone = "";
  public interlocutorTimeZone = "Europe/Paris";
  public stackedSelectedDays: Array<monthDayListType> = [];
  public myBookedSlots: VideoMeetingInvitations[] = [];
  public introlocutorBookedSlots: VideoMeetingInvitations[] = [];
  public userDefinedData: userDefinedDataType = {
    subject: "Video Meeting Invitation",
    message: "",
    meeting_data: [],
    selected_month_year: null
  };

  // temporary
  public tsRemaingDays: daysArrTypeReturned[] = [];

  fromMember: Member;
  public calendarFreeBusy: CalendarFreeBusyType = null;

  constructor(
    public memberAvailabilityService: MemberAvailabilityService,
    private messageService: MessageService,
    private memberService: MemberService,
    private sanitizer: DomSanitizer,
    public utilsService: UtilsService,
    public oAuthService: OAuthService,
    private videoMeetingInvitationService: VideoMeetingInvitationService,
    public authService: AuthService) {
    this.selectedMonthYear = { month: this.monthList[this.date.getMonth()], year: this.date.getFullYear() };
    this.myTimeZone = dayjs.tz.guess();
  }

  getDayNameOfDate(year: number, month: number, day: number) {
    const d = new Date(year, month + 1, day);
    return this.dayList[d.getDay()];
  }

  getLastDayOfMonth(month: number, year: number) {
    const d = new Date(year, month + 1, 0);
    return d.getDate();
  }

  ngOnInit() {
    this.fromMember = this.authService.getMember();

    this.selectedMonthList = this.monthList.slice(this.date.getMonth());
    // push next year jan if month is dec
    if (this.selectedMonthList.length === 1) {
      this.selectedMonthList.push("January");
    }

    this.loadingNext = true;

    // Get interlocutor video meeting schedules
    if (this.representator) {
      this.videoMeetingInvitationService.getVideoMeetingInvitationsTemporaryBookedSlots(this.toMember.idmember).subscribe((data) => {
        if (!data) { return; }
        this.introlocutorBookedSlots = data;
      });
    }

    // Get own video meeting schedules
    this.videoMeetingInvitationService.getVideoMeetingInvitationsTemporaryBookedSlots().subscribe((data) => {
      if (!data) { return; }
      this.myBookedSlots = data;
      this.prepareMonthRemainingDays();
    });

    // Get target member timezone
    // this.memberService.getTimezoneByMember( this.data.to_member.idmember ).subscribe((data) => {
    //   if (!data) { return; }

    //   if( data.timezone ){
    //     this.interlocutorTimeZone = data.timezone
    //   }
    // });
  }

  close() {
    this.step === 4 ? this.closeEvent.emit(true) : this.closeEvent.emit(false);
  }

  selectNewMonth(month: string) {
    if (month !== this.selectedMonthYear.month) {

      if (this.selectedMonthList[this.selectedMonthList.length - 1] === "January" && month === "January") {
        this.selectedMonthYear.year = this.selectedMonthYear.year + 1;
      } else {
        this.selectedMonthYear.year = this.date.getFullYear();
      }

      this.selectedMonthYear.month = month;
      this.prepareMonthRemainingDays();
    }

  }

  prepareData(data: daysArrTypeReturned[]) {

    let countWholeMonth = true;

    if (this.selectedMonthYear.month === this.selectedMonthList[0]) {
      countWholeMonth = false;
    }

    let currentDay = 1;
    let lastDay: number;

    if (!countWholeMonth) {
      currentDay = this.date.getDate();
    }

    lastDay = this.getLastDayOfMonth(this.monthList.indexOf(this.selectedMonthYear.month), this.selectedMonthYear.year);

    let pushDays = true;
    this.remainingDays = [];
    this.stackedSelectedDays = [];

    while (pushDays) {

      const dayInfo: monthDayListType = { day: "", date: "", slots: [], timezone: "" };

      dayInfo.day = this.getDayNameOfDate(this.selectedMonthYear.year, this.monthList.indexOf(this.selectedMonthYear.month) - 1, currentDay);
      dayInfo.date = currentDay + " " + this.selectedMonthYear.month;

      data.forEach(x => {

        if (x.d == dayInfo.day) {
          x.v.forEach(v => {

            if (v.timeFrom !== null) {

              // is slot is free?
              if (this.isSlotFreeFromBothUsers(dayInfo.date, v.timeFrom, this.selectedMonthYear.year)) {
                // All ok push slot to list
                dayInfo.slots.push({ isSelected: false, ...v });

              }

            }

          });
        }

        dayInfo.timezone = this.myTimeZone;

      });

      // is member set availability on given day
      if (dayInfo.slots.length > 0) {
        this.remainingDays.push(dayInfo);
      }

      currentDay++;

      if (currentDay > lastDay) {
        pushDays = false;
      }

    }

    if (this.remainingDays.length > 0) {
      this.loadingNext = false;
    }

  }


  isSlotFreeFromBothUsers(date: string, time: string, year: number) {

    let free = true;
    const status: string[] = ['pending', 'accepted'];

    const _givendate = dayjs(`${date} ${time} ${year}`, "D MMMM hh:mm A YYYY");

    if (_givendate.isToday() && _givendate.isBefore(dayjs())) {
      return false;
    }

    // local db data
    this.myBookedSlots.forEach(x => {
      // check invitation status
      if (status.includes(x.invitationStatus)) {

        x.videoMeetingInvitationSlots.forEach(s => {
          // if date time and year matched
          if (s.dateViewable === date && s.timeFromViewable === time && s.yearViewable === year) {
            free = false;
          }
        });

      }

    });

    if (free) {

      const _checkDate = new Date(Date.parse(`${date} ${year} ${time}`));

      if (this.compareTimeConflict(_checkDate, this.calendarFreeBusy.google.mine)) {
        return false;
      }

    }

    return free;

  }

  compareTimeConflict(_checkDate: Date, list) {

    let conflict = false;

    list.forEach(x => {

      const _fromDate = new Date(Date.parse(x.start));
      const _toDate = new Date(Date.parse(x.end));

      if ((_checkDate >= _fromDate && _checkDate <= _toDate)) {

        const diff = Math.abs(_toDate.getTime() - _checkDate.getTime());
        if (Math.floor((diff / 1000) / 60) > 0) {
          conflict = true;
        }

      }

    });

    return conflict;

  }


  prepareMonthRemainingDays() {

    this.loadingNext = true;

    this.tsRemaingDays = this.utilsService.getVideoMeetingSlotData();

    let countWholeMonth = true;

    if (this.selectedMonthYear.month === this.selectedMonthList[0]) {
      countWholeMonth = false;
    }

    let currentDay = 1;

    if (!countWholeMonth) {
      currentDay = this.date.getDate();
    }

    const similarMonth: number = this.monthList.indexOf(this.selectedMonthYear.month) + 1;

    const startTime = `${this.selectedMonthYear.year}-${similarMonth}-${currentDay} 00:00 AM`;
    const lastDay = this.getLastDayOfMonth(similarMonth, this.selectedMonthYear.year);
    const endTime = `${this.selectedMonthYear.year}-${similarMonth}-${lastDay} 11:59 PM`;

    this.oAuthService.fetchFreeBusySlotFromCalendars(startTime, endTime, this.myTimeZone).subscribe(data => {
      if (!data) { return; }

      this.calendarFreeBusy = data;
      this.prepareData(this.tsRemaingDays);
    },

      () => {
        this.prepareData(this.tsRemaingDays);
      });

    // this.MemberAvailabilityService.getAvailabilities( this.data.to_member.idmember ).subscribe((data: daysArrTypeReturned[]) => {

    //     // Can't make this observer HOT????
    //     // returning response twice
    //     if (!data) {return}

    //     this.prepareData( data )

    //   },() => this.loadingNext = false);

  }

  nextBack() {

    if (this.step === 3) {
      this.stackedSelectedDays.forEach(x => {
        x.slots.forEach(s => {
          s.isSelected = false;
        });
      });
    }

    if (this.step !== 2) {
      this.step--;
    }

  }

  nextStep() {

    // Validate data before navigation
    switch (this.step) {
      case 1:

        if (this.userDefinedData.subject === "") {
          return false;
        }

        this.step++;
        this.prepareMonthRemainingDays();

        break;
      case 2:

        if (this.remainingDays.length === 0 || this.stackedSelectedDays.length === 0) {
          return;
        }

        this.step++;
        break;
      case 3:

        let isSelected = false;

        this.stackedSelectedDays.forEach(x => {

          x.slots.forEach(y => {
            if (y.isSelected) {
              isSelected = true;
            }
          });

        });

        if (isSelected) {
          this.step++;
          this.sendMeetingInvitation();
        }

        break;
      default:

        if (!this.loadingNext) {
          this.close();
        }

        break;
    }

  }

  stackSelectedDays(day: monthDayListType) {
    const index: number = this.stackedSelectedDays.findIndex(x => x.date === day.date);

    if (index !== -1) {
      this.stackedSelectedDays.splice(index, 1);
    } else {

      if (!this.isLimitReached()) {
        this.stackedSelectedDays.push(day);
      }

    }

  }

  isSelectedDay(day: monthDayListType) {
    const index: number = this.stackedSelectedDays.findIndex(x => x.date === day.date);
    return index === -1 ? false : true;
  }

  isLimitReached() {
    return this.stackedSelectedDays.length === 3 ? true : false;
  }

  buttonText() {
    return this.buttonTextList[this.step][0];
  }

  buttonWidth() {
    return this.buttonTextList[this.step][1];
  }

  formatTimezone(time: string) {
    return dayjs(time, "hh:mm A").format('hh:mm A');
  }

  getInterlocutorLocalTime() {
    return dayjs().format('hh:mm A');
  }

  sendMeetingInvitation() {

    this.userDefinedData.meeting_data = this.stackedSelectedDays;
    this.userDefinedData.selected_month_year = this.selectedMonthYear;

    this.loadingNext = true;

    let createdForOrNull = null;

    if (this.representator) {
      createdForOrNull = this.toMember.idmember;
    }

    this.messageService.sendVideoMeetingInvitationMessage(
      this.userDefinedData,
      this.threadId,
      this.toCompany.idcompany,
      createdForOrNull
    ).subscribe((data) => {
      if (!data) { return; }

      this.loadingNext = false;
    }, () => this.loadingNext = false);

  }

}
