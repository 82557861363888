import {WabelEntity} from "./wabel-entity.abstract";
import {PrivateMessage} from "./private_message";
import {PrivateMessageRecipient} from "./private_message_recipient";
import {PrivateMessageRead} from "./private_message_read";
import { VideoMeetingInvitations } from "./video_meeting_invitations";

export class PrivateMessageThread extends WabelEntity {
    constructor(obj: any) {
        super(obj);

        if (obj) {
            this.recipients = obj.recipients && obj.recipients.length > 0 ? obj.recipients.map(recipient => new PrivateMessageRecipient(recipient)) : [];
            this.messages = obj.messages && obj.messages.items && obj.messages.items.length > 0 ? obj.messages.items.map(message => new PrivateMessage(message)) : [];
            this.lastMessagePrivateReads = obj.lastMessagePrivateReads && obj.lastMessagePrivateReads.length > 0 ? obj.lastMessagePrivateReads.map(lastMessagePrivateRead => new PrivateMessageRead(lastMessagePrivateRead)) : [];
        }
    }

    idthread: number;
    recipients: PrivateMessageRecipient[];
    messages: PrivateMessage[];
    subject: string;
    unread: boolean;
    lastMessageDate: string;
    lastMessagePrivateReads: PrivateMessageRead[];
    privateMessageByInProgressVideoMeeting: PrivateMessage;
    readOnly: boolean;

    get id(): number {
        return this.idthread;
    }

    set id(id: number) {
        this.idthread = id;
    }
}
