import {SourcingRequest} from './sourcing_request';
import {WabelEntity} from "./wabel-entity.abstract";

export class SourcingRequestKeyword extends WabelEntity {
      constructor(obj: any) {
        super(obj);
        if (obj) {
            this.sourcingRequest = obj.sourcingRequest ? new SourcingRequest(obj.sourcingRequest) : null;
        }
      }

    keyword: string;
    sourcingRequest: SourcingRequest;

    get id() {
        return this.keyword;
    }
}
