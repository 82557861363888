import {AfterViewInit, Input, OnDestroy, OnInit} from '@angular/core';
import {Component, EventEmitter, Output} from '@angular/core';
import {CompanyType} from '../../../../services/wabel-client/entities/company_type';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BusinessMappingPriority} from "../../../../services/wabel-client/entities/business_mapping_priority";
import {Subscription} from "rxjs";
import {Tag} from "../../../../services/wabel-client/entities/tag";
import {BusinessMappingService} from "../../../../services/wabel-client/services/business-mapping.service";
import {AuthService} from "../../../../services/auth.service";
import {BusinessMapping} from "../../../../services/wabel-client/entities/business_mapping";
import {NdlSnackbarService} from 'needl-lib';


@Component({
    selector: 'business-mapping-priority-form',
    templateUrl: './business-mapping-priorities-form.component.html',
    styleUrls: ['./business-mapping-priorities-form.component.scss']
})
export class BusinessMappingPrioritiesFormComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() regionOptions: Tag[] = [];
    @Input() idBusinessMappingPriority = null;
    @Input() activitiesOptions: Tag[] = [];
    @Input() companyTypeOptions: CompanyType[];
    @Input() businessMappingPriority: BusinessMappingPriority = null;
    @Input() businessChallengeOptions: any[] = [];

    private _priority: number;
    @Input() get priority(): number {
        return this._priority;
    }
    set priority(priority: number) {
        this._priority = priority + 1;
        if (this.formGroup) {
            this.formGroup.get('priority').setValue(this._priority, {emitEvent: true});
        }
    }

    @Input() showDragHandler = false;
    @Input() formSubmitted = false;
    @Input() loading = false;
    @Input() hideCountries = false;

    @Output('created') createNewBusinessMappingPriority = new EventEmitter<FormGroup>();
    @Output('saved') newBusinessMappingPrioritySaved = new EventEmitter<FormGroup>();
    @Output() fieldUpdated = new EventEmitter();
    countriesOptions: Tag[] = [];
    formValueChange$: Subscription;
    formGroup: FormGroup;
    saving = false;
    constructor(private fb: FormBuilder,
                private businessMappingService: BusinessMappingService,
                private snackbar: NdlSnackbarService,
                private authService: AuthService) {
    }

    ngOnInit() {
        if (this.businessMappingPriority.region) {
            this.regionOptions.filter(tag => +tag.id === +this.businessMappingPriority.region.id).forEach(tag => tag.children.forEach(child => this.countriesOptions.push(child)));
        }
        this.formGroup = this.fb.group({
            goal: [this.businessMappingPriority.goal, Validators.required],
            region: [this.businessMappingPriority.region?.id, Validators.required],
            countries: [{value: this.businessMappingPriority.countries.map(tag => tag.id), disabled: !this.businessMappingPriority.region}, !this.hideCountries ? Validators.required : null],
            activities: [this.businessMappingPriority.activities.map(tag => tag.id), Validators.required],
            targets: [this.businessMappingPriority.companyTypes.map(ct => ct.idcompanytype), Validators.required],
            id: [this.businessMappingPriority.id],
            priority: [this.priority]
        });
        this.formValueChange$ = this.formGroup.valueChanges.subscribe(data => {
            if (data.region && data.region.length) {
                this.countriesOptions = [];
                this.regionOptions.filter(tag => +tag.id === +data.region).forEach(tag => tag.children.forEach(child => this.countriesOptions.push(child)));
                if (!this.formGroup.controls.countries.enabled) {
                    this.formGroup.controls.countries.enable();
                }
            }
        });
    }

    ngAfterViewInit() {
        this.createNewBusinessMappingPriority.emit(this.formGroup);
    }

    ngOnDestroy() {
        if (this.formValueChange$) {
            this.formValueChange$.unsubscribe();
        }
    }

    emptyCountryField() {
        this.formGroup.get('countries').reset([]);
    }

    saveFocus() {
        this.formGroup.markAllAsTouched();
        if (this.formGroup.invalid) {
            return;
        }
        this.saving = true;
        this.formGroup.value.id = this.idBusinessMappingPriority ? this.idBusinessMappingPriority : null;
        this.formGroup.value.countries = this.formGroup.value.countries.filter(v => v !== null && v !== undefined);
        this.businessMappingService.saveBusinessMappingPriority({businessMappingPriority: this.formGroup.value}).subscribe(data => {
            this.saving = false;
            this.newBusinessMappingPrioritySaved.emit();
            this.snackbar.show("Your business priority has been saved.", 'check');
            this.formGroup.value.id = data.id;
            if (!this.authService.getMember().businessMapping || !this.authService.getMember().businessMapping.businessMappingPriorities) {
                this.authService.user.businessMapping = new BusinessMapping(data.businessMapping);
            }
            this.authService.getMember().businessMapping.businessMappingPriorities.push(data);
        }, err => {
            this.saving = false;
            console.error(err);
            this.snackbar.show("An error occurred. Please try again or contact an administrator.", 'error');
        });
    }
}
