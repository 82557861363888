<ndl-form-field #formField [suffix]="suffix" [label]="label" [hint]="hint" [errors]="errors">

    <ndl-radio-field [ngControl]="ngControl" [required]="required"
                     [errorState]="innerFormControl?.errors && innerFormControl?.touched" [options]="options"
                     (change)="onChange($event)" (blur)="onTouched()">
    </ndl-radio-field>

    <ng-template #suffix>
        <ng-content select="[ndl-suffix]"></ng-content>
    </ng-template>
</ndl-form-field>
