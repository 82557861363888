import {Company} from './company';
import {WabelEntity} from "./wabel-entity.abstract";

export class BusinessMappingPriorityRecommendation extends WabelEntity {
  id: number;
  company: Company;

  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.company = obj.company ? new Company(obj.company) : null;
    }
  }
}
