import {Component, HostBinding, Input} from '@angular/core';
import {AuthService} from "../../../services/auth.service";
import {TemplateService} from "../../../services/template.service";
import {Title} from "@angular/platform-browser";
import {Router} from "@angular/router";
import {environment} from "../../../../environments/environment";

@Component({
    selector: 'app-footer',
    templateUrl: './app-footer.component.html',
    styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent {

    needl_full_url: string = environment.needl_url;
    currentYear = new Date().getFullYear();

    @HostBinding('class.in-profile') @Input() inProfile: boolean = false;


    constructor(public router: Router, public authService: AuthService, public templateService: TemplateService,
                public titleService: Title) {
    }

    isJoinForFreeButtonShowed() {
        return !this.authService.getMember() && !this.router.url.includes('/join') && !this.router.url.includes('/user/login');
    }
}
