import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ImageService } from "../../../services/image.service";

@Component({
  selector: "company-card",
  templateUrl: "./company-card.component.html",
  styleUrls: ["./company-card.component.scss"]
})
export class CompanyCardComponent implements OnInit {
  @Input() companyName: string;
  @Input() memberFullName: string;
  @Input() logoUrl: string;
  @Input() isLastUpdaterWabel = false;
  @Input() isLastUpdaterOpponent = false;
  @Input() showButton = false;
  @Input() reactOnHover = false;
  @Input() labelForButton = "'button_label'";
  @Input() iconForButton = "";
  @Input() disableButton = false;
  @Input() disabledPopUpText = "";
  @Input() shrinkCompanyTitle = false;

  @Output() buttonClick: EventEmitter<object> = new EventEmitter<
    object
  >();

  tooltipVar = false;
  showTooltipWabel = false;
  showTooltipOpponent = false;
  hover = false;

  constructor(public imageService: ImageService) { }

  ngOnInit() { }

  onButtonClick(event) {
    event.stopPropagation();
    this.buttonClick.emit(event);
  }
}
