import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductPreviewComponent} from './product-preview.component';
import {RouterModule} from "@angular/router";
import {NeedlButtonsModule} from "needl-lib";
import {ProductCategoryTagModule} from '../product-category-tag/product-category-tag.module';
import {SharedModule} from '../../../modules/shared-module/shared.module';


@NgModule({
    declarations: [
        ProductPreviewComponent
    ],
  imports: [
    CommonModule,
    RouterModule,
    NeedlButtonsModule,
    ProductCategoryTagModule,
    SharedModule
  ],
    exports: [
        ProductPreviewComponent
    ]
})
export class ProductPreviewModule {
}
