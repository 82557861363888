import {WabelEntity} from "./wabel-entity.abstract";
import {Company} from "./company";
import {CompanyRelationshipStatus} from "./company_relationship_status";
import {Member} from "./member";
import {CompanyRelationshipTagReference} from "./company_relationship_tag_reference";

export class CompanyRelationship extends WabelEntity {
    constructor(obj: any) {
      super(obj);
      if (obj) {
        this.fromIdcompany = obj.fromIdcompany ? new Company(obj.fromIdcompany) : null;
        this.toIdcompany = obj.toIdcompany ? new Company(obj.toIdcompany) : null;
        this.statusFrom = obj.statusFrom ? new CompanyRelationshipStatus(obj.statusFrom) : null;
        this.statusTo = obj.statusTo ? new CompanyRelationshipStatus(obj.statusTo) : null;
        this.currentStatus = obj.currentStatus ? new CompanyRelationshipStatus(obj.currentStatus) : null;
        this.lastStatusUpdaterMember = obj.lastStatusUpdaterMember ? new Member(obj.lastStatusUpdaterMember) : null;
        this.toIdMember = obj.toIdMember ? new Member(obj.toIdMember) : null;
        this.fromIdMember = obj.fromIdMember ? new Member(obj.fromIdMember) : null;
        this.tags = obj.tags ? obj.tags.map(tag => new CompanyRelationshipTagReference(tag)) : [];
      }
    }

    idcompanyRelationship: number;
    fromIdcompany: Company;
    toIdcompany: Company;
    scoreFrom: number;
    scoreTo: number;
    statusFrom: CompanyRelationshipStatus;
    statusTo: CompanyRelationshipStatus;
    updatedDate: string;
    createdDate: string;
    currentStatus: CompanyRelationshipStatus;
    source: string;
    sourceDetails: string;
    lastStatusUpdaterMember: Member;
    toIdMember: Member;
    fromIdMember: Member;
    tags: CompanyRelationshipTagReference[];
    commentGiven: string;
    scoreGiven: number;

    get id(): number {
        return this.idcompanyRelationship;
    }

    set id(id: number) {
        this.idcompanyRelationship = id;
    }
}
