import {
    Component, ViewChild
} from '@angular/core';
import {NdlInputBaseDirective} from '../input-base.directive';
import {NdlPhoneFieldComponent} from './phone-field.component';
import {NdlValidators} from '../validators';

@Component({
    selector: 'ndl-phone',
    templateUrl: './phone.component.html',
    styleUrls: ['./phone.component.scss'],
    host: {
        'class': 'ndl-phone',
        '(blur)': "onTouched()"
    }
})
export class NdlPhoneComponent extends NdlInputBaseDirective {
    override defaultValue = null;
    override _value = null;

    validators = NdlValidators.validPhone;

    @ViewChild(NdlPhoneFieldComponent, {static: true}) phoneField: NdlPhoneFieldComponent;

    get error() {
        if (this.ngControl?.errors) {
            if (this.ngControl.errors['too-long']) {
                return "The phone number is too long!";
            }
            if (this.ngControl.errors['too-short']) {
                return "The phone number is too short!";
            }
            if (this.ngControl.errors['invalid-country-code']) {
                return "Please select a valid country.";
            }
            return "Please enter a valid phone number.";
        }
        return null;
    }

    override clear() {
        super.clear();
        this.phoneField.transformTextToPhoneNumber(this.value)
    }
}
