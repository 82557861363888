import {ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild} from '@angular/core';
import {SignupFormComponent} from "../../../forms/signup-form/signup-form.component";
import {Member} from "../../../services/wabel-client/entities/member";
import {Company} from "../../../services/wabel-client/entities/company";
import {Subject} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {CompanyService} from "../../../services/wabel-client/services/company.service";
import {MemberService} from "../../../services/wabel-client/services/member.service";
import {UtilsService} from "../../../services/utils.service";
import {Title} from "@angular/platform-browser";
import {RegistrationService} from "../../../services/wabel-client/services/registration.service";
import {debounceTime, distinctUntilChanged, filter, switchMap, tap} from "rxjs/operators";
import {CompanyType} from "../../../services/wabel-client/entities/company_type";
import {CacheService} from "../../../services/cache.service";
import {TemplateService} from "../../../services/template.service";
import {Tag} from "../../../services/wabel-client/entities/tag";
import {TagTypeService} from "../../../services/wabel-client/services/tag_type.service";

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

    @ViewChild(SignupFormComponent, { static: true }) signupFormComponent: SignupFormComponent;

    member: Member;
    companyListOptions: Company[] = [];
    companyInput = new Subject<string>();
    companyLoading: boolean = false;
    companyAlreadyCreated: boolean = true;
    signUpForCompanyType: number[] = [13, 14, 15, 16];
    defaultSelectedNoOption: Company;
    @Input() signUpType: string;
    @Input() inEventUniverse: boolean = false;
    companyCategories: number[] = [];
    categoriesOptions: Tag[] = [];
    companyActivities: number[] = [];
    activitiesOptions: Tag[] = [];
    companySpecializations: number[] = [];
    specializationOptions: Tag[] = [];
    asynchronousButtonState: string = 'before';
    asynchronousButtonState2: string = 'before';
    asynchronousButtonState3: string = 'before';
    typeOfCompanyOther: string = null;
    step: number = 1;
    errorAfterSubmit: string = null;
    errorAfterSubmit2: string = null;
    errorAfterSubmit3: string = null;
    @Output() memberHasBeenSaved: EventEmitter<any> = new EventEmitter();
    @Output() companyTypeChanged: EventEmitter<string> = new EventEmitter();

    constructor(private templateService: TemplateService, private route: ActivatedRoute, private router: Router, private companyService: CompanyService, private memberService: MemberService,
                private cd: ChangeDetectorRef, private utilsService: UtilsService,
                private titleService: Title,
                private registrationService: RegistrationService, private zone: NgZone, private cacheService: CacheService,
                private tagTypeService: TagTypeService) {
    }

    ngOnInit() {
        if (this.registrationService.getMemberForZohoRegistration() != null){
            this.member = this.registrationService.getMemberForZohoRegistration();
        }else{
            this.member = this.registrationService.initMember();
        }
        this.defaultSelectedNoOption = new Company({
            name: 'My company is not registered on needl. yet',
            idcompany: -1,
            id: -1
        });
        this.route.params.subscribe(
            params => {
                if (!this.signUpType) this.signUpType = params['type'];
                this.companyTypeChanged.emit(this.signUpType);
                switch (this.signUpType) {
                    case 'buyer':
                        this.titleService.setTitle('Registration Buyer | needl.');
                        this.signUpForCompanyType = [5, 6, 7, 8];
                        break;
                    case 'co-packer':
                        this.titleService.setTitle('Registration co-packer | needl.');
                        this.signUpForCompanyType = [5, 6, 7, 8];
                        break;
                    case 'importer':
                        this.titleService.setTitle('Registration Importer | needl.');
                        this.signUpForCompanyType = [3];
                        break;
                    case 'manufacturer':
                        this.titleService.setTitle('Registration Manufacturer | needl.');
                        this.signUpForCompanyType = [2];
                        break;
                    case 'package':
                        this.titleService.setTitle('Registration Package Supplier | needl.');
                        this.signUpForCompanyType = [18];
                        break;
                    default:
                        this.signUpForCompanyType = [2, 3, 5, 6, 7, 8, 13, 14, 15, 16];
                        this.typeOfCompanyOther = '';
                        break;
                }
            }
        );
        this.loadCompany(this.signUpForCompanyType);
        this.companyListOptions = [];
        if (this.signUpType === 'manufacturer') {
            this.cacheService.getCategories().subscribe((data) => {
                if (!data) return;
                this.categoriesOptions = data.tags;
            });
            this.tagTypeService.getTagTypeByInternalName(TagTypeService.ACTIVITY_INTERNAL_NAME).subscribe((data) => {
                if (!data) return;
                this.activitiesOptions = data.tags;
            });
        }

        if (this.signUpType === 'buyer' || this.signUpType === 'co-packer') {
            this.tagTypeService.getTagTypeByInternalName(TagTypeService.SPECIALIZATION_INTERNAL_NAME).subscribe((data) => {
                if (!data) return;
                this.specializationOptions = data.tags;
            });
        }
    }

    retrieveCompany(idcompany) {
        if (idcompany) {
            if (this.companyLoading) {
                this.companyLoading = false;
            }
            let result = this.companyListOptions.filter(obj => {
                return obj.id === idcompany
            });
            Object.assign({company: result[0]}, this.member);
            this.signupFormComponent.checkFormErrors();
        }
    }

    changePhoneCompany(phone) {
        let company = this.member.company;
        company.phone = phone;
        Object.assign({company: company}, this.member);
    }

    changePhoneMember(phone) {
        this.member.mobile = phone;
    }

    changeTypeOfCompanyOther(type) {
        this.typeOfCompanyOther = type;
    }

    loadCompany(companyTypes: number[]) {
        this.companyInput.asObservable().pipe(
            filter(term => term.length >= 3),
            tap(() => this.companyLoading = true),
            distinctUntilChanged(),
            debounceTime(1000),
            switchMap(term => this.companyService.getSimpleListByFilter(20, 0, 'name', 'ASC', term, companyTypes)),
        ).subscribe((data) => {
            if (!data) return;
            this.companyListOptions = data.items.map((company: any) => new Company(company));
            this.cd.markForCheck();
            setTimeout(() => {
                if (this.companyLoading) {
                    this.signupFormComponent.kitFormSelectComponent.openSelectElement();
                    this.companyLoading = false;
                }
            }, 1);
        }, () => {

        });
    }

    assignAddressToCompany(address) {
        let company = this.member.company;
        company.country = new Tag({name: address.country_name || ''});
        company.regionName = address.region_name || '';
        company.cityName = address.city_name || '';
        company.postalCode = address.postal_code || '';
        company.formattedAddress = address.formatted_address || '';
        Object.assign({company: company}, this.member);
    }

    saveCompany() {
        let company = this.member.company;
        this.companyAlreadyCreated = false;

        if (this.signUpType === 'buyer') {
            company.companytype = new CompanyType({idcompanytype: 6});
        } else if (this.signUpType === 'importer') {
            company.companytype = new CompanyType({idcompanytype: 3});
        } else if (this.signUpType === 'other') {
            company.companytype = new CompanyType({idcompanytype: +this.typeOfCompanyOther});
        } else if (this.signUpType === 'package') {
            company.companytype = new CompanyType({idcompanytype: +this.signUpForCompanyType});
        }else {
            company.companytype = new CompanyType({idcompanytype: 2});
        }
        company.rootCategories = this.companyCategories.map(idtag => new Tag({id: idtag}));
        company.activities = this.companyActivities.map(id => new Tag({id: id}));
        company.specializations = this.companySpecializations.map(idtag => new Tag({id: idtag}));

        Object.assign({company: company}, this.member);
        if (!this.companyAlreadyCreated) {
            this.saveMemberWithCompany();
        } else {
            this.saveMember();
        }
    }

    actionValidateSignUpStep(stepValue) {
        switch (stepValue.step) {
            case 1:
                this.errorAfterSubmit = null;
                this.asynchronousButtonState = 'loading';
                this.checkIfEmailAlreadyExists(this.member.email, false);
                break;
            case 2:
                this.errorAfterSubmit2 = null;
                this.asynchronousButtonState2 = 'loading';
                this.saveCompany();
                break;
            case 3:
                this.errorAfterSubmit3 = null;
                this.asynchronousButtonState3 = 'loading';
                this.saveMemberPassword(stepValue.value);
                break;
            default:
                if (stepValue.step === null && stepValue.value === 'jump') {
                    this.errorAfterSubmit = null;
                    this.asynchronousButtonState = 'loading';
                    this.checkIfEmailAlreadyExists(this.member.email, true);
                }
                break;
        }
    }

    checkIfEmailAlreadyExists(email: string, saveMemberAfter: boolean) {
        this.utilsService.checkIfEmailAlreadyExists(email).subscribe(data => {
                if (!data) return;
                if (data['valid'] === true) {
                    if (saveMemberAfter) {
                        this.saveMember();
                    } else {
                        this.asynchronousButtonState = 'before';
                        this.step = 2;
                        window.scrollTo(0, 0);
                    }
                } else {
                    this.asynchronousButtonState = 'before';
                    this.errorAfterSubmit = 'Your email is already registered on needl.';
                }
            },
            error => {
                this.asynchronousButtonState = 'before';
                this.errorAfterSubmit = this.utilsService.detectErrorMessages(error).join('. ');
            });
    }

    saveMember() {
        let isPreregister = this.isPreregister();
        if (this.registrationService.getMemberForZohoRegistration() != null){
            //update current zoho member
            this.memberService.updateSignupInformationAfterZohoInvitation(this.member).subscribe(data => {
                    let member = this.member;
                    member.idmember = data.idmember;
                    Object.assign(this.member, member);
                    this.asynchronousButtonState = 'before';
                    this.asynchronousButtonState2 = 'before';
                    this.step = 3;
                    this.errorAfterSubmit = null;
                    this.errorAfterSubmit2 = null;
                    window.scrollTo(0, 0);
                },
                error => {
                    this.asynchronousButtonState = 'before';
                    this.asynchronousButtonState2 = 'before';
                    this.errorAfterSubmit = this.utilsService.detectErrorMessages(error).join('. ');
                    this.errorAfterSubmit2 = this.utilsService.detectErrorMessages(error).join('. ');
                });
        }else {
            this.memberService.createMember(this.member, true, this.companyAlreadyCreated, isPreregister).subscribe(data => {
                    let member = this.member;
                    member.idmember = data.idmember;
                    Object.assign(this.member, member);
                    this.asynchronousButtonState = 'before';
                    this.asynchronousButtonState2 = 'before';
                    this.step = 3;
                    this.errorAfterSubmit = null;
                    this.errorAfterSubmit2 = null;
                    window.scrollTo(0, 0);
                },
                error => {
                    this.asynchronousButtonState = 'before';
                    this.asynchronousButtonState2 = 'before';
                    this.errorAfterSubmit = this.utilsService.detectErrorMessages(error).join('. ');
                    this.errorAfterSubmit2 = this.utilsService.detectErrorMessages(error).join('. ');
                });
        }
    }

    saveMemberWithCompany(){
        let isPreregister = this.isPreregister();
        this.memberService.createMemberWithCompany(this.member, this.member.company, true, isPreregister).subscribe(data => {
                let company = this.member.company;
                company.idcompany = data.company.idcompany;
                Object.assign({company: company}, this.member);
                let member = this.member;
                member.idmember = data.idmember;
                Object.assign(this.member, member);
                this.asynchronousButtonState = 'before';
                this.asynchronousButtonState2 = 'before';
                this.step = 3;
                this.errorAfterSubmit = null;
                this.errorAfterSubmit2 = null;
                window.scrollTo(0, 0);
            },
            error => {
                this.asynchronousButtonState = 'before';
                this.asynchronousButtonState2 = 'before';
                this.errorAfterSubmit = this.utilsService.detectErrorMessages(error).join('. ');
                this.errorAfterSubmit2 = this.utilsService.detectErrorMessages(error).join('. ');
            });
    }

    saveMemberPassword(password) {
        if (this.registrationService.getMemberForZohoRegistration() != null) {
            this.memberService.changeZohoRegistrationMemberPassword(password).subscribe(data => {
                    setTimeout(() => {
                        this.asynchronousButtonState3 = 'after';
                        this.memberHasBeenSaved.emit();
                    }, 200);
                },
                error => {
                    this.asynchronousButtonState3 = 'before';
                    this.errorAfterSubmit3 = this.utilsService.detectErrorMessages(error).join('. ');
                });
        }else{
            this.memberService.savePassword(password).subscribe(data => {
                    setTimeout(() => {
                        this.asynchronousButtonState3 = 'after';
                        this.memberHasBeenSaved.emit();
                    }, 200);
                },
                error => {
                    this.asynchronousButtonState3 = 'before';
                    this.errorAfterSubmit3 = this.utilsService.detectErrorMessages(error).join('. ');
                });
        }
    }

    endRegistration() {
        this.asynchronousButtonState3 = 'after';
        this.memberHasBeenSaved.emit();
    }

    onDocumentClick(event) {
        this.signupFormComponent.onDocumentClick(event);
    }

    isPreregister(): boolean{
        return location.pathname === '/event/wabel/register';
    }
}
