import {Component, HostBinding, Input, ViewEncapsulation} from '@angular/core';
import {coerceBooleanProperty} from "@angular/cdk/coercion";

@Component({
    selector: 'ndl-status',
    templateUrl: './status.component.html',
    styleUrls: ['./status.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        'class': 'ndl-status',
    }
})
export class NdlStatusComponent {
    @Input() color: NdlStatusColors;

    @HostBinding('class.ndl-status-small')
    @Input() set small(small) {
        this._small = coerceBooleanProperty(small);
    }
    get small(): boolean {
        return this._small;
    }
    protected _small: boolean;
}

export type NdlStatusColors = 'yellow'|'gold'|'red'|'pink'|'green'|'orange'|'dark-orange'|'grey'|'white'|'blue'|'cyan'|'dark-green'|'dark-grey'|'dark-blue'|'light-blue';
