<div class="container-fluid">
    <div class="row">
        <!-- <ndl-accordion> is also possible -->
        <div ndl-accordion class="col">
            <ndl-collapse *ngFor="let theme of themes" [theme]="theme">
                <span ndl-collapse-header>{{theme | titlecase}}</span>
                {{content}}
            </ndl-collapse>
            <ndl-collapse *ngFor="let theme of themes" [theme]="theme" outlined>
                <span ndl-collapse-header>{{theme | titlecase}} Outlined collapse</span>
                {{content}}
            </ndl-collapse>

            <ndl-collapse expandOnHover>
                <span ndl-collapse-header>Open on hover collapse</span>
                {{content}}
            </ndl-collapse>
        </div>
    </div>
</div>
