import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "relationship-status-label",
  templateUrl: "./relationship-status-label.component.html",
  styleUrls: ["./relationship-status-label.component.scss"]
})
export class RelationshipStatusLabelComponent implements OnInit {
  @Input() status: string;
  @Input() color: string;

  constructor() {}

  ngOnInit() {}
}
