// import the required animation functions from the angular animations module
import {trigger, animate, transition, style} from '@angular/animations';

export const collapseAnimation = trigger('collapseAnimation', [
    // route 'enter' transition
    transition(':enter', [

        // css styles at start of transition
        style({height: '0px'}),

        // animation and styles at end of transition
        animate('0.5s ease', style({height: '*'}))
    ]),

    // route 'leave' transition
    transition(':leave', [

        // css styles at start of transition
        style({height: '*'}),

        // animation and styles at end of transition
        animate('0.5s ease', style({height: '0px'}))
    ]),
]);