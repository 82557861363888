<div class="container-fluid">
    <div class="row">
        <ndl-card class="col-3">
            <p class="m-0" ndl-expander>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Atque possimus ratione sed. Accusantium alias aperiam deleniti
                dolore error esse ipsa iusto laborum maiores numquam provident
                similique sit veniam, voluptate voluptatibus!
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Atque possimus ratione sed. Accusantium alias aperiam deleniti
                dolore error esse ipsa iusto laborum maiores numquam provident
                similique sit veniam, voluptate voluptatibus!
            </p>
        </ndl-card>
    </div>
</div>
