import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, NgForm, Validators} from '@angular/forms';

@Component({
    selector: 'ndl-forms-demo',
    templateUrl: './forms-demo.component.html',
})
export class NdlFormsDemoComponent implements OnInit {
    @ViewChild("form", {read: NgForm}) form: NgForm;

    formControl: FormControl;
    text: string;
    textarea: string;
    password: string;
    passwordCheck: string;
    selectvalue: string;
    selectvalueMultiple: [];
    phone: string;
    upload: string;
    radioValue: string;
    checkboxValue: string[] = [];
    checkboxTreeValue: string[] = [];
    wysiwyg: string;
    address: string;
    tags = ["pipi"];

    ngOnInit() {
        this.formControl = new FormControl(this.text, [Validators.required]);
    }

    touch() {
        this.form.control.markAllAsTouched();
    }
}
