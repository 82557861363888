import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class PageErrorService {

    constructor(private router: Router, private http: HttpClient, private route: ActivatedRoute) {

    }

    private _title: string;
    private _message: string;

    get message(): string {
        return this._message;
    }

    set message(value: string) {
        this._message = value;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    clear() {
        this._message = null;
        this.title = null;
    }

    setError(title: string = null, message: string = null) {
        this.clear();
        this._title = title;
        this._message = message;
    }

    goToErrorPage(skipLocationChange: boolean = true) {
        this.router.navigateByUrl('/error', {skipLocationChange: skipLocationChange});
    }

    goToPageNotFound(skipLocationChange: boolean = true) {
        this.router.navigateByUrl('/page-not-found', {skipLocationChange: skipLocationChange});
    }

    goToPageAccessDenied(skipLocationChange: boolean = true) {
        this.router.navigateByUrl('/access-denied', {skipLocationChange: skipLocationChange});
    }

    goToHomePage(goToEventHome: boolean = false) {
        let home = 'home';
        const eventOrganizer = window.location.pathname.split('/').filter(str => str !== '' && str !== 'event' && str !== 'app');
        if ((goToEventHome || window.location.pathname.indexOf('/event/' + eventOrganizer[0] + '/login') !== -1) && eventOrganizer[0]) {
            home = '/event/' + eventOrganizer[0];
        }
        this.router.navigateByUrl(home);
    }

    goToLoginPage(skipLocationChange: boolean = true, destination: string = null) {
        let url = '/user/login';
        const eventOrganizer = destination.split('/').filter(str => str !== '' && str !== 'event' && str !== 'app');
        if (eventOrganizer[0] && destination.indexOf('/event') !== -1) {
            url = '/event/' + eventOrganizer[0] + '/login';
        }
        if (destination) {
            url = url + '?destination=' + destination;
        }
        this.router.navigateByUrl(url, {skipLocationChange: skipLocationChange});
    }

    getDebugInfo() {
        // Info that can be used to help debug
        return {
            language: navigator.language,
            platform: navigator.platform,
            userAgent: navigator.userAgent,
            screenDimensions: window.innerWidth + 'x' + window.innerHeight,
            location: location.href
        };
    }

    logErrorInDrupal(type: string, message: string, appendAdditionalInfo: boolean = false, severity: string = 'error') {
        let o = {
            message: message
        };
        if (appendAdditionalInfo) {
            o['moreInfo'] = this.getDebugInfo();
        }

        let dataToSend = {
            message: JSON.stringify(o),
            type: type,
            severity: severity
        };

        this.http.post(environment.needl_back_end_url + '/resource/utils/log', dataToSend, {
            withCredentials: true,
            headers: {
                'ngsw-bypass': 'true'
            }
        }).subscribe();
    }
}
