import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ZoomService } from "../../../services/zoom.service";
import { ZoomCallDockService } from "../../../services/zoom-call-docked.service";
import { environment } from "../../../../environments/environment";
import { VideoMeetingInvitationService } from '../../../services/wabel-client/services/video_meeting_invitation.service';
import { VideoMeetingInvitations } from '../../../services/wabel-client/entities/video_meeting_invitations';
import {TemplateService} from "../../../services/template.service";



@Component({
    selector: 'video-call-iframe',
    templateUrl: './video-call-iframe.component.html',
    styleUrls: ['./video-call-iframe.component.scss'],
    providers: [ZoomService]
})

export class VideoCallIframeComponent implements OnInit {
    @Input() videoMeetingInvitationID: number;
    @Output() closeEvent: EventEmitter<any> = new EventEmitter();

    meetingLoaded = false;
    meetingJoined = false;
    isMobile = false;
    videoMeeting: VideoMeetingInvitations;
    needl_app_full_url = environment.needl_back_end_url;
    public isShowable = false;

    constructor(
        private zoomService: ZoomService,
        private zoomCallDockService: ZoomCallDockService,
        public videoMeetingInvitationService: VideoMeetingInvitationService,
        private templateService: TemplateService
    ) { }

    ngOnInit() {

        this.videoMeetingInvitationService.getVideoMeetingInvitationsById(this.videoMeetingInvitationID).subscribe((videoMeetingInvitation) => {
            if (!videoMeetingInvitation) { return; }

            this.videoMeeting = videoMeetingInvitation;
            this.meetingLoaded = true;
        });

        // Maximize
        this.zoomCallDockService.getMaximize().subscribe(val => {

            if (val) {
                try {
                    const element = <HTMLElement>document.getElementsByClassName('cdk-overlay-container')[0];
                    element.style.display = null;
                } catch (error) { }
                this.zoomCallDockService.setShowVideoCallIframe(true);
                this.templateService.blockScroll();
            }

        });

        // Disconnect
        this.zoomCallDockService.getDisconnect().subscribe(x => {
            if (x) {
                this.closeDialoge();
            }
        });

        this.zoomCallDockService.getDock().subscribe( dock => this.isShowable = dock );

    }

    getShowVideoCallIframe() {
        return this.zoomCallDockService.getShowVideoCallIframe();
    }

    closeDialoge() {
        try {
            const element = <HTMLElement>document.getElementsByClassName('cdk-overlay-container')[0];
            element.style.display = null;
        } catch (error) { }

        this.zoomCallDockService.setDock(false);
        this.zoomCallDockService.setMaximize(false);
        this.zoomCallDockService.setDisconnect(false);
        this.zoomCallDockService.setVideoMeetingInvitationID(null);
        this.templateService.unblockScroll();
        this.closeEvent.emit();
    }

    minimize() {

        // dirty things
        try {
            const element = <HTMLElement>document.getElementsByClassName('cdk-overlay-container')[0];
            element.style.display = "none";
        } catch (error) { }
        this.zoomCallDockService.setShowVideoCallIframe(false);
        this.zoomCallDockService.setDock(true);
        this.zoomCallDockService.setMaximize(false);
        this.zoomCallDockService.setDisconnect(false);
        this.templateService.unblockScroll();
    }

    joinMeeting(directUrl: boolean = false) {

        // if (this.isMobile || directUrl) {
        //     this.zoomService.getZoomVideoMeeting(this.videoMeeting.idvideomeetinginvitation).subscribe((videoMeetingInvitation) => {
        //         window.open(videoMeetingInvitation['url'], "_blank");
        //     });
        // } else {
        //     this.videoMeetingInvitationService.currentZoomVideoMeeting = null;
        //     setTimeout(() => {
        //         this.videoMeetingInvitationService.currentZoomVideoMeeting = this.videoMeeting;
        //     }, 1);
        // }

        this.videoMeetingInvitationService.currentZoomVideoMeeting = null;
        setTimeout(() => {
            this.videoMeetingInvitationService.currentZoomVideoMeeting = this.videoMeeting;
        }, 1);

    }

    getvideoMeetingUrl() {
        // use the same provider that is used we invite colleagues.
        return `${this.needl_app_full_url}/zoom-meeting/${this.videoMeeting.idvideomeetinginvitation}?xv_show=true`;
    }

    maximizeDock() {
        this.zoomCallDockService.setMaximize(true);
    }

    disconnectCall() {
        this.zoomCallDockService.setDisconnect(true);
    }


}
