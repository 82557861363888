import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Company} from "../../../../services/wabel-client/entities/company";
import {AuthService} from "../../../../services/auth.service";
import {Product} from "../../../../services/wabel-client/entities/product";
import {Favorites} from "../../../../services/wabel-client/entities/favorites";
import {FavoriteService} from "../../../../services/wabel-client/services/favorite.service";
import {RIGHT} from "../../../../services/wabel-client/security/right.const";

@Component({
    selector: 'company-profile-favorite-button',
    templateUrl: './company-profile-favorite-button.component.html',
    styleUrls: ['./company-profile-favorite-button.component.scss']
})
export class CompanyProfileFavoriteButtonComponent implements OnInit, OnChanges {
    @Input() company: Company;
    @Input() product: Product;

    validationMessage: string = '';
    buttonState: string = 'before';
    readonly RIGHT = RIGHT;

    constructor(public authService: AuthService, private favoriteService: FavoriteService) {

    }

    ngOnInit() {
        this.refreshPage();
    }

    private refreshPage() {
        if (this.company) {
            this.validationMessage = 'You will be able to add this company in your favorites as soon as your account is validated';
            this.buttonState = this.authService.hasCompanyInFavorite(this.company.idcompany) ? 'after' : 'before';
        } else if (this.product) {
            this.validationMessage = 'You will be able to add this product in your favorites as soon as your account is validated';
            this.buttonState = this.authService.hasProductInFavorite(this.product.iduserproduct) ? 'after' : 'before';
        } else {
            this.validationMessage = '';
            this.buttonState = 'error';
        }
    }

    clickFavorite() {
        if (this.company) {
            this.toggleFavoriteCompany(this.company);
        } else if (this.product) {
            this.toggleFavoriteProduct(this.product);
        }
    }

    private toggleFavoriteCompany(company: Company) {
        if (this.buttonState === 'loading' || !this.authService.isLogged()) {
            return;
        }
        this.buttonState = 'loading';
        this.favoriteService.toggleFavoriteCompany(company.idcompany).subscribe((favorites: Favorites[]) => {
            this.authService.updateFavorite(favorites);
            this.buttonState = 'after';
            this.refreshPage();
        }, (error) => {
            console.error(error);
            this.buttonState = 'error';
        });
    }

    private toggleFavoriteProduct(product: Product) {
        if (this.buttonState === 'loading' || !this.authService.isLogged()) {
            return;
        }
        this.buttonState = 'loading';
        this.favoriteService.toggleFavoriteProduct(product.iduserproduct).subscribe((favorites: Favorites[]) => {
            this.authService.updateFavorite(favorites);
            this.buttonState = 'after';
            this.refreshPage();
        }, (error) => {
            console.error(error);
            this.buttonState = 'error';
        });
    }

    ngOnChanges() {
        this.refreshPage();
    }
}
