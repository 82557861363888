import {ContentChild, Directive} from '@angular/core';
import {NdlCustomTabHeader} from "./custom-tab-header.directive";
import {NdlCustomTabContent} from "./custom-tab-content.directive";

@Directive({
    selector: '[ndlCustomTab], [ndl-custom-tab]',
    host: {
        class: 'ndl-tab'
    },
})
export class NdlCustomTab<T = any> {
    @ContentChild(NdlCustomTabHeader) header: NdlCustomTabHeader;
    @ContentChild(NdlCustomTabContent) content: NdlCustomTabContent;
}
