<ng-container *ngIf="!closed">
    <div class="ndl-banner-prefix">
        <ng-content select="[ndl-banner-prefix]"></ng-content>
    </div>
    <div class="ndl-banner-content">
        <div class="ndl-banner-title ndl-h4">
            <ng-content select="[ndl-banner-title], ndl-banner-title"></ng-content>
        </div>
        <ng-content></ng-content>

        <div class="ndl-banner-mobile-suffix" *ngIf="isMobileView && suffix.elementRef.nativeElement.childNodes.length">
          <ng-container *ngTemplateOutlet="suffix"></ng-container>
        </div>
    </div>
    <div class="ndl-banner-suffix">
        <div *ngIf="!isMobileView">
            <ng-container *ngTemplateOutlet="suffix"></ng-container>
        </div>
    </div>
    <div class="ndl-banner-icon">
        <ng-content select="mat-icon[ndl-banner-icon]"></ng-content>
    </div>
    <button *ngIf="closable" mat-icon-button type="button" class="ndl-banner-close" (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</ng-container>

<ng-template #suffix>
    <div #suffixContent>
        <ng-content select="[ndl-banner-suffix]"></ng-content>
    </div>
</ng-template>
