import {
    AfterContentInit, Component,
    Directive, ElementRef, Input, TemplateRef, ViewChild
} from '@angular/core';

@Component({
    selector: 'ndl-error',
    template: "<ng-template><ng-content></ng-content></ng-template>"
})
export class NdlErrorComponent {
    @ViewChild(TemplateRef, {static: true}) public content: TemplateRef<any>;
}
