import {WabelEntity} from "./wabel-entity.abstract";

export class AlgoliaFilterOption extends WabelEntity {
    name: string;
    count: number;
    value: string;
    depth: number;
    options: AlgoliaFilterOption[] = [];



    constructor(obj: any) {
        super(obj);
        if (obj) {
            this.options = obj.options ? obj.options.map(opt => new AlgoliaFilterOption(opt)) : [];
        }
    }


    get id(): string {
        return this.value;
    }

    set id(id: string) {
        this.value = id;
    }
}
