import {WabelEntity} from "./wabel-entity.abstract";

export class CompanyType extends WabelEntity {

    constructor(obj: any) {
        super(obj);
        if (obj) {
            this.idcompanytype = obj.idcompanytype ? +obj.idcompanytype : null; // force id to number
        }
    }

    idcompanytype: number;
    name: string;

    get id(): number {
        return +this.idcompanytype;
    }

    set id(id: number) {
        this.idcompanytype = id;
    }
}
