import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TemplateService} from "../../../../services/template.service";

@Component({
    selector: 'kit-modal-empty',
    templateUrl: './kit-modal-empty.component.html',
    styleUrls: ['./kit-modal-empty.component.scss', '../kit-modal-simple/kit-modal-simple.component.scss']
})
export class KitModalEmptyComponent implements OnInit, OnDestroy {

    @Input() hasCloseIcon = true;
    @Input() backdropClose = true;
    @Input() isFullscreen = false;
    @Input() isMobileFullscreen = false;
    @Input() width = 800;
    @Input() closeColor = '#212529';
    goodWidth = '';

    @Output() onClose = new EventEmitter();

    constructor(private templateService: TemplateService) {
    }

    ngOnInit() {
        if (this.isFullscreen) {
            this.goodWidth = '100%';
        } else {
            this.goodWidth = this.width + 'px';
        }
        this.templateService.blockScroll();
    }

    ngOnDestroy() {
        this.templateService.unblockScroll();
    }
}
