import gql from "graphql-tag";

export class SourcingPriorityGraphql {
  static fragments = {
    sourcingPriority: gql`
      fragment sourcingPriority on SourcingPriority {           
        id
        label
        idRef
        type
      }
    `
  };

  static queries = {
    mySourcingPriorities: gql`
      query Member {
        me {
          sourcingPriorities {
            ...sourcingPriority
          }
        }
      }
      ${SourcingPriorityGraphql.fragments.sourcingPriority}
    `,
  };

  static mutations = {
    saveSourcingPriorities: gql`
      mutation SourcingPriority($sourcing_priorities: String!) {
        saveSourcingPriorities(sourcing_priorities: $sourcing_priorities) {
          sourcingPriorities {
            ...sourcingPriority
          }
        }
      }
      ${SourcingPriorityGraphql.fragments.sourcingPriority}
    `,
  };
}