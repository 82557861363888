import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CalendarEvent, CalendarEventTimesChangedEvent} from "angular-calendar";
import {addDays, endOfWeek, addMinutes} from 'date-fns';
import {fromEvent, Subscription} from "rxjs";
import {finalize, takeUntil} from "rxjs/operators";
import {EventColor, WeekViewHourSegment} from 'calendar-utils';
import * as dayjs from 'dayjs';
import * as isBetween from 'dayjs/plugin/isBetween';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import {VideoMeetingService} from "../../../../../services/wabel-client/services/video_meeting.service";
import {AuthService} from "../../../../../services/auth.service";
import {
    VideoMeetingMemberAvailability
} from "../../../../../services/wabel-client/entities/video_meeting_member_availability";

@Component({
    selector: 'app-video-meeting-availabilities',
    templateUrl: './video-meeting-availabilities.component.html',
    styleUrls: ['./video-meeting-availabilities.component.scss']
})

export class VideoMeetingAvailabilitiesComponent implements OnInit, OnDestroy {
    @Input() duration = 0;
    @Input() loading = false;
    @Input() memberAvailabilities: VideoMeetingMemberAvailability[];
    @Input() viewMode: 'own'|'counterpart' = 'own';
    @Input() bannerClosed = false;

    viewDate = new Date();
    events: CalendarEvent[] = [];
    availabilities: CalendarEvent[] = [];
    availabilities$: Subscription;
    videoMeetings: CalendarEvent[] = [];
    videoMeetings$: Subscription;
    dragToCreateActive = false;
    weekStartsOn: 1 = 1;
    ownActions = [
        {
            label: '<span class="material-icons">delete</span>',
            onClick: ({ event }: { event: CalendarEvent }): void => {
                if (!this.loading && window.confirm("Do you want to delete this availability?")) {
                    this.deleteAvailability(event);
                }
            },
        },
    ];

    finishedMeetingColor: EventColor = {
        primary: '#95a5a6',
        secondary: '#7f8c8d'
    };

    upcomingMeetingColor: EventColor = {
        primary: '#2ecc71',
        secondary: '#27ae60'
    };

    goodAvailabilityColor: EventColor = {
        primary: '#f39c12',
        secondary: '#f1c40f'
    };

    tooSmallAvailabilityColor: EventColor = {
        primary: '#d35400',
        secondary: '#e67e22'
    };

    eventSelected: CalendarEvent;
    view: 'month'|'week'|'day' = 'week';
    isAvailabilitySaving = false;
    dayjs = dayjs;

    constructor(private cdr: ChangeDetectorRef,
                public videoMeetingService: VideoMeetingService,
                private authService: AuthService) {}

    ngOnInit() {
        dayjs.extend(isBetween);
        dayjs.extend(utc);
        dayjs.extend(timezone);
        if (window.innerWidth < 700) {
            this.view = 'month';
        }
        if (this.viewMode === 'counterpart') {
            const now = dayjs();
            for (const availability of this.memberAvailabilities) {
                if ((dayjs(availability.endDate).diff(availability.startDate, 'minute') >= this.duration) && dayjs(availability.endDate).isAfter(now.add(this.duration, 'minute'))) {
                    let startDate = new Date(availability.startDate);
                    while (dayjs(startDate).isBefore(dayjs(availability.endDate))) {
                        if (now < dayjs(startDate)) {
                            this.availabilities.push({
                                id: -Math.floor(Math.random() * 100000000),
                                title: 'Availability',
                                start: this.videoMeetingService.getDateWithTimezone(startDate, dayjs.tz.guess()),
                                end: this.videoMeetingService.getDateWithTimezone(addMinutes(startDate, 30), dayjs.tz.guess()),
                                color: dayjs(addMinutes(startDate, this.duration)) <= dayjs(availability.endDate) ? this.goodAvailabilityColor : this.tooSmallAvailabilityColor,
                                meta: dayjs(addMinutes(startDate, this.duration)) <= dayjs(availability.endDate) ? 'canBeSelected' : null
                            });
                        }
                        startDate = addMinutes(startDate, 30);
                    }
                }
            }
            this.refresh();
        } else {
            this.availabilities$ = this.videoMeetingService.getMyVideoMeetingAvailabilities(true).subscribe((availabilities) => {
                for (const availability of availabilities) {
                    this.availabilities.push({
                        id: availability.id,
                        title: 'Availability' + (dayjs(availability.endDate).diff(availability.startDate, 'minute') >= this.duration ? '' : ' (Too small)'),
                        start: this.videoMeetingService.getDateWithTimezone(availability.startDate, dayjs.tz.guess()),
                        end: this.videoMeetingService.getDateWithTimezone(availability.endDate, dayjs.tz.guess()),
                        actions: this.ownActions,
                        color: dayjs(availability.endDate).diff(availability.startDate, 'minute') >= this.duration ? this.goodAvailabilityColor : this.tooSmallAvailabilityColor
                    });
                }
                this.refresh();
            });
            this.videoMeetings$ = this.videoMeetingService.getMyVideoMeetingsForAvailabilities().subscribe((videoMeetings) => {
                this.videoMeetings = [];
                for (const videoMeeting of videoMeetings) {
                    this.videoMeetings.push({
                        id: videoMeeting.id,
                        title: 'Meeting with ' + (videoMeeting.counterPartCompany?.name ? videoMeeting.counterPartCompany.name : this.authService.getMember().company.name),
                        start: this.videoMeetingService.getDateWithTimezone(videoMeeting.startDate, dayjs.tz.guess()),
                        end: this.videoMeetingService.getDateWithTimezone(videoMeeting.endDate, dayjs.tz.guess()),
                        color: dayjs(videoMeeting.endDate).isBefore(dayjs()) ? this.finishedMeetingColor : this.upcomingMeetingColor,
                        cssClass: dayjs(videoMeeting.endDate).isBefore(dayjs()) ? 'old-meeting' : 'upcoming-meeting'
                    });
                }
                this.refresh();
            });
        }

        if (this.view === 'week' || this.view === 'day') {
            setTimeout(() => this.scrollToHour(7), 0);
        }
    }

    public scrollToHour(hour: number) {
        const times = document.getElementsByClassName("cal-hour");
        if (times?.length) {
            const hourSize = times[0].clientHeight;
            const calendarDiv = document.querySelector('.cal-time-events');
            if (calendarDiv) {
                calendarDiv.scroll(0, hourSize * hour);
            }
        }
    }

    startDragToCreate(
        segment: WeekViewHourSegment,
        mouseDownEvent: MouseEvent,
        segmentElement: HTMLElement
    ) {
        if (dayjs(addMinutes(segment.date, 30)).isBefore(dayjs()) || this.loading || this.viewMode !== 'own' || this.isAvailabilitySaving) {
            return;
        }
        const dragToSelectEvent: CalendarEvent = {
            id: -Math.floor(Math.random() * 100000000),
            title: 'Availability' + (dayjs(addMinutes(segment.date, 30)).diff(segment.date, 'minute') >= this.duration ? '' : ' (Too small)'),
            start: segment.date,
            end: addMinutes(segment.date, 30),
            meta: {
                tmpEvent: true,
            },
            color: dayjs(addMinutes(segment.date, 30)).diff(segment.date, 'minute') >= this.duration ? this.goodAvailabilityColor : this.tooSmallAvailabilityColor
        };
        this.availabilities = [...this.availabilities, dragToSelectEvent];
        const segmentPosition = segmentElement.getBoundingClientRect();
        this.dragToCreateActive = true;
        const endOfView = endOfWeek(this.viewDate, {
            weekStartsOn: this.weekStartsOn,
        });

        fromEvent(document, 'mousemove')
            .pipe(
                finalize(() => {
                    delete dragToSelectEvent.meta.tmpEvent;
                    this.dragToCreateActive = false;
                    this.checkIfOverlapping(dragToSelectEvent);
                }),
                takeUntil(fromEvent(document, 'mouseup'))
            )
            .subscribe((mouseMoveEvent: MouseEvent) => {
                const minutesDiff = this.ceilToNearest(
                    mouseMoveEvent.clientY - segmentPosition.top,
                    30
                );

                const daysDiff =
                    this.floorToNearest(
                        mouseMoveEvent.clientX - segmentPosition.left,
                        segmentPosition.width
                    ) / segmentPosition.width;

                const newEnd = addDays(addMinutes(segment.date, minutesDiff), daysDiff);
                if (newEnd > segment.date && newEnd < endOfView && !this.isOverlappingMeeting(segment.date, newEnd)) {
                    dragToSelectEvent.end = newEnd;
                    dragToSelectEvent.title = 'Availability' + (dayjs(newEnd).diff(dayjs(segment.date), 'minute') >= this.duration ? '' : ' (Too small)');
                    dragToSelectEvent.color = dayjs(newEnd).diff(dayjs(segment.date), 'minute') >= this.duration ? this.goodAvailabilityColor : this.tooSmallAvailabilityColor;
                }
                this.refresh();
            });
    }

    private refresh() {
        this.availabilities = this.availabilities.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.id === value.id
            ))
        );

        this.events = [...this.availabilities, ...this.videoMeetings];
        this.cdr.detectChanges();
    }

    floorToNearest(amount: number, precision: number) {
        return Math.floor(amount / precision) * precision;
    }

    ceilToNearest(amount: number, precision: number) {
        return Math.ceil(amount / precision) * precision;
    }

    eventTimesChanged({event, newStart, newEnd}: CalendarEventTimesChangedEvent): void {
        event.start = newStart;
        event.end = newEnd;
        this.refresh();
    }

    checkIfOverlapping(event: CalendarEvent): void {
        const eventJustBefore = this.availabilities.findIndex(e => e.end.getTime() === event.start.getTime());
        const eventJustAfter = this.availabilities.findIndex(e => e.start.getTime() === event.end.getTime());
        let overlappedEvents = this.availabilities.filter(e => dayjs(event.start).isBetween(e.start, e.end, null, '()') || dayjs(event.end).isBetween(e.start, e.end, null, '()') || dayjs(e.start).isBetween(event.start, event.end, null, '()') || dayjs(e.end).isBetween(event.start, event.end, null, '()'));

        let editedEvent = null;
        if (eventJustBefore > -1 && eventJustAfter > -1) {
            this.availabilities[eventJustBefore].end = this.availabilities[eventJustAfter].end;
            this.deleteAvailability(this.availabilities[eventJustAfter]);
            this.deleteAvailability(event);
            this.editAvailability(this.availabilities[eventJustBefore]);
            editedEvent = this.availabilities[eventJustBefore];
        } else if (eventJustBefore > -1) {
            this.availabilities[eventJustBefore].end = event.end;
            this.deleteAvailability(event);
            this.editAvailability(this.availabilities[eventJustBefore]);
            editedEvent = this.availabilities[eventJustBefore];
        } else if (eventJustAfter > -1) {
            this.availabilities[eventJustAfter].start = event.start;
            this.deleteAvailability(event);
            this.editAvailability(this.availabilities[eventJustAfter]);
            editedEvent = this.availabilities[eventJustAfter];
        }

        if (overlappedEvents?.length > 0) {
            overlappedEvents = [...overlappedEvents, event];
            if (editedEvent) {
                overlappedEvents = [...overlappedEvents, editedEvent];
            }
            const firstEvent = overlappedEvents.sort((first, second) => 0 - (first.start < second.start ? 1 : -1))[0];
            const lastEvent = overlappedEvents.sort((first, second) => 0 - (first.end > second.end ? 1 : -1))[0];

            let oldFirstEvent = editedEvent;
            if (!oldFirstEvent) {
                oldFirstEvent = overlappedEvents.filter(e => e.id > -1).sort((first, second) => 0 - (first.start < second.start ? 1 : -1))[0];
            }

            const firstEventIndex = this.availabilities.findIndex(e => e.id === oldFirstEvent.id);
            this.availabilities[firstEventIndex].start = firstEvent.start;
            this.availabilities[firstEventIndex].end = lastEvent.end;

            this.editAvailability(this.availabilities[firstEventIndex]);

            for (const eventToRemove of overlappedEvents) {
                if (eventToRemove.id === oldFirstEvent.id) {
                    continue;
                }
                this.deleteAvailability(eventToRemove);
            }
        }

        if (eventJustBefore == -1 && eventJustAfter == -1 && overlappedEvents.length == 0) {
            this.addAvailability(event);
        }

        this.refresh();
    }

    addAvailability(event: CalendarEvent): void {
        if (this.isAvailabilitySaving) {
            return;
        }
        this.isAvailabilitySaving = true;
        this.videoMeetingService.addVideoMeetingAvailability(dayjs(this.videoMeetingService.getDateWithTimezone(event.start, 'Europe/Paris')).format('YYYY-MM-DD HH:mm'), dayjs(this.videoMeetingService.getDateWithTimezone(event.end, 'Europe/Paris')).format('YYYY-MM-DD HH:mm')).subscribe((availability) => {
            const index = this.availabilities.findIndex(e => e.id === event.id);
            if (index > -1) {
                this.availabilities[index] = {
                    id: availability.id,
                    title: 'Availability' + (dayjs(availability.endDate).diff(availability.startDate, 'minute') >= this.duration ? '' : ' (Too small)'),
                    start: this.videoMeetingService.getDateWithTimezone(availability.startDate, dayjs.tz.guess()),
                    end: this.videoMeetingService.getDateWithTimezone(availability.endDate, dayjs.tz.guess()),
                    actions: this.ownActions,
                    color: dayjs(availability.endDate).diff(availability.startDate, 'minute') >= this.duration ? this.goodAvailabilityColor : this.tooSmallAvailabilityColor
                };
            }
            this.refresh();
            this.isAvailabilitySaving = false;
        }, (e) => {
            console.error(e);
            this.isAvailabilitySaving = false;
        });
    }

    editAvailability(event: CalendarEvent) {
        if (this.isAvailabilitySaving) {
            return;
        }
        this.isAvailabilitySaving = true;
        this.videoMeetingService.editVideoMeetingAvailability(+event.id, dayjs(this.videoMeetingService.getDateWithTimezone(event.start, 'Europe/Paris')).format('YYYY-MM-DD HH:mm'), dayjs(this.videoMeetingService.getDateWithTimezone(event.end, 'Europe/Paris')).format('YYYY-MM-DD HH:mm')).subscribe((availability) => {
            const index = this.availabilities.findIndex(e => e.id === event.id);
            if (index > -1) {
                this.availabilities[index] = {
                    id: availability.id,
                    title: 'Availability' + (dayjs(availability.endDate).diff(availability.startDate, 'minute') >= this.duration ? '' : ' (Too small)'),
                    start: this.videoMeetingService.getDateWithTimezone(availability.startDate, dayjs.tz.guess()),
                    end: this.videoMeetingService.getDateWithTimezone(availability.endDate, dayjs.tz.guess()),
                    actions: this.ownActions,
                    color: dayjs(availability.endDate).diff(availability.startDate, 'minute') >= this.duration ? this.goodAvailabilityColor : this.tooSmallAvailabilityColor,
                };
            }
            this.refresh();
            this.isAvailabilitySaving = false;
        }, (e) => {
            console.error(e);
            this.isAvailabilitySaving = false;
        });
    }

    deleteAvailability(event: CalendarEvent): void {
        this.availabilities = this.availabilities.filter(e => e.id !== event.id);
        this.refresh();
        if (event.id < 0 || this.loading || this.isAvailabilitySaving) {
            return;
        }
        this.isAvailabilitySaving = true;
        this.videoMeetingService.deleteVideoMeetingAvailability(+event.id).subscribe((availability) => {
            this.availabilities = this.availabilities.filter(e => e.id !== availability.id);
            this.refresh();
            this.isAvailabilitySaving = false;
        }, (e) => {
            console.error(e);
            this.isAvailabilitySaving = false;
        });
    }

    eventClicked(event: { event: CalendarEvent<any>; sourceEvent: any }) {
        if (this.viewMode !== 'counterpart' || event.event.meta !== 'canBeSelected') {
            return;
        }

        for (const oldEvent of this.events.filter(e => e.color !== this.goodAvailabilityColor)) {
            oldEvent.color = oldEvent.meta === 'canBeSelected' ? this.goodAvailabilityColor : this.tooSmallAvailabilityColor;
        }
        let index = this.events.findIndex(e => e.id === event.event.id);
        this.eventSelected = this.events[index];

        let currentDuration = 0;
        while (currentDuration < this.duration) {
            this.events[index].color = this.upcomingMeetingColor;
            currentDuration += 30;
            index++;
        }
        this.eventSelected.end = this.events[index - 1].end;
    }

    private isOverlappingMeeting(startDate: Date, endDate: Date) {
        const start = dayjs(startDate);
        const end = dayjs(endDate);
        return this.videoMeetings.findIndex(vm => dayjs(vm.start).isBetween(start, end) || dayjs(vm.end).isBetween(start, end)) > -1;
    }

    dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
        this.viewDate = date;
        this.view = 'day';
    }

    ngOnDestroy() {
        if (this.availabilities$) {
            this.availabilities$.unsubscribe();
        }
        if (this.videoMeetings$) {
            this.videoMeetings$.unsubscribe();
        }
    }
}
