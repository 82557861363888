import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NdlPasswordComponent} from './password.component';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {NdlPasswordCheckComponent} from './password-check.component';
import {NeedlFormFieldModule} from '../form-field/form-field.module';
import {NeedlFormModule} from '../forms.module';


@NgModule({
    declarations: [
        NdlPasswordComponent,
        NdlPasswordCheckComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        NeedlFormFieldModule,
        NeedlFormModule
    ],
    exports: [
        NdlPasswordComponent,
        NdlPasswordCheckComponent,
    ]
})
export class NeedlPasswordModule {
}
