import {AfterViewInit, Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {FileInterface, FileStatus, NdlUploader} from './file.interface';
import {Mimes, NeedlAcceptedUploadTypes} from './mimes';
import {NdlUploadComponent} from './upload.component';
import {Subscription} from 'rxjs';

@Component({
    selector: 'ndl-upload-file',
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload.component.scss'],
    host: {
        class: `ndl-upload-file`
    }
})
export class NdlUploadFileComponent implements AfterViewInit {
    private _generatedPreview: string | ArrayBuffer;
    private _uploadSubscription: Subscription;

    @Input() for: NdlUploadComponent;
    @Input() index: number = 0;
    @Input() disabled: boolean = false;

    @Output() edit = new EventEmitter();

    get maxSizeMB(): number {
        return this.for?.maxSizeMB || 16;
    }

    get maxWidthPx(): number {
        return this.for?.maxWidthPx || 4000;
    }

    get maxHeightPx(): number {
        return this.for?.maxHeightPx || 4000;
    }

    get showAutoLimits(): boolean {
        return this.for?.showAutoLimits || false;
    }

    get file(): FileInterface {
        return this.for?.value[this.index];
    }

    get type(): NeedlAcceptedUploadTypes {
        return this.for?.type;
    }

    get autoupload(): boolean {
        return this.for?.autoupload;
    }

    get previewUrl(): string | ArrayBuffer {
        return this._generatedPreview ?? this.file?.url;
    }

    get showPreview() {
        return !this.error && this.isImage && this.previewUrl;
    }

    get fileExt() {
        return this.file?.fileExtension;
    }

    get fileSize() {
        return this.file?.fileSize;
    }

    get status() {
        return this.file?.url ? FileStatus.ONLINE : this.file?.status;
    }

    @HostBinding('class.ndl-upload-file-error')
    get error() {
        return !!this.file?.error;
    }

    get uploaded() {
        return this.status > FileStatus.UPLOADING;
    }

    get isProfile() {
        return this.type === 'profile';
    }

    get isImage() {
        return this.isProfile || this.type === 'image';
    }

    constructor(private uploader: NdlUploader) {
    }

    ngAfterViewInit() {
        if (!this.for) {
            throw new Error('Needl Lib: Displaying a NdlUploadFile component requires that you refer to the depending NdlUpload component through the \'for\' property.')
        }
        this.processFile();
    }

    checkImageDimensions(file: File, callback: (result: boolean) => any) {
        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new Image();
            img.onload = () => {
                const isValidWidth = img.width <= this.maxWidthPx;
                const isValidHeight = img.height <= this.maxHeightPx;
                callback(isValidWidth && isValidHeight);
            };
            img.src = event.target.result as string;
        };
        reader.readAsDataURL(file);
    }

    processFile() {
        if (this.file?.file) {
            if (this.file.file.size > (this.maxSizeMB * 1000000)) {
                this.file.error = new Error('Needl Lib: Size is too high! The maximum size is ' + this.maxSizeMB.toString() + 'MB.');
                this.file.error.name = 'size-too-high';
                this.updateForUploadField();
            }

            Mimes.checkFile(this.file.file, this.type, (result => {
                if (!result) {
                    this.file.error = new Error('Needl Lib: Wrong file type! Please provide a ' + this.type + '.');
                    this.file.error.name = 'wrong-type';
                    this.updateForUploadField();
                } else if (!this.file.error) {
                    if (this.isImage) {
                        this.checkImageDimensions(this.file.file, (res => {
                            if (res) {
                                this.generatePicturePreview();
                                if (this.autoupload) {
                                    this.upload();
                                }
                            } else {
                                this.file.error = new Error('Needl Lib: Dimensions are too high! The maximum dimensions are ' + this.maxWidthPx + 'x' + this.maxHeightPx + ' (px).');
                                this.file.error.name = 'dimensions-too-high';
                            }
                        }));
                    } else {
                        if (this.autoupload) {
                            this.upload();
                        }
                    }
                }
            }));
        }
    }

    updateForUploadField() {
        this.for.innerFormControl.updateValueAndValidity();
        this.for.uploadField.stateChanges.next();
    }

    upload() {
        if (this.file.status === FileStatus.PENDING) {
            this.file.status = FileStatus.UPLOADING;
            // create a fake error (we will not show it to the user)
            // to block submitting the form when the files are still uploading
            this.file.error = new Error('File still uploading');
            this.file.error.name = 'file-still-uploading';
            this.updateForUploadField();
            this._uploadSubscription = this.uploader.uploadFile(this.file.file).subscribe((newFile: FileInterface) => {
                if (newFile.success) {
                    this.file.fileName = newFile.fileName;
                    this.file.filePath = newFile.filePath;
                    this.file.fileExtension = newFile.fileExtension;
                    this.file.fileSize = newFile.fileSize;
                    this.file.status = FileStatus.ONLINE;
                    this.file.file = undefined;
                    this.updateForUploadField();
                    // delete the fake error
                    delete this.file.error;
                    this.for.uploadField.valueChange.emit(this.for.uploadField.value);
                } else {
                    this.file.status = FileStatus.PENDING;
                    this.file.error = new Error('Error received from server when uploading');
                    this.file.error.name = 'server-error';
                    this.updateForUploadField();
                }
            });
        }
    }

    generatePicturePreview() {
        const reader = new FileReader();
        reader.onload = (_event) => {
            this._generatedPreview = reader.result;
        };
        reader.readAsDataURL(this.file.file);
    }

    delete() {
        this.for.delete(this.index);
        this.for.innerFormControl.markAsTouched();
        this.for.innerFormControl.updateValueAndValidity();
        this.for.uploadField.stateChanges.next();
    }

    ngOnDestroy() {
        if (this._uploadSubscription) {
            this._uploadSubscription.unsubscribe();
        }
    }
}
