import {Component, ContentChildren, Input, OnInit, QueryList} from '@angular/core';
import {NdlSlideComponent} from './slide.component';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {CdkDrag, CdkDragMove, CdkDragRelease} from '@angular/cdk/drag-drop';

@Component({
    selector: 'ndl-carousel-new',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss'],
    host: {
        class: 'ndl-carousel'
    }
})
export class NdlCarouselNewComponent {
    @Input() selectedIndex = 0;
    @Input() arrowPosition: 'inside' | 'bottom' = 'inside';

    @Input() get disableDragging() {
        return this._disableDragging;
    }
    set disableDragging(disableDragging) {
        this._disableDragging = coerceBooleanProperty(disableDragging);
    }
    _disableDragging = false;

    @ContentChildren(NdlSlideComponent, {descendants: true}) slides: QueryList<NdlSlideComponent>;

    previous() {
        if (this.selectedIndex > 0) {
            this.selectedIndex--;
        }
    }

    next() {
        if (this.slides && this.slides.length && this.selectedIndex < this.slides.length - 1) {
            this.selectedIndex++;
        }
    }

    slideReleased(event: CdkDragRelease) {
        const width = event.source.element.nativeElement.offsetWidth;
        const transform = event.source.element.nativeElement.style.transform;
        const translateX = +transform.substring(transform.indexOf('(') + 1, transform.indexOf('p'));
        const offset = translateX / width;

        if (offset > 0.25 && this.selectedIndex !== 0) {
            this.previous();
            event.source.element.nativeElement.classList.add("ndl-slide-position-right");
        } else if (offset < -0.25 && this.selectedIndex !== this.slides.length - 1) {
            this.next();
            event.source.element.nativeElement.classList.add("ndl-slide-position-left");
        }
        event.source.reset();
    }
}
