import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {NdlSelectOption} from "../../NdlSelectOption";

@Component({
    selector: 'ndl-multi-select-callback',
    templateUrl: './multi-select-callback.component.html',
    styleUrls: ['./multi-select-callback.component.scss']
})

export class NdlMultiSelectCallbackComponent implements OnInit {
    @Input() placeholder = 'Add';
    @Input() inputListTitle = 'Suggestions';
    @Input() options: NdlSelectOption[] = [];
    @Input() selectedOptions: NdlSelectOption[] = [];
    @Input() showError = false;
    @Input() errorText = "This field is required";
    @Input() selectionLimit = 100;

    @Output() listChanged = new EventEmitter<NdlSelectOption[]>();
    @Output() valueChanged = new EventEmitter<string>();

    isListOpened = false;
    clickWasInsideComponent = false;
    value: string;
    showErrorTooManySelectionsAfterClick = false;

    constructor() {
    }

    ngOnInit(): void {
    }

    @HostListener("click", ["$event"])
    clickInsideComponent(e) {
        this.clickWasInsideComponent = true;
        this.isListOpened = true;
    }

    @HostListener("document:click", ["$event"])
    clickOutsideComponent() {
        if (!this.clickWasInsideComponent) {
            this.isListOpened = false;
        }
        this.clickWasInsideComponent = false;
    }

    selectOption(option: NdlSelectOption) {
        if (!this.selectedOptions.find(opt => opt === option)) {
            this.selectedOptions.push(option);
            this.listChanged.emit(this.selectedOptions);
        }
    }

    selectOptionAsCustomStringValue(option: string) {
        const stringToNdlOption = {value: null, label: option, children: []};
        if (!this.selectedOptions.find(opt => opt.label === stringToNdlOption.label)) {
            this.selectedOptions.push(stringToNdlOption);
            this.reset();
        }
    }

    removeOption(option: NdlSelectOption) {
        if (option.value) {
            this.selectedOptions = this.selectedOptions.filter(opt => opt.value !== option.value);
        } else {
            this.selectedOptions = this.selectedOptions.filter(opt => opt.label !== option.label);
        }
        this.listChanged.emit(this.selectedOptions);
    }

    clearList() {
        this.selectedOptions = [];
        this.value = null;
        this.listChanged.emit(this.selectedOptions);
    }

    reset() {
        this.value = null;
        this.isListOpened = false;
        this.listChanged.emit(this.selectedOptions);
    }

    checkSelectionsLimit($event) {
        this.showErrorTooManySelectionsAfterClick = $event.target['className'].includes('disabled');
        if (!this.showErrorTooManySelectionsAfterClick) {
            this.isListOpened = true;
        }
    }
}
