import {Injectable} from "@angular/core";
import {WabelService} from "./wabel-service.abstract";
import {GraphQLService} from "./graphql-service";
import {LoadingService} from "./loading.service";

import { OAuthGraphQL } from "../graphql/oauth.graphql";
import { MemberThirdPartyOAuthReffrence } from "../entities/member_third_party_oauth_reffrence";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class OAuthService extends WabelService {

    constructor(protected graphQLService: GraphQLService, protected loadingService: LoadingService) {
        super(graphQLService, loadingService);
    }

    toArrayOfObjects(objects: any[]) {
        return objects.map( x => new MemberThirdPartyOAuthReffrence(x) )
    }

    toObject(object: any) {
        return new MemberThirdPartyOAuthReffrence( object )
    }

    decodeItems(object: any) {
        return JSON.parse( object )
    }

    getOAuthConsentScreen( oauthProvider: string , fetchPolicy: string = 'network-only') {
        return this.query(OAuthGraphQL.queries.getOAuthConsentScreen, { oauthProvider }, fetchPolicy)
                .pipe(map((data) => data.data && data.data.oAuthConsentScreen ? data.data.oAuthConsentScreen : null));
    }

    fetchAttachedCalendars( sync?: boolean , fetchPolicy: string = 'network-only') {
        return this.query(OAuthGraphQL.queries.fetchAttachedCalendars, { sync } , fetchPolicy)
                .pipe(map((data) => data.data && data.data.fetchAttachedCalendars ? this.toArrayOfObjects( data.data.fetchAttachedCalendars ) : [] ));
    }

    fetchFreeBusySlotFromCalendars( startTime: string , endTime: string , timezone: string , idmember?: number , fetchPolicy: string = 'network-only') {
        return this.query(OAuthGraphQL.queries.fetchFreeBusySlotFromCalendars, { startTime , endTime , timezone , idmember } , fetchPolicy)
                .pipe(map((data) => data.data && data.data.fetchFreeBusySlotFromCalendars ? this.decodeItems( data.data.fetchFreeBusySlotFromCalendars ) : null ));
    }

    updatePrimaryCalendar( provider: string , calendarId: string ) {
        return this.mutation(OAuthGraphQL.mutations.updatePrimaryCalendar, { provider , calendarId })
                .pipe(map((data) => data.data && data.data.updatePrimaryCalendar ? data.data.updatePrimaryCalendar : null ));
    }

    revokeThirdPartyOauthFromNeedl( provider: string ) {
        return this.mutation(OAuthGraphQL.mutations.revokeThirdPartyOauthFromNeedl, { provider })
                .pipe(map((data) => data.data && data.data.revokeThirdPartyOauthFromNeedl ? data.data.revokeThirdPartyOauthFromNeedl : null ));
    }

}
