export enum CompanyTypes {
    // Names
    ADMIN = "admin",
    MANUFACTURER = "manufacturer",
    IMPORTER = "importer",
    SERVICE_PROVIDER = "service_provider",
    FOOD_SERVICE = "food_service",
    RETAIL_GROUP = "retail_group",
    BUYING_OFFICE = "buying_office",
    BUYING_ALLIANCE = "buying_alliance",
    BANNER = "banner",
    AGENT = "agent",
    LOGISTICS = "logistics",
    CO_PACKING = "co_packer",
    EXPERT = "expert",
    FEDERATION = "federation",
    PRESS = "press",
    Packaging_supplier = "packaging",
    // IDs
    MANUFACTURER_ID = 2,
    IMPORTER_ID = 3,
    SERVICE_PROVIDER_ID = 4,
    FOOD_SERVICE_ID = 5,
    RETAIL_GROUP_ID = 6,
    BUYING_OFFICE_ID = 7,
    BUYING_ALLIANCE_ID = 8,
    BANNER_ID = 9,
    AGENT_ID = 10,
    LOGISTICS_ID = 11,
    CO_PACKING_ID = 13,
    EXPERT_ID = 14,
    FEDERATION_ID = 15,
    PRESS_ID = 16,
    PACKAGING_ID = 18,
}
