import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NdlModalDirective} from './modal.directive';
import {NdlModalHeaderDirective} from './modal-header.directive';
import {NdlModalContentDirective} from "./modal-content.directive";
import {NdlModalTemplateComponent} from './modal-template.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {NdlModalCloseDirective} from './modal-close.directive';
import {NdlModalActionsDirective} from "./modal-actions.directive";


@NgModule({
    declarations: [
        NdlModalDirective,
        NdlModalHeaderDirective,
        NdlModalContentDirective,
        NdlModalCloseDirective,
        NdlModalContentDirective,
        NdlModalTemplateComponent,
        NdlModalActionsDirective
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule
    ],
    exports: [
        NdlModalDirective,
        NdlModalContentDirective,
        NdlModalCloseDirective,
        NdlModalActionsDirective,
        NdlModalHeaderDirective
    ]
})
export class NeedlModalsModule {
}
