import { WabelEntity } from "./wabel-entity.abstract";
import { File } from "./file";
import { Tag } from "./tag";
import { Company } from "./company";

export class Institution extends WabelEntity {
    constructor(obj: any) {
        super(obj);

        this.logo = obj.logo ? new File(obj.logo) : null;
        this.backgroundLogo = obj.backgroundLogo ? new File(obj.backgroundLogo) : null;
        this.tag = obj.tag ? new Tag(obj.tag) : null;
        this.companies = obj.tag && obj.tag.companyTags && obj.tag.companyTags.items ? obj.tag.companyTags.items.map(c => new Company(c.company)) : [];
        this.images = obj.images ? obj.images.map(f => new File(f)) : [];
        this.coverImg = obj.coverImg ? new File(obj.coverImg) : null;
    }

    idinstitution: number;
    urlname: string;
    name: string;
    description: string;
    logo: File;
    backgroundLogo: File;
    tag: Tag;
    companies: Company[];
    logoPreviewLink: string;
    images: File[];
    videoLink: string;
    coverImg: File;
    get id(): number {
        return this.idinstitution;
    }

    set id(id: number) {
        this.idinstitution = id;
    }

    randomizeCompanies() {
        const _companies = this.companies;
        for (let i = _companies.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [_companies[i], _companies[j]] = [_companies[j], _companies[i]];
        }
        return _companies;
    }

}
