import {
    Component, ElementRef,
    EventEmitter,
    forwardRef, HostListener,
    Input,
    OnChanges,
    Output,
    SimpleChanges, ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subject} from "rxjs";
import {NgSelectComponent} from "@ng-select/ng-select";
import {Company} from "../../../../services/wabel-client/entities/company";

@Component({
    selector: 'kit-form-select',
    templateUrl: './kit-form-select.component.html',
    styleUrls: ['./kit-form-select.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => KitFormSelectComponent),
        multi: true
    }]
})
export class KitFormSelectComponent implements ControlValueAccessor, OnChanges {

    @Input() placeholder: string;
    @Input() label: string;
    @Input() footerLabel: string;
    @Input() bindValue: string = 'id';
    @Input() bindLabel: string = 'name';

    @Input() isRequired: boolean = false;
    @Input() showError: boolean = false;
    @Input() doCheck: boolean = false;
    @Input() hideSelected: boolean = false;
    @Input() closeOnSelect: boolean = true;
    @Input() inputSearch = new Subject<string>();
    @Input() searchLoading: boolean = false;
    @Input() defaultSelectedNoOption: any;
    @Input() otherFooterOpen: boolean = false;

    @Input() searchable: boolean = true;
    @Input() clearable: boolean = true;
    @Input() disabled:boolean = false;
    @Input() minTermLength = 0;
    @Input() forDynamicList = false;

    @Input() options = [
        {id: 1, name: "Default"},
        {id: 2, name: 'Not Default', disabled: true},
    ];

    @Input() optionSelected;
    @Input() defaultSelectedOption:Company = null;
    propagateChange: any = () => {
    };

    @Output() selectedElementHasChanged: EventEmitter<any> = new EventEmitter();

    @ViewChild("ngSelectElement", { static: true }) ngSelectElement: NgSelectComponent;
    @Input() cyName: string;
    isOpen = false;

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(value: any): void {
        if (value) {
            this.optionSelected = value;
        }
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (!this.forDynamicList) {
            return;
        }
        if(this.eRef.nativeElement.contains(event.target)) {
        } else {
            this.isOpen = false;
            if (!this.optionSelected) {
                this.ngSelectElement.filter('');
            }
        }
    }

    constructor(private eRef: ElementRef) {
    }

    onChange() {
        this.selectedElementHasChanged.emit(this.optionSelected);
        this.propagateChange(this.optionSelected);
        if (this.defaultSelectedNoOption && this.options.length > 1 && this.optionSelected != -1) {
            this.options = this.options.filter(item => item !== this.defaultSelectedNoOption);
        }
    }


    ngOnChanges(changes: SimpleChanges): void {
        if (changes.doCheck && changes.doCheck.currentValue)
            this.checkError();
    }


    checkError() {
        this.showError = this.isRequired && this.optionSelected == null;
    }
    ngOnInit(){
        if (this.defaultSelectedOption != null){
            this.injectDefaultSelectedOption();
        }
    }
    injectDefaultSelectedOption() {
        this.options = this.options.filter(item => item !== this.defaultSelectedOption);
        this.options = [...this.options, this.defaultSelectedOption];
        this.optionSelected = this.defaultSelectedOption.id;
        this.selectedElementHasChanged.emit(this.optionSelected);
        this.propagateChange(this.optionSelected);
    }

    injectDefaultNoOption() {
        this.isOpen = false;
        this.ngSelectElement.close();
        this.ngSelectElement.filter('');
        this.options = this.options.filter(item => item !== this.defaultSelectedNoOption);
        this.options = [...this.options, this.defaultSelectedNoOption];
        this.optionSelected = this.defaultSelectedNoOption.id;
        this.onChange();
    }

    openSelectElement() {
        this.ngSelectElement.open();
    }

    closeOtherFooter() {
        this.otherFooterOpen = false;
    }
}
