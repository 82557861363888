<mat-tab-group [selectedIndex]="selectedIndex" (selectedTabChange)="tabChanged.emit($event)">
    <mat-tab *ngFor="let tab of lazyLoadedTabs; let i = index;" [label]="tab.label" [disabled]="disabledIndex.indexOf(i) !== -1">
        <ng-template matTabContent>
            <ng-container [ngTemplateOutlet]="tab.content"></ng-container>
        </ng-template>
    </mat-tab>
    <mat-tab *ngFor="let tab of tabs; let i = index;" [label]="tab.label" [disabled]="disabledIndex.indexOf(i) !== -1">
        <ng-template *ngTemplateOutlet="tab.content; context: {index: i}"></ng-template>
    </mat-tab>
    <mat-tab *ngFor="let tab of customTabs; let i = index;" [disabled]="disabledIndex.indexOf(i) !== -1">
        <ng-template mat-tab-label>
            <ng-template [ngTemplateOutlet]="tab.header.content"></ng-template>
        </ng-template>
        <ng-template [ngTemplateOutlet]="tab.content.content"></ng-template>
    </mat-tab>
</mat-tab-group>
