import {WabelEntity} from "./wabel-entity.abstract";
import {EventExpert} from "./event_expert";
import {Member} from "./member";
import {Video} from "./video";

export class EventWorkshop extends WabelEntity {
    constructor(obj: any) {
        super(obj);
        if (obj) {
            this.member = obj.member ? new Member(obj.member) : null;
            this.expert = obj.expert ? new EventExpert(obj.expert) : null;
            this.video = obj.video ? new Video(obj.video) : null;
        }
    }

    ideventworkshop: number;
    expert: EventExpert;
    description: string;
    startDate: string;
    endDate: string;
    title: string;
    displayInSelectionTool: boolean;
    forceDisplayInAgenda: boolean;
    member: Member;
    video: Video;
    conferenceType: string;
    idZoomWebinar: string;
    externalUrl: string;

    get id(): number {
        return this.ideventworkshop;
    }

    set id(id: number) {
        this.ideventworkshop = id;
    }

    get startDatetime() {
        return this.startDate ? new Date(this.startDate) : null;
    }

    get endDatetime() {
        return this.endDate ? new Date(this.endDate) : null;
    }

    get formattedStartDate() {
        return this.startDatetime?.toLocaleDateString();
    }

    get duration() {
        return this.startDatetime && this.endDatetime ? Math.abs(this.startDatetime.getTime() - this.endDatetime.getTime()) : 0;
    }
}
