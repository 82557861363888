<div class="ndl-table-filter">
    <ng-content select="[ndlListFilter],[ndlTableFilter]"></ng-content>
</div>

<div *ngIf='_selection.selected.length && actions.length' class="ndl-list-actions">
    <ng-content select="[ndl-list-action],[ndl-table-action]"></ng-content>
    <button mat-icon-button color="primary" type="button" class="ndl-actions-clear" (click)="_selection.clear()">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div class="ndl-table-container" [class.ndl-table-configure]="enableColumnConfiguration">

    <!-- Column Settings -->
    <div *ngIf="enableColumnConfiguration" class="ndl-table-configure-button">
        <button mat-icon-button [matMenuTriggerFor]="tableConfig">
            <mat-icon fontSet="material-icons-outlined">settings</mat-icon>
        </button>
        <mat-menu #tableConfig='matMenu' classList="ndl-scrollbar">
            <div class="ndl-table-configure-content" (click)="$event.stopPropagation();">
                <div class="ndl-table-configure-content-head">
                    <h4>Column Settings</h4>

                    <div class="ndl-form-field">
                        <mat-form-field appearance="fill">
                            <mat-icon color="primary" matPrefix class="ndl-prefix">search</mat-icon>
                            <input type="text" matInput placeholder="Search column label" (keyup)="columnSearch.next($event.target.value)">
                        </mat-form-field>
                    </div>

                    <button class="ndl-table-toggle-all-columns" mat-button color="primary" (click)="toggleAllColumns()">{{allColumsDisplayed ? 'Hide all' : 'Show all'}}</button>
                </div>
                <mat-action-list>
                    <button mat-list-item *ngFor="let column of columnSearchResult$ | async" (click)="toggleColumn(column)">
                        {{column}}
                        <mat-icon *ngIf="isColumnDisplayed(column); else notVisible" class="ndl-column-visibility">visibility</mat-icon>
                        <ng-template #notVisible>
                            <mat-icon fontSet="material-icons-outlined" class="ndl-column-visibility">visibility_off</mat-icon>
                        </ng-template>
                    </button>
                </mat-action-list>
            </div>
        </mat-menu>
    </div>

    <div class="ndl-table-content ndl-scrollbar">
        <table #table cdk-table [ngClass]="listClass" [dataSource]="dataSource" cdkDropList (cdkDropListSorted)="dragged.emit($event)" (cdkDropListDropped)="drop($event)"
               [cdkDropListDisabled]="!dragDrop">

            <!-- Action Checkboxes -->
            <ng-container *ngIf="actions.length" cdkColumnDef="select" sticky>
                <th cdk-header-cell *cdkHeaderCellDef title="select" class="ndl-table-header ndl-table-select-all">
                    <div>
                        <div class="ndl-table-header-container">
                            <mat-checkbox disableRipple color="primary" [checked]="isAllSelected()" [indeterminate]="!_selection.isEmpty() && !isAllSelected()" (change)="toggleAll()"></mat-checkbox>
                        </div>
                    </div>
                </th>

                <td class="ndl-table-cell ndl-table-select" cdk-cell *cdkCellDef="let row; let i = index">
                    <div>
                        <div class="ndl-table-cell-container">
                            <mat-checkbox disableRipple color="primary" [checked]="isSelected(i)" (change)="toggle(i)"></mat-checkbox>
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container *ngFor="let column of columns; let i = index" [cdkColumnDef]="column.name" [sticky]="column.sticky" [stickyEnd]="column.stickyEnd">
                <!-- Header Denition -->
                <th cdk-header-cell *cdkHeaderCellDef title="{{(column.title ? column.title : '')}}" class="ndl-table-header" [style.transform]="column.sticky ? fixColumnOffset : null">
                    <div>
                        <div class="ndl-table-header-container" [class.sort-active]="sortColumn ===  column.sort">
                            <div class="ndl-table-header-content">
                                <ng-container [ngTemplateOutlet]="column.header || columnName"></ng-container>

                                <!-- Sort button -->
                                <button *ngIf="column.sort" class="ndl-table-header-sort" mat-icon-button (click)="sort(column.sort)">
                                    <mat-icon inline>
                                        {{ sortColumn === column.sort && sortOrder === 'desc' ? 'arrow_downward' : 'arrow_upward' }}
                                    </mat-icon>
                                </button>
                            </div>

                            <!-- Hide button -->
                            <button *ngIf="enableColumnConfiguration" class="ndl-table-header-hide" mat-icon-button (click)="toggleColumn(column.name)">
                                <mat-icon inline fontSet="material-icons-outlined">visibility_off</mat-icon>
                            </button>
                        </div>
                    </div>
                </th>

                <!-- Column definition -->
                <td class="ndl-table-cell" cdk-cell *cdkCellDef="let row; let i = index" [style.transform]="column.sticky ? fixColumnOffset : null">
                    <div>
                        <div class="ndl-table-cell-container">
                            <ng-container *ngTemplateOutlet="column.content; context: {$implicit: row, index: i}"></ng-container>
                        </div>
                    </div>
                </td>

                <ng-template #columnName>
                    {{ column.name }}
                </ng-template>
            </ng-container>

            <!-- Header Row Template -->
            <tr cdk-header-row class='ndl-table-header-row' *cdkHeaderRowDef="displayedColumns; sticky: stickyHeader"></tr>

            <!-- Row Template -->
            <tr cdk-row class='ndl-table-row' cdkDrag *cdkRowDef="let row; columns: displayedColumns; let i = index"></tr>
        </table>
    </div>
</div>


<ng-content></ng-content>
