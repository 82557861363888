import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'kit-form-radio-inline',
    templateUrl: './kit-form-radio-inline.component.html',
    styleUrls: ['./kit-form-radio-inline.component.scss']
})
export class KitFormRadioInlineComponent implements OnInit {

    @Input() id: string;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() helper: string;
    @Input() nbPerLine: number = 2;
    @Input() colClass = 'col-lg';
    // TODO: just for now to apply good design before migrating to needl lib components
    @Input() useGoodMobileDesign = false;

    @Input() binding: string;
    @Output() bindingChange = new EventEmitter();

    @Input() type: string = 'text';
    @Input() isRequired: boolean = false;
    @Input() hasError = false;

    @Input() options = [
        {value: 'mr', label: 'Mr'},
        {value: 'mrs', label: 'Mrs'},
        {value: 'ms', label: 'Ms'},
    ];

    @Input() unique: boolean = true;
    @Input() disabled = false;

    @Input() formName: string = "form";
    @Input() errorMessage: string = 'This field is required';


    change(newValue) {
        this.binding = newValue;
        this.bindingChange.emit(newValue);
    }

    constructor() {
    }

    ngOnInit() {
    }


    getNbCol(): number {
        if (this.nbPerLine == 1) return 12;
        if (this.nbPerLine == 2) return 6;
        if (this.nbPerLine == 3) return 4;
        if (this.nbPerLine == 4) return 3;

        return 6;
    }

}
