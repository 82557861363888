import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'kit-form-star-rating',
  templateUrl: './kit-form-star-rating.component.html',
  styleUrls: ['./kit-form-star-rating.component.scss']
})
export class KitFormStarRatingComponent implements OnInit {

    @Input() numberOfStars = 5;
    @Input() currentRating: number;
    @Output() ratingChange = new EventEmitter<number>();
    currentStarHovered = 0;
    arrayOfStars = [];

  constructor() { }

  ngOnInit() {
      for (let i = 1; i <= this.numberOfStars; i++) {
          this.arrayOfStars.push(i);
      }
  }

  rate(rating: number) {
      this.currentRating = rating;
      this.ratingChange.emit(rating);
  }
}
