import { Component, OnInit } from '@angular/core';
import {PageErrorService} from "../../../services/page-error.service";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import {filter} from "rxjs/operators";

@Component({
    selector: 'page-error',
    templateUrl: './page-error.component.html',
    styleUrls: ['./page-error.component.scss']
})
export class PageErrorComponent implements OnInit {

    private status: string;

    constructor(
        public pageErrorService: PageErrorService,
        private route: ActivatedRoute,
        private location: Location) {
    }

    ngOnInit() {
        this.route.queryParams
            .pipe(filter(params => params.status))
            .subscribe(params => {
                this.status = params.status;
                this.pageErrorService.message = params.status;
                this.clearErrorParams();
            });
    }

    clearErrorParams() {
        this.location.replaceState('error');
      }

}
