import {Component, ContentChild, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {NdlStatus} from '../core/types';
import {NdlBannerIconDirective} from './banner-icon.directive';
import {BreakpointObserver} from '@angular/cdk/layout';
import {NdlBreakpoints} from '../layout/breakpoints';

@Component({
    selector: 'ndl-banner',
    templateUrl: './banner.component.html',
    host: {
        class: "ndl-banner"
    }
})

export class NdlBannerComponent {
    @Input() get theme() {
        return this._theme;
    }

    set theme(theme) {
        this._theme = theme;
    }

    private _theme: NdlStatus = "default";

    @HostBinding('class.ndl-banner-closable')
    @Input() get closable() {
        return this._closable;
    }

    set closable(closable) {
        this._closable = coerceBooleanProperty(closable);
    }

    private _closable: boolean;

    @HostBinding('class.ndl-banner-outlined')
    @Input() get outlined() {
        return this._outlined;
    }

    set outlined(outlined) {
        this._outlined = coerceBooleanProperty(outlined);
    }

    private _outlined: boolean;

    @HostBinding('class.ndl-banner-radius')
    @Input() get radius() {
        return this._radius;
    }

    set radius(radius) {
        this._radius = coerceBooleanProperty(radius);
    }

    private _radius: boolean;

    @Output() onClose = new EventEmitter();

    @HostBinding('class.ndl-banner-closed') closed = false;

    @HostBinding('class.ndl-banner-has-icon')
    private get _hasIcon() {
        return !!this.icon && !this.isMobileView;
    }

    @HostBinding('class.ndl-banner-default')
    private get _isDefault() {
        return this._theme === "default";
    }

    @HostBinding('class.ndl-banner-primary')
    private get _isPrimary() {
        return this._theme === "primary";
    }

    @HostBinding('class.ndl-banner-secondary')
    private get _isSecondary() {
        return this._theme === "secondary";
    }

    @HostBinding('class.ndl-banner-info')
    private get _isInfo() {
        return this._theme === "info";
    }

    @HostBinding('class.ndl-banner-success')
    private get _isSuccess() {
        return this._theme === "success";
    }

    @HostBinding('class.ndl-banner-warning')
    private get _isWarning() {
        return this._theme === "warning";
    }

    @HostBinding('class.ndl-banner-danger')
    private get _isDanger() {
        return this._theme === "danger";
    }

    @HostBinding('class.ndl-banner-neutral')
    private get _isNeutral() {
        return this._theme === "neutral";
    }

    @HostBinding('class.ndl-banner-mobile')
    get isMobileView() {
        return this.breakpointOberserver.isMatched(NdlBreakpoints.Handset);
    }

    @ContentChild(NdlBannerIconDirective) icon: NdlBannerIconDirective;

    constructor(private breakpointOberserver: BreakpointObserver) {}

    close() {
        this.closed = true;
        this.onClose.emit(this.closed);
    }
}
