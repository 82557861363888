import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PrivateMessagesService} from "../../../services/private-messages.service";
import {Observable, Subscription} from "rxjs";
import {PredefinedMessagesTopics} from "../../../services/wabel-client/entities/predefined_messages_topics";
import {Company} from "../../../services/wabel-client/entities/company";
import {AuthService} from "../../../services/auth.service";
import {collapseAnimation} from "../../../animations/collapse.animation";
import {fadeAnimation} from "../../../animations/fade.animation";
import {UtilsService} from "../../../services/utils.service";
import {ActivatedRoute} from "@angular/router";
import {NdlSnackbarComponent} from 'needl-lib';

@Component({
    selector: 'private-messages-popup',
    templateUrl: './private-messages-popup.component.html',
    styleUrls: ['./private-messages-popup.component.scss'],
    animations: [collapseAnimation, fadeAnimation]
})
export class PrivateMessagesPopupComponent implements OnInit, OnDestroy {

    @Input()
    companyUpdated: Observable<void>;
    @Input()
    canUpdateCompany: Observable<void>;
    @Input()
    expandPopupAction: Observable<void>;

    protected company: Company[] = [];
    protected reduced: boolean = false;
    protected companyUpdatedSubscription: any;
    protected canUpdateCompanySubscription: any;
    protected expandPopupSubscription: any;
    protected predefinedSubjects: PredefinedMessagesTopics[] = [];
    protected selectedSubject: string = 'General contact';
    protected subject: string = '';
    protected content: string = '';
    protected errorMessage: Object = {
        title: '',
        message: '',
    };

    modalSubjectOpen: boolean = false;
    currentContent: string = '';
    modalDraftOpen: boolean = false;
    draftCompanyName: string = '';
    lastInsertedThread: number = null;

    @ViewChild(NdlSnackbarComponent, {static: true}) snackbar: NdlSnackbarComponent;

    constructor(public privateMessagesService: PrivateMessagesService, private authService: AuthService, public utilsService: UtilsService, private route: ActivatedRoute) {
        this.privateMessagesService.predefinedChanged.asObservable().subscribe((data) => {
            this.subject = data.title;
            this.content = data.content;
        });
        this.privateMessagesService.predefinedSubjectsChanged.asObservable().subscribe((data) => {
            this.updatePredefinedMessagesTopics();
        });
    }

    ngOnInit() {

        this.resetForm();
        this.companyUpdatedSubscription = this.companyUpdated.subscribe(() => {
            this.resetForm();
        });
        this.expandPopupSubscription = this.expandPopupAction.subscribe(() => {
            this.expandPopup();

        });
        this.canUpdateCompanySubscription = this.canUpdateCompany.subscribe(() => {
            if (this.content) {
                this.modalDraftOpen = true;
            } else {
                this.expandPopup();
                this.privateMessagesService.updateCompany();
            }
        });
    }

    resetForm() {
        this.company = [];
        this.company = this.privateMessagesService.getCompany();

        this.content = '';
        this.selectedSubject = 'General contact';
        this.resetErrorMessage();
        this.updatePredefinedMessagesTopics();
        if (this.authService.isLogged()) {
            this.subjectChanged(null);
        } else {
            this.subject = 'Contact on needl.';
        }
    }

    ngOnDestroy() {
        if (this.companyUpdatedSubscription) {
            this.companyUpdatedSubscription.unsubscribe();
        }
        if (this.canUpdateCompanySubscription) {
            this.canUpdateCompanySubscription.unsubscribe();
        }
        if (this.expandPopupSubscription) {
            this.expandPopupSubscription.unsubscribe();
        }
    }

    updatePredefinedMessagesTopics() {
        this.predefinedSubjects = [
            new PredefinedMessagesTopics({title: 'General contact', content: ''}),
        ];
        for (let i = 0; i < this.privateMessagesService.getPredefinedMessagesTopics().length; i++) {
            this.predefinedSubjects.push(this.privateMessagesService.getPredefinedMessagesTopics()[i]);
        }
    }

    isLoading(): boolean {
        return this.privateMessagesService.isCompanyLoading() || !this.company || this.company.length === 0;
    }

    reducePopup() {
        this.resetErrorMessage();
        this.reduced = true;
    }

    expandPopup() {
        this.resetErrorMessage();
        this.reduced = false;
    }

    checkAndClosePopup() {
        this.privateMessagesService.closePopup();
    }

    subjectChanged(event: PredefinedMessagesTopics) {
        let previousTopics = this.predefinedSubjects.filter((t: PredefinedMessagesTopics) => this.subject === t.title);
        let previousTopic = this.predefinedSubjects[0];

        if (previousTopics.length) {
            previousTopic = previousTopics[0];
        }

        if (event === null || event.title === 'General contact') {
            if (this.company.length === 1) {
                this.subject = this.authService.getMember().company.name.toUpperCase() + ' // ' + this.company[0].name.toUpperCase() + ' - Contact on needl.';
            } else {
                this.subject = this.authService.getMember().company.name.toUpperCase() + '  - Contact on needl.';
            }

            this.checkAndChangeContent('', previousTopic.content);
        } else {
            this.subject = event.title;
            this.checkAndChangeContent(event.content, previousTopic.content);
        }
        this.resetErrorMessage();
    }

    private checkAndChangeContent(content: string, previousContent: string) {
        this.currentContent = content;
        if (this.content !== content && this.content !== '' && this.content !== previousContent) {
            this.modalSubjectOpen = true;
        } else {
            this.content = this.utilsService.decodeHTMLEntities(content);
        }
    }

    changeContent(content: string) {
        this.modalSubjectOpen = false;
        this.content = this.utilsService.decodeHTMLEntities(content);
    }

    private checkForm(): boolean {
        this.resetErrorMessage();
        if (this.content === '') {
            this.errorMessage = {
                title: 'Message missing',
                message: 'Please enter a message'
            };
            return false;
        }
        if (this.content.length < 30) {
            this.errorMessage = {
                title: 'Message too short',
                message: 'Your message can\'t be less than 30 characters'
            };
            return false;
        }
        return true;
    }

    checkAndSendMessage() {
        if (this.privateMessagesService.isCompanyLoading() || this.privateMessagesService.sendingMessageState() === 'loading') {
            return false;
        }
        let formValid = this.checkForm();
        if (!formValid) {
            return false;
        }
        let code = this.privateMessagesService.sendMessageToCompany(this.subject, this.content, (id) => {
            this.lastInsertedThread = id;
            this.snackbar.open();
        });
        if (code !== 'message_sending') {
            this.errorMessage = {
                title: 'Unknown error when sending message',
                message: code
            };
        }
    }

    calcMaxHeight(): string {
        if (window.innerWidth < 699) {
            // 60 top navbar
            // 50 header private message
            return (window.innerHeight - 60 - 50) + 'px';
        }
        // 80 top navbar
        // 50 header private message
        // 20 margin
        return (window.innerHeight - 80 - 50 - 20) + 'px';
    }

    private resetErrorMessage() {
        this.errorMessage = {
            title: '',
            message: ''
        };
    }
}
