import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
    name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {
    constructor(private domSanitizer: DomSanitizer) {
    }

    transform(url: string) {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    }
}

@Pipe({
    name: 'safeBackgroundUrl'
})
export class SafeBackgroundUrlPipe implements PipeTransform {
    constructor(private domSanitizer: DomSanitizer) {
    }

    transform(url: string) {
        return this.domSanitizer.bypassSecurityTrustStyle('url("' + url + '")');
    }
}
