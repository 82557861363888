import {WabelEntity} from "./wabel-entity.abstract";
import {Member} from "./member";
import {Tag} from "./tag";

export class SourcingNeed extends WabelEntity {
    constructor(obj: any) {
        super(obj);

        if (obj) {
            this.member = obj.member ? new Member(obj.member) : null;
            this.activities = obj.activities && obj.activities.items ? obj.activities.items.map(activity => new Tag(activity)) : [];
            this.certifications = obj.certifications && obj.certifications.items ? obj.certifications.items.map(certification => new Tag(certification)) : [];
            this.specifications = obj.specifications && obj.specifications.items ? obj.specifications.items.map(specification => new Tag(specification)) : [];
            this.countries = obj.countries && obj.countries.items ? obj.countries.items.map(country => new Tag(country)) : [];
            this.categorytag = obj.categorytag ? new Tag(obj.categorytag) : null;
        }
    }

    id: number;
    categorytag: Tag;
    member: Member;
    active: boolean;
    timeline: string;
    deadline: string;
    createdDate: string;
    updatedDate: string;
    specifications: Tag[];
    certifications: Tag[];
    activities: Tag[];
    countries: Tag[];
    daysLeft: number;
    searchEngineCalled: boolean;
}
