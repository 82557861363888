import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PortalModule} from '@angular/cdk/portal';
import {NdlTooltipComponent} from "./tooltip/tooltip.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import {NdlHintComponent} from './hint/hint.component';
import {NdlStatusComponent} from "./status/status.component";
import {NdlCustomTooltipComponent} from "./custom-tooltip/custom-tooltip.component";

@NgModule({
    declarations: [
        NdlTooltipComponent,
        NdlHintComponent,
        NdlStatusComponent,
        NdlCustomTooltipComponent
    ],
    exports: [
        NdlTooltipComponent,
        NdlHintComponent,
        NdlStatusComponent,
        NdlCustomTooltipComponent
    ],
    imports: [
        CommonModule,
        PortalModule,
        MatTooltipModule
    ]
})
export class NeedlTooltipModule {
}
