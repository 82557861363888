import {
    Component, EventEmitter, Input, OnDestroy, OnInit, Output
} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {AuthService} from '../../../services/auth.service';
import {Tag} from '../../../services/wabel-client/entities/tag';
import {BusinessMappingTag} from '../../../services/wabel-client/entities/business_mapping_tag';
import {CacheService} from '../../../services/cache.service';
import {debounceTime, takeUntil, takeWhile} from 'rxjs/operators';
import countries from '@amcharts/amcharts4-geodata/data/countries';
import {BehaviorSubject, Subject} from 'rxjs';
import {BusinessMappingService} from '../../../services/wabel-client/services/business-mapping.service';
import {Router} from '@angular/router';
import {NdlSnackbarService} from 'needl-lib';

@Component({
    selector: 'app-business-mapping-market',
    templateUrl: './business-mapping-market.component.html',
    styleUrls: ['./business-mapping-market.component.scss']
})
export class BusinessMappingMarketComponent implements OnInit, OnDestroy {
    private _loadingCountries = false;
    private _destroyed$ = new Subject();

    form: FormGroup;
    formSubmitted = false;
    saving = false;
    continents: Tag[];
    regions: Tag[];
    countries: Tag[];
    selectedCountries$: BehaviorSubject<Tag[]>;
    unselectedCountries$: BehaviorSubject<Tag[]>;
    notShownCountries = ['AQ'];

    @Input() standalone = true;

    @Output() stepFinished: EventEmitter<any> = new EventEmitter();
    @Output() stepPrevious: EventEmitter<any> = new EventEmitter();

    formattedCountriesForMapChart: { name: string, countries: { id: string }[], color: string }[] = [];

    get bmTags(): BusinessMappingTag[] {
        return this._authService.getMember().businessMapping && this._authService.getMember().businessMapping.businessMappingTags ?
            this._authService.getMember().businessMapping.businessMappingTags : [];
    }

    get exclusivity(): FormArray {
        return this.form.get('exclusivity') as FormArray;
    }

    get selectedCountries(): Tag[] {
        return this.form.get('countries').value.map(id => this.countries.find(c => c.id === id));
    }

    get unselectedCountries(): Tag[] {
        return this.countries.filter(c => this.form.get('countries').value.findIndex(v => v === c.id) === -1);
    }

    constructor(
        private _fb: FormBuilder,
        private _authService: AuthService,
        private _businessMappingService: BusinessMappingService,
        private snackbar: NdlSnackbarService,
        public cacheService: CacheService,
        private _router: Router
    ) {
    }

    ngOnInit() {
        this._loadingCountries = true;

        this.form = this._fb.group({
            countries: [this.bmTags.filter(bmt => bmt.type !== 'excluded').map(bmt => bmt.tag.id)],
            exclusivity: this._fb.array([]),
            excluded: [this.bmTags.filter(bmt => bmt.type === 'excluded').map(bmt => bmt.tag.id)]
        });

        this.cacheService.getLocation().pipe(takeUntil(this._destroyed$)).subscribe(tt => {
            this._loadingCountries = false;
            this.continents = tt.tags.filter(t => t.name !== "Antarctica");
            this.regions = [...this.continents.map(c => c.children)].flat();
            this.countries = [...this.regions.map(r => r.children)].flat();

            this.bmTags.filter(bmt => bmt.type !== null && bmt.type !== "excluded").forEach(bmt => this.addExclusivity(bmt.tag, bmt.type));
            this.selectedCountries$ = new BehaviorSubject<Tag[]>(this.selectedCountries);
            this.unselectedCountries$ = new BehaviorSubject<Tag[]>(this.unselectedCountries);

            this.formattedCountriesForMapChart = this.formatCountriesForMap(this.selectedCountries);

            this.form.get('countries').valueChanges
                .pipe(takeUntil(this._destroyed$))
                .subscribe((data) => {
                    this.formattedCountriesForMapChart = this.formatCountriesForMap(data.map(id => this.countries.find(c => c.id === id)));
                    this.updateOptions();
                });
        });
    }

    formatCountriesForMap(data: Tag[]): any[] {
      return [{name: 'map', countries: data.map(tag => ({id: tag.tagLocation.code.toUpperCase()})), color: '#08BD6F'}];
    }

    updateOptions() {
        this.selectedCountries$.next(this.selectedCountries);
        this.unselectedCountries$.next(this.unselectedCountries);
    }

    addExclusivity(country?: Tag, type?: string) {
        this.exclusivity.push(this._fb.group({
            excludedCountry: [country?.id ?? null],
            excludedType: [type]
        }));
    }

    removeExclusivity(index: number) {
        this.exclusivity.removeAt(index);
    }

    submitForm() {
        if (this.form.valid) {
            this.formSubmitted = true;
            this.saving = true;

            const bmTags = this.selectedCountries.map(c => new BusinessMappingTag({tag: c, type: null}));

            this.exclusivity.controls.forEach(control => {
                const tagIndex = bmTags.findIndex(t => t.tag.id === control.get('excludedCountry').value);
                bmTags[tagIndex].type = control.get('excludedType').value;
            });

            this.form.get('excluded').value?.forEach(v => {
                const tag = this.countries.find(c => c.id === v);
                bmTags.push(new BusinessMappingTag({tag: tag, type: "excluded"}));
            });

            this._businessMappingService.saveBusinessMappingMarket(this._authService.getMember().businessMapping.id, bmTags).subscribe(data => {
                if (this.standalone) {
                    this.snackbar.show('Your business market have been saved.', 'check');
                }
                this._authService.getMember().businessMapping.businessMappingTags = data;
                this.saving = false;
                this.stepFinished.emit(true);
                if (this.standalone) {
                    this._router.navigateByUrl('/business-mapping');
                }
            }, err => {
                this.saving = false;
                console.error(err);
                this.snackbar.show('An error occured while saving your export challenges form. Please try again or contact an administrator.', 'error');
            });
        } else {
            this.form.markAllAsTouched();
        }
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this.selectedCountries$.complete();
        this.unselectedCountries$.complete();
    }
}
