import {
    Component, EventEmitter, Input, OnDestroy, OnInit, Output,
} from '@angular/core';
import {RIGHT} from '../../../../services/wabel-client/security/right.const';
import {AuthService} from '../../../../services/auth.service';
import {BusinessMappingPriority} from '../../../../services/wabel-client/entities/business_mapping_priority';
import {BusinessMappingService} from '../../../../services/wabel-client/services/business-mapping.service';
import {FilterService} from "../../../../services/filter.service";
import {AlgoliaFilterOption} from "../../../../services/wabel-client/entities/algolia_filter_option";
import {Subject, Subscription} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {
    MemberNotification, REACT_TO_BM_RECOMMENDATIONS_TOUR_INTERNALNAME
} from "../../../../services/wabel-client/entities/member_notification";
import {NeedlTourService} from "../../../../services/needl-tour.service";
import {MemberService} from "../../../../services/wabel-client/services/member.service";
import {NdlLayoutService} from 'needl-lib';
import {NeedlSecurityService} from "@wabel/needl-security";
import {environment} from "../../../../../environments/environment";
import {ResourceService} from "../../../../services/wabel-client/services/resource.service";

@Component({
    selector: 'app-business-mapping-recap-collapse',
    templateUrl: './business-mapping-recap-collapse.component.html',
    styleUrls: ['./business-mapping-recap-collapse.component.scss']
})
export class BusinessMappingRecapCollapseComponent implements OnInit, OnDestroy {
    RIGHT = RIGHT;

    @Input() priority: BusinessMappingPriority;
    @Input() index: number;
    public loading = false;
    firstLoad = true;
    firstRecommendationsLoad = true;
    filters: string[] = [];
    filtersOptions: AlgoliaFilterOption[] = [];
    filtersChanged: Subject<any> = new Subject<any>();
    filtersChanged$: Subscription;
    recommendations$: Subscription;
    totalCount = 0;
    recommendationsNotToAppear = [];
    fakeDate = [];
    businessChallengeOptions = {
        'consolidate_existing_market': 'Consolidate our existing market',
        'open_new_markets': 'Open new markets',
        'develop_new_channels': 'Develop new Channels'
    };

    isUpgradeToPremiumPopUpOpened = false;
    @Output() recommendationsAdded = new EventEmitter<boolean>();

    tour$: Subscription;

    getGoal(priority: BusinessMappingPriority): string {
        return this.businessChallengeOptions[priority.goal];
    }

    getRegion(priority: BusinessMappingPriority): string {
        return priority.region ? priority.region.name + ' (' + this.priority.countries.length + ')' : null;
    }

    getTargets(priority: BusinessMappingPriority): string[] {
        return priority.companyTypes.map(ct => ct.name);
    }

    getActivities(priority: BusinessMappingPriority): string[] {
        return priority.activities.map(a => a.name);
    }

    constructor(private authService: AuthService,
                public businessMappingService: BusinessMappingService,
                private filterService: FilterService,
                private needlTourService: NeedlTourService,
                private memberService: MemberService,
                public layoutService: NdlLayoutService,
                private needlSecurityService: NeedlSecurityService) {
    }

    ngOnInit() {
        if (!this.needlSecurityService.hasRight(RIGHT.CAN_SEE_BUSINESS_MAPPING_RECOMMENDATIONS)) {
            this.fakeDate = [
                {
                    businessPriorityScore: {score: 0.99},
                    name: 'Company Retailer',
                    companytype: {name: 'Retailer'},
                    type: 'Retailer',
                    url: null,
                    logoUrl: "/assetsFiles/404/image.jpg",
                    country: {name: 'British Indian Ocean Territory', tagLocation: {code: 'io'}},
                    ca: {name: '< 20M€'},
                    nbSellingPoints: {name: '100 to 250'},
                    activities: [{name: 'Brand'}],
                    buyingScope: [{name: 'Sugar and Home Baking'}],
                },
                {
                    businessPriorityScore: {score: 0.97},
                    name: 'Company Retailer',
                    companytype: {name: 'Retailer'},
                    type: 'Retailer',
                    url: null,
                    logoUrl: "/assetsFiles/404/image.jpg",
                    country: {name: 'British Indian Ocean Territory', tagLocation: {code: 'io'}},
                    ca: {name: '< 20M€'},
                    nbSellingPoints: {name: '100 to 250'},
                    activities: [{name: 'Brand'}],
                    buyingScope: [{name: 'Sugar and Home Baking'}],
                },
                {
                    businessPriorityScore: {score: 0.90},
                    name: 'Company Retailer',
                    companytype: {name: 'Retailer'},
                    type: 'Retailer',
                    url: null,
                    logoUrl: "/assetsFiles/404/image.jpg",
                    country: {name: 'British Indian Ocean Territory', tagLocation: {code: 'io'}},
                    ca: {name: '< 20M€'},
                    nbSellingPoints: {name: '100 to 250'},
                    activities: [{name: 'Brand'}],
                    buyingScope: [{name: 'Sugar and Home Baking'}],
                },
                {
                    businessPriorityScore: {score: 0.89},
                    name: 'Company Retailer',
                    companytype: {name: 'Retailer'},
                    type: 'Retailer',
                    url: null,
                    logoUrl: "/assetsFiles/404/image.jpg",
                    country: {name: 'British Indian Ocean Territory', tagLocation: {code: 'io'}},
                    ca: {name: '< 20M€'},
                    nbSellingPoints: {name: '100 to 250'},
                    activities: [{name: 'Brand'}],
                    buyingScope: [{name: 'Sugar and Home Baking'}],
                },
                {
                    businessPriorityScore: {score: 0.87},
                    name: 'Company Retailer',
                    companytype: {name: 'Retailer'},
                    type: 'Retailer',
                    url: null,
                    logoUrl: "/assetsFiles/404/image.jpg",
                    country: {name: 'British Indian Ocean Territory', tagLocation: {code: 'io'}},
                    ca: {name: '< 20M€'},
                    nbSellingPoints: {name: '100 to 250'},
                    activities: [{name: 'Brand'}],
                    buyingScope: [{name: 'Sugar and Home Baking'}],
                },
                {
                    businessPriorityScore: {score: 0.87},
                    name: 'Company Importer',
                    companytype: {name: 'Importer'},
                    type: 'Importer',
                    url: null,
                    logoUrl: "/assetsFiles/404/image.jpg",
                    country: {name: 'France', tagLocation: {code: 'fr'}},
                    ca: {name: '> 200M€'},
                    nbSellingPoints: {name: '100 to 250'},
                    activities: [{name: 'Brand'}, {name: 'Private Label'}],
                    buyingScope: [{name: 'Fresh Meat'}, {name: 'Pet food'}],
                },
                {
                    businessPriorityScore: {score: 0.80},
                    name: 'Company Retailer',
                    companytype: {name: 'Retailer'},
                    type: 'Retailer',
                    url: null,
                    logoUrl: "/assetsFiles/404/image.jpg",
                    country: {name: 'France', tagLocation: {code: 'fr'}},
                    ca: {name: '< 10M€'},
                    nbSellingPoints: {name: 'more than 250'},
                    activities: [{name: 'Private Label'}],
                    buyingScope: [{name: 'Wines'}, {name: 'Champagnes & sparkling wines'}, {name: 'Spirits'}],
                },
                {
                    businessPriorityScore: {score: 0.72},
                    name: 'Company Retailer',
                    companytype: {name: 'Retailer'},
                    type: 'Retailer',
                    url: null,
                    logoUrl: "/assetsFiles/404/image.jpg",
                    country: {name: 'France', tagLocation: {code: 'fr'}},
                    ca: {name: '> 100M€'},
                    nbSellingPoints: {name: '100 to 250'},
                    activities: [{name: 'Private Label'}],
                    buyingScope: [{name: 'Wines'}, {name: 'Champagnes & sparkling wines'},
                        {name: 'Spirits'}, {name: 'Herbs, Spices and Cooking ingredients'}, {name: 'Rice, Pasta & Pulses'}, {name: 'Soups & Ready Meals'}],
                }
            ];
            return;
        }
        this.filtersChanged$ = this.filtersChanged.pipe(debounceTime(300)).subscribe(data => {
            this.getRecommendations(this.priority.id, data);
        });
        this.priority.countries.forEach(tag => this.filters.push(tag.name));
        this.filtersOptions = this.filterService.buyersFilters.find(filter => filter.machineName === 'company_location').options
            .filter(country => this.priority.countries.findIndex(c => c.name === country.name) !== -1);
        this.filters.forEach(opt => {
           if (this.filtersOptions.findIndex(alogliaOpt => alogliaOpt.name === opt) === -1) {
               this.filtersOptions.push(new AlgoliaFilterOption({name: opt, value: opt}));
           }
        });
        this.filtersOptions.forEach(opt => opt.count = 0);
    }

    getRecommendations(idpriority: number, filters: any = [], fromUpdatedRecommendations = false) {
        if (!this.needlSecurityService.hasRight(RIGHT.CAN_SEE_BUSINESS_MAPPING_RECOMMENDATIONS)) {
            this.loading = false;
            return;
        }
        if (this.firstLoad) {
            this.getRecommendationsFilters(idpriority, filters);
        } else {
            if (!fromUpdatedRecommendations) {
                this.loading = true;
            }
            filters = filters.filter(filter => this.filtersOptions.find(opt => opt.name === filter) !== null);
            if (this.recommendations$) {
                this.recommendations$.unsubscribe();
            }
            this.recommendations$ = this.businessMappingService.getBusinessMappingPriority(idpriority, filters).pipe(debounceTime(300))
                .subscribe(businessMappingPriority => {
                    if (this.businessMappingService.firstRecommendationIndexOpened === null) {
                        this.businessMappingService.firstRecommendationIndexOpened = this.index;
                    }
                    this.loading = false;
                    this.firstRecommendationsLoad = false;
                    this.priority.recommendations = businessMappingPriority.recommendations;
                    if (!this.authService.getMember().hasNotificationAccepted(REACT_TO_BM_RECOMMENDATIONS_TOUR_INTERNALNAME) && this.priority.recommendations.length) {
                        this.initializeTour();
                    }
                });
        }
    }

    getRecommendationsFilters(idpriority: number, filters: any = []) {
        this.loading = true;
        if (!this.firstLoad) {
            return;
        }
        this.recommendations$ = this.businessMappingService.getBusinessMappingPriorityRecommendationsFilters(idpriority, filters)
            .subscribe(businessMappingPriority => {
                let index = null;
                for (let i = 0; i < businessMappingPriority.countryRecommendationFilters.length; i++) {
                    for (const key in businessMappingPriority.countryRecommendationFilters[i]) {
                        index = this.filtersOptions.findIndex(opt => opt.name === key);
                        if (index !== -1) {
                            this.filtersOptions[index].count = businessMappingPriority.countryRecommendationFilters[i][key];
                        }
                    }
                }

                this.filtersOptions.sort((n1, n2) => n2.count - n1.count || n1.name.localeCompare(n2.name));
                this.filtersOptions.forEach(opt => this.totalCount += opt.count);
                this.firstLoad = false;
                this.getRecommendations(idpriority, filters);
            });
    }

    ngOnDestroy() {
        if (this.recommendations$) {
            this.recommendations$.unsubscribe();
        }
        if (this.filtersChanged$) {
            this.filtersChanged$.unsubscribe();
        }
        if (this.tour$) {
            this.tour$.unsubscribe();
        }
    }

    initializeTour() {
        const steps = [];
        steps.push({
            title: 'React to these recommendations to see new ones',
            anchorId: 'react-to-bm-recommendation-tour',
            content: 'Check the profiles matching your business priorities and add to your prospect list the ones you are interested in.',
        });
        this.needlTourService.initialize(steps, {
            enableBackdrop: true,
            isAsync: true,
            nextBtnTitle: 'Ok',
            endBtnTitle: 'Got it',
            disablePageScrolling: true
        }, false);
        this.needlTourService.canBeSkipped = false;
        this.needlTourService.start();
        this.tour$ = this.needlTourService
            .getTourService()
            .end$
            .subscribe(
                () => {
                    this.memberService.acceptNotification(REACT_TO_BM_RECOMMENDATIONS_TOUR_INTERNALNAME, 'modal').subscribe((notifications: MemberNotification[]) => {
                        this.authService.getMember().setNotifications(notifications);
                    });
                    if (this.tour$) {
                        this.tour$.unsubscribe();
                    }
                }
            );
    }

    get clickToMemberShipPage() {
        return ResourceService.CLICK_TO_MEMBERSHIP_PAGE;
    }
}
