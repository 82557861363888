<ndl-form-field #formField [prefix]="prefix" [suffix]="suffix" [label]="label" [hint]="hint" [errors]="errors">
    <ng-template #prefix>
        <ng-content select="[ndl-prefix]"></ng-content>
    </ng-template>
    <input matInput ngx-google-places-autocomplete [formControl]="innerFormControl"
           [options]="{types: ['geocode']}"
           [required]="required" [name]="name"
           [disabled]="disabled" [placeholder]="placeholder" (blur)="onTouched()"
           (onAddressChange)="onChange($event)"/>
    <ng-template #suffix>
        <button mat-icon-button type="button" *ngIf="!formField.empty && !disabled" (click)="clear()">
            <mat-icon>close</mat-icon>
        </button>
        <ng-content select="[ndl-suffix]"></ng-content>
    </ng-template>
    <ng-container *ngIf="!ngControl?.control?.errors?.required && error">
        <ndl-error>{{error}}</ndl-error>
    </ng-container>
</ndl-form-field>
