<div class="custom-header">
    <div class="custom-header-title" *ngIf="title">
        {{title}}
    </div>
    <div class="custom-header-content">
        <span class="custom-header-content-label">{{periodLabel}}</span>
        <div class="custom-header-content-actions">
            <button mat-icon-button (click)="previousClicked('month')">
                <span class="material-icons-outlined">keyboard_arrow_left</span>
            </button>
            <button mat-icon-button (click)="nextClicked('month')">
                <span class="material-icons-outlined">keyboard_arrow_right</span>
            </button>
        </div>
    </div>
</div>
