import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UtilsService} from "../../../../services/utils.service";
import {Company} from "../../../../services/wabel-client/entities/company";
import {AuthService} from "../../../../services/auth.service";
import {CompanyProfileSubmissionService} from "../../../../services/wabel-client/services/company-profile-submission.service";
import {MemberService} from "../../../../services/wabel-client/services/member.service";
import {Tag} from "../../../../services/wabel-client/entities/tag";
import {CompanyClient} from '../../../../services/wabel-client/entities/company_client';
import {Subscription} from "rxjs";

@Component({
    selector: 'submission-exporting-countries-step',
    templateUrl: './submission-exporting-countries-step.component.html',
    styleUrls: ['./submission-exporting-countries-step.component.scss']
})
export class SubmissionExportingCountriesStepComponent implements OnInit, OnDestroy {
    @Input() exportingToCountries: Tag[] = [];
    @Input() isExportingToBuyerCountry = null;
    @Input() buyerCompany: Company;
    @Output() exportingToCountriesChange = new EventEmitter<Tag[]>();
    @Output() isExportingToBuyerCountryChange = new EventEmitter<string>();
    @Output() clientsListEmitter = new EventEmitter<CompanyClient[]>();
    clients: CompanyClient[] = [];
    @Output() validateExportingCountriesStep = new EventEmitter();
    showLessExportingCountries = true;
    selectedCountry = null;
    myCompany: Company;
    buyerCountryIsFoundInSellerBusinessMapping = false;
    buyerCountryIsExcludedBySeller = false;
    clientList$: Subscription;
    public buyerInformationIncludeCheckbox: Array<{ value: string, label: string }> = [
        {value: 'exporting_yes', label: 'Yes'},
        {value: 'exporting_no', label: 'No'}
    ];
    public exportingToCountryCheckbox: Array<{ value: string, label: string }> = null;
    exportingToError = false;

    constructor(private memberService: MemberService,
                public utilsService: UtilsService,
                public authservice: AuthService,
                private companyProfileSubmissionService: CompanyProfileSubmissionService
    ) {
    }

    ngOnInit() {
        this.myCompany = this.authservice.getMember().company;
        this.checkIfBuyerCountryInSellerBusinessMapping();
        const countryName: string = this.buyerCompany.country &&
        this.buyerCompany.country.name ? this.buyerCompany.country.name : 'your country';
        this.exportingToCountryCheckbox = [
            {value: 'exporting_yes', label: 'Yes, add ' + countryName + ' to my export diagnostics and submission'},
            {value: 'exporting_no', label: 'No, not yet'}
        ];
        this.companyProfileSubmissionService.doCheckStep.subscribe(step => {
            if (step === this.companyProfileSubmissionService.EXPORTING_COUNTRIES_STEP) {
                this.onChange();
            }
        });
        this.fetchClient();
    }

    fetchClient() {
        if (this.clientList$) {
            this.clientList$.unsubscribe();
        }
        this.clientList$ = this.memberService.getClientList().subscribe(data => {
                if (!data) {
                    return;
                }
                this.clients = data.company.companyClients;
            },
            () => {
            });
    }

    countryClientsCounter(country: string) {
        let total = 0;
        this.clients.forEach(x => {
            if (x.client && x.client.country && x.client.country.name === country) {
                total++;
            }
        });
        return total;
    }

    onChange() {
        this.exportingToError = false;
        if (this.isExportingToBuyerCountry === null) {
            this.exportingToError = true;
            this.validateExportingCountriesStep.emit(false);
        } else {
            this.checkDiagnosisClients();
            this.isExportingToBuyerCountryChange.emit(this.isExportingToBuyerCountry);
            this.validateExportingCountriesStep.emit(true);
            this.exportingToCountriesChange.emit(this.exportingToCountries);
        }
    }

    checkIfBuyerCountryInSellerBusinessMapping() {
        const targetCompany = this.buyerCompany;
        let buyerCountryIsFoundInSellerBusinessMapping = false;
        let buyerCountryIsExcludedBySeller = false;
        if (this.authservice.getMember().businessMapping) {
            this.authservice.getMember().businessMapping.businessMappingTags.forEach(function (item) {
                if (targetCompany.country && (targetCompany.country.tagLocation.code === item.tag.tagLocation.code)) {
                    buyerCountryIsFoundInSellerBusinessMapping = true;
                    if (item.type !== null) {
                        buyerCountryIsExcludedBySeller = true;
                    }
                }
            });
        }
        this.buyerCountryIsFoundInSellerBusinessMapping = buyerCountryIsFoundInSellerBusinessMapping;
        this.buyerCountryIsExcludedBySeller = buyerCountryIsExcludedBySeller;
    }

    exportCountrySelected(data: Tag[]) {
        if (data.length > 0) {
            let fair = true;
            this.exportingToCountries.forEach(x => {
                if (x.name === data[0].name) {
                    fair = false;
                }
            });
            if (fair) {
                this.exportingToCountries.push(data[0]);
                this.onChange();
            }
        }
    }


    clearSelectedCountries() {
        this.showLessExportingCountries = true;
        this.exportingToCountries = [];
        this.onChange();
    }

    removeSelectedCountry(index: number) {
        this.exportingToCountries.splice(index, 1);
        this.onChange();
    }

    eventChangeExportTo(event) {
        this.isExportingToBuyerCountry = event;
        const targetCountry = new Tag({
            "id": +this.buyerCompany.country.id,
            "tagLocation": {"code": this.buyerCompany.country.tagLocation.code},
            "name": this.buyerCompany.country.name
        });
        const itemFoundAlready = this.exportingToCountries.find(x => x.tagLocation.code === targetCountry.tagLocation.code);
        if (itemFoundAlready && event === 'exporting_no') {
            const index: number = this.exportingToCountries.indexOf(itemFoundAlready);
            if (index !== -1) {
                this.exportingToCountries.splice(index, 1);
            }
        } else if (!itemFoundAlready && event === 'exporting_yes') {
            this.exportingToCountries.push(targetCountry);
        }
        this.onChange();
    }

    imgFailToLoad(event) {
        event.target.src = '/assetsFiles/flags/all.png';
    }

    checkDiagnosisClients() {
        let counter = 0;
        this.exportingToCountries.forEach(x => {
            counter += this.countryClientsCounter(x.name);
        });
        if (counter === 0) {
            this.companyProfileSubmissionService.setShowDiagnosisClientsStep(false);
        } else {
            this.clientsListEmitter.emit(this.clients);
            this.companyProfileSubmissionService.setShowDiagnosisClientsStep(true);
        }
    }

    ngOnDestroy() {
        if (this.clientList$) {
            this.clientList$.unsubscribe();
        }
    }
}
