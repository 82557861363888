<ndl-form-field #formField [prefix]="prefix" [suffix]="suffix" [label]="label" [hint]="hint" [errors]="errors">
    <ng-template #prefix>
        <ng-content select="[ndl-prefix]"></ng-content>
    </ng-template>

    <textarea matInput [formControl]="innerFormControl" [required]="required" [style.minHeight]="minHeight"
              [maxlength]="maxLength" [cdkTextareaAutosize]="autoresize"
              [placeholder]="placeholder" (blur)="onTouched()" (change)="onChange($event.target.value)"></textarea>

    <ng-template #suffix>
        <ng-content select="[ndl-suffix]"></ng-content>
    </ng-template>
</ndl-form-field>
