import {
    AfterViewInit,
    Component,
    ContentChildren,
    ElementRef,
    Input,
    QueryList,
    ViewChild
} from '@angular/core';
import {NdlInputBaseDirective} from '../input-base.directive';
import {NdlOptionDirective} from "../option.directive";
import {NdlOptionGroupDirective} from "../optiongroup.directive";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {MatMenu, MatMenuItem, MatMenuPanel, MatMenuTrigger} from "@angular/material/menu";
import {Subject} from "rxjs";
import {debounceTime} from "rxjs/operators";

@Component({
    selector: 'ndl-cascader',
    templateUrl: './cascader.component.html',
    styleUrls: ['./cascader.component.scss'],
    host: {
        'class': 'ndl-cascader',
        '(blur)': "onTouched()"
    }
})
export class NdlCascaderComponent extends NdlInputBaseDirective<string[]> implements AfterViewInit {
    @Input() set multiple(multiple) {
        this._multiple = coerceBooleanProperty(multiple);
    }
    get multiple(): boolean {
        return this._multiple;
    }
    protected _multiple = false;

    @Input() set clearable(clearable) {
        this._clearable = coerceBooleanProperty(clearable);
    }
    get clearable(): boolean {
        return this._clearable;
    }
    protected _clearable = false;

    @Input() set displayFullPath(displayFullPath) {
        this._displayFullPath = coerceBooleanProperty(displayFullPath);
    }
    get displayFullPath(): boolean {
        return this._displayFullPath;
    }
    protected _displayFullPath = false;

    @ViewChild('input') input: ElementRef<HTMLInputElement>;
    @ViewChild('levelOneTrigger') levelOneTrigger: MatMenuTrigger;

    options: any[] = [];
    @ContentChildren(NdlOptionGroupDirective) groups: QueryList<NdlOptionGroupDirective>;
    @ContentChildren(NdlOptionDirective) optionsWithoutGroup: QueryList<NdlOptionDirective>;

    flattenOptions: any[] = [];
    selectedValues: any[] = [];

    filteredOptionsMenuItem = {
        description: 'Menu',
        uri: '',
        route: false,
        children: []
    };

    terms$ = new Subject<string>();
    openMenuTimer: any;

    ngAfterViewInit() {
        this.options = [];
        for (let group of this.groups) {
            this.options.push(this.formatOption(group, []));
        }
        this.filteredOptionsMenuItem.children = this.options;

        this.flattenOptions.forEach((element, index) => {
            this.flattenOptions[index].realLabel = element.label;
            this.flattenOptions[index].label = this.getFullPath(element);
        });
        this.terms$.subscribe(() => {
            this.levelOneTrigger.closeMenu();
        });

        if (this.multiple) {
            for (let value of this.innerFormControl.value) {
                const flattenOption = this.flattenOptions.find(o => o.value === value);
                if (flattenOption) {
                    this.toggleOption(flattenOption);
                }
            }
        } else {
            const flattenOption = this.flattenOptions.find(o => o.value === this.innerFormControl.value);
            if (flattenOption) {
                this.toggleOption(flattenOption);
            }
        }

        this.terms$.pipe(
            debounceTime(600)
        ).subscribe(term => {
            if (this.openMenuTimer) {
                clearTimeout(this.openMenuTimer);
            }
            if (!term) {
                this.filteredOptionsMenuItem.children = this.options;
            } else {
                this.filteredOptionsMenuItem.children = this.flattenOptions.filter((o) => o.label.toLowerCase().includes(term.toLowerCase()) && !o.children?.length);
                this.openMenuTimer = setTimeout(() => {
                    this.levelOneTrigger.openMenu();
                }, 200);
            }
        });
    }

    formatOption(option: any, parents: string[]) {
        const formattedOption = {
            label: option.label,
            value: option.value,
            children: [],
            parents: parents
        }

        const newParents = [...parents];
        newParents.push(formattedOption.label);

        if (option.groups) {
            for (let child of option.groups) {
                formattedOption.children.push(this.formatOption(child, newParents));
            }
        }
        if (option.options) {
            for (let child of option.options) {
                formattedOption.children.push(this.formatOption(child, newParents));
            }
        }

        this.flattenOptions.push(Object.assign({}, formattedOption));

        return formattedOption;
    }

    toggleOption(data: any): void {
        if (data.realLabel) {
            data = Object.assign({}, data);
            data.label = data.realLabel;
        }
        if (!this.multiple) {
            this.input.nativeElement.value = '';
            this.selectedValues = [data];
        } else {
            let index = this.selectedValues.findIndex(v => v.value === data.value);
            if (index !== -1) {
                this.selectedValues.splice(index, 1);
            } else {
                this.selectedValues.push(data);
            }
        }
        this.refreshInnerFormControl();
    }

    refreshInnerFormControl() {
        if (this.innerFormControl) {
            this.innerFormControl.setValue(this.selectedValues);
        }
    }

    isSelected(data: any): boolean {
        return this.selectedValues.findIndex(v => v.value === data.value) !== -1;
    }

    remove(data): void {
        let index = this.selectedValues.findIndex(v => v.value === data.value);
        if (index >= 0) {
            this.selectedValues.splice(index, 1);
        }
        this.refreshInnerFormControl();
    }

    getFullPath(option) {
        let string = option.parents.join(' / ');
        if (string.length) {
            string += ' / ';
        }
        string += option.label;
        return string;
    }

    public hasSubItems(item): boolean
    {
        return (Array.isArray(item.children) && item.children.length > 0);
    }

    public patch(item: MatMenu, triggerButton: MatMenuItem, trigger: any, parentMenu: MatMenuPanel): MatMenu
    {
        if (parentMenu) {
            triggerButton._triggersSubmenu = true;
            trigger._parentMaterialMenu = parentMenu;
        }
        return item;
    }
}
