import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'kit-form-radio-button',
    templateUrl: './kit-form-radio-button.component.html',
    styleUrls: ['./kit-form-radio-button.component.scss']
})
export class KitFormRadioButtonComponent implements OnInit {

    @Input() selected: boolean = false;
    @Input() formName: string;
    @Input() value: string;
    @Input() isNeg: boolean = false;

    @Input() binding: boolean;
    @Output() bindingChange = new EventEmitter();

    @Output() radioClicked: EventEmitter<string> =
        new EventEmitter<string>();


    constructor() {
    }

    ngOnInit() {
    }

    @HostListener("click", ["$event"])
    public onClick(event: any): void {
        this.radioClicked.emit(this.value);
    }

    isChecked() {
        if (this.isNeg) {
            if (this.binding == false) return true;
            if (this.binding) return false;
        }

        if (this.binding == false) return false;
        if (this.binding) return true;

        return false;
    }

}
