import {BehaviorSubject, combineLatest, merge, Observable, of, Subject} from "rxjs";
import {map, switchMap, tap, debounceTime, startWith} from "rxjs/operators";
import {PageEvent} from "@angular/material/paginator";
import {AbstractControl} from "@angular/forms";
import {NdlPaginatorBase} from "./base-paginator.component";
import {_MatTableDataSource} from "@angular/material/table";
import {Sort} from "@angular/material/sort";
import {EventEmitter} from '@angular/core';

export class NdlDataSource<T = any, F = any> extends _MatTableDataSource<T, NdlPaginatorBase<T>> {

    render$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
    query: Observable<T[]>;
    loading = false;
    refresh$ = new Subject<void>();
    loading$ = new Subject<boolean>();
    doNotapplyStickyOffset: boolean = null; // This is juste to fix an offset problem encountered when defining sticky column
    sorted: Observable<NdlSortEvent>;
    currentSort: NdlSortEvent;

    _data$: Observable<T[]> = of([]);
    sortingCallback: NdlSortingCallback<T> = sortValue => sortValue;

    get data$() {
        return this._data$;
    }

    set data$(data: NdlRequest<T>) {
        if (data instanceof Array) {
            this._data$ = of(data);
        } else if (data instanceof Observable) {
            this._data$ = data;
        } else {
            this._request = data;
        }

        this._updateChangeSubscription();
    }
    _request: NdlPaginatedCallback<T>;

    get request() {
        return this._request;
    }

    set request(request) {
        this._request = request;
        this._updateChangeSubscription();
    }
    _filterCallback: NdlFilterCallback<T> = formValue => formValue;
    get filterCallback() {
        return this._filterCallback;
    }

    set filterCallback(filterCallback) {
        this._filterCallback = filterCallback;
        this._updateChangeSubscription();
    }

    _filterForm: AbstractControl;
    get filterForm() {
        return this._filterForm;
    }

    set filterForm(filterForm) {
        this._filterForm = filterForm;
        this._updateChangeSubscription();
    }

    constructor(data: NdlRequest<T>) {
        super();
        this.data$ = data;
    }

    updateLoading(isLoading: boolean) {
        this.loading = isLoading;
        this.loading$.next(this.loading);
    }

    _updateChangeSubscription() {
        const paginate = this.paginator ? this.paginator.page.asObservable() : of(null);
        const filter = this.filterForm ?
            this.filterForm.valueChanges.pipe(debounceTime(300)).pipe(
                tap(() => {
                    if (this.paginator) {
                        this.paginator.firstPage();
                    }
                }),
                startWith(this.filterForm.value)
            ) :
            of(null);
        const sortChange: Observable<NdlSortEvent> = this.sorted?.pipe(startWith(this.currentSort)) ?? of(null);

        if (this.request) {
            this.query = combineLatest([paginate, filter, sortChange, this.refresh$])
                .pipe(
                    tap(data => this.currentSort = data[2]),
                    map(data => ({pagination: data[0], filters: data[1], sort: data[2]})),
                    switchMap((options: NdlRequestOptions) => {
                           this.updateLoading(true);
                            return this.request(options)
                                .pipe(
                                    map(data => {
                                        this.updateLoading(false);
                                        this.data = this.paginator ? this.paginator.displayStrategy(this.data, data) : data;
                                        return this.data;
                                    })
                                );
                    })
                );
        } else {
            // Watch for base data or filter changes to provide a filtered set of data.
            const filteredData = combineLatest([this._data$, filter]).pipe(
                map(([data, formValue]) => this.filterCallback ? this.filterCallback(data, formValue) : data)
            );
            // Watch for filtered data or sort changes to provide an ordered set of data.
            const orderedData = combineLatest([filteredData, sortChange]).pipe(
                map(([data, sort]) => this.sortingCallback(data, sort))
            );
            // Watch for ordered data or page changes to provide a paged set of data.
            this.query = combineLatest([orderedData, paginate]).pipe(
                map(([data]) => this._pageData(data)),
                tap(data => this.data = data)
            );
        }

        this._renderChangesSubscription?.unsubscribe();
        this._renderChangesSubscription = this.query.subscribe(data => {
            this.render$.next(data);

            // Just here to fix sticky columns offsets
            this.doNotapplyStickyOffset = this.doNotapplyStickyOffset === null ? false : !this.doNotapplyStickyOffset;
        });
    }

    connect() {
        if (!this._renderChangesSubscription) {
            this._updateChangeSubscription();
        }

        return this.render$;
    }

    setSorting(sorted: Observable<NdlSortEvent>, firstSort: NdlSortEvent, callback: NdlSortingCallback) {
        this.sorted = sorted;
        this.currentSort = firstSort;
        this.sortingCallback = callback;
        this._updateChangeSubscription();
    }
}

export type NdlPaginatedCallback<T = any, O = NdlRequestOptions> = (requestOptions?: O) => Observable<T[]>;

export type NdlFilterCallback<T = any, F = any> = (data: T[], filters: F) => T[];
export type NdlSortingCallback<T = any> = (data: T[], sort: NdlSortEvent) => T[];
export type NdlRequest<T> = T[] | Observable<T[]> | NdlPaginatedCallback<T>;

export interface NdlSortEvent {
    sortColumn: string;
    sortOrder: 'asc'|'desc';
}

export interface NdlRequestOptions {
    filters?: any;
    sort?: NdlSortEvent;
    pagination?: PageEvent;
}
