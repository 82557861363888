import {
    Component,
    Input,
    QueryList, ViewChild,
} from '@angular/core';
import {MatFormFieldControl} from '@angular/material/form-field';
import {NdlOptionDirective} from '../option.directive';
import {NdlInputFieldBaseDirective} from '../input-field-base.directive';
import {NdlOptionGroupDirective} from '../optiongroup.directive';
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {MatSelect} from "@angular/material/select";
import {MatOption} from "@angular/material/core";


@Component({
    selector: 'ndl-select-multi-level-field',
    templateUrl: './select-multi-level-field.component.html',
    styleUrls: ['select-multi-level-field.component.scss'],
    providers: [
        {provide: MatFormFieldControl, useExisting: NdlSelectMultiLevelFieldComponent}
    ],
    host: {
        id: `ndl-select-multi-level-${NdlSelectMultiLevelFieldComponent.nextId}`
    }
})
export class NdlSelectMultiLevelFieldComponent<T = string> extends NdlInputFieldBaseDirective<any[]> {
    static nextId = 0;

    openedGroup: string[] = [];
    controlType = "ndl-select-multi-level";
    id = "ndl-select-multi-level-" + NdlSelectMultiLevelFieldComponent.nextId++;
    search = '';

    @Input('aria-describedby') userAriaDescribedBy: string;
    @Input() options: QueryList<NdlOptionDirective>;
    @Input() groups: QueryList<NdlOptionGroupDirective>;
    @Input() identifier = null;
    @Input() searchable;
    @Input() displayMode = false;
    @Input() showBasicTitle = false;
    @Input() clearable = false;
    @Input() onlyLastLevel = false;
    @Input() maximumSelectedOption = null;
    @Input() title = null;

    @Input() maxHeight;
    @Input() height;

    setDescribedByIds(ids: string[]) {
        const controlElement = this._elementRef.nativeElement;
        controlElement.setAttribute('aria-describedby', ids.join(' '));
    }

    onContainerClick(event: MouseEvent) {
    }

    isChecked(param: T): boolean {
        return this._getValueIndex(param) > -1;
    }

    countGroupChildrenCheck(group) {
        return group.allOptions.toArray().filter(o => this.isChecked(o.value)).length;
    }

    isAllChecked(group: NdlOptionGroupDirective): boolean {
        const countChecked = this.countGroupChildrenCheck(group);
        return countChecked > 0 && (countChecked === group.options.length || countChecked === group.allOptions.length);
    }

    isSomeChecked(group: NdlOptionGroupDirective): boolean {
        const countChecked = this.countGroupChildrenCheck(group);
        return countChecked > 0 && countChecked !== group.options.length && countChecked !== group.allOptions.length;
    }

    toggleGroupCheck(active: boolean, group: NdlOptionGroupDirective) {
        group.allOptions.forEach(o => {
            if (this.isChecked(o.value) !== active) {
                this.changeValue(o.value);
            }
        });
        if (active && !this.isGroupOpened(group)) {
            this.toggleGroup(group);
        }
    }

    toggleGroup(group: NdlOptionGroupDirective) {
        const index = this.openedGroup.findIndex(g => group.value === g);
        if (index > -1) {
            this.openedGroup.splice(index, 1);
        } else {
            this.openedGroup.push(group.value);
        }
    }

    isGroupOpened(group: NdlOptionGroupDirective) {
        return this.search?.length > 2 || this.openedGroup.findIndex(g => group.value === g) > -1;
    }

    hideGroup(group: NdlOptionGroupDirective) {
        return this.search && !group.label.toLowerCase().includes(this.search.toLowerCase())
            && !group.allOptions.filter(o => !this.hideOption(o)).length;
    }

    hideOption(option: NdlOptionDirective) {
        return this.search && !option.label.toLowerCase().includes(this.search.toLowerCase());
    }

    // Custom
    changeValue(param: T) {
        const index = this._getValueIndex(param);
        if (index > -1) {
            this.value.splice(index, 1);
        } else {
            if (!this.value) {
                this.value = [param];
            } else {
                this.value.push(param);
            }
        }
        this.innerFormControl?.updateValueAndValidity();
    }

    // Custom
    private _getValueIndex(param: T): number {
        return this.value ? this.value.findIndex(v => this.identifier ? v[this.identifier] === param[this.identifier] : v === param) : -1;
    }
    clear() {
        this.openedGroup = [];
        this.value = [];
    }
}
