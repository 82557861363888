import {
    Component, ViewChild,
    Input, TemplateRef, ContentChild, Inject, forwardRef, SkipSelf, ViewContainerRef, Optional, Directive
} from '@angular/core';
import {MatStep, MatStepContent, MatStepper, StepState} from '@angular/material/stepper';
import {FormGroup} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {STEPPER_GLOBAL_OPTIONS, StepperOptions} from '@angular/cdk/stepper';
import {NdlStepperComponent} from '../stepper/stepper.component';

@Directive({
    selector: '[ndlStepContent]',
    providers: [
        {provide: MatStepContent, useExisting: NdlStepContentDirective}
    ]
})
export class NdlStepContentDirective extends MatStepContent {
}
