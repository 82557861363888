import {
    AfterViewInit,
    Directive, ElementRef, EventEmitter,
    Input, OnDestroy, OnInit, Optional, Output, Self
} from '@angular/core';
import {CanUpdateErrorState, ErrorStateMatcher, mixinErrorState} from '@angular/material/core';
import {MatFormFieldControl} from '@angular/material/form-field';
import {Subject} from 'rxjs';
import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {ControlValueAccessor, FormControl, NgControl, Validators} from '@angular/forms';
import {isBoolean} from 'ngx-bootstrap/chronos/utils/type-checks';
import {disable} from '@amcharts/amcharts4/.internal/core/utils/Debug';

@Directive()
export abstract class NdlInputFieldBaseDirective<T = any> implements MatFormFieldControl<T>, OnDestroy {
    stateChanges = new Subject<void>();
    focused = false;
    autofilled: boolean;
    shouldLabelFloat: boolean;

    _value: T;
    get value(): T {
        return this.innerFormControl?.value ?? this._value;
    }

    set value(value: T) {
        if (this.value !== value) {
            this._value = value;
            this.innerFormControl?.setValue(value);
        }
        this.stateChanges.next();
    }

    @Input() ngControl: NgControl;

    @Input()
    get required(): boolean {
        return this._required;
    }

    set required(value: BooleanInput) {
        this._required = coerceBooleanProperty(value);
    }

    protected _required: boolean | undefined = false;

    @Input() get placeholder(): string {
        return this._placeholder;
    }
    set placeholder(placeholer: string) {
        this._placeholder = placeholer;
        this.stateChanges.next();
    }

    protected _placeholder: string;

    get disabled(): boolean {
        return this.innerFormControl?.disabled ?? this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = value;
        if (value) {
            this.innerFormControl?.disable();
        } else {
            this.innerFormControl?.enable();
        }
        this.innerFormControl?.updateValueAndValidity();
        this.stateChanges.next();
    }

    private _disabled = false;

    get touched() {
        return this.innerFormControl?.touched ?? this._touched;
    }

    set touched(touched) {
        this._touched = touched;
        if (touched) {
            this.innerFormControl?.markAsTouched();
        } else {
            this.innerFormControl?.markAsUntouched();
        }
        this.innerFormControl?.updateValueAndValidity();
        this.stateChanges.next();
    }

    private _touched = false;

    get empty(): boolean {
        return !this.value;
    }

    get innerFormControl(): FormControl {
        return this.ngControl?.control as FormControl;
    }

    @Input()
    get errorState() {
        return this._errorState;
    }

    set errorState(state) {
        this._errorState = state;
        this.stateChanges.next();
    }

    private _errorState;

    @Output('change') valueChange = new EventEmitter();
    @Output('blur') touchEvent = new EventEmitter();

    abstract id: string;
    abstract controlType: string;

    constructor(protected _elementRef: ElementRef) {
    }

    onFocusIn() {
        this.focused = true;
        this.stateChanges.next();
    }

    onFocusOut() {
        this.focused = false;
        this.touched = true;
        this.stateChanges.next();
    }

    ngOnDestroy() {
        this.stateChanges.complete();
    }

    abstract setDescribedByIds(ids: string[]);

    abstract onContainerClick(event: MouseEvent);
}
