import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'kit-form-radio',
    templateUrl: './kit-form-radio.component.html',
    styleUrls: ['./kit-form-radio.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => KitFormRadioComponent),
        multi: true
    }]
})
export class KitFormRadioComponent implements ControlValueAccessor {

    @Input() fieldName: string;
    @Input() label: string;
    @Input() options: any[];
    @Input() values: string[];
    @Input() smallSpace: boolean = false;
    @Input() isDisplayMode: boolean = false;
    @Input() nbPerLine: number = 2;
    @Input() labelOptionAttribute: string;
    @Input() idElementOptionAttribute: string;
    @Input() valueOptionAttribute: string;

    @Output() bindingChange: EventEmitter<any> = new EventEmitter();

    selectedValue: string;

    propagateChange: any = () => {
    };

    constructor() {
    }

    getNbCol(): number {
        if (this.nbPerLine == 2) return 6;
        if (this.nbPerLine == 3) return 4;
        if (this.nbPerLine == 4) return 3;

        return 6;
    }

    change(e, type) {
        if (e.checked) {
            this.selectedValue = type;
        }
        else {
            this.selectedValue = '';
        }
        this.bindingChange.emit(type);
    }


    check(value1, value2) {
        return value1 == value2
    }


    updateValue(e: Event, option) {
        if (this.selectedValue == option[this.valueOptionAttribute])
            this.selectedValue = null;
        else
            this.selectedValue = option[this.valueOptionAttribute];

        this.propagateChange(this.selectedValue);
        this.bindingChange.emit(option);
    }


    isChecked(value: string) {
        return this.selectedValue == value;
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(value: any): void {
        if (value) {
            this.selectedValue = value;
        }
    }


}
