import {Injectable, Renderer2} from '@angular/core';
import {AuthService} from "./auth.service";
import {Company} from "./wabel-client/entities/company";
import {Subject} from "rxjs";
import {NdlModalDirective} from "needl-lib";
import {SourcingNeed} from "./wabel-client/entities/sourcing_need";
import {SourcingRequest} from "./wabel-client/entities/sourcing_request";

@Injectable({
    providedIn: 'root'
})
export class TemplateService {

    private _displayNavBarMenu: boolean = false;
    private _displayFooter: boolean = false;
    private _displayLandingSourcing: boolean = true;
    private _displaySourcingPriorities: boolean = false;
    private _displayForcePasswordChange: boolean = false;
    public _displayForcePasswordChangeIsFinished: Subject<boolean> = new Subject();
    public displayForcePasswordChangeEvenIfMasquerading: boolean = false;
    public displayProfileWarnings: boolean = true;
    public displayNdlModalProfileRewardsFullyCompleted = true;
    public forceDisplayBuyerScopeModal: boolean = false;
    private _marginNavBar: boolean = true;
    private _paddingTop: boolean = true;
    private _renderer: Renderer2;
    protected popupSignup = {
        open: false,
        loginPage: false,
        isModal: false
    };
    protected premiumPopin = {
        open: false,
        company: null,
        sourcePage: null,
        membershipRequested: null,
        sourcingRequest: null,
        sourcingNeed: null
    };
    openNewAppVersionModal$: Subject<boolean> = new Subject();
    openBuyingScopeModal$ = new Subject();

    constructor(private authService: AuthService) {

    }

    get isDisplayNavBarMenu(): boolean {
        return this._displayNavBarMenu;
    }

    /**
     * In can be use for OnInit maybe AfterViewInit is better
     * @param {boolean} value
     */
    set isDisplayNavBarMenu(value: boolean) {
        setTimeout(() => {
            this._displayNavBarMenu = value;
        });
    }

    get isDisplayFooter(): boolean {
        return this._displayFooter;
    }

    /**
     * In can be use for OnInit maybe AfterViewInit is better
     * @param {boolean} value
     */
    set isDisplayFooter(value: boolean) {
        setTimeout(() => {
            this._displayFooter = value;
        });
    }

    get isDisplayLandingSourcing(): boolean {
        return this._displayLandingSourcing;
    }

    /**
     * In can be use for OnInit maybe AfterViewInit is better
     * @param {boolean} value
     */
    set isDisplayLandingSourcing(value: boolean) {
        setTimeout(() => {
            this._displayLandingSourcing = value;
        });
    }

    get isDisplaySourcingPriorities(): boolean {
        return this._displaySourcingPriorities;
    }

    /**
     * In can be use for OnInit maybe AfterViewInit is better
     * @param {boolean} value
     */
    set isDisplaySourcingPriorities(value: boolean) {
        setTimeout(() => {
            this._displaySourcingPriorities = value;
        });
    }

    get isDisplayForcePasswordChange(): boolean {
        return this._displayForcePasswordChange;
    }

    /**
     * In can be use for OnInit maybe AfterViewInit is better
     * @param {boolean} value
     */
    set isDisplayForcePasswordChange(value: boolean) {
        setTimeout(() => {
            this._displayForcePasswordChange = value;
            this._displayForcePasswordChangeIsFinished.next(!value);
        });
    }

    get hasMarginNavBar(): boolean {
        return this._marginNavBar;
    }

    set hasMarginNavBar(value: boolean) {
        setTimeout(() => {
            this._marginNavBar = value;
        });
    }

    openSignupPopin(loginPage: boolean = false) {
        if (location.pathname === '/user/login' || this.authService.isLogged() || location.pathname === '/join') {
            return;
        }
        this.popupSignup = {
            open: true,
            loginPage: loginPage,
            isModal: true
        };
        this.blockScroll();
    }

    closeSignupPopin() {
        this.popupSignup = {
            open: false,
            loginPage: false,
            isModal: false
        };
        this.unblockScroll();
    }

    openPremiumPopin(company: Company, sourcePage: string = null, membershipRequested: string = 'Essential', sourcingNeed: SourcingNeed = null, souringRequest: SourcingRequest = null) {
        this.premiumPopin = {
            open: true,
            company: company,
            sourcePage: sourcePage,
            membershipRequested: membershipRequested,
            sourcingRequest: souringRequest,
            sourcingNeed: sourcingNeed
        };
        this.blockScroll();
    }

    closePremiumPopin() {
        this.premiumPopin = {
            open: false,
            company: null,
            sourcePage: null,
            membershipRequested: null,
            sourcingNeed: null,
            sourcingRequest: null
        };
        this.unblockScroll();
    }

    signupPopinIsOpen(): boolean {
        return this.popupSignup.open;
    }

    signupPopinIsLoginPage(): boolean {
        return this.popupSignup.loginPage;
    }

    signupPopinIsModal(): boolean {
        return this.popupSignup.isModal;
    }

    premiumPopinIsOpen(): boolean {
        return this.premiumPopin.open;
    }

    premiumPopinCompany(): Company {
        return this.premiumPopin.company;
    }

    premiumPopinSourcingRequest(): SourcingRequest {
        return this.premiumPopin.sourcingRequest;
    }

    premiumPopinSourcingNeed(): SourcingNeed {
        return this.premiumPopin.sourcingNeed;
    }

    premiumPopinSourcePage() {
        return this.premiumPopin.sourcePage;
    }

    premiumPopinRembershipRequested() {
        return this.premiumPopin.membershipRequested;
    }

    blockScroll() {
        this.renderer.addClass(document.body, 'popin-open');
    }

    unblockScroll() {
        this.renderer.removeClass(document.body, 'popin-open');
    }

    get renderer(): Renderer2 {
        return this._renderer;
    }

    set renderer(value: Renderer2) {
        this._renderer = value;
    }

    isInUrl(value: string) {
        return window.location.href.indexOf(value) !== -1;
    }

    get hasPaddingTop(): boolean {
        return this._paddingTop;
    }

    set hasPaddingTop(value: boolean) {
        setTimeout(() => {
            this._paddingTop = value;
        });
    }
}
