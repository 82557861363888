import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'kit-slides-tuto',
    templateUrl: './kit-slides-tuto.component.html',
    styleUrls: ['./kit-slides-tuto.component.scss']
})
export class KitSlidesTutoComponent implements OnInit {

    @Input() hasCloseIcon = true;
    @Input() backdropClose = true;
    @Input() isFullscreen = false;
    @Input() isMobileFullscreen = true;
    @Output() onClose = new EventEmitter();

    @Input() steps: {image: string, title: string, content: string, nextText: string}[] = [];

    currentStep = 0;

    constructor() {}

    ngOnInit() {}

    nextStep() {
        if (this.steps[this.currentStep + 1]) {
            this.currentStep++;
        } else {
            this.onClose.emit();
        }
    }

    goToStep(step: number) {
        this.currentStep = step;
    }

    getCurrentStep() {
        return this.steps[this.currentStep];
    }
}
