import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FileToUpload} from "../../../../services/wabel-client/entities/fileToUpload";

@Component({
    selector: 'submission-documents-step',
    templateUrl: './submission-documents-step.component.html',
    styleUrls: ['./submission-documents-step.component.scss']
})
export class SubmissionDocumentsStepComponent implements OnInit {
    @Input() submissionOldFiles: File[];
    @Input() submissionNewFiles: FileToUpload[];

    @Output() submissionNewFilesChange = new EventEmitter<FileToUpload[]>();
    @Output() submissionOldFilesChange = new EventEmitter<File[]>();
    @Input() isStillUploading = false;

    constructor() {
    }

    ngOnInit() {
    }


}
