import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NdlAlertComponent } from './components/alert/alert.component';
import { NdlAlertTitleDirective } from './directives/alert-title.directive';

@NgModule({
  declarations: [NdlAlertComponent, NdlAlertTitleDirective],
  imports: [
    CommonModule
  ],
  exports: [NdlAlertComponent, NdlAlertTitleDirective],
})
export class NeedlAlertModule { }
