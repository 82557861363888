import {Component, HostBinding, Input} from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Component({
    selector: 'ndl-submenu-item',
    templateUrl: './submenu-item.component.html',
    host: {
        "class": "ndl-submenu-item"
    }
})
export class NdlSubmenuItemComponent {
    @Input() link: string;

    private _external = false;
    @Input() get external() {
        return this._external;
    }
    set external(external) {
        this._external = coerceBooleanProperty(external);
    }

    private _disabled = false;
    @Input() @HostBinding('class.ndl-submenu-item-disabled') get disabled() {
        return this._disabled;
    }
    set disabled(disabled) {
        this._disabled = coerceBooleanProperty(disabled);
    }
}
