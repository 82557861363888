import {Directive, HostListener} from '@angular/core';
import {Subject} from 'rxjs';

@Directive({
    selector: '[ndlStepNext]'
})
export class NdlStepNextDirective {
    _forward: Subject<boolean> = new Subject<boolean>();
    get goForward() {
        return this._forward.asObservable();
    }

    @HostListener('click') onClick() {
        this._forward.next(true);
    }
}
