import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {NdlSelectOption} from "../../NdlSelectOption";

@Component({
  selector: 'ndl-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})

export class NdlMultiSelectComponent {
    @Input() placeholder = 'Select options';
    @Input() options: NdlSelectOption[] = [];
    @Input() selectedOptions: NdlSelectOption[] = [];
    @Input() materialIconBefore = 'add_circle_outline';
    @Input() buttonLabel = 'Apply';
    @Input() searchable = false;
    @Input() searchPlaceholder = 'Search';
    @Input() displayOnlySelectedOptionsWithoutChildren = false;
    @Input() showError = false;
    @Input() errorText = 'This field is required';
    @Input() canSelectOnlyLastLevel = false;
    @Input() maximumSelectedOption: number;
    @Input() label: string;
    @Input() hint: string;
    @Input() required = false;

    @Output() listChanged = new EventEmitter<NdlSelectOption[]>();

    isListOpened = false;
    clickWasInsideComponent = false;

    constructor() {}

    @HostListener("click", ["$event"])
    clickInsideComponent(e) {
        this.clickWasInsideComponent = true;
    }

    @HostListener("document:click", ["$event"])
    clickOutsideComponent() {
        if (!this.clickWasInsideComponent) {
            this.isListOpened = false;
        }
        this.clickWasInsideComponent = false;
    }

    removeOption(option: NdlSelectOption) {
        this.selectedOptions = this.selectedOptions.filter(ndlOption => ndlOption.value !== option.value);
        this.listChanged.emit(this.selectedOptions);
    }

    get selectOptionsToDisplay() {
        return this.displayOnlySelectedOptionsWithoutChildren ? this.selectedOptions.filter(opt => !opt.children || !opt.children.length) : this.selectedOptions;
    }

    clearList() {
        this.selectedOptions = [];
        this.listChanged.emit(this.selectedOptions);
    }
}
