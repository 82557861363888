import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NdlExpanderComponent} from "./expander.component";
import {MatIconModule} from '@angular/material/icon';

@NgModule({
    declarations: [
        NdlExpanderComponent,
    ],
    imports: [
        CommonModule,
        MatIconModule,
    ],
    exports: [
        NdlExpanderComponent,
    ],
})
export class NeedlExpanderModule { }
