import {NgModule} from '@angular/core';
import {APOLLO_OPTIONS} from 'apollo-angular';
import {ApolloClientOptions, InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import {environment} from '../environments/environment';
import {HttpClientModule} from '@angular/common/http';
import {createUploadLink} from 'apollo-upload-client';

const uri = environment.needl_back_end_url + '/graphql';

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
    return {
        link: createUploadLink({
            uri: uri,
            withCredentials: true,
            credentials: 'include',
            headers: {
                'ngsw-bypass': true
            }
        }),
        cache: new InMemoryCache({
            addTypename: true
        }),
    };
}

@NgModule({
    imports: [
        HttpClientModule
    ],
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [HttpLink],
        },
    ],
})
export class GraphQLModule {
}
