import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'ndl-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NdlTooltipComponent {
    @Input() tooltipText: string;

}
