import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NdlSelectComponent} from './select.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {NeedlFormFieldModule} from '../form-field/form-field.module';
import {MatInputModule} from "@angular/material/input";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatChipsModule} from "@angular/material/chips";



@NgModule({
    declarations: [
        NdlSelectComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatIconModule,
        MatSelectModule,
        NeedlFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatChipsModule
    ],
    exports: [
        NdlSelectComponent
    ]
})
export class NeedlSelectModule { }
