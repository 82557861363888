import {
    AfterContentInit,
    AfterViewInit,
    Component, ContentChildren, ElementRef, EventEmitter,
    forwardRef, HostBinding, Input, OnDestroy, OnInit, Output, QueryList, ViewChild
} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {NdlInputBaseDirective} from '../input-base.directive';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {NdlOption, NdlOptionDirective} from '../option.directive';
import {map, startWith} from 'rxjs/operators';
import {Observable, Subscription} from 'rxjs';
import {NdlOptionGroupDirective} from "../optiongroup.directive";
import {MatOption} from "@angular/material/core";
import {MatSelect} from "@angular/material/select";
import {MatInput} from '@angular/material/input';
import {NdlTagsComponent} from "../tags/tags.component";
import {MatChipEvent} from "@angular/material/chips";

@Component({
    selector: 'ndl-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    host: {
        'class': 'ndl-select',
        '(blur)': "onTouched()"
    }
})
export class NdlSelectComponent<T = any> extends NdlInputBaseDirective<T | T[]> implements AfterContentInit, AfterViewInit, OnDestroy {
    override defaultValue = null;
    override _value = null;
    allSelected = false;
    chips: Observable<NdlOption<string>[]>;
    @HostBinding('class.ndl-tags') useNeedlTags = false;

    private _getChips(value: string[]) {
        return value.map(v => this.options?.concat(this.innerFormControl.value).find(o => o && v && o.value === v) ?? NdlTagsComponent._getNewOption(v));
    }

    delete($event: MatChipEvent) {
        if (Array.isArray(this.value)) {
            this.matRef.options.forEach(opt => opt.value === $event.chip.value ? opt.deselect() : null);
        }
    }

    private _multiple = false;
    @Output('searchValueChanged') searchValueChanged = new EventEmitter();

    @Input() get multiple() {
        return this._multiple;
    }

    set multiple(multiple: boolean) {
        this._multiple = coerceBooleanProperty(multiple);
    }

    private _bypassInnerSearch = false;
    @Input() get bypassInnerSearch() {
        return this._bypassInnerSearch;
    }

    set bypassInnerSearch(bypassInnerSearch: boolean) {
        this._bypassInnerSearch = coerceBooleanProperty(bypassInnerSearch);
    }

    private _showBasicTitle = false;
    @Input() get showBasicTitle() {
        return this._showBasicTitle;
    }

    set showBasicTitle(showBasicTitle: boolean) {
        this._showBasicTitle = coerceBooleanProperty(showBasicTitle);
    }

    private _showCustomTitle = false;
    @Input() get showCustomTitle() {
        return this._showCustomTitle;
    }

    set showCustomTitle(showCustomTitle: boolean) {
        this._showCustomTitle = coerceBooleanProperty(showCustomTitle);
    }

    private _useTagsAsSelectTrigger = false;
    @Input() get useTagsAsSelectTrigger() {
        return this._useTagsAsSelectTrigger;
    }

    set useTagsAsSelectTrigger(useTagsAsSelectTrigger: boolean) {
        this._useTagsAsSelectTrigger = coerceBooleanProperty(useTagsAsSelectTrigger);
    }

    @Input() searchPlaceholderValue = 'Search';
    private _search = false;
    @Input() get search() {
        return this._search;
    }

    set search(search: boolean) {
        this._search = coerceBooleanProperty(search);
    }

    searchValue: string;
    searchSubscription: Subscription;

    @ContentChildren(NdlOptionDirective, {descendants: false}) _options: QueryList<NdlOptionDirective>;
    options: NdlOptionDirective[];
    originalOptions: NdlOptionDirective[];

    @ContentChildren(NdlOptionGroupDirective) _optionGroups: QueryList<NdlOptionGroupDirective>;
    optionGroups: NdlOptionGroupDirective[];
    openOptGroups: number[] = [];

    @ViewChild(MatSelect, {static: true}) input: MatSelect;

    toggleOptGroup(index: number) {
        const i = this.openOptGroups.indexOf(index);
        if (i > -1) {
            this.openOptGroups.splice(i, 1);
        } else {
            this.openOptGroups.push(index);
        }
    }

    ngAfterContentInit() {
        this.options = this._options.toArray();
        this.optionGroups = this._optionGroups.toArray();
    }

    ngAfterViewInit(): void {
        this.input.ngControl = this.ngControl;
        this.searchSubscription = this._options.changes.subscribe((queryList: QueryList<NdlOptionDirective>) => {
            const search = this.searchValue ? this.searchValue.toLowerCase().replace(/ /g, '') : null;
            if (this.search && search && !this.bypassInnerSearch) {
                this.options = queryList.filter(option => option.label.toLowerCase().includes(search.toLowerCase()));
            } else {
                this.options = queryList.toArray();
            }
        });
        this.useNeedlTags = this.useTagsAsSelectTrigger;
        if (this.useNeedlTags) {
            this.chips = this.innerFormControl?.valueChanges.pipe(startWith(this.value), map(value => this._getChips(value)));
        }
    }

    ngOnDestroy() {
        this.searchSubscription.unsubscribe();
    }


    private _clearable = false;
    @Input() get clearable() {
        return this._clearable;
    }

    set clearable(clearable: boolean) {
        this._clearable = coerceBooleanProperty(clearable);
    }

    @ViewChild('matRef') matRef: MatSelect;
    clear() {
        this.matRef.options.forEach((data: MatOption) => data.deselect());
    }

    @Input() selectAllLabel = 'Select All';
    private _showSelectAll = false;
    @Input() get showSelectAll() {
        return this._showSelectAll;
    }

    set showSelectAll(showSelectAll: boolean) {
        this._showSelectAll = coerceBooleanProperty(showSelectAll);
    }

    toggleAllSelection() {
        if (this.allSelected) {
            this.matRef.options.forEach((data: MatOption) => data.select());
        } else {
            this.clear();
        }
    }

    get allChecked() {
        return this.matRef && this.matRef.options && (this.matRef.options.filter(opt => opt.selected).length === this.matRef.options.length
            ||  (this.innerFormControl.value && ((this.matRef.options.length - 1) === this.innerFormControl.value.filter(v => v !== undefined && v !== null).length)));
    }

    hideOption(option: NdlOptionDirective) {
        return this.searchValue && this.searchValue !== '' && !option.label.toLowerCase().includes(this.searchValue.toLowerCase());
    }

    hideGroup(group: NdlOptionGroupDirective) {
        return this.searchValue && this.searchValue !== '' && !group.label.toLowerCase().includes(this.searchValue.toLowerCase())
            && !group.allOptions.filter(o => !this.hideOption(o)).length;
    }
}
