import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {VideoMeetingRequest} from "../../../services/wabel-client/entities/video_meeting_request";
import {VideoMeetingService} from "../../../services/wabel-client/services/video_meeting.service";
import {UtilsService} from "../../../services/utils.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../../services/auth.service";

@Component({
    selector: 'app-video-meeting-request-cancel-meeting',
    templateUrl: './video-meeting-request-cancel-meeting.component.html',
    styleUrls: ['./video-meeting-request-cancel-meeting.component.scss']
})
export class VideoMeetingRequestCancelMeetingComponent implements OnInit {
    @Input() videoMeetingRequest: VideoMeetingRequest;
    @Input() isCancel = false;

    @Output() videoMeetingCanceled = new EventEmitter();

    loading = false;
    cancelForm: FormGroup;
    step: 'reason'|'validated' = 'reason';

    constructor(public utilsService: UtilsService,
                private videoMeetingService: VideoMeetingService,
                private fb: FormBuilder,
                public authService: AuthService) {
    }

    ngOnInit() {
        this.cancelForm = this.fb.group({
            statusReason: this.fb.control(null, Validators.required)
        });
    }

    cancelMeeting(value) {
        if (this.loading) {
            return;
        }
        this.cancelForm.markAllAsTouched();
        if (this.cancelForm.invalid) {
            return;
        }
        this.loading = true;
        let statusReason = value.statusReason;
        this.videoMeetingService.cancelVideoMeetingRequest(this.videoMeetingRequest.id, statusReason).subscribe((vmr) => {
            this.videoMeetingRequest.status = vmr.status;
            this.videoMeetingRequest.statusReason = vmr.statusReason;
            this.videoMeetingCanceled.emit();
            this.loading = false;
            this.step = 'validated';
        }, (e) => {
            this.loading = false;
            console.error(e);
        });
    }
}
