<div class="container-fluid">
    <div class="row row-cols-6">
        <div *ngFor="let status of statuses" class="col mb-2">
            <ndl-status [color]="status">
                <mat-icon>star</mat-icon>
                Status {{status}}
            </ndl-status>
        </div>
    </div>
</div>
