import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CustomDateAdapter, CustomDateFormats} from "../../custom-date-adapter";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {Platform} from "@angular/cdk/platform";
import {NdlCustomHeaderDatePickerComponent} from "./custom-header-date-picker/custom-header-date-picker.component";
import {DatePickerService} from "../../service/date-picker.service";

@Component({
  selector: 'ndl-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: CustomDateAdapter,
            deps: [MAT_DATE_LOCALE, Platform]
        },

        {provide: MAT_DATE_FORMATS, useValue: CustomDateFormats},
    ],
})

export class NdlDatePickerComponent implements OnInit {

    @Input() selectedDate: Date;
    @Input() datePickerPlaceholder = 'Choose a date';
    @Input() customTitle = null;
    @Input() showValidationButton = true;
    @Input() validationButtonLabel = 'Done';
    @Input() view = 'month';
    @Input() prefixIcon = null;
    @Input() suffixIcon = null;
    @Input() showError = false;
    @Input() errorText = 'This field is required';
    @Input() minDate: Date = null;
    @Input() maxDate: Date = null;

    datePickerIsOpen = false;
    customDatePickerHeader = NdlCustomHeaderDatePickerComponent;

    @Output() dateChanged = new EventEmitter<Date>();
    constructor(private datePickerService: DatePickerService) {
    }

    ngOnInit(): void {
        this.datePickerService.customTitle = this.customTitle;
    }

    dateChange(event: any) {
        this.dateChanged.emit(new Date(event.value));
    }
}
