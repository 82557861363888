import {NgModule} from '@angular/core';
import {RetailGroupCardOfficesComponent} from "./retail-group-card-offices.component";
import {CommonModule} from "@angular/common";
import {LazyLoadImageModule} from "ng-lazyload-image";
import {RouterModule} from "@angular/router";
import {SharedModule} from '../../../../shared-module/shared.module';


@NgModule({
    declarations: [
        RetailGroupCardOfficesComponent
    ],
  imports: [
    CommonModule,
    LazyLoadImageModule,
    RouterModule,
    SharedModule
  ],
    exports: [
        RetailGroupCardOfficesComponent
    ]
})
export class RetailGroupCardOfficesModule {
}
