import {Directive} from '@angular/core';

@Directive({
    selector: 'app-company-card-footer, [app-company-card-footer], [appCompanyCardFooter]',
    host: {
        class: 'company-card-footer w-100'
    }
})
export class CompanyCardFooterDirective {
}
