import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CompanyPreviewComponent} from './company-preview.component';
import {ProductPreviewModule} from "../../products/product-preview/product-preview.module";
import {NeedlTooltipModule, NeedlTagModule} from "needl-lib";
import {SharedModule} from '../../../modules/shared-module/shared.module';


@NgModule({
    declarations: [
        CompanyPreviewComponent,
    ],
  imports: [
    CommonModule,
    NeedlTagModule,
    ProductPreviewModule,
    NeedlTooltipModule,
    SharedModule
  ],
    exports: [
        CompanyPreviewComponent,
    ]
})
export class CompanyPreviewModule {
}
