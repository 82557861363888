import {Component, Output, EventEmitter, Input, OnInit} from '@angular/core';
import {environment} from "../../../../../../../environments/environment";

@Component({
    selector: 'registration-password',
    templateUrl: './registration-password.component.html',
    styleUrls: ['./registration-password.component.scss']
})
export class RegistrationPasswordComponent implements OnInit {

    @Input() user;
    @Input() saving = false;

    formCompleted = false;
    formSubmitted = false;
    passwordConfirmation: string;
    isSamePassword = false;
    isStrongPassword = false;
    mailCopy = '';
    errorTc = false;

    @Output() stepFinished: EventEmitter<any> = new EventEmitter();
    needl_full_url = environment.needl_url;

    constructor()
    {}

    ngOnInit() {
        this.mailCopy = this.user.email;
    }

    submitForm() {
        this.formSubmitted = true;
        this.checkFormErrors();
        if (!this.formCompleted) {
            return;
        }
        this.stepFinished.emit(this.user);
    }

    checkFormErrors() {
        if (!this.formSubmitted) {
            return;
        }

        this.formCompleted = true;

        this.isStrongPassword = true;
        if (!this.user.password || this.user.password.length < 8) {
            this.isStrongPassword = false;
            this.formCompleted = false;
        }

        this.isSamePassword = true;
        if (this.user.password !== this.passwordConfirmation) {
            this.isSamePassword = false;
            this.formCompleted = false;
        }

        this.errorTc = false;
        if (!this.user.tcAccepted) {
            this.errorTc = true;
            this.formCompleted = false;
        }
    }
}
