import {Component, ContentChild, TemplateRef, ViewChild} from '@angular/core';

@Component({
    selector: 'ndl-slide,[ndl-slide],[ndlSlide]',
    templateUrl: './slide.component.html',
    host: {
        class: 'ndl-slide'
    }
})
export class NdlSlideComponent {
    @ViewChild(TemplateRef, {static: true}) content: TemplateRef<any>;
}
