import {
    Component, Input
} from '@angular/core';
import {NdlChartBaseDirective, NdlAxis} from './chart-base.directive';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import {coerceBooleanProperty} from "@angular/cdk/coercion";

@Component({
    selector: 'ndl-map-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.scss'],
    host: {
        'class': 'ndl-xy-chart'
    }
})
export class MapChartComponent extends NdlChartBaseDirective<am4maps.MapChart> {
    type = am4maps.MapChart;

    @Input() useRoundMarker = false;
    @Input() excludedCountries;

    private _showZoom = false;
    @Input() get showZoom() {
        return this._showZoom;
    }

    set showZoom(showZoom: boolean) {
        this._showZoom = coerceBooleanProperty(showZoom);
    }

    setConfig() {
        this.chart.geodata = am4geodata_worldLow;
        this.chart.projection = new am4maps.projections.Miller();
        const polygonSeries = this.chart.series.push(new am4maps.MapPolygonSeries());
        polygonSeries.useGeodata = true;
        polygonSeries.hiddenInLegend = true;
        polygonSeries.exclude = this.excludedCountries;
        const polygonTemplate = polygonSeries.mapPolygons.template;
        polygonTemplate.tooltipText = "{name}";
        polygonTemplate.fill = am4core.color("#D3D5D8");

        if (this.showZoom) {
            const zoomControl = new am4maps.ZoomControl();
            this.chart.zoomControl = zoomControl;
            zoomControl.slider.height = 100;
            this.chart.chartContainer.wheelable = false;
        }

        if (this.useRoundMarker) {
            this.chart.legend.useDefaultMarker = true;
            this.chart.legend.align = 'right';
            this.chart.legend.valign = 'top';
            const marker: any = this.chart.legend.markers.template.children.getIndex(0);
            marker.cornerRadius(12, 12, 12, 12);
        }

        // used to block auto-zoom when data is added
        polygonTemplate.events.on("hit", function (event) {
            this.chart.maxZoomLevel = 32;
            this.chart.zoomToMapObject(event.target);
            this.chart.maxZoomLevel = 1;
        }, this);
        this.reloadSeries(false);
    }

    reloadSeries(reload = true) {
        if (reload) {
            this.chart.series.clear();
            const polygonSeries = this.chart.series.push(new am4maps.MapPolygonSeries());
            polygonSeries.useGeodata = true;
            polygonSeries.hiddenInLegend = true;
            polygonSeries.exclude = this.excludedCountries;
        }

        for (const item of this.data) {
            const series = this.chart.series.push(new am4maps.MapPolygonSeries());
            series.name = item.name;
            series.useGeodata = true;
            series.include = item.countries.map(c => c.id);
            series.data = item.countries;
            series.mapPolygons.template.tooltipText = "{name}";
            series.mapPolygons.template.fill = am4core.color(item.color);
            series.fill = am4core.color(item.color);
            series.tooltip.getFillFromObject = false;
            series.tooltip.autoTextColor = false;
            series.tooltip.label.fill = am4core.color('black');
            series.tooltip.background.fill = am4core.color("#D3D5D8");
            series.exclude = this.excludedCountries;

            series.mapPolygons.template.adapter.add("fill", function(fill, target) {
                if (target.dataItem.dataContext && target.dataItem.dataContext['color']) {
                    const pattern = new am4core.LinePattern();
                    pattern.width = 5;
                    pattern.height = 5;
                    pattern.stroke = am4core.color(target.dataItem.dataContext['color']);
                    pattern.strokeWidth = 1;
                    pattern.rotation = 45;
                    pattern.backgroundFill = am4core.color(item.color);
                    pattern.backgroundOpacity = 1;
                    return pattern;
                }
                return am4core.color(item.color);
            });
        }
    }
}
