import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Company} from "../../../services/wabel-client/entities/company";
import {CompanyService} from "../../../services/wabel-client/services/company.service";

@Component({
  selector: 'app-single-auto-complate',
  templateUrl: './single-auto-complate.component.html',
  styleUrls: ['./single-auto-complate.component.scss']
})
export class SingleAutoComplateComponent implements OnInit {

    @Input() options : Company[]=[];

    @Input() label=null;
    @Input() InitSelectedValues : Company[]=[];
    @Output() selectedElementHasChanged: EventEmitter<any> = new EventEmitter();

    myControl = new FormControl();
    filteredOptions: Company[]=[];
    chipData:Company[]=[];
    loading=false;
    constructor(private companyService: CompanyService) { }



    ngOnInit() {
        if (this.InitSelectedValues.length != 0){
            this.chipData = this.InitSelectedValues;
        }
        this.myControl.valueChanges.subscribe(value => {
            if (value.length > 3){
                this.loading = true;
                this.companyService.getSimpleListByFilter(20, 0, 'name', 'ASC', value, []).subscribe((data) => {
                    if (!data) return;
                    this.loading = false;
                    this.filteredOptions = data.items;
                });
            }else{
                this.filteredOptions = [];
            }
        });
    }

    addToChips(option:Company){
        const index: number = this.chipData.indexOf(option);
        if (index == -1) {
            this.chipData.push(option);
            this.myControl.setValue('');
            this.options.splice(index, 1);
            this.selectedElementHasChanged.emit(this.chipData);
        }
    }

    remove(option:Company){
        const index: number = this.chipData.indexOf(option);
        if (index !== -1) {
            this.chipData.splice(index, 1);
            this.options.push(option);
            this.selectedElementHasChanged.emit(this.chipData);
        }
    }

}
