<div class="col-12 pl-0" *ngIf="label.length > 0">
    <label class="wb-label">
        <span>
            <ng-container>{{label}}</ng-container>
            <span *ngIf="isRequired" class="wb-required">*</span>
        </span>
    </label>
</div>
<div class="multi-select {{(showError?'border border-danger rounded':'')}}">
    <div class="input-trigger" (click)="isListOpened = !isListOpened" [class.opened]="isListOpened">
        <div class="input-tags-container">
            <div class="input-tag mr-3 mb-3" *ngFor="let option of selectedOptions">
                <ndl-tag removable (removed)="removeOption(option); isListOpened = !isListOpened">{{option.label}}</ndl-tag>
            </div>
            <ng-container>
                <span class="input-trigger-open" *ngIf="!isListOpened || !selectedOptions.length">
                    <span class="material-icons-outlined">add_circle_outline</span>
                    <span class="input-trigger-label">{{placeholder}}</span>
                </span>
            </ng-container>
            <span *ngIf="selectedOptions.length" (click)="clearList(); isListOpened = !isListOpened"
                  class="material-icons input-clear">clear</span>
        </div>
    </div>
    <div *ngIf="isListOpened" class="input-list">
        <div class="input-search" *ngIf="searchable">
            <span class="material-icons-outlined">search</span>
            <input class="input-search-value" type="text" [(ngModel)]="searchValue" (input)="searchChanged()"
                   placeholder="{{searchPlaceholder}}"/>
        </div>
        <span class="input-list-title">{{inputListTitle}}</span>
        <div class="input-list-items">
            <div *ngFor="let option of filteredOptions" class="input-list-item" title="{{option.label}}"
                 (click)="selectOption(option)">
                <span class="material-icons-outlined">add_circle_outline</span>
                <span class="input-list-item-label">{{option.label}}</span>
            </div>
            <div *ngIf="!filteredOptions || !filteredOptions.length" class="input-list-item">
                <span>{{noOptionLabel}}</span>
            </div>
        </div>
        <button class="confirm-btn" ndl-button (click)="isListOpened = false;">{{buttonLabel}}</button>
    </div>
</div>
<div class="col-12 pl-0 mb-5" *ngIf="showError">
    <p class="text-danger text-s"><small><b>{{errorMessage}}</b></small></p>
</div>
