import {WabelEntity} from "./wabel-entity.abstract";

export class UserInfoTagManager extends WabelEntity {

    visitorId: number;
    visitorEmail: string;
    visitorFullname: string;
    visitorLoginState: string;
    visitorStatus: string;
    visitorType: string;
    visitorAccountManager: string;
    buyerSpecialisation: string;
    buyerStatus: string;
    buyerPriority: string;
    supplierCategory: string;
    supplierScore: number;
    visitorSummitParticipation: string;
    visitorCountrySelection: string;

    get id(): number {
        return this.visitorId;
    }

    toJSON(object): any {
        let jsoned = {};
        let toConvert = object || this;
        Object.getOwnPropertyNames(toConvert).forEach((prop) => {
            const val = toConvert[prop];
            // don't include those
            if (prop === 'toJSON' || prop === 'constructor') {
                return;
            }
            if (typeof val === 'function') {
                jsoned[prop] = val.bind(jsoned);
                return;
            }
            jsoned[prop] = val;
        });

        const inherited = Object.getPrototypeOf(toConvert);
        if (inherited !== null) {
            Object.keys(this.toJSON(inherited)).forEach(key => {
                if (!!jsoned[key] || key === 'constructor' || key === 'toJSON')
                    return;
                if (typeof inherited[key] === 'function') {
                    jsoned[key] = inherited[key].bind(jsoned);
                    return;
                }
                jsoned[key] = inherited[key];
            });
        }
        return jsoned;
    }
}
