<div *ngIf="showPreview" class="ndl-upload-file-image" [class.ndl-profile-picture]="isProfile"
     [style.backgroundImage]="'url('+ previewUrl +')'"></div>
<div class="ndl-upload-file-info">
    <div class="ndl-upload-file-name">
        <a *ngIf="!showPreview && previewUrl; else fileTitle" [href]="previewUrl" target="_blank">
            <ng-container *ngTemplateOutlet="fileTitle"></ng-container>
        </a>
        <ng-template #fileTitle>{{file?.fileName}}</ng-template>
    </div>
    <ng-container *ngIf="!error || (error && status === 1); else errorTemplate" [ngSwitch]="status">
        <button *ngSwitchCase="0" ndl-button outlined type="button" (click)="upload()" [disabled]="disabled">
            Upload
        </button>
        <mat-progress-bar *ngSwitchCase="1" mode="indeterminate"></mat-progress-bar>
        <div *ngSwitchCase="2" class="ndl-upload-file-info-extra">
            <ng-container *ngIf="fileExt || fileSize">
                {{fileExt | uppercase}} {{fileSize | fileSizeFormat}}<br>
            </ng-container>
            <a *ngIf='for?.editLink && !disabled' (click)="edit.emit()">{{for.editLink}}</a>
        </div>
    </ng-container>
    <ng-template #errorTemplate>
        <div *ngIf="file.error.name === 'wrong-type'">This file type is not allowed!</div>
        <div *ngIf="file.error.name === 'size-too-high'">The size of the file is too high (max size is {{maxSizeMB.toString()}}MB)!</div>
        <div *ngIf="file.error.name === 'dimensions-too-high'">The dimensions are too high (max dimensions are {{maxWidthPx.toString()}}x{{maxHeightPx.toString()}} px)!</div>
        <div *ngIf="file.error.name === 'server-error'">An error occurred when uploading the file: the server doesn't accept this file.</div>
    </ng-template>
</div>
<div class="ndl-upload-file-action" *ngIf="!disabled">
    <button mat-icon-button type="button" (click)="delete()">
        <mat-icon>close</mat-icon>
    </button>
</div>
