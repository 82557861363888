import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {VideoMeetingRequest} from "../../../services/wabel-client/entities/video_meeting_request";
import {VideoMeetingService} from "../../../services/wabel-client/services/video_meeting.service";
import {UtilsService} from "../../../services/utils.service";
import {
    VideoMeetingMemberAvailability
} from "../../../services/wabel-client/entities/video_meeting_member_availability";
import {
    VideoMeetingAvailabilitiesComponent
} from "../video-meeting-request-modal/video-meeting-request-modal-step-availabilities/video-meeting-availabilities/video-meeting-availabilities.component";
import * as dayjs from "dayjs";
import {CalendarEvent} from "angular-calendar";
import {AuthService} from "../../../services/auth.service";
import * as utc from "dayjs/plugin/utc";
import * as timezone from "dayjs/plugin/timezone";
import {NdlSnackbarService} from 'needl-lib';

@Component({
    selector: 'app-video-meeting-request-schedule-meeting',
    templateUrl: './video-meeting-request-schedule-meeting.component.html',
    styleUrls: ['./video-meeting-request-schedule-meeting.component.scss']
})
export class VideoMeetingRequestScheduleMeetingComponent implements OnInit {
    @Input() videoMeetingRequest: VideoMeetingRequest;
    @Input() view: 'buyer'|'supplier';

    @Output() closeModal: EventEmitter<any> = new EventEmitter();

    @ViewChild('counterpartCalendar') counterpartCalendar: VideoMeetingAvailabilitiesComponent;
    @ViewChild('ownCalendar') ownCalendar: VideoMeetingAvailabilitiesComponent;

    loading = false;
    step: 'counterpart-availabilities'|'my-availabilities'|'confirmation'|'validated' = 'counterpart-availabilities';
    counterPartMemberAvailabilities: VideoMeetingMemberAvailability[];
    availabilitiesLoading = true;
    errorMessage: string;
    slotSelected: CalendarEvent;
    dayjs = dayjs;

    constructor(public videoMeetingService: VideoMeetingService,
                public utilsService: UtilsService,
                private snackbar: NdlSnackbarService,
                public authService: AuthService) {
    }

    ngOnInit() {
        dayjs.extend(utc);
        dayjs.extend(timezone);
        this.availabilitiesLoading = true;
        this.videoMeetingService.getVideoMeetingAvailabilitiesByMember(this.videoMeetingRequest.counterPartMember.idmember, true).subscribe((availabilities) => {
            this.counterPartMemberAvailabilities = availabilities;
            if (!this.counterPartMemberAvailabilities?.length) {
                this.step = 'my-availabilities';
            }
            this.availabilitiesLoading = false;
        }, (e) => {
            console.error(e);
        });
    }

    confirmAvailability() {
        this.errorMessage = null;
        if (!this.counterpartCalendar.eventSelected) {
            this.errorMessage = 'Please select a slot';
            return;
        }
        this.slotSelected = this.counterpartCalendar.eventSelected;
        this.step = 'confirmation';
    }

    proposeNewTimeslots() {
        this.errorMessage = null;
        // Checks
        if (this.ownCalendar?.availabilities?.length < 1) {
            this.errorMessage = 'Please add some available times that we can propose to ' + this.videoMeetingRequest.counterPartCompany.name + '.';
            this.loading = false;
            return;
        } else if (this.getTotalDurationOfAvailabilities(this.ownCalendar?.availabilities?.filter(availability => (dayjs(availability.end).diff(availability.start, 'minute') >= this.videoMeetingRequest.duration))) < (this.videoMeetingRequest.duration * 3)) {
            this.errorMessage = 'In order to give enough time proposition to ' + this.videoMeetingRequest.counterPartCompany.name + ' please add at least 3 blocks of availabilities of ' + this.utilsService.getReadableDuration(this.videoMeetingRequest.duration) + ' or more.';
            this.loading = false;
            return;
        } else if (this.ownCalendar?.availabilities?.filter(availability => (dayjs(availability.end).diff(availability.start, 'minute') < this.videoMeetingRequest.duration)).length > 0) {
            if (!window.confirm('Some of your availabilities do not match the duration of ' + this.utilsService.getReadableDuration(this.videoMeetingRequest.duration) + '. Only matching availabilities will be proposed. Do you want to send your proposition anyway ?')) {
                this.loading = false;
                return;
            }
        }
        this.loading = true;
        this.videoMeetingService.proposeNewTimeslotsForVideoMeetingRequest(this.videoMeetingRequest.id).subscribe((vmr) => {
            this.videoMeetingRequest.status = vmr.status;
            this.snackbar.show('Your new timeslots have been sent to ' + this.videoMeetingRequest.counterPartCompany.name + '.', 'error');
            this.closeModal.emit();
        }, (e) => {
            this.loading = false;
            console.error(e);
        })
    }

    confirmMeeting() {
        if (this.loading || !this.slotSelected) {
            return;
        }
        this.loading = true;
        this.videoMeetingService.createVideoMeeting(this.videoMeetingRequest.id, dayjs(this.slotSelected.start).format('YYYY-MM-DD HH:mm Z'), dayjs(this.slotSelected.end).format('YYYY-MM-DD HH:mm Z')).subscribe((vm) => {
            this.videoMeetingRequest.status = 'accepted';
            this.videoMeetingRequest.meeting = vm;
            this.loading = false;
            this.step = 'validated';
        }, (e) => {
            this.loading = false;
            this.errorMessage = e;
            console.error(e);
            this.step = 'counterpart-availabilities';
        });
    }

    getTotalDurationOfAvailabilities(availabilities: CalendarEvent[]): number {
        let total = 0;
        for (let availability of availabilities) {
            total += dayjs(availability.end).diff(availability.start, 'minute');
        }
        return total;
    }
}
