import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'minimizedCurrencyUnits'
})
export class CurrencyUnitsMinimizePipe implements PipeTransform {

    transform(input: string): string {
        return  input?.replace('million', 'M').replace('billion', 'Bn');
    }

}
