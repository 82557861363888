export const NDL_GREEN_INDEX = 0;
export const NDL_GREEN_PRIMARY = 4;
export const NDL_BLUE_INDEX = 1;
export const NDL_BLUE_PRIMARY = 4;
export const NDL_RED_INDEX = 2;
export const NDL_RED_PRIMARY = 3;
export const NDL_YELLOW_INDEX = 3;
export const NDL_YELLOW_PRIMARY = 3;
export const NDL_PURPLE_INDEX = 4;
export const NDL_PURPLE_PRIMARY = 4;
export const NDL_ORANGE_INDEX = 5;
export const NDL_ORANGE_PRIMARY = 6;
export const NDL_COBALT_INDEX = 6;
export const NDL_COBALT_PRIMARY = 9;
export const NDL_GREY_INDEX = 7;
export const NDL_GREY_PRIMARY = 4;

export class NdlColors {
    public static PALETTE  = [
        ["#e2f6ea", "#bae7cb", "#8bd8aa", "#54c988", "#08bd6f", "#00b155", "#00a24b", "#00903e", "#007e33", "#005f1e"],
        ["#e3f1fd", "#bcdbfa", "#92c6f7", "#68aff3", "#4b9ef1", "#358eee", "#3380df", "#2f6ecc", "#2b5dba", "#243f9a"],
        ["#f8e4ea", "#f0bbcb", "#e690a9", "#dc6788", "#d44c70", "#d0345a", "#c03157", "#aa2c52", "#96274d", "#731e43"],
        ["#fdf6e0", "#fae7b1", "#f6d77f", "#f2c94c", "#f0bc27", "#f0bc27", "#eda400", "#ec9200", "#ec8200", "#eb6200"],
        ["#eaebfa", "#cacdf3", "#a7adeb", "#828ce3", "#6771dc", "#4d56d4", "#474dc9", "#3e43bd", "#3638b1", "#2b239a"],
        ["#fef4e5", "#fde2bf", "#fcd097", "#fbbd71", "#faaf59", "#f7a34e", "#f2984a", "#ea8a45", "#e27d42", "#d46a3e"],
        ["#e4e7ec", "#bbc2d2", "#8f9bb3", "#667595", "#475a81", "#27406f", "#213967", "#1a315d", "#132850", "#0b1939"],
        ["#ededf4", "#d2d5df", "#b5b9c8", "#999db0", "#83889e", "#6e748d", "#5f667c", "#4e5365", "#3d4150", "#2a2d38"]
    ];

    static getColorPalette(colorIndex = 0, random = false, firstGrade: number = null) {
        const colorPalette = NdlColors.PALETTE[colorIndex];
        const numberSequence = random ? [4, 6, 8, 7, 9, 2, 0, 3, 1, 5] : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

        if (firstGrade !== null) {
            const index = numberSequence.findIndex(v => v === firstGrade);
            numberSequence.splice(index, 1);
            numberSequence.unshift(firstGrade);
        }

        return numberSequence.map(number => colorPalette[number]);
    }
}
