import {
    Component, ElementRef, forwardRef, Input, OnDestroy, OnInit, Optional, Self, ViewChild
} from '@angular/core';
import {MatFormFieldControl} from '@angular/material/form-field';
import PhoneNumber from "awesome-phonenumber";
import {NdlInputFieldBaseDirective} from '../input-field-base.directive';
@Component({
    selector: 'ndl-phone-field',
    templateUrl: './phone-field.component.html',
    styleUrls: ['./phone-field.component.scss'],
    providers: [
        {provide: MatFormFieldControl, useExisting: NdlPhoneFieldComponent}
    ],
    host: {
        id: `ndl-tel-field-${NdlPhoneFieldComponent.nextId}`
    }
})
export class NdlPhoneFieldComponent extends NdlInputFieldBaseDirective<string> implements OnInit {
    static nextId = 0;

    controlType = "ndl-phone-field";
    id = "ndl-phone-field-" + NdlPhoneFieldComponent.nextId++;

    telFocus = false;
    countryFocus = false;
    destroyed = false;

    @Input() get placeholder(): string {
        return this._placeholder || this.defaultPlaceholder;
    }

    set placeholder(placeholer: string) {
        this._placeholder = placeholer;
        this.stateChanges.next();
    }

    @Input('aria-describedby') userAriaDescribedBy: string;

    @ViewChild('tel', {static: true}) tel: ElementRef<HTMLInputElement>;

    override onFocusIn(tel = true) {
        if (tel) {
            this.telFocus = true;
        } else {
            this.countryFocus = true;
        }

        if (!this.focused) {
            this.focused = this.telFocus || this.countryFocus;
            this.stateChanges.next();
        }
    }

    override onFocusOut(tel = true) {
        if (tel) {
            this.telFocus = false;
        } else {
            this.countryFocus = false;
        }

        this.focused = this.telFocus || this.countryFocus;
        if (!this.focused) {
            this.touched = true;
            this.stateChanges.next();
        }
        this.ngControl?.control?.setValue(this.value);
    }

    @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

    countries = PhoneNumber.getSupportedRegionCodes();

    get empty(): boolean {
        return !this.value || !this.value.length;
    }

    private _country = 'FR';
    get country(): string {
        return this._country;
    }

    set country(country: string) {
        this.resetSearch();
        this._country = country;
        if (this.value) {
            const number = this._number.getNumber("significant");
            this.number = new PhoneNumber(number, this._country);
        }
    }

    _number: PhoneNumber;
    get number(): PhoneNumber {
        if (!this._number) {
            return null;
        }
        return this._number;
    }

    set number(number: PhoneNumber) {
        this._number = number;
        this._formattedNumber = this._number ? this._number.getNumber('international') || this._number.toJSON().number.input : '';
        this.value = this.number ? this.number.getNumber() : null;
    }

    _formattedNumber: string = '';

    ngOnInit() {
        this.transformTextToPhoneNumber(this.value);
    }

    transformTextToPhoneNumber(number?: string): void {
        let newNumber;
        if (!number) {
            newNumber = null;
        } else {
            const region = this.country;
            number = number.replace(/^00/, "+");
            newNumber = new PhoneNumber(number);
            const regionCode = newNumber.getRegionCode();

            if (regionCode) {
                if (regionCode !== this.country) {
                    this._country = regionCode;
                }
            } else {
                newNumber = new PhoneNumber(number, region);
            }
        }

        this.number = newNumber;
    }

    private _search: string;
    get search() {
        return this._search;
    }
    set search(search: string) {
        this._search = search;
        this.countries = search && search.length ? PhoneNumber.getSupportedRegionCodes().filter((country: string) => {
            const searchString = PhoneNumber.getCountryCodeForRegionCode(country) + country;
            return searchString.toUpperCase().includes(this.search.toUpperCase());
        }) : PhoneNumber.getSupportedRegionCodes();
    }

    get defaultPlaceholder() {
        return this.country ? PhoneNumber.getExample(this.country).getNumber('international') : null;
    }

    getFlag(country: string) {
        switch (country) {
            case 'AC':
            case 'TA':
                return "gb";
            default:
                return country.toLowerCase();
        }
    }

    getCountryIndicator(regionCode: string) {
        return PhoneNumber.getCountryCodeForRegionCode(regionCode);
    }

    resetSearch() {
        this.search = null;
    }

    focusOnSearch() {
        this.searchInput.nativeElement.focus();
    }

    setDescribedByIds(ids: string[]) {
        const controlElement = this._elementRef.nativeElement
            .querySelector('.ndl-phone-field');
        controlElement.setAttribute('aria-describedby', ids.join(' '));
    }

    onContainerClick(event: MouseEvent) {
        if ((event.target as Element).tagName.toLowerCase() !== 'input') {
            this.tel.nativeElement.focus();
        }
    }
}
