import {
    Component, ContentChild,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnInit,
    Output, TemplateRef,
    ViewChild
} from '@angular/core';
import {MatFormFieldControl} from '@angular/material/form-field';
import {FileInterface, fileToFileInterface} from './file.interface';
import {NdlInputFieldBaseDirective} from '../input-field-base.directive';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {NdlUploadComponent} from './upload.component';
import {NdlUploadFileComponent} from './upload-file.component';

@Component({
    selector: 'ndl-upload-field',
    templateUrl: './upload-field.component.html',
    providers: [
        {provide: MatFormFieldControl, useExisting: NdlUploadFieldComponent}
    ],
    host: {
        id: `ndl-upload-field-${NdlUploadFieldComponent.nextId}`,
        class: 'ndl-upload-field'
    }
})
export class NdlUploadFieldComponent extends NdlInputFieldBaseDirective<FileInterface[]> {
    static nextId = 0;

    controlType = "ndl-upload-field";
    id = "ndl-upload-field-" + NdlUploadFieldComponent.nextId++;
    focused = false;
    hovering = false;
    uploadComponent: NdlUploadComponent;

    @Input() type;
    @Input() fileTypes: string;
    @Input() autoupload: boolean;

    @Output('dragHover') fileHoverEvent = new EventEmitter<boolean>();

    @ContentChild("prefix", {static: true, read: TemplateRef}) prefix: TemplateRef<any>;
    @ViewChild("uploadField", {static: true}) uploadField: ElementRef<HTMLInputElement>;
    @ViewChild("uploadFile", {static: false, read: NdlUploadFileComponent}) uploadFile: NdlUploadFileComponent;

    get empty(): boolean {
        return !this.value?.length;
    }

    get filesInError(): FileInterface[] {
        return this.value?.length ? this.value.filter(f => f && !!f.error) : [];
    }

    get firstFile(): FileInterface {
        return this.value?.length ? this.value[0] : null;
    }

    @HostListener('dragover', ['$event']) dragOver(event) {
        event.preventDefault();
        event.stopPropagation();
        this.hovering = true;
        this.fileHoverEvent.emit(true);
    }

    @HostListener('dragleave', ['$event']) dragLeave(event) {
        event.preventDefault();
        event.stopPropagation();
        this.hovering = false;
        this.fileHoverEvent.emit(false);
    }

    @HostListener('drop', ['$event']) drop(event) {
        event.preventDefault();
        event.stopPropagation();
        this.fileHoverEvent.emit(false);
        this.processFileList(event.dataTransfer.files);
    }

    private _multiple = false;
    @Input() get multiple() {
        return this._multiple;
    }

    set multiple(multiple: boolean) {
        this._multiple = coerceBooleanProperty(multiple);
    }

    onFocusIn() {
        this.focused = true;
        this.stateChanges.next();
    }

    onFocusOut() {
        this.focused = false;
        this.touched = true;
    }

    processFileList(value: FileList) {
        const alreadyHasFile = this.value?.length > 0;
        if (value.length) {
            if (this.multiple) {
                if (!alreadyHasFile) {
                    this.value = [];
                }
                for (let i = 0; i < value.length; i++) {
                    this.value.push(fileToFileInterface(value[i]));
                }
            } else {
                this.value = [fileToFileInterface(value[0])];
                if (alreadyHasFile) {
                    this.uploadFile.processFile();
                }
            }
        }
        this.touched = true;
    }

    onInputChange(e: Event) {
        e.stopPropagation();
        this.processFileList((<HTMLInputElement>e.target).files);
    }

    // MatFormFieldControl
    setDescribedByIds(ids: string[]) {
        const controlElement = this._elementRef.nativeElement
            .querySelector('.ndl-upload-field');
        controlElement.setAttribute('aria-describedby', ids.join(' '));
    }

    onContainerClick(event: MouseEvent) {
        this.touched = true;
    }
}
