import {Injectable} from "@angular/core";
import {WabelService} from "./wabel-service.abstract";
import {GraphQLService} from "./graphql-service";
import {LoadingService} from "./loading.service";
import {Tag} from "../entities/tag";
import {map} from "rxjs/operators";
import {TagTypeGraphql} from "../graphql/tag_type.graphql";
import {Company} from '../entities/company';
import {TagType} from '../entities/tag_type';
import {TagTypeService} from './tag_type.service';
import {TagGraphql} from '../graphql/tag.graphql';

@Injectable({
    providedIn: 'root'
})
export class TagService extends WabelService {

    constructor(protected graphQLService: GraphQLService, protected loadingService: LoadingService) {
        super(graphQLService, loadingService);
    }

    toArrayOfObjects(objects: any[]): Tag[] {
        return objects.map((tag: any) => new Tag(tag));
    }

    toObject(object: any): Tag {
        return new Tag(object);
    }

    getCompanyTags(company: Company, tagTypeInternalName: string) {
        return this.query(TagGraphql.queries.tagsByCompany, {
            tagTypeInternalName: tagTypeInternalName, company: {idcompany: company.idcompany}
        }, 'network-only').pipe(map(
            (data) => data.data.tagsByCompany.items.map(t => new Tag(t))
        ));
    }
}
