import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import {IsLoggedGuard} from "../../guards/is-logged.guard";
import {VideoMeetingsPageComponent} from "./video-meetings-page/video-meetings-page.component";
import {VideoMeetingComponent} from "./video-meeting/video-meeting.component";

const routes: Routes = [
    {
        path: 'video-meetings',
        canActivate: [IsLoggedGuard],
        children: [
            {
                path: '',
                component: VideoMeetingsPageComponent
            },
            {
                path: ':id',
                component: VideoMeetingComponent
            },
        ],
    }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
})
export class NewVideoMeetingRoutingModule {
}
