import {NgModule} from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {NeedlSecurityGuard} from "@wabel/needl-security";
import {RIGHT} from "../../services/wabel-client/security/right.const";
import {BusinessMappingComponent} from "./business-mapping.component";
import {BusinessMappingRecapComponent} from './business-mapping-recap/business-mapping-recap.component';
import {BusinessMappingCreateComponent} from './business-mapping-create/business-mapping-create.component';
import {BusinessMappingGuard} from './business-mapping.guard';
import {BusinessMappingMarketComponent} from './business-mapping-market/business-mapping-market.component';
import {BusinessMappingResolver} from '../../resolvers/business-mapping.resolver';
import {BusinessMappingPrioritiesComponent} from './business-mapping-priorities/business-mapping-priorities.component';

const routes: Routes = [
    {
        path: '',
        canActivate: [NeedlSecurityGuard],
        data: {permissions: [RIGHT.CAN_SEE_MY_BUSINESS_MAPPING]},
        component: BusinessMappingComponent,
        resolve: {
            businessMapping: BusinessMappingResolver
        },
        children: [
            {
                path: 'create',
                canActivate: [BusinessMappingGuard],
                component: BusinessMappingCreateComponent
            },
            {
                path: 'priorities',
                canActivate: [NeedlSecurityGuard],
                data: {permissions: [RIGHT.CAN_EDIT_MY_BUSINESS_MAPPING_PRIORITIES]},
                component: BusinessMappingPrioritiesComponent
            },
            {
                path: 'market',
                canActivate: [NeedlSecurityGuard],
                data: {permissions: [RIGHT.CAN_EDIT_MY_BUSINESS_MAPPING_MARKET]},
                component: BusinessMappingMarketComponent
            },
            {
                path: '',
                canActivate: [BusinessMappingGuard],
                component: BusinessMappingRecapComponent,
            }
        ]
    }
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(routes)]
})
export class BusinessMappingRoutingModule {
}
