import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Component({
    selector: 'ndl-single-select-callback',
    templateUrl: './single-select-callback.component.html',
    styleUrls: ['./single-select-callback.component.scss']
})

export class NdlSingleSelectCallbackComponent {
    @Input() placeholder = 'Search';
    @Input() inputListTitle = 'Suggestions';
    @Input() options: string[] = [];
    @Input() showError = false;
    @Input() errorText = "This field is required";
    @Input() isLoading = false;
    @Output() valueChanged = new EventEmitter<string>();
    @Output() searchValueChanged = new EventEmitter<string>();

    isListOpened = false;
    clickWasInsideComponent = false;
    @Input() value: string;
    showErrorTooManySelectionsAfterClick = false;

    constructor() {
    }

    @HostListener("click", ["$event"])
    clickInsideComponent(e) {
        this.clickWasInsideComponent = true;
        this.isListOpened = true;
    }

    @HostListener("document:click", ["$event"])
    clickOutsideComponent() {
        if (!this.clickWasInsideComponent) {
            this.isListOpened = false;
        }
        this.clickWasInsideComponent = false;
    }

    selectOption(option: string, closeList = true) {
        this.value = option;
        if (closeList) {
            this.closeList();
        } else {
            this.reset();
        }
    }

    clear() {
        this.value = null;
        this.valueChanged.emit(this.value);
    }

    closeList(reset: boolean = true) {
        this.isListOpened = false;
        if (reset) {
            this.reset();
        }
    }

    reset() {
        this.options = [];
        this.valueChanged.emit(this.value);
    }
}
