import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CompanyProductsComponent} from "./company-products.component";
import {NeedlButtonsModule} from "needl-lib";
import {MatIconModule} from "@angular/material/icon";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NeedlListsModule, NeedlTagModule, NeedlCardsModule, NeedlCarouselsModule, NeedlSelectModule, NeedlFormModule} from "needl-lib";
import {ProductCategoryTagModule} from "../../products/product-category-tag/product-category-tag.module";
import {MatButtonModule} from "@angular/material/button";
import {NeedlPlaceholdersModule} from "../../../../../projects/needl-lib/src/placeholders/placeholders.module";


@NgModule({
    declarations: [
        CompanyProductsComponent,
    ],
    imports: [
        CommonModule,
        NeedlButtonsModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        NeedlListsModule,
        ProductCategoryTagModule,
        NeedlTagModule,
        NeedlCardsModule,
        NeedlCarouselsModule,
        NeedlSelectModule,
        NeedlFormModule,
        MatIconModule,
        MatButtonModule,
        NeedlPlaceholdersModule
    ],
    exports: [
        CompanyProductsComponent
    ]
})
export class CompanyProductsModule {
}
