import {FilePreviewableInterface} from "./file-previewable.interface";
import {Subscription} from "rxjs";
import {WabelEntity} from "./wabel-entity.abstract";

export class FileToUpload extends WabelEntity implements FilePreviewableInterface {
    idfile: number;
    private base64: string = '';
    private fileSize: number;
    private fileExt: string;
    private fileName: string;
    private _file: File;
    private _selected: boolean = false;
    private _uploading: boolean = false;
    private _uploaded: boolean = false;
    private _success: boolean = false;
    private _path: string;
    private _requestUpload: Subscription = null;

    constructor(obj: any = null) {
        super(obj);
        if (obj) {
            this.fileName = obj.fileName ? obj.fileName : null;
            this.fileExt = obj.fileExt ? obj.fileExt : null;
            this.fileSize = obj.fileSize ? obj.fileSize : null;
            this._path = obj.filePath ? obj.filePath : null;
            this._success = obj.success ? obj.success : false;
        }
    }

    get success(): boolean {
        return this._success;
    }

    set success(value: boolean) {
        this._success = value;
    }

    set uploaded(value: boolean) {
        this._uploaded = value;
    }

    set uploading(value: boolean) {
        this._uploading = value;
    }

    get path(): string {
        return this._path;
    }

    set path(value: string) {
        this._path = value;
    }

    isUploading(): boolean {
        return this._uploading;
    }

    isUploaded(): boolean {
        return this._uploaded;
    }

    get requestUpload(): Subscription {
        return this._requestUpload;
    }

    set requestUpload(value: Subscription) {
        this._requestUpload = value;
    }

    get file(): File {
        return this._file;
    }

    set file(value: File) {
        this._file = value;
    }

    set selected(value: boolean) {
        this._selected = value;
    }

    setImageBase64(base64: string): void {
        this.base64 = base64;
    }

    setExtension(ext: string): void {
        this.fileExt = ext;
    }

    setFileName(name: string): void {
        this.fileName = name;
    }

    setFileSize(size: number): void {
        this.fileSize = size;
    }

    previewId(): string {
        return "" + this.idfile;
    }

    previewUrl(): string {
        return this.base64;
    }

    previewSelected(): boolean {
        return this._selected;
    }

    previewExtension(): string {
        return this.fileExt;
    }

    previewFileName(): string {
        return this.fileName;
    }

    previewFileSize(): number {
        return this.fileSize;
    }

    get id() {
        return this.fileName;
    }

    toGraphQlUploadFile() {
        return {
            filePath: this._path,
            fileName: this.fileName,
        };
    }
}
