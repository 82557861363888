import {WabelEntity} from "./wabel-entity.abstract";
import {File} from "./file";

export class EventFile extends WabelEntity {
    constructor(obj: any) {
        super(obj);
        if (obj) {
            if (obj.file) this.file = new File(obj.file);
        }
    }

    url: string;
    downloadLink: string;
    file: File;

    get id(): string {
        return this.url;
    }

}
