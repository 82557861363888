import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {TemplateService} from "../../../services/template.service";
import {AuthService} from "../../../services/auth.service";
import {ResourceService} from "../../../services/wabel-client/services/resource.service";

@Component({
    selector: 'app-premium-modal-template',
    templateUrl: './premium-modal-template.component.html',
    styleUrls: ['./premium-modal-template.component.scss'],
})
export class PremiumModalTemplateComponent implements OnInit, OnDestroy {
    @Output() close = new EventEmitter();


    constructor(public templateService: TemplateService, public authService: AuthService) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy() {
        this.templateService.unblockScroll();
    }

    get clickToMemberShipPage() {
        return ResourceService.CLICK_TO_MEMBERSHIP_PAGE;
    }
}
