import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PortalModule} from '@angular/cdk/portal';
import {NdlSliderComponent} from "./slider/slider.component";
import {MatSliderModule} from "@angular/material/slider";

@NgModule({
    declarations: [
        NdlSliderComponent
    ],
    exports: [
        NdlSliderComponent
    ],
    imports: [
        CommonModule,
        PortalModule,
        MatSliderModule
    ]
})
export class NeedlSliderModule {
}
