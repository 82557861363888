<div class="carousel" (mouseenter)="clearTimeoutNextImage()" (mouseleave)="!selectedFile && setupTimeoutNextImage()">
    <div class="carousel__left" *ngIf="files.length > 1" (click)="previousFile(false)"><span class="material-icons">chevron_left</span></div>
    <div class="carousel__right" *ngIf="files.length > 1" (click)="nextFile(false)"><span class="material-icons">chevron_right</span></div>
    <div class="carousel__files">
        <div *ngFor="let file of files" [class.active]="file.idfile === files[activeIndexFile].idfile" (click)="selectedFile = file; clearTimeoutNextImage(); setBodyOverflow('hidden');" class="carousel__files__item">
            <img src="{{file.url}}">
        </div>
    </div>
</div>

<div class="carousel-zoom" *ngIf="selectedFile">
    <span class="material-icons" (click)="selectedFile = null; setupTimeoutNextImage(); setBodyOverflow('auto');">clear</span>
    <img src="{{selectedFile.url}}">
</div>
