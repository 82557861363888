import {Product} from "./product";
import {WabelEntity} from "./wabel-entity.abstract";

export class SampleRequestProduct extends WabelEntity {
    constructor(obj: any) {
        super(obj);
        this.product = obj.product ? new Product(obj.product) : null;
    }

    id: number;
    quantity: number;
    product: Product;
}
