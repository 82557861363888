import {Component, Output, EventEmitter, Input} from '@angular/core';
import {UtilsService} from "../../../../../../services/utils.service";
import {environment} from "../../../../../../../environments/environment";

@Component({
    selector: 'registration-personal-info',
    templateUrl: './registration-personal-info.component.html',
    styleUrls: ['./registration-personal-info.component.scss']
})
export class RegistrationPersonalInfoComponent {

    @Input() user;
    optionsTitle: Array<{ value: string, label: string }> = [
        {value: 'mr', label: 'Mr'},
        {value: 'mrs', label: 'Mrs'},
        {value: 'ms', label: 'Ms'},
    ];

    formCompleted = false;
    formSubmitted = false;
    errorTitle = false;
    errorFirstName = false;
    errorLastName = false;
    errorPhoneNumber = false;
    isPhoneValid = false;
    scrollTo = null;

    @Output() stepFinished: EventEmitter<any> = new EventEmitter();

    submitForm() {
        this.formSubmitted = true;
        this.scrollTo = null;
        this.checkFormErrors();
        if (this.scrollTo) {
            UtilsService.scrollToElement(document.querySelector('#' + this.scrollTo), -50);
        }
        if (!this.formCompleted) {
            return;
        }
        this.stepFinished.emit(this.user);
    }

    checkFormErrors() {
        if (!this.formSubmitted) {
            return;
        }

        this.formCompleted = true;
        let scrollTo = null;

        this.errorTitle = false;
        if (!this.user.title) {
            this.errorTitle = true;
            scrollTo = 'title';
            this.formCompleted = false;
        }

        this.errorFirstName = false;
        if (!this.user.firstName) {
            this.errorFirstName = true;
            scrollTo = !scrollTo ? 'first-name' : scrollTo;
            this.formCompleted = false;
        }

        this.errorLastName = false;
        if (!this.user.lastName) {
            this.errorLastName = true;
            scrollTo = !scrollTo ? 'surname' : scrollTo;
            this.formCompleted = false;
        }

        this.errorPhoneNumber = false;
        if (!this.user.phone || !this.isPhoneValid) {
            this.errorPhoneNumber = true;
            scrollTo = !scrollTo ? 'phone-number' : scrollTo;
            this.formCompleted = false;
        }
        this.scrollTo = scrollTo;
    }
}
