import {Directive} from '@angular/core';
import {MatDialogContent, MatDialogTitle} from "@angular/material/dialog";

@Directive({
    selector: '[ndl-modal-content]',
    providers: [
        {provide: MatDialogContent, useExisting: NdlModalContentDirective}
    ]
})
export class NdlModalContentDirective extends MatDialogContent {
}
