import {WabelEntity} from "./wabel-entity.abstract";
import {Company} from "./company";
import {EventWorkshop} from "./event_workshop";

/**
 * Created by Michael on 20/01/2017.
 */
export class EventExpert extends WabelEntity {
    ideventexpert: number;
    company: Company;
    workshops: EventWorkshop[];
    description: string;
    acceptsMeetings: boolean;

    get id(): number {
        return this.ideventexpert;
    }

    set id(id: number) {
        this.ideventexpert = id;
    }
}
