<div class="container-fluid">
    <h1>Simple Snackbar</h1>
    <div class="row row-cols-6">
        <div class="col mb-2">
            <button ndl-button (click)="snackbarService.show('Aleeeeeert !')">Open simple snackbar</button>
        </div>
    </div>

    <hr />

    <h1>Templated Snackbar</h1>
    <div class="row row-cols-6">
        <div class="col mb-3">
            <button ndl-button theme="success" (click)="snackbar.open()">Open custom snackbar</button>

            <ndl-snackbar #snackbar duration="null" icon="check">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.

                <!-- The directive writing is also available <span ndl-snackbar-action></<span> -->
                <ndl-snackbar-action>
                    <button ndl-button>Do something</button>
                </ndl-snackbar-action>
            </ndl-snackbar>
        </div>
    </div>
</div>
