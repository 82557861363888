import {Directive, HostListener, Input} from '@angular/core';
import {Company} from "../services/wabel-client/entities/company";
import {Product} from "../services/wabel-client/entities/product";
import {ResourceService} from "../services/wabel-client/services/resource.service";
import {AuthService} from "../services/auth.service";
import {Ad} from "../services/wabel-client/entities/ad";
import {Router} from "@angular/router";

@Directive({
    selector: '[appLogOutboundClick]'
})
export class LogOutboundClickDirective {

    @Input() outboundType: string;
    @Input() company: Company;
    @Input() product: Product;
    @Input() ad: Ad;
    @Input() value: string;
    @Input() eventCode: string;
    @Input() forceSend: boolean = false;

    @HostListener('click') onClick() {
        if (!this.authService.getMember()
            || (this.company && this.company.idcompany !== this.authService.getMember().company.idcompany) || (this.product && this.product.company.idcompany !== this.authService.getMember().company.idcompany)
            || this.ad || this.forceSend) {
            if (this.outboundType === ResourceService.CLICK_TO_MEMBERSHIP_PAGE) {
                this.value = this.router.url;
            }
            this.resourceService.storeLogActivity(this.outboundType, this.company, this.product, this.ad, this.value, null, this.eventCode).subscribe();
        }
    }

    constructor(private resourceService: ResourceService, private authService: AuthService, private router: Router) {
    }
}
