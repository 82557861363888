import {NgModule} from "@angular/core";
import {CommonModule} from '@angular/common';
import {BusinessMappingComponent} from "./business-mapping.component";
import {BusinessMappingCreateComponent} from './business-mapping-create/business-mapping-create.component';
import {BusinessMappingRecapComponent} from './business-mapping-recap/business-mapping-recap.component';
import {BusinessMappingRoutingModule} from './business-mapping.routing.module';
import {
    NeedlButtonsModule,
    NeedlCardsModule,
    NeedlTooltipModule,
    NeedlCollapsesModule,
    NeedlTagModule,
    NeedlPlaceholdersModule,
    NeedlFormModule,
    NeedlCheckboxesNewModule,
    NeedlSpinnersModule,
    NeedlSelectModule,
    NeedlTagsModule,
    NeedlBannersModule,
    NeedlListsModule,
    NeedlStepperModule,
    NeedlModalsModule,
    NeedlTextAreaModule,
    NeedlChartsModule,
    NeedlCarouselsModule
} from 'needl-lib';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {BusinessMappingPrioritiesComponent} from './business-mapping-priorities/business-mapping-priorities.component';
import {
    BusinessMappingPrioritiesFormComponent
} from './business-mapping-priorities/business-mapping-priorities-form/business-mapping-priorities-form.component';
import {NeedlSecurityModule} from '@wabel/needl-security';
import {BusinessMappingResolver} from '../../resolvers/business-mapping.resolver';
import {
    BusinessMappingPrioritiesCollapseHeaderComponent
} from "./business-mapping-priorities/business-mapping-priorities-collapse-header/business-mapping-priorities-collapse-header.component";
import {CompanyCardModule} from '../../components/company/company-card/company-card.module';
import {
    BusinessMappingRecapCollapseComponent
} from "./business-mapping-recap/business-mapping-recap-collapse/business-mapping-recap-collapse.component";
import {SharedModule} from "../../modules/shared-module/shared.module";
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {
    BusinessMappingRecapRecommendationComponent
} from './business-mapping-recap/business-mapping-recap-recommendation/business-mapping-recap-recommendation.component';
import {BusinessMappingMarketComponent} from './business-mapping-market/business-mapping-market.component';
import {CompanyProfileModule} from "../../components/company/company-profile/company-profile.module";
import {MatMenuModule} from "@angular/material/menu";
import {GoogleTagManagerModule} from '../../directives/gtm/gtm.module';

@NgModule({
    declarations: [
        BusinessMappingComponent,
        BusinessMappingPrioritiesComponent,
        BusinessMappingPrioritiesFormComponent,
        BusinessMappingCreateComponent,
        BusinessMappingRecapComponent,
        BusinessMappingPrioritiesCollapseHeaderComponent,
        BusinessMappingRecapCollapseComponent,
        BusinessMappingRecapRecommendationComponent,
        BusinessMappingMarketComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BusinessMappingRoutingModule,
        CompanyCardModule,
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        NeedlBannersModule,
        NeedlButtonsModule,
        NeedlCardsModule,
        NeedlCheckboxesNewModule,
        NeedlCollapsesModule,
        NeedlFormModule,
        NeedlListsModule,
        NeedlPlaceholdersModule,
        NeedlSecurityModule,
        NeedlSelectModule,
        NeedlStepperModule,
        NeedlSpinnersModule,
        NeedlTagModule,
        NeedlTagsModule,
        NeedlTooltipModule,
        SharedModule,
        MatTooltipModule,
        NeedlModalsModule,
        CompanyProfileModule,
        MatMenuModule,
        NeedlTextAreaModule,
        NeedlChartsModule,
        NeedlCarouselsModule,
        GoogleTagManagerModule,
    ],
    exports: [
        BusinessMappingPrioritiesComponent,
    ],
    providers: [
        BusinessMappingResolver
    ]
})

export class BusinessMappingModule {
}
