import {WabelEntity} from './wabel-entity.abstract';
import {Company} from "./company";
import {PrivateMessageThread} from "./private_message_thread";

export class Request extends WabelEntity {
    constructor(obj: any) {
        super(obj);
        this.company = obj.company ? new Company(obj.company) : null;
        this.thread = obj.thread ? new PrivateMessageThread(obj.thread) : null;
    }

    id: number;
    company: Company;
    thread: PrivateMessageThread;
    dateCreated: Date;
    dateUpdated: Date;
}
