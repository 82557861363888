import {Component, EventEmitter, Input, Output} from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {NdlStatus} from '../core/types';

@Component({
    selector: 'ndl-tag',
    templateUrl: './tag.component.html',
    host: {
        'class': 'ndl-tag'
    }
})
export class NdlTagComponent {
    @Input() theme: NdlStatus = 'default';

    protected _removable: boolean;
    @Input() set removable(removable) {
        this._removable = coerceBooleanProperty(removable);
    }

    get removable(): boolean {
        return this._removable;
    }
    protected _disabled: boolean = false;
    @Input() set disabled(disabled) {
        this._disabled = coerceBooleanProperty(disabled);
    }

    get disabled(): boolean {
        return this._disabled;
    }

    get themeClass() {
        return this.theme ? 'ndl-tag-' + this.theme : null;
    }

    @Output() removed = new EventEmitter();
}
