import {Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {UtilsService} from "../../../../services/utils.service";
import {FilePreviewableInterface} from "../../../../services/wabel-client/entities/file-previewable.interface";
import {FileToUpload} from "../../../../services/wabel-client/entities/fileToUpload";
import {ResourceService} from "../../../../services/wabel-client/services/resource.service";

@Component({
    selector: 'kit-form-image-upload',
    templateUrl: './kit-form-image-upload.component.html',
    styleUrls: ['./kit-form-image-upload.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => KitFormImageUploadComponent),
        multi: true
    }]
})
export class KitFormImageUploadComponent {

    resourceService: ResourceService;
    @Input() label: string = 'Company logo (HD)';
    @Input() instruction: string = 'Select a file on your computer';
    @Input() buttonLabel: string = 'Upload a logo';
    @Input() formatInstruction: string = 'Accepted Format: PNG, JPG, GIF, PDF, DOC, DOCX, XLS, XLSX, PPT, PPTX. 2Mo Max.';
    @Input() disabled: boolean = false;

    @Input() fullWidth: boolean = false;
    @Input() isProfilePicture: boolean = false;

    @Input() isFile: boolean = false;
    @Input() enablefavoriteAction: boolean = false;
    @Input() textIsFavorite: string = 'Favorite';
    @Input() textNotFavorite: string = 'Not favorite';

    @Input() currentFiles: FilePreviewableInterface[] = [];
    @Input() filesToUpload: FileToUpload[] = [];
    filesToUploadErrors: string = '';

    // validation
    @Input() isRequired: boolean = false;
    @Input() doCheck: boolean = false;
    @Input() showError: boolean = false;
    @Input() errorMessage: string = 'This field is required';
    @Input() acceptedFormats: string[] = ['PNG', 'JPG', 'JPEG', 'GIF', 'PDF', 'DOC', 'DOCX', 'XLS', 'XLSX', 'PPT', 'PPTX'];
    isValidFormat: boolean = true;
    isAlreadyUploaded: boolean = false;
    @Input() multiple: boolean = false;
    @Input() inputName: string;
    @Input() returnFileList: boolean = false;
    @Input() uploadMaxFilesSize: number = 2000000;
    @Output() actionOnFileLoaded: EventEmitter<any> = new EventEmitter();
    @Output() actionOnFileDeleted: EventEmitter<any> = new EventEmitter();
    @Output() actionFileListUpdated: EventEmitter<any> = new EventEmitter();
    @Output() actionOnClickSelectedCurrentFiles: EventEmitter<any> = new EventEmitter();
    @Output() actionOnClickSelectedFilesToUpload: EventEmitter<any> = new EventEmitter();

    @ViewChild('inputFile') inputFile: ElementRef;

    constructor(public utilsService: UtilsService, _resourceService: ResourceService) {
        this.resourceService = _resourceService;
    }

    ngOnInit() {
        this.utilsService.loadMemoryLimit();
    }

    filesToUploadChanged(event: Event) {
        if (event.target && event.target['files']) {
            let files: FileList = event.target['files'];
            this.filesToUploadErrors = '';

            if (this.totalUploadSizeIsAccepted(files)) {
                if (!this.multiple) {
                    for (let fileToUpload in this.filesToUpload) {
                        this.removeFileFromListToUpload(fileToUpload);
                    }
                }
                this.checkAndAddFilesToUploadList(files);
                if (!this.multiple && this.filesToUpload.length) {
                    for (let currentFile in this.currentFiles) {
                        this.removeFileFromList(currentFile);
                    }
                }
            } else {
                this.filesToUploadErrors = 'The total size of your files is above the maximum allowed.';
            }
            this.filesToUploadErrors = this.filesToUploadErrors.length ? this.filesToUploadErrors : '';
        }
    }

    private getTotalSize(files: FileList): number {
        let value = 0;
        for (let indexFile in files) {
            if (typeof files[indexFile] !== 'object' || files[indexFile]['constructor']['name'] !== 'File') {
                continue;
            }
            value += files[+indexFile].size;
        }
        return value;
    }

    private totalUploadSizeIsAccepted(files: FileList): boolean {
        let size = this.getTotalSize(files);
        if (size > this.uploadMaxFilesSize) {
            this.isValidFormat = false;
            return false;
        }
        this.isValidFormat = true;
        return true;
    }

    removeFileFromListToUpload(indexFile) {
        let file = this.filesToUpload[indexFile];
        if (file && this.inputFile.nativeElement && this.inputFile.nativeElement.files) {
            const newFileList = Array.from(this.inputFile.nativeElement.files);
            const index = newFileList.findIndex(fileToUpload => fileToUpload['name'] === file.file.name);
            if (index !== -1) {
                newFileList.splice(index,1);
            }
        }

        this.filesToUpload.splice(indexFile, 1);
        this.actionFileListUpdated.emit();
    }

    removeFileFromList(indexFile) {
        this.currentFiles.splice(indexFile, 1);
    }

    private extensionIsAllowed(extension: string): boolean {
        return this.acceptedFormats.map((value) => value.toLowerCase()).includes(extension.toLowerCase());
    }

    checkError() {
        this.showError = this.isRequired && this.filesToUpload.length === 0 && this.currentFiles.length === 0;
    }

    clickFavoriteCurrentFiles(indexFile) {
        this.actionOnClickSelectedCurrentFiles.emit(this.currentFiles[indexFile]);
    }

    clickFavoriteFilesToUpload(indexFile) {
        this.actionOnClickSelectedFilesToUpload.emit(this.filesToUpload[indexFile]);
    }

    private checkAndAddFilesToUploadList(files: FileList): void {
        for (let indexFile in files) {
            if (typeof files[indexFile] !== 'object' || files[indexFile]['constructor']['name'] !== 'File') {
                continue;
            }
            let newFileToUpload = new FileToUpload();
            newFileToUpload.setFileName(files[indexFile].name);
            newFileToUpload.setFileSize(files[indexFile].size);
            newFileToUpload.setExtension(files[indexFile].name.split('.').pop());

            let fileWithSameName = this.filesToUpload.find((f: FileToUpload) => f.previewFileName() === files[indexFile].name);
            if (fileWithSameName) {
                this.filesToUploadErrors += 'A file with the name ' + files[indexFile].name + ' already exists<br/>';
                continue;
            }

            newFileToUpload.file = files[indexFile];
            this.filesToUpload.push(newFileToUpload);
            let lastIndexFile = this.filesToUpload.lastIndexOf(newFileToUpload);

            let reader = new FileReader();
            reader.onload = (e: any) => {
                if (!this.isFile) {
                    let memoryLimit = this.utilsService.getMemoryLimit();
                    let img = new Image;
                    let that = this;
                    img.onload = function () {
                        if (memoryLimit && 4 * img.width * img.height > memoryLimit) {
                            that.filesToUpload.splice(lastIndexFile, 1);
                            that.filesToUploadErrors += 'The file size of ' + img.name + ' is too big<br/>';
                        } else {
                            that.filesToUpload[lastIndexFile].setImageBase64(that.utilsService.resizeImageBase64(img, 123, 123));
                            if (that.filesToUpload.length === 1 && that.currentFiles.length === 0) {
                                that.filesToUpload[lastIndexFile].selected = true;
                            }
                            that.uploadFile(that.filesToUpload[lastIndexFile]);
                        }
                    };
                    img.onerror = function (e) {
                        that.filesToUpload.splice(lastIndexFile, 1);
                        that.filesToUploadErrors += 'The file ' + files[indexFile].name + ' is not a valid image<br/>';
                    }
                    img.src = e.target.result;
                } else {
                    this.uploadFile(this.filesToUpload[lastIndexFile]);
                }

            };

            if (this.extensionIsAllowed(newFileToUpload.previewExtension())) {
                reader.readAsDataURL(files[indexFile]);
            } else {
                this.filesToUpload.splice(lastIndexFile, 1);
                this.filesToUploadErrors += 'The type of the file ' + files[indexFile].name + ' is not supported<br/>';
            }
        }
        this.actionFileListUpdated.emit();
    }

    private uploadFile(file: FileToUpload) {
        if (!file.isUploading() && !file.isUploaded()) {
            file.uploading = true;
            file.uploaded = false;
            file.requestUpload = this.resourceService.uploadFile(file.file).subscribe(
                (uploadedFile: FileToUpload) => {
                    file.uploading = false;
                    file.uploaded = true;
                    if (uploadedFile.success) {
                        file.success = true;
                        file.path = uploadedFile.path;
                    } else {
                        file.success = false;
                    }
                },
                (error) => {
                    file.uploaded = true;
                    file.uploading = false;
                    file.success = false;
                });
        }
    }
}
