import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {NdlSelectOption} from "../../NdlSelectOption";

@Component({
  selector: 'ndl-simple-select',
  templateUrl: './simple-select.component.html',
  styleUrls: ['./simple-select.component.scss']
})

export class NdlSimpleSelectComponent {
    @Input() placeholder = 'Select an option';
    @Input() options: NdlSelectOption[] = [];
    @Input() selectedOption: NdlSelectOption;
    @Input() materialIconBefore = 'add_circle_outline';
    @Input() showError = false;
    @Input() errorText = 'This field is required';
    @Input() disabled = false;

    @Output() valueChanged = new EventEmitter<NdlSelectOption>();

    isListOpened = false;
    clickWasInsideComponent = false;

    constructor() {}

    @HostListener("click", ["$event"])
    clickInsideComponent(e) {
        this.clickWasInsideComponent = true;
    }

    @HostListener("document:click", ["$event"])
    clickOutsideComponent() {
        if (!this.clickWasInsideComponent) {
            this.isListOpened = false;
        }
        this.clickWasInsideComponent = false;
    }

    selectOption(option: NdlSelectOption) {
        this.selectedOption = option;
        this.isListOpened = false;
        this.valueChanged.emit(this.selectedOption);
    }
}
