import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {GraphQLService} from "./graphql-service";
import {LoadingService} from "./loading.service";
import {WabelService} from "./wabel-service.abstract";
import {Company} from "../entities/company";
import {BusinessMappingGraphql} from '../graphql/business-mapping.graphql';
import {Observable} from 'rxjs';
import {BusinessMappingPrioritiesForm} from '../../../forms/business-mapping-priorities.form';
import {BusinessMappingPriority} from "../entities/business_mapping_priority";
import {BusinessMapping} from '../entities/business_mapping';
import {Member} from "../entities/member";
import {BusinessMappingTag} from '../entities/business_mapping_tag';

@Injectable({
    providedIn: "root"
})
export class BusinessMappingService extends WabelService {
    firstRecommendationIndexOpened = null;
    constructor(protected graphQLService: GraphQLService, protected loadingService: LoadingService) {
        super(graphQLService, loadingService);
    }

    // Had to do that because of WabelService extension
    toObject(object: any) {
        return new BusinessMappingPriority(object);
    }

    myBusinessMappingPriorities(network = 'cache-and-network'): Observable<BusinessMappingPriority[]> {
        return this.query(BusinessMappingGraphql.queries.myBusinessMappingPriorities, {}, network).pipe(
            map(data => this.toObjects(data.data.myBusinessMappingPriorities.items))
        );
    }

    myBusinessMapping(network = 'cache-and-network'): Observable<BusinessMapping> {
        return this.query(BusinessMappingGraphql.queries.myBusinessMapping, {}, network).pipe(
            map(data => new BusinessMapping(data.data.myBusinessMapping))
        );
    }

    saveBusinessMappingPriorities(exportForm: BusinessMappingPrioritiesForm) {
        return this.mutation(BusinessMappingGraphql.mutations.saveBusinessMappingPriorities, exportForm)
            .pipe(map((data) => new Member(data.data.saveBusinessMappingPriorities)));
    }

    saveBusinessMappingPriority(businessMappingPriority: any) {
        return this.mutation(BusinessMappingGraphql.mutations.saveBusinessMappingPriority, businessMappingPriority)
            .pipe(map((data) => new BusinessMappingPriority(data.data.saveBusinessMappingPriority)));
    }

    deleteBusinessMappingPriority(idBusinessMappingPriority: number) {
        return this.mutation(BusinessMappingGraphql.mutations.deleteBusinessMappingPriority, {idBusinessMappingPriority: idBusinessMappingPriority})
            .pipe(map((data) => new Company(data.data.deleteBusinessMappingPriority)));
    }

    getBusinessMappingPriority(idBusinessMappingPriority: number, countryRecommendationFilters = null): Observable<BusinessMappingPriority> {
        return this.query(BusinessMappingGraphql.queries.businessMappingPriority, {businessMappingPriority: {id: idBusinessMappingPriority},
            countryRecommendationFilters: countryRecommendationFilters}, 'network-only')
            .pipe(map((data) => new BusinessMappingPriority(data.data.businessMappingPriority)));
    }

    getBusinessMappingPriorityRecommendationsFilters(idBusinessMappingPriority: number, countryRecommendationFilters = null): Observable<BusinessMappingPriority> {
        return this.query(BusinessMappingGraphql.queries.businessMappingPriorityRecommendationFilters, {businessMappingPriority: {id: idBusinessMappingPriority},
            countryRecommendationFilters: countryRecommendationFilters}, 'network-only')
            .pipe(map((data) => new BusinessMappingPriority(data.data.businessMappingPriority)));
    }

    saveBusinessMappingMarket(idbusinessmapping: number, businessMappingTags: BusinessMappingTag[]) {
        return this.mutation(BusinessMappingGraphql.mutations.saveBusinessMappingTags, {businessMappingTags: businessMappingTags.map(bmt => {
            return {idbusinessmapping: idbusinessmapping, tag: bmt.tag.id, type: bmt.type};
        })})
            .pipe(map((data) => data.data.saveBusinessMappingTags.map(bmt => new BusinessMappingTag(bmt))));
    }

    updateBusinessMappingPriorityProspect(idBusinessMappingPriority: number, idcompany: number, included: boolean, removedReason: string = null, customRemovedReason: string = null) {
        return this.mutation(BusinessMappingGraphql.mutations.updateBusinessMappingPriorityProspect, {
            idBusinessMappingPriority: idBusinessMappingPriority,
            idcompany: idcompany,
            included: included,
            removedReason: removedReason,
            customRemovedReason: customRemovedReason,
        }).pipe(map((data) => new Company(data.data.updateBusinessMappingPriorityProspect)));
    }
}
