import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {MatStepHeader} from '@angular/material/stepper';
import {NdlStepperComponent} from '../../stepper/stepper.component';
import {NdlStepComponent} from '../step.component';

@Component({
  selector: 'ndl-step-header',
  templateUrl: './step-header.component.html',
  styleUrls: ['./step-header.component.scss']
})
export class NdlStepHeaderComponent extends MatStepHeader {
    @HostBinding('class.ndl-step-header') addStepperClass = true;
    @Input() stepper: NdlStepperComponent;
    @Input() completed: boolean;
    @Input() displayIndex: boolean;
}
