import {Component, EventEmitter, Input, Output, ViewChild, OnInit, AfterViewInit} from '@angular/core';
import {NgModel} from "@angular/forms";
import {CacheService} from "../../../../services/cache.service";
import {Tag} from "../../../../services/wabel-client/entities/tag";

@Component({
    selector: 'kit-form-phone',
    templateUrl: './kit-form-phone.component.html',
    styleUrls: ['./kit-form-phone.component.scss'],
})
export class KitFormPhoneComponent implements OnInit, AfterViewInit {

    @Input() id: string;
    @Input() defaultCountry: string = 'fr';
    @Output() bindingChange: EventEmitter<string> = new EventEmitter();
    @Output() isValid: EventEmitter<boolean> = new EventEmitter();
    @Input() label: string;
    @Input() placeholder: string = 'International Phone Number';
    @Input() helper: string;
    @Input() errorMessage: string = "This field is required";
    @Input() nameField: string;
    @Input() isRequired: boolean = false;
    @Input() showError: boolean = false;
    @Input() phoneValue: string = '';
    @Input() allowDropDown : boolean = true;
    @ViewChild("phone_number", { static: true }) ngPhoneElement: NgModel;
    @Input() cyName: string;
    isPhoneNumberValid: boolean = false;
    countriesList: Tag[] = [];

    constructor(private cacheService: CacheService) {
    }

    ngOnInit() {
        if (this.phoneValue) {
            this.phoneValue = this.phoneValue.replace(/[^0-9\+\s]/g, "");
            this.phoneValue = this.phoneValue.replace(/^00/, "+");
            this.phoneValue = this.phoneValue.replace('+ ', "+");
        }
        this.cacheService.getLocation().subscribe((data) => {
            if (!data) {
                return;
            }
            this.countriesList = [];
            data.tags.forEach(continent => continent.children.forEach(region => region.children.forEach(country => this.countriesList.push(country))));
        });
    }

    onChange(event) {
        this.phoneValue = event.phoneNumber;
        this.isPhoneNumberValid = event.isValid;
        if (event.isDirty) {
            this.checkError();
            this.bindingChange.emit(event.phoneNumber);
        }
    }

    checkError() {
        setTimeout(() => {
            this.errorMessage = "This field is required";
            this.showError = this.showError && this.isRequired && (!this.phoneValue || (this.phoneValue && this.phoneValue.length == 0));

            if (!this.showError) {
                if (!((this.isRequired && this.phoneValue && !this.isPhoneNumberValid) || (this.phoneValue && !this.isPhoneNumberValid))) {
                    this.isValid.emit(true);
                } else {
                    this.errorMessage = "Phone is not valid";
                    this.showError = true;
                    this.isValid.emit(false);
                }
            }
        }, 1);
    }

    ngAfterViewInit(): void {
        if (this.phoneValue != '' && this.phoneValue != null) {
            this.checkError();
        }
    }
}
