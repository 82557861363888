import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewChild,
    SimpleChanges,
    OnChanges
} from '@angular/core';
// @ts-ignore
import {UtilsService} from "../../../../services/utils.service";
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {filter, take} from 'rxjs/operators';


enum Errors {
    required = 'This field is required',
    invalidAddress = 'The address is not valid, please click on a suggested address'
}

@Component({
    selector: 'kit-form-address',
    templateUrl: './kit-form-address.component.html',
    styleUrls: ['./kit-form-address.component.scss']
})
export class KitFormAddressComponent implements OnInit, OnChanges {

    @Input() id: string;
    @Input() label: string;
    @Input() placeholder: string = 'Enter your address';
    @Input() binding: string;
    @Input() helper: string;
    @Input() isRequired: boolean = false;
    @Input() readonly: boolean = false;
    @Input() showError: boolean = false;
    @Input() errorMessage: string;
    @Input() additionalInfo: string;
    @Input() searchOptions: any = {
        types: ["address"]
    };
    address: any;
    validatedBinding: string;
    apiLoaded = false;
    place: google.maps.places.PlaceResult;

    // wrongAddress: boolean = false;
    autocomplete: google.maps.places.Autocomplete;

    @Output() bindingChange = new EventEmitter();
    @Output() addressChange: EventEmitter<any> = new EventEmitter();
    @Output() validatorChange = new EventEmitter();

    @ViewChild("search", { static: true }) public searchElementRef: ElementRef;

    @HostListener('focusout', ['$event']) onFocusOut(event) {
        this.checkErrorOnFocusOut();
        this.checkError();
    }

    @Input() cyName: string;

    constructor(
        private httpClient: HttpClient,
        private ngZone: NgZone,
        private utilsService: UtilsService,
    ) {
    }

    ngOnInit() {
        //Because show error initialized on first step of registration by form control event
        this.showError = false;
        if (!this.readonly) {
            // Load Places Autocomplete
            this.autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, this.searchOptions);
            this.autocomplete.addListener("place_changed", () => {
                this.ngZone.run(() => {
                    // Get the place result
                    this.place = this.autocomplete.getPlace();
                    this.addressChanged();
                });
            });

            this.bindingChange.subscribe(() => this.addressChanged());
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.doCheck && changes.doCheck.currentValue) {
            this.checkError();
        }
    }

    addressChanged() {
        this.address = this.utilsService.formatAddress(this.place);
        if (this.address && this.address.place_id && this.address.formatted_address &&
            (this.address.city_name || this.address.country_name || this.address.region_name)
        ) {
            this.binding = this.address.formatted_address;
            this.errorMessage = null;
            this.validatorChange.emit(true);
            this.addressChange.emit(this.address);
        } else {
            this.errorMessage = Errors.invalidAddress;
            this.showError = true;
            this.validatorChange.emit(false);
        }
    }

    change(newValue) {
        this.binding = newValue;
        this.bindingChange.emit(newValue);
    }

    checkErrorOnFocusOut() {
        if (this.errorMessage) {
            // this.wrongAddress = true;
            this.showError = true;
            this.errorMessage = Errors.invalidAddress;
        }
    }

    checkError() {
        if (this.binding && (
            (this.address && this.address.formatted_address === this.binding) || (this.validatedBinding === this.binding))
        ) {
            this.errorMessage = null;
            this.showError = false;
            this.validatorChange.emit(true);
        } else if (this.binding && (
            (this.address && this.address.formatted_address !== this.binding) || (this.validatedBinding !== this.binding))) {
            this.showError = true;
            this.errorMessage = Errors.invalidAddress;
            this.validatorChange.emit(false);
        } else if (this.isRequired && (!this.binding || (this.binding && this.binding.toString().length == 0) || this.binding.toString() == '')) {
            this.showError = true;
            this.errorMessage = Errors.required;
            this.validatorChange.emit(false);
        }
    }
}
