import {Component} from '@angular/core';
import {NeedlTourService} from "../../../services/needl-tour.service";
import {UtilsService} from "../../../services/utils.service";

@Component({
    selector: 'app-tour',
    templateUrl: './app-tour.component.html',
    styleUrls: ['./app-tour.component.scss']
})
export class AppTourComponent {
    constructor(public needlTourService: NeedlTourService, public utilsService: UtilsService) {
    }
}
