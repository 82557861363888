import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {TemplateService} from "../../../services/template.service";
import {CompanyService} from "../../../services/wabel-client/services/company.service";
import {Company} from "../../../services/wabel-client/entities/company";
import {UtilsService} from "../../../services/utils.service";
import {Subscription} from "rxjs";
import Timeout = NodeJS.Timeout;

@Component({
    selector: 'premium-popin',
    templateUrl: './premium-popin.component.html',
    styleUrls: ['./premium-popin.component.scss']
})
export class PremiumPopinComponent implements OnInit, OnDestroy {

    // @ViewChild('mainDiv', {static: true}) mainDiv: ElementRef;

    @Output() close: EventEmitter<string> = new EventEmitter<string>();
    step: string = 'open';
    asynchronousButtonState: string = 'before';
    errorAfterSubmit: string;
    timeout$: Timeout;
    request$: Subscription;

    constructor(
        private templateService: TemplateService,
        private companyService: CompanyService,
        private utilsService: UtilsService
    ) {
    }

    clearTimeout() {
        if (this.timeout$) {
            clearTimeout(this.timeout$);
        }
        if (this.request$) {
            this.request$.unsubscribe();
        }
    }

    ngOnDestroy() {
        this.clearTimeout();
    }

    ngOnInit() {
        this.asynchronousButtonState = 'loading';
        // We add some delay just to give time to close if the member click multiple times on teh button.
        this.timeout$ = setTimeout(() => {
            this.request$ = this.companyService.sendMailPremiumRequest(this.templateService.premiumPopinCompany(), this.templateService.premiumPopinSourcePage(), this.templateService.premiumPopinRembershipRequested(), this.templateService.premiumPopinSourcingRequest(), this.templateService.premiumPopinSourcingNeed()).subscribe(
                success => {
                    this.asynchronousButtonState = 'success';
                },
                error => {
                    this.asynchronousButtonState = 'error';
                    this.errorAfterSubmit = this.utilsService.detectErrorMessages(error).join('. ');
                }
            );
        }, 1000);
    }

    closeModal() {
        this.clearTimeout();
        this.templateService.unblockScroll();
        this.close.emit();
    }

    getMoreInfo() {
        // if (this.asynchronousButtonState == 'before') {
        //     this.asynchronousButtonState = 'loading';
        //     this.errorAfterSubmit = false;
        //     this.companyService.sendMailPremiumRequest(this.company).subscribe(
        //         success => {
        //             this.step = 'confirmation';
        //             this.mainDiv.nativeElement.scrollTop = 0;
        //         },
        //         error => {
        //             this.asynchronousButtonState = 'before';
        //             this.errorAfterSubmit = true;
        //         }
        //     );
        // }
    }

}
