/**
 * This constant is meant to be used with the Angular CDK Layout Breakpoint Observer
 * to deal with device layouts sizes programatically. It uses the same breakpoints
 * defined by Material but with the values ofBootstrap 4 to have the same behavior
 * than what is defined in SASS (xs, sm, md, lg, xl)
 */

export const NdlBreakpoints = {
    XSmall: '(max-width: 575.98px)',
    Small: '(min-width: 576px) and (max-width: 767.98px)',
    Medium: '(min-width: 768px) and (max-width: 991.98px)',
    Large: '(min-width: 992px) and (max-width: 1199.98px)',
    XLarge: '(min-width: 1200px)',
    Handset: '(max-width: 575.98px) and (orientation: portrait), (max-width: 767.98px) and (orientation: landscape)',
    Tablet: '(min-width: 576px) and (max-width: 767.98px) and (orientation: portrait), (min-width: 768px) and (max-width: 991.98px) and (orientation: landscape)',
    Web: '(min-width: 768px) and (orientation: portrait), (min-width: 992px) and (orientation: landscape)',
    HandsetPortrait: '(max-width: 575.98px) and (orientation: portrait)',
    TabletPortrait: '(min-width: 576px) and (max-width: 767.98px) and (orientation: portrait)',
    WebPortrait: '(min-width: 768px) and (orientation: portrait)',
    HandsetLandscape: '(max-width: 767.98px) and (orientation: landscape)',
    TabletLandscape: '(min-width: 768px) and (max-width: 991.98px) and (orientation: landscape)',
    WebLandscape: '(min-width: 992px) and (orientation: landscape)'
};
