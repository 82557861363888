import gql from "graphql-tag";
import {ProductGraphQL} from "./product.graphql";
import {TagTypeService} from "../services/tag_type.service";
import {TagTypeGraphql} from "./tag_type.graphql";
import {BusinessMappingGraphql} from "./business-mapping.graphql";

export class MemberGraphQL {
    static fragments = {
        me: gql`
            fragment me on Member {
                idmember,
                permissions,
                firstname,
                name,
                nameInitials,
                email,
                status
                fullname,
                jobtitle
                status,
                timezone,
                locale,
                logoUrl(size: "123", returnNull: true),
                realLogoUrl: logoUrl(size: "123"),
                isCompanyOwner,
                favorites {
                    items {
                        idfavorite
                        company {
                            idcompany
                        }
                        userproduct {
                            iduserproduct
                        }
                    }
                }
                company {
                    idcompany,
                    name,
                    type,
                    parentCompany {
                        idcompany,
                        name
                    }
                    hasAllRequiredProfileFields
                    hasAddedAllEcoPackProjects
                    hasAddedAllProducts
                    hasAddedAllClients
                    remainingProductRequests
                    companyRewardScore {
                        value,
                        children {
                            value
                            name
                        }
                    }
                    hasEventParticipationsThisYear
                }
                userDataLayerTagManager{
                    visitorId
                    visitorEmail
                    visitorFullname
                    visitorLoginState
                    visitorStatus
                    visitorType
                    visitorAccountManager
                    buyerSpecialisation
                    buyerStatus
                    buyerPriority
                    supplierCategory
                    supplierScore
                    visitorSummitParticipation
                    visitorCountrySelection
                }
            }
        `,
        myDashboardBase: gql`
            fragment myDashboardBase on Member {
                firstname
                relationshipsCounter {
                    label
                    count
                    value
                    color
                }
                relationships(orderBy: "updated_date DESC, created_date DESC") {
                    count
                    items(limit: 10) {
                        idcompanyRelationship
                        currentStatus {
                            idrelationshipStatus
                        }
                        source
                        sourceDetails
                        lastStatusUpdaterMember {
                            company {
                                idcompany
                                name
                            }
                        }
                        toIdcompany {
                            idcompany
                            name
                            logoUrl(size: "123")
                        }
                        toIdmember {
                            idmember
                            fullname
                        }
                        fromIdcompany {
                            idcompany
                            name
                            logoUrl(size: "123")
                        }
                        fromIdmember {
                            idmember
                            fullname
                        }
                    }
                }
                relationshipsUpdatedByWabel: relationships(updatedBy: "wabel") {
                    count
                }
            }
        `,

        myFavorites: gql`
            fragment items on PorpaginasResult_Favorite {
                items {
                    idfavorite
                    userproduct {
                        ...productCard
                    }
                    company {
                        idcompany
                        name
                        logoUrl(size: "123")
                        urlname
                        rootCategories {
                            items {
                                ...fullTag
                            }
                        }
                    }
                }
            }
            ${ProductGraphQL.fragments.productCard}
            ${TagTypeGraphql.fragments.fullTag}
        `,

        myProfile: gql`
            fragment myProfile on Member {
                idmember,
                firstname,
                name,
                fullname,
                jobtitle,
                title,
                email,
                mobile,
                logoUrl(size: "123"),
                isCompanyOwner,
                fileAvatar{
                  url
                  fileName,
                  fileExtension,
                  fileSize
                },
                timezone,
                locale,
                company {
                    idcompany,
                    name,
                    type,
                    productCount
                    hasAllRequiredProfileFields
                    appliedDiscount
                    countriesNumber
                    bannersNumber
                    stockAvailability
                    companyRequiredInfoScore {
                        name,
                        value,
                        children {
                            name,
                            value
                        },
                    }
                    companyClients {
                        items {
                            client {
                                idcompany
                                name
                                country {
                                    tagLocation {
                                      code
                                    }
                                    name
                                }
                            }
                            label
                        }
                    }
                    hasAddedAllProducts
                    hasAddedAllClients
                    hasAddedAllEcoPackProjects
                    companyRewardScore {
                        name,
                        value,
                        weight,
                        children {
                            name,
                            value,
                            weight,
                        },
                    },
                    valueProposition,
                    productCount
                    activeMemberships {
                        idsubscription
                        subscriptionpack {
                            idsubscriptionpack
                            label
                        }
                    },
                    companyProfileSubmissionsByCreatedByCompany{
                      items{
                        idcompanyprofilesubmission
                        profileDescription
                        profileStep
                        profileTitle
                        profileStatus
                        updatedAt
                        createdAt
                        message{
                          idprivatemessage
                          thread{
                            idthread
                          }
                        }
                        createdByCompany{
                          name
                          idcompany
                          logoUrl(size: "123")
                          country{
                              tagLocation {
                                  code
                              }
                            name
                          }
                        }
                        createdForCompany{
                          name
                          idcompany
                          urlname
                          logoUrl(size: "123")
                          citylocation{
                              country {
                                name
                              }
                              city
                          }
                          country{
                              tagLocation {
                                  code
                              }
                            name
                          }
                        }
                        isFeaturedProducts
                        files{
                          idfile
                          fileName
                          filePath
                          fileSize
                          fileExtension
                        }
                        companyProfileDiagnosisClients{
                          items{
                            idcompanyprofilediagnosisclient
                            company{
                                idcompany
                                name
                                logoUrl(size: "123")
                                country {
                                    name
                                }
                            }
                          }
                        }
                        isExportingToBuyerCountry
                        companyProfileFeaturedProducts{
                            items{
                                userproduct{
                                    iduserproduct
                                    name
                                    published
                                    category {
                                        name
                                        parent {
                                            parent {
                                                name
                                            }
                                        }
                                    }
                                    logoUrl(size: "123")
                                }
                            }
                        }
                        companyProfileExportingCountries{
                          items{
                            idcompanyprofileexportingcountry
                            country{
                              id
                              name
                                tagLocation {
                                    code
                                    nbPurchasingGroups
                                    nbImporters
                                    nbCoPackers
                                    backgroundImage
                                    continent {
                                        id
                                        name
                                    }
                                }
                            }
                          }
                        }
                        categories: tags(tagTypeInternalName: "${TagTypeService.CATEGORY_INTERNAL_NAME}") {
                            items{
                                id
                                name
                            }
                        }
                      }
                    }
                    country {
                        name
                    },
                    company_type_machine_name,
                    stockAvailability,
                    website,
                    description,
                    urlname,
                    logoUrl(size: "300"),
                    logo {
                        file {
                            fileName,
                            filePath,
                            url(size:"123"),
                            fileExtension
                            fileSize
                        }
                    },
                    formattedAddress,
                    phone,
                    nbSellingPoints {
                        ...tag
                    },
                    yearEstablished,
                    turnoverYear
                    numemployees {
                        ...tag
                    },
                    ca {
                        ...tag
                    },
                    currency {
                        ...tag
                    },
                    logisticsCapabilities,
                    privateLabelParts,
                    productionCapacity,
                    privateLabel,
                    linkedin,
                    facebook,
                    instagram,
                    twitter,
                    pinterest,
                    ecopackpolicydescription,
                    citylocation {
                        latitude,
                        longitude,
                        city
                        region
                        country {
                            name
                        }
                    }
                    postalCode
                    products {
                        iduserproduct,
                        name,
                        url,
                        urlname,
                        canBePublished,
                        logo {
                            imageUrl(size:"300")
                        },
                        published,
                        position,
                        category {
                            ...fullTag
                        },
                        activities: tags(tagTypeInternalName: "${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        }
                        company {
                            urlname
                        }
                    },
                    ecopackprojects{
                        idecopackproject,
                        name
                    }
                    categories: tags(tagTypeInternalName: "${TagTypeService.CATEGORY_INTERNAL_NAME}") {
                        items {
                            ...fullTag
                        }
                    },
                    ecoPackPolicyFeatures: tags(tagTypeInternalName: "${TagTypeService.ECO_PACK_POLICY_FEATURES}") {
                        items {
                            ...tag
                        }
                    },
                    customCertifications: customTags(tagTypeInternalName: "${TagTypeService.CERTIFICATION_INTERNAL_NAME}") {
                        items {
                            ...customTag
                        }
                    },
                    certifications: tags(tagTypeInternalName: "${TagTypeService.CERTIFICATION_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    activities: tags(tagTypeInternalName: "${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    companyDistributionChannelsReference: tags(tagTypeInternalName: "${TagTypeService.DISTRIBUTION_CHANNEL_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    exportPositions: tags(tagTypeInternalName: "${TagTypeService.EXPORT_POSITION_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    rootCategories: tags(tagTypeInternalName: "${TagTypeService.CATEGORY_INTERNAL_NAME}", onlyLevel1: true) {
                        items {
                            ...fullTag
                        }
                    },
                    files {
                        downloadLink(forceDirectDownload: false)
                        file {
                            fileName
                            filePath
                            fileExtension
                            fileSize
                            url
                        }
                    },
                    certificationsFiles: files(filesType: "certification") {
                        downloadLink(forceDirectDownload: false)
                        file {
                            fileName
                            filePath
                            fileExtension
                            fileSize
                            url
                        }
                    },
                    minimumOrderQuantities: tags(tagTypeInternalName: "${TagTypeService.MOQ_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    pointOfSales: tags(tagTypeInternalName: "${TagTypeService.POINT_OF_SALE_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    companyCountriesDistribution {
                        items {
                            id
                            name
                        }
                    }
                    companyImages {
                        file {
                            fileName
                            fileExtension
                            filePath
                            fileSize
                            url(size:"123")
                        }
                        imageUrl(size:"123")
                    },
                    onMembers {
                        idmember
                        firstname
                        name
                        logoUrl(size: "123", returnNull: true)
                        email
                        hasEmailVerified
                        isCompanyOwner
                    }
                    onBoardingFinished,
                    onBoardingHasProducts,
                    onBoardingHasEcoProjects,
                    onBoardingCompletedEcoPackPolicy,
                    onBoardingHasRelationshipWithBuyer,
                    onBoardingNeedlCompanyProfileCompletionStep,
                    onBoardingHasCompletedNeedlCompanyProfile
                    companyVideos {
                        items {
                            id
                            name
                            url
                            priority
                            company {
                                idcompany
                            }
                        }
                    }
                    businessMappings {
                        items {
                            completed
                            member {
                                idmember
                                fullname
                            }
                            businessMappingTags {
                                items {
                                    type
                                    tag {
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
            }
            ${TagTypeGraphql.fragments.tag}
            ${TagTypeGraphql.fragments.fullTag}
            ${TagTypeGraphql.fragments.customTag}
        `,
    };

    static queries = {

        me: gql`
            query AuthQueryMe {
                me {
                    ...me
                    forcePasswordChange,
                    hasPassword,
                    isMasquerading,
                    hasEmailVerified,
                    countUnreadMessages,
                    needlTcAccepted,
                    activeSourcingNeedsCount
                    hasOneParticipationToPackagingEvent
                    businessMapping {
                        completed
                        businessMappingPriorities {
                            count
                        }
                        businessMappingTags {
                            count
                            items {
                                tag {
                                    id
                                    tagLocation {
                                        code
                                    }
                                }
                                type
                            }
                        }
                    }
                    hasMarketBusinessOpportunities
                    company {
                        idcompany
                        registrationStep
                        company_type_machine_name
                        isWabelClubMember
                        remainingCategoryBenchmarks
                        remainingCompanyProfileSubmissions
                        activeMemberships {
                            idsubscription
                            resetDate
                            endDate
                            subscriptionpack{
                              idsubscriptionpack
                              label
                              priority
                              monthlyProductRequestsNumber
                              monthlyCategoryBenchmarksNumber
                              monthlyCompanyProfileSubmissionsNumber
                            }
                        }
                        canSubmitProfile
                        citylocation {
                            formattedAddress
                            city
                            country {
                                name
                                tagLocation {
                                    code
                                }
                            }
                        }
                        logoUrl(size: "123")
                        companyProductsTags(tagTypeInternalName:"category"){
                            items {
                                    ...fullTag
                                }
                        }
                        bestCategories{
                            items {
                                ...fullTag
                            }
                        }
                    }
                    lastOpenedSelectionUpcomingRegistrationNotFinished {
                        meetingsSelectedNumber
                        selectionFinished
                        selectionToolUrl
                        event {
                            name
                        }
                    }
                    permissions
                    notifications {
                        id
                        type
                        status
                        severity
                        title
                        content
                        internalName
                        dateLastAction
                    }
                    mobile_event
                    eventOptinSms
                    eventOptinEmail
                    hasCategoryHPC: hasCategories(idcategories:[10300, 10340], atLeastOne: true),
                    wabel {
                        urlname
                        name
                        link
                        fileSmallLogo {
                            url
                        }
                        hasCurrentMemberRegistration
                    }
                    eventOrganizersWhereIParticipate {
                        items {
                            name
                            urlname
                            link
                            fileSmallLogo {
                                url
                            }
                        }
                    }
                }
            }
            ${MemberGraphQL.fragments.me}
            ${TagTypeGraphql.fragments.fullTag}
        `,

        memberByEmail: gql`
            query memberByEmail($email: String!) {
                memberByEmail(email: $email) {
                    idmember
                }
            }
        `,
        checkMemberVideoMeetingsAndThreadByCompany: gql`
            query Member($companyId: Int!){
              me{
                idmember
                threadWithCompany(companyId:$companyId){
                  idthread
                }
                hasVideoMeetingsWithCompany(companyId:$companyId)
              }
            }
        `,
        getTimezoneByMember: gql`
            query timezoneByMember($idmember: Int!) {
                member(idmember: $idmember) {
                    idmember
                    timezone
                }
            }
        `,
        clientList: gql`
            query ClientList {
                me {
                    company {
                        companyClients {
                            items {
                                client {
                                    idcompany
                                    name
                                    logoUrl(size: "123")
                                    country {
                                        name
                                    }
                                }
                                label
                            }
                        }
                    }
                }
            }
        `,
        myCompanyProductCategories: gql`
            query myCompanyProductCategories {
                me {
                    company {
                        rootCategories {
                            items{
                              id
                              name
                            }
                        }
                        products(published: true) {
                            iduserproduct
                            name
                            published
                            category {
                                name
                                parent {
                                    parent {
                                        name
                                        machineName
                                    }
                                }
                            }
                            logoUrl(size: "123")
                        }
                    }
                }
            }
        `,
        meSummitRegistrations: gql`
            query Member {
                me {
                    permissions
                    company {
                        company_type_machine_name
                    }
                    summitRegistrations {
                        items {
                            productsLookingFor
                            summit {
                                summitCode,
                                name,
                                dates,
                                #                            city,
                                bannerUrl,
                                certainEvent {
                                    eventCode,
                                    startDate
                                    endDate
                                    name
                                },
                                startDate,
                                endDate,
                                categoriesLevel2: categoriesByDepth(depth: 1) {
                                    id
                                    name
                                    parent {
                                        id
                                        name
                                    }
                                }
                                categoriesLevel3: categoriesByDepth(depth: 2) {
                                    id
                                    name
                                    parent {
                                        id
                                    }
                                }
                                tags {
                                    items {
                                        ...fullTag
                                    }
                                }
                            }
                            buyerEventRegistration {
                                idcertaineventregistration
                                comingOn
                                booking
                                organization
                                cityDeparture
                                travelOrganizationByWabel
                                accomodationOrganizationByWabel
                            }
                        }
                    },
                    buyingScopeCategories: buyingScopeTags(tagTypeInternalName: "${TagTypeService.CATEGORY_INTERNAL_NAME}") {
                        items {
                            ...fullTag
                        }
                    }
                    buyingScopeActivities: buyingScopeTags(tagTypeInternalName: "${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
                        items {
                            ...fullTag
                        }
                    }
                }
            }
            ${TagTypeGraphql.fragments.fullTag}
        `,

        myFavorites: gql`
            query Member {
                me {
                    idmember,
                    favorites {
                        count
                        ...items
                    }
                }
            }
            ${MemberGraphQL.fragments.myFavorites}
        `,

        myProfile: gql`
            query Member {
                me {
                    ...myProfile
                }
            }
            ${MemberGraphQL.fragments.myProfile}
        `,

        mySummitRegistrations: gql`
            query mySummitRegistrations {
                me {
                    summitRegistrations {
                        items {
                            summit {
                                dates
                                startDate
                                endDate
                                name
                                bannerUrl
                                certainEvent {
                                    eventCode
                                    catalogReady
                                    selectionReady
                                }
                            }
                            buyerEventRegistration {
                                idcertaineventregistration
                                selectionToolUrl
                                selectionFinished
                                meetingsNumber
                                meetingPreferencesSelectedCount: meetingPreferences(status: ["selected"]) {
                                    count
                                }
                                meetingsCount: meetings {
                                    count
                                }
                                participation {
                                    participantNumber
                                }
                            }
                        }
                    }
                    company {
                        company_type_machine_name
                    }
                }
            }
        `,

        myDashboardSupplier: gql`
            query myDashboardSupplier($startDate: String!, $endDate: String!, $oldStartDate: String!, $oldEndDate: String!) {
                me {
                    ...myDashboardBase
                    company {
                        idcompany
                        name
                        onBoardingHasProducts
                        onBoardingHasRelationshipWithBuyer
                        searchOnProducts(startDate: $startDate, endDate: $endDate)
                        oldSearchOnProducts: searchOnProducts(startDate: $oldStartDate, endDate: $oldEndDate)
                        productSamplesAsked(startDate: $startDate, endDate: $endDate)
                        oldProductSamplesAsked: productSamplesAsked(startDate: $oldStartDate, endDate: $oldEndDate)
                        totalFavoritesOnCompany(startDate: $startDate, endDate: $endDate)
                        oldTotalFavoritesOnCompany: totalFavoritesOnCompany(startDate: $oldStartDate, endDate: $oldEndDate)
                        company_type_machine_name
                        type
                        rootCategories: tags(tagTypeInternalName: "${TagTypeService.CATEGORY_INTERNAL_NAME}", onlyLevel1: true) {
                            items {
                                ...tag
                            }
                        }
                        potentialBuyersInterested {
                            items(limit: 5) {
                                idcompany
                                name
                                logoUrl(size: "123")
                                urlname
                                country {
                                    name
                                    tagLocation {
                                        code
                                    }
                                }
                            }
                        }
                    }
                }
            }
            ${MemberGraphQL.fragments.myDashboardBase}
            ${TagTypeGraphql.fragments.tag}
        `,

        myDashboardRelationships: gql`
            query myDashboardRelationships {
                me {
                    idmember
                    company {
                        idcompany
                        name
                        onBoardingHasRelationshipWithBuyer
                    }
                    relationshipsCounter {
                        label
                        count
                        value
                        color
                    }
                    relationships(orderBy: "updated_date DESC, created_date DESC") {
                        count
                        items(limit: 10) {
                            idcompanyRelationship
                            currentStatus {
                                idrelationshipStatus
                            }
                            source
                            sourceDetails
                            lastStatusUpdaterMember {
                                company {
                                    idcompany
                                    name
                                }
                            }
                            toIdcompany {
                                idcompany
                                name
                                logoUrl(size: "123")
                            }
                            toIdmember {
                                idmember
                                fullname
                            }
                            fromIdcompany {
                                idcompany
                                name
                                logoUrl(size: "123")
                            }
                            fromIdmember {
                                idmember
                                fullname
                            }
                        }
                    }
                    relationshipsUpdatedByWabel: relationships(updatedBy: "wabel") {
                        count
                    }
                }
            }
        `,

        myDashboardSupplierSuggestions: gql`
            query myDashboardSupplierSuggestions {
                me {
                    idmember
                    company {
                        idcompany
                        name
                        company_type_machine_name
                        potentialBuyersInterested {
                            items(limit: 5) {
                                idcompany
                                name
                                logoUrl(size: "123")
                                urlname
                                country {
                                    name
                                    tagLocation {
                                        code
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `,

        myDashboardBuyerSuggestions: gql`
            query myDashboardBuyerSuggestions {
                me {
                    company {
                        idcompany
                        name
                        company_type_machine_name
                        potentialSuppliersInterested {
                            items(limit: 5) {
                                idcompany
                                name
                                logoUrl(size: "123")
                                urlname
                                country {
                                    name
                                    tagLocation {
                                        code
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `,
        myDashboardBuyer: gql`
            query myDashboardBuyer($startDate: String!, $endDate: String!, $oldStartDate: String!, $oldEndDate: String!) {
                me {
                    ...myDashboardBase
                    company {
                        idcompany
                        name
                        searchOnCompany(startDate: $startDate, endDate: $endDate)
                        oldSearchOnCompany: searchOnCompany(startDate: $oldStartDate, endDate: $oldEndDate)
                        company_type_machine_name
                        type
                        rootCategories: tags(tagTypeInternalName: "${TagTypeService.CATEGORY_INTERNAL_NAME}", onlyLevel1: true) {
                            items {
                                ...tag
                            }
                        }
                        potentialSuppliersInterested {
                            items(limit: 5) {
                                idcompany
                                name
                                logoUrl(size: "123")
                                urlname
                                country {
                                    name
                                    tagLocation {
                                        code
                                    }
                                }
                            }
                        }
                    }
                }
            }
            ${MemberGraphQL.fragments.myDashboardBase}
            ${TagTypeGraphql.fragments.tag}
        `,

        myRelationships: gql`
            query myRelationships($search: String, $statuses: [Int!], $sources: [FilterSourceInput!], $updatedBy: String, $orderBy: String, $maxUpdatedDate: String, $offset: Int) {
                me {
                    company {
                        idcompany
                        company_type_machine_name
                        onBoardingHasRelationshipWithBuyer
                    }
                    relationshipsCounter {
                        label
                        count
                        value
                        color
                    }
                    relationships(search: $search, statuses: $statuses, sources: $sources, updatedBy: $updatedBy, orderBy: $orderBy, maxUpdatedDate: $maxUpdatedDate) {
                        count
                        items(limit: 10, offset: $offset) {
                            idcompanyRelationship
                            currentStatus {
                                idrelationshipStatus
                            }
                            source
                            sourceDetails
                            commentGiven
                            lastStatusUpdaterMember {
                                company {
                                    idcompany
                                    name
                                }
                            }
                            toIdcompany {
                                idcompany
                                name
                                logoUrl(size: "123")
                            }
                            toIdmember {
                                idmember
                                fullname
                            }
                            fromIdcompany {
                                idcompany
                                name
                                logoUrl(size: "123")
                            }
                            fromIdmember {
                                idmember
                                fullname
                            }
                            tags {
                                idtagreference
                                name
                            }
                        }
                    }
                    relationshipsUpdatedByWabel: relationships(updatedBy: "wabel") {
                        count
                    }
                    sourceFilters {
                        id
                        type
                        label
                        date
                    }
                }
            }
        `,

        summitsRegistration: gql`
            query summitsRegistration {
                me {
                    summitsRegistration {
                        bannerUrl
                        summitCode
                        dates
                        name
                        summitCode
                        certainEvent {
                            eventCode
                            name
                            startDate
                            endDate
                            dates
                            location
                        }
                    }
                    summitRegistrations {
                        items {
                            eventregistration {
                                event {
                                    eventCode
                                },
                                meetingsNumber
                            }
                            companyParticipation {
                                participantNumber,
                                numberOfRegistrationsByCompany
                            }
                        }
                    }
                }
            }
        `,

        getSummitByCode: gql`
            query summit($summitCode: String!) {
                summit(summitCode: $summitCode) {
                    planningImageUrl
                    floorMapImageUrl
                    bannerUrl
                    zohoId
                    name
                    eventPreregisterKey {
                        count
                        items {
                            lookingFor
                            availabilityStart
                            availabilityEnd
                            cityDeparture
                            hasRetailer
                            hasFoodService
                            zohoId
                            wechatId
                            idpreregister
                            dateUpdated
                            productLookingFor
                            importerHasRetailer
                            importerHasFoodservice
                            email
                            firstname
                            lastname
                            companyType
                            companyName
                            position
                            phone
                        }
                    }
                }
            }
        `,

        eventRegistrations: gql`
            query eventRegistrations {
                me {
                    certainEventRegistrations {
                        items {
                            meetingsNumber
                            idcertaineventregistration
                            event {
                                eventCode
                                status
                                name
                                startDate
                                endDate
                                dates
                                location
                            }
                            summits {
                                summitCode
                                name
                                startDate
                                endDate
                                dates
                                bannerUrl
                            }
                            participation {
                                numberOfRegistrationsByCompany
                                participantNumber
                            }
                        }
                    }
                }
            }
        `,

        getCompanyMembers: gql`
            query companyMembers($idCompany: Int!) {
                company(id: $idCompany) {
                    idcompany
                    allMembers {
                        profileScore
                        status
                        zohoId
                        email
                        mobile
                        mobile_event
                        fullname
                        logoUrl
                        wechatId
                        idmember
                        jobtitle
                    }
                }
            }
        `,

        getAddressList: gql`
            query currentMemberAddresses {
                currentMemberAddresses {
                    items {
                        idaddress
                        shortName
                        firstName
                        lastName
                        shortName
                        addressLine1
                        addressLine2
                        zipcode
                        company {
                            idcompany
                            formattedAddress
                            citylocation {
                                city
                                country {
                                    name
                                }
                            }
                        }
                        city
                        phone
                        country {
                            id
                            tagLocation {
                                code
                            }
                            name
                        }
                        state
                        defaultAddress
                    }
                }
            }`,

        companyEventParticipations: gql`
            query companyEventParticipations {
                me {
                    company {
                        eventParticipations {
                            participantNumber
                            memberRegistrations {
                                count
                            }
                            meetingsNumber
                            certainEvent {
                                eventCode
                                name
                                startDate
                                endDate
                                location
                                dates
                                summits {
                                    items {
                                        name
                                    }
                                }
                                eventOrganizer {
                                    name
                                    urlname
                                }
                            }
                            summits {
                                items {
                                    name
                                }
                            }
                        }
                    }
                }
            }
        `,

        meBuyingScope: gql`
            query Member {
                me {
                    permissions
                    buyingScopeActivities: buyingScopeTags(tagTypeInternalName: "${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
                        items {
                            ...fullTag
                        }
                    }
                    buyingScopeCategories: buyingScopeTags(tagTypeInternalName: "${TagTypeService.CATEGORY_INTERNAL_NAME}") {
                        items {
                            ...fullTag
                        }
                    }
                }
            }
            ${TagTypeGraphql.fragments.fullTag}
        `,

        getNotifications: gql`
            query Member {
                me {
                    notifications {
                        id
                        type
                        status
                        severity
                        title
                        content
                        internalName
                        dateLastAction
                    }
                }
            }
        `,

        getMyProspectListByFilters: gql`
            query getMyProspectListByFilters {
                currentMemberProspectListByFilters {
                    items {
                        idcompany
                        name
                        urlname
                        logoUrl(size: "123")
                        ca {
                            name
                        }
                        nbSellingPoints {
                            name
                        }
                        type
                        country {
                            id
                            name
                            tagLocation {
                                code
                            }
                        }
                       lastCompanyProfileSubmissionToCompany {
                           buyerStatus
                           createdAt
                            message {
                                thread {
                                    idthread
                                }
                            }
                        }
                        companytype {
                            name
                            idcompanytype
                        }
                        buyingScope {
                            items {
                                name
                                id
                            }
                        }
                        activities: tags(tagTypeInternalName: "${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
                            items {
                                name
                                id
                            }
                        }
                        specializations: tags(tagTypeInternalName: "${TagTypeService.SPECIALIZATION_INTERNAL_NAME}") {
                            items {
                                name
                                id
                            }
                        }
                        ca {
                            id
                            name
                        }
                        hasBuyerForCurrentMemberCategories
                        meetingsWithCurrentMember {
                            items {
                                certainEvent {
                                   eventCode
                                   name
                                }
                            }
                        }
                        isFavoriteForCurrentMember
                        companyHasCurrentMemberCompanyAsSourcingRequestRecommendation
                        companyHasCurrentMemberCompanyAsSourcingNeedRecommendation
                        isCompanyInCurrentMemberBusinessMappingRecommendations
                    }
                }
            }
        `,
    };

    static mutations = {

        sendEmailVerificationLink: gql`
            mutation sendEmailVerificationLink {
                sendEmailVerificationLink {
                    idmember,
                    hasEmailVerified
                }
            }
        `,

        updateUserNewTimezone: gql`
            mutation Member($timezone: String!) {
                updateUserNewTimezone(timezone: $timezone) {
                    idmember,
                    timezone
                }
            }
        `,

        sendEmailPasswordRecovery: gql`
            mutation Member($email: String!, $autologRedirection: String) {
                sendEmailPasswordRecovery(email: $email, autologRedirection: $autologRedirection) {
                    idmember
                }
            }
        `,

        updateMember: gql`
            mutation Member(
                $title: String,
                $firstname: String,
                $lastname: String,
                $jobtitle: String,
                $phone: String,
                $email: String,
                $logo: [FileToUploadInput!],
                $timezone: String,
            ) {
                updateMember(
                    title: $title,
                    firstname: $firstname,
                    lastname: $lastname,
                    jobtitle: $jobtitle,
                    phone: $phone,
                    email: $email,
                    logo: $logo,
                    timezone: $timezone,
                ) {
                    ...myProfile
                }
            }
            ${MemberGraphQL.fragments.myProfile}
        `,
        updateSignupInformationAfterZohoInvitation: gql`
            mutation Member(
                $firstname: String!,
                $lastname: String!,
                $jobtitle: String!,
                $phone: String!,
                $email: String!,
                $timezone: String!
            ) {
                updateSignupInformationAfterZohoInvitation(
                    firstname: $firstname,
                    lastname: $lastname,
                    jobtitle: $jobtitle,
                    phone: $phone,
                    email: $email,
                    timezone: $timezone
                ) {
                    idmember
                }
            }
        `,

        createMember: gql`
            mutation Member(
                $idcompany: Int!,
                $firstname: String!,
                $lastname: String!,
                $jobtitle: String!,
                $phone: String!,
                $email: String!,
                $timezone: String!,
                $logIn: Boolean!,
                $isPreregister: Boolean
            ) {
                createMember(
                    idcompany: $idcompany,
                    firstname: $firstname,
                    lastname: $lastname,
                    jobtitle: $jobtitle,
                    phone: $phone,
                    email: $email,
                    timezone: $timezone,
                    logIn: $logIn,
                    isPreregister: $isPreregister
                ) {
                    idmember
                }
            }
        `,

        createMemberWithCompany: gql`
            mutation Member(
                $idCompanyType: Int!,
                $name: String!,
                $country_name: String,
                $region_name: String,
                $city_name: String,
                $postal_code: String,
                $formatted_address: String,
                $website: String!,
                $companyPhone: String!,
                $categories: [Int!],
                $idcompanytags: [Int!]!,
                $specializations: [Int!],
                $activities: [Int!],
                $firstname: String!,
                $lastname: String!,
                $jobtitle: String!,
                $phone: String!,
                $email: String!,
                $timezone: String!,
                $logIn: Boolean!,
                $isPreregister: Boolean
            ) {
                createMemberWithCompany(
                    idCompanyType: $idCompanyType,
                    name: $name,
                    country_name: $country_name,
                    region_name: $region_name,
                    city_name: $city_name,
                    postal_code: $postal_code,
                    formatted_address: $formatted_address,
                    website: $website,
                    companyPhone: $companyPhone,
                    categories: $categories,
                    idcompanytags: $idcompanytags,
                    specializations: $specializations,
                    activities: $activities,
                    firstname: $firstname,
                    lastname: $lastname,
                    jobtitle: $jobtitle,
                    phone: $phone,
                    email: $email,
                    timezone: $timezone,
                    logIn: $logIn,
                    isPreregister: $isPreregister
                ) {
                    idmember,
                    company{
                        idcompany
                    }
                }
            }
        `,

        savePassword: gql`
            mutation Member($password: String!) {
                savePassword(password: $password) {
                    idmember
                    company{
                        company_type_machine_name
                    }
                }
            }
        `,

        changeSettings: gql`
            mutation Member($password: String, $locale: String) {
                changeSettings(password: $password, locale: $locale) {
                    idmember
                    company{
                        company_type_machine_name
                    }
                    locale
                }
            }
        `,
        changeZohoRegistrationMemberPassword: gql`
            mutation Member($password: String!) {
                changeZohoRegistrationMemberPassword(password: $password) {
                    idmember
                    company{
                        company_type_machine_name
                    }
                }
            }
        `,

        addFavorite: gql`
            mutation Member($idcompany: Int!) {
                addFavorite(idcompany: $idcompany) {
                    idmember
                }
            }
        `,

        saveFavorites: gql`
            mutation Member($userproductIds: [Int!], $companyIds: [Int!]) {
                saveFavorites(userproductIds: $userproductIds, companyIds: $companyIds) {
                    ...items
                }
            }
            ${MemberGraphQL.fragments.myFavorites}
        `,

        login: gql`
            mutation Member($username: String!, $password: String!) {
                login(username: $username, password: $password) {
                    idmember
                    joinMode
                    mobile
                    email
                    firstname
                    name
                    jobtitle
                    signupFinished
                    company{
                        idcompany
                        name
                        company_type_machine_name
                    }
                }
            }
        `,

        updateRelationshipStatus: gql`
            mutation updateRelationshipStatus($idCompanyRelationship: Int!, $idStatus: Int, $idTags: [Int!], $comment: String) {
                updateRelationshipStatus(idCompanyRelationship: $idCompanyRelationship, idStatus: $idStatus, idTags: $idTags, comment: $comment) {
                    idcompanyRelationship
                }
            }
        `,

        inviteNewColleague: gql`
            mutation inviteNewColleague($firstName: String!, $lastName: String!, $email: String!) {
                inviteNewColleague(firstName: $firstName, lastName: $lastName, email: $email) {
                    idmember
                    firstname
                    name
                    logoUrl(size: "123", returnNull: true)
                    email
                    hasEmailVerified
                    isCompanyOwner
                    hasBeenReactivated
                }
            }
        `,

        savePreregisterInfo: gql`
            mutation savePreregisterInfo($idmember: Int!, $buyingScopeCategories: [Int!]!, $buyingScopeActivities: [Int!]!) {
                savePreregisterInfo(idmember: $idmember, buyingScopeCategories: $buyingScopeCategories, buyingScopeActivities: $buyingScopeActivities) {
                    buyingScopeCategories: buyingScopeTags(tagTypeInternalName: "${TagTypeService.CATEGORY_INTERNAL_NAME}") {
                        items {
                            ...fullTag
                        }
                    }
                    buyingScopeActivities: buyingScopeTags(tagTypeInternalName: "${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
                        items {
                            ...fullTag
                        }
                    }
                }
            }
            ${TagTypeGraphql.fragments.fullTag}
        `,

        acceptNotification: gql`
            mutation acceptNotification($internalName: String!, $type: String) {
                acceptNotification(internalName: $internalName, type: $type) {
                    id
                    type
                    status
                    severity
                    title
                    content
                    internalName
                }
            }
        `,

        acceptNeedlTc: gql`
            mutation acceptNeedlTc {
                acceptNeedlTc {
                    idmember
                    needlTcAccepted
                }
            }
        `,

        updateParticipationDetails: gql`
            mutation updateParticipationDetails($mobileEvent: String!, $notificationPreference: String!) {
                updateParticipationDetails(mobileEvent: $mobileEvent, notificationPreference: $notificationPreference) {
                    idmember
                    mobile_event
                    eventOptinEmail
                    eventOptinSms
                }
            }
        `,

        deactivateMemberFromCompany: gql`
            mutation deactivateMemberFromCompany($idMember: Int!) {
                deactivateMemberFromCompany(idMember: $idMember) {
                    idmember
                }
            }
        `,

        saveWebRTCResult: gql`
            mutation saveWebRTCResult($score: Int!, $logs: String!, $isFinished: Boolean) {
                saveWebRTCResult(score: $score, logs: $logs, isFinished: $isFinished)
            }
        `,

        removeProspect: gql`
            mutation removeProspect($idcompany: Int!) {
                removeProspect(idcompany: $idcompany)
            }
        `,
    };
}
