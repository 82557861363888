import {Injectable} from '@angular/core';
import {interval, Subscription} from 'rxjs';
import {TemplateService} from './template.service';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CheckForUpdateService {

    private subscription$: Subscription;

    constructor(private http: HttpClient, private templateService: TemplateService) {
    }

    startVersionCheck(): void {
        if (environment.current_env !== 'dev') {
            this.subscription$ = interval(60000).subscribe(() => this.checkVersion());
        }
    }

    private checkVersion(): void {
        const timestampInSeconds = Math.floor(Date.now() / 1000);
        this.http.get('/app-version.txt?t=' + timestampInSeconds, {responseType: 'text'})
            .pipe(
                tap((result: string) => {
                    result = result.trim();
                    if (environment.appVersion !== result) {
                        this.templateService.openNewAppVersionModal$.next(true);
                        this.stopVersionCheck();
                    }
                })
            ).subscribe();
    }

    stopVersionCheck(): void {
        this.subscription$.unsubscribe();
    }

    public refreshApplication(): void {
        document.location.reload();
    }
}
