import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Company} from "../../../services/wabel-client/entities/company";
import {Tag} from "../../../services/wabel-client/entities/tag";
import {TagType} from "../../../services/wabel-client/entities/tag_type";
import {Product} from "../../../services/wabel-client/entities/product";
import {ImageService} from '../../../services/image.service';

@Component({
    selector: 'app-company-preview',
    templateUrl: './company-preview.component.html',
    styleUrls: ['./company-preview.component.scss'],
    host: {
        class: 'company-preview'
    }
})
export class CompanyPreviewComponent implements OnInit {
    typeTags: TagType[] = [];
    tagsNoType: Tag[] = [];

    @Input() company: Company;
    @Input() showProducts = 0;
    @Input() showProductsForMobile: number = null;
    @Input() inModal = false;
    @Input() selected = false;
    @Input() showTags = false;
    @Input() minimizedDesign = false;
    @Input() minimizedCurrency = false;
    showCategories = 3;

    constructor(public imageService: ImageService) {
    }

    _tags: Tag[] = [];
    get tags() {
        return this._tags;
    }

    set tags(tags) {
        this._tags = tags;
        this._tags.forEach(t => {
            if (t.tagType) {
                let typeIndex = this.typeTags.findIndex(tt => t.tagType.internalName === tt.internalName);
                if (typeIndex === -1) {
                    if (t.tagType.internalName === 'category') {
                        t.tagType.name = 'Product category';
                    } else if (t.tagType.internalName === 'activity') {
                        t.tagType.name = 'Product type';
                    }
                    this.typeTags.push(t.tagType);
                    typeIndex = this.typeTags.length - 1;
                }
                const tagIndex = this.typeTags[typeIndex].tags.findIndex(tag => tag.id === t.id);
                if (tagIndex === -1) {
                    this.typeTags[typeIndex].tags.push(t);
                }
            } else {
                const tagIndex = this.tagsNoType.findIndex(tag => tag.id === t.id);
                if (tagIndex === -1) {
                    this.tagsNoType.push(t);
                }
            }
        });
    }

    @Output() productClicked = new EventEmitter<Product>();
    @Output() moreProductsClicked = new EventEmitter<void>();

    ngOnInit(): void {
        this.tags = this.company.categories.concat(this.company.activities, this.company.priceRanges);
        if (this.showProducts && this.showProductsForMobile === null) {
            this.showProductsForMobile = this.showProducts;
        }
    }
}
