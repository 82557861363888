import {Component, Input, OnInit} from '@angular/core';
import {Product} from "../../../services/wabel-client/entities/product";
import {ImageService} from '../../../services/image.service';

@Component({
    selector: 'product-card',
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {

    @Input() product: Product;
    @Input() displayEdition: Boolean = true;
    @Input() displayVisibility: Boolean = true;

    constructor(public imageService: ImageService) {
    }

    ngOnInit() {
    }

}
