import {
    Component, ContentChild,
    ContentChildren,
    Input, QueryList,
    TemplateRef, ViewChild
} from '@angular/core';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {NdlSnackbarService} from './snackbar.service';
import {NdlSnackbarActionComponent} from './snackbar-action.component';

@Component({
    selector: 'ndl-snackbar,[ndl-snackbar],[ndlSnackbar]',
    templateUrl: './snackbar.component.html',
    exportAs: 'ndlSnackbar'
})
export class NdlSnackbarComponent<T = any> {

    @Input() class = '';
    @Input() icon: string = null;
    @Input() horizontalPosition: MatSnackBarHorizontalPosition = 'left';
    @Input() verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    @Input() duration = 5000;
    @Input() closeIcon = true;

    @ViewChild(TemplateRef, {static: true}) content: TemplateRef<T>;
    @ContentChildren(NdlSnackbarActionComponent) actions: QueryList<NdlSnackbarActionComponent>;

    open(duration?: number, horizontalPosition?: MatSnackBarHorizontalPosition, verticalPosition?: MatSnackBarVerticalPosition) {
        this.snackbarService.display({
            classes: this.class,
            horizontalPosition: horizontalPosition ?? this.horizontalPosition,
            verticalPosition: verticalPosition ?? this.verticalPosition,
            duration: duration ?? this.duration,
            data: {
                content: this.content,
                icon: this.icon,
                closeIcon: this.closeIcon,
                actions: this.actions?.map(a => a.content)
            }
        });
    }

    constructor(public snackbarService: NdlSnackbarService) {
    }

}
