import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Product} from "../../../../services/wabel-client/entities/product";
import {MemberService} from "../../../../services/wabel-client/services/member.service";
import {CompanyProfileSubmissionService} from "../../../../services/wabel-client/services/company-profile-submission.service";
import {Subscription} from "rxjs";

@Component({
    selector: 'submission-products-step',
    templateUrl: './submission-products-step.component.html',
    styleUrls: ['./submission-products-step.component.scss']
})
export class SubmissionProductsStepComponent implements OnInit {
    @Input() providedCategories = null;
    @Input() isFeaturedProducts = "yes";
    @Input() featuredSelectedProducts: Product[] = [];
    @Output() isFeaturedProductsChange = new EventEmitter<string>();
    @Output() featuredSelectedProductsChange = new EventEmitter<Product[]>();
    @Output() validateProductsStep = new EventEmitter<boolean>();
    public confirmationLabels: Array<{ value: string, label: string }> = [
        {value: "yes", label: 'Yes, I would like to feature products'},
        {value: "no", label: 'No, I\'ll share them later'},
    ];
    myProducts: Product[] = [];
    myProductsCopy: Product[] = [];
    currentSelectedProductCategory = null;
    maxProductsSelection = 4;
    productsError = false;
    myCompanyProductCategories$: Subscription;

    constructor(private memberService: MemberService, private companyProfileSubmissionService: CompanyProfileSubmissionService) {
    }

    ngOnInit() {
        this.fetchCompanyProducts();
        this.companyProfileSubmissionService.doCheckStep.subscribe(step => {
            if (step === this.companyProfileSubmissionService.FEATURED_PRODUCTS_STEP) {
                this.onChange();
            }
        });
    }

    onChange() {
        this.productsError = false;
        if (this.isFeaturedProducts === 'yes') {
            if (this.featuredSelectedProducts.length === 0) {
                this.productsError = true;
                this.validateProductsStep.emit(false);
            } else {
                this.featuredSelectedProductsChange.emit(this.featuredSelectedProducts);
                this.validateProductsStep.emit(true);
            }
        } else {
            this.validateProductsStep.emit(true);
        }
        this.isFeaturedProductsChange.emit(this.isFeaturedProducts);
    }

    fetchCompanyProducts() {
        if (this.myCompanyProductCategories$) {
            this.myCompanyProductCategories$.unsubscribe();
        }
        this.myCompanyProductCategories$ = this.memberService.getMyCompanyProductCategories().subscribe(data => {
                if (!data) {
                    return;
                }
                this.myProducts = data.company.products.map(x => x.published && x);
                this.renderCategoryWithProducts(this.providedCategories[0]);
            },
            () => {
            });
    }

    renderCategoryWithProducts(category: string) {
        this.currentSelectedProductCategory = category;
        this.myProductsCopy = this.myProducts.filter(x => x.category && x.category.parent && x.category.parent.parent && x.category.parent.parent.name === category);
    }

    categoryWiseProductCounter(category: string) {
        let total = 0;
        this.myProducts.forEach(x => {
            if (x.category && x.category.parent && x.category.parent.parent && x.category.parent.parent.name === category) {
                total++;
            }
        });
        return total;
    }

    checkIfProductExist(product: Product) {
        return this.featuredSelectedProducts.findIndex(x => x.iduserproduct === product.iduserproduct) !== -1;
    }

    selectFeaturedProducts(product: Product) {
        const index = this.featuredSelectedProducts.findIndex(x => x.iduserproduct === product.iduserproduct);
        if (index !== -1) {
            this.featuredSelectedProducts.splice(index, 1);
        } else {
            if (this.maxProductsSelection > this.featuredSelectedProducts.length) {
                this.featuredSelectedProducts.push(product);
            }
        }
        this.onChange();
    }

}
