import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NdlLoadingService {
    private _loading = 0;
    isLoading$ = new BehaviorSubject<boolean>(true);

    get pageLoading() {
        return this._loading > 0;
    }

    load() {
        ++this._loading;
        this.isLoading$.next(this.pageLoading);
    }

    endLoading() {
        if (this._loading) {
            --this._loading;
        }
        this.isLoading$.next(this.pageLoading);
    }
}
