import {WabelEntity} from "./wabel-entity.abstract";
import {Tag} from "./tag";
import {Banner} from "./banner";

export class PurchasingGroupStoresCountryDetails extends WabelEntity {
    constructor(obj: any) {
        super(obj);
        this.country = obj.country ? new Tag(obj.country) : null;
        this.banners = obj.banners ? obj.banners.map(banner => new Banner(banner)) : [];
    }

    countryName: string;
    country: Tag;
    hyper: number;
    super: number;
    convenience: number;
    distributionCenter: number;
    warehouse: number;
    pointOfSales: number;
    cashAndCarry: number;
    total: number;
    banners: Banner[];
    hasBanners: boolean;

    get id(): string {
        return this.countryName;
    }
}
