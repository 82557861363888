import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../../../../services/auth.service';
import {CompanyService} from "../../../../../../services/wabel-client/services/company.service";
import {Company} from "../../../../../../services/wabel-client/entities/company";
import {UtilsService} from "../../../../../../services/utils.service";
import {SignupService} from "../../../../../../services/wabel-client/services/signup.service";

@Component({
    selector: 'registration-company-visuals',
    templateUrl: './registration-company-visuals.component.html',
    styleUrls: ['./registration-company-visuals.component.scss']
})
export class RegistrationCompanyVisualsComponent implements OnInit {
    formCompleted = false;
    formSubmitted = false;
    showErrorLogo = false;
    showErrorImages = false;
    showErrorUploading = false;
    saving = false;

    companyCopy: Company;

    @Output() stepFinished: EventEmitter<any> = new EventEmitter();

    constructor(public authService: AuthService,
                public companyService: CompanyService,
                private utilsService: UtilsService,
                private signupService: SignupService) {

    }

    ngOnInit() {
        this.companyCopy = this.utilsService.cloneObject<Company>(this.authService.getMember().company);
    }

    checkFormErrors() {
        if (!this.formSubmitted) {
            return;
        }
        this.formCompleted = true;

        this.showErrorUploading = this.companyService.checkFileUploading(this.companyCopy);

        this.showErrorLogo = false;
        if (this.companyCopy.newCompanyLogo.length === 0) {
            this.showErrorLogo = true;
            this.formCompleted = false;
        }

        this.showErrorImages = false;
        if (this.companyCopy.newCompanyImages.length === 0) {
            this.showErrorImages = true;
            this.formCompleted = false;
        }
    }

    submitForm() {
        this.formSubmitted = true;
        this.checkFormErrors();
        if (!this.formCompleted) {
            return;
        }
        this.saving = true;
        this.signupService.saveCompanyImages(this.companyCopy).subscribe(data => {
            this.stepFinished.emit();
            this.authService.getMember().company.registrationStep = data.registrationStep;
            this.saving = false;
        }, err => {
            this.saving = false;
            console.error(err);
        });
    }
}
