import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import PhoneNumber from 'awesome-phonenumber';
import {NdlUtilsService} from "../core/utils.service";

export class NdlValidators {
    /**
     * Validates a string to match Youtube or Vimeo urls
     */
    static validVideoUrl: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        // if the value is null, we don't return error
        // this should be handled by Validator.required
        if (!control.value) {
            return null;
        }
        let re = /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i;
        let matches = re.exec(control.value);
        if (!matches) {
            re = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
            matches = re.exec(control.value);
        }
        return matches ? null : {'invalid-video-url': true};
    }

    /**
     * Validates the format of a phone number
     */
    static validPhone: ValidatorFn = (control: AbstractControl) => {
        if (control.value) {
            const phoneNumber = new PhoneNumber(control.value);
            const possibility = phoneNumber.toJSON().possibility;
            if (possibility === "is-possible") {
                return null;
            }
            return {[phoneNumber.toJSON().possibility]: true};
        } else {
            return null;
        }
    }

    static validAddress = (currentAddress: string) => {
        return (control: AbstractControl) => {
            return (control.value && typeof control.value === 'string' && control.value !== currentAddress) ? {'invalid-address': true} : null;
        };
    };

    /**
     * Validates a string to match correct urls
     */
    static validUrl: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        // if the value is null, we don't return error
        // this should be handled by Validator.required
        if (!control.value) {
            return null;
        }
        const re = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
        const matches = re.exec(control.value);
        return matches ? null : {'invalid-url': true};
    }

    static validTextLengthWithNoHTMLTags = (minLength: number) => {
        return (control: AbstractControl) => {
            return (control.value && typeof control.value === 'string' && NdlUtilsService.cleanHtmlString(control.value).length < minLength) ? {'minlength': true} : null;
        };
    };
}
