<div class="container-fluid">
    <div class="row">
        <div class="col-2">
            <ndl-card>
                <ndl-menu>
                    <ndl-menu-item link="/demo/banners">Banners</ndl-menu-item>
                    <ndl-menu-item link="/demo/buttons">Buttons</ndl-menu-item>
                    <ndl-menu-item link="/demo/cards">Cards</ndl-menu-item>
                    <ndl-menu-item link="/demo/carousel">Carousel</ndl-menu-item>
                    <ndl-menu-item link="/demo/cascader">Cascader</ndl-menu-item>
                    <ndl-menu-item link="/demo/charts">Charts</ndl-menu-item>
                    <ndl-menu-item link="/demo/collapses">Collapses</ndl-menu-item>
                    <ndl-menu-item link="/demo/expander">Expander</ndl-menu-item>
                    <ndl-menu-item link="/demo/forms">Forms</ndl-menu-item>
                    <ndl-menu-item link="/demo/rating">Rating</ndl-menu-item>
                    <ndl-menu-item link="/demo/snackbar">Snackbar</ndl-menu-item>
                    <ndl-menu-item link="/demo/status">Status</ndl-menu-item>
                    <ndl-menu-item link="/demo/steppers">Steppers</ndl-menu-item>
                    <ndl-menu-item link="/demo/tables">Table</ndl-menu-item>
                    <ndl-menu-item link="/demo/tag">Tag</ndl-menu-item>
                </ndl-menu>
            </ndl-card>
        </div>
        <div class="col-10">
            <ndl-card>
                <router-outlet></router-outlet>
            </ndl-card>
        </div>
    </div>
</div>
