import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../../../../services/auth.service';
import {TagTypeService} from "../../../../../../services/wabel-client/services/tag_type.service";
import {CacheService} from "../../../../../../services/cache.service";
import {UtilsService} from "../../../../../../services/utils.service";
import {SignupService} from "../../../../../../services/wabel-client/services/signup.service";
import {Tag} from "../../../../../../services/wabel-client/entities/tag";
import {NdlSelectOption} from "../../../../../../../../projects/needl-lib/src/selects/NdlSelectOption";

@Component({
    selector: 'registration-company-positionings',
    templateUrl: './registration-company-positionings.component.html',
    styleUrls: ['./registration-company-positionings.component.scss']
})
export class RegistrationCompanyPositioningsComponent implements OnInit, OnDestroy {

    categoryOptions = [];
    countryOptions = [];
    priceRangeOptions = [];

    categories = [];
    countries = [];
    priceRanges = [];
    plParts = 50;

    formSubmitted = false;
    formCompleted = false;
    errorCategories = false;
    errorCountries = false;
    errorPriceRanges = false;
    errorActivities = false;

    certificationsSubscription: Subscription;
    categoriesSubscription: Subscription;
    countriesSubscription: Subscription;
    priceRangesSubscription: Subscription;

    saving = false;
    scrollTo = null;

    @Output() stepFinished: EventEmitter<any> = new EventEmitter();

    constructor(public authService: AuthService,
                private cacheService: CacheService,
                private utilsService: UtilsService,
                private signupService: SignupService) {

    }

    ngOnInit() {
        this.categoriesSubscription = this.cacheService.getCategories().subscribe((data) => {
            if (!data) { return; }
            this.categoryOptions = data.tags.map(cat => ({value: cat.id, label: cat.name, children: cat.children.map(cat2 => ({value: cat2.id, label: cat2.name, children: []}))}));
            this.categoryOptions = this.utilsService.sortListByProperty(this.categoryOptions, 'label');
        });
        this.countriesSubscription = this.cacheService.getLocation().subscribe((data) => {
            if (!data) { return; }
            const regions: Tag[] = [];
            data.tags.forEach(continent => continent.children.forEach(region => regions.push(region)));
            this.countryOptions = regions.map(cont => ({value: cont.id, label: cont.name, children: cont.children.map(country =>
                    ({value: country.id, label: country.name, children: []}))}));
            this.countryOptions = this.utilsService.sortListByProperty(this.countryOptions, 'label');
        });
        this.priceRangesSubscription = this.cacheService.getPriceRanges().subscribe((data) => {
            if (!data) { return; }
            this.priceRangeOptions = data.tags.map(cat => ({value: cat.id, label: cat.name, children: []}));
            this.priceRangeOptions = this.utilsService.sortListByProperty(this.priceRangeOptions, 'label');
        });
    }

    ngOnDestroy() {
        if (this.certificationsSubscription) {
            this.certificationsSubscription.unsubscribe();
        }
        if (this.categoriesSubscription) {
            this.categoriesSubscription.unsubscribe();
        }
        if (this.countriesSubscription) {
            this.countriesSubscription.unsubscribe();
        }
    }

    checkFormErrors() {
        if (!this.formSubmitted) {
            return;
        }
        this.scrollTo = null;
        this.formCompleted = true;

        this.errorCategories = false;
        if (!this.categories.length) {
            this.errorCategories = true;
            this.scrollTo = 'categories';
            this.formCompleted = false;
        }

        this.errorPriceRanges = false;
        if (!this.priceRanges.length) {
            this.errorPriceRanges = true;
            this.scrollTo = this.scrollTo ? this.scrollTo : 'price-ranges';
            this.formCompleted = false;
        }

        this.errorCountries = false;
        if (!this.countries.length) {
            this.errorCountries = true;
            this.scrollTo = this.scrollTo ? this.scrollTo : 'countries';
            this.formCompleted = false;
        }
    }

    submitForm() {
        this.formSubmitted = true;
        this.checkFormErrors();
        if (this.scrollTo) {
            UtilsService.scrollToElement(document.querySelector('#' + this.scrollTo));
        }
        if (!this.formCompleted) {
            return;
        }
        this.saving = true;
        this.signupService.saveCompanyPositioning(
            this.plParts,
            this.categories.filter(cat => !cat.children.length).map(opt => +opt.value).filter(value => value !== null),
            this.countries.filter(country => !country.children.length).map(opt => +opt.value),
            this.priceRanges.map(opt => +opt.value)
        ).subscribe(data => {
            this.stepFinished.emit();
            this.authService.getMember().company.registrationStep = data.registrationStep;
            this.saving = false;
        }, err => {
            this.saving = false;
            console.error(err);
        });
    }
}
