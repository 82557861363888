import {Component, Input, OnInit} from '@angular/core';
import {Company} from "../../../../services/wabel-client/entities/company";
import {AuthService} from "../../../../services/auth.service";
import {UtilsService} from "../../../../services/utils.service";
import {Router} from "@angular/router";
import {ImageService} from "../../../../services/image.service";
import {CompanyProfileSubmission} from "../../../../services/wabel-client/entities/company_profile_submission";

@Component({
    selector: 'submission-preview-step',
    templateUrl: './submission-preview-step.component.html',
    styleUrls: ['./submission-preview-step.component.scss']
})
export class SubmissionPreviewStepComponent implements OnInit {
    @Input() submissionData: CompanyProfileSubmission;
    @Input() isInSubmissionForm = false;
    myCompany: Company;
    currentSelectedDiagnosisClientsCountry = '';
    currentSelectedDiagnosisClients: Company[] = [];

    constructor(
        private authservice: AuthService,
        public utilsService: UtilsService,
        public imageService: ImageService
    ) {
    }

    ngOnInit() {
        this.myCompany = this.authservice.getMember().company;
        if (this.submissionData && this.submissionData.companyProfileExportingCountries.length > 0) {
            this.renderCountryWithClients(this.submissionData.companyProfileExportingCountries[0].name);
        }
    }

    countryClientsCounter(country: string) {
        let total = 0;
        this.submissionData.companyProfileDiagnosisClients.forEach(x => {
            if (x.country && x.country.name === country) {
                total++;
            }
        });
        return total;
    }

    renderCountryWithClients(country: string) {
        this.currentSelectedDiagnosisClientsCountry = country;
        this.currentSelectedDiagnosisClients = this.submissionData.companyProfileDiagnosisClients.filter(x => x.country && x.country.name === country);
    }

    imgFailToLoad(event) {
        event.target.src = '/assetsFiles/flags/all.png';
    }
}
