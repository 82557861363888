import {Component} from '@angular/core';
import {NdlStatus} from '../../core/types';

@Component({
    selector: 'ndl-collapses-demo',
    templateUrl: './collapses-demo.component.html'
})
export class NdlCollapsesDemoComponent {
    content = "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda, deleniti dolore moles...";
    themes: NdlStatus[] = ['default', 'primary', 'secondary', 'info', 'success', 'warning', 'danger', 'neutral'];
}
