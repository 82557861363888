import gql from "graphql-tag";
import {TagTypeService} from "../services/tag_type.service";
import {TagTypeGraphql} from "./tag_type.graphql";
import {SourcingRequestService} from "../services/sourcing_request.service";

export class MessageGraphQL {
    static fragments = {
        message: gql`
            fragment message on PrivateMessage {
                idprivatemessage
                body
                createdAt
                member {
                    idmember
                    fullname
                    timezone
                    realLogoUrl: logoUrl(size: "123")
                    jobtitle
                    company {
                        idcompany
                        name
                        urlname
                        logoUrl(size: "123")
                        country {
                            tagLocation {
                                code
                            }
                        }
                    }
                }
                messageType
                videoMeetingInvitations {
                    items {
                        idvideomeetinginvitation
                        invitationStatus
                        invitationSubject
                        invitationMessage
                        createdBy {
                            idmember
                            fullname
                            timezone
                        }
                        createdFor {
                            idmember
                            fullname
                            timezone
                            company {
                                idcompany
                                name
                                company_type_machine_name
                                logoUrl(size: "123")
                                country {
                                    tagLocation {
                                        code
                                    }
                                }
                            }
                        }
                        videoMeetingInvitationSlots {
                            items {
                                idinvitationslot
                                timeFromViewable
                                timeToViewable
                                weekDayViewable
                                dateViewable
                                yearViewable
                                creatorTimezone
                                meetingDay
                            }
                        }
                    }
                }
                companyProfileSubmissions {
                    items {
                        idcompanyprofilesubmission
                        profileTitle
                        profileDescription
                        createdByCompany {
                            idcompany
                            country {
                                name
                            }
                        }
                        createdByMember {
                            idmember
                        }
                        companyProfileFeedbacks {
                            items {
                              idfeedback
                              member {
                                idmember
                              }
                              status
                              createdAt
                              updatedAt
                            }
                        }
                    }
                }
                company {
                    name
                    urlname
                    logoUrl(size: "123")
                }
                files {
                    idfile
                    url
                    fileName
                    fileSize
                    fileExtension
                }
                certainEvent {
                    eventCode
                    name
                    eventOrganizer {
                        urlname
                    }
                }
                sourcingRequestRecommendation {
                    interestedSourcingRequestMemberResponses {
                        items {
                            member {
                                firstname
                            }
                        }
                    }
                    sourcingRequest {
                        name
                        volume
                        productSpec
                        pricePositioning: tagsByInternalName(tagTypeInternalName: "${TagTypeService.PRICE_RANGE_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        }
                        certificationsAndLabels: tagsByInternalNames(tagTypeInternalNames: ["${TagTypeService.CERTIFICATION_INTERNAL_NAME}", "${TagTypeService.QUALITY_LABEL_INTERNAL_NAME}"]) {
                            items {
                                ...tag
                            }
                        }
                        activities: tagsByInternalName(tagTypeInternalName: "${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        }
                        productAttributes: tagsByInternalNames(tagTypeInternalNames: ["${TagTypeService.RANGE_OF_PRODUCT_INTERNAL_NAME}", "${TagTypeService.NUTRITIONAL_INFORMATION_INTERNAL_NAME}"]) {
                            items {
                                ...tag
                            }
                        }
                        categories: tagsByInternalName(tagTypeInternalName: "${TagTypeService.CATEGORY_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        }
                        volumeTags: tagsByInternalName(tagTypeInternalName: "${TagTypeService.VOLUME_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        }
                        packageWidth
                        packageHeight
                        packageLength
                        packagingValue
                        packagingUnit {
                            idpackagingunit
                            name
                        }
                        purchasingPriceRange
                        distributionCountries: countries(type: "${SourcingRequestService.BUYER_DISTRIBUTION_COUNTRY}") {
                            items {
                                id
                                name
                            }
                        }
                    }
                }
            }
            ${TagTypeGraphql.fragments.tag}`,
    };

    static queries = {
        myMessageThreads: gql`
            query Message($limit: Int, $offset: Int) {
                me {
                    privateMessageThreads {
                        count
                        items(limit: $limit, offset: $offset) {
                            idthread
                            unread
                            lastMessageDate
                            subject
                            readOnly
                            recipients {
                                member {
                                    idmember
                                    fullname
                                    jobtitle
                                    company {
                                        idcompany
                                        name
                                        logoUrl(size: "123")
                                        country {
                                            tagLocation {
                                                code
                                            }
                                            name
                                        }
                                        citylocation{
                                            city,
                                            country {
                                                name
                                            }
                                        }
                                        hasBuyerForCurrentMemberCategories
                                        activeAndAlreadyLoggedMembers{
                                            count
                                        }
                                    }
                                }
                                company {
                                    idcompany
                                    name
                                    logoUrl(size: "123")
                                    country {
                                        tagLocation {
                                            code
                                        }
                                    }
                                    activeAndAlreadyLoggedMembers{
                                        count
                                    }
                                    hasBuyerForCurrentMemberCategories
                                }
                            }
                            messages {
                                items(limit: 10){
                                    ...message
                                }
                            }
                            lastMessagePrivateReads {
                                member {
                                    idmember
                                    fullname
                                    logoUrl(size: "123")
                                }
                                privateMessage {
                                    idprivatemessage
                                }
                            }
                        }
                    }
                }
            }
            ${MessageGraphQL.fragments.message}
        `,

        threadById: gql`
            query threadById($threadId: Int!,$messageId: Int,$messagesLimit: Int!, $operatorType: String!){
              threadById(threadId: $threadId) {
                idthread
                unread
                lastMessageDate
                subject
                readOnly
                recipients {
                    member {
                        idmember
                        fullname
                        jobtitle
                        company {
                            idcompany
                            name
                            logoUrl(size: "123")
                            country {
                                tagLocation {
                                    code
                                }
                                name
                            }
                            citylocation{
                                city,
                                country {
                                    name
                                }
                            }
                        }
                    }
                    company {
                        idcompany
                        name
                        logoUrl(size: "123")
                        country {
                            tagLocation {
                                code
                            }
                        }
                        activeAndAlreadyLoggedMembers{
                            count
                        }
                    }
                }
                messages(messageId:$messageId,operatorType:$operatorType){
                  items(limit:$messagesLimit){
                    ...message
                  }
                }
                lastMessagePrivateReads {
                    member {
                        idmember
                        fullname
                        logoUrl(size: "123")
                    }
                    privateMessage {
                        idprivatemessage
                    }
                }
              }
            }
            ${MessageGraphQL.fragments.message}
        `,
        messagesByThreadAndMessageRange: gql`
            query privateMessagesByThreadAndMessageRange($threadId: Int!,$messageId: Int,$limit: Int!, $operatorType: String!){
              privateMessagesByThreadAndMessageRange(threadId: $threadId,messageId:$messageId,operatorType:$operatorType) {
                items(limit: $limit) {
                    ...message
                }
              }
            }
            ${MessageGraphQL.fragments.message}
        `
    };

    static mutations = {
        replyToPrivateMessageThread: gql`
            mutation replyToPrivateMessageThread($threadId: Int!, $message: String!, $messageFiles: [FileToUploadInput!], $eventCode: String) {
                replyToPrivateMessageThread(threadId: $threadId, message: $message, messageFiles: $messageFiles, eventCode: $eventCode) {
                    idprivatemessage
                    body
                    files {
                        idfile
                        url
                        fileName
                        fileSize
                        fileExtension
                    }
                }
            }
        `,

        sendVideoMeetingInvitationMessage: gql`
            mutation sendVideoMeetingInvitationMessage( $invitation_data: String!, $threadId: Int, $toCompanyId: Int, $toMemberId: Int ) {
                sendVideoMeetingInvitationMessage( invitation_data: $invitation_data, threadId: $threadId, toCompanyId: $toCompanyId, toMemberId: $toMemberId ) {
                    idprivatemessage
                }
            }
        `,

        markThreadAsRead: gql`
            mutation markThreadAsRead($threadId: Int!) {
                markThreadAsRead(threadId: $threadId) {
                    countUnreadMessages
                }
            }
        `,

        sendPrivateMessageToCompany: gql`
            mutation sendPrivateMessageToCompany($companyIds: [Int!]!, $subject: String!, $message: String!, $eventCode: String) {
                sendPrivateMessageToCompany(companyIds: $companyIds, subject: $subject, message: $message, eventCode: $eventCode) {
                    idthread
                }
            }
        `,

        askMoreInformationAboutProducts: gql`
            mutation askMoreInformationAboutProducts($productIds: [Int!]!, $content: String!) {
                askMoreInformationAboutProducts(productIds: $productIds, content: $content)
            }
        `,
    };
}
