import {Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

@Component({
    selector: 'kit-form-input',
    templateUrl: './kit-form-input.component.html',
    styleUrls: ['./kit-form-input.component.scss']
})
export class KitFormInputComponent implements OnChanges {

    @Input() id: string;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() helper: string;
    @Input() readOnly: boolean = false;

    @Input() binding: string;
    @Output() bindingChange = new EventEmitter();

    @Input() type: string = 'text';

    @Input() additionalInfo: string;
    @Input() width: string;
    @Input() firstPassword: string;

    @Input() informationPlacement: string = 'top';

    //validation related
    @Input() isRequired: boolean = false;
    @Input() checkErrorOnLoading: boolean = false;
    @Input() showError: boolean = false;
    @Input() showSuccess: boolean = false;
    @Input() doCheck: boolean = false;
    @Input() errorMessage: string = 'This field is required';
    @Input() isPasswordCopy: boolean = false;
    @Input() arePasswordDiff: boolean = false;
    @Output() validatorChange = new EventEmitter();
    isValidate: boolean = false;
    @Input() minValue: number = 0;
    @Input() maxValue;

    @Input() doCheckPassword: boolean = true;
    @Input() preventAllCharactersExceptNumbers: boolean = false;
    @Input() autocompleteStatus: boolean = false;
    @Input() isYearEstablished: boolean = false;
    @Input() isPrivateLabel: boolean = false;
    @Input() className: string;

    @Input() prefix: string;
    @Input() cyName: string;

    @HostListener('mousewheel', ['$event']) onMousewheel(event) {
        if (this.type === 'number') {
            event.preventDefault();
        }
    }

    change(newValue) {
        this.binding = newValue;
        this.bindingChange.emit(newValue);
    }

    constructor() {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.doCheck && changes.doCheck.currentValue)
            this.checkError();
    }

    checkError() {
        this.showError = this.isRequired && (!this.binding || (this.binding && this.binding.toString().length == 0) || this.binding.toString() == '');
        if (this.isRequired && this.binding && this.binding.toString().length > 0) {
            this.doCheck = false;
        }
        this.checkInFunctionOfType();
    }

    checkInFunctionOfType() {
        switch (this.type) {
            case 'password':
                if (this.doCheckPassword) {
                    this.checkPassword();
                }
                break;
            case 'email':
                this.checkEmail();
                break;
            case 'url':
                this.checkUrl();
                break;
            case 'number':
                if (this.isYearEstablished) {
                    this.checkYearEstablished();
                } else if (this.isPrivateLabel) {
                    this.checkPrivateLabel();
                }
                break;
            default:
                break;
        }
    }

    checkPassword() {
        if (!this.isPasswordCopy) {
            if (this.binding) {
                if (this.binding.length < 8) {
                    this.showError = true;
                    this.showSuccess = false;
                    this.errorMessage = 'Password too short';
                    this.isValidate = false;
                } else {
                    this.showSuccess = true;
                    this.showError = false;
                    this.errorMessage = 'Difficulty is good';
                    this.isValidate = true;
                }
            } else {
                this.showError = true;
                this.showSuccess = false;
            }
        } else if (this.firstPassword) {
            if (this.binding === this.firstPassword) {
                this.showSuccess = true;
                this.showError = false;
                this.errorMessage = '';
                this.isValidate = true;
            } else {
                this.showSuccess = false;
                this.showError = true;
                this.errorMessage = 'Passwords aren\'t the same';
                this.isValidate = false;
            }
        }
        this.validatorChange.emit(this.isValidate);
    }

    checkEmail() {
        this.isValidate = true;
        let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

        if (!regexp.test(this.binding)) {
            this.isValidate = false;
            this.doCheck = true;
            this.errorMessage = "Email is not valid";
            this.showError = true;
        }
        this.validatorChange.emit(this.isValidate);
    }

    checkUrl() {
        this.isValidate = true;
        let regexp = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,7}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
        if (!this.binding){
            this.isValidate = false;
            this.doCheck = true;
            this.errorMessage = 'This field is required';
            this.showError = true;
        }else if (!regexp.test(this.binding.toLowerCase())) {
            this.isValidate = false;
            this.doCheck = true;
            this.errorMessage = "URL is not valid";
            this.showError = true;
        }
        this.validatorChange.emit(this.isValidate);
    }

    onKeydown(event) {
        if (this.preventAllCharactersExceptNumbers) {
            let preventedCharacters = ['e', '-', '.', 'E'];
            if (preventedCharacters.indexOf(event.key) != -1 || ((!((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105))) && event.which != 8)) {
                event.preventDefault();
            }
        }
    }

    checkYearEstablished() {
        this.isValidate = true;
        let valueOfYear: any = this.binding;
        if (this.binding) {
            if (this.binding.toString().length != 4 || (valueOfYear < 1000 || valueOfYear > new Date().getFullYear())) {
                this.isValidate = false;
                this.doCheck = true;
                this.errorMessage = "Year is not valid";
                this.showError = true;
            }
        }
        this.validatorChange.emit(this.isValidate);
    }

    checkPrivateLabel() {
        this.isValidate = true;
        let valueOfPrivateLabel: any = this.binding;
        if (this.binding) {
            if (this.binding.toString().length > 3 || valueOfPrivateLabel > 100) {
                this.isValidate = false;
                this.doCheck = true;
                this.errorMessage = "Private Label is not valid";
                this.showError = true;
            }
        }
        this.validatorChange.emit(this.isValidate);
    }
}
