import {WabelEntity} from "./wabel-entity.abstract";
import {AlgoliaFilterOption} from "./algolia_filter_option";

export class AlgoliaFilter extends WabelEntity {
    name: string;
    machineName: string;
    internalName: string;
    options: AlgoliaFilterOption[];
    filterType: string;
    form: string;


    constructor(obj : any) {
        super(obj);
        if (obj) {
            this.options = obj.options ? obj.options.map(option => new AlgoliaFilterOption(option)) : null;
        }
    }


    get id(): number {
        return undefined;
    }

    set id(id: number) {

    }
}
