import {Component} from '@angular/core';
import {NdlStatus} from '../../core/types';
import {NdlStatusColors} from '../../tooltips/status/status.component';
import {NdlSnackbarService} from '../../snackbar/snackbar.service';

@Component({
    selector: 'ndl-snackbar-demo',
    templateUrl: './snackbar-demo.component.html'
})
export class NdlSnackbarDemoComponent {
    constructor(public snackbarService: NdlSnackbarService) {
    }
}
