import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';
import {environment} from '../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {FileToUpload} from './wabel-client/entities/fileToUpload';
import * as dayjs from "dayjs";
import * as relativeTime from 'dayjs/plugin/relativeTime';
import {VideoMeetingInvitationSlots} from "./wabel-client/entities/video_meeting_invitation_slots";
import * as timezone from 'dayjs/plugin/timezone';
import {Dayjs} from "dayjs";
import {ZoomCallDockService} from "./zoom-call-docked.service";
import {daysArrTypeReturned} from './wabel-client/custom_types';
import {Tag} from "./wabel-client/entities/tag";

dayjs.extend(timezone);

@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    memoryLimit: number = null;
    memoryLimitSubscription: any = null;
    acceptedFormatsFile = ['PDF', 'DOC', 'XLS', 'PPT', 'DOCX', 'XLSX', 'PPTX'];
    acceptedFormatsImage = ['PNG', 'JPG', 'JPEG', 'GIF'];

    constructor(
        private http: HttpClient,
        private domSanitizer: DomSanitizer,
        private route: ActivatedRoute,
        private zoomCallDockService: ZoomCallDockService
    ) {
    }


    public static getMachineName(value: string) {
        return value.replace(/[^A-Za-z0-9 ]/g, '_')
            .replace(/\s{2,}/g, ' ')
            .replace(/\s/g, '_')
            .toLowerCase();
    }

    formatAddress(place) {
        const componentFormAddress = {
            place_id: 'place_id',
            locality: 'city_name',
            postal_town: 'city_name',
            sublocality: 'city_name',
            sublocality_level_1: 'city_name',
            administrative_area_level_2: 'city_name',
            administrative_area_level_3: 'city_name',
            administrative_area_level_1: 'region_name',
            country: 'country_name',
            postal_code: 'postal_code',
            formatted_address: 'formatted_address'
        };

        const address: any = {};

        if (place && place.address_components) {
            for (let i = 0; i < place.address_components.length; i++) {
                for (let j = 0; j < place.address_components[i].types.length; j++) {
                    const addressType = place.address_components[i].types[j];
                    if (componentFormAddress[addressType]) {
                        if (!address[componentFormAddress[addressType]]) {
                            address[componentFormAddress[addressType]] = place.address_components[i]['long_name'];
                        }
                    }
                }
            }
            if (place.formatted_address) {
                address['formatted_address'] = place.formatted_address;
            }
            if (place.place_id) {
                address['place_id'] = place.place_id;
            }
            if (place.geometry && place.geometry.location) {
                address['lat'] = place.geometry.location.lat();
                address['lng'] = place.geometry.location.lng();
            }

            if (!address.country_name) { // Some places has no country (eg: West Bank) so we take the first result for the country name.
                address.country_name = place.address_components[0]['long_name'];
            }

            return address;
        }
    }

    /**
     *
     * @param {string} file
     * @returns {Observable<Object>}
     */
    uploadFileBase64(file: string) {
        return this.http.post(environment.needl_back_end_url + '/resource/upload-file', {fileBase64Encoded: file}, {
            headers: {
                'ngsw-bypass': 'true'
            }
        });
    }

    static scrollToElement(selector: Element, offset: number = -100, timeout: number = 0, parentSelector: string = '', smooth: boolean = false, scrollOnlySelector: boolean = false) {
        setTimeout(() => {
            try {
                // scroll the selector without scrolling the parent
                if (scrollOnlySelector) {
                    selector.scrollIntoView({block: "nearest", inline: "nearest"});
                } else {
                    if (!smooth) {
                        selector.scrollIntoView();
                    } else {
                        selector.scrollIntoView({behavior: 'smooth'});
                    }
                }
            } catch (e) {
                console.warn(e);
            }
        }, timeout);
    }

    checkIfEmailAlreadyExists(email: string) {
        return this.http.post(environment.needl_back_end_url + '/pre-register/check-email', {email: email}, {
            headers: {
                'ngsw-bypass': 'true'
            }
        });
    }

    parseURL(url) {
        let parser = document.createElement('a'),
            searchObject = {},
            queries, split, i;
        // Let the browser do the work
        parser.href = url;
        // Convert query string to object
        queries = parser.search.replace(/^\?/, '').split('&');
        for (i = 0; i < queries.length; i++) {
            split = queries[i].split('=');
            searchObject[split[0]] = split[1];
        }
        return {
            protocol: parser.protocol,
            host: parser.host,
            hostname: parser.hostname,
            port: parser.port,
            pathname: parser.pathname,
            search: parser.search,
            searchObject: searchObject,
            hash: parser.hash
        };
    }

    getPathnameFromUrl(url: string) {
        return this.parseURL(url).pathname;
    }

    bytesToSize(bytes: number, decimals: number = 1) {
        if (bytes == 0) {
            return '0 B';
        }
        const k = 1000,
            dm = decimals <= 0 ? 0 : decimals || 1,
            sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        if (i === 0) {
            return '0 MB';
        }
        // if (i === 1) {
        //     return ((parseFloat((bytes / Math.pow(k, i)).toFixed(dm))) / 1024).toFixed(dm) + ' MB';
        // }
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    getSafeHtml(html: string) {
        return this.domSanitizer.bypassSecurityTrustHtml(html);
    }

    getSafeStyle(style: string) {
        return this.domSanitizer.bypassSecurityTrustStyle(style);
    }

    loadMemoryLimit() {
        if (!this.memoryLimitSubscription && !this.memoryLimit) {
            this.memoryLimitSubscription = this.http.get(environment.needl_back_end_url + '/resource/utils/memory-limit', {
                headers: {
                    'ngsw-bypass': 'true'
                }
            }).subscribe(
                (data) => {
                    this.memoryLimit = +data.toString();
                }, () => {
                    this.memoryLimit = 536870912;
                }
            );
        }
    }

    getMemoryLimit() {
        if (!this.memoryLimitSubscription && !this.memoryLimit) {
            this.loadMemoryLimit();
            this.memoryLimit = 536870912;
        }
        return this.memoryLimit;
    }

    resizeImageBase64(img: any, maxWidth: number = 500, maxHeight: number = 500) {
        // Create and initialize two canvas
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const canvasCopy = document.createElement('canvas');
        const copyContext = canvasCopy.getContext('2d');

        // Determine new ratio based on max size
        let ratio = 1;
        if (img.width > maxWidth) {
            ratio = maxWidth / img.width;
        } else if (img.height > maxHeight) {
            ratio = maxHeight / img.height;
        }

        // Draw original image in second canvas
        canvasCopy.width = img.width;
        canvasCopy.height = img.height;
        copyContext.drawImage(img, 0, 0);

        // Copy and resize second canvas to first canvas
        canvas.width = img.width * ratio;
        canvas.height = img.height * ratio;
        ctx.drawImage(canvasCopy, 0, 0, canvasCopy.width, canvasCopy.height, 0, 0, canvas.width, canvas.height);

        return canvas.toDataURL();
    }

    decodeHTMLEntities(str) {
        const txt = document.createElement('textarea');
        txt.innerHTML = str;
        return txt.value;
    }

    scrollToTop(onPageLoading: boolean = false) {
        if (onPageLoading) {
            if (!this.route.snapshot.data['disableScrollToTop']) {
                window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
            }
        } else {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }
    }

    addAVisitHistory(entity_type: string, id: number) {
        this.http.post(environment.needl_back_end_url + '/resource/add-visit-history', {
            entity_type: entity_type,
            id: id
        }, {
            withCredentials: true,
            headers: {
                'ngsw-bypass': 'true'
            }
        }).subscribe(
            (success) => {

            }, (error) => {
                console.error(error);
            }
        );
    }

    filesWithError(files: FileToUpload[]): FileToUpload[] {
        return files.filter((file: FileToUpload) => file.isUploaded() && !file.success);
    }

    filesWithErrorMessage(files: FileToUpload[]): string {
        return this.filesWithError(files).map((file: FileToUpload) => file.previewFileName()).join('<br/>');
    }

    deepCopy(obj) {
        let copy;

        // Handle the 3 simple types, and null or undefined
        if (null == obj || 'object' !== typeof obj) {
            return obj;
        }

        // Handle Date
        if (obj instanceof Date) {
            copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }

        // Handle Array
        if (obj instanceof Array) {
            copy = [];
            for (let i = 0, len = obj.length; i < len; i++) {
                copy[i] = this.deepCopy(obj[i]);
            }
            return copy;
        }

        // Handle Object
        if (obj instanceof Object) {
            copy = {};
            for (const attr in obj) {
                if (obj.hasOwnProperty(attr)) {
                    copy[attr] = this.deepCopy(obj[attr]);
                }
            }
            return copy;
        }

        throw new Error('Unable to copy obj! Its type isn\'t supported.');
    }

    strEndsWith(str: string, search: string, this_len: number = null): boolean {
        if (this_len === null || this_len === undefined || this_len > str.length) {
            this_len = str.length;
        }
        return str.substring(this_len - search.length, this_len) === search;
    }

    objToQueryParams(obj, prefix: string = null): string {
        let str = [],
            p;
        for (p in obj) {
            if (obj.hasOwnProperty(p)) {
                const k = prefix ? prefix + "[" + p + "]" : p,
                    v = obj[p];
                str.push((v !== null && typeof v === "object") ?
                    this.objToQueryParams(v, k) :
                    encodeURIComponent(k) + "=" + encodeURIComponent(v));
            }
        }
        return str.join("&");
    }

    arrayEquals(array1: any[], array2: any[]) {
        if (array1.length !== array2.length) {
            return false;
        }
        for (let i = 0; i < array1.length; i++) {
            if (array1[i] !== array2[i]) {
                return false;
            }
        }
        return true;
    }

    generateCountryFlag(country: string) {
        return country && this.domSanitizer.bypassSecurityTrustUrl(`/assetsFiles/flags/${country.toLowerCase()}.png`);
    }

    splitTimezoneCountry(timezone: string) {
        if (timezone) {
            const country = timezone.split("/")[1];
            return `${country} timezone`;
        }

    }

    getBrowserBasedLocalTimezone(format: boolean = false) {
        return format ? this.splitTimezoneCountry(dayjs.tz.guess()) : dayjs.tz.guess();
    }

    sortListByProperty(arr, propertyName) {
        return arr.sort((n1, n2) => {
            if (n1[propertyName] > n2[propertyName]) {
                return 1;
            }
            if (n1[propertyName] < n2[propertyName]) {
                return -1;
            }
            return 0;
        });
    }


    sortListByNameProperty(arr) {
        return arr.sort((n1, n2) => {
            if (n1.name > n2.name) {
                return 1;
            }
            if (n1.name < n2.name) {
                return -1;
            }
            return 0;
        });
    }

    isOnNotSupportedBrowsers(): boolean {
        return navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1 || navigator.userAgent.indexOf("Edge/") > -1;
    }

    cloneObject<T>(object: any): T {
        return Object.create(object) as T;
    }

    // get days, hours , mins by tow dates in human readable way, example :
    // Sun Jan 24 2021 08:31:51 to Sun Jan 31 2021 14:00:00
    // days = 7 , hours = 5 , min = 28
    getHumanReadableDifferenceByTwoDates(fromTimeDate: Dayjs, toTimeDate: Dayjs, timeZone: string) {

        const days = toTimeDate.diff(fromTimeDate, 'day');
        fromTimeDate = fromTimeDate.add(days, 'day');
        const hours = toTimeDate.diff(fromTimeDate, 'hour');
        fromTimeDate = fromTimeDate.add(hours, 'hour');
        const mins = toTimeDate.diff(fromTimeDate, 'minute');
        return {
            "mins": mins,
            "hours": hours,
            "days": days
        };

        // const toTimeDate = dayjs(toDate, "D MMMM YYYY hh:mm A").tz(timeZone);
        // let fromTimeDate = dayjs(fromTime, "D MMMM YYYY hh:mm A").tz(timeZone);
        // const days = toTimeDate.diff(fromTimeDate, 'day');
        // fromTimeDate = fromTimeDate.add(days, 'day');
        // const hours = toTimeDate.diff(fromTimeDate, 'hour');
        // fromTimeDate = fromTimeDate.add(hours, 'hour');
        // const mins = toTimeDate.diff(fromTimeDate, 'minute');
        // return {
        //     "mins": mins,
        //     "hours": hours,
        //     "days": days
        // };

    }

    isVideoMeetingDocked() {
        let isDocked = true;
        this.zoomCallDockService.getDock().subscribe(dock => {
            isDocked = dock ? false : true;
        });
        return isDocked;
    }

    isVideoMeetingJoinableByTime(slot: VideoMeetingInvitationSlots) {
        const tz = slot.creatorTimezone;
        const startFromTime: dayjs.Dayjs = dayjs(`${slot.dateViewable} ${slot.yearViewable} ${slot.timeFromViewable}`, "D MMMM YYYY hh:mm A").tz(tz);

        if (startFromTime.isToday()) {
            const currentTime = dayjs().tz(tz);

            let sTime: dayjs.Dayjs = dayjs(slot.timeFromViewable, "hh:mm A");
            const eTime: dayjs.Dayjs = dayjs(slot.timeToViewable, "hh:mm A");

            // remove 5 minutes from time
            sTime = sTime.subtract(5, 'm');

            const start = new Date();
            start.setDate(startFromTime.date());
            start.setMonth(startFromTime.month());
            start.setHours(sTime.hour(), sTime.minute(), 0);

            const end = new Date();
            end.setDate(startFromTime.date());
            end.setMonth(startFromTime.month());
            end.setHours(eTime.hour(), eTime.minute(), 0);

            const date = new Date();
            date.setHours(currentTime.hour(), currentTime.minute());

            if (start <= date && date < end) {
                return true;
            }
            return false;
        }
        return false;
    }

    getVideoMeetingSlotData(): daysArrTypeReturned[] {

        const myTimeZone = dayjs.tz.guess();

        const da = [
            {timeFrom: "00:00 AM", timeTo: "01:00 AM"},
            {timeFrom: "01:00 AM", timeTo: "02:00 AM"},
            {timeFrom: "02:00 AM", timeTo: "03:00 AM"},
            {timeFrom: "03:00 AM", timeTo: "04:00 AM"},
            {timeFrom: "04:00 AM", timeTo: "05:00 AM"},
            {timeFrom: "05:00 AM", timeTo: "06:00 AM"},
            {timeFrom: "06:00 AM", timeTo: "07:00 AM"},
            {timeFrom: "07:00 AM", timeTo: "08:00 AM"},
            {timeFrom: "08:00 AM", timeTo: "09:00 AM"},
            {timeFrom: "09:00 AM", timeTo: "10:00 AM"},
            {timeFrom: "10:00 AM", timeTo: "11:00 AM"},
            {timeFrom: "11:00 AM", timeTo: "12:00 PM"},
            {timeFrom: "12:00 PM", timeTo: "01:00 PM"},
            {timeFrom: "01:00 PM", timeTo: "02:00 PM"},
            {timeFrom: "02:00 PM", timeTo: "03:00 PM"},
            {timeFrom: "03:00 PM", timeTo: "04:00 PM"},
            {timeFrom: "04:00 PM", timeTo: "05:00 PM"},
            {timeFrom: "05:00 PM", timeTo: "06:00 PM"},
            {timeFrom: "06:00 PM", timeTo: "07:00 PM"},
            {timeFrom: "07:00 PM", timeTo: "08:00 PM"},
            {timeFrom: "08:00 PM", timeTo: "09:00 PM"},
            {timeFrom: "09:00 PM", timeTo: "10:00 PM"},
            {timeFrom: "10:00 PM", timeTo: "11:00 PM"},
            {timeFrom: "11:00 PM", timeTo: "12:00 AM"}
        ];

        const tsRemaingDays: daysArrTypeReturned[] = [
            {d: "Monday", v: da, timezone: myTimeZone},
            {d: "Tuesday", v: da, timezone: myTimeZone},
            {d: "Wednesday", v: da, timezone: myTimeZone},
            {d: "Thursday", v: da, timezone: myTimeZone},
            {d: "Friday", v: da, timezone: myTimeZone},
            {d: "Saturday", v: da, timezone: myTimeZone},
            {d: "Sunday", v: da, timezone: myTimeZone}
        ];

        return tsRemaingDays;
    }

    getBeautifiedString(string: string) {
        return string.replace('_', ' ').replace(/^(.)|\s+(.)/g, function ($1) {
            return $1.toUpperCase();
        });
    }

    // return left time by a date
    // for example '2 days a go' , '2 years a go'
    countLeftTimeByDate(createdAt: string) {
        dayjs.extend(relativeTime);
        return dayjs(createdAt).fromNow();
    }


    renderCompanyImage(url: string) {
        if (url.includes("404.jpeg")) {
            return "/assetsFiles/no-product-picture.png";
        }
        return url;
    }

    getConvertedDateTimeBasedOnZoneViaInvitationSlot(videoMeetingSlot: VideoMeetingInvitationSlots = null, format: string = "") {
        if (videoMeetingSlot) {
            const slot = videoMeetingSlot;
            let time: dayjs.Dayjs = null;
            const _fr = `${slot.dateViewable} ${slot.yearViewable} ${slot.timeFromViewable}`;
            time = dayjs(_fr, "D MMMM YYYY hh:mm A").tz(slot.creatorTimezone, true);
            const _native = new Date(time.toDate());
            const _date_options: any = {dateStyle: 'full'};
            const _date_time_options: any = {dateStyle: 'full', timeStyle: 'short'};
            if (format === "time") {
                return new Intl.DateTimeFormat('en', {hour: '2-digit', minute: '2-digit'}).format(_native);
            }
            if (format === "date") {
                return new Intl.DateTimeFormat('en', _date_options).format(_native);
            }
            return new Intl.DateTimeFormat('en', _date_time_options).format(_native);
        }
        return 'NA';
    }

    getCurrentBrowser(): string {
        const userAgent = navigator.userAgent;
        if ((userAgent.indexOf('Opera') || userAgent.indexOf('OPR')) !== -1) {
            return 'Opera';
        } else if (userAgent.indexOf('Chrome') !== -1) {
            return 'Chrome';
        } else if (userAgent.indexOf('Safari') !== -1) {
            return 'Safari';
        } else if (userAgent.indexOf('Firefox') !== -1) {
            return 'Firefox';
        } else if (userAgent.indexOf('MSIE') !== -1) {
            return 'IE';
        } else {
            return 'Unknown';
        }
    }

    getIsMobile(): boolean {
        return /Mobi/i.test(window.navigator.userAgent);
    }

    isFile(extension: string): boolean {
        return this.acceptedFormatsFile.map((value) => value.toLowerCase()).includes(extension.toLowerCase());
    }

    detectErrorMessages(error: any): string[] {
        let errorList = [];
        if (error && error.networkError && error.networkError.result && error.networkError.result.errors) {
            errorList = error.networkError['result'].errors.map((m) => m.message);
        } else if (error.message) {
            errorList = [error.message];
        } else {
            errorList = ['Unexpected error, please contact Needl Team'];
        }
        console.error(error);
        console.error(errorList);
        return errorList;
    }

    transformTagIntoNdlSelectOption(tag: Tag, withChildren = true) {
        const ndlSelectOption = {value: tag.id, label: tag.name, children: []};
        if (withChildren && tag.children && tag.children.length) {
            const children = [];
            for (let childrenTag of tag.children) {
                children.push(this.transformTagIntoNdlSelectOption(childrenTag));
            }
            ndlSelectOption.children = children;
        }
        return ndlSelectOption;
    }

    //to use the plugin ngx-paginator according to our need, we need to trick a little bit and create an empty div with a special ngFor
    utilsPaginationGenerator(total: number) {
        const collection = [];
        for (let i = 1; i <= total; i++) {
            collection.push(``);
        }

        return collection;
    }

    //to use the plugin ngx-paginator according to our need, we need to trick a little bit and create an empty div with a special ngFor
    static removeTypeNameFromObject(object: any) {
        if (object?.__typename) {
            delete object.__typename;
        }

        return object;
    }

    changeColor(color: string, amount: number) { // #FFF not supported rather use #FFFFFF
        const clamp = (val) => Math.min(Math.max(val, 0), 0xFF);
        const fill = (str) => ('00' + str).slice(-2);

        const num = parseInt(color.substr(1), 16);
        const red = clamp((num >> 16) + amount);
        const green = clamp(((num >> 8) & 0x00FF) + amount);
        const blue = clamp((num & 0x0000FF) + amount);
        return '#' + fill(red.toString(16)) + fill(green.toString(16)) + fill(blue.toString(16));
    }

    getReadableDuration(durationInMinutes: number): string {
        let hours = Math.floor(durationInMinutes / 60);
        let minutes = durationInMinutes % 60;

        if (hours > 0 && minutes > 0) {
            return hours + ' hour' + (hours > 1 ? 's' : '') + ' and ' + minutes + ' minute' + (minutes > 1 ? 's' : '');
        } else if (hours) {
            return hours + ' hour' + (hours > 1 ? 's' : '');
        }

        return durationInMinutes + ' minutes';
    }


    dedupTags(tag: Tag[]) {
        return tag.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.id === value.id
            ))
        );
    }

    detectGraphqlErrorCategory(error: any) {
        return error.networkError?.result?.errors[0]?.extensions?.category;
    }
}
