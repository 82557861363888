<div *ngIf="searchable" class="ndl-checkboxes-search">
    <mat-form-field>
        <mat-icon matPrefix>search</mat-icon>
        <input matInput [placeholder]="placeholder" [(ngModel)]="search" (change)="$event.stopPropagation()"/>
        <button matSuffix mat-icon-button type="button" *ngIf="search && !disabled" (click)="search = null">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
</div>
<div class="ndl-checkboxes-field-content ndl-scrollbar" [style.max-height]="maxHeight" [style.height]="height" [class.display-mode]="displayMode"
     [style.overflow]="(maxHeight || height) ? 'auto' : 'initial'">
    <ng-container *ngFor="let option of options">
        <ng-container *ngTemplateOutlet="optionTemplate; context:{$implicit: option}"></ng-container>
    </ng-container>
    <ng-container *ngFor="let group of groups">
        <ng-container *ngTemplateOutlet="groupTemplate; context:{$implicit: group}"></ng-container>
    </ng-container>
</div>

<!-- Templates -->
<ng-template #optionTemplate let-option>
    <mat-checkbox [class.ndl-checkbox-hide]="hideOption(option)" [class.ndl-checkbox-error]="errorState" [checked]="isChecked(option.value)"
                  (change)="changeValue(option.value)" color="primary" (focusin)="onFocusIn()" [disabled]="displayMode || disabled"
                  (focusout)="onFocusOut()" *ngIf="!displayMode || (displayMode && isChecked(option.value))">
        <div [innerHTML]="option.label"></div>
    </mat-checkbox>
</ng-template>

<ng-template #groupTemplate let-group>
    <div class="ndl-checkboxes-group" [class.ndl-checkboxes-group-opened]="isGroupOpened(group)"
         [class.ndl-checkbox-hide]="hideGroup(group)" *ngIf="!displayMode || (displayMode && countGroupChildrenCheck(group))">
        <div class="ndl-checkboxes-group-label">
            <mat-checkbox color="primary" [checked]="isAllChecked(group)" [indeterminate]="isSomeChecked(group)"
                          (change)="toggleGroupCheck($event.checked, group)" (focusin)="onFocusIn()"
                          (focusout)="onFocusOut()" [disabled]="displayMode || disabled">
            </mat-checkbox>
            <span [style.cursor]="'pointer'" (click)="toggleGroup(group)">
                {{group.label}}
            </span>
            <span *ngIf="countGroupChildrenCheck(group)" class="ndl-checkboxes-group-count ndl-small">
                    {{countGroupChildrenCheck(group)}}
            </span>
            <button type="button" mat-icon-button (click)="toggleGroup(group)">
                <mat-icon color="primary">arrow_drop_down</mat-icon>
            </button>
        </div>

        <ul>
            <li *ngFor="let suboption of group.options" [class.ndl-checkbox-hide]="hideOption(suboption)">
                <ng-container *ngTemplateOutlet="optionTemplate; context:{$implicit: suboption}"></ng-container>
            </li>
            <li *ngFor="let subgroup of group.groups" [class.ndl-checkbox-hide]="hideGroup(subgroup)">
                <ng-container *ngTemplateOutlet="groupTemplate; context:{$implicit: subgroup}"></ng-container>
            </li>
        </ul>
    </div>
</ng-template>
