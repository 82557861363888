import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NdlInputPhoneComponent } from './components/input-phone/input-phone.component';
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [NdlInputPhoneComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [NdlInputPhoneComponent],
})
export class NeedlInputPhoneModule { }
