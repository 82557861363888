import { Injectable } from '@angular/core';
import {BreakpointObserver} from '@angular/cdk/layout';
import {startWith, map} from 'rxjs/operators';
import {NdlBreakpoints} from './breakpoints';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NdlLayoutService {
    XSmall$: Observable<boolean>;
    Small$: Observable<boolean>;
    Medium$: Observable<boolean>;
    Large$: Observable<boolean>;
    XLarge$: Observable<boolean>;
    Handset$: Observable<boolean>;
    Tablet$: Observable<boolean>;
    Web$: Observable<boolean>;
    HandsetPortrait$: Observable<boolean>;
    TabletPortrait$: Observable<boolean>;
    WebPortrait$: Observable<boolean>;
    HandsetLandscape$: Observable<boolean>;
    TabletLandscape$: Observable<boolean>;
    WebLandscape$: Observable<boolean>;

    constructor(private _breakpointObserver: BreakpointObserver) {
        this.XSmall$ = this._createObservable(NdlBreakpoints.XSmall);
        this.Small$ = this._createObservable(NdlBreakpoints.Small);
        this.Medium$ = this._createObservable(NdlBreakpoints.Medium);
        this.Large$ = this._createObservable(NdlBreakpoints.Large);
        this.XLarge$ = this._createObservable(NdlBreakpoints.XLarge);
        this.Handset$ = this._createObservable(NdlBreakpoints.Handset);
        this.Tablet$ = this._createObservable(NdlBreakpoints.Tablet);
        this.Web$ = this._createObservable(NdlBreakpoints.Web);
        this.HandsetPortrait$ = this._createObservable(NdlBreakpoints.HandsetPortrait);
        this.TabletPortrait$ = this._createObservable(NdlBreakpoints.TabletPortrait);
        this.WebPortrait$ = this._createObservable(NdlBreakpoints.WebPortrait);
        this.HandsetLandscape$ = this._createObservable(NdlBreakpoints.HandsetLandscape);
        this.TabletLandscape$ = this._createObservable(NdlBreakpoints.TabletLandscape);
        this.WebLandscape$ = this._createObservable(NdlBreakpoints.WebLandscape);
    }

    private _createObservable(breakpoint: string): Observable<boolean> {
        return this._breakpointObserver.observe(breakpoint)
            .pipe(
                map((result) => result.matches),
                startWith(this._breakpointObserver.isMatched(breakpoint))
            );
    }
}
