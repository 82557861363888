import { Component, OnInit, Input } from "@angular/core";
import {
  trigger,
  state,
  style,
  transition,
  animate
} from "@angular/animations";

@Component({
  selector: "tooltip",
  animations: [
    trigger("openClose", [
      state(
        "open",
        style({
          opacity: 1,
          display: 'block'
        })
      ),
      state(
        "closed",
        style({
          opacity: 0,
          display: 'none'
        })
      ),
      transition("open => closed", [animate("200ms")]),
      transition("closed => open", [animate("200ms 250ms")])
    ])
  ],
  templateUrl: "./tooltip.component.html",
  styleUrls: ["./tooltip.component.scss"]
})
export class TooltipComponent implements OnInit {
  @Input() isOpened = false;
  @Input() text: string;

  constructor() {}

  ngOnInit() {}
}
