import {Component, forwardRef, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {CdkStep} from "@angular/cdk/stepper";

@Component({
    selector: 'ndl-step-new',
    templateUrl: './step.component.html',
    providers: [
        {provide: CdkStep, useExisting: NdlStepNewComponent}
    ]
})
export class NdlStepNewComponent extends CdkStep {
    @Input() id: string;
}
