import {WabelEntity} from "./wabel-entity.abstract";
import {SourcingNeedRecommendation} from "./sourcing_need_recommendation";
import {Member} from "./member";
import {PrivateMessage} from "./private_message";
import {CompanyProfileSubmission} from "./company_profile_submission";

export class SourcingNeedRecommendationMemberResponse extends WabelEntity {
    constructor(obj: any) {
        super(obj);

        if (obj) {
            this.sourcingNeedRecommendation = obj.sourcingNeedRecommendation ? new SourcingNeedRecommendation(obj.sourcingNeedRecommendation) : null;
            this.member = obj.member ? new Member(obj.member) : null;
            this.privateMessage = obj.privateMessage ? new PrivateMessage(obj.privateMessage) : null;
            this.profileSubmission = obj.profileSubmission ? new CompanyProfileSubmission(obj.profileSubmission) : null;
        }
    }

    sourcingNeedRecommendation: SourcingNeedRecommendation;
    member: Member;
    status: string;
    reason: string;
    privateMessage: PrivateMessage;
    buyerApplicationStatus: string;
    profileSubmission: CompanyProfileSubmission;

    get id() {
        return this.sourcingNeedRecommendation.id;
    }
}
