import {Component, EventEmitter, OnInit, Output, ViewChild, OnDestroy} from '@angular/core';
import {SearchService} from '../../../services/search.service';
import {UrlGeneratorService} from '../../../services/urlgenerator.service';
import {AuthService} from "../../../services/auth.service";
import {Router} from "@angular/router";
import {TemplateService} from "../../../services/template.service";
import {NeedlSecurityService} from "@wabel/needl-security";
import {RIGHT} from "../../../services/wabel-client/security/right.const";

@Component({
    selector: 'app-search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {

    searchTerm: string = '';
    @Output() changeSearchState: EventEmitter<string> = new EventEmitter<string>();
    @Output() searchLaunched: EventEmitter<any> = new EventEmitter<any>();
    placeHolder: string = 'Enter a distributor name';
    readonly RIGHT = RIGHT;

    constructor(public searchService: SearchService,
                private urlGenerator: UrlGeneratorService,
                public authService: AuthService,
                private router: Router,
                private templateService: TemplateService,
                public needlSecurityService: NeedlSecurityService) {

    }

    ngOnInit() {
        if (this.needlSecurityService.hasRight(RIGHT.CAN_SEARCH_SUPPLIERS) && this.needlSecurityService.hasRight(RIGHT.CAN_SEARCH_BUYERS)) {
            this.placeHolder = 'Search a product, a company...';
        }else if (this.needlSecurityService.hasRight(RIGHT.CAN_SEARCH_SUPPLIERS)) {
            this.placeHolder = 'Search a product, a supplier...';
        }

        if (!this.searchService.searchCurrentTab) {
            if (!this.authService.isBuyer()) {
                this.searchService.searchCurrentTab = 'retails';
            } else {
                this.searchService.searchCurrentTab = 'suppliers';
            }
        }
    }

    onClickSearch() {
        if (this.searchService.searchTerm.length >= 4) {
            this.searchService.lastSearch = "";
            this.searchService._searchTermObs.next(this.searchService.searchTerm);
        }
    }

    cancelSearch() {
        this.templateService.unblockScroll();
        this.changeSearchState.emit('hidden');
    }

    launchSearch() {
        this.searchLaunched.emit();
        this.searchService.initPaginator(16, true);
        this.searchService.suggestionComponent.launchSearch();
    }

    resetSearch() {
        this.templateService.unblockScroll();
        this.searchService.lastSearch = '';
        this.searchService.searchTerm = '';
        this.changeSearchState.emit('hidden');
        if (this.router.url.indexOf('search') !== -1) {this.urlGenerator.updateSearchState();
    }}

    showSuggestions() {
        if (this.searchService.searchTerm.length > 2) {
            if (this.searchService.suggestionComponent.suggestionDataNull()) {
                this.searchService.lastSearch = '';
            }
            this.searchService.hideSuggestions = false;
            this.changeSearchState.emit('active');
            this.templateService.blockScroll();
            this.searchService.suggestionComponent.runSearch();
        }
    }
}
