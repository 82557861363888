import {Component, ContentChildren, HostBinding, Input, QueryList} from '@angular/core';
import {NdlInputBaseDirective} from '../input-base.directive';
import {NdlOptionDirective} from '../option.directive';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {NdlOptionGroupDirective} from '../optiongroup.directive';


@Component({
    selector: 'ndl-select-multi-level',
    templateUrl: './select-multi-level.component.html',
    styleUrls: ['./select-multi-level.component.scss'],
    host: {
        'class': 'ndl-select'
    }
})
export class NdlSelectMultiLevelComponent extends NdlInputBaseDirective {
    override defaultValue = [];

    @Input() identifier: string = null;
    @Input() maxHeight: string = null;
    @Input() height: string = null;
    @Input() title: string = null;

    @HostBinding('class.ndl-checkboxes-vertical')
    @Input() get vertical(): boolean {
        return this.groups.length > 0 ? true : this._vertical;
    }

    set vertical(vertical: boolean) {
        this._vertical = coerceBooleanProperty(vertical);
    }

    private _vertical = false;


    @Input() get searchable(): boolean {
        return this._searchable;
    }

    set searchable(searchable: boolean) {
        this._searchable = coerceBooleanProperty(searchable);
    }

    private _searchable = false;

    private _showBasicTitle = false;
    @Input() get showBasicTitle() {
        return this._showBasicTitle;
    }

    set showBasicTitle(showBasicTitle: boolean) {
        this._showBasicTitle = coerceBooleanProperty(showBasicTitle);
    }

    @Input() get displayMode(): boolean {
        return this._displayMode;
    }

    set displayMode(displayMode: boolean) {
        this._displayMode = coerceBooleanProperty(displayMode);
    }

    private _displayMode = false;

    private _clearable = false;
    @Input() get clearable() {
        return this._clearable;
    }

    set clearable(clearable: boolean) {
        this._clearable = coerceBooleanProperty(clearable);
    }

    private _onlyLastLevel = false;
    @Input() get onlyLastLevel() {
        return this._onlyLastLevel;
    }

    set onlyLastLevel(onlyLastLevel: boolean) {
        this._onlyLastLevel = coerceBooleanProperty(onlyLastLevel);
    }

    private _maximumSelectedOption = false;
    @Input() get maximumSelectedOption() {
        return this._maximumSelectedOption;
    }

    set maximumSelectedOption(maximumSelectedOption: boolean) {
        this._maximumSelectedOption = coerceBooleanProperty(maximumSelectedOption);
    }

    @ContentChildren(NdlOptionDirective) options: QueryList<NdlOptionDirective>;
    @ContentChildren(NdlOptionGroupDirective) groups: QueryList<NdlOptionGroupDirective>;
}
