import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from "@angular/forms";
import {NeedlButtonsModule} from "../buttons/buttons.module";
import {NeedlCheckboxesModule} from "../checkboxes/checkboxes.module";
import {NdlFilterTemplateComponent} from "./components/filter-template/filter-template.component";
import {NdlFilterWithCheckboxesComponent} from "./components/filter-with-checkboxes/filter-with-checkboxes.component";

@NgModule({
  declarations: [
      NdlFilterTemplateComponent,
      NdlFilterWithCheckboxesComponent
  ],
  imports: [
      CommonModule,
      FormsModule,
      NeedlButtonsModule,
      NeedlCheckboxesModule
  ],
  exports: [
      NdlFilterTemplateComponent,
      NdlFilterWithCheckboxesComponent
  ],
})
export class NeedlFiltersModule { }
