import {WabelEntity} from "./wabel-entity.abstract";
import {Company} from './company';

export class SourcingProjectProspect extends WabelEntity {
    constructor(obj: any) {
        super(obj);

        if (obj) {
            this.company = obj.company ? new Company(obj.company) : null;
        }
    }

    id: number;
    company: Company;
    comment: string;
    rating: number;
    score: number;
}
