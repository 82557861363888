import {NgModule} from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {SignUpPageComponent} from "./signup/sign-up-page.component";
import {UserComponent} from "./user/user.component";
import {IsNotLoggedGuard} from '../../guards/is-not-logged.guard';
import {RegistrationComponent} from "./registration/registration.component";
import {UnloggedRegistrationComponent} from "./registration/unlogged-registration-part/unlogged-registration.component";
import { LoggedRegistrationComponent } from './registration/logged-registration-part/logged-registration.component';
import {IsLoggedGuard} from "../../guards/is-logged.guard";

const routes: Routes = [
    {
        path: 'sign-up/manufacturer',
        component: RegistrationComponent,
        children: [
            {
                path: 'company-info',
                component: LoggedRegistrationComponent,
                canActivate: [IsLoggedGuard],
            },
            {
                path: '',
                component: UnloggedRegistrationComponent,
                canActivate: [IsNotLoggedGuard]
            }
        ]
    },
    {
        path: 'sign-up/:type',
        component: SignUpPageComponent, canActivate: [IsNotLoggedGuard]
    },
    {
        path: 'user/:action',
        component: UserComponent, canActivate: [IsNotLoggedGuard]
    },
    {
        path: 'join',
        component: UserComponent, canActivate: [IsNotLoggedGuard]
    }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
})
export class AuthRoutingModule {
}
