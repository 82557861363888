import {WabelEntity} from "./wabel-entity.abstract";

export class VideoMeetingInvitationSlots extends WabelEntity {
    constructor(obj: any) {
        super(obj);
    }

    idinvitationslot: number;
    timeFromViewable: string;
    timeToViewable: string;
    weekDayViewable: string;
    dateViewable: string;
    yearViewable: number;
    creatorTimezone: string;
    meetingDay: string;
    isSelected?: boolean;

    get id(): number {
        return this.idinvitationslot;
    }

    set id(id: number) {
        this.idinvitationslot = id;
    }

    getFormattedStringDate(){
        return `${this.weekDayViewable} ${this.dateViewable} ${this.yearViewable}`
    }

    getTimeFromFormatted(){
        return `${this.dateViewable} ${this.yearViewable} ${this.timeFromViewable}`
    }

    getTimeToFormatted(){
        return `${this.dateViewable} ${this.yearViewable} ${this.timeToViewable}`
    }

}
