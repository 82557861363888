<div class="ndl-slide" #item='cdkDrag' cdkDrag cdkDragLockAxis="x" [cdkDragDisabled]="disableDragging" (cdkDragReleased)="slideReleased($event)" *ngFor="let slide of slides; let i = index" [ngClass]="{
    'ndl-slide-position-left': selectedIndex > i,
    'ndl-slide-position-center': selectedIndex == i,
    'ndl-slide-position-right': selectedIndex < i
}">
    <ng-container *ngTemplateOutlet="slide.content"></ng-container>
</div>

<ng-container [ngSwitch]="arrowPosition">
    <ng-container *ngSwitchCase="'inside'">
        <button [disabled]='selectedIndex === 0' type="button" class="ndl-carousel-previous" mat-icon-button
                (click)="previous()">
            <mat-icon>chevron_left</mat-icon>
        </button>
        <button [disabled]='selectedIndex === slides.length-1' type="button" class="ndl-carousel-next" mat-icon-button
                (click)="next()">
            <mat-icon>chevron_right</mat-icon>
        </button>
    </ng-container>
    <ng-container *ngSwitchCase="'bottom'">
        <div class="ndl-carousel-bottom-actions">
            <button [disabled]='selectedIndex === 0' type="button" class="ndl-carousel-previous" mat-icon-button
                    (click)="previous()">
                <mat-icon>chevron_left</mat-icon>
            </button>
            <span>{{selectedIndex+1}} of {{slides.length}}</span>
            <button [disabled]='selectedIndex === slides.length-1' type="button" class="ndl-carousel-next" mat-icon-button
                    (click)="next()">
                <mat-icon>chevron_right</mat-icon>
            </button>
        </div>
    </ng-container>
</ng-container>
