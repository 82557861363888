import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Company} from "../../../services/wabel-client/entities/company";
import {Event} from "../../../services/wabel-client/entities/event";
import {NeedlSecurityService} from "@wabel/needl-security";
import {RIGHT} from "../../../services/wabel-client/security/right.const";
import {CompanyService} from "../../../services/wabel-client/services/company.service";
import {NdlModalDirective} from "needl-lib";
import {AuthService} from "../../../services/auth.service";

@Component({
    selector: 'app-video-meeting-request-button',
    templateUrl: './video-meeting-request-button.component.html',
    styleUrls: ['./video-meeting-request-button.component.scss']
})
export class VideoMeetingRequestButtonComponent implements OnInit {
    @Input() targetCompanyIds: number[] = [];
    @Input() event: Event = null;
    @Input() disabled = false;
    @Input() outlined = true;
    @Input() buttonLabel = 'Ask for a meeting';
    @Output() videoMeetingRequestDone = new EventEmitter();

    @ViewChild('videoMeetingRequestModal', {
        static: true,
        read: NdlModalDirective
    }) videoMeetingRequestModal: NdlModalDirective;

    targetCompany: Company = null;
    RIGHT = RIGHT;

    constructor(public needlSecurityService: NeedlSecurityService,
                public companyService: CompanyService,
                public authService: AuthService) {
    }

    ngOnInit() {
        if (this.targetCompanyIds.length === 1) {
            this.updateCompany(this.targetCompanyIds[0]);
        }
    }

    updateCompany(targetCompanyId: number = null) {
        if (this.needlSecurityService.hasRight(RIGHT.CAN_SEND_VIDEO_MEETING_REQUEST)) {
            this.targetCompanyIds[0] = targetCompanyId;
            this.targetCompany = null;
            if (this.targetCompanyIds[0]) {
                this.companyService.getInformationForVideoMeetingRequestButton(this.targetCompanyIds[0]).subscribe((company) => {
                    if (!this.event) {
                        this.targetCompany = company;
                    }
                }, (e) => {
                    console.error(e);
                });
            }
        }
    }

    videoMeetingRequestSent() {
        if (this.targetCompany) {
            this.targetCompany.hasPendingVideoMeetingsWithCurrentMember = true;
        }
        this.videoMeetingRequestDone.emit();
    }
}
