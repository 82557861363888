import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MemberService } from "../../../services/wabel-client/services/member.service";
import { AuthService } from "../../../services/auth.service";
import { Company } from "../../../services/wabel-client/entities/company";
import {NeedlSecurityService} from "@wabel/needl-security";
import {RIGHT} from "../../../services/wabel-client/security/right.const";

@Component({
    selector: 'schedule-video-meeting-button',
    templateUrl: './schedule-video-meeting-button.component.html',
    styleUrls: ['./schedule-video-meeting-button.component.scss']
})
export class ScheduleVideoMeetingButtonComponent implements OnInit {

    @Input() toCompany: Company = null;
    @Input() currentThreadId: number = null;
    @Input() canScheduleVideoCall = null;
    isActiveCompany = true;
    @Input() forceOpenWithoutButtonClick = false;
    @Input() isMobile = false;
    @Input() inSearch = false;
    @Output() closeEvent: EventEmitter<any> = new EventEmitter();
    RIGHT = RIGHT;

    showVideoMeetingSchedulingPopup = false;

    isVerified: boolean;
    loaded = false;

    constructor( public memberService: MemberService, public authService: AuthService, public needlSecurityService: NeedlSecurityService) {
    }

    ngOnInit() {
        if (this.toCompany) {
            this.isVerified = this.needlSecurityService.hasRight(this.RIGHT.CAN_CONTACT_SUPPLIERS);
            if (!this.toCompany.countActiveAndAlreadyLoggedMembers || this.toCompany.countActiveAndAlreadyLoggedMembers === 0) {
                this.isActiveCompany = false;
            }
            if (this.inSearch) {
                this.loaded = true;
                return;
            }
            this.memberService.checkMemberVideoMeetingsAndThreadByCompany(this.toCompany.idcompany).subscribe(
                (data) => {
                    if (!data) {
                        return;
                    }
                    this.loaded = true;
                    if (this.canScheduleVideoCall === null) {
                        this.canScheduleVideoCall = !data.hasVideoMeetingsWithCompany;
                    }
                    if (this.currentThreadId === null) {
                        this.currentThreadId = data.threadWithCompany &&
                        data.threadWithCompany.idthread ? data.threadWithCompany.idthread : null;
                    }
                });
        }
    }


    scheduleMeetingDialogue() {
        this.showVideoMeetingSchedulingPopup = true;
    }

    close(event) {
        this.showVideoMeetingSchedulingPopup = false;
        if (event === true) {
            this.canScheduleVideoCall = false;
        }
        this.closeEvent.emit(event);
    }

}
