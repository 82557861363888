import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'kit-form-input-float',
  templateUrl: './kit-form-input-float.component.html',
  styleUrls: ['./kit-form-input-float.component.scss']
})
export class KitFormInputFloatComponent implements OnChanges {

  @Input() id: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() helper: string;
  @Input() readOnly: boolean = false;

  @Input() binding: string;
  @Output() bindingChange = new EventEmitter();

  @Input() additionalInfo: string;
  @Input() width: string;

  //validation related
  @Input() isRequired: boolean = false;
  @Input() showError: boolean = false;
  @Input() showSuccess: boolean = false;
  @Input() doCheck: boolean = false;
  @Input() errorMessage: string = 'This field is required';
  @Output() validatorChange = new EventEmitter();
  isValidate: boolean = false;
  @Input() minValue: number = 0;
  @Input() maxValue;
  squeeze: boolean = false;

  change(newValue) {
    newValue = newValue.replaceAll('.', ',');
    this.binding = newValue;
    this.squeeze = false;
    this.errorMessage = 'This field is required';
    if (this.binding !== '' && this.binding !== null && !this.binding.match(/^[+-]?([0-9]+([,][0-9]*)?|[,][0-9]+)$/)) {
        this.errorMessage = 'Please use the following format: XX,XX';
        this.squeeze = true;
        return;
    }
    this.bindingChange.emit(newValue);
  }

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.doCheck && changes.doCheck.currentValue)
      this.checkError();
  }

  checkError() {
    this.showError = this.isRequired && (!this.binding || (this.binding && this.binding.length == 0) || this.binding == '' || !this.binding.match(/^[+-]?([0-9]+([,][0-9]*)?|[,][0-9]+)$/));

    if (this.isRequired && this.binding && this.binding.length > 0) {
      this.doCheck = false;
    }
  }
}
