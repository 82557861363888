import gql from "graphql-tag";
import {ProductGraphQL} from "./product.graphql";
import {TagTypeService} from "../services/tag_type.service";
import {TagTypeGraphql} from "./tag_type.graphql";
import {PackagingTags} from "../PackagingTags";

export class SearchGraphql {
    static fragments = {};

    static queries = {
        getEventPackagingSuppliersCompanies:gql`
            query Event(
                $eventcode : String!,
                $excludeCompaniesIds:[Int!]!
            ) {
                      findEventPackagingParticipationsByFilter(eventCode: $eventcode,excludeCompaniesIds:$excludeCompaniesIds) {
                        count
                        items {
                          company {
                            name
                            isWabelClubMember
                            privateLabelParts
                            ca {
                                ...tag
                            }
                            productCategories{
                                items {
                                    name
                                }
                            }
                            summitsParticipation{
                              name
                            }
                            countriesWorkingWith{
                              name
                            }
                            idcompany
                            name
                            type
                            country {
                              name
                              id
                                tagLocation {
                                    backgroundImage
                                }
                            }
                            company_type_machine_name
                            website
                            description
                            urlname
                            logoUrl(size: "300")
                            logo {
                                file {
                                    fileName
                                }
                            }
                            formattedAddress
                            phone
                            nbSellingPoints {
                                id
                                name
                            }
                            yearEstablished
                            ca {
                                ...tag
                            }
                            numemployees {
                                ...tag
                            }
                            privateLabelParts
                            productionCapacity
                            privateLabel
                              specializations: tags(tagTypeInternalName: "${TagTypeService.SPECIALIZATION_INTERNAL_NAME}") {
                                  items {
                                      ...tag
                                  }
                              }
                            citylocation {
                              latitude
                              longitude
                              city
                              region
                                country{
                                tagLocation {
                                    code
                                }
                                name
                              }
                              __typename
                            }
                              
                            products {
                                iduserproduct
                                name
                                urlPath
                                category {
                                    ...fullTag
                                }
                                logoUrl(size: "300")
                                company {
                                    company_type_machine_name
                                }
                            }

                              companyDistributionChannelsReference: tags(tagTypeInternalName: "${TagTypeService.DISTRIBUTION_CHANNEL_INTERNAL_NAME}") {
                                  items {
                                      ...tag
                                  }
                              }


                          }
                          meetingWithMyCompany(eventCode: $eventcode) {
                              startDate
                          }
                          myEventMeetingConfirmation {
                              choice
                          }
                        }
                      }
                    }
            ${TagTypeGraphql.fragments.tag}
            ${TagTypeGraphql.fragments.fullTag}
        `,


        getPackagingCompanyBuyersForEvent: gql`
            query getPackagingCompanyBuyersForEvent($eventCode : String!) {
                event(eventCode: $eventCode) {
                    buyerCompaniesForPackForChangeEvent {
                        companytype {
                            name
                        }
                        idcompany
                        name
                        ca(eventCode: $eventCode) {
                            id
                            name
                        }
                        logoUrl(size: "300")
                        country {
                            name
                        }
                        ecoPackProjectCategories: ecoPackProjectTags(idparent: ${PackagingTags.CATEGORIES}) {
                            items {
                                name
                                idecopackprojecttag
                            }
                        }
                        ecoPackProjectEcoFriendlyFeatures: ecoPackProjectTags(idparent: ${PackagingTags.ECO_FRIENDLY}) {
                            items {
                                name
                                idecopackprojecttag
                            }
                        }
                        company_type_machine_name
                        urlname
                        ecopackpolicydescription
                        ecoPackProjectsCount
                        ecopackprojects(eventCode: $eventCode, limit: 3) {
                            idecopackproject
                            name
                            packagingPurpose: tags(tagTypeInternalName: "${TagTypeService.ECO_PACKAGING_PURPOSE_INTERNAL_NAME}") {
                                items {
                                    id
                                    name
                                }
                            },
                            consistency: tags(tagTypeInternalName: "${TagTypeService.ECO_PACKAGING_CONSISTENCY_INTERNAL_NAME}") {
                                items {
                                    id
                                    name
                                }
                            },
                            projectTimeLine: tags(tagTypeInternalName: "${TagTypeService.ECO_PACKAGING_PROJECT_TIMELINE_INTERNAL_NAME}") {
                                items {
                                    id
                                    name
                                }
                            },
                            categories: ecoPackProjectTags(idparent: ${PackagingTags.CATEGORIES}) {
                                name
                                idecopackprojecttag
                            }
                            material: ecoPackProjectTags(idparent: ${PackagingTags.MATERIAL}) {
                                name
                                idecopackprojecttag
                            }
                            ecoFriendlyFeatures: ecoPackProjectTags(idparent: ${PackagingTags.ECO_FRIENDLY}) {
                                name
                                idecopackprojecttag
                            }
                            company {
                                urlname
                            }
                        }
                    }
                }
            }
        `,


    };

    static mutations = {

        askSampleRequest: gql`
            mutation askSampleRequest($products : [Int!]!, $addressID : Int!) {
                askSampleRequest(productIds: $products, idaddress: $addressID)
            }
        `,

        askSampleRequestForMeeting: gql`
            mutation askSampleRequestForMeeting($products : [Int!]!, $addressID : Int!, $idMeeting: Int!) {
                askSampleRequestForMeeting(iduserproducts: $products, idaddress : $addressID, idMeeting: $idMeeting) {
                    sampleRequest {
                        status
                        thread {
                            idthread
                        }
                    }
                }
            }
        `,


    };
}
