import {Directive, ElementRef, Input, Renderer2} from "@angular/core";

@Directive({
    selector: '[appGtm]'
})
export class GoogleTagManagerDirective {
    private _tagName: string;
    @Input('appGtm') get tagName() {
        return this._tagName;
    }

    set tagName(tagName) {
        if (this.tagName) {
            this.renderer.removeClass(this.hostElement.nativeElement, this.tagName);
        }

        this._tagName = tagName ? 'gtm' + tagName : null;

        if (this.tagName) {
            this.renderer.addClass(this.hostElement.nativeElement, this.tagName);
        }
    }

    constructor(private renderer: Renderer2, private hostElement: ElementRef) {
    }
}
