import {Directive, EventEmitter, Input, OnDestroy, Output, TemplateRef} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {NdlModalTemplateComponent} from './modal-template.component';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {Subscription} from "rxjs";

@Directive({
    selector: '[ndlModal]',
    exportAs: 'ndlModal'
})
export class NdlModalDirective<T = any> implements OnDestroy {
    ref: MatDialogRef<NdlModalTemplateComponent<T>>;
    @Input() title: string;
    @Input() icon: string;
    @Input() class = "";
    @Input() closable = true;
    @Output() closed = new EventEmitter();
    closed$: Subscription;

    private _fullscreen: boolean = false;
    @Input() get fullscreen() {
        return this._fullscreen;
    }

    set fullscreen(fullscreen) {
        this._fullscreen = coerceBooleanProperty(fullscreen);
    }

    constructor(private content: TemplateRef<any>, public dialog: MatDialog) {
    }

    open(context?: T): void {
        this.ref = this.dialog.open(NdlModalTemplateComponent, {
            data: {
                context: context,
                content: this.content,
                icon: this.icon,
                title: this.title,
                closable: this.closable
            },
            closeOnNavigation: this.closable,
            disableClose: !this.closable,
            panelClass: ("ndl-modal-overlay " + this.class + (this.fullscreen ? ' ndl-modal-fullscreen' : '')).split(' '),
            maxWidth: null,
            autoFocus: false,
            restoreFocus: true
        });

        if (this.closed$) {
            this.closed$.unsubscribe();
        }

        this.closed$ = this.ref.afterClosed().subscribe(data => {
            this.closed.emit();
        });
    }

    close(): void {
        this.ref?.close();
    }

    ngOnDestroy() {
        if (this.closed$) {
            this.closed$.unsubscribe();
        }
    }
}
