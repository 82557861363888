import {BrowserModule} from '@angular/platform-browser';
import {NgModule, ErrorHandler} from '@angular/core';

import {environment} from "../environments/environment";

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NgxPageScrollModule} from 'ngx-page-scroll';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';

import {LazyLoadImageModule} from "ng-lazyload-image";

import {FlickityModule} from 'ngx-flickity';

import {PrivateMessagesService} from "./services/private-messages.service";

import {WabelErrorHandler} from './services/wabel.errorHandler';
import {AuthModule} from './modules/auth-module/auth.module';
import {FallbacksModule} from "./pages/fallbacks/fallbacks.module";
import {NgxWebstorageModule} from 'ngx-webstorage';
import {SharedModule} from './modules/shared-module/shared.module';
import {
    NeedlPageModule,
    NdlUploader,
} from 'needl-lib';
import {VideoCallIframeComponent} from "./modules/shared-module/video-call-iframe/video-call-iframe.component";
import {GraphQLModule} from './graphql.module';
import {HttpClientModule} from '@angular/common/http';
import {TourMatMenuModule} from "ngx-ui-tour-md-menu";
import {UploadService} from './services/wabel-client/services/upload.service';
import {AppFrontModule} from './components/app-front/app-front.module';
import {SignInModule} from './components/sign-in/sign-in.module';
import {NeedlSecurityModule} from '@wabel/needl-security';
import {NewVideoMeetingModule} from "./modules/new-video-meeting-module/new-video-meeting.module";
import {LiveVideoMeetingService} from "./services/wabel-client/services/live_video_meeting.service";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@NgModule({
    declarations: [
        AppComponent,
        VideoCallIframeComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AppFrontModule,
        GraphQLModule,
        NeedlPageModule,
        NeedlSecurityModule,
        LazyLoadImageModule,
        FlickityModule,
        NgxPageScrollModule,
        NgxPageScrollCoreModule.forRoot({duration: 200}),
        FallbacksModule,
        SharedModule,
        AuthModule,
        NgxWebstorageModule.forRoot({prefix: 'needl'}),
        TourMatMenuModule.forRoot(),
        SignInModule,
        NewVideoMeetingModule,
        MatProgressSpinnerModule,
    ],
    providers: [
        // Custom injection of the ErrorHandler
        PrivateMessagesService, {
            provide: ErrorHandler,
            useClass: WabelErrorHandler,
        },

        // InjectionToken for the NdlUploadFileComponent
        {provide: NdlUploader, useClass: UploadService},
        LiveVideoMeetingService
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        VideoCallIframeComponent
    ]
})
export class AppModule {
}
