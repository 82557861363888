import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {CompanyProfileSubmissionService} from "../../../../services/wabel-client/services/company-profile-submission.service";
import {MatStepper} from "@angular/material/stepper";
import {Company} from "../../../../services/wabel-client/entities/company";
import {AuthService} from "../../../../services/auth.service";
import {ImageService} from "../../../../services/image.service";
import {UtilsService} from "../../../../services/utils.service";

@Component({
    selector: 'company-profile-stepper',
    templateUrl: './company-profile-stepper.component.html',
    styleUrls: ['./company-profile-stepper.component.scss']
})
export class CompanyProfileStepperComponent implements OnInit, AfterViewInit {
    isLinear = true;
    @Input() buyerCompany: Company;
    sellerCompany: Company;
    currentStep = '';
    @ViewChild("stepper", { static: true }) private stepper: MatStepper;

    constructor(public imageService: ImageService,
                public companyProfileSubmissionService: CompanyProfileSubmissionService,
                private authservice: AuthService, public utilsService: UtilsService
    ) {
    }

    ngOnInit() {
        this.sellerCompany = this.authservice.getMember().company;
    }

    ngAfterViewInit() {
        this.companyProfileSubmissionService.getCurrentStep().subscribe(currentStep => {
            if (!currentStep) {
                return;
            }
            if (currentStep !== this.companyProfileSubmissionService.CATEGORIES_SELECTION_STEP) {
                this.stepper.selected.completed = true;
            }
            this.currentStep = currentStep;
            this.updateStepperCurrentStep();
        });
        this.setStepperStepsStatus(0);
    }

    setStepperStepsStatus(currentStep: number) {
        let stepIndex = 0;
        this.stepper._steps.forEach(step => {
            if (currentStep > stepIndex) {
                step.completed = true;
            }
            stepIndex += 1;
        });
    }

    updateStepperCurrentStep() {
        const stepIndex = this.getStepIndex(this.currentStep);
        this.setStepperStepsStatus(stepIndex);
        this.stepper.selectedIndex = stepIndex;
    }

    getStepIndex(step: string) {
        const {
            MESSAGE_STEP,
            EXPORTING_COUNTRIES_STEP,
            CLIENT_PARTNER_STEP,
            CATEGORIES_SELECTION_STEP,
            FEATURED_PRODUCTS_STEP,
            DOCUMENTS_UPLOAD_STEP,
            CREDIT_DEDUCTION_STEP,
            PREVIEW_PROFILE_STEP
        } = this.companyProfileSubmissionService;

        switch (step) {
            case CATEGORIES_SELECTION_STEP:
                return 0;
            case MESSAGE_STEP:
                return 1;
            case EXPORTING_COUNTRIES_STEP:
            case CLIENT_PARTNER_STEP:
                return 2;
            case FEATURED_PRODUCTS_STEP:
                return 3;
            case DOCUMENTS_UPLOAD_STEP:
                return 4;
            case CREDIT_DEDUCTION_STEP:
            case PREVIEW_PROFILE_STEP:
                return 5;
            default:
                return 5;
        }
    }
}
