import gql from "graphql-tag";
import {TagTypeGraphql} from './tag_type.graphql';
import {TagTypeService} from "../services/tag_type.service";
import {TagGraphql} from './tag.graphql';

export class BusinessMappingGraphql {
    static fragments = {
        businessMappingPriority: gql`
            fragment businessMappingPriority on BusinessMappingPriority {
                id,
                goal,
                priority,
                region {
                    ...tag
                },
                countries: tagsByInternalName(internalName: "${TagTypeService.LOCATION_INTERNAL_NAME}") {
                    items {
                        id
                        name
                        tagLocation {
                            code
                        }
                    }
                }
                activities: tagsByInternalName(internalName: "${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
                    items {
                        ...tag
                    }
                }
                businessMappingPriorityRecommendations(type: "included") {
                    items {
                        company {
                            name
                        }
                    }
                }
                companyTypes {
                    idcompanytype,
                    name
                }
                businessMappingPriorityRecommendations(type: "included") {
                    items {
                        company {
                            name
                        }
                    }
                }
            }
            ${TagTypeGraphql.fragments.tag}
        `,
        completeBusinessMapping: gql`
            fragment businessMapping on BusinessMapping {
                    id
                    dateCreated
                    dateUpdated
                    completed
                    member {
                        company {
                            companyClients {
                                count
                            }
                        }
                    }
                    businessMappingPriorities {
                        items {
                            id,
                            goal,
                            priority,
                            region {
                                ...tag
                            },
                            countries: tagsByInternalName(internalName: "${TagTypeService.LOCATION_INTERNAL_NAME}") {
                                items {
                                    id
                                    name
                                    tagLocation {
                                        code
                                    }
                                }
                            }
                            activities: tagsByInternalName(internalName: "${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
                                items {
                                    ...tag
                                }
                            }
                            businessMappingPriorityRecommendations(type: "included") {
                                items {
                                    company {
                                        name
                                    }
                                }
                            }
                            companyTypes {
                                idcompanytype,
                                name
                            }
                            businessMappingPriorityRecommendations(type: "included") {
                                items {
                                    company {
                                        name
                                    }
                                }
                            }
                        }
                    }
                    businessMappingTags {
                        items {
                            tag {
                                id
                                name
                                tagLocation {
                                    code
                                }
                            }
                            type
                        }
                    }
            }
            ${TagGraphql.fragments.tag}
        `
    };

    static queries = {
        myBusinessMappingPriorities: gql`
            query myBusinessMappingPriorities {
                myBusinessMappingPriorities {
                    items {
                        ...businessMappingPriority
                    }
                }
            }
            ${BusinessMappingGraphql.fragments.businessMappingPriority}
        `,
        myBusinessMapping: gql`
            query myBusinessMapping {
                myBusinessMapping {
                    ...businessMapping
                }
            }
            ${BusinessMappingGraphql.fragments.completeBusinessMapping}
        `,
        businessMappingPriority: gql`
            query businessMappingPriority(
                $businessMappingPriority: BusinessMappingPriorityInput!,
                $countryRecommendationFilters: [String!]!,
                $limit: Int,
                $offset: Int,
            ) {
                businessMappingPriority(businessMappingPriority: $businessMappingPriority) {
                    countries {
                        items {
                            name
                        }
                    }
                    recommendations(countryRecommendationFilters: $countryRecommendationFilters, limit: $limit, offset: $offset) {
                        idcompany
                        name
                        type
                        urlname
                        company_type_machine_name
                        country {
                            name
                            tagLocation {
                                code
                            }
                        }
                        citylocation {
                            country {
                                name
                                tagLocation {
                                    code
                                }
                            }
                        }
                        companytype {
                            name
                        }
                        exportCountries {
                            name
                        }
                        buyingScope {
                            items {
                                name
                                parent {
                                    name
                                }
                            }
                        }
                        logoUrl(size: "200")
                        yearEstablished
                        numemployees(businessMappingPriority: $businessMappingPriority) {
                            name
                        },
                        ca(businessMappingPriority: $businessMappingPriority) {
                            name
                        }
                        businessPriorityScore {
                            value
                        }
                        activitiesOfMembers {
                            name
                        }
                        nbSellingPoints {
                            name
                        }
                    }
                    countryRecommendationFilters(countryRecommendationFilters: $countryRecommendationFilters)
                }
            }
        `,
        businessMappingPriorityRecommendationFilters: gql`
            query businessMappingPriority(
                $businessMappingPriority: BusinessMappingPriorityInput!,
                $countryRecommendationFilters: [String!]!
            ) {
                businessMappingPriority(businessMappingPriority: $businessMappingPriority) {
                    countryRecommendationFilters(countryRecommendationFilters: $countryRecommendationFilters)
                }
            }
        `,
    };

    static mutations = {
        saveBusinessMappingPriorities: gql`
            mutation saveBusinessMappingPriorities(
                $marketingFocus: [Int!]!,
                $businessMappingPriorities: [CreateBusinessMappingPriorityInput!]!
            ) {
                saveBusinessMappingPriorities(
                    marketingFocus: $marketingFocus,
                    businessMappingPriorities: $businessMappingPriorities
                ) {
                    company {
                        idcompany
                        registrationStep
                        stockAvailability
                        companyRewardScore {
                            name,
                            value,
                            weight,
                            children {
                                name,
                                value,
                                weight,
                            },
                        }
                    }
                    businessMapping {
                        id
                        member {
                            company {
                                companyClients {
                                    count
                                }
                            }
                        }
                        businessMappingPriorities {
                            items {
                                ...businessMappingPriority
                            }
                        }
                    }

                }
            }
            ${BusinessMappingGraphql.fragments.businessMappingPriority}
            `,

        saveBusinessMappingPriority: gql`
            mutation saveBusinessMappingPriority(
                $businessMappingPriority: CreateBusinessMappingPriorityInput!,
            ) {
                saveBusinessMappingPriority(
                    businessMappingPriority: $businessMappingPriority
                ) {
                    ...businessMappingPriority
                    businessMapping {
                        id
                    }
                }
            }
            ${BusinessMappingGraphql.fragments.businessMappingPriority}
        `,

        deleteBusinessMappingPriority: gql`
            mutation deleteBusinessMappingPriority(
                $idBusinessMappingPriority: Int!,
            ) {
                deleteBusinessMappingPriority(
                    idBusinessMappingPriority: $idBusinessMappingPriority
                )
            }`,

        saveBusinessMappingTags: gql`
            mutation saveBusinessMappingTags(
                $businessMappingTags: [BusinessMappingTagInput!]!,
            ) {
                saveBusinessMappingTags(
                    businessMappingTags: $businessMappingTags
                ) {
                    tag {
                        id
                        name
                        machineName
                        tagLocation {
                            code
                        }
                    }
                    type
                }
            }
        `,

        updateBusinessMappingPriorityProspect: gql`
            mutation updateBusinessMappingPriorityProspect(
                $idBusinessMappingPriority: Int!,
                $idcompany: Int!,
                $included: Boolean!,
                $removedReason: String,
                $customRemovedReason: String,
            ) {
                updateBusinessMappingPriorityProspect(
                    idBusinessMappingPriority: $idBusinessMappingPriority,
                    idcompany: $idcompany,
                    included: $included,
                    removedReason: $removedReason,
                    customRemovedReason: $customRemovedReason
                )
            }`,
    };
}
