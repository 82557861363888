import {WabelEntity} from "./wabel-entity.abstract";

export class SubscriptionPack extends WabelEntity {
    constructor(obj?: any) {
        super(obj);
    }

    idsubscriptionpack: number;
    label: string;
    duration: number;
    durationUnit: string;
    priority: number;
    monthlyProductRequestsNumber: number;
    monthlyCategoryBenchmarksNumber: number;
    monthlyCompanyProfileSubmissionsNumber: number;
    get id(): number {
        return +this.idsubscriptionpack;
    }

    set id(id: number) {
        this.idsubscriptionpack = id;
    }
}
