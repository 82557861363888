import gql from "graphql-tag";

export class VideoMeetingInvitationGraphQL {
  static fragments = {};

  static queries = {
    getVideoMeetingInvitationsForCalender: gql`
            query getVideoMeetingInvitationsForCalender($idmember: Int) {
                videoMeetingInvitationsForCalender(idmember: $idmember) {
                    idvideomeetinginvitation
                    invitationSubject
                    invitationMessage
                    invitationStatus
                    createdBy {
                      idmember
                      fullname
                    }
                    createdFor {
                      idmember
                      fullname
                    }
                    videoMeetingInvitationSlots {
                      items {
                        idinvitationslot
                        timeFromViewable
                        timeToViewable
                        creatorTimezone
                        dateViewable
                        yearViewable
                        weekDayViewable
                        meetingDay
                      }
                    }
                }
            }
        `,

    getVideoMeetingInvitationsTemporaryBookedSlots: gql`
                query getVideoMeetingInvitationsTemporaryBookedSlots($idmember: Int) {
                    videoMeetingInvitationsTemporaryBookedSlots(idmember: $idmember) {
                        idvideomeetinginvitation
                        invitationSubject
                        invitationMessage
                        invitationStatus
                        createdBy {
                        idmember
                        fullname
                        }
                        createdFor {
                        idmember
                        fullname
                        }
                        videoMeetingInvitationSlots {
                        items {
                            idinvitationslot
                            timeFromViewable
                            timeToViewable
                            creatorTimezone
                            dateViewable
                            yearViewable
                            weekDayViewable
                            meetingDay
                        }
                        }
                    }
                }
            `,
    getVideoMeetingInvitationsById: gql`
        query getVideoMeetingInvitationsById($idvideomeetinginvitation: Int!) {
            videoMeetingInvitationsById(idvideomeetinginvitation: $idvideomeetinginvitation) {
                idvideomeetinginvitation
                invitationSubject
                invitationMessage
                invitationStatus
                createdBy {
                  idmember
                  fullname
                }
                createdFor {
                  idmember
                  fullname
                }
                videoMeetingInvitationSlots {
                  items {
                    idinvitationslot
                    timeFromViewable
                    timeToViewable
                    creatorTimezone
                    dateViewable
                    yearViewable
                    weekDayViewable
                    meetingDay
                  }
                }
            }
        }
    `,
    getVideoMeetingByHashID: gql`
        query getVideoMeetingByHashID($hashId: String!) {
            videoMeetingByHashID(hashId: $hashId) {
                idvideomeetinginvitation
                invitationSubject
                invitationMessage
                invitationStatus
                createdBy {
                  idmember
                  fullname
                }
                createdFor {
                  idmember
                  fullname
                }
                videoMeetingInvitationSlots {
                  items {
                    idinvitationslot
                    timeFromViewable
                    timeToViewable
                    creatorTimezone
                    dateViewable
                    yearViewable
                    weekDayViewable
                    meetingDay
                  }
                }
            }
        }
    `,
    getMyVideoUpcomingMeetings: gql`
            query Member($limit: Int,$search: String!,$statuses: [String!]!) {
              myUpcomingVideoMeetings(search:$search,statuses:$statuses) {
                items(limit:$limit){
                    idvideomeetinginvitation
                    invitationStatus
                    invitationSubject
                    invitationMessage
                    videoMeetingColleagues{
                        items {
                          id
                          colleagueIdmember {
                            idmember
                          }
                          invitedFromIdmember{
                            idmember
                          }
                          videoMeeting {
                            idvideomeetinginvitation
                          }
                          firstName
                          lastName
                          nameInitials
                        }
                      }
                    message{
                      idprivatemessage
                      thread{
                        idthread
                        recipients {
                          company {
                            idcompany
                            name
                            company_type_machine_name
                            logoUrl(size: "123")
                            citylocation {
                              city
                                country {
                                  tagLocation {
                                      code
                                  }
                              }
                            }
                          }
                        }
                      }
                    }
                    createdBy {
                      idmember
                      fullname
                      timezone
                      company {
                        idcompany
                        name
                        company_type_machine_name
                        logoUrl(size: "123")
                        citylocation{
                          city
                            country{
                              tagLocation {
                                  code
                              }
                          }
                        }
                      }
                    }
                    createdFor {
                      idmember
                      fullname
                      timezone
                      company {
                        idcompany
                        name
                        company_type_machine_name
                        logoUrl(size: "123")
                        citylocation{
                          city
                            country{
                              tagLocation {
                                  code
                              }
                          }
                        }
                      }
                    }
                    videoMeetingInvitationSlots {
                      items {
                        idinvitationslot
                        timeFromViewable
                        timeToViewable
                        weekDayViewable
                        dateViewable
                        yearViewable
                        creatorTimezone
                        meetingDay
                      }
                    }
                }
              }
            }
        `,

  };

  static mutations = {

    updateStatusVideoMeetingInvitation: gql`
            mutation updateStatusVideoMeetingInvitation($idvideomeetinginvitation: Int!, $status: String! , $reason: String ) {
                updateStatusVideoMeetingInvitation( idvideomeetinginvitation: $idvideomeetinginvitation, status: $status , reason: $reason ) {
                    invitationStatus
                }
            }
        `,
    acceptSlotVideoMeetingInvitation: gql`
            mutation acceptSlotVideoMeetingInvitation($idvideomeetinginvitation: Int!, $idinvitationslot: Int! ) {
                acceptSlotVideoMeetingInvitation( idvideomeetinginvitation: $idvideomeetinginvitation, idinvitationslot: $idinvitationslot ) {
                  idvideomeetinginvitation
                  invitationStatus
                  invitationSubject
                  invitationMessage
                  createdBy {
                    idmember
                    fullname
                    timezone
                  }
                  createdFor {
                    idmember
                    fullname
                    timezone
                    company {
                      idcompany
                      name
                      company_type_machine_name
                      logoUrl(size: "123")
                      country {
                          tagLocation {
                              code
                          }
                      }
                    }
                  }
                  videoMeetingInvitationSlots {
                    items {
                      idinvitationslot
                      timeFromViewable
                      timeToViewable
                      weekDayViewable
                      dateViewable
                      yearViewable
                      creatorTimezone
                      meetingDay
                    }
                  }
                }
            }
        `,
    sendVideoMeetingInvitationToMember: gql`
            mutation sendVideoMeetingInvitationToMember($videoMeetingId: Int!, $memberId: Int!) {
                sendVideoMeetingInvitationToMember( videoMeetingId: $videoMeetingId, memberId: $memberId) {
                    idvideomeetinginvitation
                }
            }
        `,

    sendVideoMeetingInvitationToGuest: gql`
            mutation sendVideoMeetingInvitationToGuest($videoMeetingId: Int!, $firstName: String! , $lastName: String! , $email: String!) {
                sendVideoMeetingInvitationToGuest( videoMeetingId: $videoMeetingId, firstName: $firstName , lastName:$lastName  , email:$email) {
                    idvideomeetinginvitation
                }
            }
        `,
    rescheduleVideoMeetingInvitationSlots: gql`
            mutation rescheduleVideoMeetingInvitationSlots($idvideomeetinginvitation: Int!, $invitation_data: String! ,$fromToData:String!) {
                rescheduleVideoMeetingInvitationSlots( idvideomeetinginvitation: $idvideomeetinginvitation, invitation_data: $invitation_data ,fromToData:$fromToData) {
                  idvideomeetinginvitation
                  invitationStatus
                  invitationSubject
                  invitationMessage
                  createdBy {
                    idmember
                    fullname
                    timezone
                  }
                  createdFor {
                    idmember
                    fullname
                    timezone
                    company {
                      idcompany
                      name
                      company_type_machine_name
                      logoUrl(size: "123")
                      country {
                          tagLocation {
                              code
                          }
                      }
                    }
                  }
                  videoMeetingInvitationSlots {
                    items {
                      idinvitationslot
                      timeFromViewable
                      timeToViewable
                      weekDayViewable
                      dateViewable
                      yearViewable
                      creatorTimezone
                      meetingDay
                    }
                  }
                }
            }
        `
  };
}
