<mat-form-field #formField>
    <ng-container *ngIf="prefix" matPrefix class="ndl-prefix" [ngTemplateOutlet]="prefix"></ng-container>

    <mat-label *ngIf="label">{{label}}</mat-label>
    <ng-content></ng-content>

    <ng-container *ngIf="suffix" matSuffix class="ndl-suffix" [ngTemplateOutlet]="suffix"></ng-container>

    <mat-hint *ngIf="hint">
        <div [innerHTML]="hint"></div>
    </mat-hint>
    <mat-error *ngIf="formFieldControl.ngControl?.errors?.required">This field cannot be empty</mat-error>
    <mat-error *ngFor="let error of errors">
        <ng-container *ngTemplateOutlet="error.content"></ng-container>
    </mat-error>
    <mat-error *ngFor="let projectedError of projectedErrors">
        <ng-container *ngTemplateOutlet="projectedError.content"></ng-container>
    </mat-error>
</mat-form-field>

