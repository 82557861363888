import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NdlCardComponent} from './card.component';
import {NdlCardFooterDirective} from './card-footer.directive';
import {MatDividerModule} from '@angular/material/divider';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {NdlCardOptionComponent} from './card-option.component';
import {MatIconModule} from '@angular/material/icon';
import {NdlCardFlagDirective} from './card-flag.directive';
import {NdlCardStatusOutsideDirective} from "./card-status-outside.directive";

@NgModule({
    declarations: [
        NdlCardComponent,
        NdlCardFooterDirective,
        NdlCardOptionComponent,
        NdlCardFlagDirective,
        NdlCardStatusOutsideDirective
    ],
    imports: [
        CommonModule,
        MatDividerModule,
        MatButtonModule,
        MatMenuModule,
        MatIconModule
    ],
    exports: [
        NdlCardComponent,
        NdlCardFooterDirective,
        NdlCardOptionComponent,
        NdlCardFlagDirective,
        NdlCardStatusOutsideDirective
    ]
})
export class NeedlCardsModule {
}
