import {WabelEntity} from "./wabel-entity.abstract";
import {File} from "./file";

export class Sponsor extends WabelEntity {
    constructor(obj: any) {
        super(obj);
        if (obj) {
            if (obj.file) { this.file = new File(this.file); }
            if (obj.image) { this.image = new File(this.image); }
        }
    }

    id: number;
    image: File;
    file: File;
    name: string;
    url: string;
}
