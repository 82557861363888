/**
 * Created by Michael on 20/01/2017.
 */
import {WabelEntity} from './wabel-entity.abstract';
import {Registration} from './registration';

export class RegistrationAvailability extends WabelEntity {
    constructor(obj?: any) {
        super(obj);
        if(obj.availabilityStart) this.availabilityStart = new Date(obj.availabilityStart);
        if(obj.availabilityEnd) this.availabilityEnd = new Date(obj.availabilityEnd);
        if(obj.certainEventRegistration) this.registration = new Registration(obj.certainEventRegistration);
    }
    availabilityStart: Date;
    availabilityEnd: Date;
    text: string;

    registration: Registration;

    get id() {
        return 0;
    }

    get formattedAvailabilityStart() {
        return this.availabilityStart.toLocaleString()
    }

    get formattedAvailabilityEnd() {
        return this.availabilityEnd.toLocaleString()
    }
}
