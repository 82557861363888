import {Directive} from '@angular/core';
import {MatDialogTitle} from "@angular/material/dialog";

@Directive({
    selector: '[ndl-modal-header]',
    providers: [
        {provide: MatDialogTitle, useExisting: NdlModalHeaderDirective}
    ]
})
export class NdlModalHeaderDirective extends MatDialogTitle {
}
