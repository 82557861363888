import {WabelEntity} from "./wabel-entity.abstract";

export class PredefinedMessagesTopics extends WabelEntity {

    title: string;
    content: string;
    idcompanytype: number[] = [];


    constructor(obj: any) {
        super(null);
        this.title = obj.title;
        this.content = obj.content;
        if (obj.companytypes) {
            for (let currentCompanytypes of obj.companytypes) {
                this.idcompanytype.push(currentCompanytypes.idcompanytype)
            }
        }

    }


    get id(): string {
        return this.title;
    }
}