import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from "@angular/forms";
import {NdlDatePickerComponent} from "./components/date-picker/date-picker.component";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import {MatButtonModule} from "@angular/material/button";
import {NdlCustomHeaderDatePickerComponent} from "./components/date-picker/custom-header-date-picker/custom-header-date-picker.component";

@NgModule({
  declarations: [
      NdlDatePickerComponent,
      NdlCustomHeaderDatePickerComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatButtonModule
    ],
  exports: [NdlDatePickerComponent],
})
export class NeedlDatePickersModule { }
