import {Component, Input, OnInit} from '@angular/core';
import {UtilsService} from "../../../services/utils.service";
import {Summit} from "../../../services/wabel-client/entities/summit";

@Component({
    selector: 'next-summit-banner',
    templateUrl: './next-summit-banner.component.html',
    styleUrls: ['./next-summit-banner.component.scss']
})
export class NextSummitBannerComponent implements OnInit {

    @Input() summit: Summit;

    constructor(public utilsService: UtilsService) {
    }

    ngOnInit() {
    }

}
