import {Component, HostListener, OnInit, Renderer2} from '@angular/core';
import {AuthService} from './services/auth.service';
import {TemplateService} from './services/template.service';
import {fadeAnimation} from './animations/fade.animation';
import {PrivateMessagesService} from './services/private-messages.service';
import {ActivationEnd, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {CacheService} from './services/cache.service';
import {UtilsService} from './services/utils.service';
import {environment} from '../environments/environment';
import {LocalStorageService} from 'ngx-webstorage';
import {BUYER_SCOPE_MODAL_INTERNALNAME} from './services/wabel-client/entities/member_notification';
import {FilterService} from './services/filter.service';
import {ZoomCallDockService} from './services/zoom-call-docked.service';
import {RIGHT} from './services/wabel-client/security/right.const';
import {CookieService} from './services/cookie.service';
import * as dayjs from 'dayjs';
import {CheckForUpdateService} from './services/check-for-update.service';
import {NdlLoadingService} from 'needl-lib';
import {animate, AUTO_STYLE, state, style, transition, trigger} from '@angular/animations';
import {HelpdeskService} from "./services/wabel-client/services/helpdesk.service";


declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [fadeAnimation,
        trigger('ndl-loader-animate', [
            state('opened', style({transform: 'translateY(0%)'})),
            state('closed', style({ transform: 'translateY(-100%)'})),
            transition('opened => closed', animate(500 + 'ms cubic-bezier(0.895, 0.030, 0.685, 0.220)')),
            transition('closed => opened', animate(500 + 'ms cubic-bezier(0.165, 0.840, 0.440, 1.000)'))
        ])
    ]
})
export class AppComponent implements OnInit {
    BUYER_SCOPE_MODAL_INTERNALNAME = BUYER_SCOPE_MODAL_INTERNALNAME;
    readonly RIGHT = RIGHT;
    appBannersPaddingLeft = '0px';

    constructor(
        private renderer: Renderer2,
        public templateService: TemplateService,
        public authService: AuthService,
        public privateMessagesService: PrivateMessagesService,
        private router: Router,
        private titleService: Title,
        private cacheService: CacheService,
        private utilsService: UtilsService,
        private localStorageService: LocalStorageService,
        private filterService: FilterService,
        public zoomCallDockService: ZoomCallDockService,
        private cookieService: CookieService,
        private checkForUpdateService: CheckForUpdateService,
        public loadingService: NdlLoadingService,

        private helpdeskService: HelpdeskService
    ) {
        this.checkForUpdateService.startVersionCheck();
        this.loadScripts();
        this.refreshCacheKey();
        this.computeBannersPaddingLeft();
        // We load the cached variables
        // this.cacheService.load();
        // We load the current logged user into the authService
        this.authService.load(true);
        // Renderer can be instanciated in root component only, so we can store it in a service
        this.templateService.renderer = this.renderer;
        router.events.subscribe((val) => {
            if (val instanceof NavigationEnd && val.id != 1) { // ID != 1 because first loading fire a Real Pageview
                gtag('event', 'virtualPageView', {
                    page_path: router.url,
                    page_title: titleService.getTitle(),
                    page_location: window.location.href,
                    send_to: environment.gtmKey
                });
                this.updateFavicon();
                this.computeBannersPaddingLeft();
            }
            if (val instanceof NavigationStart && val.id === 1) { // we need the first loading fire only to fetch the needed params
                // this function is to save some information in cookies to know the source of lead
                this.saveSignUpSource(val);
                this.computeBannersPaddingLeft();
            }
            if (window.location.href.indexOf('/event') === -1) {
                this.templateService.isDisplayNavBarMenu = true;
                this.templateService.isDisplayFooter = true;
            } else {
                this.templateService.isDisplayNavBarMenu = false;
                this.templateService.isDisplayFooter = false;
            }
            // Scroll to Top on changing page. Set disableScrollToTop to true to avoid it
            this.utilsService.scrollToTop(true);
        });
        this.authService.firstAuthQuery.subscribe((v) => {
            if (this.authService.isLogged()) {
                this.filterService.loadFilters();
                //this.filterService.loadAlgoliaFilters();
                this.cacheService.loadCategories();
                this.cacheService.loadLocation();
                // if the user is logged then we don't need signup_source cookie any more
                // so we delete it if it's exist.
                if (this.cookieService.check('signup_source')) {
                    this.cookieService.delete('signup_source');
                }
            }
            this.templateService.isDisplayForcePasswordChange = (this.authService.isLogged() && this.authService.getMember().needlTcAccepted && this.authService.getMember().forcePasswordChange && !this.authService.isMasquerading()) || this.templateService.displayForcePasswordChangeEvenIfMasquerading
                || (this.authService.isLogged() && !this.authService.getMember().hasPassword);
        });
        this.updateFavicon();
    }

    private updateFavicon() {
        let appFavicon = document.getElementById('appFavicon');
        if (window.location.href.indexOf('/event') === -1) {
            appFavicon.setAttribute('href', '/needl_app.build/favicon.ico');
        }
    }

    private loadScripts() {
        const IS_LOCAL = window.location.hostname.indexOf('local.needl') !== -1;
        let head = document.getElementsByTagName('head')[0];
        let jsGMaps = document.createElement('script');
        jsGMaps.type = 'text/javascript';
        jsGMaps.src = 'https://maps.google.com/maps/api/js?key=' + environment.googleApiKey + '&libraries=places&language=en-US';
        head.appendChild(jsGMaps);
        if (!IS_LOCAL) {
            let jsGTM = document.createElement('script');
            jsGTM.type = 'text/javascript';
            jsGTM.src = 'https://www.googletagmanager.com/gtm.js?id=' + environment.gtmKey;
            head.appendChild(jsGTM);
            let jsGTM_custom = document.createElement('script');
            jsGTM_custom.type = 'text/javascript';
            jsGTM_custom.text = `
window.dataLayer = window.dataLayer || [];

function gtag() {
    dataLayer.push(arguments);
}

gtag('js', new Date());
gtag('config', '${environment.gtmKey}', {'send_page_view': false});
            `;
            head.appendChild(jsGTM_custom);
        } else {
            let jsGTM_custom = document.createElement('script');
            jsGTM_custom.type = 'text/javascript';
            jsGTM_custom.text = `
function gtag() {
}
            `;
            head.appendChild(jsGTM_custom);
        }
    }

    private refreshCacheKey() {
        let cacheKey = this.localStorageService.retrieve('cacheKey');
        if (!cacheKey) {
            cacheKey = {
                current: '',
                previous: '',
            }
        }
        if (cacheKey.current !== environment.cacheKey) {
            cacheKey.previous = cacheKey.current;
            cacheKey.current = environment.cacheKey;
            this.localStorageService.clear();
        }
        this.localStorageService.store('cacheKey', cacheKey);
    }

    @HostListener('window:resize', [])
    onResize() {
        this.computeBannersPaddingLeft();
    }

    computeBannersPaddingLeft() {
        this.appBannersPaddingLeft = '0px';
        if (window.location.href.indexOf('/event') !== -1) {
            this.appBannersPaddingLeft = (window.location.pathname === '/event' || window.location.href.indexOf('selection') !== -1 || window.location.href.indexOf('troubleshooting') !== -1) ?
                '0px' : (window.innerWidth >= 1920 ? '17em' : (window.innerWidth <= 1919 && window.innerWidth >= 700 ? '3.8em' : '0px'));
        }
    }

    saveSignUpSource(event: NavigationStart) {
        const utm_medium = this.router.parseUrl(event.url).queryParamMap.get('utm_medium');
        const utm_source = this.router.parseUrl(event.url).queryParamMap.get('utm_source');
        const utm_campaign = this.router.parseUrl(event.url).queryParamMap.get('utm_campaign');
        const fbclid = this.router.parseUrl(event.url).queryParamMap.get('fbclid');
        let signUpSourceCookie = {};
        if (utm_medium && utm_source && utm_campaign) {
            if (utm_source === 'facebook' && fbclid) {
                signUpSourceCookie = {
                    'utm_medium': utm_medium,
                    'utm_source': utm_source,
                    'utm_campaign': utm_campaign,
                    'fbclid': fbclid,
                    'dateTime': dayjs().toString(),
                    'timezone': dayjs.tz.guess()
                };
            } else {
                signUpSourceCookie = {
                    'utm_medium': utm_medium,
                    'utm_source': utm_source,
                    'utm_campaign': utm_campaign,
                    'dateTime': dayjs().toString(),
                    'timezone': dayjs.tz.guess()
                };
            }
            this.cookieService.set('signup_source', JSON.stringify(signUpSourceCookie));
        }
    }


    updateApplicationVersion() {
        this.checkForUpdateService.refreshApplication();
    }

    ngOnInit() {
        if (environment.salesIQKey) {
            this.helpdeskService.createHelpdesk();
        }
    }
}
