import {
    AfterViewInit,
    Directive,
    NgZone,
    OnDestroy,
    Input,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {CategoryAxis, Chart, DateAxis, DurationAxis, ValueAxis} from '@amcharts/amcharts4/charts';
import {NdlLegendChartComponent} from "./legend-chart/legend-chart.component";

@Directive()
export abstract class NdlChartBaseDirective<T extends Chart> implements AfterViewInit, OnDestroy, OnChanges {
    static nextId = 1;

    id = "chart" + NdlChartBaseDirective.nextId++;
    chart: T;
    type: (new () => T);
    config: any;
    @Input() data: any[];
    @Input() legend: NdlLegendChartComponent;
    @Input() showExportButton = true;
    @Input() reloadWholeChart = false;

    constructor(private zone: NgZone) {}

    ngAfterViewInit() {
        this.zone.runOutsideAngular(() => {
            am4core.useTheme(am4themes_animated);
            am4core.addLicense('ch-custom-attribution');
            this.chart = am4core.create(this.id, this.type);
            this.chart.data = this.data;
            if (this.showExportButton) {
                this.chart.exporting.menu = new am4core.ExportMenu();
            }
            if (this.legend) {
                this.chart.legend = new am4charts.Legend();
            }
            this.setConfig();
            if (this.legend) {
                this.chart.legend.parent = this.legend.init();
            }
        });
    }

    ngOnDestroy() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    getAxes(axis: NdlAxis) {
        switch (axis.type) {
            case 'Category':
                const amAxis = new CategoryAxis();
                amAxis.dataFields.category = axis.type;
                return amAxis;
            case 'Date':
                return new DateAxis();
            case 'Duration':
                return new DurationAxis();
            case 'Value':
                return new ValueAxis();
        }
    }

    abstract setConfig(): void;
    abstract reloadSeries(): void;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['data'] && this.chart) {
            this.chart.data = this.data;
            if (this.reloadWholeChart) {
                this.reloadSeries();
            }
        }
        if (changes['showExportButton'] && this.chart) {
            if (this.showExportButton) {
                this.chart.exporting.menu = new am4core.ExportMenu();
            }
        }
    }
}

export interface NdlAxis {
    type: "Category"|"Date"|"Duration"|"Value";
    identifier?: string;
    title?: string;
}
