<div class="multi-select">
    <div class="input-trigger"
         (click)="valueInput.focus()" [class.opened]="isListOpened && value && value.length" [class.error]="showError">
        <div class="input-tags-container">
            <ng-container>
                <span class="input-trigger-open" (click)="showErrorTooManySelectionsAfterClick = $event.target['className'].includes('disabled')">
                    <input class="input-search-value" type="text" (ngModelChange)="searchValueChanged.emit($event)"
                           [(ngModel)]="value" #valueInput placeholder="{{placeholder}}"/>
                </span>
            </ng-container>
            <span *ngIf="isLoading"><ndl-spinner></ndl-spinner></span>
            <span *ngIf="value" (click)="clear(); isListOpened = !isListOpened" class="material-icons input-clear">clear</span>
        </div>
    </div>
    <div *ngIf="isListOpened && value && value.length && options && options.length" class="input-list">
        <span class="input-list-title">{{inputListTitle}}</span>
        <div class="input-list-items">
            <div *ngFor="let option of options" class="input-list-item" title="{{option}}" (click)="selectOption(option)">
                <span class="material-icons-outlined">add_circle_outline</span>
                <span class="input-list-item-label">{{option}}</span>
            </div>
        </div>
    </div>
    <p class="text-danger" *ngIf="showError">{{ errorText }}</p>
</div>
