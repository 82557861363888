<ndl-form-field #formField [prefix]="prefix" [suffix]="suffix" [label]="label" [hint]="hint" [errors]="errors">
    <ng-template #prefix>
        <ng-content select="[ndl-prefix]"></ng-content>
    </ng-template>

    <ndl-phone-field [ngControl]="ngControl" [required]="required"
                     [errorState]="innerFormControl?.errors && innerFormControl?.touched" [placeholder]="placeholder"
                     (change)="onChange($event.target.value)" (blur)="onTouched()">
    </ndl-phone-field>

    <ng-template #suffix>
        <button mat-icon-button type="button" *ngIf="!empty && !disabled" (click)="clear()">
            <mat-icon>close</mat-icon>
        </button>
        <ng-content select="[ndl-suffix]"></ng-content>
    </ng-template>

    <ng-container *ngIf="!ngControl?.control?.errors?.required && error">
        <ndl-error>{{error}}</ndl-error>
    </ng-container>
</ndl-form-field>
