import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {VideoMeetingRequest} from "../../../services/wabel-client/entities/video_meeting_request";
import {VideoMeetingService} from "../../../services/wabel-client/services/video_meeting.service";
import {UtilsService} from "../../../services/utils.service";
import {AuthService} from "../../../services/auth.service";
import * as dayjs from 'dayjs';
import * as utc from "dayjs/plugin/utc";
import * as timezone from "dayjs/plugin/timezone";
import {LiveVideoMeetingService} from "../../../services/wabel-client/services/live_video_meeting.service";
import {NdlStatus} from 'needl-lib/core/types';
import {VideoMeetingParticipant} from '../../../services/wabel-client/entities/video_meeting_participant';

@Component({
    selector: 'app-video-meeting-request-card',
    templateUrl: './video-meeting-request-card.component.html',
    styleUrls: ['./video-meeting-request-card.component.scss']
})
export class VideoMeetingRequestCardComponent implements OnInit {
    @Input() videoMeetingRequest: VideoMeetingRequest;

    @Output() goToMyAvailability = new EventEmitter();
    @Output() newVideoMeetingRequestSent = new EventEmitter();
    @Output() videoMeetingCanceled = new EventEmitter();

    view: 'supplier'|'buyer';
    categoriesDisplayed: string[];
    categoriesHidden: string[];
    categoriesLimit = 6;
    dayjs = dayjs;

    constructor(public videoMeetingService: VideoMeetingService,
                public utilsService: UtilsService,
                public authService: AuthService,
                public liveVideoMeetingService: LiveVideoMeetingService) {
    }

    ngOnInit() {
        dayjs.extend(utc);
        dayjs.extend(timezone);
        this.view = (this.videoMeetingRequest.company.idcompany === this.authService.getMember().company.idcompany) ? 'supplier' : 'buyer';
        if (this.view === 'buyer') {
            this.categoriesDisplayed = this.videoMeetingRequest.counterPartCompany.categories.slice(0, this.categoriesLimit).map(cat => cat.name);
            this.categoriesHidden = this.videoMeetingRequest.counterPartCompany.categories.slice(this.categoriesLimit - 1, this.videoMeetingRequest.counterPartCompany.categories.length).map(cat => cat.name);
        } else {
            this.categoriesDisplayed = this.videoMeetingRequest.initiatorMember.buyingScopeCategories.slice(0, this.categoriesLimit).map(cat => cat.name);
            this.categoriesHidden = this.videoMeetingRequest.initiatorMember.buyingScopeCategories.slice(this.categoriesLimit - 1, this.videoMeetingRequest.initiatorMember.buyingScopeCategories.length).map(cat => cat.name);
        }
    }

    getReadableStatus() {
        if (this.videoMeetingRequest.meeting?.status === 'live' || (this.liveVideoMeetingService.videoMeetingForBanner && this.videoMeetingRequest.meeting?.id === this.liveVideoMeetingService.videoMeetingForBanner?.id)) {
            return 'Live';
        } else if (this.videoMeetingRequest.meeting?.status === 'finished') {
            return 'Finished';
        }
        switch (this.videoMeetingRequest.status) {
            case 'canceled':
                return 'Canceled';
            case 'proposition_sent':
                if (this.view === 'buyer') {
                    return 'Proposition sent';
                } else {
                    return 'To validate';
                }
            case 'to_review':
                if (this.view === 'supplier') {
                    return 'Proposition sent';
                } else {
                    return 'To validate';
                }
            case 'accepted':
                return 'Meeting scheduled';
            case 'declined':
                return 'Declined';
        }
    }

    getColorStatus() {
        switch (this.getReadableStatus()) {
            case 'Meeting scheduled':
            case 'Live':
                return 'green';
            case 'Canceled':
            case 'Declined':
                return 'dark-blue';
            case 'Finished':
            case 'Proposition sent':
                return 'grey';
            case 'To validate':
                return 'orange';
        }
    }

    getBorderStatus(): NdlStatus {
        switch (this.getReadableStatus()) {
            case 'Live':
                return 'success';
            case 'To validate':
                return 'warning';
            default:
                return null;
        }
    }

    canCurrentUserMakeActions(): boolean {
        return (
            this.videoMeetingRequest.initiatorMember.idmember === this.authService.getMember().idmember ||
            this.videoMeetingRequest.responderMember?.idmember === this.authService.getMember().idmember ||
            (!this.videoMeetingRequest.responderMember && this.videoMeetingRequest.company.idcompany === this.authService.getMember().company.idcompany)
        );
    }

    isCurrentMemberParticipant(videoMeetingParticipants: VideoMeetingParticipant[]) {
        return videoMeetingParticipants.some((vmp) => vmp.member.idmember === this.authService.getMember().idmember);
    }
}
