import gql from "graphql-tag";

export class ResourceGraphQL {
  static fragments = {};

  static queries = {

    predefinedMessagesTopics: gql`
        query predefinedMessagesTopics {
            predefinedMessagesTopics {
                title,
                content,
                companytypes {
                    idcompanytype
                }
            }
        }
    `,

      companyRelationshipStatuses: gql`
        query companyRelationshipStatuses {
            companyRelationshipStatuses {
                idrelationshipStatus
                name
                color
                tags {
                    idtagreference
                    name
                    tagGroup
                }
            }
        }
    `,

      customContent: gql`
        query customContent($name: String!) {
            customContent(name: $name) {
                idcustomcontent
                content
                name
            }
        }
    `,

      timezones: gql`
        query timezones {
            timezones
        }
    `,
  };

  static mutations = {
    uploadFile: gql`
        mutation UploadFile($file: Upload!) {
            uploadFile(file: $file) {
                fileName,
                filePath,
                fileSize,
                fileExtension,
                success
            }
        }
    `,
    uploadFiles: gql`
        mutation UploadFiles($files: [Upload!]!) {
            uploadFiles(files: $files) {
                fileName
                filePath
            }
        }
    `,
    storeLogActivity: gql`
        mutation storeLogActivity($type: String!, $idCompany: Int, $idUserproduct: Int, $idAd: Int, $value: String, $idEventWorkshop: Int, $eventCode: String, $targetIdMember: Int, $threadId: Int, $idTag: Int) {
            storeLogActivity(type: $type, idCompany: $idCompany, idUserproduct: $idUserproduct, idAd: $idAd, value: $value, idEventWorkshop: $idEventWorkshop, eventCode: $eventCode, targetIdMember: $targetIdMember, threadId: $threadId, idTag: $idTag)
        }
    `,
      storeLogActivityApparitions: gql`
          mutation storeLogActivityApparitions($type: String!, $value: String!, $idCompanies: [Int!], $idProducts: [Int!], $eventCode: String) {
              storeLogActivityApparitions(type: $type, value: $value, idCompanies: $idCompanies, idProducts: $idProducts, eventCode: $eventCode)
          }
    `,

      storeAlgoliaLogActivity: gql`
          mutation storeAlgoliaLogActivity($type: String!, $value: String, $eventCode: String) {
              storeAlgoliaLogActivity(type: $type, value: $value, eventCode: $eventCode)
          }
      `,
  };
}
