import gql from "graphql-tag";
import {TagTypeService} from "../services/tag_type.service";
import {TagTypeGraphql} from "./tag_type.graphql";
import {SOURCING_REQUEST_COUNTRY_TYPES} from "../sourcing_request_country_types.const";

export class SourcingRequestGraphql {
    static fragments = {
        fullSourcingRequest: gql`
            fragment fullSourcingRequest on SourcingRequest {
                id
                name
                createdDate
                status
                productSpec
                volume
                blacklistedCompanies {
                    items {
                        company {
                            name
                        }
                        name
                    }
                }
                openToOthersLocations
                packageWidth
                packageHeight
                packageLength
                expiryDate
                reviewStartDate
                reviewEndDate
                isExpired
                packagingValue
                packagingUnit {
                    idpackagingunit
                    name
                }
                preferredSupplierCountries: countries(type: "${SOURCING_REQUEST_COUNTRY_TYPES.PREFERRED_SUPPLIER_LOCATION}") {
                    items {
                        id
                        name
                    }
                }
                distributionCountries: countries(type: "${SOURCING_REQUEST_COUNTRY_TYPES.BUYER_DISTRIBUTION_COUNTRY}") {
                    items {
                        id
                        name
                    }
                }
                pricePositioning: tagsByInternalName(tagTypeInternalName: "${TagTypeService.PRICE_RANGE_INTERNAL_NAME}") {
                    items {
                        ...tag
                    }
                }
                certificationsAndLabels: tagsByInternalNames(tagTypeInternalNames: ["${TagTypeService.CERTIFICATION_INTERNAL_NAME}", "${TagTypeService.QUALITY_LABEL_INTERNAL_NAME}"]) {
                    items {
                        ...tag
                    }
                }
                productAttributes: tagsByInternalNames(tagTypeInternalNames: ["${TagTypeService.RANGE_OF_PRODUCT_INTERNAL_NAME}", "${TagTypeService.NUTRITIONAL_INFORMATION_INTERNAL_NAME}", "${TagTypeService.HEALTH_AND_WELLNESS_INTERNAL_NAME}"]) {
                    items {
                        ...tag
                    }
                }
                activities: tagsByInternalName(tagTypeInternalName: "${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
                    items {
                        ...tag
                    }
                }
                categories: tagsByInternalName(tagTypeInternalName: "${TagTypeService.CATEGORY_INTERNAL_NAME}") {
                    items {
                        ...tag
                        parent {
                            parent {
                                id
                            }
                        }
                    }
                }
                volumeTags: tagsByInternalName(tagTypeInternalName: "${TagTypeService.VOLUME_INTERNAL_NAME}") {
                    items {
                        ...tag
                    }
                }
                keywords {
                    items {
                        keyword
                    }
                }
                memberBuyer {
                    idmember
                    company {
                        name
                    }
                }
                distributionChannels
                purchasingPriceRange
                recommendationsToReviewCount: recommendationsCountByBuyerInterestStatus(buyerInterestStatus: "pending")
                interestedSourcingRequestRecommendations {
                    count
                    items {
                        buyerInterestStatus
                        sourcingRequest {
                            id
                        }
                        company {
                            idcompany
                            urlname
                            name
                            logoUrl(size: "123")
                            ca {
                                ...tag
                            }
                            country {
                                name
                            }
                            urlname
                            rootCategories: tags(tagTypeInternalName: "${TagTypeService.CATEGORY_INTERNAL_NAME}", onlyLevel1: true) {
                                items {
                                    ...tag
                                    machineName
                                }
                            }
                            activities: tags(tagTypeInternalName:"${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
                                items {
                                    ...tag
                                }
                            }
                            companyDistributionChannelsReference: tags(tagTypeInternalName:"${TagTypeService.DISTRIBUTION_CHANNEL_INTERNAL_NAME}") {
                                items {
                                    ...tag
                                }
                            }
                            certifications: tags(tagTypeInternalName:"${TagTypeService.CERTIFICATION_INTERNAL_NAME}") {
                                items {
                                    ...tag
                                }
                            }
                            minimumOrderQuantities: tags(tagTypeInternalName: "${TagTypeService.MOQ_INTERNAL_NAME}") {
                                items {
                                    ...tag
                                }
                            }
                        }
                        prioritySourcingRequestMemberResponse {
                            statusCustomReason
                        }
                        privateMessage {
                            thread {
                                idthread
                            }
                        }
                    }
                }
                file {
                    url
                    fileName,
                    fileExtension,
                    fileSize
                }
            }
            ${TagTypeGraphql.fragments.tag}
        `,
    };

    static queries = {
        mySourcingRequests: gql`
            query mySourcingRequests($offset: Int, $limit: Int) {
                mySourcingRequests {
                    count
                    items(offset: $offset, limit: $limit) {
                        id
                        name
                        status
                        createdDate
                        expiryDate
                        recommendationsToReviewCount: recommendationsCountByBuyerInterestStatus(buyerInterestStatus: "pending")
                        interestedSourcingRequestRecommendations {
                            count
                        }
                    }
                }
            }
        `,

        sourcingRequestById: gql`
            query sourcingRequestById($id: Int!) {
                sourcingRequestById(id: $id) {
                    ...fullSourcingRequest
                }
            }
            ${SourcingRequestGraphql.fragments.fullSourcingRequest}
        `,

        sourcingRequestByIdForProductRequestRegistration: gql`
            query sourcingRequestByIdForProductRequestRegistration($productRequestId: Int!) {
                sourcingRequestByIdForProductRequestRegistration(productRequestId: $productRequestId) {
                    ...fullSourcingRequest
                }
            }
            ${SourcingRequestGraphql.fragments.fullSourcingRequest}
        `,

        totalNumberOfActiveSourcingNeedAndSourcingRequest: gql`
            query totalNumberOfActiveSourcingNeedAndSourcingRequest {
               totalNumberOfActiveSourcingNeedAndSourcingRequest
            }
        `,

        sourcingRequestRecommendations: gql`
            query sourcingRequestById($id: Int!, $limit: Int!, $offset: Int!) {
                sourcingRequestById(id: $id) {
                    interestedSourcingRequestRecommendations {
                        items(limit: $limit, offset: $offset) {
                            buyerInterestStatus
                            sourcingRequest {
                                id
                            }
                            company {
                                idcompany
                                urlname
                                name
                                logoUrl(size: "123")
                                ca {
                                    ...tag
                                }
                                country {
                                    name
                                }
                                citylocation {
                                    country {
                                        name
                                    }
                                }
                                urlname
                                categories: tags(tagTypeInternalName: "${TagTypeService.CATEGORY_INTERNAL_NAME}") {
                                    items {
                                        ...tag
                                        depth
                                        machineName
                                        parent {
                                            internalName
                                        }
                                        type {
                                            name
                                            internalName
                                        }
                                    }
                                }
                                activities: tags(tagTypeInternalName:"${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
                                    items {
                                        ...tag
                                        type {
                                            name
                                            internalName
                                        }
                                    }
                                }
                                priceRanges: tags(tagTypeInternalName:"${TagTypeService.PRICE_RANGE_INTERNAL_NAME}") {
                                    items {
                                        ...tag
                                        type {
                                            name
                                            internalName
                                        }
                                    }
                                }
                                companyDistributionChannelsReference: tags(tagTypeInternalName:"${TagTypeService.DISTRIBUTION_CHANNEL_INTERNAL_NAME}") {
                                    items {
                                        ...tag
                                        type {
                                            name
                                            internalName
                                        }
                                    }
                                }
                                certifications: tags(tagTypeInternalName:"${TagTypeService.CERTIFICATION_INTERNAL_NAME}") {
                                    items {
                                        ...tag
                                        type {
                                            name
                                            internalName
                                        }
                                    }
                                }
                                minimumOrderQuantities: tags(tagTypeInternalName: "${TagTypeService.MOQ_INTERNAL_NAME}") {
                                    items {
                                        ...tag
                                        type {
                                            name
                                            internalName
                                        }
                                    }
                                }
                                products(published: true) {
                                    name
                                    category {
                                        id
                                        name
                                        parent {
                                            id
                                            parent {
                                                id
                                                machineName
                                            }
                                        }
                                    }
                                    logoUrl(size: "123")
                                }
                            }
                            prioritySourcingRequestMemberResponse {
                                statusCustomReason
                            }
                            privateMessage {
                                thread {
                                    idthread
                                }
                            }
                        }
                    }
                }
            }
            ${TagTypeGraphql.fragments.tag}
        `,

        sourcingRequestByIdForSupplier: gql`
            query sourcingRequestById($id: Int!, $idCompany: Int!, $idMember: Int!) {
                sourcingRequestById(id: $id) {
                    ...fullSourcingRequest
                    sourcingRequestRecommendationByCompanyId(idCompany: $idCompany) {
                        priorityContact {
                            idmember
                            fullname
                        }
                        nbMemberResponsesNotInterested: sourcingRequestMemberResponsesByStatus(status: "not_interested") {
                            count
                        }
                    }
                    sourcingRequestMemberResponseByMemberId(idMember: $idMember) {
                        status
                    }
                }
            }
            ${SourcingRequestGraphql.fragments.fullSourcingRequest}
        `,

        sourcingNeedByIds: gql`
            query sourcingNeedByIds($ids: [Int!]!) {
                sourcingNeedByIds(ids: $ids) {
                    id
                    searchEngineCalled
                }
            }
        `,

        myBuyerNeedsSourcingRequestsRecommendations: gql`
            query myBuyerNeedsSourcingRequestsRecommendations {
                myBuyerNeedsSourcingRequestsRecommendations {
                    items {
                        id
                        apparitionDateSeller
                        matchingScore
                        product {
                            name
                        }
                        mySourcingNeedRecommendationMemberResponse {
                            status
                        }
                        sourcingNeed {
                            categorytag {
                                name
                            }
                            daysLeft
                            member {
                                company {
                                    idcompany
                                    name
                                    logoUrl(size: "300")
                                    urlname
                                    companytype {
                                        name
                                    }
                                    country {
                                        name
                                    }
                                    ca {
                                        name
                                    }
                                }
                            }
                            activities: tags(tagTypeInternalNames: ["${TagTypeService.ACTIVITY_INTERNAL_NAME}"]) {
                                items {
                                    name
                                }
                            }
                            certifications: tags(tagTypeInternalNames: ["${TagTypeService.CERTIFICATION_INTERNAL_NAME}"]) {
                                items {
                                    name
                                }
                            }
                            specifications: tags(tagTypeInternalNames: ["${TagTypeService.PRICE_RANGE_INTERNAL_NAME}", "${TagTypeService.NUTRITIONAL_INFORMATION_INTERNAL_NAME}"]) {
                                items {
                                    name
                                }
                            }
                        }
                    }
                }
            }
        `,

        getMyMarketBusinessOpportunities: gql`
            query getMyMarketBusinessOpportunities($page: Int!) {
                myMarketBusinessOpportunities(page: $page)
            }
        `,
    };

    static mutations = {
        saveSourcingRequest: gql`
            mutation saveSourcingRequest(
                $sourcingRequest: SourcingRequestInput!,
                $projectDeadline: String,
                $activities: [Int!]!,
                $certificationsAndLabels: [Int!]!,
                $productAttributes: [Int!]!,
                $distributionCountries: [Int!]!,
                $preferredSupplierCountries: [Int!]!,
                $openToOthersLocations: Boolean!,
                $pricePositioning: [Int!]!,
                $volumeTags: [Int!]!,
                $volume: String,
                $purchasingPriceRange: String,
                $packagingValue: String,
                $packagingUnit: Int,
                $packageLength: Int,
                $packageWidth: Int,
                $packagingHeight: Int,
                $productSpec: String,
                $blackListedCompanies: [Int!]!,
                $customBlackListedCompanies: [String!]!,
                $file: FileToUploadInput,
                $reviewStartDate: String!,
                $reviewEndDate: String
            ) {
                saveSourcingRequest(
                    sourcingRequest: $sourcingRequest,
                    projectDeadline: $projectDeadline,
                    activities: $activities,
                    certificationsAndLabels: $certificationsAndLabels,
                    productAttributes: $productAttributes,
                    distributionCountries: $distributionCountries,
                    preferredSupplierCountries: $preferredSupplierCountries,
                    openToOthersLocations: $openToOthersLocations,
                    pricePositioning: $pricePositioning,
                    volumeTags: $volumeTags,
                    volume: $volume,
                    purchasingPriceRange: $purchasingPriceRange,
                    packagingValue: $packagingValue,
                    packagingUnit: $packagingUnit,
                    packageLength: $packageLength,
                    packageWidth: $packageWidth,
                    packagingHeight: $packagingHeight,
                    productSpec: $productSpec,
                    blackListedCompanies: $blackListedCompanies,
                    customBlackListedCompanies: $customBlackListedCompanies,
                    file: $file,
                    reviewStartDate: $reviewStartDate,
                    reviewEndDate: $reviewEndDate
                ) {
                    ...fullSourcingRequest
                }
            }
            ${SourcingRequestGraphql.fragments.fullSourcingRequest}
        `,
        saveSourcingRequestAdmin: gql`
            mutation saveSourcingRequestAdmin(
                $sourcingRequest: SourcingRequestInput!,
                $name: String!,
                $distributionChannels: String!,
                $categories: [Int!]!,
                $keywords: [String!],
                $projectDeadline: String,
                $activities: [Int!],
                $certificationsAndLabels: [Int!],
                $productAttributes: [Int!],
                $distributionCountries: [Int!],
                $preferredSupplierCountries: [Int!],
                $openToOthersLocations: Boolean!,
                $pricePositioning: [Int!],
                $volumeTags: [Int!],
                $volume: String,
                $purchasingPriceRange: String,
                $packagingValue: String,
                $packagingUnit: Int,
                $packageLength: Int,
                $packageWidth: Int,
                $packagingHeight: Int,
                $productSpec: String,
                $blackListedCompanies: [Int!],
                $customBlackListedCompanies: [String!],
                $file: FileToUploadInput,
                $sendMail: Boolean!,
                $reviewStartDate: String,
                $reviewEndDate: String
            ) {
                saveSourcingRequestAdmin(
                    sourcingRequest: $sourcingRequest,
                    name: $name,
                    distributionChannels: $distributionChannels,
                    categories: $categories,
                    keywords: $keywords,
                    projectDeadline: $projectDeadline,
                    activities: $activities,
                    certificationsAndLabels: $certificationsAndLabels,
                    productAttributes: $productAttributes,
                    distributionCountries: $distributionCountries,
                    preferredSupplierCountries: $preferredSupplierCountries,
                    openToOthersLocations: $openToOthersLocations,
                    pricePositioning: $pricePositioning,
                    volumeTags: $volumeTags,
                    volume: $volume,
                    purchasingPriceRange: $purchasingPriceRange,
                    packagingValue: $packagingValue,
                    packagingUnit: $packagingUnit,
                    packageLength: $packageLength,
                    packageWidth: $packageWidth,
                    packagingHeight: $packagingHeight,
                    productSpec: $productSpec,
                    blackListedCompanies: $blackListedCompanies,
                    customBlackListedCompanies: $customBlackListedCompanies,
                    file: $file
                    sendMail: $sendMail,
                    reviewStartDate: $reviewStartDate,
                    reviewEndDate: $reviewEndDate
                ) {
                    id
                    status
                }
            }
        `,

        updateSourcingRequestStatus: gql`
            mutation updateSourcingRequestStatus($idSourcingRequest: Int!, $newRequestStatus: String!) {
                updateSourcingRequestStatus(idSourcingRequest: $idSourcingRequest, newRequestStatus: $newRequestStatus) {
                    id
                }
            }
        `,

        saveAndSendSourcingRequest: gql`
            mutation saveAndSendSourcingRequest($sourcingRequest: SourcingRequestInput!) {
                saveAndSendSourcingRequest(sourcingRequest: $sourcingRequest) {
                    id
                    status
                }
            }
        `,

        saveSourcingRequestMemberResponseInterested: gql`
            mutation saveSourcingRequestMemberResponseInterested($idSourcingRequest: Int!, $statusCustomReason: String, $secondColleague: Boolean) {
                saveSourcingRequestMemberResponseInterested(idSourcingRequest: $idSourcingRequest, statusCustomReason: $statusCustomReason, secondColleague: $secondColleague) {
                    sourcingRequest {
                        id
                    }
                }
            }
        `,

        saveSourcingRequestMemberResponseNotInterested: gql`
            mutation saveSourcingRequestMemberResponseNotInterested($idSourcingRequest: Int!, $notInterestedStatusReason: String!, $statusCustomReason: String) {
                saveSourcingRequestMemberResponseNotInterested(idSourcingRequest: $idSourcingRequest, notInterestedStatusReason: $notInterestedStatusReason, statusCustomReason: $statusCustomReason) {
                    sourcingRequest {
                        id
                    }
                }
            }
        `,

        updateSourcingRequestNoLongerActive: gql`
            mutation updateSourcingRequestNoLongerActive($idSourcingRequest: Int!, $reason: String!) {
                updateSourcingRequestNoLongerActive(idSourcingRequest: $idSourcingRequest, reason: $reason) {
                    id
                }
            }
        `,

        updateSourcingRequestRecommendationBuyerInterestStatus: gql`
            mutation updateSourcingRequestRecommendationBuyerInterestStatus($idSourcingRequest: Int!, $idCompany: Int!, $newStatus: String!, $reason: String, $statusCustomReason: String) {
                updateSourcingRequestRecommendationBuyerInterestStatus(idSourcingRequest: $idSourcingRequest, idCompany: $idCompany, newStatus: $newStatus, reason: $reason,statusCustomReason: $statusCustomReason) {
                    buyerInterestStatus
                    privateMessage {
                        thread {
                            idthread
                        }
                    }
                    company {
                        idcompany
                    }
                }
            }
        `,

        saveSourcingNeed: gql`
            mutation saveSourcingNeed($categories: [Int!]!, $activities: [Int!]!, $timeline: String!, $productSpecifications: [Int!]!, $certifications: [Int!]!, $locations: [Int!]!, $deadline: String) {
                saveSourcingNeed(categories: $categories, activities: $activities, timeline: $timeline, productSpecifications: $productSpecifications, certifications: $certifications, locations: $locations, deadline: $deadline) {
                    id
                    categorytag {
                        name
                    }
                }
            }
        `,

        answerToSourcingNeedRecommendationAsSeller: gql`
            mutation answerToSourcingNeedRecommendationAsSeller($idSourcingNeedRecommendation: Int!, $status: String!, $reason: String) {
                answerToSourcingNeedRecommendationAsSeller(idSourcingNeedRecommendation: $idSourcingNeedRecommendation, status: $status, reason: $reason) {
                    status
                }
            }
        `,

        updateIsNewForBusinessOpportunities: gql`
            mutation updateIsNewForBusinessOpportunities($idsSourcingNeedRecommendation: [Int!]!,$idsSourcingRequest: [Int!]!) {
                updateIsNewForBusinessOpportunities(idsSourcingNeedRecommendation: $idsSourcingNeedRecommendation, idsSourcingRequest: $idsSourcingRequest)
            }
        `,
    };
}
