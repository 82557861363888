import {
    AfterContentInit, ContentChild, ContentChildren,
    Directive, ElementRef, Input, QueryList
} from '@angular/core';
import {NdlOptionGroupDirective} from './optiongroup.directive';

@Directive({
    selector: 'option[ndl-option], option[ndlOption]'
})
export class NdlOptionDirective<T = any> implements NdlOption<T>, AfterContentInit {
    label: string;
    @Input() value: T;

    constructor(private elementRef: ElementRef) {
    }

    ngAfterContentInit() {
        this.label = this.elementRef.nativeElement.innerHTML;
    }
}

export interface NdlOption<T> {
    label: string;
    value: T;
}
