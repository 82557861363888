<div class="ndl-phone-field">
    <div class="ndl-phone-field-country-wrapper">
        <mat-select disableOptionCentering color='primary' [disabled]='disabled' [(ngModel)]="country" class="ndl-phone-field-country" panelClass="ndl-select-panel-class" (openedChange)="searchInput.focus()" (focusin)="onFocusIn(false)" (focusout)="onFocusOut(false)">
            <mat-select-trigger>
                <span class="flag-icon flag-icon-{{ getFlag(country) }}"></span>
            </mat-select-trigger>
            <mat-option disabled class="ndl-phone-field-search">
                <input matInput #searchInput [(ngModel)]="search" placeholder="Search" autocomplete="false"/>
            </mat-option>
            <mat-option *ngFor="let flag of countries" [value]="flag">
                <span class="flag-icon flag-icon-{{ getFlag(flag) }}"></span> {{flag}} <span class="ndl-phone-search-code">+{{getCountryIndicator(flag)}}</span>
            </mat-option>
        </mat-select>

        <mat-divider vertical></mat-divider>
    </div>
    <input #tel class="ndl-phone-field-number" [disabled]='disabled' matInput [ngModel]="_formattedNumber"
           (ngModelChange)="transformTextToPhoneNumber($event)" [required]="required" [placeholder]="placeholder"
           (focusin)="onFocusIn()" (focusout)="onFocusOut()"/>
</div>
