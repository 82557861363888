import {WabelEntity} from "./wabel-entity.abstract";

export class File extends WabelEntity {
    constructor(obj: any) {
        super(obj);
    }

    idfile: number;
    url: string;
    description: string;
    filePath: string;
    dateCreated: Date;
    fileName: string;
    fileExtension: string;
    fileSize: number;
    isLogo: boolean;
    get id(): number {
        return this.idfile;
    }

    set id(id: number) {
        this.idfile = id;
    }

    get formattedCreatedDate(): string {
        return new Date(this.dateCreated).toLocaleDateString();
    }

    get fileExt(): string {
        return this.fileExtension;
    }

    set fileExt(extension: string) {
        this.fileExtension = extension;
    }

    get downloadLink(): string {
        return this.url;
    }

    set downloadLink(url: string) {
        this.url = url;
    }
    get imageUrl(): string {
        return this.url;
    }
    previewSelected(): boolean {
        return this.isLogo;
    }

    previewUrl(): string {
        return this.imageUrl ? this.imageUrl : this.downloadLink;
    }

    previewExtension(): string {
        return this.fileExtension;
    }

    previewFileName(): string {
        return this.fileName;
    }

    previewFileSize(): number {
        return this.fileSize;
    }

    previewId(): string {
        return "" + this.idfile;
    }


}
