import {Component} from '@angular/core';
import {NdlLoadingService} from 'needl-lib';

@Component({
    selector: 'app-loader',
    templateUrl: './app-loader.component.html',
    styleUrls: ['./app-loader.component.scss'],
    host: {
        'class': 'app-loader'
    }
})
export class AppLoaderComponent {
    constructor(public loadingService: NdlLoadingService) {
    }
}
