import {WabelEntity} from "./wabel-entity.abstract";
import {Member} from "./member";
import {Company} from "./company";
import {VideoMeeting} from "./video_meeting";

export class VideoMeetingRequest extends WabelEntity {
    constructor(obj: any) {
        super(obj);
        this.initiatorMember = obj.initiatorIdMember ? new Member(obj.initiatorIdMember) : null;
        this.responderMember = obj.responderIdMember ? new Member(obj.responderIdMember) : null;
        this.company = obj.company ? new Company(obj.company) : null;
        this.meeting = obj.meeting ? new VideoMeeting(obj.meeting) : null;
        this.counterPartCompany = obj.counterPartCompany ? new Company(obj.counterPartCompany) : null;
        this.counterPartMember = obj.counterPartMember ? new Member(obj.counterPartMember) : null;
    }

    id: number;
    initiatorMember: Member;
    responderMember: Member;
    company: Company;
    duration: number;
    status: 'proposition_sent'|'to_review'|'canceled'|'accepted'|'declined';
    statusReason: string;
    meeting: VideoMeeting;
    dateLastProposition: string;
    counterPartCompany: Company;
    counterPartMember: Member;
}
