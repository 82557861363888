<ndl-form-field #formField [prefix]="prefix" [suffix]="suffix" [label]="label" [hint]="hint" [errors]="errors">
    <ng-template #prefix>
        <mat-icon>lock_outline</mat-icon>
    </ng-template>

    <input matInput [formControl]="innerFormControl" [type]="type" [required]="required" [placeholder]="placeholder"
           (blur)="onTouched()" (change)="onChange($event.target.value)"/>

    <ng-template #suffix>
        <ng-container *ngIf="!formField.empty">
            <button mat-icon-button type="button" (click)="clear()">
                <mat-icon>close</mat-icon>
            </button>
            <button mat-icon-button type="button" (click)="toggleType()">
                <mat-icon>{{ type === "password" ? "visibility" : "visibility_off" }}</mat-icon>
            </button>
        </ng-container>
    </ng-template>

    <ng-container *ngIf="!ngControl?.control?.errors?.required">
        <ndl-error *ngIf="ngControl?.control?.errors?.pattern">The password must contain at least 8 characters.
        </ndl-error>
        <ndl-error *ngIf="ngControl?.control?.errors?.mismatch">The password verification doesn't match.</ndl-error>
    </ng-container>
</ndl-form-field>
