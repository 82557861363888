import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../../services/auth.service";
import {collapseAnimation} from "../../../animations/collapse.animation";
import {MemberService} from "../../../services/wabel-client/services/member.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {TemplateService} from "../../../services/template.service";
import {filter} from "rxjs/operators";
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import {environment} from "../../../../environments/environment";
import {UtilsService} from "../../../services/utils.service";
import {RIGHT} from "../../../services/wabel-client/security/right.const";
import {LiveVideoMeetingService} from "../../../services/wabel-client/services/live_video_meeting.service";
dayjs.extend(timezone);

@Component({
    selector: 'app-banners',
    templateUrl: './app-banners.component.html',
    animations: [collapseAnimation]
})
export class AppBannersComponent implements OnInit {

    authService: AuthService;
    emailVerificationState = 'before';
    timeZoneUpdateState = 'before';
    autoUpdated = false;

    successMessage: string = null;
    errorMessage: string = null;
    needl_app_full_url = environment.needl_back_end_url;
    readonly RIGHT = RIGHT;

    constructor(
        private _authService: AuthService,
        private memberService: MemberService,
        private route: ActivatedRoute,
        public router: Router,
        private location: Location,
        public templateService: TemplateService,
        public utilsService: UtilsService,
        public liveVideoMeetingService: LiveVideoMeetingService
    ) {
        this.authService = _authService;
        this.emailVerificationState = 'before';
    }

    sendEmailVerificationLink() {
        if (this.emailVerificationState === 'before') {
            this.emailVerificationState = 'loading';
            this.memberService.sendEmailVerificationLink().subscribe(
                (data) => {
                    if (!data) { return; }
                    if (data.hasEmailVerified) {
                        // If email already verified, means the banner is still displayed, so we reload the user's data
                        this.authService.load(false);
                    } else {
                        this.emailVerificationState = 'after';
                    }
                },
                (error) => {
                    this.emailVerificationState = 'error';
                }
            );
        }
    }

    updateNewTimezone() {

        if (this.isNewerTimezone()) {

            if (this.timeZoneUpdateState === 'before') {
                this.timeZoneUpdateState = 'loading';
                this.memberService.updateUserNewTimezone( dayjs.tz.guess() ).subscribe(
                    (data) => {
                        if (!data) { return; }

                        this.authService.updateTimezone( dayjs.tz.guess() );
                        this.timeZoneUpdateState = 'after';

                    },
                    (error) => {
                        this.timeZoneUpdateState = 'error';
                    }
                );
            }

        }

    }

    isNewerTimezone() {
        if (this.authService.isLogged() && this.authService.getMember().timezone === null && !this.autoUpdated) {
            this.autoUpdated = true;
            this.memberService.updateUserNewTimezone( dayjs.tz.guess() ).subscribe(
                (data) => {
                    if (!data) { return; }
                    this.authService.updateTimezone( dayjs.tz.guess() );
                });
        }

        return this.authService.getMember().timezone !== dayjs.tz.guess();
    }

    newTimezone() {
        return dayjs.tz.guess();
    }

    ngOnInit(): void {
        this.route.queryParams
            .pipe(filter(params => params.success))
            .subscribe(params => {
                this.successMessage = params.success;
                this.location.replaceState(this.location.path().split('?')[0]);
            });
        this.route.queryParams
            .pipe(filter(params => params.errMsg))
            .subscribe(params => {
                this.errorMessage = params.errMsg;
                this.location.replaceState(this.location.path().split('?')[0]);
            });
    }

    hasCompletedAllRewardSections() {
        let allCompleted = true;
        const company = this.authService.getMember().company;
        company.companyRewardScore.children.forEach(s => {
            if (s.value === 0) {
                if (s.name === 'Company Ecopack Project' && company.hasAddedAllEcoPackProjects) {
                    return;
                }
                if (s.name === 'Company Products' && company.hasAddedAllProducts) {
                    return;
                }
                if (s.name === 'Company Export Diagnosis' && company.hasAddedAllClients) {
                    return;
                }
                allCompleted = false;
            }
        });

        return allCompleted;
    }
}
