<div class="ndl-placeholder d-flex" [ngClass]="{
    'justify-content-start': align === 'left',
    'justify-content-end': align === 'right',
    'justify-content-center': align === 'center'
}">
    <div class="ndl-placeholder-animated-background"
         [style.width]="width"
         [style.height]="height"
         [style.border-radius]="radius"
    >
    </div>
</div>
