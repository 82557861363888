import {Directive, Input} from '@angular/core';
import {NdlStatus} from '../core/types';

@Directive({
    selector: '[ndl-card-footer],[ndlCardFooter],ndl-card-footer',
    host: {
        'class': 'ndl-card-footer'
    }
})
export class NdlCardFooterDirective {
}
