import {Injectable} from "@angular/core";
import {WabelService} from "./wabel-service.abstract";
import {LoadingService} from "./loading.service";

import {GraphQLService} from "./graphql-service";
import {ResourceGraphQL} from "../graphql/resource.graphql";
import {PredefinedMessagesTopics} from "../entities/predefined_messages_topics";
import {ResultIterator} from "../entities/result_iterator";
import {FileToUpload} from "../entities/fileToUpload";
import {CompanyRelationshipStatus} from "../entities/company_relationship_status";
import {CustomContent} from "../entities/custom_content";
import {Company} from "../entities/company";
import {Product} from "../entities/product";
import {Member} from "../entities/member";
import {finalize, map, mergeMap, sample} from "rxjs/operators";
import {Ad} from "../entities/ad";
import {EventWorkshop} from "../entities/event_workshop";
import {Observable} from 'rxjs';
import {File as FileUploaded} from '../entities/file';
import {PrivateMessageThread} from "../entities/private_message_thread";
import {FileInterface} from 'needl-lib';
import {Tag} from "../entities/tag";

@Injectable({
    providedIn: 'root'
})
export class ResourceService extends WabelService {

    public static readonly COMPANY_SEARCH_APPARITION = 'company_search_apparition';
    public static readonly COMPANY_CATALOG_APPARITION = 'company_catalog_apparition';
    public static readonly PRODUCT_SEARCH_APPARITION = 'product_search_apparition';
    public static readonly CATALOG_QUERY = 'catalog_query';
    public static readonly SEARCH_QUERY_NEXT_PAGE = 'search_query_next_page';
    public static readonly CATALOG_QUERY_NEXT_PAGE = 'catalog_query_next_page';
    public static readonly CATALOG_QUERY_FILTER = 'catalog_query_filter';
    public static readonly SEARCH_QUERY_FILTER = 'search_query_filter';
    public static readonly SEARCH_QUERY_SUGGESTION = 'search_query_suggestion';
    public static readonly SEARCH_QUERY = 'search_query';
    public static readonly ANALYTICS_DASHBOARD_VISITORS_SEEN = 'analytics_dashboard_visitors_seen';
    public static readonly ANALYTICS_DASHBOARD_VISIBILITY_SEEN = 'analytics_dashboard_visibility_seen';
    public static readonly ANALYTICS_DASHBOARD_MARKET_SEEN = 'analytics_dashboard_market_seen';
    public static readonly ANALYTICS_BANNER_VISITORS_CLICKED = 'analytics_banner_visitors_clicked';
    public static readonly ANALYTICS_BANNER_VISIBILITY_CLICKED = 'analytics_banner_visibility_clicked';
    public static readonly ANALYTICS_BANNER_MARKET_CLICKED = 'analytics_banner_market_clicked';
    public static readonly COMPANY_INSTITUTION_APPARITION = 'company_institution_apparition';
    public static readonly PRODUCT_INSTITUTION_APPARITION = 'product_institution_apparition';
    public static readonly INSTITUTION_QUERY_NEXT_PAGE = 'institution_query_next_page';
    public static readonly INSTITUTION_QUERY_FILTER = 'institution_query_filter';
    public static readonly BUYER_BUSINESS_PROPOSAL_VIEW = 'buyer_business_proposal_view';
    public static readonly RATING_OPEN_NEEDL_COMPANY_PROFILE_LINK_CLICKED = 'rating_open_needl_company_profile_link_clicked';
    public static readonly RATING_OPEN_NEEDL_THREAD_PAGE_LINK_CLICKED = 'rating_open_needl_thread_page_link_clicked';
    public static readonly RATING_GET_BUYER_EMAIL_LINK_CLICKED = 'rating_get_buyer_email_link_clicked';
    public static readonly CLICK_TO_MEMBERSHIP_PAGE = 'click_to_membership_page';

    constructor(protected graphQLService: GraphQLService, protected loadingService: LoadingService) {
        super(graphQLService, loadingService);
    }

    getPredefinedMessagesTopics() {
        return this.query(ResourceGraphQL.queries.predefinedMessagesTopics)
            .pipe(map((data) => data.data ? new ResultIterator<PredefinedMessagesTopics>(data.data.predefinedMessagesTopics.length, data.data.predefinedMessagesTopics.map((item: any) => new PredefinedMessagesTopics(item))) : new ResultIterator<PredefinedMessagesTopics>(0, [])));
    }

    toObject(object) {
        return new PredefinedMessagesTopics(object);
    }

    uploadFile(file: File) {
        return this.mutation(ResourceGraphQL.mutations.uploadFile, {
            file: file
        })
            .pipe(map((data) => data.data ? new FileToUpload(data.data.uploadFile) : null));
    }

    newUploadFile(file: File) {
        return this.mutation(ResourceGraphQL.mutations.uploadFile, {
            file: file
        })
            .pipe(map((data) => data.data.uploadFile as FileInterface));
    }

    uploadFiles(fileList: FileList): Observable<FileUploaded[]> {
        const files = [];
        for (let i = 0; i < fileList.length; i++) {
            files.push(fileList[i]);
        }

        return this.mutation(ResourceGraphQL.mutations.uploadFiles, {
            files: files
        }).pipe(map(data => data.data.uploadFiles));
    }

    getCompanyRelationshipStatusesList() {
        return this.query(ResourceGraphQL.queries.companyRelationshipStatuses)
            .pipe(map((data) => data.data ? new ResultIterator<CompanyRelationshipStatus>(data.data.companyRelationshipStatuses.length, data.data.companyRelationshipStatuses.map((item: any) => new CompanyRelationshipStatus(item))) : new ResultIterator<CompanyRelationshipStatus>(0, [])));
    }

    getCustomContent(name: string) {
        return this.query(ResourceGraphQL.queries.customContent, {name: name})
            .pipe(map((data) => data.data && data.data.customContent ? new CustomContent(data.data.customContent) : null));
    }

    storeLogActivity(type: string, company: Company, product: Product, ad: Ad, value: string, workshop: EventWorkshop = null, eventCode?: string, targetMember?: Member, thread?: PrivateMessageThread, tag?: Tag) {
        return this.mutation(ResourceGraphQL.mutations.storeLogActivity, {
            type: type,
            idCompany: company ? company.idcompany : null,
            idUserproduct: product ? product.iduserproduct : null,
            idAd: ad ? ad.idad : null,
            value: value,
            idEventWorkshop: (workshop ? workshop.ideventworkshop : null),
            eventCode: eventCode,
            targetIdMember: targetMember ? targetMember.id : null,
            threadId: thread ? thread.idthread : null,
            idTag: tag ? tag.id : null
        })
            .pipe(map((data) => data.data && data.data.storeLogActivity ? data.data.storeLogActivity : false));
    }

    storeAlgoliaLogActivity(type: string, value: string, eventCode?: string) {
        return this.mutation(ResourceGraphQL.mutations.storeAlgoliaLogActivity, {
            type: type,
            value: value,
            eventCode: eventCode
        })
            .pipe(map((data) => data.data && data.data.storeAlgoliaLogActivity ? data.data.storeAlgoliaLogActivity : false));
    }

    storeLogActivityApparitions(type: string, value: string, companiesID: number[] = null, productsID: number[] = null, eventCode: string = null) {
        return this.mutation(ResourceGraphQL.mutations.storeLogActivityApparitions, {
            type: type,
            value: value,
            idCompanies: companiesID,
            idProducts: productsID,
            eventCode: eventCode
        })
            .pipe(map((data) => data.data && data.data.storeLogActivityApparitions ? data.data.storeLogActivityApparitions : false));
    }

    getTimezones() {
        return this.query(ResourceGraphQL.queries.timezones)
            .pipe(map((data) => data.data && data.data.timezones ? data.data.timezones : []));
    }
}
