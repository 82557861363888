import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TemplateService} from "../../../../services/template.service";

@Component({
    selector: 'kit-modal-simple',
    templateUrl: './kit-modal-simple.component.html',
    styleUrls: ['./kit-modal-simple.component.scss']
})
export class KitModalSimpleComponent implements OnInit, OnDestroy {

    @Input() modalTitle: string;
    @Input() text: string;
    @Input() labelButton: string;
    @Input() hasCloseIcon = true;
    @Input() backdropClose = true;
    @Input() isLoading = false;
    @Input() labelLoader = 'Loading...';
    @Input() isFullscreen = false;
    @Input() isMobileFullscreen = false;

    @Output() onClose = new EventEmitter();
    @Output() onButtonClicked = new EventEmitter();

    constructor(private templateService: TemplateService) {
    }

    ngOnInit() {
        this.templateService.blockScroll();
    }

    ngOnDestroy() {
        this.templateService.unblockScroll();
    }
}
