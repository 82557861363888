import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductCategoryTagComponent} from './product-category-tag.component';
import {NeedlTagModule} from "needl-lib";


@NgModule({
    declarations: [
        ProductCategoryTagComponent
    ],
    imports: [
        CommonModule,
        NeedlTagModule
    ],
    exports: [
        ProductCategoryTagComponent
    ]
})
export class ProductCategoryTagModule {
}
