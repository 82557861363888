import {Component, ContentChildren, forwardRef, HostBinding, Input, QueryList, ViewChild} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {NdlInputBaseDirective} from '../input-base.directive';
import {NdlOptionDirective} from '../option.directive';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {NdlRadioFieldComponent} from './radio-field.component';

@Component({
    selector: 'ndl-radio',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.scss'],
    host: {
        'class': 'ndl-radio',
        '(blur)': "onTouched()"
    }
})
export class NdlRadioComponent extends NdlInputBaseDirective {
    override defaultValue = null;
    override _value = null;
    @ContentChildren(NdlOptionDirective) options: QueryList<NdlOptionDirective>;
    @ViewChild("radioField", {read: NdlRadioFieldComponent}) radioField: NdlRadioFieldComponent;

    @HostBinding('class.ndl-radio-vertical') private _vertical = false;
    @Input() get vertical(): boolean {
        return this._vertical;
    }

    set vertical(vertical: boolean) {
        this._vertical = coerceBooleanProperty(vertical);
    }
}
