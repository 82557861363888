import {Inject, Injectable, LOCALE_ID} from "@angular/core";
import {Member} from "./wabel-client/entities/member";
import {CookieService} from './cookie.service';

@Injectable({providedIn: 'root'})
export class LocaleService {
    constructor(private cookieService: CookieService,@Inject(LOCALE_ID) protected localeId: string) {
    }

    get locale() {
        return this.localeId;
    }

    set locale(locale: string) {
        this.cookieService.set("USER_LOCALE", locale, null, "/", ".needl.co");
        document.location.reload();
    }

    checkLocaleDiscrepancy(member: Member) {
        return member && member.locale && member.locale !== this.locale;
    }
}
