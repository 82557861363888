import { WabelEntity } from "./wabel-entity.abstract";
import {Tag} from "./tag";

export class TagLocation extends WabelEntity {
    constructor(obj: any) {
        super(obj);
        if (obj) {
            this.tag = obj.tag ? new Tag(obj.tag) : null;
            this.continent = obj.continent ? new Tag(obj.continent) : null;
        }
    }

    tag: Tag;
    code: string;
    backgroundImage: string;
    nbPurchasingGroups: number;
    nbImporters: number;
    nbCoPackers: number;
    continent: Tag;

    get id() {
        return this.code;
    }
}
