import {Component, OnInit, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import { Company } from '../../../services/wabel-client/entities/company';
import { AuthService } from '../../../services/auth.service';
import { CompanyProfileSubmissionService } from '../../../services/wabel-client/services/company-profile-submission.service';
import { CompanyProfileSubmitDataType } from '../../../services/wabel-client/custom_types';
import { File } from "../../../services/wabel-client/entities/file";
import { CompanyProfileSubmission } from '../../../services/wabel-client/entities/company_profile_submission';
import {Router} from "@angular/router";
import {Tag} from "../../../services/wabel-client/entities/tag";
import {NdlModalDirective} from '../../../../../projects/needl-lib/src/modals/modal.directive';
import {CompanyClient} from '../../../services/wabel-client/entities/company_client';

@Component({
    selector: 'profile-submission-component',
    templateUrl: './profile-submission.component.html',
    styleUrls: ['./profile-submission.component.scss']
})

export class ProfileSubmissionComponent implements OnInit {
    @Input() targetCompany: Company = null;
    @Input() profileSubmissionData: CompanyProfileSubmission = null;
    @Input() eventCode: string = null;
    @Input() navigateAfterSave = true;
    @Output() submissionModalClosed = new EventEmitter();
    @Output() submissionSaved = new EventEmitter();
    isStillUploading = false;
    clients: CompanyClient[] = [];
    formErrors = {
        submissionSubjectStepError: true,
        submissionExportingStepError: true,
        submissionDocumentsStepError: true,
        submissionCategoriesStepError: true,
        submissionProductsStepError: true
    };
    isSavedForLater = false;
    public myCompany: Company = null;
    public submitting = false;
    public asynchronousButtonStateError = null;
    public asynchronousButtonStateErrorCategory = 'graphqlException';
    public submissionData: CompanyProfileSubmitDataType = {
        reasonTitle: null,
        reasonValue: null,
        reasonMessage: null,
        isExportingToBuyerCountry: null,
        exportingToCountries: [],
        featuredDiagnosisClients: [],
        providedCategories: [],
        providedCategoryIds: [],
        isFeaturedProducts: "yes",
        targetCompanyId: null,
        featuredSelectedProducts: [],
        submissionFiles: [],
        status: "draft",
        step: "",
        idcompanyprofilesubmission: null,
        oldFiles: []
    };
    asynchronousButtonState = "before";
    currentStep = '';
    previewStepFinalData: CompanyProfileSubmission = null;

    @ViewChild('errorModal') ndlModal: NdlModalDirective;

    constructor(
        private authservice: AuthService,
        public companyProfileSubmissionService: CompanyProfileSubmissionService,
        private router: Router) {
    }

    ngOnInit() {
        this.myCompany = this.authservice.getMember().company;
        this.submissionData.targetCompanyId = this.targetCompany.idcompany;
        this.companyProfileSubmissionService.setInitialStep();
        this.companyProfileSubmissionService.getCurrentStep().subscribe(currentStep => {
            if (!currentStep) {
                return;
            }
            this.currentStep = currentStep;
        });
        if (this.profileSubmissionData) {

            // if submission modal is opened from draft submissions list
            if (this.profileSubmissionData.profileStep === this.companyProfileSubmissionService.PREVIEW_PROFILE_STEP) {
                this.previewStepFinalData = this.profileSubmissionData;
            }

            // this condition to check old submissions with step CREDIT_DEDUCTION_STEP but this step is not used anymore
            if (this.profileSubmissionData.profileStep === this.companyProfileSubmissionService.CREDIT_DEDUCTION_STEP) {
                this.companyProfileSubmissionService.setCurrentStep(this.companyProfileSubmissionService.PREVIEW_PROFILE_STEP);
            } else {
                this.companyProfileSubmissionService.setCurrentStep(this.profileSubmissionData.profileStep);
            }
            const profileSubmission = this.profileSubmissionData;
            this.submissionData.idcompanyprofilesubmission = profileSubmission.idcompanyprofilesubmission;
            this.submissionData.reasonMessage = profileSubmission.profileDescription;
            this.submissionData.reasonTitle = profileSubmission.profileTitle;
            this.submissionData.isExportingToBuyerCountry = profileSubmission.isExportingToBuyerCountry == true ?
                'exporting_yes' : 'exporting_no';
            this.submissionData.isFeaturedProducts = profileSubmission.isFeaturedProducts == true ? 'yes' : 'no';
            profileSubmission.companyProfileExportingCountries.forEach(x => {
                this.submissionData.exportingToCountries.push(new Tag(x));
            });
            profileSubmission.companyProfileDiagnosisClients.forEach(x => {
                this.submissionData.featuredDiagnosisClients.push(x);
            });
            if (profileSubmission.categories.length > 0) {
                profileSubmission.categories.forEach(x => {
                    this.submissionData.providedCategories.push(x.name);
                    this.submissionData.providedCategoryIds.push(x.id);
                });
            }
            profileSubmission.companyProfileFeaturedProducts.forEach(x => {
                if (x.iduserproduct !== undefined) {
                    this.submissionData.featuredSelectedProducts.push(x);
                }
            });
            this.submissionData.oldFiles = profileSubmission.files &&
                profileSubmission.files.length ?
                profileSubmission.files
                    .map(userproductsImage => new File(userproductsImage)) : [];
        }

    }

    saveForLater() {
        if (this.currentStep === this.companyProfileSubmissionService.MESSAGE_STEP) {
            this.nextPush(true);
        } else {
            this.isSavedForLater = true;
            this.asynchronousButtonState = 'close';
        }
        return;
    }

    closePopUp() {
        if(this.asynchronousButtonStateErrorCategory !== 'CompanyCategoriesNotMatchedException') {
            this.submissionModalClosed.emit(this.submissionData);
        }
        else {
            this.asynchronousButtonStateError = null;
            this.asynchronousButtonState = 'before';
        }
    }

    nextPush(saveForLater = false) {
        if (this.asynchronousButtonState === "close") {
            if (this.router.url.includes('company-submissions') || !this.navigateAfterSave) {
                this.closePopUp();
            } else {
                this.router.navigate(['/profile', 'company-submissions']);
            }
            return;
        }
        // step consts
        const {
            MESSAGE_STEP,
            EXPORTING_COUNTRIES_STEP,
            CLIENT_PARTNER_STEP,
            CATEGORIES_SELECTION_STEP,
            FEATURED_PRODUCTS_STEP,
            DOCUMENTS_UPLOAD_STEP,
            PREVIEW_PROFILE_STEP,
            SUBMISSION_COMPLETED
        } = this.companyProfileSubmissionService;
        switch (this.currentStep) {
            case CATEGORIES_SELECTION_STEP:
            if (!this.formErrors.submissionCategoriesStepError) {
                this.sendCompanySubmissionRequest(MESSAGE_STEP);
            } else {
                this.companyProfileSubmissionService.doCheckStep.emit(CATEGORIES_SELECTION_STEP);
            }
            break;
            case MESSAGE_STEP:
                if (!this.formErrors.submissionSubjectStepError) {
                    this.sendCompanySubmissionRequest(EXPORTING_COUNTRIES_STEP, saveForLater);
                } else {
                    this.companyProfileSubmissionService.doCheckStep.emit(MESSAGE_STEP);
                }
                break;
            case EXPORTING_COUNTRIES_STEP:
                if (!this.formErrors.submissionExportingStepError) {
                    if (this.submissionData.exportingToCountries.length > 0) {
                        if (this.companyProfileSubmissionService.getShowDiagnosisClientsStep() === true) {
                            this.sendCompanySubmissionRequest(CLIENT_PARTNER_STEP);
                        } else {
                            this.sendCompanySubmissionRequest(FEATURED_PRODUCTS_STEP);
                        }
                    } else {
                        this.sendCompanySubmissionRequest(FEATURED_PRODUCTS_STEP);
                    }
                } else {
                    this.companyProfileSubmissionService.doCheckStep.emit(EXPORTING_COUNTRIES_STEP);
                }
                break;
            case CLIENT_PARTNER_STEP:
                this.sendCompanySubmissionRequest(FEATURED_PRODUCTS_STEP);
                break;
            case FEATURED_PRODUCTS_STEP:
                if (!this.formErrors.submissionProductsStepError) {
                    this.sendCompanySubmissionRequest(DOCUMENTS_UPLOAD_STEP);
                } else {
                    this.companyProfileSubmissionService.doCheckStep.emit(FEATURED_PRODUCTS_STEP);
                }
                break;
            case DOCUMENTS_UPLOAD_STEP:
                if (!this.checkIfStillUploading()) {
                    this.sendCompanySubmissionRequest(PREVIEW_PROFILE_STEP);
                } else {
                    this.isStillUploading = true;
                }
                break;
            case PREVIEW_PROFILE_STEP:
                if (!this.submitting) {
                    this.submissionData.status = "sent";
                    this.sendCompanySubmissionRequest(SUBMISSION_COMPLETED);
                }
                break;
            default:
                this.closePopUp();
                break;
        }

    }

    checkIfStillUploading() {
        this.isStillUploading = false;
        if (this.submissionData.submissionFiles && this.submissionData.submissionFiles.length) {
            for (let i = 0; i < this.submissionData.submissionFiles.length; i++) {
                if (this.submissionData.submissionFiles[i].isUploading()) {
                    this.isStillUploading = true;
                    break;
                }
            }
        }
        if (this.isStillUploading) {
            return true;
        }
    }

    prevPush() {
        // step consts
        const {
            MESSAGE_STEP,
            CATEGORIES_SELECTION_STEP,
            PREVIEW_PROFILE_STEP,
            DOCUMENTS_UPLOAD_STEP,
            FEATURED_PRODUCTS_STEP,
            EXPORTING_COUNTRIES_STEP
        } = this.companyProfileSubmissionService;
        if (this.currentStep !== CATEGORIES_SELECTION_STEP) {
            if (this.currentStep === MESSAGE_STEP) {
                this.companyProfileSubmissionService.setCurrentStep(CATEGORIES_SELECTION_STEP);
            } else {
                if (this.currentStep === PREVIEW_PROFILE_STEP) {
                    this.companyProfileSubmissionService.setCurrentStep(DOCUMENTS_UPLOAD_STEP);
                } else {
                    if (this.currentStep === FEATURED_PRODUCTS_STEP && !this.submissionData.featuredDiagnosisClients.length) {
                        this.companyProfileSubmissionService.setCurrentStep(EXPORTING_COUNTRIES_STEP);
                    } else {
                        this.asynchronousButtonState = 'before';
                        this.companyProfileSubmissionService.setPreviousStep();
                    }
                }
            }
        }
    }

    sendCompanySubmissionRequest(step, saveForLater = false) {
        this.asynchronousButtonState = 'loading';
        this.submissionData.step = step;
        this.submitting = true;
        if (this.submissionData.isFeaturedProducts === "no") {
            this.submissionData.featuredSelectedProducts = [];
        }
        this.submissionData.featuredDiagnosisClients.filter(x => x !== null);
        this.companyProfileSubmissionService.submitCompanyProfile(this.submissionData, this.eventCode)
            .subscribe(data => {
                const {
                    SUBMISSION_COMPLETED,
                    PREVIEW_PROFILE_STEP
                } = this.companyProfileSubmissionService;
                if (!data) {
                    return;
                }
                this.asynchronousButtonState = 'after';
                this.submissionData.idcompanyprofilesubmission = data.idcompanyprofilesubmission;
                this.companyProfileSubmissionService.setCurrentStep(step);
                if (this.currentStep === SUBMISSION_COMPLETED) {
                    if (this.authservice.getMember().company.remainingCompanyProfileSubmissions) {
                        this.authservice.getMember().company.remainingCompanyProfileSubmissions--;
                    }
                    this.asynchronousButtonState = 'close';
                    this.submissionData.status = 'sent';
                } else {
                    this.submitting = false;
                }
                if (this.currentStep === PREVIEW_PROFILE_STEP) {
                    this.previewStepFinalData = data;
                    this.asynchronousButtonState = 'confirm';
                }
                if (saveForLater) {
                    this.isSavedForLater = true;
                    this.asynchronousButtonState = 'close';
                }
                this.submissionSaved.emit(data.createdForCompany.lastCompanyProfileSubmissionToCompany);
            }, (error) => {
                this.asynchronousButtonState = 'close';
                this.submitting = false;
                this.asynchronousButtonStateError = error.networkError.result.errors[0].message.replace("GraphQL error: ", "");
                switch (this.asynchronousButtonStateError) {
                    case 'Your Company has already sent submission(s) to the same buyer recently, this submission will be saved as draft':
                        this.asynchronousButtonStateErrorCategory = 'submissionAlreadySentException';
                        break;
                    case 'This company is not available for submission as their buyers from your category did not join our platform yet.':
                        this.asynchronousButtonStateErrorCategory = 'CompanyCategoriesNotMatchedException';
                        break;
                    default:
                        this.asynchronousButtonStateErrorCategory = 'graphqlException';
                        break;
                }
                this.ndlModal.open();
            });
    }
}
