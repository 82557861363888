import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Product} from "../../../services/wabel-client/entities/product";
import {ImageService} from '../../../services/image.service';

@Component({
    selector: 'app-product-preview',
    templateUrl: './product-preview.component.html',
    styleUrls: ['./product-preview.component.scss']
})
export class ProductPreviewComponent {
    @Input() product: Product;
    @Input() showIcon = false;

    @Output() imageClicked = new EventEmitter<Product>();
    @Output() productNameClicked = new EventEmitter<Product>();
    @Output() categoryClicked = new EventEmitter<Product>();

    constructor(public imageService: ImageService) {
    }
}
