import {NgModule} from '@angular/core';
import {
    NeedlBannersModule,
    NeedlButtonsModule,
    NeedlCheckboxesNewModule,
    NeedlFormModule,
    NeedlDividerModule,
    NeedlModalsModule,
    NeedlPasswordModule,
    NeedlSpinnersModule,
    NeedlSnackbarModule
} from 'needl-lib';
import {AppBannersComponent} from './app-banners/app-banners.component';
import {AppModalsComponent} from './app-modals/app-modals.component';
import {AppTourComponent} from './app-tour/app-tour.component';
import {AppNavbarComponent} from './app-navbar/app-navbar.component';
import {SearchItemSuggestionComponent} from './app-navbar/search-item-suggestion/search-item-suggestion.component';
import {SearchSuggestionComponent} from './app-navbar/search-suggestion/search-suggestion.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {NeedlSecurityModule} from '@wabel/needl-security';
import {SearchInputModule} from '../search/search-input/search-input.module';
import {CommonModule} from '@angular/common';
import {ClickOutsideModule} from '../../directives/click-outside/click-outside.module';
import {RouterModule} from '@angular/router';
import {TourMatMenuModule} from 'ngx-ui-tour-md-menu';
import {AppFooterComponent} from './app-footer/app-footer.component';
import {AppLoaderComponent} from './app-loader/app-loader.component';
import {SharedModule} from "../../modules/shared-module/shared.module";
import {GoogleTagManagerModule} from '../../directives/gtm/gtm.module';
import {AppMaintenanceAndUpdateComponent} from './app-maintenance-and-update/app-maintenance-and-update.component';

@NgModule({
    declarations: [
        AppBannersComponent,
        AppModalsComponent,
        AppTourComponent,
        AppNavbarComponent,
        AppFooterComponent,
        AppLoaderComponent,
        SearchItemSuggestionComponent,
        SearchSuggestionComponent,
        AppMaintenanceAndUpdateComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        MatIconModule,
        TourMatMenuModule,
        NeedlBannersModule,
        NeedlButtonsModule,
        NeedlCheckboxesNewModule,
        NeedlFormModule,
        NeedlDividerModule,
        NeedlModalsModule,
        NeedlPasswordModule,
        NeedlSecurityModule,
        NeedlSpinnersModule,
        NeedlSnackbarModule,
        SearchInputModule,
        ClickOutsideModule,
        SharedModule,
        GoogleTagManagerModule,
    ],
    exports: [
        AppNavbarComponent,
        AppBannersComponent,
        AppModalsComponent,
        AppTourComponent,
        AppFooterComponent,
        AppLoaderComponent,
        AppMaintenanceAndUpdateComponent,
    ]
})
export class AppFrontModule {
}
