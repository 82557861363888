import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NdlModalDirective} from 'needl-lib';
import {MemberService} from '../../../services/wabel-client/services/member.service';
import {TemplateService} from '../../../services/template.service';
import {take} from 'rxjs/operators';
import {NeedlSecurityService} from '@wabel/needl-security';
import {RIGHT} from '../../../services/wabel-client/security/right.const';
import {BUYER_SCOPE_MODAL_INTERNALNAME} from '../../../services/wabel-client/entities/member_notification';
import {CacheService} from '../../../services/cache.service';
import {Tag} from '../../../services/wabel-client/entities/tag';
import {Member} from '../../../services/wabel-client/entities/member';
import {combineLatest, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {NdlSnackbarService} from 'needl-lib';

@Component({
    selector: 'app-modals',
    styleUrls: ['./app-modals.component.scss'],
    templateUrl: './app-modals.component.html',
})
export class AppModalsComponent implements OnInit, AfterViewInit, OnDestroy {
    // Password
    savingPassword = false;
    passwordForm: FormGroup;
    termsAndConditionForm: FormGroup;
    // Terms & Condtions
    needlTc: string;

    // Buyer Buying Scope
    savingBuyingScope = false;
    buyingScopeForm: FormGroup;
    categories: Tag[];
    activities: Tag[];
    memberBuyingScore: Member;
    buyingScopeModal$: Subscription;
    isDisplayForcePasswordChange$: Subscription;
    activatedRoute$: Subscription;

    get member() {
        return this.authService.getMember();
    }

    @ViewChild('changePasswordModal', {read: NdlModalDirective, static: true}) passwordModal: NdlModalDirective;
    @ViewChild('termsAndCondition', {read: NdlModalDirective, static: true}) needlTcModal: NdlModalDirective;
    @ViewChild('buyerScope', {read: NdlModalDirective, static: true}) buyerScopeModal: NdlModalDirective;

    constructor(
        public authService: AuthService,
        public templateService: TemplateService,
        public cacheService: CacheService,
        private fb: FormBuilder,
        private memberService: MemberService,
        private nss: NeedlSecurityService,
        private activatedRoute: ActivatedRoute,
        private snackbar: NdlSnackbarService
    ) {
        this.passwordForm = this.fb.group({
            password: ['', Validators.required],
            check: ['', Validators.required],
        });
        this.termsAndConditionForm = this.fb.group({
            termsAndCondition: [[], Validators.required]
        });
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        // Check for Terms and Condition display
        if (this.authService.getMember() && !this.authService.isMasquerading() && !this.authService.getMember().needlTcAccepted) {
            this.needlTcModal.open();
        }

        // Check for Password change display
        this.isDisplayForcePasswordChange$ = this.templateService._displayForcePasswordChangeIsFinished.subscribe(x => {
            if (this.authService.getMember() && !this.authService.isMasquerading() && this.templateService.isDisplayForcePasswordChange) {
                this.passwordModal.open();
            }
        });


        // Check for Buyer Scope display
        if (this.nss.hasRight(RIGHT.CAN_MANAGE_MY_BUYING_SCOPE)) {
            this.buyingScopeModal$ = this.templateService.openBuyingScopeModal$.subscribe(data => {
                if (!this.categories) {
                    this.computeInfoForBuyingScopeModal();
                } else {
                    this.buyerScopeModal.open();
                }
            });
        }
        if (
            this.templateService.forceDisplayBuyerScopeModal ||
            (this.authService.getMember() &&
                !this.authService.isMasquerading() &&
                this.authService.getMember().needlTcAccepted &&
                this.nss.hasRight(RIGHT.CAN_MANAGE_MY_BUYING_SCOPE) &&
                this.authService.getMember().hasNotificationPending(BUYER_SCOPE_MODAL_INTERNALNAME) &&
                !this.templateService.isInUrl('event/')
            )
        ) {
            this.computeInfoForBuyingScopeModal();
        }
    }

    computeInfoForBuyingScopeModal() {
        combineLatest([
            this.cacheService.getCategories().pipe(take(1)),
            this.cacheService.getActivities().pipe(take(1)),
            this.memberService.getMyBuyingScope().pipe(take(1))
        ]).subscribe(data => {
            this.categories = data[0].tags.filter(tag => tag.machineName !== 'packaging');
            this.activities = data[1].tags;
            this.member.buyingScopeCategories = data[2].buyingScopeCategories;
            this.member.buyingScopeActivities = data[2].buyingScopeActivities;

            this.buyingScopeForm = this.fb.group({
                activity: [this.member.buyingScopeActivities.map(c => c.id), Validators.required],
                categories: [this.member.buyingScopeCategories.map(c => c.id), Validators.required]
            });

            this.buyerScopeModal.open();
        });
    }

    savePassword() {
        if (this.passwordForm.valid) {
            this.savingPassword = true;
            this.memberService.savePassword(this.passwordForm.get('password').value).subscribe(() => {
                this.savingPassword = false;
                this.snackbar.show('Your password has been updated.', 'check');
                this.passwordModal.close();
                this.redirectAfterSave();
            });
        } else {
            this.passwordForm.markAllAsTouched();
        }
    }

    redirectAfterSave() {
        this.activatedRoute$ = this.activatedRoute.queryParams.subscribe(params => {
            if (params['redirect']) {
                window.location.href = params['redirect'].replaceAll('//', '/');
            }
        });
    }

    acceptNeedlTc() {
        this.termsAndConditionForm.markAllAsTouched();
        if (this.termsAndConditionForm.invalid) {
            return;
        }
        this.needlTcModal.close();
        this.authService.acceptNeedlTc();
        this.memberService.acceptNeedlTc().subscribe(data => {
            this.redirectAfterSave()
        });
    }

    saveBuyingScope() {
        if (this.buyingScopeForm) {
            if (this.buyingScopeForm.valid) {
                this.savingBuyingScope = true;
                this.memberService
                    .savePreregisterInfo(this.member, this.buyingScopeForm.value.categories, this.buyingScopeForm.value.activity)
                    .subscribe(
                        (data: Member) => {
                            this.savingBuyingScope = false;
                            this.member.changeNotificationStatusOrCreateOne(BUYER_SCOPE_MODAL_INTERNALNAME, 'accepted');
                            this.snackbar.show('Your product interest has been saved.', 'check');
                            if (this.authService.getMember()) {
                                this.authService.refreshNotifications();
                            }
                            this.member.buyingScopeActivities = data.buyingScopeActivities;
                            this.member.buyingScopeCategories = data.buyingScopeCategories;
                            this.templateService.forceDisplayBuyerScopeModal = false;
                            this.buyerScopeModal.close();
                            this.redirectAfterSave();
                        },
                        (error) => {
                            this.savingBuyingScope = false;
                            this.snackbar.show('An error occurred. Please try again or contact an administrator.', 'error', null);
                        }
                    );
            } else {
                this.buyingScopeForm.markAllAsTouched();
            }
        }
    }

    ngOnDestroy() {
        if (this.buyingScopeModal$) {
            this.buyingScopeModal$.unsubscribe();
        }
        if (this.isDisplayForcePasswordChange$) {
            this.isDisplayForcePasswordChange$.unsubscribe();
        }
        if (this.activatedRoute$) {
            this.activatedRoute$.unsubscribe();
        }
    }
}
