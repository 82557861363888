import {
    Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild,
} from '@angular/core';
import {Company} from '../../../../services/wabel-client/entities/company';
import {AuthService} from '../../../../services/auth.service';
import {NdlLayoutService} from 'needl-lib';
import {BusinessMappingPriority} from "../../../../services/wabel-client/entities/business_mapping_priority";
import {TemplateService} from "../../../../services/template.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NdlModalDirective} from "../../../../../../projects/needl-lib/src/modals/modal.directive";
import {BusinessMappingService} from "../../../../services/wabel-client/services/business-mapping.service";
import {
    DISCOVER_BUSINESS_PRIORITIES_TOUR_INTERNALNAME, FIRST_BUSINESS_MAPPING_PRIORITY_RECOMMENDATION_REMOVED_INTERNALNAME,
    MemberNotification,
    REACT_TO_BM_RECOMMENDATIONS_TOUR_INTERNALNAME,
    SEE_PROSPECTS_LIST_TOUR_INTERNALNAME
} from "../../../../services/wabel-client/entities/member_notification";
import {NeedlTourService} from "../../../../services/needl-tour.service";
import {MemberService} from "../../../../services/wabel-client/services/member.service";
import {
    BusinessMappingPriorityRecommendation
} from "../../../../services/wabel-client/entities/business_mapping_priority_recommendation";

@Component({
    selector: 'app-business-mapping-recap-recommendation',
    templateUrl: './business-mapping-recap-recommendation.component.html',
    styleUrls: ['./business-mapping-recap-recommendation.component.scss']
})
export class BusinessMappingRecapRecommendationComponent implements OnInit {
    @Input() recommendation: Company;
    @Input() businessMappingPriority: BusinessMappingPriority;
    @Input() hideInfo = false;
    buyingScopeDisplayed: string[];
    buyingScopeHidden: string[];
    buyingScopeLimit = 3;
    removedReasons = [];
    customReasonForm: FormGroup;
    loading = false;
    @Output() recommendationsUpdated = new EventEmitter<any>();
    removedReason = null;

    @ViewChild('customReasonModal', {static: true}) customReasonModal: NdlModalDirective;
    @ViewChild('firstRecommendationRemoved', {static: true}) firstRecommendationRemovedModal: NdlModalDirective;

    constructor(private authService: AuthService,
                public layoutService: NdlLayoutService,
                public templateService: TemplateService,
                private _fb: FormBuilder,
                private businessMappingService: BusinessMappingService,
                private needlTourService: NeedlTourService,
                private memberService: MemberService) {
        this.customReasonForm = this._fb.group({
            customReason: this._fb.control('', Validators.required)
        });
    }

    ngOnInit() {
        const suppliercats = this.authService.getMember().company.categories.map(cat => cat.name).concat(this.authService.getMember().company.categories.map(cat => cat.parent.name));
        const bs = this.recommendation.buyingScope.sort((a, b) => {
            const aScore = suppliercats.includes(a.name) && suppliercats.includes(a?.parent?.name) ? 3 : (suppliercats.includes(a.name) ? 2 : suppliercats.includes(a?.parent?.name) ? 1 : 0);
            const bScore = suppliercats.includes(b.name) && suppliercats.includes(b?.parent?.name) ? 3 : (suppliercats.includes(b.name) ? 2 : suppliercats.includes(b?.parent?.name) ? 1 : 0);
            return bScore - aScore;
        });

        this.buyingScopeDisplayed = bs.slice(0, this.buyingScopeLimit).map(cat => cat.name);
        this.buyingScopeHidden = bs.slice(this.buyingScopeLimit - 1, bs.length).map(cat => cat.name);
        this.removedReasons = [
            {'label': 'I already work with this company', 'value': 'already_work_with'},
            {'label': 'None of the product categories match my needs', 'value': 'products_do_not_match_needs'},
            {'label': 'The points of sales doesn\'t match my needs', 'value': 'number_selling_points_does_not_match_needs'},
            {'label': 'The turnover doesn\'t match my needs', 'value': 'turnover_does_not_match_needs'},
            {'label': 'I don\'t want to export with ' + this.recommendation.companytype.name +  's', 'value': 'does_not_want_export_with_company_type'},
            {'label': 'None of the above', 'value': 'none_of_the_above'}
        ];
    }

    getStatusColor(score) {
        if (score >= 0.95) {
            return 'red';
        }
        if (score >= 0.85) {
            return 'orange';
        }
        return 'yellow';
    }

    updateProspect(included: boolean, removedReason: string = null, customRemovedReason: any = null) {
        this.removedReason = removedReason;
        if (removedReason === 'none_of_the_above' && (!customRemovedReason || !customRemovedReason.customReason)) {
            this.customReasonModal.open();
            return;
        }
        if (!included && !this.authService.getMember().hasNotificationAccepted(FIRST_BUSINESS_MAPPING_PRIORITY_RECOMMENDATION_REMOVED_INTERNALNAME)) {
            this.customReasonModal.close();
            this.firstRecommendationRemovedModal.open();
            return;
        }
        this.loading = true;
        this.businessMappingService.updateBusinessMappingPriorityProspect(this.businessMappingPriority.id, this.recommendation.idcompany, included, removedReason, customRemovedReason?.customReason)
            .subscribe(data => {
                this.loading = false;
                this.customReasonModal.close();
                this.firstRecommendationRemovedModal.close();
                if (included) {
                    const index = this.authService.getMember().businessMapping.businessMappingPriorities.findIndex(bmp => +bmp.id === +this.businessMappingPriority.id);
                    if (index !== -1) {
                        this.authService.getMember().businessMapping.businessMappingPriorities[index].businessMappingPriorityRecommendations.push(new BusinessMappingPriorityRecommendation({company: this.recommendation
                        }));
                    }
                }
                if (included && !this.authService.getMember().hasNotificationAccepted(SEE_PROSPECTS_LIST_TOUR_INTERNALNAME)) {
                    this.needlTourService.launchNavBarMenuOpenUpdate({id: 'business', duringTour: true});
                    this.initializeTour();
                }
                this.recommendationsUpdated.emit(included);
            });
    }

    endNotificationFirstRemove(included: boolean, removedReason: string = null, customRemovedReason: any = null) {
        this.memberService.acceptNotification(FIRST_BUSINESS_MAPPING_PRIORITY_RECOMMENDATION_REMOVED_INTERNALNAME, 'modal').subscribe((notifications: MemberNotification[]) => {
            this.authService.getMember().setNotifications(notifications);
            this.updateProspect(included, removedReason, customRemovedReason);
        });
    }

    initializeTour() {
        const steps = [];
        steps.push({
            title: 'Find all your favorites profiles, in one place ',
            anchorId: 'see-prospects-list',
            content: 'You saved the first recommendation as a prospect, click here now to get in your <b>Prospect list</b> and manage it.',
        });
        this.needlTourService.initialize(steps, {
            enableBackdrop: true,
            isAsync: true,
            nextBtnTitle: 'Ok',
            endBtnTitle: 'Got it',
            disablePageScrolling: true
        }, false);
        this.needlTourService.canBeSkipped = false;
        this.needlTourService.start();
        this.needlTourService
            .getTourService()
            .end$
            .subscribe(
                () => {
                    this.memberService.acceptNotification(SEE_PROSPECTS_LIST_TOUR_INTERNALNAME, 'modal').subscribe((notifications: MemberNotification[]) => {
                        this.authService.getMember().setNotifications(notifications);
                        this.needlTourService.launchNavBarMenuOpenUpdate({id: null, duringTour: false});
                    });
                }
            );
    }
}
