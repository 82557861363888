import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {NdlSelectOption} from "../../NdlSelectOption";

@Component({
    selector: 'ndl-multi-select-list',
    templateUrl: './multi-select-list.component.html',
    styleUrls: ['./multi-select-list.component.scss']
})

export class NdlMultiSelectListComponent implements OnInit {
    @Input() placeholder = 'Add';
    @Input() inputListTitle = 'Select an option from the list';
    @Input() buttonLabel = 'Done';
    @Input() searchable = true;
    @Input() searchPlaceholder = 'Search...';
    @Input() options: NdlSelectOption[] = [];
    @Input() noOptionLabel = 'No option';
    @Input() selectedOptions: NdlSelectOption[] = [];
    @Input() isRequired = false;
    @Input() showError = false;
    @Input() errorMessage = 'This section must be filled';
    @Input() label = '';

    @Output() listChanged = new EventEmitter<NdlSelectOption[]>();
    @Output() showErrorChange = new EventEmitter<boolean>();

    isListOpened = false;
    clickWasInsideComponent = false;

    filteredOptions: NdlSelectOption[] = [];

    searchValue: string;

    constructor() {
    }

    @HostListener("click", ["$event"])
    clickInsideComponent(e) {
        this.clickWasInsideComponent = true;
    }

    @HostListener("document:click", ["$event"])
    clickOutsideComponent() {
        if (!this.clickWasInsideComponent) {
            this.isListOpened = false;
        }
        this.clickWasInsideComponent = false;
    }

    ngOnInit(): void {
        this.filteredOptions = this.options;
        this.selectedOptions.forEach(option => {
            this.filteredOptions = this.filteredOptions.filter(opt => opt.value !== option.value);
        });
        this.emitChanges();
    }

    selectOption(option: NdlSelectOption) {
        this.selectedOptions.push(option);
        this.filteredOptions = this.filteredOptions.filter(opt => opt.value !== option.value);
        this.emitChanges();
    }

    removeOption(option: NdlSelectOption) {
        this.filteredOptions.push(option);
        this.selectedOptions = this.selectedOptions.filter(opt => opt.value !== option.value);
        this.emitChanges();
    }

    searchChanged() {
        this.filteredOptions = this.selectedOptions.length ? this.options.filter(opt => this.selectedOptions.findIndex(slcOpt => slcOpt.value === opt.value) === -1) : this.options;
        if (this.searchValue && this.searchValue.length) {
            this.filteredOptions = this.filteredOptions.filter(opt => opt.label.toLowerCase().match(this.searchValue.toLowerCase()));
        }
    }

    clearList() {
        this.selectedOptions = [];
        this.searchChanged();
        this.emitChanges();
    }

    emitChanges() {
        if (this.selectedOptions.length > 0) {
            this.showError = false;
            this.showErrorChange.emit(this.showError);
        }
        this.listChanged.emit(this.selectedOptions);
    }
}
