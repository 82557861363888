import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NdlUploadComponent} from './upload.component';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {NdlUploadFieldComponent} from './upload-field.component';
import {MatDividerModule} from '@angular/material/divider';
import {NeedlFormFieldModule} from '../form-field/form-field.module';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {NeedlFormModule} from '../forms.module';
import {NeedlButtonsModule} from '../../buttons/buttons.module';
import {NdlUploadFileComponent} from './upload-file.component';
import {NdlFileSizePipe} from './file-size.pipe';


@NgModule({
    declarations: [
        NdlUploadComponent,
        NdlUploadFieldComponent,
        NdlUploadFileComponent,
        NdlFileSizePipe
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MatButtonModule,
        MatIconModule,
        MatDividerModule,
        MatInputModule,
        MatSelectModule,
        MatProgressBarModule,
        NeedlFormFieldModule,
        NeedlFormModule,
        NeedlButtonsModule
    ],
    exports: [
        NdlUploadComponent,
        NdlUploadFileComponent,
        NdlFileSizePipe
    ]
})
export class NeedlUploadModule {
}
