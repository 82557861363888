import {Directive} from '@angular/core';

@Directive({
  selector: '[ndl-button-suffix],[ndlButtonSuffix]',
    host: {
        class: 'ndl-button-suffix'
    }
})
export class NdlButtonSuffixDirective {
}
