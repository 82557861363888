// To learn more, check @see https://stackoverflow.com/questions/18299806/how-to-check-file-mime-type-with-javascript-before-upload
export class Mimes {

    static types = {
        image: [
            // JPG
            {
                ext: 'jpg',
                mime: 'image/jpeg',
                pattern: [0xFF, 0xD8, 0xFF],
                mask: [0xFF, 0xFF, 0xFF]
            },
            // PNG
            {
                ext: 'png',
                mime: 'image/png',
                pattern: [0x89, 0x50, 0x4E, 0x47, 0x0D, 0x0A, 0x1A, 0x0A],
                mask: [0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF]
            },
            // GIF
            {
                ext: 'gif',
                mime: 'image/gif',
                pattern: [0x47, 0x49, 0x46, 0x38],
                mask: [0xFF, 0xFF, 0xFF, 0xFF]
            },
            // // WEBP
            // {
            //     mime: 'image/webp',
            //     pattern: [0x52, 0x49, 0x46, 0x46],
            //     mask: [0xFF, 0xFF, 0xFF, 0xFF]
            // },
            // // BMP
            // {
            //     mime: 'image/bmp',
            //     pattern: [0x42, 0x4D],
            //     mask: [0xFF, 0xFF]
            // },
            // // ICO
            // {
            //     mime: 'image/x-icon',
            //     pattern: [0x00, 0x00, 0x01, 0x00],
            //     mask: [0xFF, 0xFF, 0xFF, 0xFF]
            // }
        ],
        document: [
            // PDF
            {
                ext: 'pdf',
                mime: 'application/pdf',
                pattern: [0x25, 0x50, 0x44, 0x46, 0x2D],
                mask: [0xFF, 0xFF, 0xFF, 0xFF, 0xFF]
            },
            // DOCX
            {
                ext: 'docx',
                mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                pattern: [0x50, 0x4B, 0x03, 0x04],
                mask: [0xFF, 0xFF, 0xFF, 0xFF]
            },
            // DOC
            {
                ext: 'doc',
                mime: 'application/msword',
                pattern: [0xD0, 0xCF, 0x11, 0xE0, 0xA1, 0xB1, 0x1A, 0xE1],
                mask: [0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF]
            },
            // XLSX
            {
                ext: 'xlsx',
                mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                pattern: [0x50, 0x4B, 0x03, 0x04],
                mask: [0xFF, 0xFF, 0xFF, 0xFF]
            },
            // XLS
            {
                ext: 'xls',
                mime: 'application/vnd.ms-excel',
                pattern: [0xD0, 0xCF, 0x11, 0xE0, 0xA1, 0xB1, 0x1A, 0xE1],
                mask: [0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF]
            },
            // PPTX
            {
                ext: 'pptx',
                mime: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                pattern: [0x50, 0x4B, 0x03, 0x04],
                mask: [0xFF, 0xFF, 0xFF, 0xFF]
            },
            // PPT
            {
                ext: 'ppt',
                mime: 'application/vnd.ms-powerpoint',
                pattern: [0xD0, 0xCF, 0x11, 0xE0, 0xA1, 0xB1, 0x1A, 0xE1],
                mask: [0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF]
            },
            // ODT
            {
                ext: 'odt',
                mime: 'application/vnd.oasis.opendocument.text',
                pattern: [0x50, 0x4B, 0x03, 0x04],
                mask: [0xFF, 0xFF, 0xFF, 0xFF]
            },
            // ODS
            {
                ext: 'ods',
                mime: 'application/vnd.oasis.opendocument.spreadsheet',
                pattern: [0x50, 0x4B, 0x03, 0x04],
                mask: [0xFF, 0xFF, 0xFF, 0xFF]
            },
            // ODP
            {
                ext: 'odp',
                mime: 'application/vnd.oasis.opendocument.presentation',
                pattern: [0x50, 0x4B, 0x03, 0x04],
                mask: [0xFF, 0xFF, 0xFF, 0xFF]
            }
        ]
    };

    static getTypesForInputFile(type: NeedlAcceptedUploadTypes) {
        switch (type) {
            case 'profile':
            case 'image':
                return Mimes.types.image.map(t => t.mime).join(", ");
            case 'document':
                return Mimes.types.document.map(t => t.mime).join(", ");
            case 'all':
            default:
                return "*/*";
        }
    }

    static getExtForInputFile(type: NeedlAcceptedUploadTypes) {
        const imgExt = Mimes.types.image.map(t => t.ext);
        const docExt = Mimes.types.document.map(t => t.ext);
        switch (type) {
            case 'profile':
            case 'image':
                return imgExt.join(', ');
            case 'document':
                return docExt.join(', ');
            case 'all':
            default:
                return (imgExt.concat(docExt)).join(', ');
        }
    }

    static checkFile(file: File, type: NeedlAcceptedUploadTypes, callback: (result: boolean) => any) {
        if (type === 'profile') {
            type = 'image';
        }
        const blob = file.slice(0, 8);
        const reader = new FileReader();
        reader.readAsArrayBuffer(blob);
        reader.onloadend = function(e) {
            if (e.target.readyState === FileReader.DONE) {
                const bytes = new Uint8Array(e.target.result as ArrayBufferLike);

                let fileAsValidMimeType = false;
                for (let i = 0; !fileAsValidMimeType && i < Mimes.types[type].length; i++) {
                    let continueToCheckHexMask = true;
                    for (let j = 0; continueToCheckHexMask && j < Mimes.types[type][i].mask.length; j++) {
                        if (bytes[j] !== Mimes.types[type][i].pattern[j]) {
                            continueToCheckHexMask = false;
                        }
                    }
                    fileAsValidMimeType = continueToCheckHexMask;
                }
                callback(fileAsValidMimeType);
            }
        };
    }
}

export type NeedlAcceptedUploadTypes = "all"|"document"|"image"|"profile";
