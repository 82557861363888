<ng-container [ngSwitch]="orientation">
    <ng-container *ngSwitchCase="'horizontal'">
        <div class="container-fluid">

            <!-- Horizontal stepper -->
            <div class="row">
                <div class="col-12 mat-horizontal-stepper-header-container ndl-stepper-container">
                    <ng-container *ngFor="let step of steps; let i = index; let isLast = last">
                        <ng-container
                            [ngTemplateOutlet]="stepTemplate"
                            [ngTemplateOutletContext]="{step: step, i: i}"></ng-container>
                        <div *ngIf="!isLast" class="mat-stepper-horizontal-line ndl-stepper-line" [class.ndl-stepper-line-selected]="this._getFocusIndex() > i"></div>
                    </ng-container>
                </div>
                <div class="col-12">
                    <ng-content select="[ndl-stepper-prefix]"></ng-content>
                </div>
                <div class="col-12 mat-horizontal-content-container">
                    <div *ngFor="let step of steps; let i = index"
                         class="mat-horizontal-stepper-content" role="tabpanel"
                         [@horizontalStepTransition]="_getAnimationDirection(i)"
                         (@horizontalStepTransition.done)="_animationDone.next($event)"
                         [id]="_getStepContentId(i)"
                         [attr.aria-labelledby]="_getStepLabelId(i)"
                         [attr.aria-expanded]="selectedIndex === i">
                        <ng-container [ngTemplateOutlet]="step.content"></ng-container>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- Vertical stepper -->
    <ng-container *ngSwitchCase="'vertical'">
        <mat-drawer-container>
            <mat-drawer opened position="end" disableClose mode="side" size="20%">
                <mat-selection-list #stepperVertical [multiple]="false" [compareWith]="verticalStepperCompareWith">
                    <ng-container *ngFor="let step of steps; let i = index; let isLast = last">
                        <mat-list-option [value]="i" [disabled]="!step.completed && linear && i > this.selectedIndex" [class.ndl-step-selected]="i === selectedIndex" [class.ndl-step-disabled]="!step.completed && linear && i > this.selectedIndex"
                                         (click)="step.select()">
                            <div mat-line><ng-container *ngIf="displayIndex">{{step.index+1}}. </ng-container>{{step.label}}</div>
                            <div mat-list-icon  class="ndl-horizontal-icon" [class.selected]="step.completed">
                                <mat-icon inline>done</mat-icon>
                            </div>
                        </mat-list-option>
                    </ng-container>
                </mat-selection-list>
            </mat-drawer>
            <mat-drawer-content>
                <div class="mat-step" *ngFor="let step of steps; let i = index; let isLast = last">
                    <div>
                        <div role="tabpanel"
                             [@verticalStepTransition]="_getAnimationDirection(i)"
                             (@verticalStepTransition.done)="_animationDone.next($event)"
                             [id]="_getStepContentId(i)"
                             [attr.aria-labelledby]="_getStepLabelId(i)"
                             [attr.aria-expanded]="selectedIndex === i">
                            <div class="mat-vertical-content">
                                <ng-container [ngTemplateOutlet]="step.content"></ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-drawer-content>
        </mat-drawer-container>
    </ng-container>
</ng-container>

<!-- Common step templating -->
<ng-template let-step="step" let-i="i" #stepTemplate>
    <ndl-step-header
        [class.mat-horizontal-stepper-header]="orientation === 'horizontal'"
        [class.mat-vertical-stepper-header]="orientation === 'vertical'"
        (click)="step.select()"
        (keydown)="_onKeydown($event)"
        [tabIndex]="_getFocusIndex() === i ? 0 : -1"
        [id]="_getStepLabelId(i)"
        [attr.aria-posinset]="i + 1"
        [attr.aria-setsize]="steps.length"
        [attr.aria-controls]="_getStepContentId(i)"
        [attr.aria-selected]="selectedIndex == i"
        [attr.aria-label]="step.ariaLabel || null"
        [attr.aria-labelledby]="(!step.ariaLabel && step.ariaLabelledby) ? step.ariaLabelledby : null"
        [attr.aria-disabled]="_stepIsNavigable(i, step) ? null : true"
        [index]="i"
        [displayIndex]="displayIndex"
        [state]="_getIndicatorType(i, step.state)"
        [label]="step.stepLabel || step.label"
        [selected]="selectedIndex === i"
        [active]="_stepIsNavigable(i, step)"
        [optional]="step.optional"
        [errorMessage]="step.errorMessage"
        [iconOverrides]="_iconOverrides"
        [disableRipple]="disableRipple"
        [color]="step.color || color"
        [stepper]="this"
        [completed]="step.completed">
    </ndl-step-header>
</ng-template>
