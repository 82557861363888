import {WabelEntity} from "./wabel-entity.abstract";
import {Tag} from "./tag";

export class Citylocation extends WabelEntity {
    constructor(obj: any) {
        super(obj);
        this.country = obj.country ? new Tag(obj.country) : null;
    }

    placeId: string;
    latitude: number;
    longitude: number;
    city: string;
    region: string;
    formattedAddress: string;
    country: Tag;

    get id(): string {
        return this.city;
    }
}
