import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NdlSuffixDirective} from './suffix.directive';
import {NdlPrefixDirective} from './prefix.directive';
import {NdlOptionDirective} from './option.directive';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {NdlOptionGroupDirective} from "./optiongroup.directive";
import {NdlErrorComponent} from './error.component';
import {NdlValidators} from './validators';


@NgModule({
    declarations: [
        NdlSuffixDirective,
        NdlPrefixDirective,
        NdlOptionDirective,
        NdlOptionGroupDirective,
        NdlErrorComponent
    ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatIconModule,
        MatButtonModule
    ],
    exports: [
        NdlSuffixDirective,
        NdlPrefixDirective,
        NdlOptionDirective,
        NdlOptionGroupDirective,
        NdlErrorComponent
    ],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {appearance: "fill", hideRequiredMarker: false, floatLabel: "always"}
        }
    ]
})
export class NeedlFormModule {
}
