import {WabelEntity} from "./wabel-entity.abstract";
import {Company} from "./company";
import {VideoMeeting} from "./video_meeting";
import {Member} from "./member";

export class VideoMeetingParticipant extends WabelEntity {
    constructor(obj: any) {
        super(obj);
        this.meeting = obj.meeting ? new VideoMeeting(obj.meeting) : null;
        this.member = obj.member ? new Member(obj.member) : null;
        this.invitedByIdCompany = obj.invitedByIdCompany ? new Company(obj.invitedByIdCompany) : null;
    }

    id: number;
    meeting: VideoMeeting;
    member: Member;
    invitedByIdCompany: Company;
}
