import {Directive, Input, TemplateRef} from '@angular/core';

@Directive({
    selector: '[ndlTab]',
    host: {
        class: 'ndl-tab'
    },
})
export class NdlTabDirective {

    @Input('ndlTab') label: string;

    constructor(public content: TemplateRef<any>) {
    }
}
