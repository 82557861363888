import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MemberService} from "../../../../services/wabel-client/services/member.service";
import {CompanyProfileSubmissionService} from "../../../../services/wabel-client/services/company-profile-submission.service";
import {Subscription} from "rxjs";

@Component({
    selector: 'submission-categories-step',
    templateUrl: './submission-categories-step.component.html',
    styleUrls: ['./submission-categories-step.component.scss']
})
export class SubmissionCategoriesStepComponent implements OnInit {
    categoriesLoading = false;
    myProductCategories: Array<{ value: string, id: number }> = [];
    @Input() providedCategoriesIds = [];
    @Input() providedCategories = [];
    @Output() providedCategoriesIdsChange = new EventEmitter<number[]>();
    @Output() providedCategoriesChange = new EventEmitter<string[]>();
    @Output() validateCategoriesStep = new EventEmitter<boolean>();
    categoriesError = false;
    myCompanyProductCategories$: Subscription;

    constructor(private memberService: MemberService, private companyProfileSubmissionService: CompanyProfileSubmissionService
    ) {
    }

    ngOnInit() {
        this.fetchCompanyCategories();
        this.companyProfileSubmissionService.doCheckStep.subscribe(step => {
            if (step === this.companyProfileSubmissionService.CATEGORIES_SELECTION_STEP) {
                this.onChange();
            }
        });
    }

    onChange() {
        this.categoriesError = false;
        if (this.providedCategories.length === 0) {
            this.categoriesError = true;
            this.validateCategoriesStep.emit(false);
        } else {
            this.validateCategoriesStep.emit(true);
            this.providedCategoriesIds = [];
            this.myProductCategories.forEach(x => {
                if (this.providedCategories.includes(x.value)) {
                    this.providedCategoriesIds.push(x.id);
                }
            });
            this.providedCategoriesChange.emit(this.providedCategories);
            this.providedCategoriesIdsChange.emit(this.providedCategoriesIds);
        }
    }

    fetchCompanyCategories() {
        this.categoriesLoading = true;
        if (this.myCompanyProductCategories$) {
            this.myCompanyProductCategories$.unsubscribe();
        }
        this.myCompanyProductCategories$ = this.memberService.getMyCompanyProductCategories().subscribe(data => {
                if (!data) {
                    return;
                }
                this.myProductCategories = data.company.rootCategories.map(x => {
                    return {value: x.name, id: x.id};
                });
                this.categoriesLoading = false;
            },
            () => {
                this.categoriesLoading = false;
            });
    }


}
