import gql from "graphql-tag";


export class TagGraphql {
    static fragments = {
        fullTag: gql`
            fragment fullTag on Tag {
                id
                name
                machineName
                type {
                    name
                    internalName
                }
                parent {
                    name
                    id
                    machineName
                    parent {
                        name
                        id
                        machineName
                    }
                }
                children {
                    items {
                        name
                        id
                        machineName
                        parent {
                            name
                            id
                            machineName
                        }
                        children {
                            items {
                                name
                                id
                                machineName
                                tagLocation {
                                    code
                                }
                                parent {
                                    name
                                    id
                                    machineName
                                }
                            }
                        }
                    }
                }
            }
        `,
        tag: gql`
            fragment tag on Tag {
                id
                name
                machineName
            }
        `,
        customTag: gql`
            fragment customTag on CustomTag {
                id
                name
            }
        `
    };
    static queries = {
        tagsByCompany: gql`
            query tagsByCompany($company: CompanyInput, $tagTypeInternalName: String) {
                tagsByCompany(company: $company, tagTypeInternalName: $tagTypeInternalName){
                    items {
                        ...tag
                    }
                    count
                }
            }
            ${TagGraphql.fragments.tag}
        `
    };
}
