import {
    AfterContentChecked, AfterContentInit,
    Component,
    ContentChild, ContentChildren,
    Input, QueryList,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {MatFormField, MatFormFieldControl} from '@angular/material/form-field';
import {NdlErrorComponent} from '../error.component';

@Component({
    selector: 'ndl-form-field',
    templateUrl: './form-field.component.html',
    styleUrls: ['./form-field.component.scss'],
    host: {
        'class': 'ndl-form-field'
    }
})
export class NdlFormFieldComponent implements AfterContentInit {
    @Input() label: string;
    @Input() hint: string;

    private _prefix: TemplateRef<any>;
    hasPrefix = false;

    @Input() set prefix(prefix: TemplateRef<any>) {
        this._prefix = prefix;
    }

    get prefix() {
        return this._prefix;
    }

    private _suffix: TemplateRef<any>;
    hasSuffix = false;
    @Input() set suffix(suffix: TemplateRef<any>) {
        this._suffix = suffix;
    }

    get suffix() {
        return this._suffix;
    }

    get empty() {
        return !this.formFieldControl || this.formFieldControl.empty;
    }

    @Input('errors') projectedErrors: QueryList<NdlErrorComponent>;

    @ViewChild('formField', {static: true, read: MatFormField}) matFormField: MatFormField;
    @ContentChild(MatFormFieldControl, {static: true}) formFieldControl: MatFormFieldControl<any>;
    @ContentChildren(NdlErrorComponent, {descendants: true}) errors: QueryList<NdlErrorComponent>;


    ngAfterContentInit() {
        this.matFormField._control = this.formFieldControl;
    }
}
