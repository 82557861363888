import {Component} from '@angular/core';
import {NdlStatus} from '../../core/types';

@Component({
    selector: 'ndl-buttons-demo',
    templateUrl: './buttons-demo.component.html'
})
export class NdlButtonsDemoComponent {
    themes: NdlStatus[] = ['primary', 'secondary', 'info', 'success', 'warning', 'danger', 'neutral'];
}
