import {Directive} from '@angular/core';
import {MatDialogActions} from "@angular/material/dialog";

@Directive({
    selector: '[ndl-modal-actions]',
    providers: [
        {provide: MatDialogActions, useExisting: NdlModalActionsDirective}
    ]
})
export class NdlModalActionsDirective extends MatDialogActions {
}
