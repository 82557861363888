import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges
} from "@angular/core";

@Component({
  selector: "app-paginator",
  templateUrl: "./paginator.component.html",
  styleUrls: ["./paginator.component.scss"]
})
export class PaginatorComponent implements OnInit, OnChanges {
  @Input() totalQuantityOfElements = 0;
  @Input() quantityOfElementsPerPage = 10;
  @Input() currentPage = 1;

  @Output() goToPage: EventEmitter<object> = new EventEmitter<object>();

  labelsForPaginatorButtons = [];

  constructor() {}
  ngOnInit() {
    this.renderLabels();
    this.floorCurrentPageValue();
  }

  ngOnChanges() {
    this.renderLabels();
  }

  onGoToPage(label: number | string) {
    if (label === this.currentPage || label === "...") {
      return null;
    }
    let goToPage;

    switch (label) {
      case "previous":
        goToPage = this.currentPage - 1;
        break;

      case "next":
        goToPage = this.currentPage + 1;
        break;

      default:
        goToPage = label;
        break;
    }

    this.goToPage.emit({
      quantityOfPages: this.getQuantityOfpages(),
      goToPage,
      goFromPage: this.currentPage
    });
  }

  getQuantityOfpages() {
    return Math.ceil(
      this.totalQuantityOfElements / this.quantityOfElementsPerPage
    );
  }

  private floorCurrentPageValue() {
    if (this.currentPage > this.getQuantityOfpages()) {
      this.currentPage = this.getQuantityOfpages();
    }
    if (this.currentPage < 1 || Boolean(this.currentPage) === false) {
      this.currentPage = 1;
    }
  }

  private rangeStr(start, end) {
    return Array(end - start + 1)
      .fill("")
      .map((el, index) => start + index);
  }

  private renderLabels() {
    if (this.getQuantityOfpages() < 10) {
      this.labelsForPaginatorButtons = [
        ...this.rangeStr(1, this.getQuantityOfpages())
      ];
      return null;
    }

    if (this.currentPage <= 5) {
      this.labelsForPaginatorButtons = [
        ...this.rangeStr(1, 7),
        "...",
        this.getQuantityOfpages()
      ];
      return null;
    }

    if (this.currentPage >= this.getQuantityOfpages() - 4) {
      this.labelsForPaginatorButtons = [
        1,
        "...",
        ...this.rangeStr(this.getQuantityOfpages() - 6, this.getQuantityOfpages())
      ];
      return null;
    }

    this.labelsForPaginatorButtons = [
      1,
      "...",
      ...this.rangeStr(this.currentPage - 2, this.currentPage + 2),
      "...",
      this.getQuantityOfpages()
    ];
  }
}
