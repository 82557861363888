import {WabelEntity} from "./wabel-entity.abstract";

export class VideoMeetingMemberAvailability extends WabelEntity {
    constructor(obj: any) {
        super(obj);
    }

    id: number;
    startDate: string;
    endDate: string;
}
