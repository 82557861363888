import {Component, Input, OnInit} from '@angular/core';
import {Company} from "../../../services/wabel-client/entities/company";
import {NdlLayoutService} from 'needl-lib';
import {Tag} from "../../../services/wabel-client/entities/tag";
import {BusinessMappingPriority} from "../../../services/wabel-client/entities/business_mapping_priority";

@Component({
    selector: 'app-company-profile',
    templateUrl: './company-profile.component.html',
    styleUrls: ['./company-profile.component.scss'],
})
export class CompanyProfileComponent implements OnInit {
    @Input() company: Company;
    @Input() businessMappingPriority: BusinessMappingPriority = null;
    businessInfo: Tag[] = [];
    disabledIndexes: number[] = [];
    currentIndex = 0;

    constructor(public layoutService: NdlLayoutService) {
    }

    ngOnInit() {
        this.getBusinessInfo();
    }

    getBusinessInfo(): void {
        if (!this.company.isBuyingAlliance()) {
            this.businessInfo.push(new Tag({
                name: (this.company.exportCountries.length ? this.company.exportCountries.length : '-'),
                tagType: {name: 'Countries of activity'}
            }));
        }
        this.businessInfo.push(new Tag({name: (this.company.ca ? this.company.ca.name : '-'), tagType: {name: 'Turnover'}}),
            new Tag({
                name: (this.company.numemployees ? this.company.numemployees.name : '-'),
                tagType: {name: 'Global workforce'}
            }));

        if (!this.company.isBuyingAlliance() && !this.company.isImporter()) {
            this.businessInfo.push(new Tag({
                name: (this.company.nbSellingPoints ? this.company.nbSellingPoints.name : '-'),
                tagType: {name: 'Number of selling points'}
            }));
        }

        this.businessInfo.push(new Tag({
            name: (this.company.yearEstablished ? 'Since ' + this.company.yearEstablished : '-'),
            tagType: {name: 'Activity'}
        }));
    }
}
