import gql from "graphql-tag";

export class OAuthGraphQL {
    static queries = {

        getOAuthConsentScreen: gql`
            query getOAuthConsentScreen( $oauthProvider: String! ) {
                oAuthConsentScreen( oauthProvider: $oauthProvider )
            }`
        ,

        fetchAttachedCalendars: gql`
            query fetchAttachedCalendars( $sync: Boolean ) {
                fetchAttachedCalendars( sync: $sync ) {
                    id
                    provider
                    lastUsedOn
                    memberThirdPartyCalendars{
                      items{
                        id
                        provider
                        calendarId
                        calendarName
                        isSelected
                      }
                    }
                }
            }`
        ,
        
        fetchFreeBusySlotFromCalendars: gql`
            query fetchFreeBusySlotFromCalendars( $startTime: String! , $endTime: String! , $timezone: String! , $idmember: Int ) {
                fetchFreeBusySlotFromCalendars( startTime: $startTime , endTime: $endTime , timezone: $timezone , idmember: $idmember )
            }`
        };

    static mutations = {

        updatePrimaryCalendar: gql`
            mutation updatePrimaryCalendar( $provider: String!, $calendarId: String! ) {
                updatePrimaryCalendar( provider: $provider, calendarId: $calendarId){
                    id
                }
            }
        `,

        revokeThirdPartyOauthFromNeedl: gql`
            mutation revokeThirdPartyOauthFromNeedl( $provider: String! ) {
                revokeThirdPartyOauthFromNeedl( provider: $provider )
            }
        `,
    };
}
