import {WabelEntity} from "./wabel-entity.abstract";
import {BusinessMappingPriority} from './business_mapping_priority';
import {BusinessMappingTag} from './business_mapping_tag';
import {Member} from "./member";

export class BusinessMapping extends WabelEntity {
    constructor(obj: any) {
        super(obj);
        if (obj) {
            this.businessMappingPriorities = obj.businessMappingPriorities?.items ? obj.businessMappingPriorities.items.map(bmp => new BusinessMappingPriority(bmp)) : [];
            this.prioritiesNumber = obj.businessMappingPriorities?.count ?? 0;
            this.businessMappingTags = obj.businessMappingTags?.items ? obj.businessMappingTags?.items.map(bmt => new BusinessMappingTag(bmt)) : [];
            this.member = obj.member ? new Member(obj.member) : null;
            this.tagsNumber = obj.businessMappingTags?.count ?? 0;
        }
    }

    id: number;
    businessMappingPriorities: BusinessMappingPriority[];
    businessMappingTags: BusinessMappingTag[];
    member: Member;
    prioritiesNumber: number;
    tagsNumber: number;
    completed: boolean;
}
