import {Company} from './company';
import {SourcingRequest} from './sourcing_request';
import {WabelEntity} from "./wabel-entity.abstract";

export class SourcingRequestBlacklistedCompany extends WabelEntity {
  constructor(obj: any) {
    super(obj);
    if (obj) {
      this.company = obj.company ? new Company(obj.company) : null;
      this.sourcingRequest = obj.sourcingRequest ? new SourcingRequest(obj.sourcingRequest) : null;
    }
  }

  id: number;
  name: string;
  company: Company;
  sourcingRequest: SourcingRequest;
}
