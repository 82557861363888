import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'kit-form-input-link-option',
    templateUrl: './kit-form-input-link-option.component.html',
    styleUrls: ['./kit-form-input-link-option.component.scss']
})
export class KitFormInputLinkOptionComponent implements OnInit {

    @Input() values: any[];
    @Input() selectValues;
    @Input() selectValueOption: string = 'id';
    @Input() selectLabelOption: string = 'name';
    @Input() fieldName: string = '';
    @Input() isMultiple: boolean = false;
    @Input() defaultInput: string = null;
    @Input() defaultSelect: string = null;
    @Input() defaultThird: string = null;
    @Input() placeholderValue: string = null;
    @Input() title: string = 'Pack volume/weight';
    @Input() withThird: boolean = false;
    @Input() titleThird: string = 'Average retail price';
    @Input() additionalInfoThird: string = null;
    @Input() helperThird: string = null;
    @Output() bindingChange: EventEmitter<any[]> = new EventEmitter<any[]>();
    @Input() showError: boolean = false;

    propagateChange: any = () => {
    };

    constructor() {
    }

    ngOnInit() {
        if (!this.values || !this.values.length) {
            this.values = [
                {input: null, select: null, third: null},
            ];
        }
    }

    addAnother() {
        this.values.push({input: this.defaultInput, select: this.defaultSelect, third: this.defaultThird});
    }

    onChange() {
        this.propagateChange(this.values);
        this.bindingChange.emit(this.values);
    }

    deleteLine(index) {
        if (index > -1) {
            this.values.splice(index, 1);
        }
        this.bindingChange.emit(this.values);
    }
}
