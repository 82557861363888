import {
    AfterViewInit,
    Component, Input, ViewChild
} from '@angular/core';
import {NdlInputBaseDirective} from "../input-base.directive";
import {GooglePlaceDirective} from "ngx-google-places-autocomplete";

@Component({
    selector: 'ndl-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss'],
    host: {
        'class': 'ndl-address',
        '(blur)': "onTouched()"
    }
})
export class NdlAddressComponent extends NdlInputBaseDirective {
    get error() {
        if (this.ngControl?.errors) {
            return "Please select a valid address.";
        }
        return null;
    }
}
