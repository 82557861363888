export abstract class WabelEntity {
    __typename: string;
    constructor(object: any) {
        this.copy(object);
    }

    copy(object: any) {
        Object.assign(this, object);
    }

    abstract get id();
}
