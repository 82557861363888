import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NdlThemeService} from '../../core/theme/theme.service';
import {NdlStepperComponent} from '../../steppers/stepper/stepper.component';
import {NdlStepComponent} from '../../steppers/step/step.component';

@Component({
  selector: 'ndl-steppers-demo',
  templateUrl: './steppers-demo.component.html',
})
export class NdlSteppersDemoComponent implements OnInit {
  formGroup1: FormGroup;
  formGroup2: FormGroup;
  formGroup3: FormGroup;
  formGroups: any;

  @ViewChild("stepper", {static: true, read: NdlStepperComponent}) stepper: NdlStepperComponent;
  @ViewChild("step1", {static: true, read: NdlStepComponent}) step1: NdlStepComponent;

  constructor(private _formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
      this.formGroup1 = this._formBuilder.group({
          nom: ['', Validators.required],
          prenom: ['', Validators.required],
          address: ['', Validators.required],
      });
      this.formGroup2 = this._formBuilder.group({
          companyname: ['', Validators.required],
          jobposition: ['', Validators.required],
          country: ['', Validators.required],
      });
      this.formGroup3 = this._formBuilder.group({
          product: ['', Validators.required],
          product2: ['', Validators.required],
          product3: ['', Validators.required]
      });
  }

  next() {
      this.formGroups = {
          form1: this.formGroup1,
          form2: this.formGroup2,
          form3: this.formGroup3
      };
      this.step1.completed = true;
      this.stepper.next();
  }
}
