import {Component, HostBinding, Input} from '@angular/core';

@Component({
    selector: 'ndl-divider',
    templateUrl: './divider.component.html',
    styleUrls: ['./divider.component.scss'],
    host: {
        'class': 'ndl-divider'
    }
})
export class NdlDividerComponent {
}
