import gql from "graphql-tag";

export class PackagingUnitGraphql {
  static fragments = {
    packagingUnit: gql `
            fragment packagingUnit on Packagingunit {
                idpackagingunit
                name
            }
        `
  };

  static queries = {
    packagingUnits: gql `
            query PackagingUnit {
                packagingUnits {
                    ...packagingUnit
                }
            }
            ${PackagingUnitGraphql.fragments.packagingUnit}
        `,
  };

  static mutations = {};
}