import {
    Component,
    ContentChild,
    ContentChildren,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    QueryList
} from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {NdlStatus} from '../core/types';
import {NdlCardFooterDirective} from './card-footer.directive';
import {NdlCardOptionComponent} from './card-option.component';
import {NdlCardStatusOutsideDirective} from "./card-status-outside.directive";

@Component({
    selector: 'ndl-card',
    templateUrl: './card.component.html',
    host: {
        'class': 'ndl-card'
    }
})
export class NdlCardComponent {
    @Input() contentClasses: string;
    @Input() get theme() {
        return this._theme;
    }

    set theme(theme) {
        this._theme = theme;
    }

    private _theme: NdlStatus = "default";

    @HostBinding('class.ndl-card-outlined')
    @Input() get outlined() {
        return this._outlined;
    }

    set outlined(outlined) {
        this._outlined = coerceBooleanProperty(outlined);
    }

    private _outlined: boolean;

    _row = false;
    @Input('horizontal') get row() {
        return this._row;
    }
    set row(row: boolean) {
        this._row = coerceBooleanProperty(row);
    }

    _noShadow = false;
    @HostBinding('class.ndl-card-no-shadow')
    @Input() get noShadow() {
        return this._noShadow;
    }
    set noShadow(noShadow: boolean) {
        this._noShadow = coerceBooleanProperty(noShadow);
    }

    _noOverflowHidden = false;
    @HostBinding('class.ndl-card-no-overflow-hidden')
    @Input() get noOverflowHidden() {
        return this._noOverflowHidden;
    }
    set noOverflowHidden(noOverflowHidden: boolean) {
        this._noOverflowHidden = coerceBooleanProperty(noOverflowHidden);
    }

    @Output() click: EventEmitter<any> = new EventEmitter<any>();
    @HostBinding('class.ndl-card-clickable')
    get isClickable() {
        return this.click.observers.length > 0;
    }

    @HostBinding('class.ndl-card-default')
    private get _isDefault() {
        return this._theme === "default";
    }

    @HostBinding('class.ndl-card-primary')
    private get _isPrimary() {
        return this._theme === "primary";
    }

    @HostBinding('class.ndl-card-secondary')
    private get _isSecondary() {
        return this._theme === "secondary";
    }

    @HostBinding('class.ndl-card-success')
    private get _isSuccess() {
        return this._theme === "success";
    }

    @HostBinding('class.ndl-card-info')
    private get _isInfo() {
        return this._theme === "info";
    }

    @HostBinding('class.ndl-card-warning')
    private get _isWarning() {
        return this._theme === "warning";
    }

    @HostBinding('class.ndl-card-danger')
    private get _isDanger() {
        return this._theme === "danger";
    }

    @HostBinding('class.ndl-card-neutral')
    private get _isNeutral() {
        return this._theme === "neutral";
    }

    @ContentChild(NdlCardFooterDirective) footer: NdlCardFooterDirective;
    @ContentChildren(NdlCardOptionComponent, {descendants: true}) options: QueryList<NdlCardOptionComponent>;
    @ContentChild(NdlCardStatusOutsideDirective) statusOutside: NdlCardStatusOutsideDirective;
}
