import {WabelEntity} from "./wabel-entity.abstract";
import {Registration} from "./registration";

export class Video extends WabelEntity {
    constructor(obj?: any) {
        super(obj);
        if (obj) {
            this.registrations = obj.registrations ? obj.registrations.map(registration => new Registration(registration)) : [];
        }
    }

    idvideo: number;
    hostId: string;
    description: string;
    dateCreated: string;
    dateUpdated: string;
    registrations: Registration[];

    get id() {
        return this.idvideo;
    }

    get iframeUrl(): string {
        return 'https://player.vimeo.com/video/' + this.hostId + '?embedparameter=value';
    }
}
