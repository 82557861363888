import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NdlTagsComponent} from './tags.component';
import {ReactiveFormsModule} from '@angular/forms';
import {NeedlFormFieldModule} from '../form-field/form-field.module';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from "@angular/material/form-field";


@NgModule({
    declarations: [
        NdlTagsComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatChipsModule,
        MatIconModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        NeedlFormFieldModule,
    ],
    exports: [
        NdlTagsComponent
    ]
})
export class NeedlTagsModule {
}
