import {Component, Injectable, OnDestroy, TemplateRef} from '@angular/core';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarRef,
    MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';
import {SnackbarConfig} from './snackbar';
import {NdlSnackbarTemplateComponent} from './snackbar-template.component';

@Injectable({
    providedIn: 'root'
})
export class NdlSnackbarService extends MatSnackBar {
    snackbarRef: MatSnackBarRef<any>;

    display(config?: SnackbarConfig) {
        this.snackbarRef = this.openFromComponent(NdlSnackbarTemplateComponent, {
            data: config.data,
            horizontalPosition: config?.horizontalPosition,
            verticalPosition: config?.verticalPosition,
            panelClass: config?.classes ? config?.classes.split(' ') : 'ndl-snackbar-overlay',
            duration: config?.duration,
        });
    }

    show(text: string, icon: string = null, duration: number = 5000, horizontalPosition: MatSnackBarHorizontalPosition = 'left', verticalPosition: MatSnackBarVerticalPosition = 'bottom', closeIcon = true) {
        this.snackbarRef = this.openFromComponent(NdlSnackbarTemplateComponent, {
            data: {
                content: text,
                icon: icon,
                closeIcon: closeIcon
            },
            horizontalPosition: horizontalPosition,
            verticalPosition: verticalPosition,
            panelClass: 'ndl-snackbar-overlay',
            duration: duration,
        });

        return this.snackbarRef;
    }

    close() {
        if (this.snackbarRef) {
            this.snackbarRef.dismiss();
        }
    }
}
