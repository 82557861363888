import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {VideoMeetingService} from "../../../services/wabel-client/services/video_meeting.service";
import {FormBuilder, FormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {VideoMeeting} from "../../../services/wabel-client/entities/video_meeting";
import {AuthService} from "../../../services/auth.service";
import {CompanyService} from "../../../services/wabel-client/services/company.service";
import {Company} from "../../../services/wabel-client/entities/company";
import {UtilsService} from "../../../services/utils.service";
import {Member} from "../../../services/wabel-client/entities/member";
import * as dayjs from 'dayjs';
import * as utc from "dayjs/plugin/utc";
import * as timezone from "dayjs/plugin/timezone";
import {NdlSnackbarService} from 'needl-lib';

@Component({
    selector: 'app-video-meeting-invite-colleagues',
    templateUrl: './video-meeting-invite-colleagues.component.html',
    styleUrls: ['./video-meeting-invite-colleagues.component.scss']
})
export class VideoMeetingInviteColleaguesComponent implements OnInit {

    @Input() videoMeeting: VideoMeeting;

    @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

    company: Company;
    memberLoading: number;
    anonymousColleagueForm: FormGroup;
    dayjs = dayjs;

    constructor(public authService: AuthService,
                public videoMeetingService: VideoMeetingService,
                private fb: FormBuilder,
                private companyService: CompanyService,
                public utilsService: UtilsService,
                private snackbar: NdlSnackbarService) {
    }

    ngOnInit() {
        dayjs.extend(utc);
        dayjs.extend(timezone);
        this.anonymousColleagueForm = this.fb.group({
            firstName: this.fb.control(null, Validators.required),
            lastName: this.fb.control(null, Validators.required),
            email: this.fb.control(null, [Validators.required, Validators.email]),
            jobTitle: this.fb.control(null)
        });

        this.companyService.getInformationForVideoMeetingInviteColleaguesModal(this.authService.getMember().company.idcompany).subscribe((company) => {
            this.company = company;
            this.sortCompanyMembers();
        });
    }

    sortCompanyMembers() {
        let that = this;
        this.company.members = this.company.members.sort(function(a, b) {
            let aParticipating = that.isMemberAlreadyParticipant(a);
            let bParticipating = that.isMemberAlreadyParticipant(b);
            let aStatus = a.status !== 'locked' && a.status !== 'rejected';
            let bStatus = b.status !== 'locked' && b.status !== 'rejected';

            if (aParticipating && !bParticipating) {
                return -1;
            }
            if (bParticipating && !aParticipating) {
                return 1;
            }
            if (aStatus && !bStatus) {
                return -1;
            }
            if (bStatus && !aStatus) {
                return 1;
            }

            return a.fullname.localeCompare(b.fullname);
        });
    }

    isMemberAlreadyParticipant(member: Member): boolean {
        return this.videoMeeting.videoMeetingParticipants.findIndex(vmp => vmp.member.idmember === member.idmember) > -1;
    }

    inviteMember(member: Member) {
        if (this.memberLoading) {
            return;
        }
        this.memberLoading = member.idmember;
        this.videoMeetingService.inviteColleagueToVideoMeeting(this.videoMeeting.id, member.idmember).subscribe((vmp) => {
            this.videoMeeting.videoMeetingParticipants.push(vmp);
            this.sortCompanyMembers();
            this.memberLoading = null;
            this.snackbar.show('Your invitation to participate to the video meeting has been sent to your colleague.', 'check');
        }, (e) => {
            console.error(e);
            this.memberLoading = null;
        });
    }

    inviteAnonymousMember(value) {
        this.anonymousColleagueForm.markAllAsTouched();
        if (this.anonymousColleagueForm.invalid || this.memberLoading) {
            return;
        }
        this.memberLoading = 1;
        this.videoMeetingService.inviteAnonymousColleagueToVideoMeeting(this.videoMeeting.id, value.firstName, value.lastName, value.email, value.jobTitle).subscribe((vmp) => {
            this.videoMeeting.videoMeetingParticipants.push(vmp);
            this.company.members.push(vmp.member);
            this.sortCompanyMembers();
            this.formGroupDirective.resetForm();
            this.memberLoading = null;
            this.snackbar.show('Your invitation to participate to the video meeting has been sent to your colleague.', 'check');
        }, (e) => {
            console.error(e);
            this.memberLoading = null;
        });
    }
}
