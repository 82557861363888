import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NdlTextComponent} from './text.component';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {NeedlFormFieldModule} from '../form-field/form-field.module';
import {MatFormFieldModule} from '@angular/material/form-field';



@NgModule({
  declarations: [
      NdlTextComponent
  ],
  imports: [
      CommonModule,
      ReactiveFormsModule,
      MatButtonModule,
      MatIconModule,
      MatInputModule,
      MatFormFieldModule,
      NeedlFormFieldModule
  ],
  exports: [
      NdlTextComponent
  ]
})
export class NeedlTextModule { }
