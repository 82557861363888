<ndl-form-field #formField [suffix]="suffix" [prefix]="prefix" [label]="label" [hint]="hint" [errors]="errors">
    <ng-template #prefix>
        <ng-content select="[ndl-prefix]"></ng-content>
    </ng-template>
    <ndl-select-multi-level-field [ngControl]="ngControl" [required]="required" [maxHeight]="maxHeight" [height]="height"
                          [errorState]="innerFormControl?.errors && innerFormControl?.touched"
                          [placeholder]="placeholder" [displayMode]="displayMode"
                          [identifier]="identifier" [options]="options" [groups]="groups" [searchable]="searchable"
                          (change)="onChange($event)" (blur)="onTouched()"
                          [clearable]="clearable" [onlyLastLevel]="onlyLastLevel" [maximumSelectedOption]="maximumSelectedOption"
                          [title]="title" [showBasicTitle]="showBasicTitle">
    </ndl-select-multi-level-field>

    <ng-template #suffix>
        <ng-content select="[ndl-suffix]"></ng-content>
    </ng-template>
</ndl-form-field>
