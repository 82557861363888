import {AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthService} from "../../../services/auth.service";
import {Location} from "@angular/common";
import {SearchSuggestionComponent} from "./search-suggestion/search-suggestion.component";
import {environment} from "../../../../environments/environment";
import {UtilsService} from "../../../services/utils.service";
import {TemplateService} from "../../../services/template.service";
import {Router} from "@angular/router";
import {RIGHT} from "../../../services/wabel-client/security/right.const";
import {
    DASHBOARD_MODAL_INTERNALNAME,
    EXPLORE_MARKET_MENU_TOUR_INTERNALNAME,
    MemberNotification
} from "../../../services/wabel-client/entities/member_notification";
import {MemberService} from "../../../services/wabel-client/services/member.service";
import {NeedlSecurityService} from "@wabel/needl-security";
import {NeedlTourService} from "../../../services/needl-tour.service";
import {SearchService} from "../../../services/search.service";
import {Subscription} from "rxjs";
import {ResourceService} from "../../../services/wabel-client/services/resource.service";

@Component({
    selector: 'app-navbar',
    templateUrl: './app-navbar.component.html',
    styleUrls: ['./app-navbar.component.scss']
})

export class AppNavbarComponent implements OnInit, AfterViewInit, OnDestroy {
    currentSubmenuOpened: string;
    currentSubSubmenuOpened: string;
    bo_url: string = environment.needl_bo_url;
    needl_full_url: string = environment.needl_url;
    needl_back_end_url: string = environment.needl_back_end_url;
    currentPath: string;
    isMobileMenuOpened = false;
    forceOpeningSearch = false;
    isDuringTour = false;
    isMobileNavbar = false;
    readonly RIGHT = RIGHT;
    updateNavBarOpenedMenu$: Subscription;

    @ViewChild(SearchSuggestionComponent, { static: false }) searchInputComponent: SearchSuggestionComponent;

    @HostListener('document:click', ['$event'])
    clickOutsideComponent(e) {
        if (this.currentSubSubmenuOpened && e.target.id !== this.currentSubSubmenuOpened && !this.isDuringTour) {
            this.currentSubSubmenuOpened = null;
        }
        if (!this.currentSubSubmenuOpened && this.currentSubmenuOpened && e.target.id !== this.currentSubmenuOpened && !this.isDuringTour) {
            this.currentSubmenuOpened = null;
        }
    }

    @HostListener('window:resize', [])
    onResize() {
        this.isMobileNavbar = window.innerWidth < 1200;
    }

    constructor(
        private location: Location,
        public authService: AuthService,
        public utilsService: UtilsService,
        private router: Router,
        public templateService: TemplateService,
        private needlTourService: NeedlTourService,
        private memberService: MemberService,
        private needlSecurityService: NeedlSecurityService,
        public searchService: SearchService) {
    }

    ngOnInit() {
        if (this.location.path() !== '') {
            this.currentPath = this.location.path();
        }
        this.onResize();
        this.updateNavBarOpenedMenu$ = this.needlTourService.updateNavBarOpenedMenu$.subscribe(data => {
            this.currentSubmenuOpened = data.id;
            this.isDuringTour = data.duringTour;
        });
    }

    ngOnDestroy() {
        if (this.updateNavBarOpenedMenu$) {
            this.updateNavBarOpenedMenu$.unsubscribe();
        }
    }

    checkIfPasswordModalIsFinished() {
        this.templateService._displayForcePasswordChangeIsFinished.subscribe(x => {
            if (x) {
                this.needlTourService.start();
            }
        });
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.router.url === '/home' && !this.isMobileNavbar && this.authService.getMember() && !this.authService.getMember().isMasquerading && this.authService.getMember().hasNotificationAccepted(DASHBOARD_MODAL_INTERNALNAME) && !this.authService.getMember().hasNotificationAccepted(EXPLORE_MARKET_MENU_TOUR_INTERNALNAME)) {
                this.showTourForExploreMarketMenu();
                this.checkIfPasswordModalIsFinished();
                // TODO: We do this to put the anchor explore market at the right place
                setTimeout(() => {
                    window.dispatchEvent(new Event('resize'));
                }, 100);
            }
        }, 3000);
    }

    changeSearchState(event: string) {
        this.searchInputComponent.changeSearchState(event);
    }

    get inSearchPage() {
        return this.router.url.indexOf('search') !== -1;
    }

    toggleCurrentSubmenuOpened(idMenu: string) {
        this.currentSubmenuOpened === idMenu ? this.currentSubmenuOpened = null : this.currentSubmenuOpened = idMenu;
    }

    toggleCurrentSubSubmenuOpened(idMenu: string) {
        this.currentSubSubmenuOpened === idMenu ? this.currentSubSubmenuOpened = null : this.currentSubSubmenuOpened = idMenu;
    }

    showTourForExploreMarketMenu() {
        const steps = [];

        steps.push({
            title: 'Discover our new Explore Market menu',
            anchorId: 'explore-market',
            content: '',
        });

        if (this.needlSecurityService.hasRight(RIGHT.CAN_MANAGE_MY_SOURCING_REQUESTS_BUYER)) {
            steps.push({
                anchorId: 'explore-market-buyer',
                title: 'Product requests',
                content: 'Share either your tenders or urgent product requests and manage qualified supplier applications.',
            });
        }

        if (this.needlSecurityService.hasRight(RIGHT.CAN_MANAGE_MY_SOURCING_NEEDS_BUYER)) {
            steps.push({
                anchorId: 'explore-market-buyer',
                title: 'Category benchmark',
                content: 'Express your current products and trends of interest, to find instant recommendations that match your requirements.',
            });
        }

        if (this.needlSecurityService.hasRight(RIGHT.CAN_MANAGE_MY_SOURCING_NEEDS_SELLER)) {
            steps.push({
                anchorId: 'explore-market-supplier',
                title: 'Business opportunities',
                content: 'You can discover what buyers are looking for and when they are looking for it: trends, sourcing projects, tenders. We only display what matches your range of products.'
            });
        }

        if (this.needlSecurityService.hasRight(RIGHT.CAN_ACCESS_ANALYTICS_DASHBOARD_SELLER)) {
            steps.push({
                anchorId: 'explore-market-supplier',
                title: 'Analytics',
                content: 'Get details about your company & product visibility and discover trends on buyers’ interests and competition within your scope.'
            });
        }

        if (steps.length < 2) {
            return;
        }

        this.needlTourService.initialize(steps, {
            enableBackdrop: true,
            isAsync: true,
            nextBtnTitle: 'Ok',
            endBtnTitle: 'Got it!',
            disablePageScrolling: true
        });
        this.currentSubmenuOpened = 'my_business';
        this.isDuringTour = true;
        if (!this.templateService.isDisplayForcePasswordChange) {
            this.needlTourService.start();
        }
        this.needlTourService
            .getTourService()
            .end$
            .subscribe(
                () => {
                    this.isDuringTour = false;
                    this.memberService.acceptNotification(EXPLORE_MARKET_MENU_TOUR_INTERNALNAME, 'modal').subscribe((notifications: MemberNotification[]) => {
                        this.authService.getMember().setNotifications(notifications);
                    });
                }
            );
    }

    isRouteActive(route: string) {
        return this.router.url.includes(route);
    }

    get clickToMemberShipPage() {
        return ResourceService.CLICK_TO_MEMBERSHIP_PAGE;
    }
}
