import {WabelEntity} from "./wabel-entity.abstract";
import {PackagingUnit} from "./packaging_unit";

export class PackagingFormat extends WabelEntity {
    idpackagingformat: number;
    packagingunit: PackagingUnit;
    value: string;
    price: number;
    name: string;
    description: string;

    get id(): number {
        return this.idpackagingformat;
    }
}
