import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {BusinessMapping} from '../services/wabel-client/entities/business_mapping';
import {BusinessMappingService} from '../services/wabel-client/services/business-mapping.service';
import {NdlLoadingService, NdlResolver} from 'needl-lib';

@Injectable()
export class BusinessMappingResolver extends NdlResolver<BusinessMapping> {

    constructor(loadingService: NdlLoadingService, private businessMappingService: BusinessMappingService) {
        super(loadingService);
    }

    override getResolveObservable(): Observable<BusinessMapping> {
        return this.businessMappingService.myBusinessMapping('network-only').pipe(take(1));
    }
}
