import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'kit-progress-bar',
  templateUrl: './kit-progress-bar.component.html',
  styleUrls: ['./kit-progress-bar.component.scss']
})
export class KitProgressBarComponent implements OnInit {
    @Input() title:string='';
    @Input() value:string;
    @Input() barWidth:string='50';
  constructor() { }

  ngOnInit() {
  }

}
