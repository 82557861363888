<ndl-form-field #formField [prefix]="prefix" [suffix]="suffix" [label]="label" [hint]="hint" [errors]="errors">
    <ng-template #prefix>
        <ng-content select="[ndl-prefix]"></ng-content>
    </ng-template>

    <mat-chip-list #chipList [multiple]="multiple" [formControl]="innerFormControl" [selectable]="false" [required]="required">
        <mat-chip
            *ngFor="let value of selectedValues"
            [value]="value.value"
            (removed)="remove(value)">
            {{displayFullPath ? getFullPath(value) : value.label}}
            <button matChipRemove *ngIf="(multiple || clearable) && !disabled">
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip>
        <input
            [matMenuTriggerFor]="itemMenu"
            #levelOneTrigger="matMenuTrigger"
            (click)="disabled ? null : levelOneTrigger.openMenu()"
            [placeholder]="placeholder"
            #input
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            (input)="terms$.next($event.target.value)"
            [disabled]="disabled"
        >
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete"></mat-autocomplete>

    <ng-template #suffix>
        <ng-content select="[ndl-suffix]"></ng-content>
    </ng-template>
</ndl-form-field>

<mat-menu #itemMenu="matMenu">
    <ng-container *ngTemplateOutlet="recursiveMenu; context: {$implicit: filteredOptionsMenuItem, parentMenuRef: itemMenu}"></ng-container>
    <button mat-menu-item *ngIf="!hasSubItems(filteredOptionsMenuItem)">
        <span>No option found.</span>
    </button>
</mat-menu>

<ng-template #recursiveMenu let-rootItem let-parentMenuRef="parentMenuRef">
    <ng-container appMenupatch [parentMenu]=parentMenuRef>
        <ng-container *ngFor="let childItem of rootItem.children">
            <ng-container *ngIf="hasSubItems(childItem)">
                <button #triggerButton="matMenuItem" #trigger="matMenuTrigger" [matMenuTriggerFor]="patch(itemMenuNew, triggerButton, trigger, parentMenuRef)" mat-menu-item>
                    {{childItem.label}}
                </button>
                <mat-menu #itemMenuNew="matMenu">
                    <ng-container *ngTemplateOutlet="recursiveMenu; context: {$implicit: childItem, parentMenuRef: itemMenuNew}"></ng-container>
                </mat-menu>
            </ng-container>
            <ng-container *ngIf="!hasSubItems(childItem)">
                <button *ngIf="!childItem.route" mat-menu-item (click)="multiple ? $event.stopPropagation() : null; toggleOption(childItem)" [class.selected]="isSelected(childItem)">
                    <ndl-checkbox *ngIf="multiple" [option]="childItem" [checked]="isSelected(childItem)"></ndl-checkbox>
                    <span *ngIf="!multiple">{{childItem.label}}</span>
                </button>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-template>
