<div class="container-fluid">
    <form #form class="row row-cols-4" autocomplete="off">
        <div class="col">
            <ndl-text #name="ngModel" [(ngModel)]="text" required name="caca" label="Search"
                      placeholder="E.g. Products" clearable>
                <mat-icon ndl-prefix>search</mat-icon>
                <ndl-error *ngIf="name.errors?.required">Oulala grosse erreur!</ndl-error>
            </ndl-text>
            <h3>Text</h3>
            <p>
                Value : {{text | json}}<br>
                Is touched : {{name.touched}}<br>
                Is invalid : {{name.invalid}}<br>
                Is dirty : {{name.dirty}}<br>
                Has errors : {{name.errors | json}}<br>
            </p>
        </div>

        <div class="col">
            <ndl-password required #passwordInput="ngModel" #ndlPassword name="pipi" label="Password"
                          placeholder="Enter a password" [(ngModel)]="password"></ndl-password>
            <h3>Password</h3>
            <p>
                Value : {{password | json}}<br>
                Is touched : {{passwordInput.touched}}<br>
                Is invalid : {{passwordInput.invalid}}<br>
                Is dirty : {{passwordInput.dirty}}<br>
                Has errors : {{passwordInput.errors | json}}<br>
            </p>
        </div>

        <div class="col">
            <ndl-password-check [password]="ndlPassword" required #passwordCheckInput="ngModel" name="popo"
                                label="Verify Password" placeholder="Verify your password"
                                [(ngModel)]="passwordCheck"></ndl-password-check>

            <h3>Password Check</h3>
            <p>
                Value : {{passwordCheck | json}}<br>
                Is touched : {{passwordCheckInput.touched}}<br>
                Is invalid : {{passwordCheckInput.invalid}}<br>
                Is dirty : {{passwordCheckInput.dirty}}<br>
                Has errors : {{passwordCheckInput.errors | json}}<br>
            </p>
        </div>

        <div class="col">
            <ndl-textarea #textareaInput="ngModel" required name="pupu" [(ngModel)]="textarea" label="Description"
                          placeholder="Big text"></ndl-textarea>

            <h3>Text Area</h3>
            <p>
                Value : {{textarea | json}}<br>
                Is touched : {{textareaInput.touched}}<br>
                Is invalid : {{textareaInput.invalid}}<br>
                Is dirty : {{textareaInput.dirty}}<br>
                Has errors : {{textareaInput.errors | json}}<br>
            </p>
        </div>

        <div class="col">
            <ndl-select #selectInput="ngModel" required name="plpl" [(ngModel)]="selectvalue" label="Select"
                        placeholder="Select an option">
                <option ndl-option>None</option>
                <option ndl-option value="caca">caca</option>
                <option ndl-option value="pipi">pipi</option>
                <option ndl-option value="popo">popo</option>
            </ndl-select>

            <h3>Select</h3>
            <p>
                Value : {{selectvalue | json}}<br>
                Is touched : {{selectInput.touched}}<br>
                Is invalid : {{selectInput.invalid}}<br>
                Is dirty : {{selectInput.dirty}}<br>
                Has errors : {{selectInput.errors | json}}<br>
            </p>
        </div>

        <div class="col">
            <ndl-select multiple #selectMultipleInput="ngModel" required name="plplm" [(ngModel)]="selectvalueMultiple"
                        label="Multiple Select" placeholder="Select options">
                <option ndl-option value="caca">caca</option>
                <option ndl-option value="pipi">pipi</option>
                <option ndl-option value="popo">popo</option>
            </ndl-select>

            <h3>Multiple Select</h3>
            <p>
                Value : {{selectvalueMultiple | json}}<br>
                Is touched : {{selectMultipleInput.touched}}<br>
                Is invalid : {{selectMultipleInput.invalid}}<br>
                Is dirty : {{selectMultipleInput.dirty}}<br>
                Has errors : {{selectMultipleInput.errors | json}}<br>
            </p>
        </div>

        <div class="col">
            <ndl-phone #phoneInput="ngModel" [(ngModel)]="phone" required name="phonphone" label="Phone"></ndl-phone>

            <h3>Phone</h3>
            <p>
                Value : {{phone | json}}<br>
                Is touched : {{phoneInput.touched}}<br>
                Is invalid : {{phoneInput.invalid}}<br>
                Is dirty : {{phoneInput.dirty}}<br>
                Has errors : {{phoneInput.errors | json}}<br>
            </p>
        </div>

        <div class="col">
            <ndl-upload #uploadInput="ngModel" type="image" autoupload [(ngModel)]="upload" required name="upload"
                        label="File Upload"></ndl-upload>

            <h3>Upload</h3>
            <p>
                Value : {{upload | json}}<br>
                Is touched : {{uploadInput.touched}}<br>
                Is invalid : {{uploadInput.invalid}}<br>
                Is dirty : {{uploadInput.dirty}}<br>
                Has errors : {{uploadInput.errors | json}}<br>
            </p>
        </div>

        <div class="col">
            <ndl-radio #radioInput="ngModel" [(ngModel)]="radioValue" required name="radioName" label="Radios">
                <option ndl-option value="test">Le Test</option>
                <option ndl-option value="other">Autre</option>
            </ndl-radio>

            <h3>Radios</h3>
            <p>
                Value : {{radioValue | json}}<br>
                Is touched : {{radioInput.touched}}<br>
                Is invalid : {{radioInput.invalid}}<br>
                Is dirty : {{radioInput.dirty}}<br>
                Has errors : {{radioInput.errors | json}}<br>
            </p>
        </div>

        <div class="col">
            <ndl-checkboxes-new #checkboxInput="ngModel" [(ngModel)]="checkboxValue" required name="checkboxName"
                                label="Checkboxes">
                <option ndl-option value="first">
                    First
                </option>
                <option ndl-option value="second">Second</option>
                <option ndl-option value="third">Third</option>
            </ndl-checkboxes-new>

            <h3>Checkboxes</h3>
            <p>
                Value : {{checkboxValue | json}}<br>
                Is touched : {{checkboxInput.touched}}<br>
                Is invalid : {{checkboxInput.invalid}}<br>
                Is dirty : {{checkboxInput.dirty}}<br>
                Has errors : {{checkboxInput.errors | json}}<br>
            </p>
        </div>

        <div class="col">
            <ndl-wysiwyg #wysiwygInput="ngModel" [(ngModel)]="wysiwyg" required name="wysiwygName" label="Wysiwyg">
            </ndl-wysiwyg>

            <h3>Wysiwyg</h3>
            <p>
                Value : {{wysiwyg | json}}<br>
                Is touched : {{wysiwygInput.touched}}<br>
                Is invalid : {{wysiwygInput.invalid}}<br>
                Is dirty : {{wysiwygInput.dirty}}<br>
                Has errors : {{wysiwygInput.errors | json}}<br>
            </p>
        </div>

        <div class="col">
            <ndl-checkboxes-new #treeInput="ngModel" [(ngModel)]="checkboxTreeValue" required name="treeName"
                                label="Checkbox Tree" searchable placeholder="E.g. Search in my tree">
                <ndl-option-group label="first" value="1">
                    <optgroup ndl-option-group label="Alphabet" value="A1">
                        <option ndl-option value="a">A</option>
                        <option ndl-option value="b">B</option>
                        <option ndl-option value="c">C</option>
                        <option ndl-option value="d">D</option>
                    </optgroup>
                    <optgroup ndl-option-group label="number" value="N1">
                        <option ndl-option value="1">1</option>
                        <option ndl-option value="2">2</option>
                        <option ndl-option value="3">3</option>
                        <option ndl-option value="4">4</option>
                    </optgroup>
                </ndl-option-group>
            </ndl-checkboxes-new>

            <h3>Checkbox Tree</h3>
            <p>
                Value : {{checkboxTreeValue | json}}<br>
                Is touched : {{treeInput.touched}}<br>
                Is invalid : {{treeInput.invalid}}<br>
                Is dirty : {{treeInput.dirty}}<br>
                Has errors : {{treeInput.errors | json}}<br>
            </p>
        </div>

        <div class="col">
            <ndl-address #addressInput="ngModel" [(ngModel)]="address" required name="address" label="Address">
            </ndl-address>

            <h3>Address</h3>
            <p>
                Value : {{address | json}}<br>
                Is touched : {{addressInput.touched}}<br>
                Is invalid : {{addressInput.invalid}}<br>
                Is dirty : {{addressInput.dirty}}<br>
                Has errors : {{addressInput.errors | json}}<br>
            </p>
        </div>

        <div class="col">
            <ndl-tags #tagsInput="ngModel" [(ngModel)]="tags" required allowCustomValues name="tags" label="Tags"
                      placeholder="Type to add tags">
                <option ndl-option value="pipi">Test 1</option>
                <option ndl-option value="papa">Test 2</option>
                <option ndl-option value="popo">Test 3</option>
            </ndl-tags>

            <h3>Tags</h3>
            <p>
                Value : {{tags | json}}<br>
                Is touched : {{tagsInput.touched}}<br>
                Is invalid : {{tagsInput.invalid}}<br>
                Is dirty : {{tagsInput.dirty}}<br>
                Has errors : {{tagsInput.errors | json}}<br>
            </p>
        </div>
    </form>

    <button (click)="touch()">TOUCH</button>
</div>
