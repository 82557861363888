import {WabelEntity} from "./wabel-entity.abstract";
import { Member } from "./member";
import { CompanyProfileSubmission } from "./company_profile_submission";

export class CompanyProfileFeedback extends WabelEntity {

    constructor(obj : any) {
        super(obj);
        if (obj) {
            this.companyprofilesubmission = obj.companyprofilesubmission ? new CompanyProfileSubmission(obj.companyprofilesubmission) : null;
            this.member = obj.member ? new Member(obj.member) : null;
        }
    }

    idfeedback: number
    companyprofilesubmission: CompanyProfileSubmission
    member: Member
    status: string
    createdAt: string
    updatedAt: string
    rejectionReason: string;

    get id(): number {
        return this.idfeedback;
    }

    set id(id: number) {
        this.idfeedback = id;
    }

}
