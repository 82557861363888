import {WabelEntity} from "./wabel-entity.abstract";
import {CompanyType} from "./company_type";
import {Company} from "./company";
import { Tag } from "./tag";
import {BusinessMapping} from "./business_mapping";
import {BusinessMappingPriorityRecommendation} from "./business_mapping_priority_recommendation";

export class BusinessMappingPriority extends WabelEntity {
    constructor(obj: any) {
        super(obj);
        if (obj) {
            this.company = obj.company ? new Company(obj.company) : null;
            this.region = obj.region ? new Tag(obj.region) : null;
            this.countries = obj.countries && obj.countries.items ? obj.countries.items.map(country => new Tag(country)) : [];
            this.businessMappingPriorityRecommendations = obj.businessMappingPriorityRecommendations && obj.businessMappingPriorityRecommendations.items ? obj.businessMappingPriorityRecommendations.items.map(prospect => new BusinessMappingPriorityRecommendation(prospect)) : [];
            this.activities = obj.activities && obj.activities.items ? obj.activities.items.map(activity => new Tag(activity)) : [];
            this.companyTypes = obj.companyTypes ? obj.companyTypes.map(companyType => new CompanyType(companyType)) : [];
            this.recommendations = obj.recommendations ? obj.recommendations.map(reco => new Company(reco)) : [];
            this.countryRecommendationFilters = obj.countryRecommendationFilters
            && typeof obj.countryRecommendationFilters === 'string' ? JSON.parse(obj.countryRecommendationFilters) : null;
            this.businessMapping = obj.businessMapping ? new BusinessMapping(obj.businessMapping) : null;
        }
    }



    id: number;
    company: Company;
    businessMappingPriorityRecommendations: BusinessMappingPriorityRecommendation[];
    goal: string;
    priority: number;
    countries: Tag[];
    activities: Tag[];
    companyTypes: CompanyType[];
    region: Tag;
    recommendations: Company[];
    countryRecommendationFilters: any;
    businessMapping: BusinessMapping;
}
