<mat-drawer-container>
    <mat-drawer opened mode="side">
        <ng-content select="[ndl-left]"></ng-content>
    </mat-drawer>
    <mat-drawer-content>
        <ng-content></ng-content>
    </mat-drawer-content>
    <mat-drawer opened mode="side" position="end">
        <ng-content select="[ndl-right]"></ng-content>
    </mat-drawer>
</mat-drawer-container>
