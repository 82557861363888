import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ndl-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
    host: {
        "class": "ndl-page"
    }
})
export class NdlPageComponent {
}
