import {
    AfterContentInit,
    AfterViewInit, ChangeDetectorRef,
    Component, ContentChild,
    ContentChildren, Directive, ElementRef,
    EventEmitter, HostListener, Inject,
    Input,
    OnDestroy,
    OnInit, Optional,
    Output,
    QueryList,
    TemplateRef
} from '@angular/core';
import {NdlPaginatorBase} from "./base-paginator.component";
import {MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorDefaultOptions, MatPaginatorIntl} from "@angular/material/paginator";

@Component({
    selector: "ndl-scroll-paginator",
    templateUrl: "./scroll-paginator.component.html"
})
export class NdlScrollPaginatorComponent<T = any> extends NdlPaginatorBase implements OnInit {
    idle: 500;
    idleTimeout;

    constructor(
        intl: MatPaginatorIntl,
        changeDetectorRef: ChangeDetectorRef,
        private el: ElementRef,
        @Optional() @Inject(MAT_PAGINATOR_DEFAULT_OPTIONS) defaults?: MatPaginatorDefaultOptions
    ) {
        super(intl, changeDetectorRef, defaults);
    }

    @Input() loading = false;
    @Input() stopListen = false;

    ngOnInit() {
        window.addEventListener('scroll', this.isScrolledIntoView.bind(this), true);
    }

    isScrolledIntoView() {
        if (this.stopListen) {
            return;
        }
        const rect = this.el.nativeElement.getBoundingClientRect();
        if (
            ((Math.round(rect.top) <= Math.round(window.innerHeight) && Math.round(rect.top) > 0)
                || (Math.round(rect.bottom) <= Math.round(window.innerHeight) && Math.round(rect.bottom) > 0)) &&
            ((Math.round(rect.left) <= Math.round(window.innerWidth) && Math.round(rect.left) > 0)
                || (Math.round(rect.right) <= Math.round(window.innerWidth) && Math.round(rect.right) > 0)) &&
            this.loading === false
        ) {
            if (this.idleTimeout) {
                clearTimeout(this.idleTimeout);
            }
            this.idleTimeout = setTimeout(() => this.nextPage(), this.idle);
        }
    }

    displayStrategy(oldData: any[], newData: any[]): T[] {
        return this.pageIndex === 0 ? newData : oldData.concat(newData);
    }
}
