import {Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'kit-form-checkbox',
    templateUrl: './kit-form-checkbox.component.html',
    styleUrls: ['./kit-form-checkbox.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => KitFormCheckboxComponent),
        multi: true
    }]
})
export class KitFormCheckboxComponent implements ControlValueAccessor, OnChanges {

    @Input() fieldName: string;
    @Input() label: string;
    @Input() options: any[];
    @Input() values: string[];
    @Input() smallSpace: boolean = false;
    @Input() isDisplayMode: boolean = false;
    @Input() nbPerLine: number = 2;
    @Input() labelOptionAttribute: string;
    @Input() idElementOptionAttribute: string;
    @Input() valueOptionAttribute: string;
    @Input() maximumOptionsShowedByDefault: number;

    //validation
    @Input() isRequired: boolean = false;
    @Input() doCheck: boolean = false;
    @Input() showError: boolean = false;
    @Input() errorMessage: string = 'At least one choice must be selected';

    @Output() elementHasBeenChecked: EventEmitter<any> = new EventEmitter();

    @Input() cyName: string;

    selectedValue: string[] = [];
    expanded = false;

    propagateChange: any = () => {
    };

    constructor() {
    }

    getNbCol(): number {
        if (this.nbPerLine == 1) return 12;
        if (this.nbPerLine == 2) return 6;
        if (this.nbPerLine == 3) return 4;
        if (this.nbPerLine == 4) return 3;

        return 6;
    }

    change(e, type) {
        if (e.checked) {
            this.selectedValue.push(type);
        }
        else {
            let updateItem = this.selectedValue.find(this.findIndexToUpdate, type[this.valueOptionAttribute]);

            let index = this.selectedValue.indexOf(updateItem);

            this.selectedValue.splice(index, 1);
        }

    }

    findIndexToUpdate(type) {
        return type[this.valueOptionAttribute] === this;
    }


    check(value1, value2) {
        return (value1.filter(item => item[this.valueOptionAttribute] == value2)).length
    }


    updateValue(e: Event, option) {
        if (option.disabled) {
            return;
        }
        this.addOrRemove(this.selectedValue, option[this.valueOptionAttribute]);
        this.propagateChange(this.selectedValue);

        this.checkError();
    }

    addOrRemove(array, value) {
        let index = array.indexOf(value);

        if (index === -1) {
            array.push(value);
        } else {
            array.splice(index, 1);
        }
        this.elementHasBeenChecked.emit(value);
    }

    isChecked(value: string) {
        return this.selectedValue.indexOf(value) >= 0;
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(value: any): void {
        if (value) {
            this.selectedValue = value;
        }
    }

    checkError() {
        this.showError = this.isRequired && this.selectedValue.length == 0 && this.doCheck;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.doCheck && changes.doCheck.currentValue)
            this.checkError();
    }


}
