import {
    AfterViewInit,
    Component, ContentChildren, HostBinding, HostListener, Input, OnDestroy, QueryList, ViewChildren
} from '@angular/core';
import {MatStep, MatStepper} from '@angular/material/stepper';
import {NdlStepComponent} from '../step/step.component';
import {BehaviorSubject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Component({
    selector: 'ndl-stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss'],
    providers: [
        {provide: MatStepper, useExisting: NdlStepperComponent}
    ],
    host: {
        "class": "ndl-stepper"
    }
})
export class NdlStepperComponent extends MatStepper implements AfterViewInit {
    @ContentChildren(NdlStepComponent, {descendants: true}) _steps: QueryList<NdlStepComponent>;

    private _displayIndex = false;
    @Input() set displayIndex(display: boolean) {
        this._displayIndex = coerceBooleanProperty(display);
    }

    get displayIndex() {
        return this._displayIndex;
    }

    labelPosition: "bottom" | "end" = "bottom";

    ngAfterViewInit() {
        super.ngAfterViewInit();
        this._steps.changes.pipe(takeUntil(this._destroyed)).subscribe((steps: NdlStepComponent[]) => {
            steps.forEach((s, i) => {
                s.index = i;
                s._stepper = this;
            });
        });
        this._steps.notifyOnChanges();
    }

    _stepIsNavigable(index: number, step: MatStep): boolean {
        return super._stepIsNavigable(index, step);
    }

    verticalStepperCompareWith(a, b) {
        return a === this.selectedIndex;
    }
}
