import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Tag} from "../../../services/wabel-client/entities/tag";

@Component({
    selector: 'tag-picker',
    templateUrl: './tag-picker.component.html',
    styleUrls: ['./tag-picker.component.scss']
})
export class TagPickerComponent implements OnInit {

    @Input() tags: Tag[];
    @Input() tagTypeName: string;
    @Input() withIconsForLevel0 = false;
    @Output() tagConfirmed: EventEmitter<Tag> = new EventEmitter<Tag>();
    @Output() tagPickerClosed: EventEmitter<any> = new EventEmitter<any>();
    selectedTag: Tag;
    currentTag: Tag;
    selectedTags: Tag[] = [];

    constructor() {
    }

    ngOnInit() {
    }

    selectTag(tag: Tag) {
        if (tag.children.length) {
            this.selectedTags.push(tag);
        } else {
            this.selectedTag = tag;
        }
    }

    getCurrentTag() {
        if (!this.selectedTags || !this.selectedTags.length) {
            return null;
        }
        return this.selectedTags[this.selectedTags.length - 1];
    }

    back() {
        this.selectedTag = null;
        this.selectedTags.splice(-1, 1);
    }

    getBreadcrumbText() {
        if (!this.selectedTags || !this.selectedTags.length) {
            return '-';
        }

        let breadcrumbText = '';
        for (const tag of this.selectedTags) {
            if (tag.id !== this.selectedTags[0].id) {
                breadcrumbText += ' > ' + tag.name;
            } else {
                breadcrumbText += tag.name;
            }
        }
        return breadcrumbText;
    }

    confirmTag() {
        this.tagConfirmed.emit(this.selectedTag);
    }
}
