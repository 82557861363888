import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {NdlSnackbarComponent} from './snackbar.component';
import {NdlSnackbarTemplateComponent} from './snackbar-template.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {NdlSnackbarActionComponent} from './snackbar-action.component';


@NgModule({
    declarations: [
        NdlSnackbarComponent,
        NdlSnackbarTemplateComponent,
        NdlSnackbarActionComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatSnackBarModule,
        MatIconModule
    ],
    exports: [
        NdlSnackbarComponent,
        NdlSnackbarActionComponent
    ]
})
export class NeedlSnackbarModule {
}
