import { map } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { GraphQLService } from "./graphql-service";
import { LoadingService } from "./loading.service";
import { WabelService } from "./wabel-service.abstract";
import { SignupGraphQL } from "../graphql/signup.graphql";
import { Member } from "../entities/member";
import {Company} from "../entities/company";
import {FileToUpload} from "../entities/fileToUpload";

@Injectable({
    providedIn: "root"
})
export class SignupService extends WabelService {
    public static readonly COMPANY_INFO = 'company_info';
    public static readonly COMPANY_VISUALS = 'company_visuals';
    public static readonly COMPANY_POSITIONING = 'company_positioning';
    public static readonly COMPANY_EXPORT_CHALLENGES = 'company_export_challenges';
    public static readonly REGISTRATION_FINISHED = 'registration_finished';

    constructor(protected graphQLService: GraphQLService, protected loadingService: LoadingService) {
        super(graphQLService, loadingService);
    }

    // Had to do that because of WabelService extension
    toObject(object: any) {
        return object;
    }

    savePersonalInformation(
        email: string,
        title: string,
        firstName: string,
        lastName: string,
        phone: string,
        jobTitle: string,
        password: string,
        companyName: string = null,
        companyId: number = null,
        website: string = null,
        idcompanytype: number = null
    ) {
        return this.mutation(SignupGraphQL.mutations.savePersonalInformation, {
            email: email,
            title: title,
            firstName: firstName,
            lastName: lastName,
            phone: phone,
            jobTitle: jobTitle,
            password: password,
            companyName: companyName,
            companyId: companyId,
            website: website,
            idcompanytype: idcompanytype
        }).pipe(map((data) => data.data ? new Member(data.data.saveNewMemberWithCompany) : null));
    }

    saveCompanyInformation(
        turnover: number,
        currency: number,
        yearEstablished: number
    ) {
        return this.mutation(SignupGraphQL.mutations.saveCompanyInformation, {
            turnover: turnover,
            currency: currency,
            yearEstablished: yearEstablished
        }).pipe(map((data) => data.data ? new Company(data.data.saveCompanyInformation) : null));
    }

    saveCompanyImages(company: Company) {
        return this.mutation(SignupGraphQL.mutations.saveCompanyImages, {
            newCompanyLogo: company.newCompanyLogo.length ? company.newCompanyLogo[0].toGraphQlUploadFile() : null,
            newCompanyImages: company.newCompanyImages.map((f: FileToUpload) => f.toGraphQlUploadFile())
        }).pipe(map((data) => data.data ? new Company(data.data.saveCompanyImages) : null));
    }

    saveCompanyPositioning(
        plParts: number,
        categories: number[],
        countries: number[],
        priceRanges: number[],
    ) {
        return this.mutation(SignupGraphQL.mutations.saveCompanyPositioning, {
            plParts: plParts,
            categories: categories,
            countries: countries,
            priceRanges: priceRanges,
        }).pipe(map((data) => data.data ? new Company(data.data.saveCompanyPositioning) : null));
    }

    saveProductRequestRegistrationInfo(productRequestId: number, productRequestApplicationData: any, contactInformationData: any, companyInformationData: any, passwordData: any) {
        return this.mutation(SignupGraphQL.mutations.saveProductRequestRegistrationInfo, {
            productRequestId: productRequestId,
            email: contactInformationData.email,
            firstName: contactInformationData.firstName,
            lastName: contactInformationData.lastName,
            phone: contactInformationData.phone,
            password: passwordData.password,
            companyId: contactInformationData.companyId !== -1 ? contactInformationData.companyId : null,
            companyName: contactInformationData.companyId !== -1 ? null : companyInformationData?.name,
            companyWebsite: contactInformationData.companyId !== -1 ? null : companyInformationData?.website
        }).pipe(map((data) => data.data ? new Member(data.data.saveProductRequestRegistrationInfo) : null));
    }
}
