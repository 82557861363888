import {WabelEntity} from "./wabel-entity.abstract";
import {Member} from "./member";
import {SourcingRequest} from "./sourcing_request";
import {Company} from "./company";
import {SourcingRequestMemberResponse} from "./sourcing_request_member_response";
import {PrivateMessage} from "./private_message";

export class SourcingRequestRecommendation extends WabelEntity {
    constructor(obj: any) {
        super(obj);

        if (obj) {
            this.sourcingRequest = obj.sourcingRequest ? new SourcingRequest(obj.sourcingRequest) : null;
            this.company = obj.company ? new Company(obj.company) : null;
            this.priorityContact = obj.priorityContact ? new Member(obj.priorityContact) : null;
            this.prioritySourcingRequestMemberResponse = obj.prioritySourcingRequestMemberResponse ? new SourcingRequestMemberResponse(obj.prioritySourcingRequestMemberResponse) : null;
            this.privateMessage = obj.privateMessage ? new PrivateMessage(obj.privateMessage) : null;
            this.interestedSourcingRequestMemberResponses = obj.interestedSourcingRequestMemberResponses && obj.interestedSourcingRequestMemberResponses.items && obj.interestedSourcingRequestMemberResponses.items.length > 0 ? obj.interestedSourcingRequestMemberResponses.items.map(interestedSourcingRequestMemberResponse => new SourcingRequestMemberResponse(interestedSourcingRequestMemberResponse)) : [];
            this.sourcingRequestMemberResponseByMemberId = obj.sourcingRequestMemberResponseByMemberId ? new SourcingRequestMemberResponse(obj.sourcingRequestMemberResponseByMemberId) : null;
            this.nbMemberResponsesNotInterested = obj.nbMemberResponsesNotInterested && obj.nbMemberResponsesNotInterested.count ? obj.nbMemberResponsesNotInterested.count : 0;
        }
    }

    sourcingRequest: SourcingRequest;
    company: Company;
    buyerInterestStatus: string;
    buyerRejectionReason: string;
    priorityContact: Member;
    prioritySourcingRequestMemberResponse: SourcingRequestMemberResponse;
    privateMessage: PrivateMessage;
    interestedSourcingRequestMemberResponses: SourcingRequestMemberResponse[];
    sourcingRequestMemberResponseByMemberId: SourcingRequestMemberResponse;
    nbMemberResponsesNotInterested: number;

    get id(): string {
        return '0';
    }

    set id(id: string) {}
}
