import {WabelEntity} from './wabel-entity.abstract';
import {Summit} from './summit';
import {Registration} from './registration';
import {EventParticipation} from "./event_participation";
import {Tag} from "./tag";

export class SummitRegistration extends WabelEntity {
    constructor(obj: any) {
        super(obj);
        if (obj) {
            if (obj.summit) this.summit = new Summit(obj.summit);
            if (obj.eventregistration) this.registration = new Registration(obj.eventregistration);
            if (obj.buyerEventRegistration) this.registration = new Registration(obj.buyerEventRegistration);
            if (obj.companyParticipation) this.companyParticipation = new EventParticipation(obj.companyParticipation);
            if (obj.tags) this.tags = obj.tags && obj.tags.items ? obj.tags.items.map(tag => new Tag(tag)) : [];
            if (obj.categories) this.categories = obj.categories && obj.categories.items ? obj.categories.items.map(cat => new Tag(cat)) : [];
        }
    }

    summit: Summit;
    registration: Registration;
    productsLookingFor: string;
    companyParticipation: EventParticipation;
    tags: Tag[];
    categories: Tag[];

    get id(): number {
        return null;
    }

}
