import gql from "graphql-tag";

export class SignupGraphQL {
    static queries = {};

    static mutations = {
        savePersonalInformation: gql`
            mutation saveNewMemberWithCompany(
                $email: String!,
                $title: String!,
                $firstName: String!,
                $lastName: String!,
                $phone: String!,
                $jobTitle: String!,
                $password: String!,
                $companyName: String,
                $companyId: Int,
                $website: String,
                $idcompanytype: Int
            ) {
                saveNewMemberWithCompany(
                    email: $email,
                    title: $title,
                    firstName: $firstName,
                    lastName: $lastName,
                    phone: $phone,
                    jobTitle: $jobTitle,
                    password: $password,
                    companyName: $companyName,
                    companyId: $companyId,
                    website: $website,
                    idcompanytype: $idcompanytype
                ) {
                    idmember
                }
            }`,

        saveCompanyInformation: gql`
            mutation saveCompanyInformation(
                $yearEstablished: Int!,
                $turnover: Int!,
                $currency: Int!
            ) {
                saveCompanyInformation(
                    yearEstablished: $yearEstablished,
                    turnover: $turnover,
                    currency: $currency
                ) {
                    idcompany
                    registrationStep
                }
            }`,

        saveCompanyImages: gql`
            mutation saveCompanyImages(
                $newCompanyLogo: FileToUploadInput!,
                $newCompanyImages: [FileToUploadInput!]!,
            ) {
                saveCompanyImages(
                    newCompanyLogo: $newCompanyLogo,
                    newCompanyImages: $newCompanyImages,
                ) {
                    idcompany
                    registrationStep
                }
            }`,

        saveCompanyPositioning: gql`
            mutation saveCompanyPositioning(
                $plParts: Int!,
                $categories: [Int!]!,
                $countries: [Int!]!,
                $priceRanges: [Int!]!,
            ) {
                saveCompanyPositioning(
                    plParts: $plParts,
                    categories: $categories,
                    countries: $countries,
                    priceRanges: $priceRanges,
                ) {
                    idcompany
                    registrationStep
                }
            }`,

        saveProductRequestRegistrationInfo: gql`
            mutation saveProductRequestRegistrationInfo(
                $productRequestId: Int!,
                $email: String!,
                $firstName: String!,
                $lastName: String!,
                $phone: String!,
                $password: String!,
                $companyId: Int,
                $companyName: String,
                $companyWebsite: String,
            ) {
                saveProductRequestRegistrationInfo(
                    productRequestId: $productRequestId
                    email: $email,
                    firstName: $firstName,
                    lastName: $lastName,
                    phone: $phone,
                    password: $password,
                    companyId: $companyId,
                    companyName: $companyName,
                    companyWebsite: $companyWebsite,
                ) {
                    idmember
                }
            }`,
    };
}
