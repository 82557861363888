import {
    Component, ElementRef, HostBinding, Input
} from '@angular/core';
import {NDL_COLLAPSE_ANIMATION} from '../core/animations';

@Component({
    selector: 'ndl-expander, [ndl-expander], [ndlExpander]',
    templateUrl: './expander.component.html',
    styleUrls: ['./expander.component.scss'],
    host: {
        class: 'ndl-expander'
    },
    animations: [NDL_COLLAPSE_ANIMATION]
})

export class NdlExpanderComponent {
    @Input() showText = "See more";
    @Input() hideText = "See less";


    @HostBinding("class.ndl-expander-opened")
    opened = false;

    constructor(private _hostElement: ElementRef) {
    }

    toggle() {
        this.opened = !this.opened;
    }
}
