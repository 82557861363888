import {
    AfterContentInit, ContentChild, ContentChildren,
    Directive, ElementRef, Input, OnChanges, QueryList, SimpleChanges
} from '@angular/core';
import {NdlOptionDirective} from "./option.directive";

@Directive({
    selector: 'optgroup[ndl-option-group], optgroup[ndlOptGroup], ndl-option-group'
})
export class NdlOptionGroupDirective<T = any> {
    @Input() label: string;
    @Input() value: string;
    @ContentChildren(NdlOptionDirective) options: QueryList<NdlOptionDirective>;
    @ContentChildren(NdlOptionGroupDirective) groups: QueryList<NdlOptionGroupDirective>;
    @ContentChildren(NdlOptionDirective, {descendants: true}) allOptions: QueryList<NdlOptionDirective>;
}
