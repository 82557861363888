import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {NdlSelectOption} from "../../../selects/NdlSelectOption";

@Component({
  selector: 'ndl-checkboxes',
  templateUrl: './checkboxes.component.html',
  styleUrls: ['./checkboxes.component.scss']
})

export class NdlCheckboxesComponent implements OnInit {
    @Input() options: NdlSelectOption[] = [];
    @Input() selectedOptions: NdlSelectOption[] = [];
    @Input() notFilteredOptions: NdlSelectOption[] = [];
    @Input() searchable = false;
    @Input() showError = false;
    @Input() errorText = 'This field is required';
    @Input() searchPlaceholder = 'Search';
    @Input() columnNumber = 2;
    @Input() flattenOptions: any[] = [];
    @Input() canSelectOnlyLastLevel = false;
    @Input() maximumSelectedOption: number;
    filteredOptions: NdlSelectOption[] = [];
    searchValue: string;
    @Input() openedChildrenList: NdlSelectOption[] = [];

    @Output() listChanged = new EventEmitter<NdlSelectOption[]>();
    @Output() allOptionsChecked = new EventEmitter<NdlSelectOption[]>();
    @Output() allOptionsNotChecked = new EventEmitter<NdlSelectOption[]>();

    constructor() {}

    ngOnInit(): void {
        this.filteredOptions = this.options.slice(0);
        this.populateFlattenOptions();
    }

    toggleOption(option: NdlSelectOption) {
        const index = this.selectedOptions.findIndex(ndlOption => ndlOption.value === option.value);
        if (index === -1) {
            this.addOption(option);
        } else {
            this.removeOption(option);
        }
        this.listChanged.emit(this.selectedOptions);
    }

    searchChanged(searchValue: any) {
        this.openedChildrenList = [];
        if (searchValue && searchValue.length) {
            let matchingOptions = this.flattenOptions.filter(opt => opt.label.toLowerCase().match(searchValue.toLowerCase()));
            const parentOptions = [];
            for (const matchingOption of matchingOptions) {
                let parentOption = this.getParentOfFlattenOption(matchingOption);
                while (parentOption) {
                    if (!parentOptions.find(opt => opt.value === parentOption.value) && !matchingOptions.find(opt => opt.value === parentOption.value)) {
                        parentOptions.push(parentOption);
                    }
                    this.openedChildrenList.push(this.buildNdlOptionFromFlattenOption(parentOption, []));
                    parentOption = this.getParentOfFlattenOption(parentOption);
                }
            }
            matchingOptions = matchingOptions.concat(parentOptions);

            const filteredOptions = [];

            for (const option of matchingOptions.filter(opt => opt.valueParent === null)) {
                const ndlOption = this.buildNdlOptionFromFlattenOption(option, matchingOptions);
                // when building the ndlOption, if children are zero
                // this means the cat 1 is the filtered(the one we search for) option
                // so we need to add all its child
                if (ndlOption.children.length === 0) {
                    filteredOptions.push(option);
                } else {
                    // otherwise we add only filtered children
                    filteredOptions.push(ndlOption);
                }
            }

            this.filteredOptions = filteredOptions;
        }

        if (!searchValue.length) {
            this.filteredOptions = this.options.slice(0);
        }
    }

    buildNdlOptionFromFlattenOption(option: any, matchingOptions: any[]) {
        const children = [];
        for (const childrenOption of matchingOptions.filter(opt => opt.valueParent === option.value)) {
            children.push(this.buildNdlOptionFromFlattenOption(childrenOption, matchingOptions));
        }

        return {
            label: option.label,
            value: option.value,
            children: children,
        } as NdlSelectOption;
    }

    isChecked(option: NdlSelectOption) {
        return this.selectedOptions.findIndex(ndlOption => ndlOption && ndlOption.value === option.value) > -1;
    }

    isIndeterminate(option: NdlSelectOption) {
        if (this.isChecked(option)) {
            return false;
        }

        for (let children of option.children) {
            if (this.selectedOptions.findIndex(ndlOption => ndlOption && ndlOption.value === option.value) > -1) {
                return true;
            }
        }

        return false;
    }

    clear() {
        this.selectedOptions = [];
    }

    addOption(option: NdlSelectOption) {
        const index = this.selectedOptions.findIndex(ndlOption => ndlOption.value === option.value);
        if (index === -1) {
            this.selectedOptions.push(option);
        }

        const selectedSiblingOptions = this.selectedOptions.filter(opt => this.options.map(o => o.value).includes(opt.value));
        if (!this.canSelectOnlyLastLevel && selectedSiblingOptions.length === this.notFilteredOptions.length) {
            this.allOptionsChecked.emit(this.selectedOptions);
        }

        this.addChildrenOption(option);
    }

    removeOption(option: NdlSelectOption, fromChildren = false) {
        const index = this.selectedOptions.findIndex(ndlOption => ndlOption.value === option.value);
        if (index > -1) {
            this.selectedOptions.splice(index, 1);
            if (!fromChildren && option.children?.length) {
                for (const children of option.children) {
                    const childrenIndex = this.selectedOptions.findIndex(ndlOption => ndlOption.value === children.value);
                    if (childrenIndex > -1) {
                        this.removeOption(children);
                    }
                }
            }
            this.allOptionsNotChecked.emit(this.selectedOptions);
        }
    }

    addChildrenOption(option: NdlSelectOption) {
        const flattenOption = this.flattenOptions.find(opt => opt.value === option.value);
        if (flattenOption?.children?.length) {
            for (const children of flattenOption.children) {
                const childrenIndex = this.selectedOptions.findIndex(ndlOption => ndlOption.value === children.value);
                if (childrenIndex === -1) {
                    this.addOption(children);
                }
            }
        }
    }

    getChildrenChecked(option: NdlSelectOption, isFirst = true) {
        let checkedOptions = [];
        const flattenOption = this.flattenOptions.find(opt => opt.value === option.value);

        if (flattenOption?.children?.length) {
            if (isFirst) {
                checkedOptions = checkedOptions.concat(this.selectedOptions.filter(opt => flattenOption.children.map(o => o.value).includes(opt.value)));
            }
            for (const children of flattenOption.children) {
                if (children.children?.length) {
                    checkedOptions = checkedOptions.concat(this.selectedOptions.filter(opt => children.children.map(o => o.value).includes(opt.value)), this.getChildrenChecked(children, false));
                }
            }
        }

        return checkedOptions;
    }

    populateFlattenOptions() {
        let flattenOptions = [];

        for (const option of this.options) {
            flattenOptions = flattenOptions.concat(this.getFlattenOptionForOption(option));
        }

        this.flattenOptions = flattenOptions;
    }

    getFlattenOptionForOption(option: NdlSelectOption, parentOption: NdlSelectOption = null) {
        let flattenOptions = [];

        if (option.children?.length) {
            for (const children of option.children) {
                flattenOptions = flattenOptions.concat(this.getFlattenOptionForOption(children, option));
            }
        }

        flattenOptions.push({
            label: option.label,
            value: option.value,
            valueParent: parentOption ? parentOption.value : null,
            children: option.children
        });

        return flattenOptions;
    }

    getParentOfFlattenOption(flattenOption: any) {
        if (!flattenOption.valueParent) {
            return null;
        }

        return this.flattenOptions.find(opt => opt.value === flattenOption.valueParent);
    }

    toggleChildrenList(ndlSelectOption: NdlSelectOption) {
        let index = this.openedChildrenList.findIndex(opt => opt.value === ndlSelectOption.value);

        if (index === -1) {
            this.openedChildrenList.push(ndlSelectOption);
        } else {
            this.openedChildrenList.splice(index, 1);
        }
    }

    isChildrenListOpened(ndlSelectOption: NdlSelectOption) {
        return this.openedChildrenList.findIndex(opt => opt.value === ndlSelectOption.value) !== -1;
    }

    getOption(option: NdlSelectOption) {
        return this.options.find(o => o.value === option.value);
    }
}
