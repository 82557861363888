import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthService} from "../../../../services/auth.service";
import {Company} from "../../../../services/wabel-client/entities/company";
import {CompanyProfileSubmissionService} from "../../../../services/wabel-client/services/company-profile-submission.service";

@Component({
    selector: 'submission-subject-step',
    templateUrl: './submission-subject-step.component.html',
    styleUrls: ['./submission-subject-step.component.scss']
})
export class SubmissionSubjectStepComponent implements OnInit {
    @Input() buyerCompany: Company;
    @Input() showError = false;
    @Input() selectedReasonTitle = null;
    @Input() selectedReasonMessage = null;
    @Input() selectedReasonValue = null;
    @Output() validateSubjectStep = new EventEmitter();
    @Output() selectedReasonMessageChange = new EventEmitter<string>();
    @Output() selectedReasonTitleChange = new EventEmitter<string>();
    @Output() selectedReasonValueChange = new EventEmitter<number>();
    reasonMessageOptions = [];
    reasonTitleOptions = [];
    reasonTitleError = false;
    reasonMessageError = false;
    modalChangeSubjectOpen = false;
    tmpSubject: number;
    customMessageChanged = false;
    oldSelectedReasonValue = null;

    constructor(private authservice: AuthService, private companyProfileSubmissionService: CompanyProfileSubmissionService,
    ) {
    }

    ngOnInit() {
        const countryName: string = this.buyerCompany.country &&
        this.buyerCompany.country.name ? this.buyerCompany.country.name : 'your country';
        this.reasonTitleOptions = [
            {
                name: 'I\'m looking for a distributor in ' + countryName,
                id: 1,
            },
            {
                name: 'I have an innovation to share with you',
                id: 2,
            },
            {
                name: 'Why should we work together?',
                id: 3,
            },
            {
                name: 'We have a great reputation in ' + countryName,
                id: 4,
            },
            {
                name: 'General Contact',
                id: 5,
            }
        ];

        this.reasonMessageOptions = [

            `Good day,
            \nOur products fit well with your current range.
            \nI would like to request a meeting with you to discuss further about a potential collaboration.
            \nLooking forward to hearing from you,
            \nKind regards,
            \n${this.authservice.getMember().fullname}`,

            `Good day,
            \nWe’re launching a brand new innovation, that I believe is a great fit for your customers.
            \nWould you agree to schedule a meeting to discuss further?
            \nThank you,
            \nKind regards,
            \n${this.authservice.getMember().fullname}`,

            `Good day,
            \nAfter looking at your company profile, I believe our products ranges are a perfect fit with your customers.
            \nWould you agree to schedule a meeting to discuss further?
            \nThank you,
            \nKind regards,
            \n${this.authservice.getMember().fullname}`,

            `Good day,
            \nWe work well in ${countryName}. And we would like to expand as we see customer's demand for our products.
            \nWould you agree to schedule a meeting to discuss a potential partnership?
            \nThank you,
            \nKind regards,
            \n${this.authservice.getMember().fullname}`,

            ''

        ];
        if (this.selectedReasonTitle !== null) {
            this.selectedReasonValue = this.getReasonValue(this.selectedReasonTitle);
        }
        this.companyProfileSubmissionService.doCheckStep.subscribe(step => {
            if (step === this.companyProfileSubmissionService.MESSAGE_STEP) {
                this.onChange();
            }
        });

    }

    getReasonValue(reason): string {
        return this.reasonTitleOptions.find(x => x.name.includes(reason.substring(5, 20))).id;
    }

    onChange() {
        this.reasonTitleError = true;
        this.reasonMessageError = true;
        if (this.selectedReasonTitle !== null && this.selectedReasonMessage !== null) {
            this.reasonTitleError = false;
            this.reasonMessageError = false;
            this.selectedReasonTitleChange.emit(this.selectedReasonTitle);
            this.selectedReasonMessageChange.emit(this.selectedReasonMessage);
            this.selectedReasonValueChange.emit(this.selectedReasonValue);
            this.validateSubjectStep.emit(true);
        } else {
            this.validateSubjectStep.emit(false);
        }
    }

    setSelectedReasonMessageTitle(index: number, changeMessage = true) {
        this.selectedReasonValue = index;
        this.oldSelectedReasonValue = index;
        this.selectedReasonTitle = this.reasonTitleOptions[index - 1].name;
        if (changeMessage) {
            this.selectedReasonMessage = this.reasonMessageOptions[index - 1];
            this.customMessageChanged = false;
        }
    }
}
