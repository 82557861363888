import {
    Component, Input, OnInit
} from '@angular/core';
import {NdlInputBaseDirective} from '../input-base.directive';

@Component({
    selector: 'ndl-rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.scss'],
    host: {
        'class': 'ndl-rating',
        '(blur)': "onTouched()"
    }
})
export class NdlRatingComponent extends NdlInputBaseDirective<number> implements OnInit {
    facesIcons = [];
    iterator: number[];
    @Input() type: "faces" | "stars" = "faces";

    private _range: 3|5 = 3;
    @Input() get range() {
        return this._range;
    }
    set range(range: 3|5) {
        this._range = range;
        this.setupRange();
    }

    ngOnInit() {
        this.setupRange();
    }

    setValue(value: number) {
        this.innerFormControl ? this.innerFormControl.setValue(value) : this.value = value;
    }

    private setupRange() {
        this.iterator = Array(this.range).fill(0).map((x, i) => i);
        this.facesIcons = ['sentiment_dissatisfied', 'sentiment_neutral', 'sentiment_satisfied_alt'];

        if (this.range === 5) {
            this.facesIcons = ['sentiment_very_dissatisfied', ...this.facesIcons, 'sentiment_very_satisfied'];
        }
    }
}
