import {Component, Input, OnInit} from '@angular/core';
import {NdlFile} from "../NdlFile";

@Component({
  selector: 'ndl-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})

export class NdlCarouselComponent implements OnInit {
    @Input() files: NdlFile[];

    timer: number = 3; // In second. If 0, no timer.
    activeIndexFile = 0;
    timeoutNextFile: any;
    selectedFile: NdlFile;

    constructor() {}

    ngOnInit(): void {
        this.setupTimeoutNextImage();
    }

    previousFile(setTimeoutNextFile: boolean = true) {
        if (this.files[this.activeIndexFile - 1]) {
            this.activeIndexFile--;
        } else {
            this.activeIndexFile = this.files.length - 1;
        }
        if (setTimeoutNextFile) {
            this.setupTimeoutNextImage();
        }
    }

    nextFile(setTimeoutNextFile: boolean = true) {
        if (this.files[this.activeIndexFile + 1]) {
            this.activeIndexFile++;
        } else {
            this.activeIndexFile = 0;
        }
        if (setTimeoutNextFile) {
            this.setupTimeoutNextImage();
        }
    }

    setupTimeoutNextImage() {
        this.clearTimeoutNextImage();
        if (this.files.length > 1) {
            this.timeoutNextFile = setTimeout(() => {
                this.nextFile();
            }, this.timer * 1000);
        }
    }

    clearTimeoutNextImage() {
        if (this.timeoutNextFile) {
            clearTimeout(this.timeoutNextFile);
        }
    }

    setBodyOverflow(value: string) {
        document.body.style.overflow = value;
    }
}
