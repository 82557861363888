import {
    AfterContentInit,
    AfterViewInit,
    Component, ContentChild,
    ContentChildren,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    TemplateRef
} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject, Subscription} from "rxjs";
import {merge} from "rxjs";
import {NdlListItemDirective} from "./list-item.directive";
import {coerceBooleanProperty, coerceNumberProperty} from "@angular/cdk/coercion";
import {NdlListBaseDirective} from "./list-base.component";
import {NdlPaginatedCallback} from "./data-source";

@Component({
    selector: 'ndl-list',
    templateUrl: './list.component.html',
})
export class NdlListComponent<T = any> extends NdlListBaseDirective {

    set grid(grid: number) {
        this._grid = coerceNumberProperty(grid);
    }

    get gridEnable() {
        return this.grid > 1;
    }

    protected _grid = 1;
    @Input() get grid(): number {
        return this._grid;
    }

    @ContentChild(NdlListItemDirective) itemTemplate: NdlListItemDirective;

    getItemClasses(i) {
        const classes = [];
        if (i % this.grid === 0) {
            classes.push("ndl-list-item-first");
        }
        if (i % this.grid === this.grid - 1) {
            classes.push('ndl-list-item-last');
        }
        if (this.itemClass) {
            classes.push(this.itemClass);
        }
        if (this.isSelected(i)) {
            classes.push('ndl-list-item-selected');
        }

        return classes.join(' ');
    }
}
