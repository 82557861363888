import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
  jobNumber: number;

  constructor() {
    this.jobNumber = 0;
  }

  startRequest(): void {
    this.jobNumber++;
  }

  endRequest(): void {
    if (this.jobNumber) this.jobNumber--;
  }

  get processing() {
    return this.jobNumber > 0;
  }
}
