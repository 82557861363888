import {ContentChild, Directive, HostBinding, OnDestroy, OnInit, TemplateRef} from '@angular/core';

@Directive({
    selector: 'button[ndl-list-action],button[ndl-table-action]',
    host: {
        class: 'ndl-list-action'
    }
})
export class NdlListActionDirective {
}
