<div class="alert fade show ndl-alert" [class.alert-dismissable]="dismissable" [ngClass]="'alert-'+theme+'  ndl-alert-'+theme" role="alert">
    <h6 *ngIf="title" class="ndl-alert-title" [ngClass]="'text-'+theme">
        <ng-content select="[ndl-alert-title]"></ng-content>
    </h6>

    <ng-content></ng-content>

    <button *ngIf="dismissable" (click)="dismiss()" type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
