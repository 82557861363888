import {Injectable} from "@angular/core";
import {WabelService} from "./wabel-service.abstract";
import {GraphQLService} from "./graphql-service";
import {LoadingService} from "./loading.service";

import {MemberAvailability} from "../entities/member_availabilities";
import {MemberAvailabilityGraphql} from "../graphql/member_availability.graphql";
import {map} from "rxjs/operators";
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)

import { daysArrType , daysArrTypeReturned , daysTimeArrType } from "../custom_types"

@Injectable({
    providedIn: 'root'
})
export class MemberAvailabilityService extends WabelService {

    constructor(protected graphQLService: GraphQLService, protected loadingService: LoadingService) {
        super(graphQLService, loadingService);
    }

  toArrayOfObjects(objects: any[]): MemberAvailability[] {
    return objects.map((data: any) => new MemberAvailability(data));
  }

  toObject(object: any): MemberAvailability {
    return new MemberAvailability(object);
  }

  getAvailabilities( idmember?: number ) {
    return this.query(MemberAvailabilityGraphql.queries.get_availability , idmember && {idmember})
      .pipe(map((data) => data.data && data.data.meetingAvailabilities ? this.prepareLocalArray(data.data.meetingAvailabilities.items) : []));
  }

  toStringForDB( val: daysArrType[]) : string {
    val.forEach( (day: daysArrType) => {
      day.v.forEach( (timeArr: daysTimeArrType ) => {

        if( timeArr.timeFrom !== null && timeArr.timeFrom !== ""  ) {
          let d: Date = new Date( timeArr.timeFrom )
          timeArr.timeFrom = d.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit', hour12: true})
        }

        if( timeArr.timeTo !== null && timeArr.timeTo !== ""  ) {
          let d: Date = new Date( timeArr.timeTo )
          timeArr.timeTo = d.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit', hour12: true})
        }

      })
    })


    let data: string = JSON.stringify( val )
    return data
  }

  prepareLocalArray( val: any) : daysArrTypeReturned[] {

    let alreadyPushed: string[] = []
    let data: daysArrTypeReturned[] = []

    val.forEach( (item: any) => {
      if( !alreadyPushed.includes(item.day) ){
        data.push( {d: item.day , v: [{timeFrom: item.timeFrom , timeTo: item.timeTo}] , timezone: item.timezone} )
      }else{
        data.forEach( x => {
          if( x.d === item.day ) {
            x.v.push( {timeFrom: item.timeFrom , timeTo: item.timeTo} )
          }
        });

      }

      alreadyPushed.push( item.day )
    })

    return data.length > 0 && data

  }

  setAvailabilities( availabilities: daysArrType[]) {
    return this.mutation(MemberAvailabilityGraphql.mutations.set_availability , { availabilities: this.toStringForDB( availabilities ) , timezone: dayjs.tz.guess()})
      .pipe(map((data) => data.data && data.data.saveAvailabilities ? this.prepareLocalArray(data.data.saveAvailabilities.items) : []));
  }
}
