import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {environment} from "../../environments/environment";

@Directive({
    selector: 'img[appImage404]'
})
export class Image404Directive {

    @Input() image404: 'image.jpg' | 'banner.jpg' | 'medium-banner.jpg' | 'summit-banner.jpg' = 'image.jpg';

    constructor(private el: ElementRef) {
    }

    @HostListener("error")
    private onError() {
        this.el.nativeElement.src = environment.needl_front_end_url + '/assetsFiles/404/' + this.image404;
    }
}
