import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HighlightModule} from 'ngx-highlightjs';
import {NdlDemoComponent} from './demo.component';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NdlSteppersDemoComponent} from './steppers/steppers-demo.component';
import {NeedlStepperModule} from '../steppers/stepper.module';
import {MatSidenavModule} from '@angular/material/sidenav';
import {NeedlTextModule} from '../forms/text/text.module';
import {MatInputModule} from '@angular/material/input';
import {NdlFormsDemoComponent} from './forms/forms-demo.component';
import {MatButtonModule} from '@angular/material/button';
import {NeedlFormModule} from '../forms/forms.module';
import {MatIconModule} from '@angular/material/icon';
import {NeedlPageModule} from '../layout/page/page.module';
import {NeedlPasswordModule} from '../forms/password/password.module';
import {NeedlTextAreaModule} from '../forms/textarea/textarea.module';
import {NeedlSelectModule} from '../forms/select/select.module';
import {NeedlPhoneModule} from '../forms/phone/phone.module';
import {NdlChartsDemoComponent} from './charts/charts-demo.component';
import {NeedlChartsModule} from '../charts/charts.module';
import {NeedlUploadModule} from '../forms/upload/upload.module';
import {NeedlRadioModule} from '../forms/radio/radio.module';
import {NeedlCheckboxesNewModule} from '../forms/checkboxes/checkboxes.module';
import {NeedlWysiwygModule} from '../forms/wysiwyg/wysiwyg.module';
import {NeedlAddressModule} from "../forms/address/address.module";
import {NeedlTagsModule} from '../forms/tags/tags.module';
import {NeedlBannersModule} from '../banners/banners.module';
import {NdlBannersDemoComponent} from './banners/banners-demo.component';
import {NeedlButtonsModule} from '../buttons/buttons.module';
import {NeedlMenuModule} from '../layout/menu/menu.module';
import {NeedlCardsModule} from '../cards/cards.module';
import {NdlCardsDemoComponent} from './cards/cards-demo.component';
import {NdlButtonsDemoComponent} from './buttons/buttons-demo.component';
import {NeedlDividerModule} from '../divider/divider.module';
import {NdlTagDemoComponent} from './tag/tag-demo.component';
import {NeedlTagModule} from '../tag/tag.module';
import {NdlStatusDemoComponent} from './status/status-demo.component';
import {NeedlTooltipModule} from '../tooltips/tooltip.module';
import {NeedlCollapsesModule} from '../collapses/collapses.module';
import {NdlCollapsesDemoComponent} from './collapses/collapses-demo.component';
import {NdlCarouselDemoComponent} from './carousel/carousel-demo.component';
import {NeedlCarouselsModule} from '../carousels/carousels.module';
import {NdlExpanderDemoComponent} from './expander/expander-demo.component';
import {NeedlExpanderModule} from '../expander/expander.module';
import {NdlCascaderDemoComponent} from "./cascader/cascader-demo.component";
import {NeedlCascaderModule} from "../forms/cascader/cascader.module";
import {NdlSnackbarDemoComponent} from './snackbar/snackbar-demo.component';
import {NeedlSnackbarModule} from '../snackbar/snackbar.module';
import {NeedlListsModule} from '../lists/lists.module';
import {NdlTableDemoComponent} from './table/table-demo.component';
import {NdlRatingDemoComponent} from './rating/rating-demo.component';
import {NeedlRatingModule} from '../forms/rating/rating.module';

@NgModule({
    declarations: [
        NdlDemoComponent,
        NdlBannersDemoComponent,
        NdlButtonsDemoComponent,
        NdlChartsDemoComponent,
        NdlCardsDemoComponent,
        NdlCarouselDemoComponent,
        NdlCascaderDemoComponent,
        NdlCollapsesDemoComponent,
        NdlExpanderDemoComponent,
        NdlFormsDemoComponent,
        NdlSnackbarDemoComponent,
        NdlStatusDemoComponent,
        NdlSteppersDemoComponent,
        NdlTagDemoComponent,
        NdlTableDemoComponent,
        NdlRatingDemoComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        HighlightModule,
        MatSidenavModule,
        MatInputModule,
        MatIconModule,
        NeedlBannersModule,
        MatButtonModule,
        NeedlAddressModule,
        NeedlButtonsModule,
        NeedlChartsModule,
        NeedlCardsModule,
        NeedlCarouselsModule,
        NeedlCheckboxesNewModule,
        NeedlCollapsesModule,
        NeedlFormModule,
        NeedlMenuModule,
        NeedlPageModule,
        NeedlPasswordModule,
        NeedlPhoneModule,
        NeedlRadioModule,
        NeedlSelectModule,
        NeedlStepperModule,
        NeedlTagsModule,
        NeedlTextAreaModule,
        NeedlTextModule,
        NeedlUploadModule,
        NeedlWysiwygModule,
        NeedlDividerModule,
        NeedlTagModule,
        NeedlTooltipModule,
        NeedlExpanderModule,
        NeedlCascaderModule,
        NeedlSnackbarModule,
        NeedlListsModule,
        NeedlRatingModule
    ]
})
export class NeedlDemoModule {
}
