import {ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {NdlSelectOption} from "../../../selects/NdlSelectOption";


@Component({
  selector: 'ndl-filter-with-checkboxes',
  templateUrl: './filter-with-checkboxes.component.html',
  styleUrls: ['./filter-with-checkboxes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class NdlFilterWithCheckboxesComponent implements OnInit {
    @Input() label = 'Choose an option';
    @Input() machineName = 'default';
    @Input() validationButtonLabel = 'Apply';
    @Input() clearButtonLabel = 'Clear';
    @Input() options: NdlSelectOption[] = [];
    @Input() searchable = false;
    @Input() searchPlaceholder = 'Search';
    @Input() selectedOptions: NdlSelectOption[] = [];
    @Input() maximumSelectedOption: number;
    validatedOptions: NdlSelectOption[] = [];
    filteredOptions: NdlSelectOption[] = [];

    @Output() listChanged = new EventEmitter<{machineName: string, options: NdlSelectOption[]}>();

    constructor() {}

    ngOnInit() {
        this.filteredOptions = this.options.slice(0);
        this.validatedOptions = this.selectedOptions.slice(0);
    }

    closeList() {
        this.selectedOptions = this.validatedOptions.slice(0);
        this.filteredOptions = this.options.slice(0);
    }

    get filterNumber() {
        return this.selectedOptions.filter(selectedOption => !selectedOption.children || !selectedOption.children.length).length;
    }

    apply() {
        this.validatedOptions = this.selectedOptions.slice(0);
        this.closeList();
        this.listChanged.emit({machineName: this.machineName, options: this.validatedOptions});
    }

    clear(emitEvent: boolean = true) {
        this.selectedOptions = [];
        this.validatedOptions = [];
        this.closeList();
        if (emitEvent) {
            this.listChanged.emit({machineName: this.machineName, options: this.validatedOptions});
        }
    }
}
