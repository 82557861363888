import {WabelEntity} from "./wabel-entity.abstract";
import {FilePreviewableInterface} from "./file-previewable.interface";
import {File} from "./file";
import {Product} from "./product";

export class ProductFile extends WabelEntity implements FilePreviewableInterface {

    constructor(obj: any) {
        super(obj);
        if (obj) {
            this.product = obj.product ? new Product(obj.product) : null;
            this.file = obj.file ? new File(obj.file) : null;
        }
    }

    file: File;
    product: Product;
    fileType: string;
    downloadLink: string;
    imageUrl: string;
    imageUrl123: string;
    imageUrl300: string;
    imageUrl450: string;

    get id(): string {
        return this.file.fileName;
    }

    get dataBase64(): string {
        return this.file.fileName;
    }

    previewExtension(): string {
        return this.file.fileExtension;
    }

    previewFileName(): string {
        return this.file.fileName;
    }

    previewFileSize(): number {
        return this.file.fileSize;
    }

    previewId(): string {
        return "" + this.file.idfile;
    }

    previewSelected(): boolean {
        return this.isCover;
    }

    previewUrl(): string {
        return this.imageUrl ? this.imageUrl : this.downloadLink;
    }

    get isCover(): boolean {
        return this.fileType === 'logo';
    }

    get selected(): boolean {
        return this.isCover;
    }

    set selected(val: boolean) {
        this.fileType = (val ? 'logo' : 'image');
    }
}
