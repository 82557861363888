import {Component, Directive, TemplateRef, ViewChild} from '@angular/core';
import {MatMenuItem} from '@angular/material/menu';

@Component({
    selector: 'ndl-card-option,[ndl-card-option],[ndlCardOption]',
    templateUrl: './card-option.component.html',
    host: {
        'class': 'ndl-card-option'
    },
    providers: [
        {provide: MatMenuItem, useExisting: NdlCardOptionComponent}
    ]

})
export class NdlCardOptionComponent extends MatMenuItem {
}
