import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CompanyCardComponent} from './company-card.component';
import {NeedlCardsModule, NeedlExpanderModule} from "needl-lib";
import {CompanyCardFooterDirective} from './company-card-footer.directive';
import {MatDividerModule} from '@angular/material/divider';
import {CompanyCardStatusDirective} from './company-card-status.directive';
import {MatIconModule} from '@angular/material/icon';
import {CompanyCardStatusOutsideDirective} from "./company-card-status-outside.directive";
import {RouterModule} from "@angular/router";
import {SharedModule} from "../../../modules/shared-module/shared.module";


@NgModule({
    declarations: [
        CompanyCardComponent,
        CompanyCardFooterDirective,
        CompanyCardStatusDirective,
        CompanyCardStatusOutsideDirective
    ],
    imports: [
        CommonModule,
        NeedlCardsModule,
        NeedlExpanderModule,
        MatDividerModule,
        MatIconModule,
        RouterModule,
        SharedModule
    ],
    exports: [
        CompanyCardComponent,
        CompanyCardFooterDirective,
        CompanyCardStatusDirective,
        CompanyCardStatusOutsideDirective
    ]
})
export class CompanyCardModule {
}
