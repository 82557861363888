import {WabelEntity} from "./wabel-entity.abstract";
import {CompanyRelationshipTagReference} from "./company_relationship_tag_reference";

export class CompanyRelationshipStatus extends WabelEntity {
    constructor(obj: any) {
        super(obj);
        if (obj) {
            this.tags = obj.tags ? obj.tags.map(tag => new CompanyRelationshipTagReference(tag)) : [];
        }
    }

    idrelationshipStatus: number;
    name: string;
    canHaveMultipleTags: boolean;
    color: string;
    tags: CompanyRelationshipTagReference[] = [];

    get id(): number {
        return this.idrelationshipStatus;
    }

    set id(id: number) {
        this.idrelationshipStatus = id;
    }
}
