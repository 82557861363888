<div class="d-flex justify-content-between">
    <mat-label *ngIf="showBasicTitle" (click)="customSelect.toggle()">
            <span [class.has-value]="value && value.length">
            {{title}} {{value && value.length ? '(' + value.length + ')' : ''}}
            </span>
    </mat-label>
    <mat-select disableOptionCentering [multiple]="true" [required]="required" panelClass="ndl-select-panel"
                [disabled]="disabled" [placeholder]="!showBasicTitle && placeholder ? placeholder : ''" #customSelect>
        <mat-option *ngIf="searchable" class="ndl-select-field-search" disabled>
            <span class="material-icons">search</span>
            <input matInput [placeholder]="placeholder" [(ngModel)]="search" (change)="$event.stopPropagation()"/>
        </mat-option>
        <div class="ndl-checkboxes-field-content ndl-scrollbar" [style.max-height]="maxHeight" [style.height]="height"
             [class.display-mode]="displayMode"
             [style.overflow]="(maxHeight || height) ? 'auto' : 'initial'">
            <ng-container *ngFor="let option of options">
                <ng-container *ngTemplateOutlet="optionTemplate; context:{$implicit: option}"></ng-container>
            </ng-container>
            <ng-container *ngFor="let group of groups">
                <ng-container *ngTemplateOutlet="groupTemplate; context:{$implicit: group}"></ng-container>
            </ng-container>
        </div>
    </mat-select>
    <mat-icon *ngIf="clearable && value && value.length" (click)="clear(); $event.stopPropagation()">close</mat-icon>
</div>

<!-- Templates -->
<ng-template #optionTemplate let-option>
    <mat-option [class.ndl-checkbox-hide]="hideOption(option)">
        <mat-checkbox [checked]="isChecked(option.value)"
                      (change)="changeValue(option.value)" color="primary"
                      [disabled]="displayMode || disabled || (maximumSelectedOption && this.value.length >= maximumSelectedOption && !isChecked(option.value))"
                      *ngIf="!displayMode || (displayMode && isChecked(option.value))">
            <div [innerHTML]="option.label"></div>
        </mat-checkbox>
    </mat-option>
</ng-template>

<ng-template #groupTemplate let-group>
    <div class="ndl-checkboxes-group" [class.ndl-checkboxes-group-opened]="isGroupOpened(group)"
         [class.ndl-checkbox-hide]="hideGroup(group)"
         *ngIf="!displayMode || (displayMode && countGroupChildrenCheck(group))">
        <div class="ndl-checkboxes-group-label d-flex">
            <mat-checkbox color="primary" [checked]="isAllChecked(group)" [indeterminate]="isSomeChecked(group)"
                          (change)="toggleGroupCheck($event.checked, group)" [disabled]="displayMode || disabled || onlyLastLevel"
                          (click)="onlyLastLevel ? toggleGroup(group) : null" [class.disabled-level]="onlyLastLevel">
                {{group.label}}
                <span *ngIf="countGroupChildrenCheck(group)" class="ndl-checkboxes-group-count ndl-small">
                    {{countGroupChildrenCheck(group)}}
                </span>
            </mat-checkbox>

            <button type="button" mat-icon-button (click)="toggleGroup(group)">
                <mat-icon color="primary">{{isGroupOpened(group) ? 'arrow_drop_up' : 'arrow_drop_down'}}</mat-icon>
            </button>
        </div>
        <ul>
            <li>
                <ng-container *ngFor="let suboption of group.options">
                    <ng-container *ngTemplateOutlet="optionTemplate; context:{$implicit: suboption}"></ng-container>
                </ng-container>
            </li>
            <li>
                <ng-container *ngFor="let subgroup of group.groups">
                    <ng-container *ngTemplateOutlet="groupTemplate; context:{$implicit: subgroup}"></ng-container>
                </ng-container>
            </li>
        </ul>
    </div>
</ng-template>
