<div class="ndl-upload-field" (focusin)="onFocusIn()" (focusout)="onFocusOut()">
    <ndl-upload-file #uploadFile *ngIf="!multiple && firstFile && uploadComponent; else placeholderTemplate"
                     [disabled]="disabled"
                     [for]="uploadComponent" (edit)="uploadField.click()"></ndl-upload-file>

    <input #uploadField class="ndl-upload-field-input" [accept]="fileTypes" type="file" [multiple]="multiple"
           [disabled]="disabled"
           (change)="onInputChange($event)"/>

    <p *ngIf="uploadComponent?.showAutoLimits" class="ndl-upload-auto-limit-hint">
        {{uploadComponent.autoLimits}}
    </p>
</div>


<ng-template #placeholderTemplate>
    <!-- Placeholder used on the upload area when multiple or single upload is empty -->
    <div class="ndl-upload-field-placeholder">
        <div class="ndl-upload-field-placeholder-prefix" *ngIf="prefix">
            <ng-container *ngTemplateOutlet="prefix"></ng-container>
        </div>

        <div class="ndl-upload-field-placeholder-text">
            <div *ngIf="placeholder; else defaultPlaceholder" [innerHTML]="placeholder"></div>
            <ng-template #defaultPlaceholder>
                Drag {{multiple ? "files" : "file" }} here or<br>
                <a (click)="uploadField.click()">click here to browse</a>
            </ng-template>
        </div>
    </div>
</ng-template>
