import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NdlSmallSpinnerComponent} from './components/small-spinner/small-spinner.component';

@NgModule({
    declarations: [
        NdlSmallSpinnerComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        NdlSmallSpinnerComponent
    ]
})
export class NeedlSpinnersModule { }
