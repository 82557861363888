import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NdlDemoComponent} from './demo.component';
import {NdlSteppersDemoComponent} from './steppers/steppers-demo.component';
import {NdlFormsDemoComponent} from './forms/forms-demo.component';
import {NdlChartsDemoComponent} from './charts/charts-demo.component';
import {NdlBannersDemoComponent} from './banners/banners-demo.component';
import {NdlCardsDemoComponent} from './cards/cards-demo.component';
import {NdlButtonsDemoComponent} from './buttons/buttons-demo.component';
import {NdlTagDemoComponent} from './tag/tag-demo.component';
import {NdlStatusDemoComponent} from './status/status-demo.component';
import {NdlCollapsesDemoComponent} from './collapses/collapses-demo.component';
import {NdlCarouselDemoComponent} from './carousel/carousel-demo.component';
import {NdlExpanderDemoComponent} from './expander/expander-demo.component';
import {NdlCascaderDemoComponent} from "./cascader/cascader-demo.component";
import {NdlSnackbarDemoComponent} from './snackbar/snackbar-demo.component';
import {NdlTableDemoComponent} from './table/table-demo.component';
import {NdlRatingDemoComponent} from './rating/rating-demo.component';

const routes: Routes = [
    {
        path: '',
        component: NdlDemoComponent,
        children: [
            {path: 'banners', component: NdlBannersDemoComponent},
            {path: 'buttons', component: NdlButtonsDemoComponent},
            {path: 'cards', component: NdlCardsDemoComponent},
            {path: 'carousel', component: NdlCarouselDemoComponent},
            {path: 'cascader', component: NdlCascaderDemoComponent},
            {path: 'charts', component: NdlChartsDemoComponent},
            {path: 'collapses', component: NdlCollapsesDemoComponent},
            {path: 'expander', component: NdlExpanderDemoComponent},
            {path: 'forms', component: NdlFormsDemoComponent},
            {path: 'rating', component: NdlRatingDemoComponent},
            {path: 'snackbar', component: NdlSnackbarDemoComponent},
            {path: 'status', component: NdlStatusDemoComponent},
            {path: 'steppers', component: NdlSteppersDemoComponent},
            {path: 'tag', component: NdlTagDemoComponent},
            {path: 'tables', component: NdlTableDemoComponent}
        ]
    }
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(routes)],
})
export class NeedlDemoRoutingModule { }
