import {NgModule} from "@angular/core";
import {SharedModule} from "../../modules/shared-module/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {CommonModule} from "@angular/common";
import {PageErrorComponent} from "./page-error/page-error.component";
import {PrivateMessagesPopupComponent} from "../../modules/shared-module/private-messages-popup/private-messages-popup.component";
import {ProductSourcingPrioritiesComponent} from "../../modules/shared-module/product-sourcing-priorities/product-sourcing-priorities.component";
import {LandingSourcingMenuComponent} from "../../modules/shared-module/landing-sourcing-menu/landing-sourcing-menu.component";
import {EmailVerifiedComponent} from "./email-verified/email-verified.component";
import {PageNotFoundComponent} from "./page-not-found/page-not-found.component";
import {AccessDeniedComponent} from "./access-denied/access-denied.component";
import {PremiumPopinComponent} from "../../modules/shared-module/premium-popin/premium-popin.component";
import {FallbacksRoutingModule} from './fallbacks-routing.module';
import {NeedlTagModule, NeedlCardsModule, NeedlSnackbarModule} from 'needl-lib';
import {NeedlAlertModule} from "../../../../projects/needl-lib/src/alert/alert.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@NgModule({
    declarations: [
        PageErrorComponent,
        PrivateMessagesPopupComponent,
        ProductSourcingPrioritiesComponent,
        LandingSourcingMenuComponent,
        EmailVerifiedComponent,
        PageNotFoundComponent,
        AccessDeniedComponent,
        PremiumPopinComponent,
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        SharedModule,
        CommonModule,
        FallbacksRoutingModule,
        NeedlTagModule,
        NeedlCardsModule,
        NeedlAlertModule,
        NgSelectModule,
        NeedlSnackbarModule,
        MatProgressSpinnerModule
    ],
    exports: [
        ProductSourcingPrioritiesComponent,
        LandingSourcingMenuComponent,
        PremiumPopinComponent,
        PrivateMessagesPopupComponent,
    ]
})

export class FallbacksModule {
}
