import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {GraphQLService} from '../../../services/wabel-client/services/graphql-service';
import {Subscription} from 'rxjs';
import {NdlSnackbarComponent} from '../../../../../projects/needl-lib/src/snackbar/snackbar.component';
import {TemplateService} from '../../../services/template.service';

@Component({
    selector: 'app-maintenance-and-update',
    templateUrl: './app-maintenance-and-update.component.html',
})
export class AppMaintenanceAndUpdateComponent implements OnInit, AfterViewInit, OnDestroy {

    backendInMaintenanceModal$: Subscription;
    openNewAppVersionModal$: Subscription;
    @ViewChild('backendInMaintenance') backendInMaintenanceModal: NdlSnackbarComponent;
    @ViewChild('newVersion') newVersionModal: NdlSnackbarComponent;

    constructor(
        private graphqlService: GraphQLService,
        private templateService: TemplateService,
    ) {
    }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        this.backendInMaintenanceModal$ = this.graphqlService.backendInMaintenance$.subscribe(data => {
            if (data) {
                this.backendInMaintenanceModal.open();
            }
        });
        this.openNewAppVersionModal$ = this.templateService.openNewAppVersionModal$.subscribe(data => {
            if (data) {
                this.newVersionModal.open();
            }
        });
    }

    ngOnDestroy() {
        if (this.backendInMaintenanceModal$) {
            this.backendInMaintenanceModal$.unsubscribe();
        }
        if (this.openNewAppVersionModal$) {
            this.openNewAppVersionModal$.unsubscribe();
        }
    }

    refreshPage() {
        document.location.reload();
    }
}
