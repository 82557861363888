import {Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList} from '@angular/core';
import {NdlTabDirective} from "./tab.directive";
import {NdlLazyLoadedTabDirective} from "./lazy-loaded-tab.directive";
import {NdlCustomTab} from "./custom-tab.directive";

@Component({
    selector: 'ndl-tabs',
    templateUrl: './tabs.component.html',
    host: {
        class: 'ndl-tabs'
    },
})
export class NdlTabsComponent {
    @Input() selectedIndex = 0;
    @Input() disabledIndex = [];
    @ContentChildren(NdlTabDirective) tabs: QueryList<NdlTabDirective>;
    @ContentChildren(NdlLazyLoadedTabDirective) lazyLoadedTabs: QueryList<NdlLazyLoadedTabDirective>;
    @ContentChildren(NdlCustomTab) customTabs: QueryList<NdlCustomTab>;
    @Output() tabChanged = new EventEmitter();
}
