import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NdlRatingComponent} from './rating.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';



@NgModule({
  declarations: [
      NdlRatingComponent
  ],
  imports: [
      CommonModule,
      ReactiveFormsModule,
      MatButtonModule,
      MatIconModule,
  ],
  exports: [
      NdlRatingComponent
  ]
})
export class NeedlRatingModule { }
