import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NeedlSpinnersModule} from "../spinners/spinners.module";
import {NdlGridPrefixDirective} from "./prefix.directive";
import {NdlGridSuffixDirective} from "./suffix.directive";
import {NdlListItemDirective} from "./list-item.directive";
import {CdkTableModule} from "@angular/cdk/table";
import {NdlListComponent} from "./list.component";
import {NdlScrollPaginatorComponent} from "./scroll-paginator.component";
import {NdlListPlaceholderDirective} from './list-placeholder.directive';
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatCheckboxModule} from '@angular/material/checkbox';
import {NdlListActionDirective} from './list-action.directive';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {NdlTableComponent} from './table.component';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatMenuModule} from '@angular/material/menu';
import {MatListModule} from '@angular/material/list';
import {NdlListFilterDirective} from './list-filter.directive';

@NgModule({
    declarations: [
        NdlListComponent,
        NdlListItemDirective,
        NdlListPlaceholderDirective,
        NdlListActionDirective,
        NdlGridPrefixDirective,
        NdlGridSuffixDirective,
        NdlScrollPaginatorComponent,
        NdlTableComponent,
        NdlListFilterDirective
    ],
    imports: [
        CommonModule,
        NeedlSpinnersModule,
        CdkTableModule,
        DragDropModule,
        MatCheckboxModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
        MatFormFieldModule,
        MatMenuModule,
        MatListModule
    ],
    exports: [
        NdlListComponent,
        NdlListItemDirective,
        NdlListPlaceholderDirective,
        NdlListActionDirective,
        NdlGridPrefixDirective,
        NdlGridSuffixDirective,
        NdlScrollPaginatorComponent,
        NdlTableComponent,
        NdlListFilterDirective
    ]
})
export class NeedlListsModule {
}
