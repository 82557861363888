import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {NeedlFormFieldModule} from '../form-field/form-field.module';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {NdlSelectMultiLevelComponent} from "./select-multi-level.component";
import {NdlSelectMultiLevelFieldComponent} from "./select-multi-level-field.component";
import {MatSelectModule} from "@angular/material/select";

@NgModule({
    declarations: [
        NdlSelectMultiLevelComponent,
        NdlSelectMultiLevelFieldComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatCheckboxModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
        NeedlFormFieldModule
    ],
    exports: [
        NdlSelectMultiLevelComponent
    ]
})
export class NeedlSelectMultiLevelModule {
}
