import {Component, OnInit} from '@angular/core';
import {NdlAxis} from '../../charts/chart-base.directive';
import {NdlXYDataConfig} from '../../charts/xy-chart.component';

@Component({
  selector: 'ndl-charts-demo',
  templateUrl: './charts-demo.component.html',
})
export class NdlChartsDemoComponent {
    xyData = [{
        "date": new Date(2018, 3, 20),
        "value": 90,
        "value2": 45
    }, {
        "date": new Date(2018, 3, 21),
        "value": 102,
        "value2": 90
    }, {
        "date": new Date(2018, 3, 22)
    }, {
        "date": new Date(2018, 3, 23),
        "value": 125
    }, {
        "date": new Date(2018, 3, 24),
        "value": 55,
        "value2": 90
    }, {
        "date": new Date(2018, 3, 25),
        "value": 81,
        "value2": 60
    }, {
        "date": new Date(2018, 3, 26)
    }, {
        "date": new Date(2018, 3, 27),
        "value": 63,
        "value2": 87
    }, {
        "date": new Date(2018, 3, 28),
        "value": 113,
        "value2": 62
    }];

    pieData = [{
        "country": "Lithuania",
        "litres": 501.9
    }, {
        "country": "Czech Republic",
        "litres": 301.9
    }, {
        "country": "Ireland",
        "litres": 201.1
    }, {
        "country": "Germany",
        "litres": 165.8
    }, {
        "country": "Australia",
        "litres": 139.9
    }, {
        "country": "Austria",
        "litres": 128.3
    }, {
        "country": "UK",
        "litres": 99
    }, {
        "country": "Belgium",
        "litres": 60
    }, {
        "country": "The Netherlands",
        "litres": 50
    }];

    XAxis: NdlAxis = {
        type: "Date"
    };
    YAxis: NdlAxis = {
        type: "Value"
    };
    dataXYConfigs: NdlXYDataConfig[] = [
        {type: "Line", name: "Company Profile", xParam: "date", yParam: "value"},
        {type: "Line", name: "Company Profile 2", xParam: "date", yParam: "value2"},
    ];

    mapData = [
        {
        "name": "Northern Europe",
        "countries": [{id: "FI"}, {id: "DK"}, {id: "SE"}, {id: "NO"}, {id: "LT"}, {id: "LV"}, {id: "EE"}, {id: "IS"}],
        "color": "#96BDC6"
        },
        {
        "name": "Central Europe",
        "countries": [{id: "AT"}, {id: "CZ"}, {id: "DE"}, {id: "HU"}, {id: "LI"}, {id: "PL"}, {id: "SK"}, {id: "SI"}, {id: "CH"}, {id: "FR"}],
        "color": "#81968F"
        },
        {
        "name": "Eastern Europe",
        "countries": [{id: "MD"}, {id: "BY"}, {id: "UA"}],
        "color": "#CFB9A5"
        },
        {
        "name": "Southeast Europe",
        "countries": [{id: "AL"}, {id: "BA"}, {id: "BG"}, {id: "HR"}, {id: "GR"}, {id: "XK"}, {id: "MK"}, {id: "ME"}, {id: "RO"}, {id: "RS"}],
        "color": "#E8CCBF"
        },
        {
        "name": "Western Europe",
        "countries": [{id: "FR", color: "red"}, {id: "IE"}, {id: "IT"}, {id: "LU"}, {id: "MC"}, {id: "NL"}, {id: "GB"}, {id: "ES"}, {id: "PT"}, {id: "AD"}],
        "color": "#99C78F"
        },
    ];
}
