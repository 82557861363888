import {WabelEntity} from "./wabel-entity.abstract";
import {PackagingFormat} from "./packaging_format";
import {ProductFile} from "./product_file";
import {Company} from "./company";
import {FileToUpload} from "./fileToUpload";
import {Tag} from "./tag";

export class Product extends WabelEntity {
    constructor(obj: any) {
        super(obj);
        if (obj) {
            this.category = obj.category ? new Tag(obj.category) : null;
            this.packagingformats = obj.packagingformats && obj.packagingformats.length ? obj.packagingformats.map(packagingformat => new PackagingFormat(packagingformat)) : [];
            this.userproductsImages = obj.userproductsImages && obj.userproductsImages.length ? obj.userproductsImages.map(userproductsImage => new ProductFile(userproductsImage)) : [];
            this.userproductsFiles = obj.userproductsFiles && obj.userproductsFiles.length ? obj.userproductsFiles.map(userproductsFile => new ProductFile(userproductsFile)) : [];
            this.company = obj.company ? new Company(obj.company) : null;
            this.similarProducts = obj.similarProducts ? obj.similarProducts.map(product => new Product(product)) : [];
            this.similarProductsFromSameCompany = obj.similarProductsFromSameCompany ? obj.similarProductsFromSameCompany.map(product => new Product(product)) : [];
            this.similarProductsFromOtherCompanies = obj.similarProductsFromOtherCompanies ? obj.similarProductsFromOtherCompanies.map(product => new Product(product)) : [];
            this.activities = obj.activities && obj.activities.items ? obj.activities.items.map(tag => new Tag(tag)) : [];
            this.pricePosition = obj.pricePosition && obj.pricePosition.items ? obj.pricePosition.items.map(tag => new Tag(tag)) : obj.pricePosition ? obj.pricePosition.map(tag => new Tag(tag)) : [];
            this.manufacturingCountry = obj.manufacturingCountry ? new Tag(obj.manufacturingCountry) : null;
            this.targetConsumers = obj.targetConsumers && obj.targetConsumers.items ? obj.targetConsumers.items.map(targetConsumer => new Tag(targetConsumer)) : [];
            this.rangeOfProducts = obj.rangeOfProducts && obj.rangeOfProducts.items ? obj.rangeOfProducts.items.map(rangeOfProduct => new Tag(rangeOfProduct)) : [];
            this.nutritionalInformations = obj.nutritionalInformations && obj.nutritionalInformations.items ? obj.nutritionalInformations.items.map(nutritionalInformation => new Tag(nutritionalInformation)) : [];
            this.qualityLabels = obj.qualityLabels && obj.qualityLabels.items ? obj.qualityLabels.items.map(qualityLabel => new Tag(qualityLabel)) : [];
            this.productAttributes = obj.productAttributes && obj.productAttributes.items ? obj.productAttributes.items.map(productAttributes => new Tag(productAttributes)) : [];
            this.exportCertifications = obj.exportCertifications && obj.exportCertifications.items ? obj.exportCertifications.items.map(exportCertification => new Tag(exportCertification)) : [];
            this.countryOrigin = obj.countryOrigin ? new Tag(obj.countryOrigin) : null;
            this.sustainability = obj.sustainability && obj.sustainability.items ? obj.sustainability.items.map(sustainability => new Tag(sustainability)) : [];
            this.healthAndWellness = obj.healthAndWellness && obj.healthAndWellness.items ? obj.healthAndWellness.items.map(healthAndWellness => new Tag(healthAndWellness)) : [];
            this.consumptionMode = obj.consumptionMode && obj.consumptionMode.items ? obj.consumptionMode.items.map(consumptionMode => new Tag(consumptionMode)) : [];
            this.dietary = obj.dietary && obj.dietary.items ? obj.dietary.items.map(dietary => new Tag(dietary)) : [];
            this.temperatureControlled = obj.temperatureControlled && obj.temperatureControlled.items ? obj.temperatureControlled.items.map(temperatureControlled => new Tag(temperatureControlled)) : [];
            this.packagingProductMaterials = obj.packagingProductMaterials && obj.packagingProductMaterials.items ? obj.packagingProductMaterials.items.map(tag => new Tag(tag)) : [];
            this.packagingProductCategories = obj.packagingProductCategories && obj.packagingProductCategories.items ? obj.packagingProductCategories.items.map(tag => new Tag(tag)) : [];
            this.packagingPurpose = obj.packagingPurpose && obj.packagingPurpose.items ? obj.packagingPurpose.items.map(tag => new Tag(tag)) : [];
            this.packagingConsistency = obj.packagingConsistency && obj.packagingConsistency.items ? obj.packagingConsistency.items.map(tag => new Tag(tag)) : [];
            this.packagingEcoFriendlyFeatures = obj.packagingEcoFriendlyFeatures && obj.packagingEcoFriendlyFeatures.items ? obj.packagingEcoFriendlyFeatures.items.map(tag => new Tag(tag)) : [];
        }
    }

    iduserproduct: number;
    name: string;
    category: Tag;
    level: number;
    machineName: string;
    description: string;
    innovation: boolean;
    logo: any;
    readyForChina: boolean;
    pricePosition: Tag[];
    manufacturingCountry: Tag;
    composition: string;
    packagingformats: PackagingFormat[];
    packagingDescription: string;
    countryOrigin: Tag;
    packaging: string;
    published: boolean;
    placeId: string;
    latitude: number;
    longitude: number;
    country: string;
    regionName: string;
    cityName: string;
    formattedAddress: string;
    userproductsImages: ProductFile[] = [];
    userproductsImagesCount: number;
    userproductsFiles: ProductFile[] = [];
    company: Company;
    url: string;
    urlname: string;
    urlPath: string;
    similarProducts: Product[];
    similarProductsFromSameCompany: Product[];
    similarProductsFromOtherCompanies: Product[];
    position: number;
    logoUrl: string;
    activities: Tag[];
    packaging_categories: Tag[] = [];

    // Fields used only for uploading files
    newProductPictures: FileToUpload[] = [];
    newProductFiles: FileToUpload[] = [];
    newProductPictureCover: string = null;
    productPictureCover: number = null;

    targetConsumers: Tag[];
    rangeOfProducts: Tag[];
    nutritionalInformations: Tag[];
    qualityLabels: Tag[];
    productAttributes: Tag[];
    exportCertifications: Tag[];
    sustainability: Tag[];
    healthAndWellness: Tag[];
    consumptionMode: Tag[];
    dietary: Tag[];
    temperatureControlled: Tag[];
    canBePublished: boolean;
    packagingProductMaterials: Tag[];
    packagingProductCategories: Tag[];
    packagingPurpose: Tag[];
    packagingConsistency: Tag[];
    packagingEcoFriendlyFeatures: Tag[];
    shelfLife: number;
    isPackaging: boolean;

    get isPackagingProduct() {
        return this.isPackaging || this?.category?.machineName === 'packaging';
    }


    get id(): number {
        return this.iduserproduct;
    }

    set id(id: number) {
        this.iduserproduct = id;
    }

    get urlSegment(): string {
        if (!this.company || !this.company.urlname || !this.iduserproduct || !this.urlname) {
            return null;
        }
        return '/p/' + this.company.urlname + '/' + this.iduserproduct + '/' + this.urlname;
    }

    isABrand(): boolean {
        const brandTag = this.activities.find(t => t.name === 'Brand');
        return !!brandTag;
    }
}
