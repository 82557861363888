import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "search-bar",
  templateUrl: "./search-bar.component.html",
  styleUrls: ["./search-bar.component.scss"]
})
export class SearchBarComponent implements OnInit {
  @Input() placeholder = "";

  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Output() clear: EventEmitter<string> = new EventEmitter<string>();

  searchInputValue = "";

  constructor() {}

  ngOnInit() {}

  onSearchInputChange(event) {
    if (event.key === "Enter") {
      this.onSearch();
      return null;
    }
    if (event.key === "Escape") {
      this.onSearchInputClear();
      return null;
    }
    this.searchInputValue = event.target.value;
  }

  onSearchInputClear() {
    this.searchInputValue = "";
    this.clear.emit(null);
  }

  onSearch() {
    this.search.emit(this.searchInputValue);
  }
}
