import {Injectable} from "@angular/core";
import {WabelService} from "./wabel-service.abstract";
import {GraphQLService} from "./graphql-service";
import {LoadingService} from "./loading.service";
import {TagType} from "../entities/tag_type";
import {map} from "rxjs/operators";
import {TagTypeGraphql} from "../graphql/tag_type.graphql";

@Injectable({
    providedIn: 'root'
})
export class TagTypeService extends WabelService {
    public static readonly SPECIALIZATION_INTERNAL_NAME = 'specialization';
    public static readonly MOQ_INTERNAL_NAME = 'minimum_order_quantity';
    public static readonly POINT_OF_SALE_INTERNAL_NAME = 'point_of_sale';
    public static readonly ACTIVITY_INTERNAL_NAME = 'activity';
    public static readonly DISTRIBUTION_CHANNEL_INTERNAL_NAME = 'distribution_channel';
    public static readonly EXPORT_POSITION_INTERNAL_NAME = 'export_position';
    public static readonly CERTIFICATION_INTERNAL_NAME = 'certification';
    public static readonly SUPPLIER_CERTIFICATION = 'supplier_certification';
    public static readonly ECO_PACK_POLICY_FEATURES = 'eco_pack_policy_features';
    public static readonly PACKAGING_PRODUCT_ECO_FRIENDLY_FEATURES = 'packaging_product_eco_friendly_features';
    public static readonly PACKAGING_PRODUCT_MATERIAL = 'packaging_product_material';
    public static readonly PACKAGING_PRODUCT_CATEGORIES = 'packaging_product_categories';
    public static readonly PRICE_RANGE_INTERNAL_NAME = 'price_range';
    public static readonly TARGET_CONSUMER_INTERNAL_NAME = 'target_consumer';
    public static readonly RANGE_OF_PRODUCT_INTERNAL_NAME = 'range_of_product';
    public static readonly NUTRITIONAL_INFORMATION_INTERNAL_NAME = 'nutritional_information';
    public static readonly QUALITY_LABEL_INTERNAL_NAME = 'quality_label';
    public static readonly EXPORT_CERTIFICATION_INTERNAL_NAME = 'export_certification';
    public static readonly CATEGORY_INTERNAL_NAME = 'category';
    public static readonly INSTITUTION_INTERNAL_NAME = 'institutions';
    public static readonly ECO_PACKAGING_PURPOSE_INTERNAL_NAME = 'eco_packaging_purpose';
    public static readonly ECO_PACKAGING_CONSISTENCY_INTERNAL_NAME = 'eco_packaging_consistency';
    public static readonly ECO_PACKAGING_PROJECT_TIMELINE_INTERNAL_NAME = 'eco_packaching_project_timeline';
    public static readonly PACKAGING_PRODUCT_MARKET_AVAILABILITY_INTERNAL_NAME = 'packaging_product_market_availability';
    public static readonly TURNOVER_INTERNAL_NAME = 'turnover';
    public static readonly EVENT_PRIORITIES_INTERNAL_NAME = 'event_priorities';
    public static readonly CURRENCY_INTERNAL_NAME = 'currency';
    public static readonly MARKETING_FOCUS_INTERNAL_NAME = 'marketing_focus';
    public static readonly NB_SELLING_POINTS_INTERNAL_NAME = 'nb_selling_points';
    public static readonly NB_EMPLOYEES_INTERNAL_NAME = 'nb_employees';
    public static readonly LOCATION_INTERNAL_NAME = 'location';
    public static readonly SUSTAINABILITY_INTERNAL_NAME = 'sustainability';
    public static readonly CONSUMPTION_MODE_INTERNAL_NAME = 'consumption_mode';
    public static readonly HEALTH_AND_WELLNESS_INTERNAL_NAME = 'health_and_wellness';
    public static readonly DIETARY_INTERNAL_NAME = 'dietary';
    public static readonly TEMPERATURE_CONTROLLED_INTERNAL_NAME = 'temperature_controlled';
    public static readonly PACKAGING_INTERNAL_NAME = 'packaging';
    public static readonly VOLUME_INTERNAL_NAME = 'volume';

    constructor(protected graphQLService: GraphQLService, protected loadingService: LoadingService) {
        super(graphQLService, loadingService);
    }

    toArrayOfObjects(objects: any[]): TagType[] {
        return objects.map((tag: any) => new TagType(tag));
    }

    toObject(object: any): TagType {
        return new TagType(object);
    }

    getTagTypeByInternalName(tagTypeInternalName: string) {
        return this.query(TagTypeGraphql.queries.tagTypeByInternalName, {
            tagTypeInternalName: tagTypeInternalName
        }, 'network-only').pipe(map(
            (data) => data.data && data.data.tagTypeByInternalName ? new TagType(data.data.tagTypeByInternalName) : null
        ));
    }

    getTagTypeByInternalNameWithTagInternalName(tagTypeInternalName: string) {
        return this.query(TagTypeGraphql.queries.tagTypeByInternalNameWithTagInternalName, {
            tagTypeInternalName: tagTypeInternalName
        }).pipe(map((data) => data.data && data.data.tagTypeByInternalName ? new TagType(data.data.tagTypeByInternalName) : null));
    }

    getFullTagTypeByInternalName(tagTypeInternalName: string, onlyLevel0: boolean = true, network = "network-only") {
        return this.query(TagTypeGraphql.queries.fullTagTypeByInternalName, {
            tagTypeInternalName: tagTypeInternalName,
            onlyLevel0: onlyLevel0
        }, 'network-only').pipe(map((data) => data.data && data.data.tagTypeByInternalName ? new TagType(data.data.tagTypeByInternalName) : null));
    }
}
