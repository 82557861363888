import {NgModule} from "@angular/core";
import {NewVideoMeetingRoutingModule} from "./new-video-meeting-routing.module";
import {CalendarModule, DateAdapter} from "angular-calendar";
import {CommonModule} from "@angular/common";
import {adapterFactory} from "angular-calendar/date-adapters/date-fns";
import {VideoMeetingRequestModalComponent} from "./video-meeting-request-modal/video-meeting-request-modal.component";
import {
    VideoMeetingRequestButtonComponent
} from "./video-meeting-request-button/video-meeting-request-button.component";
import {NeedlSecurityModule} from "@wabel/needl-security";
import {
    NeedlButtonsModule,
    NeedlModalsModule,
    NeedlBannersModule,
    NeedlStepperModule,
    NeedlSelectModule,
    NeedlFormModule,
    NeedlSpinnersModule,
    NeedlTabsModule,
    NeedlCardsModule,
    NeedlListsModule,
    NeedlPlaceholdersModule,
    NeedlTextAreaModule,
    NeedlTooltipModule,
    NeedlTagModule,
    NeedlTextModule
} from "needl-lib";
import {
    VideoMeetingRequestModalStepDurationComponent
} from "./video-meeting-request-modal/video-meeting-request-modal-step-duration/video-meeting-request-modal-step-duration.component";
import {MatIconModule} from "@angular/material/icon";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {
    VideoMeetingRequestModalStepAvailabilitiesComponent
} from "./video-meeting-request-modal/video-meeting-request-modal-step-availabilities/video-meeting-request-modal-step-availabilities.component";
import {
    VideoMeetingRequestModalStepConfirmationComponent
} from "./video-meeting-request-modal/video-meeting-request-modal-step-confirmation/video-meeting-request-modal-step-confirmation.component";
import {VideoMeetingsPageComponent} from "./video-meetings-page/video-meetings-page.component";
import {
    VideoMeetingAvailabilitiesComponent
} from "./video-meeting-request-modal/video-meeting-request-modal-step-availabilities/video-meeting-availabilities/video-meeting-availabilities.component";
import {
    VideoMeetingRequestCardComponent
} from "./video-meeting-request-card/video-meeting-request-card.component";
import {
    VideoMeetingRequestScheduleMeetingComponent
} from "./video-meeting-request-schedule-meeting/video-meeting-request-schedule-meeting.component";
import {
    VideoMeetingRequestCancelMeetingComponent
} from "./video-meeting-request-cancel-meeting/video-meeting-request-cancel-meeting.component";
import {DateFormatPipe} from "../../pipes/dateFormat.pipe";
import {SharedModule} from "../shared-module/shared.module";
import {CompanyCardModule} from "../../components/company/company-card/company-card.module";
import {MatTooltipModule} from "@angular/material/tooltip";
import {
    VideoMeetingInviteColleaguesComponent
} from "./video-meeting-invite-colleagues/video-meeting-invite-colleagues.component";
import {
    VideoMeetingParticipantsListComponent
} from "./video-meeting-participants-list/video-meeting-participants-list.component";
import {VideoMeetingComponent} from "./video-meeting/video-meeting.component";
import {VideoMeetingIframeComponent} from "./video-meeting-iframe/video-meeting-iframe.component";

@NgModule({
    declarations: [
        VideoMeetingRequestButtonComponent,
        VideoMeetingRequestModalComponent,
        VideoMeetingRequestModalStepDurationComponent,
        VideoMeetingRequestModalStepAvailabilitiesComponent,
        VideoMeetingRequestModalStepConfirmationComponent,
        VideoMeetingsPageComponent,
        VideoMeetingAvailabilitiesComponent,
        VideoMeetingRequestCardComponent,
        VideoMeetingRequestScheduleMeetingComponent,
        VideoMeetingRequestCancelMeetingComponent,
        VideoMeetingInviteColleaguesComponent,
        VideoMeetingParticipantsListComponent,
        VideoMeetingComponent,
        VideoMeetingIframeComponent
    ],
    imports: [
        CommonModule,
        NewVideoMeetingRoutingModule,
        FormsModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        NeedlSecurityModule,
        NeedlButtonsModule,
        NeedlModalsModule,
        NeedlBannersModule,
        NeedlStepperModule,
        MatIconModule,
        NeedlSelectModule,
        ReactiveFormsModule,
        NeedlFormModule,
        NeedlSpinnersModule,
        NeedlTabsModule,
        NeedlCardsModule,
        NeedlListsModule,
        NeedlPlaceholdersModule,
        NeedlTextAreaModule,
        SharedModule,
        CompanyCardModule,
        NeedlTooltipModule,
        NeedlTagModule,
        MatTooltipModule,
        NeedlTextModule,
        NeedlBannersModule
    ],
    exports: [
        VideoMeetingRequestButtonComponent,
        VideoMeetingIframeComponent
    ],
    providers: [
        DateFormatPipe
    ]
})

export class NewVideoMeetingModule {
}
