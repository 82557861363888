import { MemberThirdPartyCalendar } from "./member_third_party_calendar";
import {WabelEntity} from "./wabel-entity.abstract";

export class MemberThirdPartyOAuthReffrence extends WabelEntity {
    idtokenreffrence: number;
    provider: string
    lastUsedOn: Date
    memberThirdPartyCalendars: MemberThirdPartyCalendar[]

    constructor(obj: any) {
        if (obj !== null) {
            super(obj);
            this.idtokenreffrence = obj.id;
            this.lastUsedOn = obj.lastUsedOn ? new Date( obj.lastUsedOn ): null
            this.memberThirdPartyCalendars = obj.memberThirdPartyCalendars && obj.memberThirdPartyCalendars.items ? obj.memberThirdPartyCalendars.items.map(x => new MemberThirdPartyCalendar(x)): []
        }
    }

    get id(): number {
        return this.idtokenreffrence;
    }

    set id(id: number) {

    }

}
