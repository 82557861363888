import {WabelEntity} from "./wabel-entity.abstract";
import {Member} from "./member";

export class MemberNotification extends WabelEntity {
    constructor(obj: any) {
        super(obj);
        if (obj) {
            this.member = obj.member ? new Member(obj.member) : null;
        }
    }

    id: number;
    member: Member;
    type: string;
    internalName: string;
    title: string;
    content: string;
    severity: string;
    status: string;
    dateLastAction: string;
}

export const DASHBOARD_MODAL_INTERNALNAME = 'dashboard-tuto';
export const BUYER_SCOPE_MODAL_INTERNALNAME = 'buyer-scope-update';
export const MY_EVENT_MODAL_INTERNALNAME = 'my-event-welcome-video';
export const MY_EVENT_DASHBOARD_MODAL_INTERNALNAME = 'my-event-dashboard-video';
export const MY_EVENT_DASHBOARD_INTRO_HOST_MODAL_INTERNALNAME = 'my-event-dashboard-intro-host-video';
export const MY_EVENT_DASHBOARD_INTRO_GUEST_MODAL_INTERNALNAME = 'my-event-dashboard-intro-guest-video';
export const EXPLORE_MARKET_MENU_TOUR_INTERNALNAME = 'explore-market-menu-tour';
export const SOURCING_REQUEST_TOUR_INTERNALNAME = 'sourcing-request-tour';
export const SOURCING_NEED_TOUR_INTERNALNAME = 'sourcing-need-tour';
export const ANALYTICS_MENU_TOUR_INTERNALNAME = 'analytics-menu-tour';
export const DISCOVER_BUSINESS_PRIORITIES_TOUR_INTERNALNAME = 'discover-business-priorities-tour';
export const REACT_TO_BM_RECOMMENDATIONS_TOUR_INTERNALNAME = 'react-to-bm-recommendation-tour';
export const SEE_PROSPECTS_LIST_TOUR_INTERNALNAME = 'see-prospects-list-tour';
export const FIRST_BUSINESS_MAPPING_PRIORITY_RECOMMENDATION_REMOVED_INTERNALNAME = 'first-business-mapping-priority-recommendation-removed';
export const AFTER_FIRST_RATINGS_TOUR_INTERNALNAME = 'after-first-ratings-tour';
