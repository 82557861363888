import {WabelEntity} from "./wabel-entity.abstract";
import {Company} from "./company";
import {Event} from "./event";
import {Member} from "./member";
import {Meeting} from "./meeting";

export class EventMeetingConfirmation extends WabelEntity {
    constructor(obj: any) {
        super(obj);
        if (obj) {
            if (obj.certainEvent) {
                this.event = new Event(obj.certainEvent);
            }
            if (obj.member) {
                this.member = new Member(obj.member);
            }
            if (obj.company) {
                this.company = new Company(obj.company);
            }
            if (obj.companyTarget) {
                this.companyTarget = new Company(obj.companyTarget);
            }
            if (obj.meeting) {
                this.meeting = new Meeting(obj.meeting);
            }
        }
    }

    ideventmeetingconfirmation: number;
    event: Event;
    member: Member;
    company: Company;
    companyTarget: Company;
    meeting: Meeting;
    choice: string;

    get id(): number {
        return this.ideventmeetingconfirmation;
    }
}
