import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'ndl-custom-tooltip',
    templateUrl: './custom-tooltip.component.html',
    styleUrls: ['./custom-tooltip.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NdlCustomTooltipComponent {
    @Input() tooltipText: string;

}
