import gql from "graphql-tag";

export class EventOrganizerGraphql {
  static fragments = {
    full: gql`
        fragment fullEventOrganizer on EventOrganizer {
            ideventorganizer,
            name,
            urlname,
            link,
            fileLogo {
                url
            }
            fileSmallLogo {
                url
            }
            dashboardText: customContentByName(name: "dashboardText") {
                content
            }
            dashboardTextSeller: customContentByName(name: "dashboardTextSeller") {
                content
            }
            dashboardTextBuyer: customContentByName(name: "dashboardTextBuyer") {
                content
            }
            mainColor
            linkTc
            linkRegistration
            contactMail
            contactPhone
            helpdeskKey
            helpEmail
            tutorialVideoLink
            tipsVideoLink
            nextEvent {
                backgroundImageUrl
                name
                dates
            }
        }
    `
  };

  static queries = {
      eventOrganizerByUrlname: gql`
          query EventOrganizer($urlname: String!) {
              eventOrganizerByUrlname(urlname: $urlname) {
                  ...fullEventOrganizer
              }
          }
          ${EventOrganizerGraphql.fragments.full}
      `,
      eventOrganizersWhereIHaveRegistration: gql`
          query EventOrganizer {
              eventOrganizersWhereIHaveRegistration {
                  items {
                      ...fullEventOrganizer
                  }
              }
          }
          ${EventOrganizerGraphql.fragments.full}
      `,
  };

  static mutations = {};
}
