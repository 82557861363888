import {Registration} from './registration';
import {EventWorkshop} from './event_workshop';
import {WabelEntity} from "./wabel-entity.abstract";

export class EventWorkshopPreference extends WabelEntity {

    constructor(obj?: any) {
        super(obj);
        if (obj) {
            this.registration = obj.registration ? new Registration(obj.registration) : null;
            this.eventWorkshop = obj.eventworkshop ? new EventWorkshop(obj.eventworkshop) : null;
        }
    }

    ideventworkshoppreference: number;
    registration: Registration;
    eventWorkshop: EventWorkshop;

    get id(): number {
        return +this.ideventworkshoppreference;
    }

    set id(id: number) {
        this.ideventworkshoppreference = id;
    }
}
