import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Company} from "../../../../services/wabel-client/entities/company";
import {CompanyService} from "../../../../services/wabel-client/services/company.service";
import {Subscription} from "rxjs";
import {BusinessMappingPriority} from "../../../../services/wabel-client/entities/business_mapping_priority";
import {
    LINKEDIN_BASE_PROFILE_URL,
    FACEBOOK_BASE_PROFILE_URL,
    INSTAGRAM_BASE_PROFILE_URL
} from "../../../../services/wabel-client/entities/company";

@Component({
    selector: 'app-company-details',
    templateUrl: './company-details.component.html',
    styleUrls: ['./company-details.component.scss'],
})
export class CompanyDetailsComponent implements OnInit, OnDestroy {
    company: Company;
    @Input() urlname;
    @Input() businessMappingPriority: BusinessMappingPriority = null;
    company$: Subscription;
    INSTAGRAM_BASE_PROFILE_URL = INSTAGRAM_BASE_PROFILE_URL;
    FACEBOOK_BASE_PROFILE_URL = FACEBOOK_BASE_PROFILE_URL;
    LINKEDIN_BASE_PROFILE_URL = LINKEDIN_BASE_PROFILE_URL;
    viewMoreDescription = false;

    constructor(private companyService: CompanyService) {
    }

    ngOnInit() {
        this.company$ = this.companyService.getCompanyProfileDetails(this.urlname, this.businessMappingPriority).subscribe(data => {
            this.company = data;
        });
    }

    ngOnDestroy() {
        if (this.company$) {
            this.company$.unsubscribe();
        }
    }
}
