import {Directive, HostBinding, Input} from '@angular/core';
import {NdlStatus} from '../core/types';

@Directive({
    selector: 'ndl-card-flag',
    host: {
        'class': 'ndl-card-flag'
    }
})
export class NdlCardFlagDirective {
    @Input() theme: NdlStatus = 'neutral';

    @HostBinding('class.ndl-default')
    private get _isDefault() {
        return this.theme === "default";
    }

    @HostBinding('class.ndl-primary')
    private get _isPrimary() {
        return this.theme === "primary";
    }

    @HostBinding('class.ndl-secondary')
    private get _isSecondary() {
        return this.theme === "secondary";
    }

    @HostBinding('class.ndl-success')
    private get _isSuccess() {
        return this.theme === "success";
    }

    @HostBinding('class.ndl-warning')
    private get _isWarning() {
        return this.theme === "warning";
    }

    @HostBinding('class.ndl-danger')
    private get _isDanger() {
        return this.theme === "danger";
    }

    @HostBinding('class.ndl-neutral')
    private get _isNeutral() {
        return this.theme === "neutral";
    }
}
