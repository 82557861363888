import gql from "graphql-tag";

export class MemberAvailabilityGraphql {
    static fragments = {};

    static queries = {
        get_availability: gql`
            query MemberAvailability($idmember : Int) {
                meetingAvailabilities(id : $idmember) {
                    items {
                      day
                      timeFrom
                      timeTo
                      timezone
                    }
                }
            }
        `
    };

    static mutations = {
        set_availability: gql`
            mutation MemberAvailability($availabilities: String! , $timezone: String!) {
                saveAvailabilities(availabilities: $availabilities , timezone: $timezone) {
                    items {
                        day
                        timeFrom
                        timeTo
                        timezone    
                    }
                }
            }
        `,

    };
}
