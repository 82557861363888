import {NdlCheckboxesNewComponent} from './checkboxes.component';
import {NdlCheckboxesFieldComponent} from './checkboxes-field.component';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {NeedlFormFieldModule} from '../form-field/form-field.module';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';

@NgModule({
    declarations: [
        NdlCheckboxesNewComponent,
        NdlCheckboxesFieldComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        NeedlFormFieldModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule
    ],
    exports: [
        NdlCheckboxesNewComponent
    ]
})
export class NeedlCheckboxesNewModule {
}
