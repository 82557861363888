import {
    AfterViewInit,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges
} from '@angular/core';
import {TemplateService} from "../../../../../services/template.service";
import {Tag} from "../../../../../services/wabel-client/entities/tag";

@Component({
    selector: 'retail-group-popin-categories',
    templateUrl: './retail-group-popin-categories.component.html',
    styleUrls: ['./retail-group-popin-categories.component.scss']
})
export class RetailGroupPopinCategoriesComponent implements OnInit, OnChanges, AfterViewInit {

    @Output() closeEvent: EventEmitter<string> = new EventEmitter<string>();
    @Output() addingCategory: EventEmitter<string> = new EventEmitter<string>();
    @Output() subcategorySelected: EventEmitter<Tag> = new EventEmitter<Tag>();
    @Output() removingCategory: EventEmitter<string> = new EventEmitter<string>();
    @Output() categoriesChanged: EventEmitter<Tag[]> = new EventEmitter<Tag[]>();
    @Input() category: Tag;

    currentSubCategory: Tag = null;

    @Input() selectedCategories: Tag[] = [];
    @Input() selectedCategory: Tag = null;

    isSmallScreen: boolean = false;
    showPanelLeft: boolean = true;

    showPanelRight: boolean = true;

    @Input() level: number = 3;
    @Input() multiple: boolean = false;
    @Input() selectAll: boolean = false;

    categoryLoop: Tag[] = [];

    constructor(private renderer: Renderer2, public templateService: TemplateService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
    }


    ngOnInit() {
        if (this.level == 2) {
            this.currentSubCategory = this.category;
            this.categoryLoop = [this.category];
        } else {
            this.categoryLoop = this.category.children;
            if (this.selectedCategory && this.category.id === this.selectedCategory.parent.parent.id) {
                this.setCurrentSubCategory(this.selectedCategory.parent);
            } else if (this.category.children.length) {
                for (const category of this.category.children) {
                    if (category.children.length > 1 || (category.children.length === 1 && category.children[0].name != '')) {
                        this.setCurrentSubCategory(category);
                        break;
                    }
                }
            }

            this.checkSmallScreen();
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.checkSmallScreen();
        }, 1);
    }

    setCurrentSubCategory(cat: Tag) {
        this.currentSubCategory = cat;
        this.subcategorySelected.emit(cat);
        this.checkSmallScreen();
    }

    isSelected(category: Tag) {
        if (this.multiple) {
            return this.selectedCategories.filter(cat => cat.id === category.id).length > 0;
        } else {
            return (this.selectedCategory && this.selectedCategory.id == category.id);
        }
    }

    addACategory(category: Tag) {
        if (this.multiple) {
            this.selectedCategories.push(category);
            this.categoriesChanged.emit(this.selectedCategories);
        } else {
            this.addingCategory.emit(category.id.toString());
        }
    }

    removeACategory(category: Tag) {
        if (this.multiple) {
            this.selectedCategories = this.selectedCategories.filter(obj => obj.id !== category.id);
            this.categoriesChanged.emit(this.selectedCategories);
        } else {
            this.removingCategory.emit(category.id.toString());
        }
    }

    @HostListener('window:resize', [])
    checkSmallScreen() {
        this.isSmallScreen = window.innerWidth < 1200;

        if (this.isSmallScreen) {
            if (this.currentSubCategory === null) {
                this.showPanelLeft = true;
                this.showPanelRight = false;
            } else {
                this.showPanelLeft = false;
                this.showPanelRight = true;
            }
        } else {
            this.showPanelLeft = true;
            this.showPanelRight = true;
        }
    }

    selectAllFromCategory(category: Tag) {
        for (const subcategory of category.children) {
            if (!this.isSelected(subcategory)) {
                this.addACategory(subcategory);
            }
        }
    }

    unselectAllFromCategory(category: Tag) {
        for (const subcategory of category.children) {
            this.removeACategory(subcategory);
        }
    }
}
