<ndl-form-field #formField [prefix]="prefix" [suffix]="suffix" [label]="label" [hint]="hint" [errors]="errors">
    <ng-template #prefix>
        <ng-content select="[ndl-prefix]"></ng-content>
    </ng-template>

    <input matInput [formControl]="innerFormControl" [type]="type" [required]="required" [name]="name"
           [disabled]="disabled" [placeholder]="placeholder" (blur)="onTouched()"
           (change)="onChange($event.target.value)"/>
    <ng-template #suffix>
        <button mat-icon-button type="button" *ngIf="!formField.empty && !disabled && clearable" (click)="clear()">
            <mat-icon>close</mat-icon>
        </button>
        <ng-content select="[ndl-suffix]"></ng-content>
    </ng-template>
</ndl-form-field>
