import {
    AfterViewInit,
    ChangeDetectorRef,
    Component, EventEmitter, forwardRef, HostBinding, HostListener, Inject, Input, OnInit, Optional, Self, ViewChild
} from '@angular/core';
import {NdlInputBaseDirective} from '../input-base.directive';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {Observable} from 'rxjs';
import {FileInterface} from './file.interface';
import {Mimes, NeedlAcceptedUploadTypes} from './mimes';
import {NdlUploadFieldComponent} from './upload-field.component';

@Component({
    selector: 'ndl-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.scss'],
    exportAs: 'ndlUpload',
    host: {
        'class': 'ndl-upload',
        '(blur)': 'onTouched()'
    }
})
export class NdlUploadComponent extends NdlInputBaseDirective<FileInterface[]> implements AfterViewInit {
    override defaultValue = [];
    override _value = [];

    fileTypes: string = null;
    validators = () => this.uploadField?.filesInError.length ? {'mime-type-mismatch': true} : null;

    @Input() editLink: string = null;
    @Input() maxSizeMB: number = 16;
    @Input() maxWidthPx: number = 4000;
    @Input() maxHeightPx: number = 4000;
    @Input() showAutoLimits: boolean = false;

    get autoLimits(): string {
        const exts = Mimes.getExtForInputFile(this.type);
        let hint = `Accepted files: ${exts}, max size: ${this.maxSizeMB}MB`;
        if (['profile', 'image'].includes(this.type)) {
            hint += `, max dimensions: ${this.maxWidthPx}x${this.maxHeightPx} px`;
        }
        return hint;
    }

    private _type;
    @Input() get type(): NeedlAcceptedUploadTypes {
        return this._type;
    }

    set type(type) {
        this._type = type;
        this.fileTypes = Mimes.getTypesForInputFile(type);
    }

    @Input()
    get multiple() {
        return this._multiple;
    }

    set multiple(multiple) {
        this._multiple = coerceBooleanProperty(multiple);
    }

    private _multiple = false;

    @Input()
    get autoupload() {
        return this._autoupload;
    }

    set autoupload(autoupload) {
        this._autoupload = coerceBooleanProperty(autoupload);
    }

    private _autoupload = false;

    @ViewChild(NdlUploadFieldComponent, {static: true}) uploadField: NdlUploadFieldComponent;

    @HostBinding('class.ndl-upload-drag-hover') isDragging = false;

    get wrongMimeType() {
        return this.innerFormControl?.errors && this.innerFormControl?.errors['mime-type-mismatch'];
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
        this.uploadField.uploadComponent = this;
    }

    delete(index = 0) {
        this.value.splice(index, 1);
    }
}
