import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NdlCarouselComponent} from "./components/carousel/carousel.component";
import {NdlCarouselNewComponent} from './components/carousel-new/carousel.component';
import {NdlSlideComponent} from './components/carousel-new/slide.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {DragDropModule} from '@angular/cdk/drag-drop';

@NgModule({
    declarations: [
        NdlCarouselComponent,
        NdlCarouselNewComponent,
        NdlSlideComponent
    ],
    imports: [
        CommonModule,
        MatTabsModule,
        MatIconModule,
        MatButtonModule,
        DragDropModule
    ],
    exports: [
        NdlCarouselComponent,
        NdlCarouselNewComponent,
        NdlSlideComponent
    ]
})
export class NeedlCarouselsModule {
}
