import {Component} from '@angular/core';
import {NdlStatus} from '../../core/types';

@Component({
    selector: 'ndl-tag-demo',
    templateUrl: './tag-demo.component.html'
})
export class NdlTagDemoComponent {
    themes: NdlStatus[] = ["default", "primary", "secondary", "info", "success", "warning", "danger", "neutral"];
}
