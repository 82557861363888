<ndl-filter-template [label] = "label" [validationButtonLabel] = "validationButtonLabel" [clearButtonLabel] = "clearButtonLabel"
                     [filterNumber] = "filterNumber"
                     [forceUnDisabling]="validatedOptions.length"
                     (applied)="apply()"
                     (cleared)="clear()"
                     (listClosed)="closeList()">
    <ndl-checkboxes [options] = "options"
                    [selectedOptions] = "selectedOptions"
                    [searchable] = "searchable"
                    [searchPlaceholder] = "searchPlaceholder"
                    [columnNumber]="1"
                    [maximumSelectedOption]="maximumSelectedOption"
                    (listChanged)="selectedOptions = $event.slice(0);">
    </ndl-checkboxes>
</ndl-filter-template>
