import {Injectable} from '@angular/core';
import {WabelService} from "./wabel-service.abstract";
import {GraphQLService} from "./graphql-service";
import {LoadingService} from "./loading.service";
import {filter, map} from "rxjs/operators";
import {SourcingRequest} from "../entities/sourcing_request";
import {SourcingRequestGraphql} from "../graphql/sourcing_request.graphql";
import {SourcingRequestMemberResponse} from "../entities/sourcing_request_member_response";
import {SourcingRequestRecommendation} from "../entities/sourcing_request_recommendation";
import {SourcingNeed} from "../entities/sourcing_need";
import {SourcingNeedRecommendation} from "../entities/sourcing_need_recommendation";
import {SourcingNeedRecommendationMemberResponse} from "../entities/sourcing_need_recommendation_member_response";
import {Subject} from "rxjs";
import {ResultIterator} from "../entities/result_iterator";
import {EcoPackProjectOption} from "../entities/eco-pack-project-option";
import {File} from "../entities/file";
import {Company} from "../entities/company";

@Injectable({
    providedIn: 'root'
})
export class SourcingRequestService extends WabelService {

    public static readonly SOURCING_REQUEST_EMAIL_ADDRESS = 'productrequest@needl.co';

    public static readonly APPLIED = 'applied';
    public static readonly DO_NOT_MANUFACTURE = 'do_not_manufacture';
    public static readonly DO_NOT_QUALIFY = 'do_not_qualify';
    public static readonly COUNTRY_NOT_INTERESTING = 'country_not_interesting';
    public static readonly NONE_OF_THE_ABOVE = 'none_of_the_above';
    public static readonly BUYER_DISTRIBUTION_COUNTRY = 'buyer_distribution_country';
    public static readonly PREFERRED_SUPPLIER_LOCATION = 'preferred_supplier_location';

    modalTypeInBusinessOpportunitiesPage: {type: string, company: Company} = null;
    currentSourcingRequest: SourcingRequest;
    createdSourcingNeeds: SourcingNeed[];

    constructor(protected graphqlService: GraphQLService, protected loadingService: LoadingService) {
        super(graphqlService, loadingService);
    }

    toObject(object: any): SourcingRequest {
        return new SourcingRequest(object);
    }

    toArrayOfObjects(objects: any[]): SourcingRequest[] {
        return objects.map((sourcingRequest: any) => new SourcingRequest(sourcingRequest));
    }

    getReadableBuyerStatusReason(buyerStatusReason: string) {
        switch (buyerStatusReason) {
            case 'profile_not_qualified':
                return 'Profile is not qualified';
            case 'already_work_with':
                return 'I already work with this company';
            case 'do_not_want_work_with':
                return 'I don\'t want to work with this company';
            case 'country_not_interesting':
                return 'The country is not interesting';
            default:
                return null;
        }
    }

    getMySourcingRequests(limit: number = null, offset: number = null) {
        return this.query(SourcingRequestGraphql.queries.mySourcingRequests, {
            offset: offset,
            limit: limit
        })
            .pipe(
                filter(data => data.data !== undefined),
                map(data => data.data.mySourcingRequests ? new ResultIterator<SourcingRequest>(data.data.mySourcingRequests.count, this.toArrayOfObjects(data.data.mySourcingRequests.items) as SourcingRequest[]) : null)
            );
    }

    getSourcingRequestRecommendations(sourcingRequest: SourcingRequest, limit: number, offset: number) {
        return this.query(SourcingRequestGraphql.queries.sourcingRequestRecommendations, {
            id: sourcingRequest.id,
            limit: limit,
            offset: offset
        })
            .pipe(
                filter(data => data.data !== undefined),
                map(data => data.data.sourcingRequestById && data.data.sourcingRequestById ? this.toObject(data.data.sourcingRequestById) : null)
            );
    }

    saveSourcingRequest(
        sourcingRequest: SourcingRequest,
        reviewStartDate: string,
        activities: number[],
        certificationsAndLabels: number[],
        productAttributes: number[],
        distributionCountries: number[],
        preferredSupplierCountries: number[],
        openToOthersLocations: boolean,
        pricePositioning: number[],
        volumeTags: number[],
        volume: string = null,
        purchasingPriceRange: string = null,
        packagingValue: string = null,
        packagingUnit: number = null,
        packageLength: number = null,
        packageWidth: number = null,
        packagingHeight: number = null,
        productSpec: string = null,
        blackListedCompanies = [],
        customBlackListedCompanies = [],
        file: File = null,
        projectDeadline: string = null,
        reviewEndDate: string = null
    ) {
        return this.mutation(SourcingRequestGraphql.mutations.saveSourcingRequest, {
            sourcingRequest: {
                id: sourcingRequest.id
            },
            projectDeadline: projectDeadline,
            activities: activities,
            certificationsAndLabels: certificationsAndLabels,
            productAttributes: productAttributes,
            distributionCountries: distributionCountries,
            preferredSupplierCountries: preferredSupplierCountries,
            openToOthersLocations: openToOthersLocations,
            pricePositioning: pricePositioning,
            volumeTags: volumeTags,
            volume: volume,
            purchasingPriceRange: purchasingPriceRange,
            packagingValue: packagingValue,
            packagingUnit: packagingUnit,
            packageLength: packageLength,
            packageWidth: packageWidth,
            packagingHeight: packagingHeight,
            productSpec: productSpec,
            blackListedCompanies: blackListedCompanies,
            customBlackListedCompanies: customBlackListedCompanies,
            file: file,
            reviewStartDate: reviewStartDate,
            reviewEndDate: reviewEndDate
        })
            .pipe(
                filter(data => data.data !== undefined),
                map(data => data.data.saveSourcingRequest ? this.toObject(data.data.saveSourcingRequest) : null)
            );
    }

    saveSourcingRequestAdmin(
        sourcingRequest: SourcingRequest,
        name: string,
        distributionChannels: string,
        categories: number[],
        keywords: string[] = [],
        projectDeadline: string = null,
        reviewStartDate: string = null,
        reviewEndDate: string = null,
        activities: number[] = [],
        certificationsAndLabels: number[] = [],
        productAttributes: number[] = [],
        distributionCountries: number[] = [],
        preferredSupplierCountries: number[] = [],
        openToOthersLocations: boolean = true,
        pricePositioning: number[] = [],
        volumeTags: number[] = [],
        volume: string = null,
        purchasingPriceRange: string = null,
        packagingValue: string = null,
        packagingUnit: number = null,
        packageLength: number = null,
        packageWidth: number = null,
        packagingHeight: number = null,
        productSpec: string = null,
        blackListedCompanies = [],
        customBlackListedCompanies = [],
        file: File = null,
        sendMail = false
    ) {
        return this.mutation(SourcingRequestGraphql.mutations.saveSourcingRequestAdmin, {
            sourcingRequest: {
                id: sourcingRequest.id
            },
            name: name,
            distributionChannels: distributionChannels,
            categories: categories,
            keywords: keywords,
            projectDeadline: projectDeadline,
            activities: activities,
            certificationsAndLabels: certificationsAndLabels,
            productAttributes: productAttributes,
            distributionCountries: distributionCountries,
            preferredSupplierCountries: preferredSupplierCountries,
            openToOthersLocations: openToOthersLocations,
            pricePositioning: pricePositioning,
            volumeTags: volumeTags,
            volume: volume,
            purchasingPriceRange: purchasingPriceRange,
            packagingValue: packagingValue,
            packagingUnit: packagingUnit,
            packageLength: packageLength,
            packageWidth: packageWidth,
            packagingHeight: packagingHeight,
            productSpec: productSpec,
            blackListedCompanies: blackListedCompanies,
            customBlackListedCompanies: customBlackListedCompanies,
            file: file,
            sendMail: sendMail,
            reviewStartDate: reviewStartDate,
            reviewEndDate: reviewEndDate
        })
            .pipe(
                filter(data => data.data !== undefined),
                map(data => data.data.saveSourcingRequestAdmin && data.data.saveSourcingRequestAdmin ? this.toObject(data.data.saveSourcingRequestAdmin) : null)
            );
    }

    getSourcingRequestById(id: number) {
        return this.query(SourcingRequestGraphql.queries.sourcingRequestById, {id: id})
            .pipe(
                filter(data => data.data !== undefined),
                map(data => data.data.sourcingRequestById && data.data.sourcingRequestById ? this.toObject(data.data.sourcingRequestById) : null)
            );
    }

    getSourcingRequestByIdForSupplier(id: number, idCompany: number, idMember: number) {
        return this.query(SourcingRequestGraphql.queries.sourcingRequestByIdForSupplier, {id: id, idCompany: idCompany, idMember: idMember})
            .pipe(
                filter(data => data.data !== undefined),
                map(data => data.data.sourcingRequestById && data.data.sourcingRequestById ? this.toObject(data.data.sourcingRequestById) : null)
            );
    }

    updateSourcingRequestStatus(sourcingRequest: SourcingRequest, newRequestStatus: string) {
        return this.mutation(SourcingRequestGraphql.mutations.updateSourcingRequestStatus, {
            idSourcingRequest: sourcingRequest.id,
            newRequestStatus: newRequestStatus
        }).pipe(map(data => new SourcingRequest(data.data.updateSourcingRequestStatus)));
    }

    saveAndSendSourcingRequest(sourcingRequest: SourcingRequest) {
        return this.mutation(SourcingRequestGraphql.mutations.saveAndSendSourcingRequest, {
            sourcingRequest: {
                id: sourcingRequest.id,
                name: sourcingRequest.name,
                productSpec: sourcingRequest.productSpec,
                volume: sourcingRequest.volume,
                //blacklist: sourcingRequest.blacklist,
                openToOthersLocations: sourcingRequest.openToOthersLocations,
                packageWidth: sourcingRequest.packageWidth,
                packageHeight: sourcingRequest.packageHeight,
                packageLength: sourcingRequest.packageLength,
                packagingValue: sourcingRequest.packagingValue,
                idPackagingUnit: sourcingRequest.packagingUnit ? sourcingRequest.packagingUnit.idpackagingunit : null,
                idTagsActivities: sourcingRequest.activities.map(activity => activity.id),
                idTagCategory: sourcingRequest.categories ? sourcingRequest.categories[0].id : null,
                idTagPricePositioning: sourcingRequest.pricePositioning.map(pricePositioning => pricePositioning.id),
                idTagsCertifications: sourcingRequest.certifications.map(certification => certification.id),
                idCountries: sourcingRequest.preferredSupplierCountries.map(country => country.id),
                expiryDate: sourcingRequest.expiryDate,
                distributionChannels: sourcingRequest.distributionChannels,
                distributionCountries: sourcingRequest.distributionCountries
            }
        })
            .pipe(
                filter(data => data.data !== undefined),
                map(data => data.data.saveAndSendSourcingRequest ? this.toObject(data.data.saveAndSendSourcingRequest) : null)
            );
    }

    saveSourcingRequestMemberResponseInterested(idSourcingRequest: number, statusCustomReason: string, secondColleague = false) {
        return this.mutation(SourcingRequestGraphql.mutations.saveSourcingRequestMemberResponseInterested, {
            idSourcingRequest: idSourcingRequest,
            statusCustomReason: statusCustomReason,
            secondColleague: secondColleague
        })
            .pipe(
                filter(data => data.data !== undefined),
                map(data => data.data.saveSourcingRequestMemberResponseInterested ? new SourcingRequestMemberResponse(data.data.saveSourcingRequestMemberResponseInterested) : null)
            );
    }

    saveSourcingRequestMemberResponseNotInterested(idSourcingRequest: number, notInterestedStatusReason: string, statusCustomReason: string = null) {
        return this.mutation(SourcingRequestGraphql.mutations.saveSourcingRequestMemberResponseNotInterested, {
            idSourcingRequest: idSourcingRequest,
            notInterestedStatusReason: notInterestedStatusReason,
            statusCustomReason: statusCustomReason
        })
            .pipe(
                filter(data => data.data !== undefined),
                map(data => data.data.saveSourcingRequestMemberResponseNotInterested ? new SourcingRequestMemberResponse(data.data.saveSourcingRequestMemberResponseNotInterested) : null)
            );
    }

    updateSourcingRequestNoLongerActive(sourcingRequest: SourcingRequest, reason: string) {
        return this.mutation(SourcingRequestGraphql.mutations.updateSourcingRequestNoLongerActive, {
            idSourcingRequest: sourcingRequest.id,
            reason: reason
        }).pipe(map(data => new SourcingRequest(data.data.updateSourcingRequestNoLongerActive)));
    }

    updateSourcingRequestRecommendationBuyerInterestStatus(sourcingRequestRecommendation: SourcingRequestRecommendation, newStatus: string, reason: string = null, statusCustomReason: string = null) {
        return this.mutation(SourcingRequestGraphql.mutations.updateSourcingRequestRecommendationBuyerInterestStatus, {
            idSourcingRequest: sourcingRequestRecommendation.sourcingRequest.id,
            idCompany: sourcingRequestRecommendation.company.idcompany,
            newStatus: newStatus,
            reason: reason,
            statusCustomReason: statusCustomReason
        }).pipe(map(data => new SourcingRequestRecommendation(data.data.updateSourcingRequestRecommendationBuyerInterestStatus)));
    }

    saveSourcingNeed(categories: number[],
                     activities: number[],
                     timeline: string,
                     productSpecifications: number[] = [],
                     certifications: number[] = [],
                     locations: number[] = [],
                     deadline: string = null) {
        return this.mutation(SourcingRequestGraphql.mutations.saveSourcingNeed, {
            categories: categories,
            activities: activities,
            timeline: timeline,
            productSpecifications: productSpecifications,
            certifications: certifications,
            locations: locations,
            deadline: deadline
        }).pipe(map(data => data.data && data.data.saveSourcingNeed ? data.data.saveSourcingNeed.map(sn => new SourcingNeed(sn)) : []));
    }

    getSourcingNeedByIds(ids: number[]) {
        return this.query(SourcingRequestGraphql.queries.sourcingNeedByIds, {
            ids: ids
        }).pipe(map(data => data.data && data.data.sourcingNeedByIds ? data.data.sourcingNeedByIds.map(sn => new SourcingNeed(sn)) : []));
    }

    getMyBuyerNeedsSourcingRequestsRecommendations() {
        return this.query(SourcingRequestGraphql.queries.myBuyerNeedsSourcingRequestsRecommendations, {})
            .pipe(map(data => data.data && data.data.myBuyerNeedsSourcingRequestsRecommendations && data.data.myBuyerNeedsSourcingRequestsRecommendations.items ? data.data.myBuyerNeedsSourcingRequestsRecommendations.items.map(snr => new SourcingNeedRecommendation(snr)) : []));
    }

    answerToSourcingNeedRecommendationAsSeller(
                     idSourcingNeedRecommendation: number,
                     status: string,
                     reason: string = null
    ) {
        return this.mutation(SourcingRequestGraphql.mutations.answerToSourcingNeedRecommendationAsSeller, {
            idSourcingNeedRecommendation: idSourcingNeedRecommendation,
            status: status,
            reason: reason
        }).pipe(map(data => data.data && data.data.answerToSourcingNeedRecommendationAsSeller ? new SourcingNeedRecommendationMemberResponse(data.data.answerToSourcingNeedRecommendationAsSeller) : null));
    }

    updateIsNewForBusinessOpportunities(idsSourcingNeedRecommendation: number[], idsSourcingRequest: number[]) {
        return this.mutation(SourcingRequestGraphql.mutations.updateIsNewForBusinessOpportunities, {
            idsSourcingNeedRecommendation, idsSourcingRequest
        }).pipe(map(data => data.data && data.data.updateIsNewForBusinessOpportunities ? data.data.updateIsNewForBusinessOpportunities : false));
    }

    getMyMarketBusinessOpportunities(page: number) {
        return this.query(SourcingRequestGraphql.queries.getMyMarketBusinessOpportunities, {
            page: page
        }, 'network-only').pipe(map(data => data.data && data.data.myMarketBusinessOpportunities ? JSON.parse(data.data.myMarketBusinessOpportunities) : null));
    }

    sourcingRequestByIdForProductRequestRegistration(productRequestId: number) {
        return this.query(SourcingRequestGraphql.queries.sourcingRequestByIdForProductRequestRegistration, {
            productRequestId,
        })
            .pipe(
                filter(data => data.data !== undefined),
                map(data => data.data.sourcingRequestByIdForProductRequestRegistration && data.data.sourcingRequestByIdForProductRequestRegistration ? this.toObject(data.data.sourcingRequestByIdForProductRequestRegistration) : null)
            );
    }

    getTotalCountOfActiveSourcingNeedAndSourcingRequests() {
        return this.query(SourcingRequestGraphql.queries.totalNumberOfActiveSourcingNeedAndSourcingRequest)
            .pipe(
                filter(data => data.data !== undefined),
                map(data => data.data.totalNumberOfActiveSourcingNeedAndSourcingRequest ? data.data.totalNumberOfActiveSourcingNeedAndSourcingRequest : null)
            );
    }
}
