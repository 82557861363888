import {Component, Input, OnInit} from '@angular/core';
import {VideoMeeting} from "../../../services/wabel-client/entities/video_meeting";
import {Company} from "../../../services/wabel-client/entities/company";
import {UtilsService} from "../../../services/utils.service";

@Component({
    selector: 'app-video-meeting-participants-list',
    templateUrl: './video-meeting-participants-list.component.html',
    styleUrls: ['./video-meeting-participants-list.component.scss']
})
export class VideoMeetingParticipantsListComponent implements OnInit {

    @Input() videoMeeting: VideoMeeting;
    companies: Company[] = [];

    constructor(public utilsService: UtilsService) {
    }

    ngOnInit() {
        for (let participant of this.videoMeeting.videoMeetingParticipants) {
            let companyIndex = this.companies.findIndex(c => c.idcompany === participant.member.company.idcompany);
            if (companyIndex > -1) {
                this.companies[companyIndex].members.push(participant.member);
            } else {
                participant.member.company.members = [participant.member];
                this.companies.push(participant.member.company);
            }
        }
    }
}
