<div class="ndl-form-placeholder">
    <ng-container *ngIf="formHeader">
        <div class="container mb-4">
            <div class="row">
                <ndl-placeholder class="col" width="150px" height="30px"></ndl-placeholder>
            </div>
        </div>
    </ng-container>

    <div class="ndl-form">
        <div class="container">
            <div *ngFor="let row of rowArray; let i = index" class="row mb-2">
                <div class="col-lg-3 col-12">
                    <ndl-placeholder [width]="getLabelWidth(i)"></ndl-placeholder>
                </div>
                <div class="col-lg-9 col-12">
                    <ndl-placeholder height="50px"></ndl-placeholder>
                </div>
            </div>
        </div>
    </div>
</div>
