import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NdlTabsComponent} from './tabs.component';
import {NdlTabDirective} from './tab.directive';
import {MatTabsModule} from "@angular/material/tabs";
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {NdlLazyLoadedTabDirective} from "./lazy-loaded-tab.directive";
import {NdlCustomTabHeader} from "./custom-tab-header.directive";
import {NdlCustomTabContent} from "./custom-tab-content.directive";
import {NdlCustomTab} from "./custom-tab.directive";


@NgModule({
    declarations: [
        NdlTabsComponent,
        NdlTabDirective,
        NdlLazyLoadedTabDirective,
        NdlCustomTabHeader,
        NdlCustomTabContent,
        NdlCustomTab
    ],
    imports: [
        CommonModule,
        MatTabsModule,
        MatIconModule,
        MatButtonModule
    ],
    exports: [
        NdlTabsComponent,
        NdlTabDirective,
        NdlLazyLoadedTabDirective,
        NdlCustomTabHeader,
        NdlCustomTabContent,
        NdlCustomTab
    ]
})
export class NeedlTabsModule {
}
