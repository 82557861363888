import {WabelEntity} from "./wabel-entity.abstract";

export class MeetingTimeRange extends WabelEntity {
    constructor(obj: any) {
        super(obj);
    }

    startDate: string;
    endDate: string;

    get id(): number {
        return 0;
    }

    set id(id: number) {
    }
}
