import {Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'kit-line-hidding',
    templateUrl: './kit-line-hidding.component.html',
    styleUrls: ['./kit-line-hidding.component.scss']
})
export class KitLineHiddingComponent implements OnInit {


    @HostBinding('style.width') @Input() width: string;
    @Input() withLock: boolean = true;

    @Output() clickUnlock: EventEmitter<string> = new EventEmitter<string>();
    @Output() clickSignInPopup: EventEmitter<string> = new EventEmitter<string>();

    constructor() {
    }

    ngOnInit() {
    }

    @HostListener('click', ['$event'])
    onClick() {
        if (this.withLock) {
            this.clickUnlock.emit();
        } else {
            this.clickSignInPopup.emit();
        }
    }


}
