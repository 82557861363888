<mat-label *ngIf="label" [class.text-danger]="showError">{{label}}</mat-label>
<span *ngIf="required" class="mat-placeholder-required mat-form-field-required-marker"> *</span>
<div class="select">
    <div class="select__input" (click)="isListOpened = !isListOpened" [class.opened]="isListOpened">
        <div class="select__input__tag" *ngFor="let option of selectOptionsToDisplay">
            <ndl-tag removable (removed)="removeOption(option); isListOpened = !isListOpened">{{option.label}}</ndl-tag>
        </div>
        <ng-container>
            <span class="select__input__empty" *ngIf="!isListOpened || !selectedOptions.length">
                <span class="material-icons-outlined">add_circle_outline</span>
                <span class="select__input__empty__placeholder">{{placeholder}}</span>
            </span>
        </ng-container>
        <span *ngIf="selectedOptions.length" (click)="clearList(); isListOpened = false" class="material-icons select__input__clear">clear</span>
    </div>
    <div *ngIf="isListOpened" class="select__list">
        <div class="select__list__options">
            <ndl-checkboxes [options] = "options"
                            [selectedOptions] = "selectedOptions"
                            [searchable] = "searchable"
                            [searchPlaceholder] = "searchPlaceholder"
                            [columnNumber]="1"
                            (listChanged)="selectedOptions = $event.slice(0); listChanged.emit(selectedOptions)"
                            [canSelectOnlyLastLevel]="canSelectOnlyLastLevel"
                            [maximumSelectedOption]="maximumSelectedOption"
            >
            </ndl-checkboxes>
        </div>
        <button ndl-button (click)="isListOpened = false;">{{buttonLabel}}</button>
    </div>
    <p *ngIf="showError" class="text-danger show-error">{{ errorText }}</p>
</div>
<mat-hint *ngIf="hint && !showError">{{hint}}</mat-hint>
