import {WabelEntity} from "./wabel-entity.abstract";
import {Member} from "./member";
import {Registration} from "./registration";
import {Meeting} from "./meeting";
import {EventExpert} from "./event_expert";
import {EventWorkshop} from "./event_workshop";
import * as dayjs from 'dayjs';
import {Summit} from "./summit";
import {EventFile} from "./event_file";
import {Video} from "./video";
import {EventOrganizer} from "./event_organizer";
import {Tag} from "./tag";
import {EventRegistration} from "./event_registration";
import {MeetingTimeRange} from "./meeting_time_range";

export class Event extends WabelEntity {
    constructor(obj: any) {
        super(obj);
        if (obj) {
            if (obj.ispackaging) this.ispackaging = obj.ispackaging;
            this.rootCategories = obj.rootCategories && obj.rootCategories.items ? obj.rootCategories.items.map(category => new Tag(category)) : [];
            this.categories = obj.categories && obj.categories.items ? obj.categories.items.map(category => new Tag(category)) : [];
            if (obj.summits && obj.summits.items) this.summits = obj.summits.items.map(summit => new Summit(summit));
            if (obj.eventFiles && obj.eventFiles.length) this.eventFiles = obj.eventFiles.map(eF => new EventFile(eF));
            if (obj.workshops) {
                this.workshops = obj.workshops.map(w => new EventWorkshop(w));
            }
            if (obj.videos && obj.videos.items) {
                this.videos = obj.videos.items.map(video => new Video(video));
            }
            if (obj.eventOrganizer) this.eventOrganizer = new EventOrganizer(obj.eventOrganizer);
            if (obj.meetingTimeRanges && obj.meetingTimeRanges.items) this.meetingTimeRanges = obj.meetingTimeRanges.items.map(mtr => new MeetingTimeRange(mtr));
            if (obj.eventType && obj.eventType.name) this.eventType = obj.eventType.name;
            this.registrationOfCurrentMember = obj.registrationOfCurrentMember ? new EventRegistration(obj.registrationOfCurrentMember) : null;
        }
    }

    ispackaging:boolean;
    idcertainevent: number;
    name: string;
    startDate: string;
    endDate: string;
    dates: string;
    eventCode: string;
    catalogReady: boolean;
    selectionReady: boolean;
    agendaReady: boolean;
    meetingConfirmationsOpened: boolean;
    meetingReschedulerOpened: boolean;
    removeMeetingAllowed: boolean;
    adminNotificationCc: string;
    participants: Registration[];
    participantNumber: number;
    meetings: Meeting[];
    meetingNumber: number;
    categories: Tag[];
    rootCategories: Tag[];
    city: string;
    planningImageUrl: string;
    floorMapImageUrl: string;
    experts: EventExpert[];
    workshops: EventWorkshop[];
    bannerUrl: string;
    summits: Summit[] = [];
    location: string;
    badgeTemplateUrl: string;
    eventFiles: EventFile[] = [];
    videos: Video[] = [];
    eventOrganizer: EventOrganizer;
    isOnline: boolean;
    jitsiFallback: boolean;
    allowSampleRequests: boolean;
    helpdeskActivated: boolean;
    meetingExtraTime: number;
    ratingReady: boolean;
    ratingReadyForEssentialAndPremiumOnly: boolean;
    companySubmissionOpen: boolean;
    isFinished: boolean;

    isStarted: boolean;
    backgroundImageUrl: string;
    squareImageUrl: string;
    nameOrSummitName: string;
    anonymousCatalogReady: boolean;
    meetingTimeRanges: MeetingTimeRange[];
    meetingDuration: number;
    eventType: string;
    forceOpenMeetingInNewTab: boolean;
    remainingTimeInSecondsBeforeStart: number;
    remainingTimeInSecondsBeforeEnd: number;
    registrationsOpen: boolean;
    registrationOfCurrentMember: EventRegistration;
    isSourcingDay: boolean;
    timezone: string;

    get id(): string {
        return this.eventCode;
    }

    set id(id: string) {
        this.eventCode = id;
    }

    get formattedStartDate(): string {
        return new Date(this.startDate).toLocaleDateString();
    }

    get formattedendDate(): string {
        return new Date(this.endDate).toLocaleDateString();
    }

    get formattedStartDateInDate(): Date {
        return new Date(this.startDate);
    }
    get formattedEndDateInDate(): Date {
        return new Date(this.endDate);
    }

    get isOnGoing(): boolean {
        const d = new Date();
        return this.formattedStartDateInDate <= d && this.formattedEndDateInDate > d;
    }

    daysLitteral(separator: string = '&'): string {
        let day = dayjs(this.startDate).set('hour', 0).set('minute', 0).set('second', 0);
        let eDate = dayjs(this.endDate).set('hour', 0).set('minute', 0).set('second', 0);
        let days = [];
        while (day.isBefore(eDate)) {
            days.push(day.format('D'));
            day = day.add(1, 'day');
        }
        return days.join(', ') + (days.length ? ' ' + separator + ' ' : '') + eDate.format('D');
    }
}
