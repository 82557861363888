import {WabelEntity} from "./wabel-entity.abstract";
import {Member} from "./member";
import {PrivateMessage} from "./private_message";
import {PrivateMessageThread} from "./private_message_thread";

export class PrivateMessageRead extends WabelEntity {
    constructor(obj: any) {
        super(obj);

        if (obj) {
            this.thread = obj.thread ? new PrivateMessageThread(obj.thread) : null;
            this.member = obj.member ? new Member(obj.member) : null;
            this.privateMessage = obj.privateMessage ? new PrivateMessage(obj.privateMessage) : null;
        }
    }

    thread: PrivateMessageThread;
    member: Member;
    privateMessage: PrivateMessage;
    readAt: string;

    get id(): number {
        return this.thread.idthread;
    }

    set id(id: number) {
        this.thread.idthread = id;
    }
}
