import {Component, ContentChild, EventEmitter, Input, Output} from '@angular/core';
import {Company} from "../../../services/wabel-client/entities/company";
import {NdlLayoutService} from 'needl-lib';
import {CompanyCardStatusOutsideDirective} from "./company-card-status-outside.directive";
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {NdlStatus} from 'needl-lib/core/types';

@Component({
    selector: 'app-company-card',
    templateUrl: './company-card.component.html',
    styleUrls: ['./company-card.component.scss'],
    host: {
        class: 'company-card d-flex'
    }
})
export class CompanyCardComponent {
    @Input() company: Company;
    @Input() type: string;
    @Input() blurredInfo = false;
    @Input() flagTheme: string = null;
    @Input() border: NdlStatus = null;
    @Input() redirect = true;
    @Input() get noShadow() {
        return this._noShadow;
    }
    set noShadow(shadow) {
        this._noShadow = coerceBooleanProperty(shadow);
    }
    private _noShadow = false;

    @Input() get noOverflowHidden() {
        return this._noOverflowHidden;
    }
    set noOverflowHidden(noOverflowHidden) {
        this._noOverflowHidden = coerceBooleanProperty(noOverflowHidden);
    }
    private _noOverflowHidden = false;

    @Input() get showOptions() {
        return this._showOptions;
    }
    set showOptions(showOptions) {
        this._showOptions = coerceBooleanProperty(showOptions);
    }
    private _showOptions = false;

    @Output() fullProfileClicked: EventEmitter<any> = new EventEmitter();

    @ContentChild(CompanyCardStatusOutsideDirective) statusOutside: CompanyCardStatusOutsideDirective;

    constructor(public layoutService: NdlLayoutService) {
    }
}
