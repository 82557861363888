import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from "@angular/forms";
import {NdlCheckboxComponent} from "./components/checkbox/checkbox.component";
import {NdlCheckboxesComponent} from "./components/checkboxes/checkboxes.component";

@NgModule({
  declarations: [
      NdlCheckboxComponent,
      NdlCheckboxesComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
      NdlCheckboxComponent,
      NdlCheckboxesComponent
  ],
})
export class NeedlCheckboxesModule { }
