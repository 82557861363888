import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Product} from "../../../../services/wabel-client/entities/product";
import {Router} from "@angular/router";
import {ProductService} from "../../../../services/wabel-client/services/product.service";
import {ImageService} from "../../../../services/image.service";
import {NdlSnackbarService} from 'needl-lib';

@Component({
  selector: 'profile-product-card',
  templateUrl: './profile-product-card.component.html',
  styleUrls: ['./profile-product-card.component.scss']
})
export class ProfileProductCardComponent implements OnInit {

  @Input() askASample: boolean = false;
  @Input() product: Product;
  @Input() reorderMode: boolean;
  @Input() actionsDisabled = false;
  @Output() moveToTop: EventEmitter<number> = new EventEmitter<number>();
  @Output() productDelete: EventEmitter<number> = new EventEmitter<number>();

  actionLoading: boolean = false;
  deletedProduct: boolean = false;
  modalVisibilityOpen: boolean = false;
  modalDeletionOpen: boolean = false;
  modalDuplicationOpen: boolean = false;

  constructor(private router: Router, private productService: ProductService, private snackbar: NdlSnackbarService, public imageService: ImageService) {

  }

  ngOnInit() {
  }

  actionClicked(value: string) {
    if(!this.actionLoading) {
      switch (value) {
        case 'edit':
          this.router.navigate(['/product/edit', this.product.iduserproduct]);
          break;
        case 'visibility':
          this.modalVisibilityOpen = true;
          break;
        case 'delete':
            this.modalDeletionOpen = true;
          break;
        case 'duplicate':
            this.modalDuplicationOpen = true;
          break;
        default:
          break;
      }
    }
  }

  toggleVisibility() {
      this.modalVisibilityOpen = false;
      this.actionLoading = true;
      return this.productService.toggleVisibility(this.product).subscribe(
          success => {
              this.actionLoading = false;
              if (this.product.published === success.published) {
                  this.snackbar.show('Please fill in all mandatory fields before publishing your products.', 'error');
                  this.router.navigate(['/product/edit', this.product.iduserproduct]);
              } else {
                  this.product.published = success.published;
              }
          }
      );
  }

  deleteProduct() {
      this.modalDeletionOpen = false;
      this.actionLoading = true;
      return this.productService.deleteProduct(this.product).subscribe(
          success => {
              this.actionLoading = false;
              this.deletedProduct = true;
              this.productDelete.emit(this.product.id);
          }
      );
  }

  duplicateProduct() {
      this.modalDuplicationOpen = false;
      this.actionLoading = true;
      return this.productService.duplicateProduct(this.product).subscribe(
          (product) => {
              this.actionLoading = false;
              this.router.navigate(['/product/edit/', product.iduserproduct]);
          }
      );
  }
}
