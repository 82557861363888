import gql from "graphql-tag";

export class CompanyProfileSubmissionGraphQL {
    static fragments = {};

    static queries = {
        getCompanyProposalById: gql`
            query getCompanyProposalById( $idcompanyprofilesubmission: Int! ) {
                    companyProposalById( idcompanyprofilesubmission: $idcompanyprofilesubmission ) {
                        profileTitle
                        profileDescription
                        isExportingToBuyerCountry
                        isFeaturedProducts
                        featuredRootCategories
                        createdByCompany {
                            idcompany
                            name
                            formattedAddress
                            website
                            numemployees {
                                id
                                name
                            }
                            description
                            urlname
                            logoUrl(size: "123")
                        }
                        createdForCompany {
                            idcompany
                            name
                            urlname
                            logoUrl(size: "123")
                            country {
                                name
                            }
                        }
                        companyProfileDiagnosisClients{
                            items {
                                company{
                                    idcompany
                                    name
                                    urlname
                                    logoUrl(size: "123")
                                    country {
                                        id
                                        name
                                        tagLocation {
                                            code
                                        }
                                    }
                                }
                            }
                        }
                        companyProfileExportingCountries {
                            items {
                                country {
                                    id
                                    name
                                    tagLocation {
                                        code
                                    }
                                }
                            }
                        }
                        companyProfileFeaturedProducts {
                            items {
                                userproduct {
                                name
                                logoUrl(size: "123")
                                category {
                                    name
                                    parent {
                                        parent {
                                            name
                                        }
                                    }
                                }
                                urlPath
                                }
                            }
                        }
                        files{
                          idfile
                          url
                          filePath
                          fileName
                          fileExtension
                          fileSize
                        }
                    }
                }
        `,
        getSubmissionsByFeedbackEntry: gql`
            query getSubmissionsByFeedbackEntry {
                submissionsByFeedbackEntry {
                    idcompanyprofilesubmission
                    createdAt
                    createdByCompany {
                        idcompany
                        logoUrl( size: "123")
                        name
                        country {
                            name
                        }
                    }
                    companyProfileFeedbacks{
                        items {
                            member {
                                idmember
                            }
                            status
                        }
                    }
                    message{
                        idprivatemessage
                        thread {
                          idthread
                          privateMessageByInProgressVideoMeeting{
                              idprivatemessage
                          }
                        }
                    }
                }
            }
        `
    };

    static mutations = {
        submitCompanyProfile: gql`
            mutation submitCompanyProfile(
                $reasonTemplate: String,
                $reason: String,
                $targetCompanyId: Int!,
                $isExportingToBuyerCountry: String,
                $isFeaturedProducts: String!,
                $status: String!,
                $step: String!,
                $idcompanyprofilesubmission: Int,
                $providedCategoryIds: [Int!],
                $providedCategories: [String!],
                $featuredSelectedProducts: [Int!],
                $featuredDiagnosisClients: [Int!],
                $exportingToCountries: [String!],
                $companySubmissionFiles:[FileToUploadInput!],
                $oldFiles:[Int!]!,
                $eventCode:String
                ) {
                submitCompanyProfile(
                    reasonTemplate: $reasonTemplate,
                    reason: $reason,
                    targetCompanyId: $targetCompanyId,
                    isExportingToBuyerCountry: $isExportingToBuyerCountry,
                    isFeaturedProducts: $isFeaturedProducts,
                    providedCategoryIds: $providedCategoryIds,
                    status: $status,
                    providedCategories: $providedCategories,
                    featuredSelectedProducts: $featuredSelectedProducts,
                    featuredDiagnosisClients: $featuredDiagnosisClients,
                    step: $step,
                    exportingToCountries: $exportingToCountries,
                    idcompanyprofilesubmission: $idcompanyprofilesubmission,
                    companySubmissionFiles:$companySubmissionFiles,
                    oldFiles:$oldFiles,
                    eventCode:$eventCode
                    ) {
                    idcompanyprofilesubmission
                    profileTitle
                    profileDescription
                    isExportingToBuyerCountry
                    isFeaturedProducts
                    featuredRootCategories
                    createdByCompany {
                      idcompany
                      name
                      formattedAddress
                      website
                      numemployees {
                          id
                          name
                      }
                      description
                      urlname
                      logoUrl(size: "123")
                    }
                    createdForCompany {
                      idcompany
                      name
                      urlname
                      logoUrl(size: "123")
                      country {
                        name
                      }
                      lastCompanyProfileSubmissionToCompany {
                        buyerStatus
                        createdAt
                        message {
                            thread {
                                idthread
                            }
                        }
                      }
                    }
                    companyProfileDiagnosisClients {
                      items {
                        company {
                          idcompany
                          name
                          urlname
                          logoUrl(size: "123")
                          country {
                            id
                            name
                              tagLocation {
                                  code
                              }
                          }
                        }
                      }
                    }
                    companyProfileExportingCountries {
                      items {
                        country {
                          id
                          name
                            tagLocation {
                                code
                            }
                        }
                      }
                    }
                    companyProfileFeaturedProducts {
                      items {
                        userproduct {
                          name
                          logoUrl(size: "123")
                          category {
                            name
                            parent {
                              parent {
                                name
                              }
                            }
                          }
                          urlPath
                        }
                      }
                    }
                    files {
                      idfile
                      url
                      filePath
                      fileName
                      fileExtension
                      fileSize
                    }
                }
            }
        `,
        updateCompanyProfileFeedback: gql`
            mutation updateCompanyProfileFeedback($idcompanyprofilesubmission: Int! , $status: String! , $reason: String) {
                updateCompanyProfileFeedback(idcompanyprofilesubmission: $idcompanyprofilesubmission , status: $status , reason: $reason) {
                    idfeedback
                    member {
                        idmember
                    }
                    status
                    createdAt
                    updatedAt
                }
            }
        `,

        deleteCompanyProfileSubmission: gql`
            mutation deleteCompanyProfileSubmission($idcompanyprofilesubmission: Int!) {
                deleteCompanyProfileSubmission(idcompanyprofilesubmission: $idcompanyprofilesubmission)
            }
        `,
    };
}
