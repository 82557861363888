<li>
    <ng-container *ngIf="!disabled; else disabledTemplate">
        <a *ngIf="!external; else externalTemplate" [routerLink]="link" [routerLinkActive]="link ? 'ndl-submenu-item-active' : ''">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </a>
        <ng-template #externalTemplate>
            <a [href]="link">
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </a>
        </ng-template>
    </ng-container>

    <ng-template #disabledTemplate>
        <a class="ndl-submenu-item-disabled">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </a>
    </ng-template>
</li>

<ng-template #content>
    <ng-content></ng-content>
</ng-template>
