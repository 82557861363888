import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NdlDividerComponent} from './divider.component';
import {MatDividerModule} from '@angular/material/divider';

@NgModule({
    declarations: [
        NdlDividerComponent
    ],
    imports: [
        CommonModule,
        MatDividerModule
    ],
    exports: [
        NdlDividerComponent
    ]
})
export class NeedlDividerModule {
}
