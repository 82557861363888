import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NdlPhoneComponent} from './phone.component';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {NdlPhoneFieldComponent} from './phone-field.component';
import {MatDividerModule} from '@angular/material/divider';
import {NeedlFormFieldModule} from '../form-field/form-field.module';
import {NeedlFormModule} from '../forms.module';


@NgModule({
    declarations: [
        NdlPhoneComponent,
        NdlPhoneFieldComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MatButtonModule,
        MatIconModule,
        MatDividerModule,
        MatInputModule,
        MatSelectModule,
        NeedlFormFieldModule,
        NeedlFormModule
    ],
    exports: [
        NdlPhoneComponent
    ]
})
export class NeedlPhoneModule {
}
