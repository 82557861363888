<div class="mat-step-header-ripple mat-focus-indicator" matRipple
     [matRippleTrigger]="_getHostElement()"
     [matRippleDisabled]="disableRipple"></div>

<div class="mat-step-icon-state-{{state == 'edit' && index > stepper.selectedIndex ? 'number' : state}} ndl-step-icon mat-step-icon" [class.mat-step-icon-selected]="selected">
    <div [class.ndl-step-icon-wrapper-selected]="selected"></div>
    <div class="mat-step-icon-content">
        <span *ngIf="completed"><mat-icon>done</mat-icon></span>
    </div>
</div>
<div class="ndl-step-label mat-step-label"
     [class.mat-step-label-active]="active"
     [class.mat-step-label-selected]="selected"
     [class.mat-step-label-error]="state == 'error'">
    <!-- If there is no label template, fall back to the text label. -->
    <div class="mat-step-text-label ndl-step-text-label"><ng-container *ngIf="displayIndex">{{index+1}}.<br></ng-container>{{label}}</div>

    <div class="mat-step-optional" *ngIf="optional && state != 'error'">{{_intl.optionalLabel}}</div>
    <div class="mat-step-sub-label-error" *ngIf="state == 'error'">{{errorMessage}}</div>
</div>
