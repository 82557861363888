/*
 * Public API Surface of needl-lib
 */

export * from './tabs.module';
export * from './tabs.component';
export * from './tab.directive';
export * from './lazy-loaded-tab.directive';
export * from './custom-tab-header.directive';
export * from './custom-tab-content.directive';
export * from './custom-tab.directive';
