<div class="container-fluid">
    <div class="row" *ngFor="let theme of themes">
        <div class="col">
            <ndl-card [theme]="theme">
                <h1>{{theme | titlecase}} Card With Menu</h1>
                <p>{{content}}</p>

                <!-- For footer, component syntax is possible... --->
                <ndl-card-footer>
                    <button ndl-button>Click me!</button>
                </ndl-card-footer>

                <button ndl-card-option>Option 1</button>
                <button ndl-card-option>Option 2</button>
                <button ndl-card-option>Option 3</button>
            </ndl-card>
        </div>
        <div class="col">
            <ndl-card outlined [theme]="theme">
                <h1>{{theme | titlecase}} Outlined Card</h1>
                <p>{{content}}</p>

                <!-- ...as well as the classic directive syntax --->
                <div ndl-card-footer>
                    <button ndl-button>Click me!</button>
                </div>

                <ndl-card-flag [theme]="theme">
                    {{theme}}
                </ndl-card-flag>
            </ndl-card>
        </div>
    </div>
</div>
