import {
    AfterContentInit,
    AfterViewInit,
    Component,
    ContentChild,
    ContentChildren,
    ElementRef, EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild
} from '@angular/core';
import {NdlInputBaseDirective} from '../input-base.directive';
import {NdlOption, NdlOptionDirective} from '../option.directive';
import {FormControl} from '@angular/forms';
import {combineLatest, Observable, Subject} from 'rxjs';
import {ENTER} from '@angular/cdk/keycodes';
import {MatChipEvent, MatChipInputEvent, MatChipList} from '@angular/material/chips';
import {startWith, map, takeWhile} from 'rxjs/operators';
import {NdlFormFieldComponent} from '../form-field/form-field.component';
import {
    MatAutocompleteOrigin,
    MatAutocompleteSelectedEvent,
    MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Component({
    selector: 'ndl-tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss'],
    host: {
        'class': 'ndl-tags',
        '(blur)': "onTouched()"
    }
})
export class NdlTagsComponent extends NdlInputBaseDirective<string[]> implements AfterContentInit, AfterViewInit, OnDestroy {
    override defaultValue = [];
    override _value = [];

    private _destroyed = false;

    searchControl = new FormControl();
    filteredOptions: Observable<NdlOptionDirective[]>;
    customOptions: NdlOption<string>[];
    chips: Observable<NdlOption<string>[]>;
    autocompleteOrigin: MatAutocompleteOrigin;
    selectedOptions: NdlOptionDirective[] = [];

    @Input() separatorKeysCodes: number[] = [ENTER];

    @Input() get allowCustomValues() {
        return this._allowCustomValues;
    }

    @Output('searchValueChanged') searchValueChanged = new EventEmitter();

    set allowCustomValues(allow) {
        this._allowCustomValues = coerceBooleanProperty(allow);
    }

    private _allowCustomValues = false;

    @ContentChildren(NdlOptionDirective) options: QueryList<NdlOptionDirective<string>>;
    @ViewChild('chipList', {static: true, read: MatChipList}) chipList: MatChipList;
    @ViewChild('formField', {static: true, read: NdlFormFieldComponent}) ndlFormField: NdlFormFieldComponent;
    @ViewChild(MatAutocompleteTrigger, {read: MatAutocompleteTrigger}) inputAutoComplete: MatAutocompleteTrigger;

    ngAfterContentInit() {
        this.chips = this.innerFormControl.valueChanges.pipe(startWith(this.value), map(value => this._getChips(value)));

        this.filteredOptions = combineLatest([this.chips, this.searchControl.valueChanges.pipe(startWith('')), this.options.changes.pipe(startWith(this.options.toArray()))])
            .pipe(map(request => {
                    const selected = request[0];
                    const search = String(request[1]);
                    const options = request[2];
                    return options.filter(o => selected.findIndex(s => o.value === s.value) < 0 && o.label.trim().toLowerCase().includes(search.trim().toLowerCase()));
                }
            ));
    }

    openPanel(evt): void {
        evt.stopPropagation();
        this.inputAutoComplete.openPanel();
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
        this.chipList.chipBlurChanges.pipe(takeWhile(() => !this._destroyed)).subscribe(() => this.onTouched());
        this.autocompleteOrigin = new MatAutocompleteOrigin(this.ndlFormField?.matFormField._connectionContainerRef)
    }

    selectOption(event: MatAutocompleteSelectedEvent) {
        this._addOption(event.option.value);
    }

    createOption(event: MatChipInputEvent) {
        if (this.allowCustomValues && event.value && !this.innerFormControl.value.includes(event.value)) {
            this._addOption(event.value);
        }
    }

    delete($event: MatChipEvent) {
        this.innerFormControl.setValue(this.value.filter(v => v !== $event.chip.value));
    }

    private _addOption(value: string) {
        this.innerFormControl.setValue([
            ...this.value,
            value
        ]);

        this.searchControl.reset('');
    }

    static _getNewOption(value) {
        return {value: value, label: value};
    }

    private _getChips(value: string[]) {
        return value ? value.map(v => this.options.toArray().concat(this.selectedOptions).find(o => o.value == v) ?? NdlTagsComponent._getNewOption(v)) : [];
    }

    ngOnDestroy() {
        this._destroyed = true;
    }
}
