import {Directive} from '@angular/core';

@Directive({
    selector: 'app-company-card-status-outside,[app-company-card-status-outside],[appCompanyCardStatusOutside]',
    host: {
        class: 'company-card-status-outside'
    }
})
export class CompanyCardStatusOutsideDirective {
}
