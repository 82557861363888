import {Component, HostBinding, Input} from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {NdlStatus} from '../../core/types';

@Component({
    selector: 'ndl-menu-item',
    templateUrl: './menu-item.component.html',
    host: {
        "class": "ndl-menu-item"
    }
})
export class NdlMenuItemComponent {
    @Input() link: string;
    private _external = false;
    @Input() get external() {
        return this._external;
    }
    set external(external) {
        this._external = coerceBooleanProperty(external);
    }

    private _disabled = false;
    @Input() @HostBinding('class.ndl-menu-item-disabled') get disabled() {
        return this._disabled;
    }
    set disabled(disabled) {
        this._disabled = coerceBooleanProperty(disabled);
    }

    @Input() banner: NdlStatus;

    private _hasSubmenu = false;
    @Input() @HostBinding('class.ndl-menu-item-with-submenu') get hasSubmenu() {
        return this._hasSubmenu;
    }
    set hasSubmenu(hasSubmenu) {
        this._hasSubmenu = coerceBooleanProperty(hasSubmenu);
    }

    @Input() forceActive = false;
    showSubmenu = false;


    @HostBinding('class.ndl-menu-item-banner') get isBanner() { return !!this.banner; }

    closeSubmenu() {
        this.showSubmenu = false;
    }

    openSubmenu() {
        this.showSubmenu = true;
    }
}
