import {Component, Input, OnInit} from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Component({
    selector: 'ndl-form-placeholder',
    templateUrl: './form-placeholder.component.html',
    styleUrls: ['../../scss/placeholders.scss', './form-placeholder.component.scss']
})
export class NdlFormPlaceholderComponent {
    /** Number of input placeholders displayed */
    @Input() rows = 3;

    private _formHeader: boolean;
    get formHeader(): boolean {
        return this._formHeader;
    }
    /**
     * If true, adds a placeholder as a header for the form
     */
    @Input()
    set formHeader(value: boolean) {
        this._formHeader = coerceBooleanProperty(value);
    }

    get rowArray(): null[] {
        return Array(this.rows);
    }

    getLabelWidth(index: number): string {
        if (index % 3 === 1) {
            return '30px';
        }
        if (index % 3 === 2) {
            return '75px';
        }
        return '50px';
    }
}
