import {Directive, ElementRef, Input, OnChanges, Optional} from "@angular/core";
import {RouterLinkWithHref} from "@angular/router";

@Directive({
    selector: '[appExternalLink]'
})
export class ExternalLinkDirective implements OnChanges {

    @Input() externalLink: string;

    constructor(
        private el: ElementRef,
        @Optional() private link: RouterLinkWithHref
    ) {}

    ngOnChanges() {
        if (!this.link || !this.externalLink) {
            return;
        }
        this.el.nativeElement.href = this.link.href = this.externalLink;

        this.link.onClick = (...args: any[]) => {
            return true;
        };
    }

}
