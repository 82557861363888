import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime'
import * as duration from 'dayjs/plugin/duration'
import { UtilsService } from '../../../services/utils.service';
import { OAuthService } from '../../../services/wabel-client/services/oauth.service';
import { MemberThirdPartyOAuthReffrence } from '../../../services/wabel-client/entities/member_third_party_oauth_reffrence';

@Component({
    selector: 'third-party-calendar-list',
    templateUrl: './third-party-calendar.component.html',
    styleUrls: ['./third-party-calendar.component.scss']
})


export class ThirdPartyCalendarListComponent implements OnInit {

    @Output() thirdPartyCalendarModalClosed: EventEmitter<any> = new EventEmitter();

    public requestMode : string = null
    public waitingForUrl : string = null
    public syncing: boolean = false
    public selectedProvider : string = null
    public updatingPrimaryCalendar : boolean = false
    public revokingToken : boolean = false

    public subCalendarList: Array<{value: string, label: string}> = []
    public alreadyAttachedCalendarsData: MemberThirdPartyOAuthReffrence[] = []

    public selectedSubCalendar: string = null

    public localSubscriptions = null

    constructor(
        public utilsService: UtilsService,
        private oAuthService: OAuthService,
        ) {}

    ngOnInit() {
        this.requestMode = 'existing'
        this.fetchAttachedCalendars()
    }

    lastSyncByProvider( _provider: string ){

        dayjs.extend(duration)
        dayjs.extend(relativeTime)

        let date_string: string = ''

        this.alreadyAttachedCalendarsData.forEach(x => {
            if( x.provider == _provider ){
                date_string = 'Last sync '+ dayjs(x.lastUsedOn).fromNow()
            }
        })

        return date_string
    }

    isCalendarAttached( _provider: string ){
        return this.alreadyAttachedCalendarsData.filter(x => x.provider == _provider).length
    }

    fetchAttachedCalendars( sync?: boolean ){

        this.oAuthService.fetchAttachedCalendars( sync ).subscribe((data: MemberThirdPartyOAuthReffrence[]) => {

            if ( !data ) {return}

            if( data.length == 0 ){
                this.closePopUp()
            }

            this.alreadyAttachedCalendarsData = data

            if( this.selectedProvider ){
                this.attachedSelectedProviderCalendars(this.selectedProvider)
            }

            if( this.revokingToken ){
                this.selectedProvider = null
            }

            this.requestMode = null
            this.syncing = false

            }, () => {
                this.requestMode = null
            }
        );
    }

    syncCalendarNames(){
        this.syncing = true
        this.fetchAttachedCalendars(true)
    }

    closePopUp(){
        if( this.requestMode == null ){
            this.thirdPartyCalendarModalClosed.emit()
        }
    }

    updatePrimaryCalendar(){

        let canRequestUpdate: boolean = false

        if( this.selectedProvider && this.alreadyAttachedCalendarsData.length ){
            this.alreadyAttachedCalendarsData.forEach(x => {
                if( x.provider == this.selectedProvider ){
                    x.memberThirdPartyCalendars.forEach( y => {
                        if( y.calendarId == this.selectedSubCalendar && !y.isSelected ){
                            canRequestUpdate = true
                        }
                    })
                }
            })
        }

        if( canRequestUpdate ){
            // update primary calendar here
            this.updatingPrimaryCalendar = true
            this.oAuthService.updatePrimaryCalendar( this.selectedProvider , this.selectedSubCalendar ).subscribe( x => {
                if( !x ) return

                this.fetchAttachedCalendars()
                this.updatingPrimaryCalendar = false
                
                }, () => {
                    this.updatingPrimaryCalendar = false
                }
            )
        }

    }

    revokeTokenByProvider(){

        if( this.revokingToken || !this.selectedProvider ){
            return
        }

        this.revokingToken = true

        this.oAuthService.revokeThirdPartyOauthFromNeedl( this.selectedProvider ).subscribe( x => {

            if( !x ) return

            this.closePopUp()
            // this.fetchAttachedCalendars()
            this.revokingToken = false
            }, () => {
                this.revokingToken = false
            }
        )

    }

    attachedSelectedProviderCalendars( _provider: string ){
        this.alreadyAttachedCalendarsData.map(x => {
            if( x.provider == _provider ){
                this.selectedProvider = x.provider

                this.subCalendarList = x.memberThirdPartyCalendars.map( y => {
                    
                    if( y.isSelected ){
                        this.selectedSubCalendar = y.calendarId
                    }

                    let label: string = y.calendarName.length > 30 ? y.calendarName.substring(0, 26).trim() + '...' : y.calendarName
                    return { value: y.calendarId , label }
                })
            }
        })
    }

    getConsentUrl( _provider: string ){

        if( this.waitingForUrl || this.requestMode == "existing" ){
            return
        }

        this.attachedSelectedProviderCalendars(_provider)

        if( this.isCalendarAttached( _provider ) ){
            return
        }

        this.waitingForUrl = _provider
        this.selectedProvider = null
        this.oAuthService.getOAuthConsentScreen( _provider ).subscribe((data) => {

                if ( !data ) {return}
                
                window.location = data

            }, () => {
                this.waitingForUrl = null;
            }
        );

    }

}