import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UtilsService} from "../../../../services/utils.service";
import {Company} from "../../../../services/wabel-client/entities/company";
import {ImageService} from "../../../../services/image.service";
import {Tag} from "../../../../services/wabel-client/entities/tag";
import {CompanyClient} from '../../../../services/wabel-client/entities/company_client';

@Component({
    selector: 'submission-client-partner-step',
    templateUrl: './submission-client-partner-step.component.html',
    styleUrls: ['./submission-client-partner-step.component.scss']
})
export class SubmissionClientPartnerStepComponent implements OnInit {
    currentSelectedDiagnosisClientsCountry = null;
    @Input() clients: CompanyClient[] = [];
    clientsCopy: CompanyClient[] = [];
    @Input() exportingToCountries: Tag[] = [];
    showLessExportingCountries = true;
    @Input() featuredDiagnosisClients: Company[] = [];
    @Output() featuredDiagnosisClientsChange = new EventEmitter<Company[]>();

    constructor(public utilsService: UtilsService, public imageService: ImageService
    ) {
    }

    ngOnInit() {
        this.clientsCopy = this.clients;
    }

    renderCountryWithClients(country: string) {
        if (this.currentSelectedDiagnosisClientsCountry === country) {
            this.currentSelectedDiagnosisClientsCountry = null;
            this.clientsCopy = this.clients;
        } else {
            this.currentSelectedDiagnosisClientsCountry = country;
            this.clientsCopy = this.clients.filter(x => x.client?.country && x.client.country.name === country);
        }
    }

    countryClientsCounter(country: string) {
        let total = 0;
        this.clients.forEach(x => {
            if (x.client?.country && x.client.country.name === country) {
                total++;
            }
        });
        return total;

    }

    checkIfClientExist(client: CompanyClient) {
        return this.featuredDiagnosisClients.findIndex(x => x.idcompany === +client.client?.idcompany) !== -1;
    }

    selectFeaturedClients(client: CompanyClient) {
        const index = this.featuredDiagnosisClients.findIndex(x => x.idcompany === +client.client?.idcompany);
        if (index !== -1) {
            this.featuredDiagnosisClients.splice(index, 1);
        } else {
            this.featuredDiagnosisClients.push(new Company(client));
        }
        this.featuredDiagnosisClientsChange.emit(this.featuredDiagnosisClients);
    }

    imgFailToLoad(event) {
        event.target.src = '/assetsFiles/flags/all.png';
    }

    getExportingToCountriesWithClients() {
        return this.exportingToCountries.filter(c => this.countryClientsCounter(c.name) > 0);
    }
}
