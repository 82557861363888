<div class="filter">
    <!-- TODO: Recheck button design after ndl-button migration -->
    <button class="main-button" ndl-button outlined (click)="isListOpened = !isListOpened"
            [class.opened]="isListOpened" [class.selected]="filterNumber">
        {{label}} <span *ngIf="filterNumber" [class.opened]="isListOpened"
                        class="selected-options-number">{{filterNumber}}</span>
        <span class="material-icons" ndl-button-suffix>arrow_drop_down</span>
    </button>
    <div *ngIf="isListOpened" class="filter__list">
        <div class="filter__list__content">
            <ng-content>
            </ng-content>
        </div>
        <div class="validation-buttons">
            <button ndl-button [disabled]="!filterNumber && !forceUnDisabling" (click)="apply()">{{validationButtonLabel}}</button>
            <button ndl-button outlined [disabled]="!filterNumber && !forceUnDisabling" (click)="clear()">{{clearButtonLabel}}</button>
        </div>
    </div>
</div>
