import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'kit-form-textarea',
    templateUrl: './kit-form-textarea.component.html',
    styleUrls: ['./kit-form-textarea.component.scss']
})
export class KitFormTextareaComponent implements OnInit {

    @Input() id: string;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() helper: string;
    @Input() maxlength: number;
    @Input() showError: boolean = false;
    @Input() readonly : boolean = false;
    @Input() pullHeight : boolean = false;

    @Input() binding: string;
    @Output() bindingChange = new EventEmitter();

    change(newValue) {
        this.binding = newValue;
        this.bindingChange.emit(newValue);
    }

    constructor() {
    }

    ngOnInit() {
    }

}
