import {Injectable} from '@angular/core';
import {AlgoliaFilter} from './wabel-client/entities/algolia_filter';
import {AlgoliaFilterOption} from './wabel-client/entities/algolia_filter_option';
import {UtilsService} from './utils.service';
import {FilterHierarchy} from '../modules/search-module/search/search-element/filter-hierarchy/filter-hierarchy';
import {UrlGeneratorService} from './urlgenerator.service';
import {HttpClient} from "@angular/common/http";
import {AuthService} from "./auth.service";
import {environment} from "../../environments/environment";
import {CacheService} from "./cache.service";
import {IFilterService} from "./wabel-client/interfaces/ifilterservice";
import {NeedlSecurityService} from "@wabel/needl-security";
import {RIGHT} from "./wabel-client/security/right.const";
import {SearchService} from "./search.service";

@Injectable({
    providedIn: 'root'
})
export class FilterService implements IFilterService {

    defaultAllFilter: AlgoliaFilter[] = [];
    allFilter: AlgoliaFilter[] = [];
    sellersFilter: AlgoliaFilter[] = [];
    buyersFilters: AlgoliaFilter[] = [];
    selectedFilter: AlgoliaFilter[] = [];
    lastApply: AlgoliaFilter[] = [];
    isBuyerVision = false;
    firstLoad = true;
    displayModal = false;
    urlgeneratorService: UrlGeneratorService;

    constructor(
        public utils: UtilsService,
        private _http: HttpClient,
        private authService: AuthService,
        private cacheService: CacheService,
        private needlSecurityService: NeedlSecurityService,
        private searchService: SearchService
    ) {
    }

    public loadFilters() {
        if (this.firstLoad) {
            this.isBuyerVision = (this.needlSecurityService.hasRight(RIGHT.CAN_SEARCH_SUPPLIERS) && this.needlSecurityService.hasRight(RIGHT.CAN_SEARCH_BUYERS)) || this.needlSecurityService.hasRight(RIGHT.CAN_SEARCH_SUPPLIERS);
            this.firstLoad = !this.firstLoad;
        }
        let type = 'buyers';
        if (this.needlSecurityService.hasRight(RIGHT.CAN_SEARCH_SUPPLIERS)) {
            type = 'sellers';
            if (this.needlSecurityService.hasRight(RIGHT.CAN_SEARCH_PACKAGING)) {
                type = 'packaging';
            }
        } else if (this.needlSecurityService.hasRight(RIGHT.CAN_SEARCH_PACKAGING)) {
            type = 'packaging-buyers';
        }
        this._http.get(environment.needl_back_end_url + '/resource/search_filters?type=' + type, {
            withCredentials: true,
            headers: {
                'ngsw-bypass': 'true'
            }
        }).subscribe((data) => {
            if (typeof data !== "object") {
                console.error('Filters received from Algolia are not a valid object');
                return;
            }
            this.allFilter = (data as any).map(f => new AlgoliaFilter(f));
            if (this.needlSecurityService.hasRight(RIGHT.CAN_SEARCH_SUPPLIERS)) {
                this.sellersFilter = (data as any).map(f => new AlgoliaFilter(f));
            } else if (this.needlSecurityService.hasRight(RIGHT.CAN_SEARCH_BUYERS)) {
                this.buyersFilters = (data as any).map(f => new AlgoliaFilter(f));
            }
            this.defaultAllFilter = (data as any).map(f => new AlgoliaFilter(f));
        });

        if ((this.needlSecurityService.hasRight(RIGHT.CAN_SEARCH_SUPPLIERS) && this.needlSecurityService.hasRight(RIGHT.CAN_SEARCH_BUYERS))) {
            type = 'buyers';
            if (this.needlSecurityService.hasRight(RIGHT.CAN_SEARCH_PACKAGING)) {
                type = 'packaging-buyers';
            }
            this._http.get(environment.needl_back_end_url + '/resource/search_filters?type=' + type, {
                withCredentials: true,
                headers: {
                    'ngsw-bypass': 'true'
                }
            }).subscribe((data) => {
                if (typeof data !== "object") {
                    console.error('Filters received from Algolia are not a valid object');
                    return;
                }
                this.buyersFilters = (data as any).map(f => new AlgoliaFilter(f));
            });
        }
    }

    public getFinalFilter() {
        this.removeNull();
        const newFilter = this.utils.deepCopy(this.selectedFilter);
        newFilter.forEach((i) => {
            i.options.forEach((o) => {
                if (!o.value?.includes('private_label')) {
                    o.value = o.value.split('_')[0];
                } else {
                    o.value = 'private_label';
                }
            });
        });

        if (this.isBuyerVision) {
            newFilter.push(new AlgoliaFilter({
                name: 'Buyer / Supplier view',
                machineName: 'buyerOrSupplierView',
                options: [
                    {name: 'Buyer view', value: 'buyer_view'}
                ]
            }));
        } else {
            newFilter.push(new AlgoliaFilter({
                name: 'Buyer / Supplier view',
                machineName: 'buyerOrSupplierView',
                options: [
                    {name: 'Seller view', value: 'supplier_view'}
                ]
            }));
        }
        return newFilter;
    }

    apply() {
        this.searchService.resetPaginator();
        this.urlgeneratorService.updateSearchState();
        this.lastApply = this.utils.deepCopy(this.selectedFilter);
    }

    clearFilter() {
        this.displayModal = false;
        this.selectedFilter = [];
        this.apply();
    }

    rollback() {
        this.selectedFilter = this.utils.deepCopy(this.lastApply);
    }

    removeOneFilter(filter: AlgoliaFilter) {
        let counter = 0;
        for (const f of this.selectedFilter) {
            if (f.name === filter.name) {
                delete this.selectedFilter[counter];
                break;
            }
            counter++;
        }
        this.removeNull();

    }


    removeNull() {
        this.selectedFilter = this.selectedFilter.filter(function (el) {
            return el != null;
        });
        for (const filter of this.selectedFilter) {
            filter.options = filter.options.filter(function (el) {
                return el != null;
            });
        }
    }

    onChecked(option: AlgoliaFilterOption, filter: AlgoliaFilter) {
        let filterExist: null | AlgoliaFilter = null;

        for (const f of this.selectedFilter) {
            if (f.name === filter.name) {
                filterExist = f;
                break;
            }
        }

        if (filterExist) {
            let optionExist = false;
            let key = 0;
            for (const optionsList of filterExist.options) {
                if ((optionsList.name + '_' + optionsList.depth) === (option.name + '_' + option.depth)) {
                    delete filterExist.options[key];
                    optionExist = true;
                    break;
                }
                key++;
            }
            if (!optionExist) {
                const newOption = new AlgoliaFilterOption(null);
                newOption.name = option.name;
                newOption.value = option.value;
                newOption.depth = option.depth;
                filterExist.options.push(newOption);
            }
            this.removeNull();


        } else {
            const newFilter = new AlgoliaFilter(null);
            newFilter.name = filter.name;
            newFilter.machineName = filter.machineName;
            newFilter.options = [];
            newFilter.options[0] = new AlgoliaFilterOption(null);
            newFilter.options[0].name = option.name;
            newFilter.options[0].value = option.value;
            newFilter.options[0].depth = option.depth;
            this.selectedFilter.push(newFilter);
        }

        let counter = 0;
        for (const filterGlobal of this.selectedFilter) {
            if (filterGlobal.options === null || filterGlobal.options.length === 0) {
                delete this.selectedFilter[counter];
            }
            counter++;
        }

    }

    numberOfFilter(): number {
        this.removeNull();
        let counter = 0;
        for (const filter of this.selectedFilter) {
            counter += filter.options.length;
        }
        return counter;
    }

    // Retourne ne nombre d'option selectionné dans le filter
    getSelected(filter: AlgoliaFilter) {
        const optionSelected = this.getFilterFromName(filter.name);
        if (!optionSelected) {
            return 0;
        }
        return optionSelected.options.length;
    }

    getFilterFromName(name: string) {
        this.removeNull();
        for (const filter of this.selectedFilter) {
            if (filter.name === name) {
                return filter;
            }
        }
    }

    hasFilter(optionName: string, filter: AlgoliaFilter): boolean {
        const selectedFilter = this.getFilterFromName(filter.name);
        if (selectedFilter) {
            let hasOption = false;
            this.removeNull();
            for (const opt of selectedFilter.options) {
                if ((opt.name + '_' + opt.depth) === optionName) {
                    hasOption = true;
                    break;
                }
            }
            return hasOption;
        } else {
            return false;
        }
    }

    convertHierarchyToOption(hierarchy: FilterHierarchy): AlgoliaFilterOption {
        return new AlgoliaFilterOption({
            name: hierarchy.name,
            value: hierarchy.id + '_' + hierarchy.depth,
            count: hierarchy.children != null ? hierarchy.children.length + 1 : 1,
            depth: hierarchy.depth
        });
    }

    hasAtLeastOneChildrenChecked(filterHierarchy: FilterHierarchy, filter: AlgoliaFilter) {
        if (filterHierarchy) {
            for (const children of filterHierarchy.children) {
                if (this.hasFilter(children.name + '_' + children.depth, filter) || this.hasAtLeastOneChildrenChecked(children, filter)) {
                    return true;
                }
            }
        }
        return false;
    }

    getFilterNotFiltered(filtersHierarchy: FilterHierarchy[], filterHierarchy: FilterHierarchy) {
        for (const filter of filtersHierarchy) {
            if ((filter.name + '_' + filter.depth) === (filterHierarchy.name + '_' + filter.depth)) {
                return filter;
            }
            for (const children of filter.children) {
                if ((children.name + '_' + children.depth) === (filterHierarchy.name + '_' + filterHierarchy.name)) {
                    return children;
                }
            }
        }
    }

    addCategoryToSelectedFilter(category: string, depth: number = 1) {
        const categoryFilter = new AlgoliaFilter(null);
        categoryFilter.name = 'Category';
        if (this.isBuyerVision) {
            categoryFilter.machineName = 'category_3_en';
        } else {
            categoryFilter.machineName = 'category_1_en';
        }
        categoryFilter.options = [];

        this.selectAllSolrCategory(category, categoryFilter.options, depth);
        this.selectedFilter.push(categoryFilter);
    }

    selectAllSolrCategory(name: string, options: AlgoliaFilterOption[], depth: number = null) {
        const category = this.cacheService.getCategoryByName(name, depth);
        const option = new AlgoliaFilterOption(null);
        option.name = category.name;
        option.value = category.name;
        option.depth = depth;

        options.push(option);
        if (category.children.length) {
            category.children.forEach(c => {
                this.selectAllSolrCategory(c.name, options, (depth + 1));
            });
        }
    }
}
