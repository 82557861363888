import {
    ChangeDetectorRef,
    Component, ElementRef,
    HostBinding,
    Inject,
    InjectionToken,
    Input,
    Optional,
    SkipSelf
} from '@angular/core';
import {NdlStatus} from '../core/types';
import {CdkAccordionItem} from '@angular/cdk/accordion';
import {NDL_COLLAPSE_ANIMATION} from '../core/animations';
import {NDL_ACCORDION, NdlAccordionDirective} from './accordion.directive';
import {UniqueSelectionDispatcher} from '@angular/cdk/collections';

export const NDL_COLLAPSE = new InjectionToken<CdkAccordionItem>('NDL_COLLAPSE');
import {coerceBooleanProperty} from "@angular/cdk/coercion";

@Component({
    selector: 'ndl-collapse',
    templateUrl: './collapse.component.html',
    styleUrls: ['./collapse.component.scss'],
    host: {
      class: 'ndl-collapse'
    },
    animations: [NDL_COLLAPSE_ANIMATION],
    providers: [
        {provide: NDL_ACCORDION, useValue: undefined},
        {provide: NDL_COLLAPSE, useExisting: NdlCollapseComponent}
    ],
    exportAs: 'ndlCollapse'
})

export class NdlCollapseComponent extends CdkAccordionItem {
    @Input() get theme() {
        return this._theme;
    }
    set theme(theme) {
        this._theme = theme;
    }
    private _theme: NdlStatus = "default";

    @HostBinding('class.ndl-collapse-outlined')
    @Input() get outlined() {
        return this._outlined;
    }

    set outlined(outlined) {
        this._outlined = coerceBooleanProperty(outlined);
    }

    private _outlined: boolean;

    @Input() get expandOnHover() {
        return this._expandOnHover;
    }

    set expandOnHover(expandOnHover) {
        this._expandOnHover = coerceBooleanProperty(expandOnHover);
    }

    private _expandOnHover: boolean;

    @Input() get toggleMode() {
        return this._toggleMode;
    }
    set toggleMode(toggleMode) {
        this._toggleMode = toggleMode;
    }
    private _toggleMode: 'before'|'after'|'hidden' = 'before';

    @HostBinding('class.ndl-collapse-opened')
    private get _isExpanded() {
        return this.expanded;
    }

    constructor(
        @Optional() @SkipSelf() @Inject(NDL_ACCORDION) accordion: NdlAccordionDirective,
        changeDetectorRef: ChangeDetectorRef,
        expansionDispatcher: UniqueSelectionDispatcher,
        public elementRef: ElementRef
    ) {
        super(accordion, changeDetectorRef, expansionDispatcher);
        this.accordion = accordion;
    }
}
