import {Component, Input} from '@angular/core';

@Component({
    selector: 'ndl-spinner',
    templateUrl: './small-spinner.component.html',
    styleUrls: ['./small-spinner.component.scss']
})
export class NdlSmallSpinnerComponent {
    /** Icon used as a spinner */
    @Input() icon: string = 'autorenew';
    @Input() fontSize: string = '24px';
}
