import {Injectable} from "@angular/core";
import {WabelService} from "./wabel-service.abstract";
import {GraphQLService} from "./graphql-service";
import {LoadingService} from "./loading.service";

import {Company} from "../entities/company";
import {CompanyGraphQL} from "../graphql/company.graphql";
import {UtilsService} from "../../utils.service";
import {CompanyTypes} from "../company_types";
import {FileToUpload} from "../entities/fileToUpload";
import {CompanyFile} from "../entities/company_file";
import {Member} from "../entities/member";
import {map} from "rxjs/operators";
import {DummyFile} from "../entities/dummy_file";
import {EcoPackProjectGraphQL} from "../graphql/EcoPackProject.graphql";
import {ResultIterator} from "../entities/result_iterator";
import {EcoPackProjectOption} from "../entities/eco-pack-project-option";
import {CompanyPolicyOption} from "../entities/company_policy_option";
import {CompanyProfileSubmission} from "../entities/company_profile_submission";
import { CompanyType } from "../entities/company_type";
import { CompanyTypeGraphQL } from "../graphql/company_type.graphql";

@Injectable({
    providedIn: 'root'
})
export class CompanyTypeService extends WabelService {

    constructor(protected graphQLService: GraphQLService, protected loadingService: LoadingService, protected utilsService: UtilsService) {
        super(graphQLService, loadingService);
    }
    toArrayOfObjects(objects: any[]): CompanyType[] {
        return objects.map((companytype: any) => new CompanyType(companytype));
    }
    toObject(object: any): CompanyType {
        return new CompanyType(object);
    }
    getCompanyTypesForBusinessMappingPriorities() {
        return this.query(CompanyTypeGraphQL.queries.companyTypesForBusinessMappingPriorities, {}, 'network-only')
            .pipe(map((data) => data.data && data.data.companyTypesForBusinessMappingPriorities  && data.data.companyTypesForBusinessMappingPriorities.items ? this.toArrayOfObjects(data.data.companyTypesForBusinessMappingPriorities.items) : null));
    }
}
