import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from "@angular/forms";
import {NdlMultiSelectListComponent} from "./components/multi-select-list/multi-select-list.component";
import {NeedlButtonsModule} from "../buttons/buttons.module";
import {NdlMultiSelectCallbackComponent} from "./components/multi-select-callback/multi-select-callback.component";
import {NdlSimpleSelectComponent} from "./components/simple-select/simple-select.component";
import {NdlMultiSelectComponent} from "./components/multi-select/multi-select.component";
import {NeedlCheckboxesModule} from "../checkboxes/checkboxes.module";
import {NdlSingleSelectCallbackComponent} from "./components/single-select-callback/single-select-callback.component";
import {NeedlSpinnersModule} from "../spinners/spinners.module";
import {NeedlTagModule} from '../tag/tag.module';
import {MatFormFieldModule} from "@angular/material/form-field";

@NgModule({
  declarations: [
      NdlMultiSelectListComponent,
      NdlMultiSelectCallbackComponent,
      NdlSimpleSelectComponent,
      NdlMultiSelectComponent,
      NdlSingleSelectCallbackComponent
  ],
  imports: [
      CommonModule,
      FormsModule,
      NeedlButtonsModule,
      NeedlCheckboxesModule,
      NeedlSpinnersModule,
      NeedlTagModule,
      MatFormFieldModule
  ],
  exports: [
      NdlMultiSelectListComponent,
      NdlMultiSelectCallbackComponent,
      NdlSimpleSelectComponent,
      NdlMultiSelectComponent,
      NdlSingleSelectCallbackComponent
  ],
})
export class NeedlSelectsModule { }
