import {finalize, share, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {PredefinedMessagesTopics} from "./wabel-client/entities/predefined_messages_topics";
import {Observable, of} from "rxjs";
import {ResultIterator} from "./wabel-client/entities/result_iterator";
import {ResourceService} from "./wabel-client/services/resource.service";
import {PackagingUnit} from "./wabel-client/entities/packaging_unit";
import {PackagingUnitService} from "./wabel-client/services/packaging_unit.service";
import {CompanyRelationshipStatus} from "./wabel-client/entities/company_relationship_status";
import {LocalStorageService} from "ngx-webstorage";
import {TagTypeService} from "./wabel-client/services/tag_type.service";
import {TagType} from "./wabel-client/entities/tag_type";
import {Tag} from "./wabel-client/entities/tag";
import {environment} from "../../environments/environment";
import {AlgoliaFilterOption} from "./wabel-client/entities/algolia_filter_option";

@Injectable({
    providedIn: 'root'
})
export class CacheService {

    private predefinedMessages: ResultIterator<PredefinedMessagesTopics>;
    private _categories: TagType;
    private certifications: TagType;
    private packagingProductEcoFriendlyFeatures: TagType;
    private packagingProductMaterial: TagType;
    private packagingProductCategories: TagType;
    private packagingPurpose: TagType;
    private packagingConsistency: TagType;
    private ecoPackPolicyFeatures: TagType;
    private minimumOrderQuantities: TagType;
    private activities: TagType;
    private priceRanges: TagType;
    private pointOfSales: TagType;
    private exportPositions: TagType;
    private targetConsumer: TagType;
    private rangeOfProduct: TagType;
    private nutritionalInformation: TagType;
    private qualityLabel: TagType;
    private exportCertification: TagType;
    private packagingUnits: ResultIterator<PackagingUnit>;
    private companyDistributionChannel: TagType;
    private companyRelationshipStatuses: ResultIterator<CompanyRelationshipStatus>;
    private parentCategoriesByIdCategories: { [id: number]: Tag } = {};
    private parentCategoriesByCategoryName: { [name: string]: Tag } = {};
    private parentLocationsByLocationName: { [name: string]: Tag } = {};
    public categoryTree: any = {};
    private timezones: string[];
    private _location: TagType;
    private turnover: TagType;
    private currency: TagType;
    private eventPriorities: TagType;
    private nbSellingPoints: TagType;
    private nbEmployees: TagType;
    private sustainability: TagType;
    private healthAndWellness: TagType;
    private consumptionMode: TagType;
    private dietary: TagType;
    private temperatureControlled: TagType;
    private volume: TagType;

    constructor(
        private resourceService: ResourceService,
        private packagingUnitService: PackagingUnitService,
        private localStorageService: LocalStorageService,
        private tagTypeService: TagTypeService
    ) {
        this.parentCategoriesByCategoryName = {};
    }

    set categories(categories: TagType) {
        this._categories = categories;
    }

    get categories() {
        return this._categories;
    }

    set location(location: TagType) {
        this._location = location;
    }

    get location() {
        return this._location;
    }

    private cacheHasExpired(obj: any, minutes: number = 30): boolean {
        const cacheKey = this.localStorageService.retrieve('cacheKey');
        if (cacheKey && cacheKey.current === environment.cacheKey) {
            if (obj && obj.hasOwnProperty('expiry')) {
                return ((Date.now() - obj.expiry) / 60000) >= minutes;
            }
        }
        return true;
    }

    private hasExpired(obj: any, minutes: number = 30): boolean {
        // let cacheKey = this.localStorageService.retrieve('cacheKey');
        // if (cacheKey && cacheKey.current === environment.cacheKey) {
        //     if (obj && obj.hasOwnProperty('expiry')) {
        //         return ((Date.now() - obj.expiry) / 60000) >= minutes;
        //     }
        // }
        return true;
    }

    private storeInCache(key: string, data: any, minutes: number = 30): void {
        this.localStorageService.store(key, {
            data: data,
            expiry: new Date(Date.now() + (minutes * 60000)).getTime()
        });
    }

    load() {
        // this.loadPredefinedMessages();
        // this.loadCategories();
        // this.loadTargetConsumer();
        // this.loadRangeOfProduct();
        // this.loadNutritionalInformation();
        // this.loadQualityLabel();
        // this.loadExportCertification();
        // this.loadCertifications();
        // this.loadPackagingUnits();
        // this.loadCompanyDistributionChannels();
        // this.loadEcoPackPolicyFeatures();
        // this.loadPackagingProductEcoFriendlyFeatures();
        // this.loadPackagingProductCategories();
        // this.loadPackagingProductMaterial();
        // this.loadTimezones();
        // this.loadCompanyTurnover();
        // this.loadEventPriorities();
        // this.loadCompanyCurrency();
    }

    loadPredefinedMessages() {
        const predefinedMessages = this.localStorageService.retrieve('predefinedMessages');
        if (this.hasExpired(predefinedMessages)) {
            this.resourceService.getPredefinedMessagesTopics().subscribe((data) => {
                if (!data) {
                    return;
                }
                this.predefinedMessages = data;
                this.storeInCache('predefinedMessages', data);
            });
        } else {
            this.predefinedMessages = predefinedMessages.data;
        }
    }

    getPredefinedMessages(): Observable<ResultIterator<PredefinedMessagesTopics>> {
        if (!this.predefinedMessages) {
            return this.resourceService.getPredefinedMessagesTopics().pipe(switchMap((data) => {
                this.predefinedMessages = data;
                return of(data);
            }));
        }
        return of(this.predefinedMessages);
    }


    private buildParentCategoriesByIdCategory() {
        this.parentCategoriesByIdCategories = {};
        for (const cat1 of this.categories.tags) {
            this.parentCategoriesByIdCategories[cat1.id] = null;
            for (const cat2 of cat1.children) {
                this.parentCategoriesByIdCategories[cat2.id] = cat1;
                for (const cat3 of cat2.children) {
                    this.parentCategoriesByIdCategories[cat3.id] = cat2;
                }
            }
        }
    }

    getParentCategoryByIdCategory(id: number): Tag {
        return this.parentCategoriesByIdCategories[id];
    }


    private buildParentCategoriesByCategoryNameAndDepth() {
        this.parentCategoriesByCategoryName = {};
        for (const cat1 of this.categories.tags) {
            this.parentCategoriesByCategoryName[cat1.name + '1'] = null;
            for (const cat2 of cat1.children) {
                this.parentCategoriesByCategoryName[cat2.name + '2'] = cat1;
                for (const cat3 of cat2.children) {
                    this.parentCategoriesByCategoryName[cat3.name + '3'] = cat2;
                }
            }
        }
    }

    getParentCategoryByCategoryNameAndDepth(name: string, depth: string): Tag {
        return this.parentCategoriesByCategoryName[name + depth];
    }


    private buildParentLocationsByLocationName() {
        this.parentLocationsByLocationName = {};
        for (const continent of this.location.tags) {
            this.parentLocationsByLocationName[continent.name + '_1'] = null;
            for (const region of continent.children) {
                this.parentLocationsByLocationName[region.name + '_2'] = continent;
                for (const country of region.children) {
                    this.parentLocationsByLocationName[country.name + '_3'] = region;
                }
            }
        }
    }

    getParentLocationByLocationName(name: string, depth = 1): Tag {
        return this.parentLocationsByLocationName[name + '_' + depth];
    }

    getGrandParentLocationByLocationName(name: string, depth = 1): Tag {
        return this.parentLocationsByLocationName[this.parentLocationsByLocationName[name + '_' + depth].name + '_' + (depth - 1)];
    }

    getCategory(id: number): Tag {
        let cat = this.categories.tags.find(category => +category.id === id);
        if (!cat) {
            this.categories.tags.forEach(category => {
                if (!cat) {
                    cat = category.children.find(c2 => +c2.id === id);
                }
            });
        }
        if (!cat) {
            this.categories.tags.forEach(category => {
                category.children.forEach(c2 => {
                    if (!cat) {
                        cat = c2.children.find(c3 => +c3.id === id);
                    }
                });
            });
        }
        return cat;
    }

    getCategoryByName(name: string, depth: number = null): Tag {
        let cat = this.categories.tags.find(category => category.name === name);
        if (cat && (!depth || depth === 1)) {
            return cat;
        }

        cat = null;
        let iteratedCat = null;
        this.categories.tags.forEach(category => {
            iteratedCat = category.children.find(c2 => c2.name === name);
            if (iteratedCat) {
                cat = iteratedCat;
            }
        });


        if (cat && (!depth || depth === 2)) {
            return cat;
        }

        cat = null;
        iteratedCat = null;
        this.categories.tags.forEach(category => {
            category.children.forEach(c2 => {
                iteratedCat = c2.children.find(c3 => c3.name === name);
                if (iteratedCat) {
                    cat = iteratedCat;
                }
            });
        });

        return cat;
    }

    loadCategories() {
        const categories = this.localStorageService.retrieve('categories');
        if (this.hasExpired(categories)) {
            this.tagTypeService.getFullTagTypeByInternalName(TagTypeService.CATEGORY_INTERNAL_NAME).subscribe((data) => {
                if (!data) {
                    return;
                }
                this.categories = data;
                this.storeInCache('categories', data);
                this.buildParentCategoriesByIdCategory();
                this.buildParentCategoriesByCategoryNameAndDepth();
            });
        } else {
            this.categories = categories.data;
            this.buildParentCategoriesByIdCategory();
            this.buildParentCategoriesByCategoryNameAndDepth();
        }
    }

    getCategories(): Observable<TagType> {
        if (!this.categories) {
            return this.tagTypeService.getFullTagTypeByInternalName(TagTypeService.CATEGORY_INTERNAL_NAME);
        }
        return of(this.categories);
    }

    constructCategoriesFilterTreeFromCategories3(nameCategories: string[] = []): AlgoliaFilterOption[] {
        const categories: AlgoliaFilterOption[] = [];
        for (const nameCategory of nameCategories) {
            const cat2 = this.getParentCategoryByCategoryNameAndDepth(nameCategory, '3');
            if (!cat2) {
                return;
            }
            const cat1 = this.getParentCategoryByCategoryNameAndDepth(cat2.name, '2');

            if (!categories.some(cats1 => cats1.value === cat1.name)) {
                const newCat1 = new AlgoliaFilterOption({});
                newCat1.name = cat1.name;
                newCat1.value = cat1.name;
                categories.push(newCat1);
            }

            const cat1Index = categories.findIndex(cats1 => cats1.value === cat1.name);
            if (!categories[cat1Index].options.some(cats2 => cats2.value === cat2.name)) {
                const newCat2 = new AlgoliaFilterOption({});
                newCat2.name = cat2.name;
                newCat2.value = cat2.name;
                categories[cat1Index].options.push(newCat2);
            }

            const cat2Index = categories[cat1Index].options.findIndex(cats2 => cats2.value === cat2.name);
            if (!categories[cat1Index].options[cat2Index].options.some(cats3 => cats3.value === nameCategory)) {
                const newCat3 = new AlgoliaFilterOption({});
                newCat3.name = nameCategory;
                newCat3.value = nameCategory;
                categories[cat1Index].options[cat2Index].options.push(newCat3);
            }

        }
        return categories;
    }

    loadTargetConsumer() {
        const targetConsumer = this.localStorageService.retrieve(TagTypeService.TARGET_CONSUMER_INTERNAL_NAME);
        if (this.hasExpired(targetConsumer)) {
            this.tagTypeService.getTagTypeByInternalName(TagTypeService.TARGET_CONSUMER_INTERNAL_NAME).subscribe((data) => {
                if (!data) {
                    return;
                }
                this.targetConsumer = data;
                this.storeInCache(TagTypeService.TARGET_CONSUMER_INTERNAL_NAME, data);
            });
        } else {
            this.targetConsumer = targetConsumer.data;
        }
    }

    getTargetConsumer(): Observable<TagType> {
        const tags = this.localStorageService.retrieve('tag' + TagTypeService.TARGET_CONSUMER_INTERNAL_NAME);
        if (this.cacheHasExpired(tags)) {
            return this.tagTypeService.getTagTypeByInternalName(TagTypeService.TARGET_CONSUMER_INTERNAL_NAME)
                .pipe(
                    take(1),
                    tap(data => {
                        this.storeInCache('tag' + TagTypeService.TARGET_CONSUMER_INTERNAL_NAME, data);
                    }),
                );
        }
        return of(new TagType(tags.data));
    }

    loadRangeOfProduct() {
        const rangeOfProduct = this.localStorageService.retrieve(TagTypeService.RANGE_OF_PRODUCT_INTERNAL_NAME);
        if (this.hasExpired(rangeOfProduct)) {
            this.tagTypeService.getTagTypeByInternalName(TagTypeService.RANGE_OF_PRODUCT_INTERNAL_NAME).subscribe((data) => {
                if (!data) {
                    return;
                }
                this.rangeOfProduct = data;
                this.storeInCache(TagTypeService.RANGE_OF_PRODUCT_INTERNAL_NAME, data);
            });
        } else {
            this.rangeOfProduct = rangeOfProduct.data;
        }
    }

    getRangeOfProduct(): Observable<TagType> {
        const tags = this.localStorageService.retrieve('tag' + TagTypeService.RANGE_OF_PRODUCT_INTERNAL_NAME);
        if (this.cacheHasExpired(tags)) {
            return this.tagTypeService.getTagTypeByInternalName(TagTypeService.RANGE_OF_PRODUCT_INTERNAL_NAME)
                .pipe(
                    take(1),
                    tap(data => {
                        this.storeInCache('tag' + TagTypeService.RANGE_OF_PRODUCT_INTERNAL_NAME, data);
                    }),
                );
        }
        return of(new TagType(tags.data));
    }

    loadNutritionalInformation() {
        const nutritionalInformation = this.localStorageService.retrieve(TagTypeService.NUTRITIONAL_INFORMATION_INTERNAL_NAME);
        if (this.hasExpired(nutritionalInformation)) {
            this.tagTypeService.getTagTypeByInternalName(TagTypeService.NUTRITIONAL_INFORMATION_INTERNAL_NAME).subscribe((data) => {
                if (!data) {
                    return;
                }
                this.nutritionalInformation = data;
                this.storeInCache(TagTypeService.NUTRITIONAL_INFORMATION_INTERNAL_NAME, data);
            });
        } else {
            this.nutritionalInformation = nutritionalInformation.data;
        }
    }

    getNutritionalInformation(): Observable<TagType> {
        const tags = this.localStorageService.retrieve('tag' + TagTypeService.NUTRITIONAL_INFORMATION_INTERNAL_NAME);
        if (this.cacheHasExpired(tags)) {
            return this.tagTypeService.getTagTypeByInternalName(TagTypeService.NUTRITIONAL_INFORMATION_INTERNAL_NAME)
                .pipe(
                    take(1),
                    tap(data => {
                        this.storeInCache('tag' + TagTypeService.NUTRITIONAL_INFORMATION_INTERNAL_NAME, data);
                    }),
                );
        }
        return of(new TagType(tags.data));
    }

    loadQualityLabel() {
        const qualityLabel = this.localStorageService.retrieve(TagTypeService.QUALITY_LABEL_INTERNAL_NAME);
        if (this.hasExpired(qualityLabel)) {
            this.tagTypeService.getTagTypeByInternalName(TagTypeService.QUALITY_LABEL_INTERNAL_NAME).subscribe((data) => {
                if (!data) {
                    return;
                }
                this.qualityLabel = data;
                this.storeInCache(TagTypeService.NUTRITIONAL_INFORMATION_INTERNAL_NAME, data);
            });
        } else {
            this.qualityLabel = qualityLabel.data;
        }
    }

    getQualityLabel(): Observable<TagType> {
        const tags = this.localStorageService.retrieve('tag' + TagTypeService.QUALITY_LABEL_INTERNAL_NAME);
        if (this.cacheHasExpired(tags)) {
            return this.tagTypeService.getTagTypeByInternalName(TagTypeService.QUALITY_LABEL_INTERNAL_NAME)
                .pipe(
                    take(1),
                    tap(data => {
                        this.storeInCache('tag' + TagTypeService.QUALITY_LABEL_INTERNAL_NAME, data);
                    }),
                );
        }
        return of(new TagType(tags.data));
    }

    loadExportCertification() {
        const exportCertification = this.localStorageService.retrieve(TagTypeService.EXPORT_CERTIFICATION_INTERNAL_NAME);
        if (this.hasExpired(exportCertification)) {
            this.tagTypeService.getTagTypeByInternalName(TagTypeService.EXPORT_CERTIFICATION_INTERNAL_NAME).subscribe((data) => {
                if (!data) {
                    return;
                }
                this.exportCertification = data;
                this.storeInCache(TagTypeService.EXPORT_CERTIFICATION_INTERNAL_NAME, data);
            });
        } else {
            this.exportCertification = exportCertification.data;
        }
    }

    getExportCertification(): Observable<TagType> {
        const tags = this.localStorageService.retrieve('tag' + TagTypeService.EXPORT_CERTIFICATION_INTERNAL_NAME);
        if (this.cacheHasExpired(tags)) {
            return this.tagTypeService.getTagTypeByInternalName(TagTypeService.EXPORT_CERTIFICATION_INTERNAL_NAME)
                .pipe(
                    take(1),
                    tap(data => {
                        this.storeInCache('tag' + TagTypeService.EXPORT_CERTIFICATION_INTERNAL_NAME, data);
                    }),
                );
        }
        return of(new TagType(tags.data));
    }

    loadCertifications() {
        const certifications = this.localStorageService.retrieve('certifications');
        if (this.hasExpired(certifications)) {
            this.tagTypeService.getFullTagTypeByInternalName(TagTypeService.CERTIFICATION_INTERNAL_NAME).subscribe((data) => {
                if (!data) {
                    return;
                }
                this.certifications = data;
                this.storeInCache('certifications', data);
            });
        } else {
            this.certifications = certifications.data;
        }
    }

    loadPackagingProductEcoFriendlyFeatures() {
        const packagingProductEcoFriendlyFeatures = this.localStorageService.retrieve('PackagingProductEcoFriendlyFeatures');
        if (this.hasExpired(packagingProductEcoFriendlyFeatures)) {
            this.tagTypeService.getFullTagTypeByInternalName(TagTypeService.PACKAGING_PRODUCT_ECO_FRIENDLY_FEATURES).subscribe((data) => {
                if (!data) {
                    return;
                }
                this.packagingProductEcoFriendlyFeatures = data;
                this.storeInCache('PackagingProductEcoFriendlyFeatures', data);
            });
        } else {
            this.packagingProductEcoFriendlyFeatures = packagingProductEcoFriendlyFeatures.data;
        }
    }

    loadPackagingProductMaterial() {
        const packagingProductMaterial = this.localStorageService.retrieve('packagingProductMaterial');
        if (this.hasExpired(packagingProductMaterial)) {
            this.tagTypeService.getFullTagTypeByInternalName(TagTypeService.PACKAGING_PRODUCT_MATERIAL).subscribe((data) => {
                if (!data) {
                    return;
                }
                this.packagingProductMaterial = data;
                this.storeInCache('packagingProductMaterial', data);
            });
        } else {
            this.packagingProductMaterial = packagingProductMaterial.data;
        }
    }

    loadPackagingProductCategories() {
        const packagingProductCategories = this.localStorageService.retrieve('packagingProductCategories');
        if (this.hasExpired(packagingProductCategories)) {
            this.tagTypeService.getFullTagTypeByInternalName(TagTypeService.PACKAGING_PRODUCT_CATEGORIES).subscribe((data) => {
                if (!data) {
                    return;
                }
                this.packagingProductCategories = data;
                this.storeInCache('packagingProductCategories', data);
            });
        } else {
            this.packagingProductCategories = packagingProductCategories.data;
        }
    }

    loadEcoPackPolicyFeatures() {
        const ecoPackPolicyFeatures = this.localStorageService.retrieve('ecoPackPolicyFeatures');
        if (this.hasExpired(ecoPackPolicyFeatures)) {
            this.tagTypeService.getFullTagTypeByInternalName(TagTypeService.ECO_PACK_POLICY_FEATURES).subscribe((data) => {
                if (!data) {
                    return;
                }
                this.ecoPackPolicyFeatures = data;
                this.storeInCache('ecoPackPolicyFeatures', data);
            });
        } else {
            this.ecoPackPolicyFeatures = ecoPackPolicyFeatures.data;
        }
    }

    getPackagingProductEcoFriendlyFeatures(): Observable<TagType> {
        if (!this.packagingProductEcoFriendlyFeatures) {
            return this.tagTypeService.getFullTagTypeByInternalName(TagTypeService.PACKAGING_PRODUCT_ECO_FRIENDLY_FEATURES);
        }
        return of(this.packagingProductEcoFriendlyFeatures);
    }

    getCompanyEcoPackPolicyFeatures(): Observable<TagType> {
        if (!this.ecoPackPolicyFeatures) {
            return this.tagTypeService.getFullTagTypeByInternalName(TagTypeService.ECO_PACK_POLICY_FEATURES);
        }
        return of(this.ecoPackPolicyFeatures);
    }

    getCertifications(): Observable<TagType> {
        const tags = this.localStorageService.retrieve('tag' + TagTypeService.CERTIFICATION_INTERNAL_NAME);
        if (this.cacheHasExpired(tags)) {
            return this.tagTypeService.getFullTagTypeByInternalName(TagTypeService.CERTIFICATION_INTERNAL_NAME)
                .pipe(
                    take(1),
                    tap(data => {
                        this.storeInCache('tag' + TagTypeService.CERTIFICATION_INTERNAL_NAME, data);
                    }),
                );
        }
        return of(new TagType(tags.data));
    }

    getPackagingProductMaterial(): Observable<TagType> {
        if (!this.packagingProductMaterial) {
            return this.tagTypeService.getFullTagTypeByInternalName(TagTypeService.PACKAGING_PRODUCT_MATERIAL, true);
        }
        return of(this.packagingProductMaterial);
    }

    getPackagingProductCategories(): Observable<TagType> {
        if (!this.packagingProductCategories) {
            return this.tagTypeService.getFullTagTypeByInternalName(TagTypeService.PACKAGING_PRODUCT_CATEGORIES, true);
        }
        return of(this.packagingProductCategories);
    }

    getPackagingPurpose(): Observable<TagType> {
        if (!this.packagingPurpose) {
            return this.tagTypeService.getFullTagTypeByInternalName(TagTypeService.ECO_PACKAGING_PURPOSE_INTERNAL_NAME, true);
        }
        return of(this.packagingPurpose);
    }

    getPackagingConsistency(): Observable<TagType> {
        if (!this.packagingConsistency) {
            return this.tagTypeService.getFullTagTypeByInternalName(TagTypeService.ECO_PACKAGING_CONSISTENCY_INTERNAL_NAME, true);
        }
        return of(this.packagingConsistency);
    }

    getMinimumOrderQuantities(): Observable<TagType> {
        const tags = this.localStorageService.retrieve('tag' + TagTypeService.MOQ_INTERNAL_NAME);
        if (this.cacheHasExpired(tags)) {
            return this.tagTypeService.getTagTypeByInternalName(TagTypeService.MOQ_INTERNAL_NAME)
                .pipe(
                    take(1),
                    tap(data => {
                        this.storeInCache('tag' + TagTypeService.MOQ_INTERNAL_NAME, data);
                    }),
                );
        }
        return of(new TagType(tags.data));
    }

    getActivities(): Observable<TagType> {
        const tags = this.localStorageService.retrieve('tag' + TagTypeService.ACTIVITY_INTERNAL_NAME);
        if (this.cacheHasExpired(tags)) {
            return this.tagTypeService.getTagTypeByInternalName(TagTypeService.ACTIVITY_INTERNAL_NAME)
                .pipe(
                    take(1),
                    tap(data => {
                        this.storeInCache('tag' + TagTypeService.ACTIVITY_INTERNAL_NAME, data);
                    }),
                );
        }
        return of(new TagType(tags.data));
    }

    getPriceRanges(): Observable<TagType> {
        const tags = this.localStorageService.retrieve('tag' + TagTypeService.PRICE_RANGE_INTERNAL_NAME);
        if (this.cacheHasExpired(tags)) {
            return this.tagTypeService.getTagTypeByInternalName(TagTypeService.PRICE_RANGE_INTERNAL_NAME)
                .pipe(
                    take(1),
                    tap(data => {
                        this.storeInCache('tag' + TagTypeService.PRICE_RANGE_INTERNAL_NAME, data);
                    }),
                );
        }
        return of(new TagType(tags.data));
    }

    getPointOfSales(): Observable<TagType> {
        if (!this.pointOfSales) {
            return this.tagTypeService.getTagTypeByInternalName(TagTypeService.POINT_OF_SALE_INTERNAL_NAME);
        }
        return of(this.pointOfSales);
    }

    getExportPositions(): Observable<TagType> {
        const tags = this.localStorageService.retrieve('tag' + TagTypeService.EXPORT_POSITION_INTERNAL_NAME);
        if (this.cacheHasExpired(tags)) {
            return this.tagTypeService.getTagTypeByInternalName(TagTypeService.EXPORT_POSITION_INTERNAL_NAME)
                .pipe(
                    take(1),
                    tap(data => {
                        this.storeInCache('tag' + TagTypeService.EXPORT_POSITION_INTERNAL_NAME, data);
                    }),
                );
        }
        return of(new TagType(tags.data));
    }

    loadPackagingUnits() {
        const packagingUnits = this.localStorageService.retrieve('packagingUnits');
        if (this.hasExpired(packagingUnits)) {
            this.packagingUnitService.getPackagingUnits().subscribe((data) => {
                if (!data) {
                    return;
                }
                this.packagingUnits = data;
                this.storeInCache('packagingUnits', data);
            });
        } else {
            this.packagingUnits = packagingUnits.data;
        }
    }

    getPackagingUnits(): Observable<ResultIterator<PackagingUnit>> {
        if (!this.packagingUnits) {
            return this.packagingUnitService.getPackagingUnits();
        }
        return of(this.packagingUnits);
    }

    loadCompanyDistributionChannels() {
        const companyDistributionChannel = this.localStorageService.retrieve('companyDistributionChannel');
        if (this.hasExpired(companyDistributionChannel)) {
            this.tagTypeService.getTagTypeByInternalName(TagTypeService.DISTRIBUTION_CHANNEL_INTERNAL_NAME).subscribe((data) => {
                if (!data) {
                    return;
                }
                this.companyDistributionChannel = data;
                this.storeInCache('companyDistributionChannel', data);
            });
        } else {
            this.companyDistributionChannel = companyDistributionChannel.data;
        }
    }

    getCompanyDistributionChannels(): Observable<TagType> {
        const tags = this.localStorageService.retrieve('tag' + TagTypeService.DISTRIBUTION_CHANNEL_INTERNAL_NAME);
        if (this.cacheHasExpired(tags)) {
            return this.tagTypeService.getFullTagTypeByInternalName(TagTypeService.DISTRIBUTION_CHANNEL_INTERNAL_NAME)
                .pipe(
                    take(1),
                    tap(data => {
                        this.storeInCache('tag' + TagTypeService.DISTRIBUTION_CHANNEL_INTERNAL_NAME, data);
                    }),
                );
        }
        return of(new TagType(tags.data));
    }

    loadCompanyRelationshipStatuses() {
        const companyRelationshipStatuses = this.localStorageService.retrieve('companyRelationshipStatuses');
        if (this.hasExpired(companyRelationshipStatuses)) {
            this.resourceService.getCompanyRelationshipStatusesList().subscribe((data) => {
                if (!data) {
                    return;
                }
                this.companyRelationshipStatuses = data;
                this.storeInCache('companyRelationshipStatuses', data);
            });
        } else {
            this.companyRelationshipStatuses = companyRelationshipStatuses.data;
        }
    }

    getCompanyRelationshipStatuses(): Observable<ResultIterator<CompanyRelationshipStatus>> {
        if (!this.companyRelationshipStatuses) {
            return this.resourceService.getCompanyRelationshipStatusesList();
        }
        return of(this.companyRelationshipStatuses);
    }

    loadTimezones() {
        const timezones = this.localStorageService.retrieve('timezones');
        if (this.hasExpired(timezones)) {
            this.resourceService.getTimezones().subscribe((data) => {
                if (!data) {
                    return;
                }
                this.timezones = data;
                this.storeInCache('timezones', data);
            });
        } else {
            this.timezones = timezones.data;
        }
    }

    getTimezones(): Observable<string[]> {
        if (!this.timezones) {
            return this.resourceService.getTimezones();
        }
        return of(this.timezones);
    }

    loadLocation() {
        const location = this.localStorageService.retrieve(TagTypeService.LOCATION_INTERNAL_NAME);
        if (this.hasExpired(location)) {
            this.tagTypeService.getFullTagTypeByInternalName(TagTypeService.LOCATION_INTERNAL_NAME).subscribe((data) => {
                if (!data) {
                    return;
                }
                this.location = data;
                this.storeInCache(TagTypeService.LOCATION_INTERNAL_NAME, data);
                this.buildParentLocationsByLocationName();
            });
        } else {
            this.location = location.data;
            this.buildParentLocationsByLocationName();
        }
    }

    getLocation(): Observable<TagType> {
        if (!this.location) {
            return this.tagTypeService.getFullTagTypeByInternalName(TagTypeService.LOCATION_INTERNAL_NAME, true, "network-only");
        }
        return of(this.location);
    }

    loadCompanyTurnover() {
        const turnover = this.localStorageService.retrieve(TagTypeService.TURNOVER_INTERNAL_NAME);
        if (this.hasExpired(turnover)) {
            this.tagTypeService.getTagTypeByInternalName(TagTypeService.TURNOVER_INTERNAL_NAME).subscribe((data) => {
                if (!data) {
                    return;
                }
                this.turnover = data;
                this.storeInCache(TagTypeService.TURNOVER_INTERNAL_NAME, data);
            });
        } else {
            this.turnover = turnover.data;
        }
    }

    getCompanyTurnover(): Observable<TagType> {
        if (!this.turnover) {
            return this.tagTypeService.getTagTypeByInternalName(TagTypeService.TURNOVER_INTERNAL_NAME);
        }
        return of(this.turnover);
    }

    loadCompanyCurrency() {
        const currency = this.localStorageService.retrieve(TagTypeService.CURRENCY_INTERNAL_NAME);
        if (this.hasExpired(currency)) {
            this.tagTypeService.getTagTypeByInternalName(TagTypeService.CURRENCY_INTERNAL_NAME).subscribe((data) => {
                if (!data) {
                    return;
                }
                this.currency = data;
                this.storeInCache(TagTypeService.CURRENCY_INTERNAL_NAME, data);
            });
        } else {
            this.currency = currency.data;
        }
    }

    getCompanyCurrency(): Observable<TagType> {
        if (!this.currency) {
            return this.tagTypeService.getTagTypeByInternalName(TagTypeService.CURRENCY_INTERNAL_NAME);
        }
        return of(this.currency);
    }

    loadEventPriorities() {
        const eventPriorities = this.localStorageService.retrieve(TagTypeService.EVENT_PRIORITIES_INTERNAL_NAME);
        if (this.hasExpired(eventPriorities)) {
            this.tagTypeService.getTagTypeByInternalName(TagTypeService.EVENT_PRIORITIES_INTERNAL_NAME).subscribe((data) => {
                if (!data) {
                    return;
                }
                this.eventPriorities = data;
                this.storeInCache(TagTypeService.EVENT_PRIORITIES_INTERNAL_NAME, data);
            });
        } else {
            this.eventPriorities = eventPriorities.data;
        }
    }

    getEventPriorities(): Observable<TagType> {
        if (!this.eventPriorities) {
            return this.tagTypeService.getTagTypeByInternalName(TagTypeService.EVENT_PRIORITIES_INTERNAL_NAME);
        }
        return of(this.eventPriorities);
    }

    loadNbSellingPoints() {
        const nbSellingPoints = this.localStorageService.retrieve(TagTypeService.NB_SELLING_POINTS_INTERNAL_NAME);
        if (this.hasExpired(nbSellingPoints)) {
            this.tagTypeService.getTagTypeByInternalName(TagTypeService.NB_SELLING_POINTS_INTERNAL_NAME).subscribe((data) => {
                if (!data) {
                    return;
                }
                this.nbSellingPoints = data;
                this.storeInCache(TagTypeService.NB_SELLING_POINTS_INTERNAL_NAME, data);
            });
        } else {
            this.nbSellingPoints = nbSellingPoints.data;
        }
    }

    getNbSellingPoints(): Observable<TagType> {
        if (!this.nbSellingPoints) {
            return this.tagTypeService.getTagTypeByInternalName(TagTypeService.NB_SELLING_POINTS_INTERNAL_NAME);
        }
        return of(this.nbSellingPoints);
    }

    loadNbEmployees() {
        const nbEmployees = this.localStorageService.retrieve(TagTypeService.NB_EMPLOYEES_INTERNAL_NAME);
        if (this.hasExpired(nbEmployees)) {
            this.tagTypeService.getTagTypeByInternalName(TagTypeService.NB_EMPLOYEES_INTERNAL_NAME).subscribe((data) => {
                if (!data) {
                    return;
                }
                this.nbSellingPoints = data;
                this.storeInCache(TagTypeService.NB_EMPLOYEES_INTERNAL_NAME, data);
            });
        } else {
            this.nbSellingPoints = nbEmployees.data;
        }
    }

    getNbEmployees(): Observable<TagType> {
        if (!this.nbEmployees) {
            return this.tagTypeService.getTagTypeByInternalName(TagTypeService.NB_EMPLOYEES_INTERNAL_NAME);
        }
        return of(this.nbEmployees);
    }

    loadSustainability() {
        const sustainability = this.localStorageService.retrieve(TagTypeService.SUSTAINABILITY_INTERNAL_NAME);
        if (this.hasExpired(sustainability)) {
            this.tagTypeService.getTagTypeByInternalName(TagTypeService.SUSTAINABILITY_INTERNAL_NAME).subscribe((data) => {
                if (!data) {
                    return;
                }
                this.sustainability = data;
                this.storeInCache(TagTypeService.SUSTAINABILITY_INTERNAL_NAME, data);
            });
        } else {
            this.sustainability = sustainability.data;
        }
    }

    getSustainability(): Observable<TagType> {
        const tags = this.localStorageService.retrieve('tag' + TagTypeService.SUSTAINABILITY_INTERNAL_NAME);
        if (this.cacheHasExpired(tags)) {
            return this.tagTypeService.getTagTypeByInternalName(TagTypeService.SUSTAINABILITY_INTERNAL_NAME)
                .pipe(
                    take(1),
                    tap(data => {
                        this.storeInCache('tag' + TagTypeService.SUSTAINABILITY_INTERNAL_NAME, data);
                    }),
                );
        }
        return of(new TagType(tags.data));
    }

    loadHealthAndWellness() {
        const healthAndWellness = this.localStorageService.retrieve(TagTypeService.HEALTH_AND_WELLNESS_INTERNAL_NAME);
        if (this.hasExpired(healthAndWellness)) {
            this.tagTypeService.getTagTypeByInternalName(TagTypeService.HEALTH_AND_WELLNESS_INTERNAL_NAME).subscribe((data) => {
                if (!data) {
                    return;
                }
                this.healthAndWellness = data;
                this.storeInCache(TagTypeService.HEALTH_AND_WELLNESS_INTERNAL_NAME, data);
            });
        } else {
            this.healthAndWellness = healthAndWellness.data;
        }
    }

    getHealthAndWellness(): Observable<TagType> {
        const tags = this.localStorageService.retrieve('tag' + TagTypeService.HEALTH_AND_WELLNESS_INTERNAL_NAME);
        if (this.cacheHasExpired(tags)) {
            return this.tagTypeService.getTagTypeByInternalName(TagTypeService.HEALTH_AND_WELLNESS_INTERNAL_NAME)
                .pipe(
                    take(1),
                    tap(data => {
                        this.storeInCache('tag' + TagTypeService.HEALTH_AND_WELLNESS_INTERNAL_NAME, data);
                    }),
                );
        }
        return of(new TagType(tags.data));
    }

    loadConsumptionMode() {
        const consumptionMode = this.localStorageService.retrieve(TagTypeService.CONSUMPTION_MODE_INTERNAL_NAME);
        if (this.hasExpired(consumptionMode)) {
            this.tagTypeService.getTagTypeByInternalName(TagTypeService.CONSUMPTION_MODE_INTERNAL_NAME).subscribe((data) => {
                if (!data) {
                    return;
                }
                this.consumptionMode = data;
                this.storeInCache(TagTypeService.CONSUMPTION_MODE_INTERNAL_NAME, data);
            });
        } else {
            this.consumptionMode = consumptionMode.data;
        }
    }

    getConsumptionMode(): Observable<TagType> {
        const tags = this.localStorageService.retrieve('tag' + TagTypeService.CONSUMPTION_MODE_INTERNAL_NAME);
        if (this.cacheHasExpired(tags)) {
            return this.tagTypeService.getTagTypeByInternalName(TagTypeService.CONSUMPTION_MODE_INTERNAL_NAME)
                .pipe(
                    take(1),
                    tap(data => {
                        this.storeInCache('tag' + TagTypeService.CONSUMPTION_MODE_INTERNAL_NAME, data);
                    }),
                );
        }
        return of(new TagType(tags.data));
    }

    loadDietary() {
        const dietary = this.localStorageService.retrieve(TagTypeService.DIETARY_INTERNAL_NAME);
        if (this.hasExpired(dietary)) {
            this.tagTypeService.getTagTypeByInternalName(TagTypeService.DIETARY_INTERNAL_NAME).subscribe((data) => {
                if (!data) {
                    return;
                }
                this.dietary = data;
                this.storeInCache(TagTypeService.DIETARY_INTERNAL_NAME, data);
            });
        } else {
            this.dietary = dietary.data;
        }
    }

    getDietary(): Observable<TagType> {
        const tags = this.localStorageService.retrieve('tag' + TagTypeService.DIETARY_INTERNAL_NAME);
        if (this.cacheHasExpired(tags)) {
            return this.tagTypeService.getTagTypeByInternalName(TagTypeService.DIETARY_INTERNAL_NAME)
                .pipe(
                    take(1),
                    tap(data => {
                        this.storeInCache('tag' + TagTypeService.DIETARY_INTERNAL_NAME, data);
                    }),
                );
        }
        return of(new TagType(tags.data));
    }

    loadTemperatureControlled() {
        const temperatureControlled = this.localStorageService.retrieve(TagTypeService.TEMPERATURE_CONTROLLED_INTERNAL_NAME);
        if (this.hasExpired(temperatureControlled)) {
            this.tagTypeService.getTagTypeByInternalName(TagTypeService.TEMPERATURE_CONTROLLED_INTERNAL_NAME).subscribe((data) => {
                if (!data) {
                    return;
                }
                this.temperatureControlled = data;
                this.storeInCache(TagTypeService.TEMPERATURE_CONTROLLED_INTERNAL_NAME, data);
            });
        } else {
            this.temperatureControlled = temperatureControlled.data;
        }
    }

    getTemperatureControlled(): Observable<TagType> {
        const tags = this.localStorageService.retrieve('tag' + TagTypeService.TEMPERATURE_CONTROLLED_INTERNAL_NAME);
        if (this.cacheHasExpired(tags)) {
            return this.tagTypeService.getTagTypeByInternalName(TagTypeService.TEMPERATURE_CONTROLLED_INTERNAL_NAME)
                .pipe(
                    take(1),
                    tap(data => {
                        this.storeInCache('tag' + TagTypeService.TEMPERATURE_CONTROLLED_INTERNAL_NAME, data);
                    }),
                );
        }
        return of(new TagType(tags.data));
    }

    loadVolume() {
        const volume = this.localStorageService.retrieve(TagTypeService.VOLUME_INTERNAL_NAME);
        if (this.hasExpired(volume)) {
            this.tagTypeService.getTagTypeByInternalName(TagTypeService.VOLUME_INTERNAL_NAME).subscribe((data) => {
                if (!data) {
                    return;
                }
                this.volume = data;
                this.storeInCache(TagTypeService.VOLUME_INTERNAL_NAME, data);
            });
        } else {
            this.volume = volume.data;
        }
    }

    getVolume(): Observable<TagType> {
        const tags = this.localStorageService.retrieve('tag' + TagTypeService.VOLUME_INTERNAL_NAME);
        if (this.cacheHasExpired(tags)) {
            return this.tagTypeService.getTagTypeByInternalName(TagTypeService.VOLUME_INTERNAL_NAME)
                .pipe(
                    take(1),
                    tap(data => {
                        this.storeInCache('tag' + TagTypeService.VOLUME_INTERNAL_NAME, data);
                    }),
                );
        }
        return of(new TagType(tags.data));
    }
}
