import gql from "graphql-tag";


export class TagTypeGraphql {
    static fragments = {
        fullTag: gql`
            fragment fullTag on Tag {
                id
                name
                machineName
                type {
                    name
                    internalName
                }
                parent {
                    name
                    id
                    machineName
                    parent {
                        name
                        id
                        machineName
                    }
                }
                children {
                    items {
                        name
                        id
                        machineName
                        parent {
                            name
                            id
                            machineName
                        }
                        children {
                            items {
                                name
                                id
                                machineName
                                tagLocation {
                                    code
                                }
                                parent {
                                    name
                                    id
                                    machineName
                                }
                            }
                        }
                    }
                }
            }
        `,
        tag: gql`
            fragment tag on Tag {
                id
                name
                machineName
                parent {
                  id
                  machineName
                }
            }
        `,
        customTag: gql`
            fragment customTag on CustomTag {
                id
                name
            }
        `,
    };
    static queries = {
        fullTagTypeByInternalName: gql`
            query tagTypeByInternalName($tagTypeInternalName: String!, $onlyLevel0: Boolean) {
                tagTypeByInternalName(tagTypeInternalName: $tagTypeInternalName){
                    id
                    name
                    internalName
                    tags(onlyLevel0: $onlyLevel0) {
                        items {
                            ...fullTag
                        }
                    }
                }
            }
            ${TagTypeGraphql.fragments.fullTag}
        `,
        tagTypeByInternalName: gql`
            query tagTypeByInternalName($tagTypeInternalName: String!) {
                tagTypeByInternalName(tagTypeInternalName: $tagTypeInternalName){
                    id
                    name
                    tags {
                        items {
                            ...tag
                        }
                    }
                }
            }
            ${TagTypeGraphql.fragments.tag}
        `,
        tagTypeByInternalNameWithTagInternalName: gql`
            query tagTypeByInternalName($tagTypeInternalName: String!) {
                tagTypeByInternalName(tagTypeInternalName: $tagTypeInternalName){
                    id
                    name
                    tags {
                        items {
                            id
                            name
                            internalName
                        }
                    }
                }
            }`,
        };
}
