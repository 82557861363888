import {WabelEntity} from "./wabel-entity.abstract";
import {File} from "./file";
import {environment} from "../../../../environments/environment";
import {CustomContent} from "./custom_content";
import {Event} from "./event";

export class EventOrganizer extends WabelEntity {
    constructor(obj: any) {
        super(obj);
        if (obj) {
            if (obj.fileLogo) { this.fileLogo = new File(this.fileLogo); }
            if (obj.dashboardText) { this.dashboardText = new CustomContent(obj.dashboardText); }
            if (obj.dashboardTextSeller) { this.dashboardTextSeller = new CustomContent(obj.dashboardTextSeller); }
            if (obj.dashboardTextBuyer) { this.dashboardTextBuyer = new CustomContent(obj.dashboardTextBuyer); }
            if (obj.fileSmallLogo) { this.fileSmallLogo = new File(this.fileSmallLogo); }
            if (obj.nextEvent) { this.nextEvent = new Event(this.nextEvent); }
        }
    }

    ideventorganizer: number;
    name: string;
    urlname: string;
    fileLogo: File;
    fileSmallLogo: File;
    mainColor: string;
    dashboardText: CustomContent;
    dashboardTextSeller: CustomContent;
    dashboardTextBuyer: CustomContent;
    linkTc: string;
    linkRegistration: string;
    contactMail: string;
    contactPhone: string;
    helpdeskKey: string;
    helpEmail: string;
    tutorialVideoLink: string;
    tipsVideoLink: string;
    nextEvent: Event;
    hasCurrentMemberRegistration: boolean;
    link: string;

    get id(): number {
        return this.ideventorganizer;
    }

    set id(id: number) {
        this.ideventorganizer = id;
    }

    get routerLink(): string {
        return '/event/' + this.urlname + '/';
    }
}
