import {WabelEntity} from "./wabel-entity.abstract";

export class MemberAvailability extends WabelEntity {

    id: string;
    member: string;
    day: string;
    timefrom: string;
    timeTo: string;
    timezone: string;

    constructor(obj : any) {
        super(obj);
    }

}
