import {Component, Input} from '@angular/core';

@Component({
    selector: 'ndl-placeholder',
    templateUrl: './placeholder.component.html',
    styleUrls: ['../../scss/placeholders.scss', './placeholder.component.scss']
})
export class NdlPlaceholderComponent {
    /** Width of the placeholder inside of the component */
    @Input() width: string = '100%';

    /** Height of the placeholder */
    @Input() height: string = '15px';

    /** Alignement of the placeholder inside of the component  */
    @Input() align: string = 'left';

    /** Alignement of the placeholder inside of the component  */
    @Input() radius: string = '3px';
}
