import {Component, HostListener, OnInit} from '@angular/core';
import {LiveVideoMeetingService} from "../../../services/wabel-client/services/live_video_meeting.service";

@Component({
    selector: 'app-video-meeting-iframe',
    templateUrl: './video-meeting-iframe.component.html',
    styleUrls: ['./video-meeting-iframe.component.scss']
})
export class VideoMeetingIframeComponent implements OnInit {
    @HostListener("window:resize", ["$event"])
    onResize(event) {
        if (this.liveVideoMeetingService.meetingJoined) {
            this.liveVideoMeetingService.isFloating ? this.liveVideoMeetingService.placeMeetingIframeAsFloating() : this.liveVideoMeetingService.placeMeetingIframe();
        }
    }

    constructor(public liveVideoMeetingService: LiveVideoMeetingService) {
    }

    ngOnInit() {
    }
}
