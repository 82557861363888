import {BehaviorSubject, Observable} from 'rxjs';
import {Apollo, QueryRef} from 'apollo-angular';
import {Injectable} from '@angular/core';
import {FetchPolicy} from '@apollo/client';

@Injectable({
    providedIn: 'root'
})
export class GraphQLService {

    backendInMaintenance$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(protected apollo: Apollo) {
    }

    /**
     * Execute a Query to the Wabel GraphQL API
     * WARNING : When fetchPolicy has Cache access activated, queries might return an empty response!
     * @param graphQLQuery  The GraphQL Query build with gpl tag
     * @param data          The data used as variables parameter
     * @param fetchPolicy   Cache management
     * @param pollInterval  Repeating the request after a certain number of time
     * @returns {QueryRef<T>}
     */
    query(graphQLQuery: any, data: any = null, fetchPolicy: string = 'cache-and-network', pollInterval?: number): QueryRef<any> {
        return this.apollo.watchQuery({
            query: graphQLQuery,
            variables: data,
            fetchPolicy: fetchPolicy as FetchPolicy,
            pollInterval: pollInterval
        });
    }

    /**
     * Execute a Mutation to the Wabel GraphQL API
     * @param graphQLMutation   The GraphQL Mutation build with gpl tag
     * @param data              The data used as variables parameter
     * @param optimisticResponse The data object to use as an optimistic response
     * @returns {Observable<FetchResult<T>>}
     */
    mutation(graphQLMutation: any, data: any = {}, optimisticResponse: any = null): Observable<any> {
        return this.apollo.mutate({
            mutation: graphQLMutation,
            variables: data,
            optimisticResponse: optimisticResponse
        });
    }

    backendInMaintenance(inMaintenance: boolean): void {
        this.backendInMaintenance$.next(inMaintenance);
    }
}
