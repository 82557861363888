import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject, Input,
    OnDestroy
} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MatDateFormats} from "@angular/material/core";
import {Subject} from "rxjs";
import {MatCalendar} from "@angular/material/datepicker";
import {takeUntil} from "rxjs/operators";
import {DatePickerService} from "../../../service/date-picker.service";

@Component({
    selector: 'ndl-custom-header-date-picker',
    templateUrl: './custom-header-date-picker.component.html',
    styleUrls: ['./custom-header-date-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class NdlCustomHeaderDatePickerComponent<D> implements OnDestroy {
    private _destroyed = new Subject<void>();

    constructor(
        private datePickerService: DatePickerService,
        private _calendar: MatCalendar<D>, private _dateAdapter: DateAdapter<D>,
        @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats, cdr: ChangeDetectorRef) {
        _calendar.stateChanges
            .pipe(takeUntil(this._destroyed))
            .subscribe(() => cdr.markForCheck());
    }

    ngOnDestroy() {
        this._destroyed.next();
        this._destroyed.complete();
    }

    get periodLabel() {
        return this._dateAdapter
            .format(this._calendar.activeDate, this._dateFormats.display.monthYearA11yLabel);
    }

    get title(): string {
        return this.datePickerService.customTitle;
    }

    previousClicked(mode: 'month' | 'year') {
        this._calendar.activeDate = mode === 'month' ?
            this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1) :
            this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
    }

    nextClicked(mode: 'month' | 'year') {
        this._calendar.activeDate = mode === 'month' ?
            this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1) :
            this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
    }
}
