import {
    Component, Input
} from '@angular/core';
import {NdlTextBaseDirective} from '../input-text-base.directive';

@Component({
    selector: 'ndl-text',
    templateUrl: './text.component.html',
    styleUrls: ['./text.component.scss'],
    host: {
        'class': 'ndl-text',
        '(blur)': "onTouched()"
    }
})
export class NdlTextComponent extends NdlTextBaseDirective {
    @Input() type: "color" | "email" | "number" | "search" | "text" | "url" = "text";
}
