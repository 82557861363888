<div class="checkboxes">
    <div class="checkboxes__search" *ngIf="searchable">
        <span class="material-icons-outlined">search</span>
        <input class="checkboxes__search__value" type="text" [(ngModel)]="searchValue" (ngModelChange)="searchChanged($event)" placeholder="{{searchPlaceholder}}"/>
    </div>
    <div class="checkboxes__options" [class.searchable]="searchable">
        <div *ngFor="let option of filteredOptions; let i = index" class="checkboxes__options__option" [class.has-children]="option.children && option.children.length">
            <ndl-checkbox
                [ngStyle]="{'width': 100/columnNumber + '%'}"
                [option]="option"
                [checked]="isChecked(option)"
                (valueChanged)="toggleOption(this.getOption(option))"
                [counter]="getChildrenChecked(option).length"
                [hideCheckbox]="canSelectOnlyLastLevel && option.children && (option.children.length > 0)"
                (labelWithoutCheckboxClicked)="toggleChildrenList(option)"
                [disabled]="maximumSelectedOption && selectedOptions.length >= maximumSelectedOption"
                [canDeselectIfDisabled]="true"
            ></ndl-checkbox>
            <span *ngIf="option.children && option.children.length" (click)="toggleChildrenList(option)" class="material-icons" [class.opened]="isChildrenListOpened(option)">arrow_drop_down</span>
            <div class="checkboxes__options__option__children" *ngIf="isChildrenListOpened(option)">
                <ndl-checkboxes
                    [notFilteredOptions]="getOption(option).children"
                    [options]="option.children"
                    [selectedOptions]="selectedOptions"
                    [columnNumber]="1"
                    (allOptionsChecked)="addOption(option)"
                    (allOptionsNotChecked)="removeOption(option, true)"
                    [flattenOptions]="flattenOptions"
                    (listChanged)="listChanged.emit(this.selectedOptions)"
                    [canSelectOnlyLastLevel]="canSelectOnlyLastLevel"
                    [maximumSelectedOption]="maximumSelectedOption"
                    [openedChildrenList]="openedChildrenList"
                ></ndl-checkboxes>
            </div>
        </div>
    </div>
    <p *ngIf="showError" class="text-danger">{{ errorText }}</p>
</div>
