import {WabelEntity} from "./wabel-entity.abstract";
import {Company} from "./company";
import {Tag} from "./tag";

export class Address extends WabelEntity {
    shortName: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    companyName: string;
    defaultAddress: boolean;
    firstName: string;
    idaddress: number;
    lastName: string;
    state: string;
    zipcode: string;
    phone: string;
    country: Tag;
    email: string;
    idcountry: number;
    company: Company;
    idMeeting: number;


    constructor(obj: any) {
        super(obj);
        if (obj !== null) {
            if (obj.company && obj.company.items) this.company = obj.company.items.map(company => new Company(company));
            this.idaddress = parseInt(obj.idaddress);
            this.country = obj.country ? new Tag(obj.country) : null;
            if (this.country) {
                this.idcountry = +this.country.id;
            }
        }
    }

    get id(): number {
        return 0;
    }

    set id(id: number) {

    }
}
