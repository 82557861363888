import {Directive, InjectionToken} from '@angular/core';
import {CdkAccordion} from '@angular/cdk/accordion';

export const NDL_ACCORDION = new InjectionToken<CdkAccordion>('NDL_ACCORDION');

@Directive({
    selector: 'ndl-accordion, [ndl-accordion]',
    host: {
      class: 'ndl-accordion'
    },
    providers: [
        {provide: NDL_ACCORDION, useExisting: NdlAccordionDirective}
    ]
})

export class NdlAccordionDirective extends CdkAccordion {
}
