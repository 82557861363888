import { Favorites } from '../entities/favorites';
import {Injectable} from "@angular/core";
import {WabelService} from "./wabel-service.abstract";
import {GraphQLService} from "./graphql-service";
import {LoadingService} from "./loading.service";

import {Member} from "../entities/member";
import {MemberGraphQL} from "../graphql/member.graphql";
import {FilterSource} from "../entities/filter_source";
import {CompanyRelationship} from "../entities/company_relationship";
import {Company} from "../entities/company";
import {UtilsService} from "../../utils.service";
import {MemberNotification} from "../entities/member_notification";
import {ChartResultItem} from "../entities/chart_result_item";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class MemberService extends WabelService {

    constructor(protected graphQLService: GraphQLService, protected loadingService: LoadingService, private utilsService: UtilsService) {
        super(graphQLService, loadingService);
    }

    toArrayOfObjects(objects: any[]): Member[] {
        return objects.map((member: any) => new Member(member));
    }

    toArrayOfFavorites(objects: any[]): Favorites[] {
        return objects.map((favorite: any) => new Favorites(favorite));
    }

    toObject(member: any): Member {
        return new Member(member);
    }

    getMeRequest(fetchPolicy: string = 'network-only') {
        return this.query(MemberGraphQL.queries.me, {}, fetchPolicy);
    }

    getClientList() {
        return this.query(MemberGraphQL.queries.clientList, {})
            .pipe(map((data) => data.data && data.data.me ? new Member(data.data.me) : null));
    }

    getMyCompanyProductCategories() {
        return this.query(MemberGraphQL.queries.myCompanyProductCategories, {})
            .pipe(map((data) => data.data && data.data.me ? new Member(data.data.me) : null));
    }

    getMe(fetchPolicy: string = 'network-only') {
        return this.query(MemberGraphQL.queries.me, {}, fetchPolicy)
            .pipe(map((data) => this.processResponse<Member>(data, 'me')));
    }

    getTimezoneByMember( idmember: number ) {
        return this.query(MemberGraphQL.queries.getTimezoneByMember, {idmember})
            .pipe(map((data) => data.data && data.data.member ? new Member(data.data.member) : null));
    }

    checkMemberVideoMeetingsAndThreadByCompany( companyId: number ) {
        return this.query(MemberGraphQL.queries.checkMemberVideoMeetingsAndThreadByCompany, {companyId})
            .pipe(map((data) => data.data && data.data.me ? new Member(data.data.me) : null));
    }

    sendEmailVerificationLink() {
        return this.mutation(MemberGraphQL.mutations.sendEmailVerificationLink)
            .pipe(map((data) => data.data.sendEmailVerificationLink as Member));
    }

    updateUserNewTimezone( timezone: string ) {
        return this.mutation(MemberGraphQL.mutations.updateUserNewTimezone , {timezone})
            .pipe(map((data) => data.data.updateUserNewTimezone as Member));
    }

    searchMemberByEmail(email: string) {
        return this.query(MemberGraphQL.queries.memberByEmail, {email: email})
            .pipe(map((data) => data.data ? new Member(data.data.memberByEmail) : null));
    }

    sendEmailPasswordRecovery(email: string, autologRedirection: string = null) {
        return this.mutation(MemberGraphQL.mutations.sendEmailPasswordRecovery, {
            email: email,
            autologRedirection: autologRedirection
        })
            .pipe(map((data) => (data.data && data.data.sendEmailPasswordRecovery) ? new Member(data.data.sendEmailPasswordRecovery) : null));
    }

    updateMember(memberForm: Member) {
        return this.mutation(MemberGraphQL.mutations.updateMember, memberForm)
            .pipe(map((data) => new Member(data.data.updateMember)));
    }

    createMember(member: Member, logIn: boolean = false, companyAlreadyCreated: boolean = true, isPreregister = false) {
        return this.mutation(MemberGraphQL.mutations.createMember, {
            idcompany: +member.company.id,
            firstname: member.firstname,
            lastname: member.name,
            jobtitle: member.jobtitle,
            phone: member.mobile,
            email: member.email,
            timezone: member.timezone,
            logIn: logIn,
            companyAlreadyCreated: companyAlreadyCreated,
            isPreregister: isPreregister
        })
            .pipe(map((data) => data.data.createMember));
    }

    updateSignupInformationAfterZohoInvitation(member: Member) {
        return this.mutation(MemberGraphQL.mutations.updateSignupInformationAfterZohoInvitation, {
            firstname: member.firstname,
            lastname: member.name,
            jobtitle: member.jobtitle,
            phone: member.mobile,
            email: member.email,
            timezone: member.timezone
        })
            .pipe(map((data) => data.data && data.data.updateSignupInformationAfterZohoInvitation ? new Member(data.data.updateSignupInformationAfterZohoInvitation) : null));
    }


    createMemberWithCompany(member: Member, company: Company, logIn: boolean = false, isPreregister = false) {
        return this.mutation(MemberGraphQL.mutations.createMemberWithCompany, {
            idCompanyType: +company.companytype.id,
            name: company.name,
            country_name: company.country.name,
            region_name: company.regionName ? company.regionName : null,
            city_name: company.cityName ? company.cityName : null,
            postal_code: company.postalCode ? company.postalCode : null,
            formatted_address: company.formattedAddress,
            website: company.website,
            companyPhone: company.phone,
            idcompanytags: company.companyTags.length > 0 ? company.companyTags.map(tag => tag.id) : [],
            categories: company.rootCategories.map(category => +category.id),
            activities: company.activities.map(activity => +activity.id),
            specializations: company.specializations.map(specialization => +specialization.id),
            firstname: member.firstname,
            lastname: member.name,
            jobtitle: member.jobtitle,
            phone: member.mobile,
            email: member.email,
            timezone: member.timezone,
            logIn: logIn,
            isPreregister: isPreregister
        })
            .pipe(map((data) => data.data.createMemberWithCompany));
    }

    savePassword(password: string) {
        return this.mutation(MemberGraphQL.mutations.savePassword, {password: password})
            .pipe(map((data) => data.data.savePassword));
    }

    changeSettings(formValue: any) {
        return this.mutation(MemberGraphQL.mutations.changeSettings, formValue)
            .pipe(map((data) => data.data.changeSettings));
    }

    changeZohoRegistrationMemberPassword(password: string) {
        return this.mutation(MemberGraphQL.mutations.changeZohoRegistrationMemberPassword, {
            password: password,
        })
            .pipe(map((data) => data.data && data.data.changeZohoRegistrationMemberPassword ?new Member(data.data.changeZohoRegistrationMemberPassword):null));
    }

    addFavorite(idcompany: number) {
        return this.mutation(MemberGraphQL.mutations.addFavorite, {
            idcompany: idcompany
        })
            .pipe(map((data) => data.data.addFavorite));
    }

    saveFavorite(userproductIds: number[], companyIds: number[]) {
        return this.mutation(MemberGraphQL.mutations.saveFavorites, {
            userproductIds: userproductIds,
             companyIds: companyIds
            }).pipe(map(data => data.data.saveFavorites ? this.toArrayOfFavorites(data.data.saveFavorites.items) : []));
    }

    login(username: string, password: string = null) {
        return this.mutation(MemberGraphQL.mutations.login, {
            username: username,
            password: password,
        })
            .pipe(map((data) => data.data &&  data.data.login? new Member(data.data.login):null));
    }

    getMyFavorites() {
        return this.query(MemberGraphQL.queries.myFavorites, null, 'network-only')
            .pipe(map((data) => data.data.me && data.data.me.favorites ? this.toArrayOfFavorites(data.data.me.favorites.items) : null));
    }

    getMyProfile() {
        return this.query(MemberGraphQL.queries.myProfile, {}, 'no-cache')
            .pipe(map((data) => this.processResponse<Member>(data, 'me')));
    }

    getMySummitRegistration() {
        return this.query(MemberGraphQL.queries.meSummitRegistrations, {})
            .pipe(map((data) => this.processResponse<Member>(data, 'me')));
    }

    getMySummitRegistrations() {
        return this.query(MemberGraphQL.queries.mySummitRegistrations, {})
            .pipe(map((data) => data.data && data.data.me ? new Member(data.data.me) : null));
    }

    getMyDashboardBuyer(startDate: string, endDate: string, oldStartDate: string, oldEndDate: string) {
        return this.query(MemberGraphQL.queries.myDashboardBuyer, {
            startDate: startDate,
            endDate: endDate,
            oldStartDate: oldStartDate,
            oldEndDate: oldEndDate
        })
            .pipe(map((data) => data.data && data.data.me ? new Member(data.data.me) : null));
    }

    getMyDashboardSupplier(startDate: string, endDate: string, oldStartDate: string, oldEndDate: string) {
        return this.query(MemberGraphQL.queries.myDashboardSupplier, {
            startDate: startDate,
            endDate: endDate,
            oldStartDate: oldStartDate,
            oldEndDate: oldEndDate
        })
            .pipe(map((data) => data.data && data.data.me ? new Member(data.data.me) : null));
    }

    getMyDashboardRelationships() {
        return this.query(MemberGraphQL.queries.myDashboardRelationships, {}, 'no-cache')
            .pipe(map((data) => data.data && data.data.me ? new Member(data.data.me) : null));
    }

    getMyDashboardSupplierSuggestions() {
        return this.query(MemberGraphQL.queries.myDashboardSupplierSuggestions, {}, 'no-cache')
            .pipe(map((data) => data.data && data.data.me ? new Member(data.data.me) : null));
    }

    getMyDashboardBuyerSuggestions() {
        return this.query(MemberGraphQL.queries.myDashboardBuyerSuggestions, {}, 'no-cache')
            .pipe(map((data) => data.data && data.data.me ? new Member(data.data.me) : null));
    }

    getMyRelationships(search: string, statuses: number[], sources: FilterSource[], updatedBy: string, orderBy: string, maxUpdatedDate: string, offset: number) {
        return this.query(MemberGraphQL.queries.myRelationships, {
            search: search,
            statuses: statuses,
            sources: sources.map((filter: FilterSource) => {
                return {id: filter.id, type: filter.type};
            }),
            updatedBy: updatedBy,
            orderBy: orderBy,
            maxUpdatedDate: maxUpdatedDate,
            offset: offset
        }, 'network-only')
            .pipe(map((data) => data.data && data.data.me ? new Member(data.data.me) : null));
    }

    updateRelationshipStatus(idCompanyRelationship: number, idStatus: number, idTags: number[], comment: string) {
        return this.mutation(MemberGraphQL.mutations.updateRelationshipStatus, {
            idCompanyRelationship: idCompanyRelationship,
            idStatus: idStatus,
            idTags: idTags,
            comment: comment
        })
            .pipe(map((data) => data.data && data.data.updateRelationshipStatus ? new CompanyRelationship(data.data.updateRelationshipStatus) : null));
    }

    getSummitsRegistrations() {
        return this.query(MemberGraphQL.queries.summitsRegistration, {})
            .pipe(map((data) => {
                return data.data && data.data.me ? new Member(data.data.me) : null;
            }));
    }

    getSummitDetails(summitCode: string) {
        return this.query(MemberGraphQL.queries.getSummitByCode, {summitCode: summitCode})
            .pipe(map((data) => {
                return data.data ? data.data.summit : null;
            }));
    }

    getEventRegistrations() {
        return this.query(MemberGraphQL.queries.eventRegistrations, {})
            .pipe(map((data) => {
                return data.data && data.data.me ? new Member(data.data.me) : null;
            }));
    }

    inviteNewColleague(firstName: string, lastName: string, email: string) {
        return this.mutation(MemberGraphQL.mutations.inviteNewColleague, {
            firstName: firstName,
            lastName: lastName,
            email: email
        }).pipe(map((data) => data.data && data.data.inviteNewColleague ? new Member(data.data.inviteNewColleague) : null));
    }

    savePreregisterInfo(member: Member, buyingScopeCategories: number[], buyingScopeActivities: number[]) {
        return this.mutation(MemberGraphQL.mutations.savePreregisterInfo, {
            idmember: member.id,
            buyingScopeCategories: buyingScopeCategories,
            buyingScopeActivities: buyingScopeActivities
        })
            .pipe(map((data) => data.data && data.data.savePreregisterInfo ? new Member(data.data.savePreregisterInfo) : null));
    }

    getCompanyEventParticipations() {
        return this.query(MemberGraphQL.queries.companyEventParticipations, null)
            .pipe(map((data) => data.data && data.data.me ? new Member(data.data.me) : null));
    }

    acceptNotification(internalName: string, type: string = null) {
        return this.mutation(MemberGraphQL.mutations.acceptNotification, {internalName: internalName, type: type})
            .pipe(map((data) => data.data && data.data.acceptNotification && data.data.acceptNotification.length > 0 ? data.data.acceptNotification.map((notification: any) => new MemberNotification(notification)) : []));
    }

    getMyBuyingScope() {
        return this.query(MemberGraphQL.queries.meBuyingScope, {})
            .pipe(map((data) => {
                return data.data && data.data.me ? new Member(data.data.me) : null;
            }));
    }

    getNotifications(fetchPolicy: string = 'network-only') {
        return this.query(MemberGraphQL.queries.getNotifications, {}, fetchPolicy)
            .pipe(map((data) => data.data && data.data.me && data.data.me.notifications.length > 0 ? data.data.me.notifications.map((notification: any) => new MemberNotification(notification)) : []));
    }

    populateMemberWithFakeData(member: Member): Member {
        const fakeMember = new Member(member);
        if (member.isSupplier()) {
            fakeMember.company.searchOnProducts = this.getRandomInt(50, 100);
            fakeMember.company.oldSearchOnProducts = this.getRandomInt(0, 50);
        }
        fakeMember.company.oldSearchOnCompany = this.getRandomInt(0, 50);
        fakeMember.company.searchOnCompany = this.getRandomInt(50, 100);
        fakeMember.company.oldTotalFavoritesOnCompany = this.getRandomInt(0, 50);
        fakeMember.company.totalFavoritesOnCompany = this.getRandomInt(50, 100);

        fakeMember.relationshipsCounter = this.createFakeRelationshipCounter();
        fakeMember.relationships = {
            count: this.getRandomInt(50, 100),
            items: []
        };
        return fakeMember;
    }

     getRandomInt(min: number, max: number) {
        return Math.floor(Math.random() * (max - min) + min);
    }

    createFakeRelationshipCounter() {
        const fakeRelationshipCounter = [];
        const items = {
            "Not open yet": "#D9DEE8",
            "Open": "#0FA1F8",
            "Deal won": "#E92531",
            "Deal lost": "#009F57"
        };

        for (const label in items) {
            const item = new ChartResultItem(null);
            item.label = label;
            item.color = items[label];
            item.count = this.getRandomInt(100, 500);
            fakeRelationshipCounter.push(item);
        }
        return fakeRelationshipCounter;
    }

    acceptNeedlTc() {
        return this.mutation(MemberGraphQL.mutations.acceptNeedlTc, {})
            .pipe(map((data) => data.data && data.data.acceptNeedlTc ? new Member(data.data.acceptNeedlTc) : null));
    }

    updateParticipationDetails(form: any) {
        return this.mutation(MemberGraphQL.mutations.updateParticipationDetails, form)
            .pipe(map((data) => new Member(data.data.updateParticipationDetails)));
    }

    deactivateMemberFromCompany(idMember: number) {
        return this.mutation(MemberGraphQL.mutations.deactivateMemberFromCompany, {idMember: idMember})
            .pipe(map((data) => data.data && data.data.deactivateMemberFromCompany ? new Member(data.data.deactivateMemberFromCompany) : null));
    }

    saveWebRTCResult(score: number, logs: string, isFinished: boolean = true) {
        return this.mutation(MemberGraphQL.mutations.saveWebRTCResult, {score: score, logs: logs, isFinished: isFinished})
            .pipe(map((data) => data.data && data.data.saveWebRTCResult ? data.data.saveWebRTCResult : null));
    }

    getProfileType(machineName: string) {
        let profileType = '';
        switch (machineName) {
            case "manufacturer":
            case "packaging":
            case "admin":
                profileType = 'supplier';
                break;
            case "food_service":
            case "retail_group":
            case "buying_office":
            case "buying_alliance":
                profileType = 'buyer';
                break;
            case "agent":
            case "logistics":
            case "co_packer":
            case "expert":
            case "federation":
            case "press":
            case "importer":
            case "service_provider":
                profileType = 'other';
                break;
        }
        return profileType;
    }

    getMyProspectListByFilters() {
        return this.query(MemberGraphQL.queries.getMyProspectListByFilters, {})
            .pipe(map((data) => {
                return data.data && data.data.currentMemberProspectListByFilters && data.data.currentMemberProspectListByFilters.items
                    ? data.data.currentMemberProspectListByFilters.items.map(comp => new Company(comp))
                    : [];
            }));
    }

    removeProspect(prospect: Company) {
        return this.mutation(MemberGraphQL.mutations.removeProspect , {idcompany: prospect.idcompany})
            .pipe(map((data) => data.data.prospect));
    }
}
