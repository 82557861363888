import {
    Component, ContentChild,
    forwardRef, Input, OnDestroy, OnInit
} from '@angular/core';
import {NdlPasswordComponent} from './password.component';
import {Subscription} from 'rxjs';
import {NdlPasswordBaseDirective} from './password-base.directive';
import {AbstractControl, Validators} from '@angular/forms';

@Component({
    selector: 'ndl-password-check',
    templateUrl: './password.component.html',
    styleUrls: ['./password.component.scss'],
    host: {
        'class': 'ndl-password ndl-password-check',
        '(blur)': "onTouched()"
    }
})
export class NdlPasswordCheckComponent extends NdlPasswordBaseDirective implements OnInit, OnDestroy {
    private passwordWatch: Subscription;
    @Input() password: NdlPasswordComponent;

    ngOnInit() {
        super.ngOnInit();
        if (!this.password) {
            throw new Error("Needl Lib: Your ndl-password-check field " + (this.name ? '"' + this.name + '" ' : '') + " should have a ndl-password attached to it. Pass it through the \"password\" property of the component");
        }
        this.passwordWatch = this.password.change.subscribe(() => this.ngControl?.control?.updateValueAndValidity());
    }

    protected _setPatternValidator() {
        if (this.password) {
            this.validators = (control: AbstractControl) => this.password.value !== control.value ? {mismatch: true} : null
        }
    }

    ngOnDestroy() {
        this.passwordWatch.unsubscribe();
    }
}
