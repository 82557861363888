<ndl-form-field #formField [prefix]="prefix" [suffix]="suffix" [label]="label" [hint]="hint" [errors]="errors" (click)="openPanel($event)">
    <ng-template #prefix>
        <ng-content select="[ndl-prefix]"></ng-content>
    </ng-template>

    <mat-chip-list #chipList multiple [formControl]="innerFormControl" [selectable]="false" [required]="required">
        <mat-chip *ngFor="let chip of chips | async" [value]="chip.value" removable (removed)="delete($event)"
                  (blur)="onTouched()">
            {{chip.label}}
            <button matChipRemove (click)="onTouched()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-chip>
        <input class="input-tags" [placeholder]="placeholder" [formControl]="searchControl" [matAutocomplete]="auto"
               [matChipInputFor]="chipList" (ngModelChange)="searchValueChanged.emit($event)"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matAutocompleteConnectedTo]="autocompleteOrigin"
               #inputAutoComplete="matAutocompleteTrigger"
               (matChipInputTokenEnd)="createOption($event)" (blur)="onTouched()"/>
    </mat-chip-list>

    <mat-autocomplete #auto="matAutocomplete" class="ndl-tag-autocomplete" (optionSelected)="selectOption($event)">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.value" (click)="selectedOptions.push(option)">
            {{option.label}}
        </mat-option>
    </mat-autocomplete>

    <ng-template #suffix>
        <ng-content select="[ndl-suffix]"></ng-content>
    </ng-template>
</ndl-form-field>
