import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NdlTagComponent} from './tag.component';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
    declarations: [
        NdlTagComponent,
    ],
    imports: [
        CommonModule,
        MatChipsModule,
        MatIconModule
    ],
    exports: [
        NdlTagComponent,
    ]
})
export class NeedlTagModule { }
