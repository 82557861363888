import {Directive, HostBinding, Input, OnInit, TemplateRef} from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Directive({
    selector: '[ndlListItem], [ndlColumn]'
})
export class NdlListItemDirective implements OnInit {
    index = 0;
    styckyOffset = "initial"; // Because of styling, using sticky creates a gap that need to be corrected. See ndl-table AfterInit
    @Input('ndlColumnName') name: string;
    @Input('ndlColumnTitle') title: string;
    @Input('ndlColumnSort') sort: string|boolean;
    @Input('ndlColumnHeader') header: TemplateRef<any>;

    @Input('ndlColumnSticky') get sticky() {
        return this._sticky;
    }
    set sticky(enable) {
        this._sticky = coerceBooleanProperty(enable);
    }
    private _sticky = false;

    @Input('ndlColumnStickyEnd') get stickyEnd() {
        return this._stickyEnd;
    }
    set stickyEnd(enable) {
        this._stickyEnd = coerceBooleanProperty(enable);
    }
    private _stickyEnd = false;

    constructor(public content: TemplateRef<any>) {
    }

    static ngTemplateContextGuard<T>(dir: T, ctx: any): ctx is T {
        return true;
    }

    ngOnInit() {
        if (this.name && this.sort === true) {
            this.sort = this.name;
        }
    }
}
