<div class="input-group">
    <span class="input-group-addon">
        <div class="dropdown">
            <button type="button" class="dropbtn btn" #toggleDropdown id="toggleDropdown">
                <span [class]="'flag flag-' + selectedCountry.toLowerCase()" *ngIf="selectedCountry"></span>
                <span class="flag" *ngIf="!selectedCountry"></span>
                <span class="material-icons">arrow_drop_down</span>
            </button>
            <div class="dropdown-content" *ngIf="showDropdown" #dropdownCountries>
                <input type="text"
                       placeholder="Search..."
                       id="searchCountry"
                       autocomplete="off"
                       name="searchRegion"
                       [(ngModel)]="searchValue"
                       (ngModelChange)="onSearchChange()"
                       #searchCountry>
                <div class="list-group scrollable-menu">
                    <span class="list-group-item" *ngFor="let country of filteredCountries"
                        (click)="updateSelectedCountry(country)">
                        <span [class]="'flag flag-' + country.toLowerCase()"></span>
                        <span class="country-name">{{ getCountryName(country) }}
                            <span class="dial-code">+{{ getCountryCodeFromRegionCode(country) }}</span>
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </span>

    <!-- if required -->
    <input class="form-control" [(ngModel)]="phoneNumber" (ngModelChange)="updatePhoneNumber()" [name]="name"
        [placeholder]="placeholder" [type]="type" *ngIf="required" required #phoneNumberInput>

    <!-- if not required -->
    <input class="form-control" [(ngModel)]="phoneNumber" (ngModelChange)="updatePhoneNumber()" [name]="name"
        [placeholder]="placeholder" [type]="type" *ngIf="!required" #phoneNumberInput>
</div>
