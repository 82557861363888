<div class="ndl-collapse-header"
     [class.ndl-collapse-header-disabled]="disabled"
     matRipple matRippleColor="#FFFFFF25" [matRippleDisabled]="disabled"
     [ngClass]="'ndl-collapse-header-'+theme"
     [class.ndl-collapse-header-outlined]="outlined" (click)="!expandOnHover && toggle()"
    (mouseenter)="expandOnHover && open()"
    (mouseleave)="expandOnHover && close()">
    <div class="ndl-collapse-header-container">
        <mat-icon *ngIf="toggleMode === 'before' && !disabled" class="ndl-collapse-icon">expand_more</mat-icon>
        <div class="ndl-collapse-header-content">
            <ng-content select="[ndl-collapse-header]"></ng-content>
        </div>
        <mat-icon *ngIf="toggleMode === 'after' && !disabled" class="ndl-collapse-icon after">expand_more</mat-icon>
    </div>
</div>
<div class="ndl-collapse-body" [@ndl-collapse-animate]="expanded ? 'opened' : 'closed'"
     (mouseenter)="expandOnHover && open()"
     (mouseleave)="expandOnHover && close()">
    <div class="ndl-collapse-body-container">
        <ng-content></ng-content>
    </div>
</div>
