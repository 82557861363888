import {StickyPositioningListener, StickyUpdate} from '@angular/cdk/table';
import {Subject} from 'rxjs';

export class NdlStickyListener implements StickyPositioningListener {
    columnUpdates$ = new Subject<StickyUpdate>();
    constructor() {

    }
    stickyColumnsUpdated(update: StickyUpdate) {
        this.columnUpdates$.next(update);
    }

    stickyHeaderRowsUpdated(update: StickyUpdate) {
    }

    stickyEndColumnsUpdated(update: StickyUpdate) {
    }

    stickyFooterRowsUpdated(update: StickyUpdate) {
    }
}
