import {WabelEntity} from "./wabel-entity.abstract";

export class CustomContent extends WabelEntity {
    idcustomcontent: number;
    name: string;
    content: string;

    get id(): number {
        return +this.idcustomcontent;
    }

    set id(id: number) {
        this.idcustomcontent = id;
    }
}
