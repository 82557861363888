<ndl-table [data]="data$" [sortColumn]="sortColumn" [sortOrder]="sortOrder" [sortingCallback]="sort" stickyHeader enableSelection>
    <ng-container *ndlColumn="let col; name: 'ID', sort: true, sticky: true">{{ col.id }}</ng-container>
    <ng-container *ndlColumn="let col; name: 'Name', sort: true, sticky: true">{{ col.name }}</ng-container>
    <ng-container *ndlColumn="let col; name: 'Website', sort: true">{{ col.website }}</ng-container>
    <ng-container *ndlColumn="let col; name: 'Status', sort: true">{{ col.status }}</ng-container>
    <ng-container *ndlColumn="let col; name: 'Col1', sort: true">{{ col.name }}</ng-container>
    <ng-container *ndlColumn="let col; name: 'Col2', sort: true">{{ col.website }}</ng-container>
    <ng-container *ndlColumn="let col; name: 'Col3', sort: true">{{ col.status }}</ng-container>
    <ng-container *ndlColumn="let col; name: 'Col4', sort: true">{{ col.name }}</ng-container>
    <ng-container *ndlColumn="let col; name: 'Col5', sort: true">{{ col.website }}</ng-container>
    <ng-container *ndlColumn="let col; name: 'Col6', sort: true">{{ col.status }}</ng-container>
    <ng-container *ndlColumn="let col; name: 'Col7', sort: true">{{ col.name }}</ng-container>
    <ng-container *ndlColumn="let col; name: 'Col8', sort: true">{{ col.website }}</ng-container>
    <ng-container *ndlColumn="let col; name: 'Col9', sort: true">{{ col.status }}</ng-container>

    <form #filters [ndlTableFilter]="filter">
        <ndl-text name="search" ngModel placeholder="This is your search placeholder" clearable>
            <mat-icon ndl-prefix>search</mat-icon>
        </ndl-text>
        <ndl-select name="status" ngModel placeholder="Status" clearable>
            <option ndl-option value="on">On</option>
            <option ndl-option value="off">Off</option>
        </ndl-select>
    </form>

    <button ndl-table-action ndl-button><mat-icon fontSet="material-icons-outlined">get_app</mat-icon>Export</button>
</ndl-table>
