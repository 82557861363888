import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
export interface PlasticTypes {
    name: string;
    Checked: boolean;
    id: number;

}
@Component({
  selector: 'app-plastic-type-popin-packaging-material',
  templateUrl: './plastic-type-popin-packaging-material.component.html',
  styleUrls: ['./plastic-type-popin-packaging-material.component.scss']
})
export class PlasticTypePopinPackagingMaterialComponent implements OnInit {

    @Output() closeEvent: EventEmitter<any> = new EventEmitter();

    isSmallScreen = false;
    @Input() popSubMenu = false;
    @Input() tagsArray = null;
    @Input() subEditModeTags;
    removable = true;
    popData: PlasticTypes[];
    constructor() { }

    ngOnInit() {

        if (this.tagsArray != null) {
            this.popData = this.tagsArray.map(item => {
                if (this.subEditModeTags != null && this.subEditModeTags.length !== 0) {
                    if (this.subEditModeTags.indexOf(item.id) !== -1) {
                        return {
                            name: item.name,
                            Checked: true,
                            id: +item.id
                        };
                    }
                }
                return {
                    name: item.name,
                    Checked: false,
                    id: +item.id
                };
            });
            const index = this.popData.findIndex(el => el.name === 'Others');
            if (index !== -1) {
                const other = this.popData.find(el => el.name === 'Others');
                this.popData.splice(index, 1);
                this.popData.push(other);
            }
        }
        // if (this.popSubMenu)
        //     this.popData = this.CategoriesData;
        // else
        //     this.popData = this.PlasticTypesData;
    }
    addType(type: PlasticTypes): void {
        const index = this.popData.indexOf(type);

        if (index >= 0) {
            this.popData[index].Checked = true;
        }
    }
    remove(type: PlasticTypes): void {
        const index = this.popData.indexOf(type);

        if (index >= 0) {
            this.popData[index].Checked = false;
        }
    }
    closePopUp() {
        const choosedTags = this.popData.filter(function(address) {
            return address.Checked == true;
        });
        const choosedTagsIDS = choosedTags.map(a => a.id);
        this.closeEvent.emit(choosedTagsIDS);
    }

}
