import { Component, Input, OnInit, HostBinding } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "progress-icon",
  templateUrl: "./progress-icon.component.html",
  styleUrls: ["./progress-icon.component.scss"]
})
export class ProgressIconComponent implements OnInit {
  @Input() percent: Number = 50;
  @Input() size: String = "13px";
  @Input() borderWidth: String = "2px";
  @Input() incompleteColor: String = "#455463";
  @Input() showPercentValue: Boolean = false;

  @HostBinding("attr.style")
  public get sizeAsStyle(): any {
    return this.sanitizer.bypassSecurityTrustStyle(this.style);
  }

  style = "";
  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.style = [
      `--border-width: ${this.borderWidth}`,
      `--size: ${this.size}`,
      `--border-color-default: ${this.incompleteColor}`
    ].join(";");
  }
}
