import {Request} from './request';
import {Address} from './Address';
import {SampleRequestProduct} from "./sample_request_product";

export class SampleRequest extends Request {
    constructor(obj: any) {
        super(obj);
        this.address = obj.address ? new Address(obj.address) : null;
        this.sampleRequestProducts = obj.sampleRequestProducts && obj.sampleRequestProducts.items ? obj.sampleRequestProducts.items.map(srp => new SampleRequestProduct(srp)) : [];
    }

    status: string;
    address: Address;
    trackingNumber: string;
    courier: string;
    dateArrivalEstimation: Date;
    sampleRequestProducts: SampleRequestProduct[];
}
