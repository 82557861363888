import {Injectable} from '@angular/core';
import {MemberService} from "./wabel-client/services/member.service";
import {Member} from "./wabel-client/entities/member";
import {Company} from "./wabel-client/entities/company";
import {LocalStorageService} from "ngx-webstorage";
import {Favorites} from "./wabel-client/entities/favorites";
import {DataSharingService} from './data-sharing.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {NeedlSecurityService} from "@wabel/needl-security";
import {map} from 'rxjs/operators';
import {Subscription} from 'rxjs/internal/Subscription';
import {LocaleService} from './locale.service';
import {SignupService} from "./wabel-client/services/signup.service";
import {Router} from "@angular/router";
import {NdlLoadingService} from 'needl-lib';
import {LiveVideoMeetingService} from "./wabel-client/services/live_video_meeting.service";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private nbAuthQuery: number = 0;
    private guardsLoading: number = 0;
    public request: Subscription;
    public user: Member;
    public loading: boolean = true;
    public memberUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public firstAuthQuery: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private _countUnreadMessages = null;
    public wasLoadedAtLeastOnce = false;
    public wantToBypassSupplierRegistrationFunnel = false;

    constructor(private memberService: MemberService, private localStorageService: LocalStorageService,
                private dataSharingService: DataSharingService, private needlSecurityService: NeedlSecurityService,
                private localeService: LocaleService,
                private router: Router,
                private loadingService: NdlLoadingService,
                private liveVideoMeetingService: LiveVideoMeetingService
    ) {
        loadingService.load();
    }

    /*
     * Methods used only in Master Guard
     */
    getMainRequest(): Observable<Member> {
        return this.memberService.getMe().pipe(map(member => {
            this.loading = true;
            this.wasLoadedAtLeastOnce = true;
            if (this.user && member) {
                this.loading = false;
                return this.user;
            }
            this.localeDiscrepancy = this.localeService.checkLocaleDiscrepancy(member);
            this.setUser(member);
            if (member) {
                this.liveVideoMeetingService.initVideoMeetingForBanner();
            }
            return this.user;
        }));
    }

    setUser(u: Member = null) {
        this.user = u;
        this.loading = false;
        this.localeDiscrepancy = this.localeService.checkLocaleDiscrepancy(this.user);
        if (u) {
            this.needlSecurityService.permissions = u.permissions;
        }
        this.memberUpdated.next(true);
        if (this.nbAuthQuery === 0) {
            this.firstAuthQuery.next(true);
        }
        this.nbAuthQuery++;
    }
    localeDiscrepancy = false;

    /*
     * Methods used everywhere
     */

    private isInUrl(value: string) {
        return window.location.href.indexOf(value) !== -1;
    }

    load(loadUserGoogleTagDatalayer: boolean) {
        if (this.request) {
            this.request.unsubscribe();
        }
        this.request = this.getMainRequest().subscribe(member => {
            // We had a bug when any subscribe GraphQL query returns a response, it passed in this next and the
            // user was reloaded to an old version of this user, maybe cached?
            // So we decided to stop it if the user is already loaded
            this.dataSharingService.isUserLoggedIn.next(this.isLogged());
            if (member && member.userInfoTagManager && loadUserGoogleTagDatalayer && (<any>window).dataLayer && !(<any>window).dataLayer.user) {
                (<any>window).dataLayer.push({user: member.userInfoTagManager.toJSON(null)});
            }
            if (this.countUnreadMessages === null && this.user && this.user.countUnreadMessages) {
                this.countUnreadMessages = this.user.countUnreadMessages;
            }

            this.loadingService.endLoading();

            // this.localStorageService.store('meAuthentication', {
            //     data: member,
            //     expiry: new Date(Date.now() + (5 * 60000)).getTime()
            // });
            if (this.request) {
                this.request.unsubscribe();
            }
            if (member && member.company.registrationStep && member.company.registrationStep !== SignupService.REGISTRATION_FINISHED && !this.wantToBypassSupplierRegistrationFunnel && !this.isInUrl('product-requests')) {
                this.router.navigate(['/sign-up/manufacturer/company-info']);
            }
        });
    }

    addLoadingGuard() {
        this.guardsLoading++;
    }

    updateFavorite(favorites: Favorites[]) {
        this.user.favorites = favorites;
        this.memberUpdated.next(true);
    }

    removeLoadingGuard() {
        this.guardsLoading--;
        this.guardsLoading = this.guardsLoading < 0 ? 0 : this.guardsLoading; // never under 0
    }

    isGuardLoading(): boolean {
        return this.guardsLoading !== 0;
    }

    isAdmin(): boolean {
        return this.isLogged() && this.user.isAdmin();
    }

    isLogged(): boolean {
        return !this.loading && this.user !== null;
    }

    isVerified(): boolean {
        return this.isLogged() && this.user.isVerified();
    }

    isUnverified(): boolean {
        return this.isLogged() && this.user.isUnverified();
    }

    isRejected(): boolean {
        return this.user.isRejected();
    }

    isWabelClubMember(): boolean {
        return this.isLogged() && this.user.isWabelClubMember();
    }

    isMasquerading(): boolean {
        return this.isLogged() && this.user.isMasquerading;
    }

    hasEmailVerified(): boolean {
        return this.isLogged() && this.user.hasEmailVerified;
    }

    isOwnerOfCompany(company: Company): boolean {
        return this.isLogged() && this.user.company.idcompany === company.idcompany && this.user.isCompanyOwner;
    }

    isLoading(): boolean {
        return this.loading;
    }

    getMember(): Member {
        return this.user;
    }

    isBuyer(): boolean {
        return this.isLogged() && this.user.isBuyer();
    }

    isImporterOrServiceProvider(): boolean {
        return this.isImporter() || this.isServiceProvider();
    }

    isSupplier(): boolean {
        return this.isLogged() && this.user.isSupplier();
    }

    isImporter(): boolean {
        return this.isLogged() && this.user.isImporter();
    }

    isPackagingSupplier(): boolean {
        return this.isLogged() && this.user.isPackagingSupplier();
    }
    isServiceProvider(): boolean {
        return this.isLogged() && this.user.isServiceProvider();
    }
    isRetailGroup():boolean{
        return this.isLogged() && this.user.isRetailGroup();
    }
    isBuyingOffice():boolean{
        return this.isLogged() && this.user.isBuyingOffice();
    }
    isBuyingAlliance():boolean{
        return this.isLogged() && this.user.isBuyingAlliance();
    }
    isCoPacking():boolean{
        return this.isLogged() && this.user.isCoPacking();
    }
    isInCompany(company: Company, checkCanAccessParent: boolean = false, checkCanAccessParentChildren: boolean = false): boolean {
        if (!this.user) return false;
        if (this.user.company.idcompany === company.idcompany) {
            return true;
        }
        if (checkCanAccessParent) { // Ex: a Buying Office can access his parent (Retail Group)
            if (this.user.company.parentCompany && this.user.company.parentCompany.idcompany === company.idcompany) {
                return true;
            }
        }
        if (checkCanAccessParentChildren) { // Ex: a Buying Office can access others Buying Offcies of his parent (Retail Group)
            if (this.user.company.parentCompany && company.parentCompany && this.user.company.parentCompany.idcompany === company.parentCompany.idcompany) {
                return true;
            }
        }
        return false;
    }

    canManageCompany(): boolean {
        return this.isLogged() && (this.isVerified() || this.user.isCompanyOwner);
    }

    hasCompanyInFavorite(idcompany: number): boolean {
        return this.isLogged() && this.getMember().favorites.some((f) => f.idfavorite && f.company && f.company.idcompany === idcompany);
    }

    hasProductInFavorite(idproduct: number): boolean {
        return this.isLogged() && this.getMember().favorites.some((f) => f.idfavorite && f.userproduct && f.userproduct.iduserproduct === idproduct);
    }

    refreshNotifications() {
        this.memberService.getNotifications().subscribe((notifications) => {
            if (!notifications) { return; }
            this.getMember().setNotifications(notifications);
            }
        );
    }

    updateTimezone( zone: string ){
        if( this.isLogged() ){
            this.user.timezone = zone
        }
    }

    get userlocale() {
        return this.user.locale;
    }

    get countUnreadMessages(): number {
        return this._countUnreadMessages;
    }

    set countUnreadMessages(value: number) {
        if (value) {
            this._countUnreadMessages = value;
        } else {
            this._countUnreadMessages = 0;
        }
    }

    acceptNeedlTc() {
        this.user.needlTcAccepted = true;
        this.memberUpdated.next(true);
    }

    addPermissionsToNeedlSecurity(permissions: string[]) {
        this.needlSecurityService.permissions = this.needlSecurityService.permissions.concat(permissions).filter(
            (permission, index, permissionsArray) => permissionsArray.indexOf(permission) === index);
    }
}
