import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NdlSelectOption} from "../../../selects/NdlSelectOption";

@Component({
  selector: 'ndl-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})

export class NdlCheckboxComponent {
    @Input() option: NdlSelectOption;
    @Input() checked = false;
    @Input() counter = 0;
    @Input() hideCheckbox = false;
    @Input() disabled = false;
    @Input() canDeselectIfDisabled = false;

    @Output() valueChanged = new EventEmitter<boolean>();
    @Output() labelWithoutCheckboxClicked = new EventEmitter<boolean>();

    constructor() {}

    toggleOption() {
        if (!this.hideCheckbox && (!this.disabled || (this.canDeselectIfDisabled && this.checked))) {
            this.checked = !this.checked;
            this.valueChanged.emit(this.checked);
        }
    }

    clickLabelWithoutCheckbox(event) {
        event.preventDefault();
        this.labelWithoutCheckboxClicked.emit();
    }
}
