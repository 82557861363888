import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {PageErrorService} from './page-error.service';

@Injectable()
export class WabelErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) {
        // We cannot put Services here due to cyclic dependency, so we use injector instead
    }

    handleError(error: any) {
        // We can do what we want with the error here :)
        if (!error) {
            return;
        }
        const pageErrorService = this.injector.get(PageErrorService);
        console.error(error);
        if (typeof error === 'object' && !error.hasOwnProperty('graphQLErrors')) {
            if (
                error['message'].indexOf('ExpressionChangedAfterItHasBeenCheckedError') === -1 &&
                error['message'].indexOf('Received status code 503') === -1
            ) {
                pageErrorService.logErrorInDrupal('angular', error['message'], true);
            }
        } else if (typeof error === 'string') {
            pageErrorService.logErrorInDrupal('angular', error, true);
        }
    }
}
