<div class="container-fluid">
    <ndl-cascader placeholder="Choose your category" label="Categories" multiple>
        <ndl-option-group *ngFor="let cat1 of categoriesOptions" [label]="cat1.name" [value]="cat1.id">
            <ndl-option-group *ngFor="let cat2 of cat1.children" [label]="cat2.name" [value]="cat2.id">
                <ndl-option-group *ngFor="let cat3 of cat2.children" [label]="cat3.name" [value]="cat3.id">
                    <ndl-option-group *ngFor="let cat4 of cat3.children" [label]="cat4.name" [value]="cat4.id">
                        <option ndl-option *ngFor="let cat5 of cat4.children" [value]="cat5.id">{{cat5.name}}</option>
                    </ndl-option-group>
                </ndl-option-group>
            </ndl-option-group>
        </ndl-option-group>
    </ndl-cascader>
</div>
