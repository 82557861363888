import {Component, Input, OnInit} from '@angular/core';
import {Company} from "../../../../../services/wabel-client/entities/company";
import {UtilsService} from "../../../../../services/utils.service";
import {Tag} from "../../../../../services/wabel-client/entities/tag";

@Component({
    selector: 'retail-group-card-offices',
    templateUrl: './retail-group-card-offices.component.html',
    styleUrls: ['./retail-group-card-offices.component.scss']
})
export class RetailGroupCardOfficesComponent implements OnInit {

    country: Tag = null;
    @Input() company: Company;

    constructor(public utilsService: UtilsService) {
    }

    ngOnInit() {
        if (this.company.citylocation !== null && this.company.citylocation.country !== null) {
            this.country = this.company.citylocation.country;
        } else if (this.company.country !== null) {
            this.country = this.company.country;
        }
    }

    getCountryImage() {
        if (this.country) {
            return this.country.tagLocation.backgroundImage;
        }
    }

    getCountryImageBlurred() {
        if (this.country) {
            let path = this.country.tagLocation.backgroundImage;
            let pos = path.lastIndexOf('.');
            return path.substring(0, pos) + '.blur' + path.substring(pos);
        }
    }
}
