import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
    selector: '[appSectionScroll]'
})
export class SectionScrollDirective {

    el: ElementRef;
    @Output() onCurrentSection: EventEmitter<string> = new EventEmitter<string>();


    constructor(el: ElementRef) {
        this.el = el;
    }

    @HostListener("window:resize", [])
    @HostListener("window:scroll", [])
    onWindowScroll() {
        const rect = this.el.nativeElement.getBoundingClientRect();

        if (rect.y <= 200 && rect.y > -rect.height)
            this.onCurrentSection.emit(this.el.nativeElement.getAttribute('id'));

    }

}
