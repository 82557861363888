import {WabelEntity} from "./wabel-entity.abstract";
import {Tag} from "./tag";
import {SourcingProjectProspect} from './sourcing_project_prospect';
import {SourcingProjectFolder} from './sourcing_project_folder';

export class SourcingProject extends WabelEntity {
    constructor(obj: any) {
        super(obj);

        if (obj) {
            this.deadline = obj.deadline ? new Date(obj.deadline) : null;
            this.createdDate = obj.createdDate ? new Date(obj.createdDate) : null;
            this.tags = obj.tags ? obj.tags.map(t => new Tag(t)) : [];
            this.prospects = obj.sourcingProjectProspects?.items ? obj.sourcingProjectProspects.items.map(t => new SourcingProjectProspect(t)) : [];
            this.prospectCount = isNaN(obj.sourcingProjectProspects?.count) ? null : obj.sourcingProjectProspects.count;
            this.folder = obj.folder ? new SourcingProjectFolder(obj.folder) : null;
        }
    }

    id: number;
    name: string;
    description: string;
    status: string;
    deadline: Date;
    createdDate: Date;
    tags: Tag[];
    prospects: SourcingProjectProspect;
    prospectCount: number;
    folder?: SourcingProjectFolder;
}
