import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'join-now-buyer',
    templateUrl: './join-now-buyer.component.html',
    styleUrls: ['./join-now-buyer.component.scss']
})
export class JoinNowBuyerComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
