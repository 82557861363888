import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NdlFormFieldComponent} from './form-field.component';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {NeedlFormModule} from '../forms.module';

@NgModule({
  declarations: [
      NdlFormFieldComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    NeedlFormModule
  ],
  exports: [
      NdlFormFieldComponent
  ],
    providers: [
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: "fill", hideRequiredMarker: false, floatLabel: "always"}}
    ]
})
export class NeedlFormFieldModule { }
