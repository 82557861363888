import {Directive} from '@angular/core';

@Directive({
    selector: 'app-company-card-status, [app-company-card-status], [appCompanyCardStatus]',
    host: {
        class: 'company-card-status'
    }
})
export class CompanyCardStatusDirective {
}
