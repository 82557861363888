import {Injectable} from "@angular/core";
import {WabelService} from "./wabel-service.abstract";
import {ResourceGraphQL} from "../graphql/resource.graphql";
import {map} from "rxjs/operators";
import {FileInterface, NdlUploader} from 'needl-lib';
import {Observable} from 'rxjs';
import {ResourceService} from './resource.service';

@Injectable()
export class UploadService extends NdlUploader {
    constructor(public resourceService: ResourceService) {
        super();
    }

    /*
     This should be the method that uploads a temporary file to the server
     before it is moved to its final location by the request which saves the form
     */
    uploadFile(file: File): Observable<FileInterface> {
        return this.resourceService.newUploadFile(file);
    }
}
