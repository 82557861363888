import {Directive} from '@angular/core';
import {MatDialogClose, MatDialogTitle} from "@angular/material/dialog";

@Directive({
    selector: '[ndl-modal-close]',
    providers: [
        {provide: MatDialogClose, useExisting: NdlModalCloseDirective}
    ]
})
export class NdlModalCloseDirective extends MatDialogClose {
}
