<div class="ndl-snackbar-content">
    <mat-icon inline *ngIf="data.icon" class="ndl-snackbar-icon">{{data.icon}}</mat-icon>

    <div *ngIf="!isContentATemplate; else templateRef" [innerHTML]="data.content"></div>
    <ng-template #templateRef [ngTemplateOutlet]="template"></ng-template>

    <div class="ndl-snackbar-actions">
        <ng-container *ngIf="data.actions?.length">
            <div *ngFor="let action of data.actions" class="ndl-snackbar-action-item">
                <ng-container *ngTemplateOutlet="action"></ng-container>
            </div>
        </ng-container>
    </div>
</div>

<button *ngIf="data.closeIcon"
    class="ndl-snackbar-close-button" mat-icon-button (click)="snackbarService.close()">
    <mat-icon>close</mat-icon>
</button>
