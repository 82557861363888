import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import Phonenumber from "awesome-phonenumber";

@Component({
  selector: 'ndl-input-phone',
  templateUrl: './input-phone.component.html',
  styleUrls: ['./input-phone.component.scss']
})

export class NdlInputPhoneComponent implements OnInit {

    @Input() phoneNumber = '';
    @Input() required = false;
    @Input() placeholder = 'Phone number';
    @Input() name = 'phone';
    @Input() countriesList: any[] = [];
    @Output() phoneNumberChange = new EventEmitter<{phoneNumber: string, isValid: boolean, isDirty: boolean}>();

    searchValue = '';
    selectedCountry: string;
    showDropdown = false;
    countries: string[];
    countriesSorted = false;
    filteredCountries: string[];
    type = 'phone';

    @ViewChild('toggleDropdown', { static: true }) toggleDropdown: ElementRef;
    @ViewChild('dropdownCountries', { static: false }) dropdownCountries: ElementRef;
    @ViewChild('searchCountry', { static: false }) searchCountry: ElementRef;

    constructor(private renderer: Renderer2) {
        // Close the dropdown if clicked outside
        this.renderer.listen('window', 'click', (e: Event) => {
            if (!this.countriesSorted) {
                this.sortCountries();
            }
            if (this.searchCountry && e.target === this.searchCountry.nativeElement) {
                this.showDropdown = true;
            } else if (this.toggleDropdown && (e.target === this.toggleDropdown.nativeElement || this.toggleDropdown.nativeElement.contains(e.target)) && !this.showDropdown) {
                this.showDropdown = true;
            } else if (this.dropdownCountries && e.target !== this.dropdownCountries.nativeElement) {
                this.showDropdown = false;
            }
        });
    }

    ngOnInit(): void {
        this.countries = Phonenumber.getSupportedRegionCodes();
        this.filteredCountries = this.countries;
        if (this.phoneNumber) {
            this.updatePhoneNumber();
        } else {
            const phoneNumber = new Phonenumber('', this.selectedCountry);
            this.phoneNumberChange.emit({phoneNumber: phoneNumber.getNumber('international'), isValid: phoneNumber.isValid(), isDirty: false});
        }
    }

    updateSelectedCountry(countryCode: string) {
        this.showDropdown = false;
        if (this.phoneNumber && this.phoneNumber.startsWith('+') && this.phoneNumber.length > 1) {
            if (this.selectedCountry) {
                this.phoneNumber = this.phoneNumber.substring(this.getCountryCodeFromRegionCode(this.selectedCountry).toString().length + 1).trim();
            } else {
                this.phoneNumber.replace(this.getCurrentCountryCodeInput().toString(), '');
            }
        }
        this.selectedCountry = countryCode;
        this.phoneNumber = '+' + this.getCountryCodeFromRegionCode(this.selectedCountry) + ' ' + (this.phoneNumber ? this.phoneNumber : '');
        this.updatePhoneNumber();
    }

    updatePhoneNumber() {
        // Check if the country code did not change
        const countryCodeInput = this.getCurrentCountryCodeInput();
        if (countryCodeInput) {
            const regionCodeInput = this.getRegionCodeFromCountryCode(countryCodeInput);
            if (this.selectedCountry !== regionCodeInput) {
                this.selectedCountry = regionCodeInput;
            }
        } else {
            this.selectedCountry = null;
        }
        const phoneNumber = new Phonenumber( this.phoneNumber ? this.phoneNumber : '', this.selectedCountry );
        this.phoneNumberChange.emit({phoneNumber: phoneNumber.getNumber('international'), isValid: phoneNumber.isValid(), isDirty: true});
    }

    getCountryCodeFromRegionCode(regionCode: string) {
        return Phonenumber.getCountryCodeForRegionCode(regionCode);
    }

    getRegionCodeFromCountryCode(countryCode: number) {
        return Phonenumber.getRegionCodeForCountryCode(countryCode);
    }

    getCurrentCountryCodeInput() {
        const countryCode = new Phonenumber( this.phoneNumber, null ).getCountryCode();
        return countryCode !== 0 ? countryCode : null;
    }

    getCountryName(code: string): string {
        if (this.countriesList && this.countriesList.length) {
            const country = this.countriesList.find(c => c.tagLocation.code.toLowerCase() === code.toLowerCase());
            if (country) {
                return country.name;
            }
        }
        return code;
    }

    onSearchChange() {
        if (!this.searchValue || this.searchValue === '') {
            this.filteredCountries = this.countries;
        } else {
            const searchValue = this.searchValue.toLowerCase().replace('+', '');
            const countries = this.countries;
            this.filteredCountries = countries.filter(c => c.toLowerCase().includes(searchValue) || this.getCountryName(c).toLowerCase().includes(searchValue) || this.getCountryCodeFromRegionCode(c).toString().toLowerCase().includes(searchValue));
        }
    }

    sortCountries() {
        if (!this.countriesSorted && this.countries && this.countries.length) {
            const countries = Phonenumber.getSupportedRegionCodes();
            countries.sort(
                (a, b) => {
                    if (a === this.getCountryName(a)) { return 1; }
                    if (b === this.getCountryName(b)) { return -1; }
                    if (this.getCountryName(a) < this.getCountryName(b)) { return -1; }
                    if (this.getCountryName(a) > this.getCountryName(b)) { return 1; }
                    return 0;
                }
            );
            this.countries = countries;
            if (!this.searchValue || this.searchValue === '') {
                this.filteredCountries = this.countries;
            }
            this.countriesSorted = true;
        }
    }
}
