import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChildren
} from '@angular/core';
import {forkJoin, of} from 'rxjs';
import {AuthService} from '../../../services/auth.service';
import {TagTypeService} from "../../../services/wabel-client/services/tag_type.service";
import {CacheService} from "../../../services/cache.service";
import {BusinessMappingPriority} from "../../../services/wabel-client/entities/business_mapping_priority";
import {CompanyTypeService} from "../../../services/wabel-client/services/company_type.service";
import {UtilsService} from "../../../services/utils.service";
import {take} from 'rxjs/operators';
import {BusinessMappingService} from '../../../services/wabel-client/services/business-mapping.service';
import {Tag} from '../../../services/wabel-client/entities/tag';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TagService} from '../../../services/wabel-client/services/tag.service';
import {NdlOption, NdlCollapseComponent, NdlSnackbarService} from "needl-lib";
import {CompanyService} from "../../../services/wabel-client/services/company.service";
import {MyNeedlProfileService} from "../../../services/my-needl-profile.service";
import {
    BusinessMappingPrioritiesFormComponent
} from './business-mapping-priorities-form/business-mapping-priorities-form.component';
import {Router} from "@angular/router";

@Component({
    selector: 'app-business-mapping-priorities',
    templateUrl: './business-mapping-priorities.component.html',
    styleUrls: ['./business-mapping-priorities.component.scss']
})
export class BusinessMappingPrioritiesComponent implements OnInit, AfterViewInit {
    errorMarketingFocus = false;
    redirectAfterSubmitRoute = '/business-mapping';
    loading = false;
    businessChallengeOptions = [
        {value: 'consolidate_existing_market', label: 'Consolidate our existing market'},
        {value: 'open_new_markets', label: 'Open new markets'},
        {value: 'develop_new_channels', label: 'Develop new Channels'},
    ];

    marketingFocus = [];
    businessMappingPrioritiesForm: FormArray;
    regionOptions: Tag[] = [];
    marketingFocusOptions: Tag[] = [];
    activitiesOptions: Tag[] = [];
    companyTypesOptions = [];

    businessMappingPriorities: BusinessMappingPriority[] = [];

    formGroup: FormGroup;
    formSubmitted = false;
    formCompleted = false;
    scrollTo = null;
    saving = false;

    @Input() standalone = true;
    @Input() inRewardsPage = false;
    @Input() inSignupPage = false;
    @Input() hideCountries = false;

    @Output() stepFinished: EventEmitter<any> = new EventEmitter();

    @ViewChildren(BusinessMappingPrioritiesFormComponent) businessMappingPrioritiesComponents: QueryList<BusinessMappingPrioritiesFormComponent>;
    @ViewChildren(NdlCollapseComponent) collapseComponents: QueryList<NdlCollapseComponent>;

    constructor(public authService: AuthService,
                private tagTypeService: TagTypeService,
                private cacheService: CacheService,
                private businessMappingService: BusinessMappingService,
                private tagService: TagService,
                private companyTypeService: CompanyTypeService,
                private utilsService: UtilsService,
                private snackbar: NdlSnackbarService,
                private companyService: CompanyService,
                private fb: FormBuilder,
                private router: Router,
                private myNeedlProfileService: MyNeedlProfileService) {
    }

    ngOnInit() {
        this.businessMappingPrioritiesForm = this.fb.array([]);
        this.formGroup = this.fb.group({
            marketingFocus: ['', Validators.required],
            businessMappingPriorities: this.businessMappingPrioritiesForm
        });

        this.loading = true;
        const businessMappingPrioritiesQuery = this.standalone ? this.businessMappingService.myBusinessMappingPriorities('network-only').pipe(take(1)) : of(this.businessMappingPriorities);

        forkJoin({
            marketingFocus: this.tagService.getCompanyTags(this.authService.getMember().company, TagTypeService.MARKETING_FOCUS_INTERNAL_NAME).pipe(take(1)),
            marketing: this.tagTypeService.getTagTypeByInternalName(TagTypeService.MARKETING_FOCUS_INTERNAL_NAME).pipe(take(1)),
            continent: this.tagTypeService.getFullTagTypeByInternalName(TagTypeService.LOCATION_INTERNAL_NAME, true).pipe(take(1)),
            activity: this.tagTypeService.getFullTagTypeByInternalName(TagTypeService.ACTIVITY_INTERNAL_NAME, true).pipe(take(1)),
            companyType: this.companyTypeService.getCompanyTypesForBusinessMappingPriorities().pipe(take(1)),
            businessMappingPriorities: businessMappingPrioritiesQuery
        }).subscribe((data) => {
            if (data.marketingFocus) {
                this.formGroup.get('marketingFocus').setValue(data.marketingFocus.map(mf => mf.id));
            }
            if (data.marketing) {
                this.marketingFocusOptions = data.marketing.tags;
            }
            if (data.continent) {
                this.regionOptions = [];
                data.continent.tags.forEach(continent => continent.children.forEach(region => this.regionOptions.push(region)));
            }
            if (data.activity) {
                this.activitiesOptions = data.activity.tags;
            }
            if (data.companyType) {
                this.companyTypesOptions = data.companyType;
            }

            this.businessMappingPriorities = this.authService.getMember().businessMapping?.businessMappingPriorities ?
                this.authService.getMember().businessMapping.businessMappingPriorities
                    .sort((a, b) => {
                        if (+b.priority === +a.priority) {
                            return -1;
                        }
                        return +a.priority > +b.priority ? 1 : -1;
                    }).slice(0) : [];

            if (!this.businessMappingPriorities || !this.businessMappingPriorities.length) {
                this.addNewBusinessMappingPriority();
            }
            this.expandFirstInvalidCollapsedForm();

            this.loading = false;
        }, error => {
            console.error(error);
            this.snackbar.show("An error occured while saving your export challenges form. Please try again or contact an administrator.", 'error');
        });
    }

    getGoal(data) {
        return data?.goal?.length ? this.businessChallengeOptions.find(item => item.value === data.goal).label : null;
    }

    getRegion(data) {
        let region = null;
        if (data?.region?.length) {
            region = this.regionOptions.find(reg => +reg.id === +data.region).name;
            if (data.countries && data.countries.length) {
                region += ' (' + data.countries.length + ')';
            }
        }
        return region;
    }

    getTargets(data) {
        return data?.targets?.length ?
            this.companyTypesOptions.filter(ct => data.targets.indexOf(ct.idcompanytype) !== -1).map(ct => ct.name)
            : null;
    }

    getActivities(data) {
        return data?.activities?.length ?
            this.activitiesOptions.filter(a => data.activities.indexOf(a.id) !== -1).map(a => a.name)
            : null;
    }

    checkFormErrors() {
        if (!this.formSubmitted) {
            return;
        }
        this.scrollTo = null;
        this.formCompleted = true;

        this.errorMarketingFocus = false;
        if (!this.marketingFocus.length) {
            this.errorMarketingFocus = true;
            this.formCompleted = false;
            this.scrollTo = 'marketing_focus';
        }

        for (let i = 0; i < this.businessMappingPriorities.length; i++) {
            if (!this.businessMappingPriorities[i].region.id ||
                !this.businessMappingPriorities[i].companyTypes.length
                || !this.businessMappingPriorities[i].goal) {
                if (!this.scrollTo) {
                    this.scrollTo = 'export_challenge_' + i;
                }
                this.formCompleted = false;
            }
        }
    }

    idBusinessMappingPriorityByPriority(priority: number) {
        return this?.businessMappingPriorities?.find(bmp => +bmp.priority === +priority)?.id;
    }

    submitForm() {
        if (this.formGroup.valid) {
            if (this.formGroup.touched) {
                this.formSubmitted = true;
                if (this.scrollTo) {
                    UtilsService.scrollToElement(document.querySelector('#' + this.scrollTo));
                }
                this.saving = true;
                this.formGroup.value.businessMappingPriorities.forEach(bmp => bmp.countries = bmp.countries.filter(v => v !== null && v !== undefined));
                this.businessMappingService.saveBusinessMappingPriorities(this.formGroup.value).subscribe(data => {
                    this.stepFinished.emit(true);
                    if (this.standalone) {
                        if (this.myNeedlProfileService.member) {
                            // update the rewards score
                            this.myNeedlProfileService.member.company.companyRewardScore = data.company.companyRewardScore;
                        }
                        this.snackbar.show("Your business priorities have been saved.", 'check');
                    }
                    this.authService.getMember().company.registrationStep = data.company.registrationStep;
                    if (!this.authService.getMember().businessMapping || !this.authService.getMember().businessMapping.businessMappingPriorities) {
                        this.authService.user.businessMapping = Object.assign({}, data.businessMapping);
                    } else {
                        this.authService.getMember().businessMapping.businessMappingPriorities = data.businessMapping.businessMappingPriorities.slice(0);
                    }
                    this.authService.user.company.stockAvailability = data.company.stockAvailability;

                    this.saving = false;
                    if (this.standalone && !this.inRewardsPage) {
                        this.router.navigate([this.redirectAfterSubmitRoute]);
                    }
                }, err => {
                    this.saving = false;
                    console.error(err);
                    this.snackbar.show("An error occured while saving your export challenges form. Please try again or contact an administrator.", 'error');
                });
            } else {
                this.stepFinished.emit(true);
                if (this.standalone && !this.inRewardsPage) {
                    this.router.navigate([this.redirectAfterSubmitRoute]);
                }
            }
        } else {
            this.formGroup.markAllAsTouched();
        }
    }

    addGroup(group: FormGroup) {
        this.businessMappingPrioritiesForm.push(group);
    }

    removeGroup(priority: number) {
        let index = this.businessMappingPriorities.findIndex(ec => ec.priority === priority);
        if (this.businessMappingPriorities[index].id) {
            this.businessMappingService.deleteBusinessMappingPriority(this.businessMappingPriorities[index].id).subscribe();
        }
        this.businessMappingPriorities.splice(index, 1);
        index = this.businessMappingPrioritiesForm.value.findIndex(ec => ec.priority === priority);
        this.businessMappingPrioritiesForm.removeAt(index);
        this.businessMappingPriorities.forEach((ec, i) => ec.priority = i + 1);
        this.formGroup.markAsTouched();
    }

    addNewBusinessMappingPriority() {
        if (this.businessMappingPriorities.length < 6) {
            this.businessMappingPriorities.push(new BusinessMappingPriority({
                company: this.authService.getMember().company,
                region: {id: null},
                priority: this.businessMappingPriorities.length + 1
            }));
            this.expandFirstInvalidCollapsedForm();
        }
    }

    expandFirstInvalidCollapsedForm() {
        setTimeout(() => {
            const array = this.businessMappingPrioritiesComponents.toArray();
            for (let i = 0; i < array.length; i++) {
                if (array[i].formGroup.invalid) {
                    if (array[i].formGroup.value?.goal
                        || array[i].formGroup.value?.region
                        || array[i].formGroup.value?.countries?.length
                        || array[i].formGroup.value?.activities?.length
                        || array[i].formGroup.value?.targets?.len
                    ) {
                        array[i].formGroup.markAllAsTouched();
                    }
                    this.collapseComponents.get(i).expanded = true;
                    break;
                }
            }
        }, 500);
    }

    get indexOfFirstInvalidForm() {
        if (!this.businessMappingPrioritiesComponents) {
            return -1;
        }
        const array = this.businessMappingPrioritiesComponents.toArray();
        for (let i = 0; i < array.length; i++) {
            if (array[i].formGroup.invalid) {
                return i;
            }
        }
        return -1;
    }

    updatePriority() {
        this.formGroup.markAsTouched();
    }

    ngAfterViewInit() {
    }
}
