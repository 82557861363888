import {Component, Input, OnInit} from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {BootstrapRowColsNumber} from '../../../core/types';

@Component({
    selector: 'ndl-grid-placeholder',
    templateUrl: './grid-placeholder.component.html',
    styleUrls: ['../../scss/placeholders.scss']
})
export class NdlGridPlaceholderComponent {
    /** Height of each cell */
    @Input() height: string = '150px';

    /** Number of columns */
    @Input() cols: BootstrapRowColsNumber = 5;

    /** Number of cells */
    @Input() cells: number;
    private _caption: boolean;
    get caption(): boolean {
        return this._caption;
    }

    /**
     * If true, adds a caption placeholder at the bottom of each cell
     */
    @Input()
    set caption(value: boolean) {
        this._caption = coerceBooleanProperty(value);
    }

    get rowArray(): null[] {
        return Array(this.cells || this.cols);
    }
}
