<div class="ndl-rating-container ndl-rating-range-{{range}}">
    <ng-container *ngFor="let i of iterator">
        <ng-container *ngTemplateOutlet="type === 'faces' ? faceTemplate : starTemplate; context: {$implicit: i}"></ng-container>
    </ng-container>
</div>

<ng-template #faceTemplate let-index>
    <button mat-icon-button class="ndl-rating-button ndl-rating-face" [class.active]="index+1 === this.value" [ngClass]="'ndl-rating-'+(index+1)" (click)="setValue(index+1)">
        <mat-icon>{{facesIcons[index]}}</mat-icon>
    </button>
</ng-template>

<ng-template #starTemplate let-index>
    <button mat-icon-button class="ndl-rating-button ndl-rating-star" [class.active]="this.value >= index+1" (click)="setValue(index+1)">
        <mat-icon>{{this.value >= index+1 ? 'star' : 'star_border'}}</mat-icon>
    </button>
</ng-template>
