<div class="container-fluid">
    <div class="row row-cols-1 row-cols-md-2" *ngFor="let theme of themes">
        <div class="col">
            <ndl-banner [theme]="theme" closable>
                <span ndl-banner-title>{{theme | titlecase}} with Suffix and Main Icon</span>
                {{content}}
                <mat-icon ndl-banner-icon>info</mat-icon>
                <button ndl-banner-suffix ndl-button>Click me!</button>
            </ndl-banner>
        </div>
        <div class="col">
            <ndl-banner outlined radius closable [theme]="theme">
                <mat-icon ndl-banner-prefix fontSet="material-icons-outlined">info</mat-icon>
                <span ndl-banner-title>{{theme | titlecase}} Outlined With Prefix and Radius</span>
                {{content}}
            </ndl-banner>
        </div>
    </div>
</div>
