import {
    Component, Input, OnInit
} from '@angular/core';
import {NdlTextBaseDirective} from '../input-text-base.directive';

@Component({
    selector: 'ndl-wysiwyg',
    templateUrl: './wysiwyg.component.html',
    styleUrls: ['./wysiwyg.component.scss'],
    host: {
        'class': 'ndl-wysiwyg',
        '(blur)': "onTouched()"
    }
})
export class NdlWysiwygComponent extends NdlTextBaseDirective implements OnInit {
    @Input() config: any = {
        toolbar: [
            'undo', 'redo', '|',
            'heading', '|',
            'bold', 'italic', 'blockQuote', '|',
            'numberedList', 'bulletedList', 'indent', 'outdent', '|',
            'link'
        ],
        heading: {
            options: [
                {model: 'paragraph', view: 'p', title: 'Paragraph', class: ''},
                {model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ndl-h1'},
                {model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ndl-h2'},
                {model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ndl-h3'}
            ]
        }
    };

    ngOnInit() {
        if (this.config) {
            this.config.placeholder = this.placeholder;
        }
    }
}
