import {Component, HostBinding, Inject, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import {NdlSnackbarService} from './snackbar.service';
import {SnackbarData} from './snackbar';
import {NdlLayoutService} from '../layout/layout.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'ndl-snackbar-template',
    templateUrl: './snackbar-template.component.html',
    styleUrls: ['./snackbar-template.component.scss'],
    host: {
        class: 'ndl-snackbar'
    }

})
export class NdlSnackbarTemplateComponent<T = any> implements OnInit, OnDestroy {

    @HostBinding('class.ndl-snackbar-mobile')
    private handsetView: boolean;
    private subscription: Subscription;

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData<T>,
        public snackbarService: NdlSnackbarService,
        private layoutService: NdlLayoutService
    ) {}

    get isContentATemplate() {
        return this.data?.content instanceof TemplateRef;
    }

    get template(): TemplateRef<T> {
        return this.data?.content instanceof TemplateRef ? this.data?.content : null;
    }

    ngOnInit() {
        this.subscription = this.layoutService.Handset$.subscribe(mobile => this.handsetView = mobile);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
