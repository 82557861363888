import {WabelEntity} from "./wabel-entity.abstract";
import {UtilsService} from "../../utils.service";

export class PackagingUnit extends WabelEntity {
    idpackagingunit: number;
    position: number;
    status: string;
    name: string;

    get id(): string {
        return UtilsService.getMachineName(this.name);
    }

    get value(): number {
        return +this.idpackagingunit;
    }
}
