import {WabelEntity} from "./wabel-entity.abstract";
import {Member} from './member';

export class WebRTCResult extends WabelEntity {

    constructor(obj?: any) {
        super(obj);
        if (obj) {
            this.member = obj.member ? new Member(obj.member) : null;
        }
    }

    id: number;
    member: Member;
    score: number;
    logs: string;
    dateCreated: string;
    downloadLogsLink: string;
    isFinished: boolean;
    errors: string;
}
