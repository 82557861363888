import {Component, OnInit} from '@angular/core';
import {NdlAxis} from '../../charts/chart-base.directive';
import {NdlXYDataConfig} from '../../charts/xy-chart.component';
import {NdlStatus} from '../../core/types';

@Component({
  selector: 'ndl-cards-demo',
  templateUrl: './cards-demo.component.html',
})
export class NdlCardsDemoComponent {
    content = "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi doloribus est et impedit iusto nobis tenetur ullam velit! Adipisci aut commodi error eum facilis iste natus nesciunt provident sit unde!";
    themes: NdlStatus[] = ['default', 'primary', 'secondary', 'info', 'success', 'warning', 'danger', 'neutral'];
}
