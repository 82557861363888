import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SearchInputComponent} from './search-input.component';
import {GoogleTagManagerModule} from '../../../directives/gtm/gtm.module';


@NgModule({
    declarations: [
        SearchInputComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        GoogleTagManagerModule
    ],
    exports: [
        SearchInputComponent,
    ]
})
export class SearchInputModule {
}
