import {Company} from "./company";
import {Registration} from './registration';
import {Meeting} from './meeting';
import {WabelEntity} from "./wabel-entity.abstract";

export class MeetingPreference extends WabelEntity {

    constructor(obj?: any) {
        super(obj);
        if (obj) {
            this.company = obj.company ? new Company(obj.company) : null;
            this.registration = obj.registration ? new Registration(obj.registration) : null;
            this.meeting = obj.meeting ? new Meeting(obj.meeting) : null;
        }
    }

    idmeetingpreference: number;
    registration: Registration;
    company: Company;
    status: "selected" | "excluded" | 'removed';
    position: number;
    meeting: Meeting;
    removedReason: string;
    customRemovedReason: string;

    get id(): number {
        return +this.idmeetingpreference;
    }

    set id(id: number) {
        this.idmeetingpreference = id;
    }
}
