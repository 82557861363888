import {Component, Input, OnInit} from '@angular/core';
import {UtilsService} from "../../../services/utils.service";
import {Product} from "../../../services/wabel-client/entities/product";
import {Company} from "../../../services/wabel-client/entities/company";
import {File} from "../../../services/wabel-client/entities/file";

@Component({
    selector: 'card-document',
    templateUrl: './card-document.component.html',
    styleUrls: ['./card-document.component.scss']
})
export class CardDocumentComponent implements OnInit {

    titleLength: number = 35;

    @Input() file: File;
    @Input() product: Product = null;
    @Input() company: Company = null;
    @Input() isImageIcon = false;
    @Input() downloadLink: string;

    constructor(public utilsService: UtilsService) {
    }

    ngOnInit() {
    }
}
