import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NdlPageComponent} from './page.component';
import {MatSidenavModule} from '@angular/material/sidenav';


@NgModule({
    declarations: [
        NdlPageComponent
    ],
    imports: [
        CommonModule,
        MatSidenavModule
    ],
    exports: [
        NdlPageComponent
    ]
})
export class NeedlPageModule {
}
