import {Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy} from '@angular/core';
import {TemplateService} from "../../../services/template.service";
import {AuthService} from "../../../services/auth.service";
import {ImageService} from "../../../services/image.service";

@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselComponent implements OnInit {

    @Input() canOpen: boolean = true;
    @Input() showCarousel: boolean = false;
    @Input() imageUrls: string[];
    @Input() imageUrls123: string[];
    @Input() imageUrls300: string[];
    @Input() imageUrls450: string[];

    @Output() toggleCarousel: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(public templateService: TemplateService, public authService: AuthService, public imageService: ImageService) {
    }

    ngOnInit() {
    }

    closeCarousel() {
        this.showCarousel = false;
        this.toggleCarousel.emit(false);
    }

    openCarousel() {
        this.showCarousel = true;
        this.toggleCarousel.emit(true);
    }
}
