import {WabelEntity} from "./wabel-entity.abstract";

export class ChartResultItem extends WabelEntity {
  constructor(obj: any) {
    super(obj);
  }

  label: string;
  count: number;
  value: string;
  color: string;

  get id(): number {
    return +this.value;
  }

  set id(id: number) {
    this.value = ''+id;
  }
}
