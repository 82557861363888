import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'kit-form-custom-checkbox',
    templateUrl: './kit-form-custom-checkbox.component.html',
    styleUrls: ['./kit-form-custom-checkbox.component.scss']
})
export class KitFormCustomCheckboxComponent implements OnInit {

    @Input() checked: boolean = false;

    constructor() {
    }

    ngOnInit() {
    }


}
