<div class="container-fluid">
    <ng-container *ngFor="let theme of themes">
        <h1>{{theme}}</h1>
        <div class="row mb-2">
            <div class="col d-flex align-items-end">
                <button ndl-button [theme]="theme" class="mr-2">
                    {{((theme | titlecase) + ' Normal')}}
                </button>

                <button ndl-button outlined [theme]="theme" class="mr-2">
                    {{(theme | titlecase) + ' Outlined'}}
                </button>

                <button ndl-button [radius]="false" [theme]="theme" class="mr-2">
                    {{(theme | titlecase) + ' No Radius'}}
                </button>

                <button ndl-button disabled [theme]="theme" class="mr-2">
                    {{((theme | titlecase) + ' Disabled')}}
                </button>

                <button ndl-button large [theme]="theme" class="mr-2">
                    {{((theme | titlecase) + ' Large')}}
                </button>

                <button ndl-button [theme]="theme" class="mr-2">
                    {{((theme | titlecase) + ' With Prefix')}}
                    <mat-icon ndl-button-prefix>chevron_left</mat-icon>
                </button>

                <button ndl-button [theme]="theme" class="mr-2">
                    {{((theme | titlecase) + ' With Suffix')}}
                    <mat-icon ndl-button-suffix>done</mat-icon>
                </button>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col">
                <button ndl-button block [theme]="theme">
                    {{(theme | titlecase) + ' Block Button'}}
                </button>
            </div>
        </div>

        <ndl-divider></ndl-divider>
    </ng-container>
</div>
