import {Component} from '@angular/core';

@Component({
    selector: 'ndl-menu',
    templateUrl: './menu.component.html',
    host: {
        "class": "ndl-menu"
    }
})
export class NdlMenuComponent {
}
