import {
    Component, ElementRef, Input, OnDestroy, EventEmitter, Optional, QueryList, Self, Output
} from '@angular/core';
import {
    ControlValueAccessor, NgControl
} from '@angular/forms';
import {MatFormFieldControl} from '@angular/material/form-field';
import {Subject} from 'rxjs';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {NdlOptionDirective} from '../option.directive';
import {NdlInputFieldBaseDirective} from '../input-field-base.directive';
@Component({
    selector: 'ndl-radio-field',
    templateUrl: './radio-field.component.html',
    providers: [
        {provide: MatFormFieldControl, useExisting: NdlRadioFieldComponent}
    ],
    host: {
        id: `ndl-radio-field-${NdlRadioFieldComponent.nextId}`
    }
})
export class NdlRadioFieldComponent<T = any> extends NdlInputFieldBaseDirective<T> {
    @Input() options: QueryList<NdlOptionDirective>;

    static nextId = 0;

    controlType = "ndl-radio-field";
    id = "ndl-radio-field-" + NdlRadioFieldComponent.nextId++;

    @Input('aria-describedby') userAriaDescribedBy: string;

    // MatFormFieldControl
    setDescribedByIds(ids: string[]) {
        const controlElement = this._elementRef.nativeElement
            .querySelector('.ndl-radio-field');
        controlElement.setAttribute('aria-describedby', ids.join(' '));
    }

    onContainerClick(event: MouseEvent) {
    }
}
