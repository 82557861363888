import { Member } from "./member";
import { MemberThirdPartyOAuthReffrence } from "./member_third_party_oauth_reffrence";
import {WabelEntity} from "./wabel-entity.abstract";

export class MemberThirdPartyCalendar extends WabelEntity {
    idmemberthirdpartycalendar: number;
    thirdPartyToken: MemberThirdPartyOAuthReffrence;
    member: Member
    provider: string
    calendarId: string
    calendarName: string
    isSelected: boolean
    updatedAt: string
    createdAt: string

    constructor(obj: any) {
        if (obj !== null) {
            super(obj);
            this.idmemberthirdpartycalendar = obj.id
            this.member = obj.member ? new Member( obj.member ): null
            this.thirdPartyToken = obj.thirdPartyToken ? new MemberThirdPartyOAuthReffrence( obj.thirdPartyToken ): null
        }
    }

    get id(): number {
        return this.idmemberthirdpartycalendar;
    }

    set id(id: number) {

    }
}
