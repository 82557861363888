import {Component, ContentChild, Input, OnInit, ViewChild} from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {BootstrapThemeColors} from '../../../core/types';
import {NdlAlertTitleDirective} from '../../directives/alert-title.directive';

@Component({
  selector: 'ndl-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class NdlAlertComponent implements OnInit {

    protected _dismissable: boolean;
    // @Input() set dismissable(square) {
    //     this._dismissable = coerceBooleanProperty(square);
    // };
    get dismissable(): boolean {
        return this._dismissable;
    };

    protected _theme: BootstrapThemeColors = 'success';
    // @Input() set theme(theme) {
    //     this._theme = theme;
    // };
    get theme(): BootstrapThemeColors {
        return this._theme;
    }

    /**
     * Status color of the alert
     */
    protected _status: "success" | "error" | "warning" = 'success';
    @Input() set status(status: "success" | "error" | "warning") {
        switch (status) {
            case 'error':
                this._theme = 'danger';
                break;
            case 'warning':
                this._theme = 'warning';
                break;
            default:
                this._theme = 'success';
                break;
        }
    };

    @ContentChild(NdlAlertTitleDirective, {static: false}) title: NdlAlertTitleDirective;

    dismiss() {

    }

    constructor() { }

    ngOnInit() {
    }

}
