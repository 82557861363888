<div class="ndl-card-status-outside" *ngIf="statusOutside">
    <ng-content select="[ndl-card-status-outside],[ndlCardStatusOutside]"></ng-content>
</div>

<div class="ndl-card-content" [ngClass]="contentClasses">

    <div *ngIf="options?.length">
        <button class="ndl-card-menu" mat-icon-button [matMenuTriggerFor]="cardMenu" (click)="$event.stopPropagation();">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #cardMenu="matMenu">
            <ng-content select="ndl-card-option,[ndl-card-option],[ndlCardOption]"></ng-content>
        </mat-menu>
    </div>

    <ng-content></ng-content>
</div>

<ng-container *ngIf="footer">
    <mat-divider class="ndl-card-footer-divider"></mat-divider>
    <ng-content select="[ndl-card-footer],[ndlCardFooter],ndl-card-footer"></ng-content>
</ng-container>
