<div class="ndl-slider" [class.simple-design]="keepSimpleDesign">
    <div class="ndl-slider__labels" *ngIf="labelLeft || labelRight">
        <span>{{labelLeft}}</span>
        <span>{{labelRight}}</span>
    </div>
    <div class="ndl-slider__content">
        <span class="ndl-slider__content__percent" *ngIf="showPercentage">{{matslider.value}}%</span>
        <mat-slider [min]="minValue" [max]="maxValue" #matslider [value]="defaultValue" (change)="updateValue($event)"
                    [step]="step"></mat-slider>
        <span class="ndl-slider__content__percent" *ngIf="showPercentage">{{maxValue - matslider.value}}%</span>
    </div>
</div>
