import {WabelEntity} from "./wabel-entity.abstract";

export class CompanyPolicyOption extends WabelEntity {
    constructor(obj: any) {
        super(obj);
        if(obj) {
            if (obj.name) this.name = obj.name;
            if (obj.idcompanytag) this.id = obj.idcompanytag;
        }
    }

    id: string;
    name: string;
}


