import {Component} from '@angular/core';
import {NdlStatus} from '../../core/types';

@Component({
    selector: 'ndl-rating-demo',
    templateUrl: './rating-demo.component.html'
})
export class NdlRatingDemoComponent {
    rating = 0;
}
