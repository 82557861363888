import {
    AfterViewInit,
    Component, Input, OnChanges, OnInit, SimpleChanges
} from '@angular/core';
import {NdlChartBaseDirective, NdlAxis} from './chart-base.directive';
import {NDL_GREEN_INDEX, NdlColors} from '../core/color';
import {
    CandlestickSeries,
    CategoryAxis,
    ColumnSeries, ColumnSeries3D, ConeSeries, CurvedColumnSeries,
    DateAxis,
    DurationAxis, LineSeries, OHLCSeries, PieChart, PieSeries, StepLineSeries,
    ValueAxis
} from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4plugins_sliceGrouper from "@amcharts/amcharts4/plugins/sliceGrouper";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

@Component({
    selector: 'ndl-pie-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.scss'],
    host: {
        'class': 'ndl-xy-chart'
    }
})
export class NdlPieChartComponent extends NdlChartBaseDirective<PieChart> implements OnInit, OnChanges {
    type = PieChart;
    @Input() valueParam: string;
    @Input() categoryParam: string;

    ngOnInit() {
        const palette = NdlColors.getColorPalette(NDL_GREEN_INDEX, true);
        this.data.forEach((data, index) => {
            data['slice_color'] = am4core.color(palette[index % palette.length]);
        });
    }

    setConfig() {
        if (!this.valueParam) {
            throw new Error("NdlPieChart: A 'valueParam' needs to be set in order to extract the value from the data collection.");
        }
        if (!this.categoryParam) {
            throw new Error("NdlPieChart: A 'categoryParam' needs to be set in order to extract the name of each segment from the data collection.");
        }

        this.chart.radius = am4core.percent(50);
        const pieSeries = this.chart.series.push(new PieSeries());
        pieSeries.dataFields.value = this.valueParam;
        pieSeries.dataFields.category = this.categoryParam;
        pieSeries.slices.template.propertyFields.fill = "slice_color";
        pieSeries.labels.template.fontSize = 10;
        pieSeries.labels.template.paddingTop = 0;
        pieSeries.labels.template.paddingBottom = 0;
        const grouper = pieSeries.plugins.push(new am4plugins_sliceGrouper.SliceGrouper());
        grouper.zoomOutButton.dy = -35;
        grouper.limit = 10;
        grouper.groupProperties.fill = am4core.color("green");
        grouper.groupName = "See more";
        grouper.clickBehavior = "break";
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['data']) {
            const palette = NdlColors.getColorPalette(NDL_GREEN_INDEX, true);
            this.data.forEach((data, index) => {
                data['slice_color'] = am4core.color(palette[index % palette.length]);
            });
        }
    }

    reloadSeries(): void {}
}
