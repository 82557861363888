import { WabelEntity } from "./wabel-entity.abstract";
import { VideoMeetingInvitationSlots } from "./video_meeting_invitation_slots";
import { PrivateMessage } from "./private_message";
import { VideoMeetingColleague } from "./video_meeting_colleague";
import { Member } from "./member";

export class VideoMeetingInvitations extends WabelEntity {
    constructor(obj: any) {
        super(obj);

        if (obj) {
            this.videoMeetingColleagues = obj.videoMeetingColleagues && obj.videoMeetingColleagues.items ? obj.videoMeetingColleagues.items.map((colleague: any) => new VideoMeetingColleague(colleague)) : [];
            this.videoMeetingInvitationSlots = obj.videoMeetingInvitationSlots && obj.videoMeetingInvitationSlots.items ? obj.videoMeetingInvitationSlots.items.map((slots: any) => new VideoMeetingInvitationSlots(slots)) : [];
            this.message = obj.message ? new PrivateMessage(obj.message) : null;
            this.createdBy = obj.createdBy ? new Member(obj.createdBy) : null;
            this.createdFor = obj.createdFor ? new Member(obj.createdFor) : null;
        }
    }

    idvideomeetinginvitation: number;
    invitationStatus: string;
    invitationSubject: string;
    invitationMessage: string;
    message: PrivateMessage;
    createdBy: Member;
    createdFor: Member;
    videoMeetingInvitationSlots: VideoMeetingInvitationSlots[];
    videoMeetingColleagues: VideoMeetingColleague[];
    get id(): number {
        return this.idvideomeetinginvitation;
    }

    set id(id: number) {
        this.idvideomeetinginvitation = id;
    }
}
