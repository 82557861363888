import {OnDestroy, ViewChild} from '@angular/core';
import {Component, OnInit} from '@angular/core';
import { AuthService } from '../../../../services/auth.service';
import {SignupService} from "../../../../services/wabel-client/services/signup.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TemplateService} from "../../../../services/template.service";
import {NdlSnackbarComponent} from 'needl-lib';
import {NdlSnackbarService} from 'needl-lib';

@Component({
    selector: 'logged-registration',
    templateUrl: './logged-registration.component.html',
    styleUrls: ['./logged-registration.component.scss']
})
export class LoggedRegistrationComponent implements OnInit, OnDestroy {
    companyInfoFinished = false;
    companyPositioningFinished = false;
    companyBusinessMappingPrioritiesFinished = false;
    currentStepIndex = 0;

    @ViewChild('stepper') stepper: any;


    constructor(private authService: AuthService, public router: Router, private templateService: TemplateService, private route: ActivatedRoute, private snackbar: NdlSnackbarService) {

    }

    ngOnInit() {
        this.templateService.isDisplayFooter = false;
        this.templateService.displayProfileWarnings = false;
        this.templateService.isDisplayNavBarMenu = false;
        if (!this.authService.getMember().company.registrationStep || this.authService.getMember().company.registrationStep === SignupService.REGISTRATION_FINISHED) {
            this.router.navigate(['/home']);
        }
        switch (this.authService.getMember().company.registrationStep) {
            // company visuals step is deleted now, but keep this case
            // for old companies that are stuck at this step
            case SignupService.COMPANY_VISUALS:
            case SignupService.COMPANY_POSITIONING:
                this.companyInfoFinished = true;
                this.currentStepIndex = 1;
                break;
            case SignupService.COMPANY_EXPORT_CHALLENGES:
                this.companyInfoFinished = true;
                this.companyPositioningFinished = true;
                this.currentStepIndex = 2;
                break;
        }
    }


    ngAfterViewInit() {
        this.stepper.selectedIndex = this.currentStepIndex;
    }

    redirectAfterFinish() {
        const destination = this.route.snapshot.queryParamMap.get('destination');
        this.snackbar.show('Congratulations, your account has been created!', 'check');
        if (destination) {
            if (destination.includes('app/')) {
                this._localRedirect(destination.replace('app/', ''));
            } else {
                window.location.href = destination;
            }
        } else {
            this._localRedirect();
        }
    }

    private _localRedirect(destination = '/home') {
        this.router.navigate(['/home']);
    }

    ngOnDestroy() {
        this.templateService.displayProfileWarnings = true;
        if (this.authService.getMember().company.registrationStep && this.authService.getMember().company.registrationStep !== SignupService.REGISTRATION_FINISHED) {
            this.router.navigate(['/sign-up/manufacturer/company-info']);
        }
    }
}
