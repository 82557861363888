import {WabelEntity} from "./wabel-entity.abstract";
import {Member} from "./member";
import {VideoMeetingInvitations} from "./video_meeting_invitations";

export class VideoMeetingColleague extends WabelEntity {
    idVideoMeetingColleague: number;
    colleagueIdmember: Member;
    videoMeeting: VideoMeetingInvitations;
    invitedFromIdmember: Member;
    firstName: string;
    lastName: string;
    createdAt: string;
    joinedMeetingAt: string;
    type: string;
    nameInitials: string;

    constructor(obj: any) {
        super(obj);

        if (obj) {
            this.colleagueIdmember = obj.colleagueIdmember ? new Member(obj.colleagueIdmember) : null;
            this.invitedFromIdmember = obj.invitedFromIdmember ? new Member(obj.invitedFromIdmember) : null;
            this.videoMeeting = obj.videoMeeting ? new VideoMeetingInvitations(obj.videoMeeting) : null;
        }
    }


    get id(): number {
        return this.idVideoMeetingColleague;
    }

    set id(id: number) {
        this.idVideoMeetingColleague = id;
    }

}
