import {Injectable} from "@angular/core";
import {WabelService} from "./wabel-service.abstract";
import {GraphQLService} from "./graphql-service";
import {LoadingService} from "./loading.service";

import {SourcingPriority} from "../entities/sourcing_priority";
import {SourcingPriorityGraphql} from "../graphql/sourcing_priority.graphql";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class SourcingPriorityService extends WabelService {

    constructor(protected graphQLService: GraphQLService, protected loadingService: LoadingService) {
        super(graphQLService, loadingService);
    }

  toArrayOfObjects(objects: any[]): SourcingPriority[] {
    return objects.map((sourcingPriority: any) => new SourcingPriority(sourcingPriority));
  }

  toObject(object: any): SourcingPriority {
    return new SourcingPriority(object);
  }

  getSourcingPriorities() {
    return this.query(SourcingPriorityGraphql.queries.mySourcingPriorities, {})
      .pipe(map((data) => {
        return data.data ? this.toArrayOfObjects(data.data.me.sourcingPriorities) as SourcingPriority[] : null
      }));
  }

  getHumanTypeName(type: string): string {
    switch (type) {
      case 'category':
        return 'Categories';
      case 'tag':
        return 'Product tags';
      case 'country':
        return 'Countries';
      default:
        return 'Categories';
    }
  }

  typeToIcon(type: string): string {
    switch (type) {
      case "category":
        return 'local_offer';
      case "tag":
        return 'star';
      case "country":
        return 'language';
      default:
        return 'local_offer';
    }
  }

  getOptionsByType(type: string, list) {
    if (list.length)
      return list.filter((value) => {
        return value.type === type;
      });
  }

  saveSourcingPriorities(sourcingPriorities: SourcingPriority[]) {
    return this.mutation(SourcingPriorityGraphql.mutations.saveSourcingPriorities, {
      sourcing_priorities: JSON.stringify(sourcingPriorities)
    })
      .pipe(map((data) => data.data.saveSourcingPriorities));
  }
}
