import {WabelEntity} from "./wabel-entity.abstract";
import {FilePreviewableInterface} from "./file-previewable.interface";
import {File} from "./file";
import {Company} from "./company";

export class CompanyFile extends WabelEntity implements FilePreviewableInterface {
    constructor(obj: any) {
        super(obj);
        if (obj) {
            this.company = obj.company ? new Company(obj.company) : null;
            this.file = obj.file ? new File(obj.file) : null;
        }
    }

    file: File;
    company: Company;
    fileType: string;
    downloadLink: string;
    imageUrl: string;
    imageUrl123: string;
    imageUrl300: string;
    imageUrl450: string;

    get id(): string {
        return this.file.fileName;
    }

    get dataBase64(): string {
        return this.file.fileName;
    }

    previewExtension(): string {
        return this.file.fileExtension;
    }

    previewFileName(): string {
        return this.file.fileName;
    }

    previewFileSize(): number {
        return this.file.fileSize;
    }

    previewId(): string {
        return "" + this.file.idfile;
    }

    previewSelected(): boolean {
        return false;
    }

    previewUrl(): string {
        return this.imageUrl ? this.imageUrl : this.downloadLink;
    }
}
