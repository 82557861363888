import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NdlThemeService {
    private _baseColor: string = null;
    private _themeColor: string = null;
    setThemeColor(color: string) {
        this._themeColor = color;
        if (!this._baseColor) {
            this._baseColor = document.documentElement.style.getPropertyValue('--ndl-primary');
        }
        document.documentElement.style.setProperty('--ndl-primary', this._themeColor);
    }

    private _baseHoverColor: string = null;
    private _themeHoverColor: string = null;
    setThemeHoverColor(color: string) {
        this._themeHoverColor = color;
        if (!this._baseHoverColor) {
            this._baseHoverColor = document.documentElement.style.getPropertyValue('--ndl-primary-hover');
        }
        document.documentElement.style.setProperty('--ndl-primary-hover', this._themeHoverColor);
    }

    reset() {
        if (this._baseColor) {
            document.documentElement.style.setProperty('--ndl-primary', this._baseColor);
        }
        if (this._baseHoverColor) {
            document.documentElement.style.setProperty('--ndl-primary-hover', this._baseHoverColor);
        }
        this._baseColor = null;
        this._baseHoverColor = null;
    }

    get baseColor() {
        return this._baseColor;
    }

    get themeColor() {
        return this._themeColor;
    }

    get baseHoverColor() {
        return this._baseHoverColor;
    }

    get themeHoverColor() {
        return this._themeHoverColor;
    }
}
