import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {UtilsService} from "../../../../services/utils.service";
import {ResourceService} from "../../../../services/wabel-client/services/resource.service";
import {FileToUpload} from "../../../../services/wabel-client/entities/fileToUpload";

@Component({
    selector: 'kit-form-attachment',
    templateUrl: './kit-form-attachment.component.html',
    styleUrls: ['./kit-form-attachment.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => KitFormAttachmentComponent),
        multi: true
    }]
})
export class KitFormAttachmentComponent implements OnInit {

    @Input() filesToUpload: FileToUpload[] = [];
    @Input() acceptedFormatsFile: string[] = ['PDF', 'DOC', 'XLS', 'PPT', 'DOCX', 'XLSX', 'PPTX'];
    @Input() acceptedFormatsImage: string[] = ['PNG', 'JPG', 'GIF'];
    @Input() uploadMaxFilesSize = 32000000;

    @Output() errorReceived: EventEmitter<string> = new EventEmitter<string>();

    constructor(public utilsService: UtilsService, public resourceService: ResourceService) {
    }

    ngOnInit() {
        this.utilsService.loadMemoryLimit();
    }

    filesToUploadChanged(event: Event) {
        if (event.target && event.target['files']) {
            const files: FileList = event.target['files'];

            if (this.totalUploadSizeIsAccepted(files)) {
                this.checkAndAddFilesToUploadList(files);
            } else {
                this.errorReceived.emit('The total size of your files is above the maximum allowed.');
            }
        }
    }

    private totalUploadSizeIsAccepted(files: FileList): boolean {
        const size = this.getTotalSize(files);
        if (size > this.uploadMaxFilesSize) {
            this.errorReceived.emit('The total size of your files is above the maximum allowed.');
            return false;
        }
        return true;
    }

    private getTotalSize(files: FileList): number {
        let value = 0;
        for (const indexFile in files) {
            if (typeof files[indexFile] !== 'object' || files[indexFile]['constructor']['name'] !== 'File') {
                continue;
            }
            value += files[+indexFile].size;
        }
        return value;
    }

    private checkAndAddFilesToUploadList(files: FileList): void {
        for (const indexFile in files) {
            if (typeof files[indexFile] !== 'object' || files[indexFile]['constructor']['name'] !== 'File') {
                continue;
            }
            const newFileToUpload = new FileToUpload();
            newFileToUpload.setFileName(files[indexFile].name);
            newFileToUpload.setFileSize(files[indexFile].size);
            newFileToUpload.setExtension(files[indexFile].name.split('.').pop());

            const fileWithSameName = this.filesToUpload.find((f: FileToUpload) => f.previewFileName() === files[indexFile].name);
            if (fileWithSameName) {
                this.errorReceived.emit('A file with the name ' + files[indexFile].name + ' already exists');
                continue;
            }

            newFileToUpload.file = files[indexFile];
            this.filesToUpload.push(newFileToUpload);
            const lastIndexFile = this.filesToUpload.lastIndexOf(newFileToUpload);

            const reader = new FileReader();
            reader.onload = (e: any) => {
                if (!this.isFile(newFileToUpload.previewExtension())) {
                    const memoryLimit = this.utilsService.getMemoryLimit();
                    const img = new Image;
                    const that = this;
                    img.onload = function () {
                        if (memoryLimit && 4 * img.width * img.height > memoryLimit) {
                            that.filesToUpload.splice(lastIndexFile, 1);
                            that.errorReceived.emit('The file size of ' + img.name + ' is too big');
                        } else {
                            that.filesToUpload[lastIndexFile].setImageBase64(that.utilsService.resizeImageBase64(img, 123, 123));
                            that.uploadFile(that.filesToUpload[lastIndexFile]);
                        }
                    };
                    img.src = e.target.result;
                } else {
                    this.uploadFile(this.filesToUpload[lastIndexFile]);
                }

            };

            if (this.extensionIsAllowed(newFileToUpload.previewExtension())) {
                reader.readAsDataURL(files[indexFile]);
            } else {
                this.filesToUpload.splice(lastIndexFile, 1);
                this.errorReceived.emit('The type of the file ' + files[indexFile].name + ' is not supported');
            }
        }
    }

    private extensionIsAllowed(extension: string): boolean {
        return (
            this.acceptedFormatsFile.map((value) => value.toLowerCase()).includes(extension.toLowerCase()) ||
            this.acceptedFormatsImage.map((value) => value.toLowerCase()).includes(extension.toLowerCase())
        );
    }

    private uploadFile(file: FileToUpload) {
        if (!file.isUploading() && !file.isUploaded()) {
            this.errorReceived.emit(null);
            file.uploading = true;
            file.uploaded = false;
            file.requestUpload = this.resourceService.uploadFile(file.file).subscribe(
                (uploadedFile: FileToUpload) => {
                    file.uploading = false;
                    file.uploaded = true;
                    if (uploadedFile.success) {
                        file.success = true;
                        file.path = uploadedFile.path;
                    } else {
                        file.success = false;
                    }
                },
                (error) => {
                    file.uploaded = true;
                    file.uploading = false;
                    file.success = false;
                });
        }
    }

    private isFile(extension: string): boolean {
        return this.acceptedFormatsFile.map((value) => value.toLowerCase()).includes(extension.toLowerCase());
    }
}
