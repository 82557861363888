import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatStepperModule} from '@angular/material/stepper';
import {NdlStepperComponent} from './stepper/stepper.component';
import {NdlStepComponent} from './step/step.component';
import {NdlStepNextDirective} from './step/step-next.directive';
import {NdlStepPreviousDirective} from './step/step-previous.directive';
import {NdlStepHeaderComponent} from './step/step-header/step-header.component';
import {MatRippleModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatDividerModule} from '@angular/material/divider';
import {PortalModule} from '@angular/cdk/portal';
import {NdlStepContentDirective} from './step/step-content.directive';
import {MatSidenavModule} from '@angular/material/sidenav';
import {NdlStepperNewComponent} from "./stepper-new/stepper.component";
import {NdlStepNewComponent} from "./stepper-new/step.component";
import {RouterModule} from "@angular/router";
import {NdlStepNewPreviousDirective} from './stepper-new/step-previous.directive';
import {NdlStepNewNextDirective} from './stepper-new/step-next.directive';
import {NdlStepperPrefixNewDirective} from "./stepper-new/stepper-prefix.directive";
import {NdlStepperPrefixDirective} from './stepper/stepper-prefix.directive';

@NgModule({
    declarations: [
        NdlStepperComponent,
        NdlStepperNewComponent,
        NdlStepComponent,
        NdlStepNewComponent,
        NdlStepNextDirective,
        NdlStepPreviousDirective,
        NdlStepHeaderComponent,
        NdlStepContentDirective,
        NdlStepNewPreviousDirective,
        NdlStepNewNextDirective,
        NdlStepperPrefixDirective,
        NdlStepperPrefixNewDirective
    ],
    exports: [
        NdlStepperComponent,
        NdlStepperNewComponent,
        NdlStepComponent,
        NdlStepNewComponent,
        NdlStepNextDirective,
        NdlStepPreviousDirective,
        NdlStepHeaderComponent,
        NdlStepContentDirective,
        NdlStepNewPreviousDirective,
        NdlStepNewNextDirective,
        NdlStepperPrefixDirective,
        NdlStepperPrefixNewDirective
    ],
    imports: [
        CommonModule,
        RouterModule,
        PortalModule,
        MatStepperModule,
        MatRippleModule,
        MatIconModule,
        MatListModule,
        MatDividerModule,
        MatSidenavModule,
        MatRippleModule
    ]
})
export class NeedlStepperModule {
}
