import {Injectable, InjectionToken} from '@angular/core';
import {Observable} from 'rxjs';

export interface FileInterface {
    filePath: string;
    fileName: string;
    fileExtension?: string;
    fileSize?: number;
    url?: string;
    file?: File;
    status?: FileStatus;
    error?: Error;
    success: boolean;
}

export enum FileStatus {
    PENDING,
    UPLOADING,
    ONLINE
}

export function fileToFileInterface(file: File): FileInterface {
    return {
        fileName: file.name,
        filePath: null,
        fileExtension: file.type,
        status: FileStatus.PENDING,
        file: file,
        success: false
    };
}

export abstract class NdlUploader {
    abstract uploadFile(file: File): Observable<FileInterface>;
}
