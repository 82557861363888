import {WabelEntity} from "./wabel-entity.abstract";
import {Member} from "./member";
import {SourcingRequest} from "./sourcing_request";

export class SourcingRequestMemberResponse extends WabelEntity {
    constructor(obj: any) {
        super(obj);

        if (obj) {
            this.sourcingRequest = obj.sourcingRequest ? new SourcingRequest(obj.sourcingRequest) : null;
            this.member = obj.member ? new Member(obj.member) : null;
        }
    }

    sourcingRequest: SourcingRequest;
    member: Member;
    status: string;
    responseDate: string;
    notInterestedStatusReason: string;
    statusCustomReason: string;
    isNew: boolean;

    get id(): string {
        return '0';
    }

    set id(id: string) {}
}
