import {WabelEntity} from "./wabel-entity.abstract";
import {CompanyRelationshipStatus} from "./company_relationship_status";

export class CompanyRelationshipTagReference extends WabelEntity {
  constructor(obj: any) {
    super(obj);
    if (obj) {
      this.status = obj.status ? new CompanyRelationshipStatus(obj.status) : null;
    }
  }

  idtagreference: number;
  name: string;
  tagGroup: string;
  status: CompanyRelationshipStatus;

  get id(): number {
    return this.idtagreference;
  }

  set id(id: number) {
    this.idtagreference = id;
  }
}
