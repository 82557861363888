import {AfterViewInit, Component, EventEmitter, HostBinding, Inject, Input, Output, TemplateRef} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'ndl-modal-template',
    templateUrl: './modal-template.component.html',
    host: {
        class: 'ndl-modal-template'
    }
})
export class NdlModalTemplateComponent<T = any> {
    constructor(
        public dialogRef: MatDialogRef<NdlModalTemplateComponent<T>>,
        @Inject(MAT_DIALOG_DATA) public data: NdlModalConfig<T>
    ) {
    }
}

interface NdlModalConfig<T> {
    title?: string;
    icon?: string;
    content: TemplateRef<any>
    closable: boolean,
    context?: T;
}
