import {Injectable} from '@angular/core';
import {OrderByList, PaginatorList, SearchService} from './search.service';
import {FilterService} from './filter.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from "rxjs";
import {compressToEncodedURIComponent, decompressFromEncodedURIComponent} from 'lz-string';
import {IUrlGenerator} from "./wabel-client/interfaces/iurlgenerator";
import {Location} from '@angular/common';


interface SearchState {
    filter?: any;
    paginator?: PaginatorList;
    searchTerm?: string;
    supplierProductDisplayInList?: boolean;
    searchCurrentTab?: string;
    orderByList?: OrderByList;
    isBuyerVision: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class UrlGeneratorService implements IUrlGenerator {

    searchState: SearchState;
    // Observable string sources
    _searchStateObs = new Subject();
    // Observable string streams
    searchState$ = this._searchStateObs.asObservable();


    constructor(
        private searchService: SearchService,
        private filterService: FilterService,
        private router: Router,
        private route: ActivatedRoute,
        private location: Location
    ) {

        this.filterService.urlgeneratorService = this;
    }

    URLEncode(soft: boolean = false) {
        const urlEncoded = compressToEncodedURIComponent(JSON.stringify(this.searchState));
        if (!soft) {
            this.router.navigate(['/search'], {queryParams: {s: urlEncoded}});
        } else {
            this.location.go('/search', 's=' + urlEncoded);
        }
    }

    updateCurrentSearchStateFromSearchService() {
        this.searchState = {
            paginator: this.searchService.paginator,
            searchTerm: this.searchService.searchTerm,
            supplierProductDisplayInList: this.searchService.supplierProductDisplayInList,
            searchCurrentTab: this.searchService.searchCurrentTab,
            orderByList: this.searchService.orderByList,
            filter: this.filterService.selectedFilter,
            isBuyerVision: this.filterService.isBuyerVision
        };
    }


    updateSearchState(updateSearch: boolean = true,  signal: string = "all", soft: boolean = false) {
        this.updateCurrentSearchStateFromSearchService();
        this.URLEncode(soft);

        if (updateSearch) {
            this._searchStateObs.next(signal);
        }

    }

    getEncodedUrlFromCurrentTabAndCurrentVision(currentTab: string, isBuyerVision: boolean, categoryName?: string, depth: number = null) {
        this.searchService.searchCurrentTab = currentTab;
        this.filterService.isBuyerVision = isBuyerVision;
        this.filterService.selectedFilter = [];

        if (categoryName) {
            this.filterService.addCategoryToSelectedFilter(categoryName, depth);
        }

        this.updateCurrentSearchStateFromSearchService();
        return compressToEncodedURIComponent(JSON.stringify(this.searchState));
    }

    refresh(signal: string) {
        this._searchStateObs.next(signal);
    }


    fillServiceFromURL(updateSearch: boolean = true, signal: string = "all") {
        try {
            const term = this.route.snapshot.queryParamMap.get('term');
            if (!term) {
                const param = this.route.snapshot.queryParamMap.get('s');
                if (!param) {
                    this.updateSearchState(updateSearch);
                } else {

                    this.searchState = JSON.parse(decompressFromEncodedURIComponent(param));
                    if (this.searchState.paginator) {
                        this.searchService.paginator = this.searchState.paginator;
                    }

                    if (this.searchState.searchTerm) {
                        this.searchService.searchTerm = this.searchState.searchTerm;
                    }

                    if (this.searchState.supplierProductDisplayInList) {
                        this.searchService.supplierProductDisplayInList = this.searchState.supplierProductDisplayInList;
                    }

                    if (this.searchState.searchCurrentTab) {
                        this.searchService.searchCurrentTab = this.searchState.searchCurrentTab;
                    }

                    if (this.searchState.orderByList) {
                        this.searchService.orderByList = this.searchState.orderByList;
                    }

                    if (this.searchState.filter) {
                        this.filterService.selectedFilter = this.searchState.filter;
                    }

                    if (this.searchState.isBuyerVision) {
                        this.filterService.isBuyerVision = this.searchState.isBuyerVision;
                    }


                    if (updateSearch) {
                        this._searchStateObs.next(signal);
                    }
                }
            } else {
                this.updateSearchState(updateSearch);
                this.searchService.searchTerm = term;
                this.searchState.searchTerm = term;
                if (updateSearch) {
                    this._searchStateObs.next(signal);
                }
            }

        } catch (e) {
            this.updateSearchState(updateSearch);
        }

    }

}
