import {WabelEntity} from "./wabel-entity.abstract";
import {Member} from "./member";
import {PrivateMessageThread} from "./private_message_thread";
import {VideoMeetingInvitations} from "./video_meeting_invitations";
import {File} from "./file";
import {Company} from './company';
import { CompanyProfileSubmission } from "./company_profile_submission";
import {Event} from "./event";
import {SourcingRequestRecommendation} from "./sourcing_request_recommendation";

export class PrivateMessage extends WabelEntity {
    constructor(obj: any) {
        super(obj);

        if (obj) {
            this.member = obj.member ? new Member(obj.member) : null;
            this.thread = obj.thread ? new PrivateMessageThread(obj.thread) : null;
            this.files = obj.files && obj.files.length > 0 ? obj.files.map(file => new File(file)) : [];
            this.videoMeetingInvitations = obj.videoMeetingInvitations && obj.videoMeetingInvitations.items ? obj.videoMeetingInvitations.items.map((invitation : any) => new VideoMeetingInvitations(invitation)) : [];
            this.companyProfileSubmissions = obj.companyProfileSubmissions && obj.companyProfileSubmissions.items ? obj.companyProfileSubmissions.items.map((proposal : any) => new CompanyProfileSubmission(proposal)) : [];
            this.certainEvent = obj.certainEvent ? new Event(obj.certainEvent) : null;
            this.sourcingRequestRecommendation = obj.sourcingRequestRecommendation ? new SourcingRequestRecommendation(obj.sourcingRequestRecommendation) : null;
        }
    }

    idprivatemessage: number;
    member: Member;
    company: Company;
    message_type: string;
    body: string;
    createdAt: string;
    thread: PrivateMessageThread;
    videoMeetingInvitations: VideoMeetingInvitations[];
    companyProfileSubmissions: CompanyProfileSubmission[];
    files: File[];
    messageType: string;
    certainEvent: Event;
    sourcingRequestRecommendation: SourcingRequestRecommendation;

    get id(): number {
        return this.idprivatemessage;
    }

    set id(id: number) {
        this.idprivatemessage = id;
    }
}
