import {Component} from '@angular/core';
import {SearchService} from '../../../../services/search.service';
import {NavigationEnd, Router} from "@angular/router";
import {UrlGeneratorService} from "../../../../services/urlgenerator.service";
import {AuthService} from "../../../../services/auth.service";
import {UtilsService} from "../../../../services/utils.service";
import {TemplateService} from "../../../../services/template.service";
import {Suggestion} from "../../../../services/wabel-client/entities/suggestion";
import {FilterService} from "../../../../services/filter.service";
import {environment} from "../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Company} from "../../../../services/wabel-client/entities/company";
import {NeedlSecurityService} from "@wabel/needl-security";
import {ResourceService} from "../../../../services/wabel-client/services/resource.service";
import {RIGHT} from "../../../../services/wabel-client/security/right.const";
import {Institution} from "../../../../services/wabel-client/entities/institution";
import {Subscription} from "rxjs";
import {debounceTime, take} from "rxjs/operators";

@Component({
    selector: 'app-search-suggestion',
    templateUrl: './search-suggestion.component.html',
    styleUrls: ['./search-suggestion.component.scss']
})
export class SearchSuggestionComponent {

    suggestAreaState: string = 'hidden';
    totalCount: number;
    suggestionsCount: number;
    buyerOrder: string = '2';
    supplierOrder: string = '1';

    buyersSuggestions = null;
    sellersSuggestions = null;
    productsSuggestions = null;
    institutionsSuggestions = null;

    lastRequestSuggestions$: Subscription;
    lastRequestSellers$: Subscription;
    lastRequestBuyers$: Subscription;
    lastRequestInstitutions$: Subscription;

    productsSuggestionsLoaded = false;
    sellersSuggestionsLoaded = false;
    buyersSuggestionsLoaded = false;
    institutionsSuggestionsLoaded = false;

    readonly RIGHT = RIGHT;


    constructor(public utilsService: UtilsService,
                public searchService: SearchService,
                private router: Router,
                private urlGenerator: UrlGeneratorService,
                public authService: AuthService,
                private templateService: TemplateService,
                private _http: HttpClient,
                public filterService: FilterService,
                public needlSecurityService: NeedlSecurityService,
                private resourceService: ResourceService) {
        router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.templateService.unblockScroll();
                if (window.location.pathname !== '/search') {
                    this.resetAutoComplete();
                }
            }
        });
        this.templateService.unblockScroll();
        let firstStart = true;
        this.searchService.suggestionComponent = this;
        this.searchService.searchTerm$.pipe(debounceTime(300)).subscribe(
            () => {
                if (firstStart) {
                    this.searchService.lastSearch = this.searchService.searchTerm;
                    firstStart = false;
                }
                this.runSearch();
            });

        this.urlGenerator.searchState$.subscribe(data => {
            this.suggestAreaState = 'hidden';
            this.searchService.lastSearch = this.searchService.searchTerm;
            this.resetLastRequests();
        });
    }

    runSearch() {
        if (this.filterService.isBuyerVision) {
           this.supplierOrder = '1';
           this.buyerOrder = '2';
        } else {
            this.supplierOrder = '2';
            this.buyerOrder = '1';
        }
        if (this.searchService.searchTerm !== this.searchService.lastSearch) {
            this.resetSuggestionData();
            this.totalCount = 0;
            this.suggestionsCount = 0;

            if (
                this.searchService.searchTerm.length <= 2 ||
                (this.needlSecurityService.hasRight(RIGHT.CAN_SEARCH_SUPPLIERS) && !this.searchService.buyerHasEventParticipationsThisYear)
            ) {
                this.suggestAreaState = 'hidden';
                this.searchService.hideSuggestions = true;
                this.templateService.unblockScroll();
            } else {
                this.templateService.blockScroll();
                this.suggestAreaState = 'loading';
                this.searchService.hideSuggestions = false;
                this.searchService.lastSearch = this.searchService.searchTerm;

                this.productsSuggestionsLoaded = false;
                this.sellersSuggestionsLoaded = false;
                this.buyersSuggestionsLoaded = false;
                this.institutionsSuggestionsLoaded = false;

                if (this.needlSecurityService.hasRight(RIGHT.CAN_SEARCH_SUPPLIERS)) {
                    this.searchForSuppliers();
                }

                if (this.needlSecurityService.hasRight(RIGHT.CAN_SEARCH_BUYERS)) {
                    this.searchForBuyers();
                }
            }
        }
    }

    private resetSuggestionData() {
        this.buyersSuggestions = null;
        this.sellersSuggestions = null;
        this.productsSuggestions = null;
        this.institutionsSuggestions = null;
    }

    suggestionDataNull() {
        return !this.buyersSuggestions && !this.sellersSuggestions && !this.productsSuggestions && !this.institutionsSuggestions;
    }

    allFinished() {
        return (this.needlSecurityService.hasRight(RIGHT.CAN_SEARCH_SUPPLIERS) && this.needlSecurityService.hasRight(RIGHT.CAN_SEARCH_BUYERS)
                && this.productsSuggestionsLoaded && this.sellersSuggestionsLoaded && this.buyersSuggestionsLoaded && this.institutionsSuggestionsLoaded)
            || (this.needlSecurityService.hasRight(RIGHT.CAN_SEARCH_SUPPLIERS) && !this.needlSecurityService.hasRight(RIGHT.CAN_SEARCH_BUYERS)
                && this.productsSuggestionsLoaded && this.sellersSuggestionsLoaded && this.institutionsSuggestionsLoaded)
            || (!this.needlSecurityService.hasRight(RIGHT.CAN_SEARCH_SUPPLIERS) && this.needlSecurityService.hasRight(RIGHT.CAN_SEARCH_BUYERS)
                && this.buyersSuggestionsLoaded);
    }

    searchForSuppliers() {
        this.lastRequestSuggestions$ = this._http.get(environment.needl_back_end_url + '/search_suggestions?limit=10&type=products&searchValue=' + this.searchService.searchTerm, {
            withCredentials: true,
            headers: {
                'ngsw-bypass': 'true'
            }
        }).pipe(debounceTime(500), take(2)).subscribe((data) => {
            this.productsSuggestions = (data as any)['suggestions'].map(s => new Suggestion(s));
            this.suggestionsCount = this.productsSuggestions.length;
            this.productsSuggestionsLoaded = true;

            if (this.productsSuggestions.length > 0 && this.suggestAreaState === 'loading') {
                this.suggestAreaState = 'active';
            }
        });

        this.lastRequestSellers$ = this._http.get(environment.needl_back_end_url + '/search_suggestions?type=sellers&searchValue=' + this.searchService.searchTerm, {
            withCredentials: true,
            headers: {
                'ngsw-bypass': 'true'
            }
        }).pipe(debounceTime(500), take(2)).subscribe((data) => {
            this.sellersSuggestions = (data as any)['companies'].map(c => new Company(c));
            this.totalCount += this.sellersSuggestions.length;
            this.sellersSuggestionsLoaded = true;
            this.limitResultIfCanSearchBoth();

            if (this.sellersSuggestions.length > 0 && this.suggestAreaState === 'loading') {
                this.suggestAreaState = 'active';
            }
        });

        this.lastRequestInstitutions$ = this._http.get(environment.needl_back_end_url + '/search_suggestions?type=institutions&searchValue=' + this.searchService.searchTerm, {
            withCredentials: true,
            headers: {
                'ngsw-bypass': 'true'
            }
        }).pipe(debounceTime(500), take(2)).subscribe((data) => {
            this.institutionsSuggestions = (data as any)['institutions'].map(c => new Institution(c));
            this.totalCount += this.institutionsSuggestions.length;
            this.institutionsSuggestionsLoaded = true;

            if (this.institutionsSuggestions.length > 0 && this.suggestAreaState === 'loading') {
                this.suggestAreaState = 'active';
            }
        });
    }

    searchForBuyers() {
        this.buyersSuggestions = [];
        this.lastRequestBuyers$ = this._http.get(environment.needl_back_end_url + '/search_suggestions?type=buyers&searchValue=' + this.searchService.searchTerm, {
            withCredentials: true,
            headers: {
                'ngsw-bypass': 'true'
            }
        }).pipe(debounceTime(500), take(2)).subscribe((data) => {
            this.buyersSuggestions = (data as any)['companies'].map(c => new Company(c));
            this.totalCount += this.buyersSuggestions.length;
            this.buyersSuggestionsLoaded = true;
            this.limitResultIfCanSearchBoth();

            if (this.buyersSuggestions.length > 0 && this.suggestAreaState === 'loading') {
                this.suggestAreaState = 'active';
            }
        });
    }

    limitResultIfCanSearchBoth() {
        if (this.sellersSuggestions !== null && this.sellersSuggestions.length > 3
            && this.buyersSuggestions !== null && this.buyersSuggestions.length > 3) {
            this.sellersSuggestions = this.sellersSuggestions.slice(0, 3);
            this.buyersSuggestions = this.buyersSuggestions.slice(0, 3);
        }
    }


    selectSuggest(suggestion: Suggestion) {
        this.searchService.lastSearch = suggestion.name;
        this.searchService.searchTerm = suggestion.name;
        this.filterService.selectedFilter = [];
        this.filterService.isBuyerVision = true;
        this.searchService.searchCurrentTab = 'suppliers';
        this.filterService.addCategoryToSelectedFilter(suggestion.category.name, suggestion.depth);
        this.launchSearch(true);
    }

    resetAutoComplete() {
        this.suggestAreaState = 'hidden';
        this.searchService.searchTerm = "";
        this.resetLastRequests();
        this.resetSuggestionData();
    }

    changeSearchState(state: string) {
        this.suggestAreaState = state;
    }

    launchSearch(fromSuggestion: boolean = false) {
        this.resetLastRequests();
        const type = fromSuggestion ? ResourceService.SEARCH_QUERY_SUGGESTION : ResourceService.SEARCH_QUERY;
        this.resourceService.storeAlgoliaLogActivity(type, this.searchService.searchTerm).subscribe();
        if (typeof this.urlGenerator.searchState !== 'undefined') {
            this.urlGenerator.updateSearchState();
            this.searchService.lastSearch = this.searchService.searchTerm;
        }
        if (typeof this.urlGenerator.searchState === 'undefined') {
            this.urlGenerator.updateSearchState();
        }
        this.suggestAreaState = 'hidden';

    }

    resetLastRequests() {
        if (this.lastRequestSellers$) {
            this.lastRequestSellers$.unsubscribe();
        }
        if (this.lastRequestBuyers$) {
            this.lastRequestBuyers$.unsubscribe();
        }
        if (this.lastRequestSuggestions$) {
            this.lastRequestSuggestions$.unsubscribe();
        }
        if (this.lastRequestInstitutions$) {
            this.lastRequestInstitutions$.unsubscribe();
        }
    }

    close() {
        this.templateService.unblockScroll();
        this.suggestAreaState = 'hidden';
    }
}
