import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {VideoMeetingService} from "../../../../services/wabel-client/services/video_meeting.service";
import {Company} from "../../../../services/wabel-client/entities/company";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'app-video-meeting-request-modal-step-duration',
    templateUrl: './video-meeting-request-modal-step-duration.component.html',
    styleUrls: ['./video-meeting-request-modal-step-duration.component.scss']
})

export class VideoMeetingRequestModalStepDurationComponent implements OnInit {
    @Input() targetCompany: Company = null;
    @Input() duration: number;

    @Output() stepCompleted: EventEmitter<number> = new EventEmitter();

    durationForm: FormGroup;
    durationOptions = [
        {value: 10, label: '10 minutes'},
        {value: 15, label: '15 minutes'},
        {value: 20, label: '20 minutes'},
        {value: 30, label: '30 minutes'},
        {value: 45, label: '45 minutes'},
        {value: 60, label: '1 hour'},
        {value: 75, label: '1 hour 15 minutes'},
        {value: 90, label: '1 hour 30 minutes'},
        {value: 105, label: '1 hour 45 minutes'},
        {value: 120, label: '2 hours'}
    ];

    constructor(private videoMeetingService: VideoMeetingService,
                private fb: FormBuilder) {}

    ngOnInit() {
        this.durationForm = this.fb.group({
            duration: this.fb.control(this.duration, Validators.required)
        });
    }

    validForm(value) {
        this.durationForm.markAllAsTouched();
        if (this.durationForm.invalid) {
            return;
        }
        this.duration = value.duration;
        this.stepCompleted.emit(this.duration);
    }
}
