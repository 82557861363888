import {
    Component,
    OnInit,
    ChangeDetectorRef,
    ChangeDetectionStrategy, ViewChild, OnDestroy
} from '@angular/core';
import {TemplateService} from "../../../services/template.service";
import {Title} from "@angular/platform-browser";
import {ActivatedRoute} from "@angular/router";
import {SignupComponent} from "../../../pages/signup/signup/signup.component";

@Component({
    selector: 'sign-up-page',
    templateUrl: './sign-up-page.component.html',
    styleUrls: ['./sign-up-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    host: {'(document:click)': 'onDocumentClick($event)'},
})
export class SignUpPageComponent implements OnInit, OnDestroy {

    @ViewChild(SignupComponent, { static: true }) signupComponent: SignupComponent;
    destination: string;
    companyType: string = '';

    constructor(private templateService: TemplateService, private cd: ChangeDetectorRef, private titleService: Title, private route: ActivatedRoute)
    {

    }

    ngOnDestroy() {
        this.templateService.isDisplayFooter = true;
    }

    ngOnInit() {
        this.templateService.isDisplayFooter = false;
        this.titleService.setTitle('Registration | needl.');
    }

    onDocumentClick(event) {
        this.signupComponent.onDocumentClick(event);
    }

    redirectAfterSignUp() {
        this.route.queryParams.subscribe(value => {
            this.destination = value.destination ? value.destination : null;
        });
        if (this.destination === null) {
            this.destination = '/profile/user';
        }
        window.location.href = this.destination.replaceAll('//', '/');
    }
}
