<li [class]="isBanner ? 'ndl-'+banner : ''">
    <ng-container *ngIf="!disabled; else disabledTemplate">
        <a *ngIf="!external; else externalTemplate" [routerLink]="link" [routerLinkActive]="link ? 'ndl-menu-item-active' : ''" [class.ndl-menu-item-active]="forceActive" [class.ndl-menu-item-with-children]="hasSubmenu" (click)="hasSubmenu ? showSubmenu = !showSubmenu : null">
            <ng-container *ngTemplateOutlet="content"></ng-container>
            <mat-icon *ngIf="hasSubmenu" class="submenu-dropdown">{{showSubmenu ? 'arrow_drop_up' : 'arrow_drop_down'}}</mat-icon>
        </a>
        <ng-template #externalTemplate>
            <a [href]="link">
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </a>
        </ng-template>
        <ng-content select="[ndl-submenu]" *ngIf="showSubmenu"></ng-content>
    </ng-container>

    <ng-template #disabledTemplate>
        <a class="ndl-menu-item-disabled">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </a>
    </ng-template>
</li>

<ng-template #content>
    <ng-content></ng-content>
</ng-template>
