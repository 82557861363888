import gql from "graphql-tag";

export class FavoriteGraphql {
    static fragments = {};

    static queries = {};

    static mutations = {
        toggleFavoriteCompany: gql`
            mutation toggleFavoriteCompany($idcompany: Int!) {
                toggleFavoriteCompany(idcompany: $idcompany) {
                    items {
                        idfavorite
                        company {
                            idcompany
                            logoUrl(size:"123")
                        }
                        userproduct {
                            iduserproduct
                        }
                    }
                }
            }
        `,
        toggleFavoriteProduct: gql`
            mutation toggleFavoriteProduct($idproduct: Int!) {
                toggleFavoriteProduct(idproduct: $idproduct) {
                    items {
                        idfavorite
                        company {
                            idcompany
                            logoUrl(size:"123")
                        }
                        userproduct {
                            iduserproduct
                        }
                    }
                }
            }
        `,
        saveFavoritesUserProduct: gql`
            mutation saveFavoritesUserProduct($userproductid: Int!) {
                saveFavorites(userproductIds: [$userproductid]) {
                    items {
                        idfavorite
                        company {
                            idcompany
                            logoUrl
                        }
                        userproduct {
                            iduserproduct
                        }
                    }
                }
            }
        `,

        saveFavoritesCompany: gql`
            mutation saveFavoritesCompany($companyID: Int!) {
                saveFavorites(companyIds: [$companyID]) {
                    items {
                        idfavorite
                        company {
                            idcompany
                            logoUrl
                        }
                        userproduct {
                            iduserproduct
                        }
                    }
                }
            }
        `,
    };
}
