import {Directive, TemplateRef} from '@angular/core';

@Directive({
    selector: '[ndlCustomTabHeader], [ndl-custom-tab-header]',
    host: {
        class: 'ndl-custom-tab-header'
    },
})
export class NdlCustomTabHeader {


    constructor(public content: TemplateRef<any>) {
    }
}
