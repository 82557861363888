import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {VideoMeetingService} from "../../../services/wabel-client/services/video_meeting.service";
import {Company} from "../../../services/wabel-client/entities/company";
import {Event} from "../../../services/wabel-client/entities/event";
import {CompanyService} from "../../../services/wabel-client/services/company.service";
import {NdlStepperNewComponent} from "needl-lib";

@Component({
    selector: 'app-video-meeting-request-modal',
    templateUrl: './video-meeting-request-modal.component.html',
    styleUrls: ['./video-meeting-request-modal.component.scss']
})

export class VideoMeetingRequestModalComponent implements OnInit {
    @ViewChild("stepper", {static: false, read: NdlStepperNewComponent}) stepper: NdlStepperNewComponent;

    @Output() videoMeetingRequestSent = new EventEmitter();
    @Output() closeModal = new EventEmitter();
    @Input() targetCompanyIds: number[] = [];
    @Input() event: Event = null;
    bannerClosed = false;

    targetCompany: Company = null;
    duration = 30;
    currentStep: 'duration'|'availabilities'|'confirmation' = 'duration';

    constructor(private videoMeetingService: VideoMeetingService,
                private companyService: CompanyService) {}

    ngOnInit() {
        if (this.targetCompanyIds.length > 1) {
            return;
        }
        this.companyService.getInformationForVideoMeetingRequestModal(this.targetCompanyIds[0]).subscribe((company) => {
            if (!this.event) {
                this.targetCompany = company;
            }
        }, (e) => {
            console.error(e);
        });
    }

    goToSecondStep(duration: number) {
        this.duration = duration;
        this.stepper.selected.completed = true;
        this.stepper.next();
        this.currentStep = 'availabilities';
    }

    save() {
        this.videoMeetingService.saveVideoMeetingRequest(this.targetCompanyIds, this.duration, this.event?.eventCode).subscribe((vmr) => {
            this.currentStep = 'confirmation';
            this.videoMeetingRequestSent.emit();
        }, (e) => {
            console.error(e);
        });
    }
}
