import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Company} from "../../../services/wabel-client/entities/company";
import {CompanyService} from "../../../services/wabel-client/services/company.service";
import {CompanyProfileSubmission} from "../../../services/wabel-client/entities/company_profile_submission";
import {UtilsService} from "../../../services/utils.service";
import {CompanyProfileSubmissionService} from "../../../services/wabel-client/services/company-profile-submission.service";
import {NdlSnackbarService} from 'needl-lib';

@Component({
    selector: 'company-old-submissions-recap-modal',
    templateUrl: './company-old-submissions-recap-modal.component.html',
    styleUrls: ['./company-old-submissions-recap-modal.component.scss']
})
export class CompanyOldSubmissionsRecapModalComponent implements OnInit {

    @Input() buyerCompany: Company;
    oldSubmissionsByBuyerCompany: CompanyProfileSubmission[];
    @Output() oldSubmissionsModalClosed = new EventEmitter();
    tooltipVar: boolean[] = [];
    actionLoading: boolean[] = [];
    selectedSubmission: CompanyProfileSubmission = null;
    loading = false;
    loadingError = null;
    draftSubmissionsExist = true;
    modalDeletionOpen = false;

    constructor(private companyService: CompanyService,
                public utilsService: UtilsService,
                private companyProfileSubmissionService: CompanyProfileSubmissionService,
                private snackbar: NdlSnackbarService
    ) {
    }

    ngOnInit() {
        this.getOldSubmissionsByBuyerCompany();
    }


    getOldSubmissionsByBuyerCompany() {
        this.loading = true;
        this.companyService.getMyCompanyProfileSubmissionsWithBuyerCompany(this.buyerCompany.id).subscribe(
            (data) => {
                if (!data) {
                    return;
                }
                this.oldSubmissionsByBuyerCompany = data;
                this.loading = false;
                if (this.oldSubmissionsByBuyerCompany.length === 0) {
                    this.oldSubmissionsModalClosed.emit(true);
                } else {
                    this.checkDraftsIfExist();
                }
            },
            error => {
                this.loading = false;
                this.loadingError = this.utilsService.detectErrorMessages(error).join('. ');
            });

    }

    closePopUp() {
        this.oldSubmissionsModalClosed.emit(false);
    }

    openSubmissionModal() {
        this.oldSubmissionsModalClosed.emit(true);
    }

    openCompleteSubmissionModal(submissionId: number) {
        const submissionToComplete = this.oldSubmissionsByBuyerCompany.find(x => x.idcompanyprofilesubmission === submissionId);
        if (submissionToComplete.createdForCompany !== null) {
            this.selectedSubmission = submissionToComplete;
        }
    }

    submissionModalClosed() {
        this.selectedSubmission = null;
    }

    deleteSubmission() {
        this.modalDeletionOpen = false;
        this.actionLoading[this.selectedSubmission.idcompanyprofilesubmission] = true;
        return this.companyProfileSubmissionService.deleteCompanyProfileSubmission(this.selectedSubmission).subscribe(
            success => {
                this.oldSubmissionsByBuyerCompany = this.oldSubmissionsByBuyerCompany
                    .filter(item => item.idcompanyprofilesubmission !== this.selectedSubmission.idcompanyprofilesubmission);
                this.checkDraftsIfExist();
                this.selectedSubmission = null;
                if (this.oldSubmissionsByBuyerCompany.length === 0) {
                    this.closePopUp();
                    this.snackbar.show('Your company profile submission has been deleted.', 'check');
                }
            },
            error => {
                this.selectedSubmission = null;
            }
        );
    }

    checkDraftsIfExist() {
        this.draftSubmissionsExist = this.oldSubmissionsByBuyerCompany
            .filter(submission => submission.profileStatus === 'draft').length > 0;
    }
}
