import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import * as dayjs from 'dayjs';
import * as isBetween from 'dayjs/plugin/isBetween';
import {Company} from "../../../../services/wabel-client/entities/company";
import {
    VideoMeetingAvailabilitiesComponent
} from "./video-meeting-availabilities/video-meeting-availabilities.component";
import {UtilsService} from "../../../../services/utils.service";
import {CalendarEvent} from "angular-calendar";

@Component({
    selector: 'app-video-meeting-request-modal-step-availabilities',
    templateUrl: './video-meeting-request-modal-step-availabilities.component.html',
    styleUrls: ['./video-meeting-request-modal-step-availabilities.component.scss']
})

export class VideoMeetingRequestModalStepAvailabilitiesComponent implements OnInit {

    @Input() targetCompany: Company = null;
    @Input() targetCompaniesCount = 1;
    @Input() duration: number;

    baseSlot = 3;

    @Output() stepCompleted: EventEmitter<any> = new EventEmitter();
    @Output() bannerClosed = new EventEmitter<any>();
    bannerIsClosed = false;

    @ViewChild("videoMeetingAvailabilities") videoMeetingAvailabilities: VideoMeetingAvailabilitiesComponent;

    loading = false;
    errorMessage: string;

    constructor(private utilsService: UtilsService) {}

    ngOnInit() {
        dayjs.extend(isBetween);
        this.baseSlot = this.baseSlot * this.targetCompaniesCount;
    }

    sendMeetingProposition() {
        this.errorMessage = null;
        // Checks
        if (this.videoMeetingAvailabilities?.availabilities?.length < 1) {
            this.errorMessage = 'Please add some availabilities.';
            this.loading = false;
            return;
        } else if (this.getTotalDurationOfAvailabilities(this.videoMeetingAvailabilities?.availabilities?.filter(availability => (dayjs(availability.end).diff(availability.start, 'minute') >= this.duration))) < (this.duration * this.baseSlot)) {
            this.errorMessage = 'Please add at least ' + this.baseSlot + ' timeslots of ' + this.utilsService.getReadableDuration(this.duration) + ' or more.';
            this.loading = false;
            return;
        } else if (this.videoMeetingAvailabilities?.availabilities?.filter(availability => (dayjs(availability.end).diff(availability.start, 'minute') < this.duration)).length > 0) {
            if (!window.confirm('Some of your availabilities do not match the duration of ' + this.utilsService.getReadableDuration(this.duration) + ' that you selected. Only matching availabilities will be proposed. Do you want to send your proposition anyway ?')) {
                this.loading = false;
                return;
            }
        }
        this.loading = true;
        this.stepCompleted.emit();
    }

    getTotalDurationOfAvailabilities(availabilities: CalendarEvent[]): number {
        let total = 0;
        for (const availability of availabilities) {
            total += dayjs(availability.end).diff(availability.start, 'minute');
        }
        return total;
    }
}
