import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {map} from "rxjs/operators";
import {NdlRequestOptions} from "needl-lib";
import {VideoMeetingService} from "../../../services/wabel-client/services/video_meeting.service";
import {Title} from "@angular/platform-browser";
import {VideoMeetingRequest} from "../../../services/wabel-client/entities/video_meeting_request";
import {LiveVideoMeetingService} from "../../../services/wabel-client/services/live_video_meeting.service";
import { RIGHT } from '../../../services/wabel-client/security/right.const';
import {CatalogFilterComponent} from "../../event-module/event/event-catalog/catalog-filter/catalog-filter.component";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {AuthService} from "../../../services/auth.service";

@Component({
    selector: 'app-video-meetings-page',
    templateUrl: './video-meetings-page.component.html',
    styleUrls: ['./video-meetings-page.component.scss']
})
export class VideoMeetingsPageComponent implements OnInit {
    loading = false;
    videoMeetingRequests: VideoMeetingRequest[] = [];
    videoMeetingRequestsTotalCount: number = null;
    selectedIndex = 0;
    readonly RIGHT = RIGHT;

    form: FormGroup;

    constructor(private videoMeetingService: VideoMeetingService,
                private titleService: Title,
                public liveVideoMeetingService: LiveVideoMeetingService,
                private fb: FormBuilder,
                public authService: AuthService) {
    }

    ngOnInit() {
        this.titleService.setTitle('Video meetings | needl.');
        this.form = this.fb.group({
            filters: this.fb.array([this.fb.group({'participants': 'any_member'})])
        });
    }

    formFilters(): FormArray {
        return this.form.get('filters') as FormArray;
    }

    getVideoMeetingRequestsList = (options: NdlRequestOptions) => {
        this.loading = true;
        return this.videoMeetingService.getMyVideoMeetingRequests(options.pagination?.pageSize, options.pagination?.pageIndex * options.pagination?.pageSize, options.filters?.filters[0]?.participants).pipe(map((data) => {
            this.videoMeetingRequests = data.items;
            this.videoMeetingRequestsTotalCount = data.count;
            this.loading = false;
            return this.videoMeetingRequests;
        }));
    }
}
