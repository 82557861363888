import {Directive, TemplateRef} from '@angular/core';

@Directive({
    selector: '[ndlCustomTabContent], [ndl-custom-tab-content]',
    host: {
        class: 'ndl-custom-tab-content'
    },
})
export class NdlCustomTabContent {


    constructor(public content: TemplateRef<any>) {
    }
}
