import {Component, Input, OnInit} from '@angular/core';
import {Company} from "../../../services/wabel-client/entities/company";
import {Tag} from "../../../services/wabel-client/entities/tag";

@Component({
    selector: 'app-company-business-info',
    templateUrl: './company-business-info.component.html',
    styleUrls: ['./company-business-info.component.scss']
})
export class CompanyBusinessInfoComponent {
    @Input() tags: Tag[];
    @Input() loading = false;
}
