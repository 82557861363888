import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../../../../services/auth.service';
import {TagTypeService} from "../../../../../../services/wabel-client/services/tag_type.service";
import {SignupService} from "../../../../../../services/wabel-client/services/signup.service";
import {CacheService} from "../../../../../../services/cache.service";
import {UtilsService} from '../../../../../../services/utils.service';

@Component({
    selector: 'registration-company-info',
    templateUrl: './registration-company-info.component.html',
    styleUrls: ['./registration-company-info.component.scss']
})
export class RegistrationCompanyInfoComponent implements OnInit, OnDestroy {
    errorTurnover = false;
    errorYear = false;
    isYearEstablished = false;
    errorCurrency = false;

    currencyOptions = [];
    turnoverOptions = [];

    turnover: number;
    year: number;
    currency: number;

    currencySubscription$: Subscription;
    turnoverSubscription$: Subscription;
    formSubmitted = false;
    formCompleted = false;
    scrollTo = null;
    saving = false;


    @Output() stepFinished: EventEmitter<any> = new EventEmitter();

    constructor(public authService: AuthService,
                private tagTypeService: TagTypeService,
                private signupService: SignupService,
                private cacheService: CacheService) {

    }

    ngOnInit() {
        this.currencySubscription$ = this.tagTypeService.getTagTypeByInternalName(TagTypeService.CURRENCY_INTERNAL_NAME).subscribe(data => {
            this.currencyOptions = data.tags.map(tag => ({value: tag.id, label: tag.name}));
        }, error => {
            console.error(error);
        });
        this.turnoverSubscription$ = this.cacheService.getCompanyTurnover().subscribe(data => {
            this.turnoverOptions = data.tags.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)).map(tag => ({value: tag.id, label: tag.name}));
        });
    }

    ngOnDestroy() {
        if (this.currencySubscription$) {
            this.currencySubscription$.unsubscribe();
        }
        if (this.turnoverSubscription$) {
            this.turnoverSubscription$.unsubscribe();
        }
    }

    isYearEstablishedValid(event) {
        this.isYearEstablished = event;
        this.checkFormErrors();
    }

    checkFormErrors() {
        if (!this.formSubmitted) {
            return;
        }
        this.formCompleted = true;

        this.errorTurnover = false;
        if (!this.turnover) {
            this.errorTurnover = true;
            this.scrollTo = 'turnover';
            this.formCompleted = false;
        }

        this.errorCurrency = false;
        if (!this.currency) {
            this.errorCurrency = true;
            this.scrollTo = this.scrollTo ? this.scrollTo : 'currency';
            this.formCompleted = false;
        }

        this.errorYear = false;
        if (!this.year || !this.isYearEstablished) {
            this.errorYear = true;
            this.scrollTo = this.scrollTo ? this.scrollTo : 'year';
            this.formCompleted = false;
        }
    }

    submitForm() {
        this.formSubmitted = true;
        this.checkFormErrors();
        if (this.scrollTo) {
            UtilsService.scrollToElement(document.querySelector('#' + this.scrollTo));
        }
        if (!this.formCompleted) {
            return;
        }
        this.saving = true;
        this.signupService.saveCompanyInformation(this.turnover, this.currency, this.year).subscribe(data => {
            this.authService.getMember().company.yearEstablished = this.year;
            this.authService.getMember().company.registrationStep = data.registrationStep;
            this.stepFinished.emit();
            this.saving = false;
        }, err => {
            this.saving = false;
            console.error(err);
        });
    }
}
