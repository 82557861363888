import gql from "graphql-tag";
import {TagTypeService} from "../services/tag_type.service";
import {TagTypeGraphql} from "./tag_type.graphql";
import {Tag} from "../entities/tag";

export class ProductGraphQL {
    static fragments = {
        product: gql`
            fragment product on Userproduct {
                iduserproduct
                name
                description
                innovation
                logo {
                    imageUrl(size: "123"),
                    imageUrl450: imageUrl(size: "450"),
                }
                url
                urlname
                similarProductsFromSameCompany {
                    iduserproduct
                    name
                    logo {
                        imageUrl(size: "123")
                    }
                    url
                    urlname
                    company {
                        idcompany
                        name
                        urlname
                    }
                    category {
                        ...fullTag
                    }
                }
                similarProductsFromOtherCompanies {
                    iduserproduct
                    name
                    logo {
                        imageUrl(size: "123")
                    }
                    url
                    urlname
                    company {
                        idcompany
                        name
                        urlname
                    }
                    category {
                        ...fullTag
                    }
                }
                company {
                    idcompany
                    name
                    urlname
                    website
                    logoUrl(size: "123")
                    membership
                    isFavoriteForCurrentMember
                    allMembers {
                        idmember
                        status
                        fullname
                    }
                    citylocation {
                        city,
                        formattedAddress,
                        country {
                            id
                            tagLocation {
                                code
                            },
                            name
                        },
                        longitude,
                        latitude
                    }
                    products(published: $published) {
                        iduserproduct
                        urlname,
                        url
                        name
                        published,
                        logo {
                            imageUrl(size: "123")
                        }
                        category {
                            ...fullTag
                        }
                    }
                }
                manufacturingCountry {
                    id
                    name
                    tagLocation {
                        code
                    }
                }
                composition
                category {
                    ...fullTag
                }
                readyForChina
                pricePosition: tags(tagTypeInternalName: "${TagTypeService.PRICE_RANGE_INTERNAL_NAME}") {
                    items {
                        ...tag
                    }
                }
                packagingformats {
                    idpackagingformat
                    value
                    packagingunit {
                        idpackagingunit
                        status
                        position
                    }
                    price
                }
                targetConsumers: tags(tagTypeInternalName: "${TagTypeService.TARGET_CONSUMER_INTERNAL_NAME}") {
                    items {
                        ...tag
                    }
                },
                rangeOfProducts: tags(tagTypeInternalName: "${TagTypeService.RANGE_OF_PRODUCT_INTERNAL_NAME}") {
                    items {
                        ...tag
                    }
                },
                nutritionalInformations: tags(tagTypeInternalName: "${TagTypeService.NUTRITIONAL_INFORMATION_INTERNAL_NAME}") {
                    items {
                        ...tag
                    }
                },
                qualityLabels: tags(tagTypeInternalName: "${TagTypeService.QUALITY_LABEL_INTERNAL_NAME}") {
                    items {
                        ...tag
                    }
                },
                exportCertifications: tags(tagTypeInternalName: "${TagTypeService.EXPORT_CERTIFICATION_INTERNAL_NAME}") {
                    items {
                        ...tag
                    }
                },
                sustainability: tags(tagTypeInternalName: "${TagTypeService.SUSTAINABILITY_INTERNAL_NAME}") {
                    items {
                        ...tag
                    }
                },
                healthAndWellness: tags(tagTypeInternalName: "${TagTypeService.HEALTH_AND_WELLNESS_INTERNAL_NAME}") {
                    items {
                        ...tag
                    }
                },
                consumptionMode: tags(tagTypeInternalName: "${TagTypeService.CONSUMPTION_MODE_INTERNAL_NAME}") {
                    items {
                        ...tag
                    }
                },
                dietary: tags(tagTypeInternalName: "${TagTypeService.DIETARY_INTERNAL_NAME}") {
                    items {
                        ...tag
                    }
                },
                temperatureControlled: tags(tagTypeInternalName: "${TagTypeService.TEMPERATURE_CONTROLLED_INTERNAL_NAME}") {
                    items {
                        ...tag
                    }
                },
                userproductsImages {
                    file {
                        idfile
                    }
                    fileType
                    imageUrl,
                    imageUrl450: imageUrl(size: "450")
                }
                userproductsFiles {
                    file {
                        idfile
                        fileName
                        fileSize
                        fileExtension
                    }
                    downloadLink(forceDirectDownload: false)
                }
                countryOrigin {
                    id
                    name,
                    tagLocation {
                        code
                    }
                }
                packaging,
                published,
                activities: tags(tagTypeInternalName: "${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
                    items {
                        ...tag
                    }
                }
            }
            ${TagTypeGraphql.fragments.tag}
            ${TagTypeGraphql.fragments.fullTag}
        `,
        productCard: gql`
            fragment productCard on Userproduct {
                iduserproduct,
                name,
                description,
                url,
                urlPath,
                urlname,
                targetConsumers: tags(tagTypeInternalName: "${TagTypeService.TARGET_CONSUMER_INTERNAL_NAME}") {
                    items {
                        ...tag
                    }
                },
                rangeOfProducts: tags(tagTypeInternalName: "${TagTypeService.RANGE_OF_PRODUCT_INTERNAL_NAME}") {
                    items {
                        ...tag
                    }
                },
                nutritionalInformations: tags(tagTypeInternalName: "${TagTypeService.NUTRITIONAL_INFORMATION_INTERNAL_NAME}") {
                    items {
                        ...tag
                    }
                },
                qualityLabels: tags(tagTypeInternalName: "${TagTypeService.QUALITY_LABEL_INTERNAL_NAME}") {
                    items {
                        ...tag
                    }
                },
                exportCertifications: tags(tagTypeInternalName: "${TagTypeService.EXPORT_CERTIFICATION_INTERNAL_NAME}") {
                    items {
                        ...tag
                    }
                },
               category {
                   ...fullTag
                },
                manufacturingCountry {
                    id
                    name
                    tagLocation {
                        code
                    }
                }
                logoUrl(size: "300"),
                company {
                    ca {
                        ...tag
                    },
                    isWabelClubMember,
                    name,
                    urlname,
                    idcompany,
                    logoUrl(size: "123")
                }
            }
            ${TagTypeGraphql.fragments.tag}
            ${TagTypeGraphql.fragments.fullTag}
        `
    };

    static queries = {

        findById: gql`
            query Product($iduserproduct: Int!, $published: Boolean) {
                product(idproduct: $iduserproduct) {
                    ...product
                    category {
                        parent {
                            children {
                                items {
                                    name
                                    id
                                }
                            }
                        }
                    }
                }
            }
            ${ProductGraphQL.fragments.product}
        `,

        findOneForEdition: gql`
            query findOneProductForEdition($iduserproduct: Int!) {
                product(idproduct: $iduserproduct) {
                    iduserproduct
                    name
                    description
                    published
                    innovation
                    url
                    composition
                    isPackaging
                    packaging
                    shelfLife
                    company {
                        idcompany
                    }
                    manufacturingCountry {
                        id
                    }
                    countryOrigin {
                        id
                    }
                    category {
                        id
                    }
                    packagingformats {
                        idpackagingformat
                        value
                        packagingunit {
                            idpackagingunit
                        }
                        price
                        name
                        description
                    }
                    logo {
                        file {
                            fileName
                            fileSize
                            fileExtension
                            filePath
                            url
                        }
                    }
                    userproductsImages {
                        file {
                            fileName
                            fileSize
                            fileExtension
                            filePath
                            url
                        }
                    }
                    userproductsFiles {
                        file {
                            fileName
                            fileSize
                            fileExtension
                            filePath
                            url
                        }
                        downloadLink(forceDirectDownload: false)
                    }
                    activities: tags(tagTypeInternalName: "${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                    pricePosition: tags(tagTypeInternalName: "${TagTypeService.PRICE_RANGE_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                    targetConsumers: tags(tagTypeInternalName: "${TagTypeService.TARGET_CONSUMER_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                    rangeOfProducts: tags(tagTypeInternalName: "${TagTypeService.RANGE_OF_PRODUCT_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                    nutritionalInformations: tags(tagTypeInternalName: "${TagTypeService.NUTRITIONAL_INFORMATION_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                    qualityLabels: tags(tagTypeInternalName: "${TagTypeService.QUALITY_LABEL_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                    exportCertifications: tags(tagTypeInternalName: "${TagTypeService.EXPORT_CERTIFICATION_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                    sustainability: tags(tagTypeInternalName: "${TagTypeService.SUSTAINABILITY_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                    healthAndWellness: tags(tagTypeInternalName: "${TagTypeService.HEALTH_AND_WELLNESS_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                    consumptionMode: tags(tagTypeInternalName: "${TagTypeService.CONSUMPTION_MODE_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                    dietary: tags(tagTypeInternalName: "${TagTypeService.DIETARY_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                    temperatureControlled: tags(tagTypeInternalName: "${TagTypeService.TEMPERATURE_CONTROLLED_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                    packagingProductMaterials: tags(tagTypeInternalName: "${TagTypeService.PACKAGING_PRODUCT_MATERIAL}") {
                        items {
                            ...tag
                        }
                    }
                    packagingProductCategories: tags(tagTypeInternalName: "${TagTypeService.PACKAGING_PRODUCT_CATEGORIES}") {
                        items {
                            ...tag
                        }
                    }
                    packagingPurpose: tags(tagTypeInternalName: "${TagTypeService.ECO_PACKAGING_PURPOSE_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                    packagingConsistency: tags(tagTypeInternalName: "${TagTypeService.ECO_PACKAGING_CONSISTENCY_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                    packagingEcoFriendlyFeatures: tags(tagTypeInternalName: "${TagTypeService.PACKAGING_PRODUCT_ECO_FRIENDLY_FEATURES}") {
                        items {
                            ...tag
                        }
                    }
                }
            }
            ${TagTypeGraphql.fragments.tag}
        `,

        productProfilePage: gql`
            query productProfilePage($idproduct: Int!) {
                product(idproduct: $idproduct) {
                    iduserproduct
                    name
                    published
                    innovation
                    category {
                        ...fullTag
                    }
                    description
                    targetConsumers: tags(tagTypeInternalName: "${TagTypeService.TARGET_CONSUMER_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    rangeOfProducts: tags(tagTypeInternalName: "${TagTypeService.RANGE_OF_PRODUCT_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    nutritionalInformations: tags(tagTypeInternalName: "${TagTypeService.NUTRITIONAL_INFORMATION_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    qualityLabels: tags(tagTypeInternalName: "${TagTypeService.QUALITY_LABEL_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    exportCertifications: tags(tagTypeInternalName: "${TagTypeService.EXPORT_CERTIFICATION_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    sustainability: tags(tagTypeInternalName: "${TagTypeService.SUSTAINABILITY_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    healthAndWellness: tags(tagTypeInternalName: "${TagTypeService.HEALTH_AND_WELLNESS_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    consumptionMode: tags(tagTypeInternalName: "${TagTypeService.CONSUMPTION_MODE_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    dietary: tags(tagTypeInternalName: "${TagTypeService.DIETARY_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    temperatureControlled: tags(tagTypeInternalName: "${TagTypeService.TEMPERATURE_CONTROLLED_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    pricePosition: tags(tagTypeInternalName: "${TagTypeService.PRICE_RANGE_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                    packagingProductMaterials: tags(tagTypeInternalName: "${TagTypeService.PACKAGING_PRODUCT_MATERIAL}") {
                        items {
                            ...tag
                        }
                    }
                    packagingProductCategories: tags(tagTypeInternalName: "${TagTypeService.PACKAGING_PRODUCT_CATEGORIES}") {
                        items {
                            ...tag
                        }
                    }
                    packagingPurpose: tags(tagTypeInternalName: "${TagTypeService.ECO_PACKAGING_PURPOSE_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                    packagingConsistency: tags(tagTypeInternalName: "${TagTypeService.ECO_PACKAGING_CONSISTENCY_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                    packagingEcoFriendlyFeatures: tags(tagTypeInternalName: "${TagTypeService.PACKAGING_PRODUCT_ECO_FRIENDLY_FEATURES}") {
                        items {
                            ...tag
                        }
                    }
                    shelfLife,
                    isPackaging,
                    packaging,
                    company {
                        idcompany
                        name
                        urlname
                        membership
                        logoUrl(size: "123")
                        website
                        citylocation {
                            country {
                                id
                                name
                                tagLocation {
                                    code
                                }
                            }
                        }
                        products(published: true) {
                            iduserproduct
                            name
                            urlPath
                            logoUrl(size:"450")
                        }
                        activeAndAlreadyLoggedMembers {
                            count
                        }
                        institutions{
                            items{
                                idinstitution
                                name
                                urlname
                            }
                        }
                    }
                    manufacturingCountry {
                        id
                        name
                        tagLocation {
                            code
                        }
                    }
                    countryOrigin {
                        id
                        name
                        tagLocation {
                            code
                        }
                    }
                    readyForChina
                    composition
                    packaging
                    packagingformats {
                        value
                        idpackagingformat
                        packagingunit {
                            name
                        }
                        price
                        name
                        description
                    }
                    activities: tags(tagTypeInternalName: "${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                    userproductsFiles {
                        file {
                            fileName
                            fileSize
                            fileExtension
                        }
                        downloadLink(forceDirectDownload: false)
                    }
                    logoUrl(size: "450")
                    userproductsImagesCount
                    userproductsImages {
                        imageUrl
                    }
                }
            }
            ${TagTypeGraphql.fragments.tag}
            ${TagTypeGraphql.fragments.fullTag}
        `,

        findByIdSimilarProductsOtherProducts: gql`
            query Product($iduserproduct: Int!, $published: Boolean, $limitSimilarProduct: Int) {
                product(idproduct: $iduserproduct) {
                    ...product
                }
            }
            ${ProductGraphQL.fragments.product}
        `,

        similarProducts: gql`
            query similarProducts($idproduct: Int!) {
                product(idproduct: $idproduct) {
                    iduserproduct
                    similarProductsFromSameCompany {
                        iduserproduct
                        name
                        logoUrl(size:"123")
                        urlPath
                        category {
                            ...fullTag
                        }
                    }
                    similarProductsFromOtherCompanies {
                        iduserproduct
                        name
                        logoUrl(size:"123")
                        urlPath
                        category {
                            ...fullTag
                        }
                    }
                }
            }
            ${TagTypeGraphql.fragments.fullTag}
        `
    };

    static mutations = {
        deleteProduct: gql`
            mutation Product($id_product: Int!) {
                deleteProduct(id_product: $id_product) {
                    iduserproduct
                }
            }
        `,

        deleteProducts: gql`
            mutation deleteProducts($idProducts: [Int!]!) {
                deleteProducts(idProducts: $idProducts)
            }
        `,

        toggleVisibilityOfProducts: gql`
            mutation toggleVisibilityOfProducts($idProducts: [Int!]!) {
            toggleVisibilityOfProducts(idProducts: $idProducts)
            }
        `,

        toggleVisibility: gql`
            mutation Product($id_product: Int!) {
                toggleVisibility(id_product: $id_product) {
                    iduserproduct
                    published
                }
            }
        `,

        saveProductsOrder: gql`
            mutation Product($productsIdsOrdered: [Int!]!) {
                saveProductsOrder(productsIdsOrdered: $productsIdsOrdered) {
                    iduserproduct
                    position
                }
            }
        `,

        duplicateProduct: gql`
            mutation Product($id_product: Int!) {
                duplicateProduct(id_product: $id_product) {
                    iduserproduct
                }
            }
        `,

        saveProduct: gql`
            mutation Product(
                $id: Int,
                $published: Boolean!,
                $name: String!,
                $pricePositioningIds: [Int!],
                $activityIds: [Int!],
                $categoryId: Int!,
                $description: String,
                $consumerTargetIds: [Int!],
                $innovation: Boolean!,
                $productLogo: FileToUploadInput!,
                $productImages: [FileToUploadInput!],
                $exportCertificationIds: [Int!],
                $qualityLabelIds: [Int!],
                $sustainabilityIds: [Int!],
                $nutritionalInformationIds: [Int!],
                $healthAndWellnessIds: [Int!],
                $rangeOfProductsIds: [Int!],
                $dietaryIds: [Int!],
                $consumptionModeIds: [Int!],
                $temperatureControlledIds: [Int!],
                $composition: String,
                $countryOriginId: Int,
                $files: [FileToUploadInput!],
                $manufacturingCountryId: Int,
                $packagingFormats: [PackagingFormatInput!],
                $duplicateProduct: Boolean,
                $packaging: String,
                $packagingProductMaterial: [Int!],
                $packagingProductCategories: [Int!],
                $packagingPurpose: [Int!],
                $packagingConsistency: [Int!],
                $packagingEcoFriendlyFeatures: [Int!],
                $shelfLife: Int,
            ) {
                saveProduct(
                    id: $id,
                    published: $published,
                    name: $name,
                    pricePositioningIds: $pricePositioningIds,
                    activityIds: $activityIds,
                    categoryId: $categoryId,
                    description: $description,
                    consumerTargetIds: $consumerTargetIds,
                    innovation: $innovation,
                    productLogo: $productLogo,
                    productImages: $productImages,
                    exportCertificationIds: $exportCertificationIds,
                    qualityLabelIds: $qualityLabelIds,
                    sustainabilityIds: $sustainabilityIds,
                    nutritionalInformationIds: $nutritionalInformationIds,
                    healthAndWellnessIds: $healthAndWellnessIds,
                    rangeOfProductsIds: $rangeOfProductsIds,
                    dietaryIds: $dietaryIds,
                    consumptionModeIds: $consumptionModeIds,
                    temperatureControlledIds: $temperatureControlledIds,
                    composition: $composition,
                    countryOriginId: $countryOriginId,
                    files: $files,
                    manufacturingCountryId: $manufacturingCountryId,
                    packagingFormats: $packagingFormats,
                    duplicateProduct: $duplicateProduct,
                    packaging: $packaging
                    packagingProductMaterial: $packagingProductMaterial
                    packagingProductCategories: $packagingProductCategories
                    packagingPurpose: $packagingPurpose
                    packagingConsistency: $packagingConsistency
                    packagingEcoFriendlyFeatures: $packagingEcoFriendlyFeatures
                    shelfLife: $shelfLife
                ) {
                    iduserproduct
                    company {
                        idcompany
                    }
                }
            }
        `,
    };
}
