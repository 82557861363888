import {
    Component, OnDestroy,
} from '@angular/core';
import {RIGHT} from '../../../services/wabel-client/security/right.const';
import {AuthService} from '../../../services/auth.service';
import {BusinessMappingPriority} from '../../../services/wabel-client/entities/business_mapping_priority';
import {
    DISCOVER_BUSINESS_PRIORITIES_TOUR_INTERNALNAME,
    MemberNotification
} from "../../../services/wabel-client/entities/member_notification";
import {NeedlTourService} from "../../../services/needl-tour.service";
import {MemberService} from "../../../services/wabel-client/services/member.service";
import {SourcingRequestService} from "../../../services/wabel-client/services/sourcing_request.service";
import {
    BusinessMappingPriorityRecommendation
} from "../../../services/wabel-client/entities/business_mapping_priority_recommendation";
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-business-mapping-recap',
    templateUrl: './business-mapping-recap.component.html',
    styleUrls: ['./business-mapping-recap.component.scss']
})
export class BusinessMappingRecapComponent implements OnDestroy {
    RIGHT = RIGHT;
    formattedDataForMapChart = [];
    totalCountOfActiveSourcingNeedAndSourcingRequests: number;

    public priorities: BusinessMappingPriority[];
    public loading: boolean[] = [];

    private tour$: Subscription;

    colorByInternalName = {
        current_market: '#08BD6F',
        exclusive_market: '#BE2127',
        excluded_market: '#000000',
        market_to_consolidate: '#F2C94C',
        market_to_open: '#F2994A',
        channel_to_develop: '#6771DC'
    };

    constructor(private authService: AuthService,
                private needlTourService: NeedlTourService,
                private memberService: MemberService,
                private sourcingRequestService: SourcingRequestService) {
        this.priorities = this.authService.getMember().businessMapping.businessMappingPriorities
            .sort((a, b) => {
                if (b.priority === a.priority) {
                    return -1;
                }
                return a.priority > b.priority ? 1 : -1 ;
            });
        this.sourcingRequestService.getTotalCountOfActiveSourcingNeedAndSourcingRequests().subscribe(data => {
            this.totalCountOfActiveSourcingNeedAndSourcingRequests = +data;
            this.computeDataToFormatForMapChart();
        });
    }

    computeDataToFormatForMapChart() {
        const currentMarket = {name: 'Current market', internalName: 'current_market', color: '#08BD6F', countries: []};
        currentMarket.countries = this.authService.getMember().businessMapping.businessMappingTags.filter(bmt => bmt.type === null).map(t => ({
            id: t.tag.tagLocation.code.toUpperCase()
        }));
        if (currentMarket.countries.length) {
            currentMarket.name += ' \n ' + currentMarket.countries.length + ' countr' + (currentMarket.countries.length === 1 ? 'y' : 'ies');
        }
        if (this.authService.getMember().businessMapping.member.company.companyClientCount) {
            currentMarket.name += ' \n ' + this.authService.getMember().businessMapping.member.company.companyClientCount + ' current client'
                + (this.authService.getMember().businessMapping.member.company.companyClientCount > 1 ? 's' : '');
        }

        const exclusiveMarket = {name: 'Exclusive market', internalName: 'exclusive_market', color: '#BE2127', countries: []};
        exclusiveMarket.countries = this.authService.getMember().businessMapping.businessMappingTags
            .filter(bmt => bmt.type === 'part_portfolio' || bmt.type === 'full_portfolio').map(t => ({
            id: t.tag.tagLocation.code.toUpperCase()
        }));
        if (exclusiveMarket.countries.length) {
            exclusiveMarket.name += ' \n ' + exclusiveMarket.countries.length + ' countr' + (exclusiveMarket.countries.length === 1 ? 'y' : 'ies');
        }

        const excludedMarket = {name: 'Excluded market', internalName: 'excluded_market', color: '#000000', countries: []};
        excludedMarket.countries = this.authService.getMember().businessMapping.businessMappingTags.filter(bmt => bmt.type === 'excluded')
            .map(t => ({
            id: t.tag.tagLocation.code.toUpperCase()
        }));
        if (excludedMarket.countries.length) {
            excludedMarket.name += ' \n ' + excludedMarket.countries.length + ' countr' + (excludedMarket.countries.length === 1 ? 'y' : 'ies');
        }

        const marketToConsolidate = {name: 'Market to consolidate', internalName: 'market_to_consolidate', color: '#F2C94C', countries: []};
        const bmpsToConsolidate = this.authService.getMember().businessMapping.businessMappingPriorities.filter(bmp => bmp.goal === 'consolidate_existing_market');
        let nbClientsFromMarketToConsolidate = 0;
        bmpsToConsolidate.forEach(bmpToConsolidate => {
            bmpToConsolidate.countries.forEach(country => marketToConsolidate.countries.push(({id: country.tagLocation.code.toUpperCase()})));
            nbClientsFromMarketToConsolidate += bmpToConsolidate.businessMappingPriorityRecommendations.length;
        });
        if (marketToConsolidate.countries.length) {
            marketToConsolidate.name += ' \n ' + marketToConsolidate.countries.length + ' countr' + (marketToConsolidate.countries.length === 1 ? 'y' : 'ies');
        }
        if (nbClientsFromMarketToConsolidate) {
            marketToConsolidate.name += ' \n ' + nbClientsFromMarketToConsolidate + ' prospect' + (nbClientsFromMarketToConsolidate > 1 ? 's' : '');
        }

        const marketToOpen = {name: 'Market to open', internalName: 'market_to_open', color: '#F2994A', countries: []};
        const bmpsToOpen = this.authService.getMember().businessMapping.businessMappingPriorities.filter(bmp => bmp.goal === 'open_new_markets');
        let nbClientsFromMarketToOpen = 0;
        bmpsToOpen.forEach(bmpToOpen => {
            bmpToOpen.countries.forEach(country => marketToOpen.countries.push(({id: country.tagLocation.code.toUpperCase()})));
            nbClientsFromMarketToOpen += bmpToOpen.businessMappingPriorityRecommendations.length;
        });

        if (marketToConsolidate.countries.length) {
            marketToOpen.name += ' \n ' + marketToOpen.countries.length + ' countr' + (marketToOpen.countries.length === 1 ? 'y' : 'ies');
        }
        if (nbClientsFromMarketToOpen) {
            marketToOpen.name += ' \n ' + nbClientsFromMarketToOpen + ' prospect' + (nbClientsFromMarketToOpen > 1 ? 's' : '');
        }
        if (this.totalCountOfActiveSourcingNeedAndSourcingRequests) {
            marketToOpen.name += ' \n ' + this.totalCountOfActiveSourcingNeedAndSourcingRequests + ' current sourcing needs';
        }

        const channelToDevelop = {name: 'Channels to develop', internalName: 'channel_to_develop', color: '#6771DC', countries: []};
        const bmpsToDevelop = this.authService.getMember().businessMapping.businessMappingPriorities.filter(bmp => bmp.goal === 'develop_new_channels');
        let companyTypesToDevelop = [];
        bmpsToDevelop.forEach(bmpToDevelop => {
            bmpToDevelop.countries.forEach(country => channelToDevelop.countries.push(({id: country.tagLocation.code.toUpperCase()})));
            companyTypesToDevelop = companyTypesToDevelop.concat(bmpToDevelop.companyTypes.map(ct => ct.id));
        });
        if (channelToDevelop.countries.length) {
            channelToDevelop.name += ' \n ' + channelToDevelop.countries.length + ' countr' + (channelToDevelop.countries.length === 1 ? 'y' : 'ies');
        }
        companyTypesToDevelop = [... new Set(companyTypesToDevelop)];
        if (companyTypesToDevelop.length) {
            channelToDevelop.name += ' \n ' + companyTypesToDevelop.length + ' channel' + (companyTypesToDevelop.length === 1 ? 's' : '');
        }

        const formattedDataForMapChart = [];
        if (currentMarket.countries.length) {
            formattedDataForMapChart.push(currentMarket);
        }
        if (exclusiveMarket.countries.length) {
            formattedDataForMapChart.push(exclusiveMarket);
        }
        if (excludedMarket.countries.length) {
            formattedDataForMapChart.push(excludedMarket);
        }
        if (marketToConsolidate.countries.length) {
            formattedDataForMapChart.push(marketToConsolidate);
        }
        if (marketToOpen.countries.length) {
            formattedDataForMapChart.push(marketToOpen);
        }
        if (channelToDevelop.countries.length) {
            formattedDataForMapChart.push(channelToDevelop);
        }

        this.formattedDataForMapChart = this.computeCountriesWithTwoColors(formattedDataForMapChart);

        if (!this.authService.getMember().hasNotificationAccepted(DISCOVER_BUSINESS_PRIORITIES_TOUR_INTERNALNAME)) {
            this.initializeTour();
        }
    }

    computeCountriesWithTwoColors(formattedDataForMapChart: {name: string, internalName: string, color: string, countries: {id: string, color: string}[]}[]) {
        for (let i = 0; i < formattedDataForMapChart.length; i++) {
            if (formattedDataForMapChart[i].internalName === 'current_market'
                || formattedDataForMapChart[i].internalName === 'excluded_market') {
                continue;
            }
            for (let j = 0; j < formattedDataForMapChart[i].countries.length; j++) {
                if (!formattedDataForMapChart[i].countries[j].color) {
                    for (let k = 0; k < formattedDataForMapChart.length; k++) {
                        if (formattedDataForMapChart[k].internalName !== 'current_market'
                            && formattedDataForMapChart[k].internalName !== 'excluded_market'
                            && formattedDataForMapChart[k].internalName !== formattedDataForMapChart[i].internalName) {
                            const index = formattedDataForMapChart[k].countries.findIndex(c => c.id === formattedDataForMapChart[i].countries[j].id);
                            if (index !== -1) {
                                formattedDataForMapChart[i].countries[j].color = this.colorByInternalName[formattedDataForMapChart[k].internalName];
                            }
                        }
                    }
                }
            }
        }
        return formattedDataForMapChart;
    }

    initializeTour() {
        const steps = [];
        steps.push({
            title: 'Discover in each business priorities the best buyers\' profiles we found.',
            anchorId: 'discover-business-priorities',
            content: 'Open your priorities below and check your recommendations.',
        });
        this.needlTourService.initialize(steps, {
            enableBackdrop: true,
            isAsync: true,
            nextBtnTitle: 'Ok',
            endBtnTitle: 'Ok',
            disablePageScrolling: true
        }, false);
        this.needlTourService.canBeSkipped = false;
        this.needlTourService.start();
        this.tour$ = this.needlTourService
            .getTourService()
            .end$
            .subscribe(
                () => {
                    this.memberService.acceptNotification(DISCOVER_BUSINESS_PRIORITIES_TOUR_INTERNALNAME, 'modal').subscribe((notifications: MemberNotification[]) => {
                        this.authService.getMember().setNotifications(notifications);
                    });
                    if (this.tour$) {
                        this.tour$.unsubscribe();
                    }
                }
            );
    }

    ngOnDestroy() {
        if (this.tour$) {
            this.tour$.unsubscribe();
        }
    }
}
