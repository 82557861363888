import {NgModule} from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {PageErrorComponent} from "./page-error/page-error.component";
import {EmailVerifiedComponent} from "./email-verified/email-verified.component";
import {PageNotFoundComponent} from "./page-not-found/page-not-found.component";
import {AccessDeniedComponent} from "./access-denied/access-denied.component";

const routes: Routes = [
    {
        path: 'error',
        component: PageErrorComponent
    },
    {
        path: 'email-verified/:status',
        component: EmailVerifiedComponent
    },
    {
        path: 'page-not-found',
        component: PageNotFoundComponent
    },
    {
        path: 'access-denied',
        component: AccessDeniedComponent
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
})
export class FallbacksRoutingModule {
}
