import { Platform } from '@angular/cdk/platform';
import * as dayjs from 'dayjs';
import 'dayjs/locale/en';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import { NativeDateAdapter } from '@angular/material/core';

export const CustomDateFormats = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'LL',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    }
};

export class CustomDateAdapter extends NativeDateAdapter {

    constructor(matDateLocale: string, platform: Platform) {
        super(matDateLocale, platform);

        // Initalize DayJS-Parser
        dayjs.locale('en');
        dayjs.extend(customParseFormat);
        dayjs.extend(localizedFormat);
    }

    parse(value: any): Date | null {
        return dayjs(value, 'DD.MM.YYYY').toDate();
    }

    format(date: Date, displayFormat: any): string {
        return dayjs(date).format(displayFormat);
    }

}
