<div class="checkbox" (click)="toggleOption()">
    <input (change)="toggleOption()"
           type="checkbox"
           [name]="option.label + '_' + option.value"
           [id]="option.label + '_' + option.value"
           [checked]="checked"
           [disabled]="disabled"
           [indeterminate]="counter > 0 && counter !== option.children.length"
           *ngIf="!hideCheckbox"
    >
    <div class="checkbox__label">
        <label [for]="option.label + '_' + option.value" title="{{option.label}}" (click)="hideCheckbox ? clickLabelWithoutCheckbox($event) : null" [class.has-checkbox]="!hideCheckbox">{{option.label}}</label>
        <span *ngIf="counter" class="checkbox-counter">{{ counter }}</span>
    </div>
    <span class="checkbox__sign checkmark" *ngIf="!hideCheckbox" [class.disabled]="disabled"></span>
    <span class="checkbox__sign indeterminate" *ngIf="!hideCheckbox" [class.disabled]="disabled"></span>
</div>
