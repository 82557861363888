import {Injectable} from "@angular/core";
import {WabelService} from "./wabel-service.abstract";
import {GraphQLService} from "./graphql-service";
import {LoadingService} from "./loading.service";

import {ResultIterator} from "../entities/result_iterator";
import {PackagingUnit} from "../entities/packaging_unit";
import {PackagingUnitGraphql} from "../graphql/packaging_unit.graphql";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class PackagingUnitService extends WabelService {

    constructor(protected graphQLService: GraphQLService, protected loadingService: LoadingService) {
        super(graphQLService, loadingService);
    }

  private objectsToPackagingUnits(objects) {
    return objects.map((packagingUnit: any) => new PackagingUnit(packagingUnit));
  }

  toObject(object) {
    return new PackagingUnit(object);
  }

  getPackagingUnits() {
    return this.query(PackagingUnitGraphql.queries.packagingUnits, {})
      .pipe(map((data) => {
        return data.data ? new ResultIterator<PackagingUnit>(data.data.packagingUnits.length, this.objectsToPackagingUnits(data.data.packagingUnits) as PackagingUnit[]) : null
      }));
  }


}
