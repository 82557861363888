import {WabelEntity} from "./wabel-entity.abstract";
import {Member} from "./member";
import {Event} from "./event";
import {Company} from "./company";
import {SummitRegistration} from './summit_registration';
import {Meeting} from './meeting';
import {EventParticipation} from './event_participation';
import {RegistrationAvailability} from './registration_availability';
import {EventWorkshopPreference} from "./event_workshop_preference";
import {Tag} from "./tag";
import {MeetingPreference} from "./meeting_preference";
import * as dayjs from "dayjs";
import {EventMeetingConfirmation} from "./event_meeting_confirmation";

export class Registration extends WabelEntity {
    constructor(obj: any) {
        super(obj);
        if (obj) {
            this.event = obj.event ? new Event(obj.event) : null;
            if (!this.event) {
                this.event = obj.certainevent ? new Event(obj.certainevent) : null;
            }
            this.member = obj.member ? new Member(obj.member) : null;
            this.selectedBy = obj.selectedBy ? obj.selectedBy.map(selectedByMember => new Member(selectedByMember)) : [];
            this.excludedBy = obj.excludedBy ? obj.excludedBy.map(excludedByMember => new Member(excludedByMember)) : [];
            this.selection = obj.selection ? obj.selection.map(selectionCompany => new Company(selectionCompany)) : [];
            this.exclusion = obj.exclusion ? obj.exclusion.map(exclusionCompany => new Company(exclusionCompany)) : [];
            this.selections = obj.selections && obj.selections.items ? obj.selections.items.map(sel => new MeetingPreference(sel)) : [];
            this.buyingScope = obj.buyingScope && obj.buyingScope.items ? obj.buyingScope.items.map(category => new Tag(category)) : obj.buyingScope ? obj.buyingScope.map(category => new Tag(category)) : [];
            this.participation = obj.participation ? new EventParticipation(obj.participation) : null;
            this.meetingPreferencesSelectedCount = obj.meetingPreferencesSelectedCount && obj.meetingPreferencesSelectedCount.count ? obj.meetingPreferencesSelectedCount.count : 0;
            this.availabilities = obj.availabilities && obj.availabilities.items ? obj.availabilities.items.map(ra => new RegistrationAvailability(ra)) : [];
            if (obj.meetings) {
                if (obj.meetings.items) {
                    this.meetings = obj.meetings.items.map(m => new Meeting(m));
                }
                if (obj.meetings.count) {
                    this.meetingsCount = obj.meetings.count;
                }
            }
            if (obj.summitRegistrations && obj.summitRegistrations.items) {
                this.summitRegistrations = obj.summitRegistrations.items.map(sr => new SummitRegistration(sr));
            }
            if (obj.dateSelectionFinished) {
                this.dateSelectionFinished = new Date(obj.dateSelectionFinished);
            }
            if (obj.selectionStartedDate) {
                this.selectionStartedDate = new Date(obj.selectionStartedDate);
            }
            if (obj.selectionFinishedDate) {
                this.selectionFinishedDate = new Date(obj.selectionFinishedDate);
            }
            if (obj.dateLastAgendaCheck) {
                this.dateLastAgendaCheck = new Date(obj.dateLastAgendaCheck);
            }
            if (obj.ideventregistration) {
                this.idcertaineventregistration = obj.ideventregistration;
            }
            this.eventWorkshopPreferences = obj.eventWorkshopPreferences && obj.eventWorkshopPreferences.items ? obj.eventWorkshopPreferences.items.map(w => new EventWorkshopPreference(w)) : [];
            this.summitRegistrationCategories = obj.summitRegistrationCategories && obj.summitRegistrationCategories.items ? obj.summitRegistrationCategories.items.map(category => new Tag(category)) : obj.summitRegistrationCategories ? obj.summitRegistrationCategories.map(category => new Tag(category)) : [];
            this.meetingsWithSampleRequests = obj.meetingsWithSampleRequests && obj.meetingsWithSampleRequests.items ? obj.meetingsWithSampleRequests.items.map(meeting => new Meeting(meeting)) : [];
            this.meetingsToReschedule = obj.meetingsToReschedule && obj.meetingsToReschedule.items ? obj.meetingsToReschedule.items.map(meeting => new Meeting(meeting)) : [];
            this.privateMeetingPreferences = obj.privateMeetingPreferences && obj.privateMeetingPreferences.items ? obj.privateMeetingPreferences.items.map(pmp => new MeetingPreference(pmp)) : [];
            this.eventMeetingConfirmations = obj.eventMeetingConfirmations && obj.eventMeetingConfirmations.items ? obj.eventMeetingConfirmations.items.map(emc => new EventMeetingConfirmation(emc)) : [];
        }
    }

    idcertaineventregistration: number;
    event: Event;
    member: Member;
    zohoId: string;
    checkedIn: boolean;
    qrCode: string;
    availabilityStart: string;
    availabilityEnd: string;
    availabilities: RegistrationAvailability[];
    noDiary: boolean;
    registrationCode: string;
    selectedBy: Member[];
    excludedBy: Member[];
    selection: Company[];
    exclusion: Company[];
    buyingScope: Tag[];
    meetingsNumber: number;
    hasMeetings: boolean;
    dateSelectionFinished: Date;
    selectionStartedDate: Date;
    selectionFinishedDate: Date;
    selectionToolUrl: string;
    meetingsSelectedNumber: number;
    meetingPreferencesSelectedCount: number;
    meetingsCount: number;
    hotel: string;
    nightsDates: string[];
    badgeUrl: string;
    participation: EventParticipation;
    summitRegistrations: SummitRegistration[];
    travelOrganizationByWabel: boolean;
    accomodationOrganizationByWabel: boolean;
    cityDeparture: string;
    booking: string;
    organization: string;
    comingOn: string;
    messageByEmail: boolean;
    messageBySms: boolean;
    meetings: Meeting[];
    purchaseTimeline: string;
    eventWorkshopPreferences: EventWorkshopPreference[];
    summitRegistrationCategories: Tag[];
    meetingsWithSampleRequests: Meeting[];
    meetingsToReschedule: Meeting[];
    wantSamples: boolean;
    permissions: string[];
    nbMeetingPreferencesCancelledByAdminWithNoMeeting: number;
    selections: MeetingPreference[];
    privateMeetingPreferences: MeetingPreference[];
    dateLastAgendaCheck: Date;
    eventMeetingConfirmations: EventMeetingConfirmation[] = [];
    room: string;

    get id(): number {
        return this.idcertaineventregistration;
    }

    set id(id: number) {
        this.idcertaineventregistration = id;
    }

    get summitRegistrationsConcat(): string {
        return this.summitRegistrations.reduce((concat, curr, i, s) => { return concat + curr.summit.summitCode +  (i < s.length - 1 ? ',' : '')}, '')
    }

    get numberOfHoursOfAvailability(): number {
        let hours = 0;
        for (let availability of this.availabilities) {
            hours += dayjs(availability.availabilityEnd).diff(dayjs(availability.availabilityStart), 'hour', true);
        }
        return hours;
    }

    get minimumNumberOfHoursToCommit(): number {
        return this.selections ? this.selections.length * this.event.meetingDuration / 60 : 0;
    }

    get nextMeetings(): Meeting[] {
        const now = dayjs();
        return this.meetings.filter(m => dayjs(m.endDate) > now);
    }
}
