import { PrivateMessage } from "./private_message";
import { Company } from "./company";
import {WabelEntity} from "./wabel-entity.abstract";
import { Member } from "./member";
import { Product } from "./product";
import { CompanyProfileFeedback } from "./company_profile_feedback";
import {File} from "./file";
import { Tag } from "./tag";

export class CompanyProfileSubmission extends WabelEntity {

    constructor(obj: any) {
        super(obj);
        if (obj) {
            this.profileStatus = obj.profileStatus ? obj.profileStatus : null;
            this.profileStep = obj.profileStep ? obj.profileStep : null;
            this.message = obj.message ? new PrivateMessage(obj.message) : null;
            this.createdByCompany = obj.createdByCompany ? new Company(obj.createdByCompany) : null;
            this.createdForCompany = obj.createdForCompany ? new Company(obj.createdForCompany) : null;
            this.createdByMember = obj.createdByMember ? new Member(obj.createdByMember) : null;
            this.createdForMember = obj.createdForMember ? new Member(obj.createdForMember) : null;
            this.companyProfileExportingCountries = obj.companyProfileExportingCountries && obj.companyProfileExportingCountries.items ? obj.companyProfileExportingCountries.items.map((country: any) => new Tag(country.country)) : [];
            this.companyProfileFeaturedProducts = obj.companyProfileFeaturedProducts && obj.companyProfileFeaturedProducts.items ? obj.companyProfileFeaturedProducts.items.map((product: any) => new Product(product.userproduct)) : [];
            this.companyProfileDiagnosisClients = obj.companyProfileDiagnosisClients && obj.companyProfileDiagnosisClients.items ? obj.companyProfileDiagnosisClients.items.map((company: any) => new Company(company.company)) : [];
            this.companyProfileFeedbacks = obj.companyProfileFeedbacks && obj.companyProfileFeedbacks.items ? obj.companyProfileFeedbacks.items.map((feedback: any) => new CompanyProfileFeedback(feedback)) : [];
            this.files = obj.files ? obj.files.map(file => new File(file)) : [];
            this.categories = obj.categories && obj.categories.items && obj.categories.items.length > 0 ? obj.categories.items.map( x => new Tag(x)) : [];
        }
    }

    idcompanyprofilesubmission: number;
    message: PrivateMessage;
    createdByCompany: Company;
    createdForCompany: Company;
    createdByMember: Member;
    createdForMember: Member;
    profileTitle: string;
    profileDescription: string;
    isExportingToBuyerCountry: boolean;
    isFeaturedProducts: boolean;
    categories: Tag[];
    companyProfileExportingCountries: Tag[];
    companyProfileFeaturedProducts: Product[];
    companyProfileDiagnosisClients: Company[];
    companyProfileFeedbacks: CompanyProfileFeedback[];
    files: File[] = [];
    profileStatus: string;
    profileStep: string;
    createdAt: string;
    updatedAt: string;
    buyerStatus: string;

    get id(): number {
        return this.idcompanyprofilesubmission;
    }

    set id(id: number) {
        this.idcompanyprofilesubmission = id;
    }

}
