import {Component} from '@angular/core';

@Component({
    selector: 'ndl-cascader-demo',
    templateUrl: './cascader-demo.component.html'
})
export class NdlCascaderDemoComponent {
    categoriesOptions = [
        {
            id: '10181',
            name: 'Frozen food',
            children: [
                {
                    id: '10199',
                    name: 'Frozen Desserts',
                    children: [
                        {
                            id: '10186',
                            name: 'Christmas frozen desserts',
                            children: [
                                {
                                    id: '101862',
                                    name: 'Level 4',
                                    children: [
                                        {
                                            id: '104162',
                                            name: 'Ice cream level 5'
                                        },
                                        {
                                            id: '104163',
                                            name: 'Dessert level 5'
                                        },
                                    ]
                                }
                            ]
                        },
                        {
                            id: '10187',
                            name: 'Frozen Crumbles, Tarts and Pastries'
                        },
                        {
                            id: '17861',
                            name: 'Frozen Cakes and Cheesecakes'
                        }
                    ]
                },
                {
                    id: '10416',
                    name: 'Ice cream'
                }
            ]
        },
        {
            id: '10230',
            name: 'Chilled and fresh food'
        }
    ];
}
