import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Company} from "../../../../services/wabel-client/entities/company";
import {CompanyService} from "../../../../services/wabel-client/services/company.service";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-company-buying-offices',
    templateUrl: './company-buying-offices.component.html',
    styleUrls: ['./company-buying-offices.component.scss'],
})
export class CompanyBuyingOfficesComponent implements OnInit, OnDestroy {
    @Input() urlname;
    buyingOffices$: Subscription;
    buyingOffices: Company[];
    @Output() noBuyingOffices = new EventEmitter<any>();

    constructor(private companyService: CompanyService) {
    }

    ngOnInit() {
        this.buyingOffices$ = this.companyService.getSiblingsCompanies(this.urlname).subscribe(
            (buyingOffices: Company[]) => {
                this.buyingOffices = buyingOffices;
                if (!this.buyingOffices.length) {
                    this.noBuyingOffices.emit();
                }
            }
        );
    }

    ngOnDestroy() {
        if (this.buyingOffices$) {
            this.buyingOffices$.unsubscribe();
        }
    }
}
