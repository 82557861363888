import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {CompanyProfileComponent} from "./company-profile.component";
import {CompanyPreviewModule} from "../company-preview/company-preview.module";
import {CompanyBusinessInfoModule} from "../company-business-info/company-business-info.module";
import {NeedlTabsModule, NeedlModalsModule, NeedlPlaceholdersModule, NeedlTagModule} from "needl-lib";
import {CompanyDetailsComponent} from "./company-details/company-details.component";
import {SharedModule} from "../../../modules/shared-module/shared.module";
import {CompanyBuyingOfficesComponent} from "./company-buying-offices/company-buying-offices.component";
import {
    RetailGroupCardOfficesModule
} from "../../../modules/company-profile-module/company-profile/retail-group/retail-group-card-offices/retail-group-card-offices.module";
import {
    CompanyGeographicalBreakdownComponent
} from "./company-geographical-breakdown/company-geographical-breakdown.component";
import {CompanyProductsModule} from "../company-products/company-products.module";
import {
    CompanyCountriesOfActivityComponent
} from "./company-countries-of-activity/company-countries-of-activity.component";
import {NeedlTooltipModule} from "needl-lib";


@NgModule({
    declarations: [
        CompanyProfileComponent,
        CompanyDetailsComponent,
        CompanyBuyingOfficesComponent,
        CompanyGeographicalBreakdownComponent,
        CompanyCountriesOfActivityComponent
    ],
    imports: [
        CommonModule,
        MatDividerModule,
        MatIconModule,
        CompanyPreviewModule,
        CompanyBusinessInfoModule,
        NeedlTabsModule,
        NeedlModalsModule,
        NeedlPlaceholdersModule,
        SharedModule,
        NeedlTagModule,
        RetailGroupCardOfficesModule,
        CompanyProductsModule,
        NeedlTooltipModule
    ],
    exports: [
        CompanyProfileComponent
    ]
})
export class CompanyProfileModule {
}
