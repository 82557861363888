import {Injectable} from '@angular/core';
import {TourService} from "ngx-ui-tour-md-menu";
import {Subject, Subscription} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class NeedlTourService {

    // Tried to extends the TourService but the start didn't worked...
    constructor(public tourService: TourService) {
    }

    currentStepIndex = 0;
    canBeSkipped = false;
    private _showSteps = false;
    stepShow$: Subscription;

    private updateNavBarOpenedMenu = new Subject<{id: string, duringTour: boolean }>();
    updateNavBarOpenedMenu$ = this.updateNavBarOpenedMenu.asObservable();

    // Service message commands
    launchNavBarMenuOpenUpdate(menuBehavior: {id: string, duringTour: boolean }) {
        this.updateNavBarOpenedMenu.next(menuBehavior);
    }

    getTourService() {
        return this.tourService;
    }

    initialize(steps, stepDefaults = null, showSteps = true) {
        this.currentStepIndex = 0;
        this._showSteps = showSteps;
        this.tourService.initialize(steps, stepDefaults);
        this.stepShow$ = this.tourService
            .stepShow$
            .subscribe(
                () => {
                    this.currentStepIndex++;
                }
            );
    }

    get showStep() {
        return this._showSteps;
    }

    start() {
        this.tourService.start();
    }

    hasNext(step) {
        return this.tourService.hasNext(step);
    }

    next() {
        this.tourService.next();
    }

    end() {
        this.tourService.end();
        if (this.stepShow$) {
            this.stepShow$.unsubscribe();
        }
    }
}
