import {Company} from './company';
import {WabelEntity} from './wabel-entity.abstract';

export class CompanyClient extends WabelEntity {
  idCompanyClient: number;
  client: Company;
  label: string;

  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.client = obj.client ? new Company(obj.client) : null;
    }
  }

  get id(): number {
    return +this.idCompanyClient;
  }

  set id(id: number) {
    this.idCompanyClient = id;
  }
}
