import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {Company} from "../../../services/wabel-client/entities/company";
import {CompanyService} from "../../../services/wabel-client/services/company.service";
import {Subscription} from "rxjs";
import {NdlSelectComponent} from "needl-lib";
import {Tag} from "../../../services/wabel-client/entities/tag";
import {Product} from "../../../services/wabel-client/entities/product";

@Component({
    selector: 'app-company-products',
    templateUrl: './company-products.component.html',
    styleUrls: ['./company-products.component.scss'],
})
export class CompanyProductsComponent implements OnInit, OnDestroy {
    @Input() urlname: string;
    @Input() products: Product[];
    @Input() businessMappingPriority;
    @Output() noProducts = new EventEmitter<any>();
    @Input() canSelectProducts = false;
    @Output() selectedProductsChanged = new EventEmitter<Product[]>();
    @Input() selectedProducts: Product[] = [];

    company$: Subscription;
    buyingOffices: Company[];

    openFiltersModal = false;
    categoryOptions: Tag[] = [];
    countryOptions: Tag[] = [];
    targetConsumerOptions: Tag[] = [];
    productAttributesOptions: Tag[] = [];
    productLabelOptions: Tag[] = [];
    openedInfoProductIds: number[] = [];

    showFilters = false;
    hasMultipleFiltersCategory = false;
    @ViewChildren(NdlSelectComponent) children!: QueryList<NdlSelectComponent>;

    constructor(private companyService: CompanyService) {
    }

    ngOnInit() {
        if (this.products) {
            this.init();
            return;
        }
        this.company$ = this.companyService.getCompanyGeographicalProducts(this.urlname, this.businessMappingPriority).subscribe(
            (company: Company) => {
                this.products = company.products.slice(0);
                if (!this.products.length) {
                    this.noProducts.emit();
                }
                this.init();
            }
        );
    }

    ngOnDestroy() {
        if (this.company$) {
            this.company$.unsubscribe();
        }
    }

    getNumberOfFilters(filters: any) {
        let number = 0;

        if (filters.categories && filters.categories.length) {
            number += filters.categories.length;
        }
        if (filters.countries && filters.countries.length) {
            number += filters.countries.length;
        }
        if (filters.lifestyle && filters.lifestyle.length) {
            number += filters.lifestyle.length;
        }
        if (filters.productAttributes && filters.productAttributes.length) {
            number += filters.productAttributes.length;
        }
        if (filters.nutritional && filters.nutritional.length) {
            number += filters.nutritional.length;
        }
        if (filters.targets && filters.targets.length) {
            number += filters.targets.length;
        }
        if (filters.label && filters.label.length) {
            number += filters.label.length;
        }
        return number;
    }

    clear() {
        this.children.forEach(select => select.clear());
    }

    filterProductList(data: Product[], filters: any) {
        if (filters.categories && filters.categories.length) {
            data = data.filter((p) => {
                return filters.categories.some((f) => {
                    return +p.category?.id === +f;
                });
            });
        }
        if (filters.countries && filters.countries.length) {
            data = data.filter((p) => {
                return filters.countries.some((f) => {
                    return +p.countryOrigin?.id === +f;
                });
            });
        }
        if (filters.productAttributes && filters.productAttributes.length) {
            data = data.filter((p) => {
                return filters.productAttributes.some((f) => {
                    return p.productAttributes.map(t => +t.id).indexOf(+f) > -1;
                });
            });
        }
        if (filters.targets && filters.targets.length) {
            data = data.filter((p) => {
                return filters.targets.some((f) => {
                    return p.targetConsumers.map(t => +t.id).indexOf(+f) > -1;
                });
            });
        }
        return data;
    }

    getProductImageUrls(product: Product): string[] {
        return [product.logoUrl].concat(product.userproductsImages.map(i => i.imageUrl));
    }

    toggleProductInfo(id: number) {
        const index = this.openedInfoProductIds.indexOf(+id);
        if (index === -1) {
            this.openedInfoProductIds.push(+id);
        } else {
            this.openedInfoProductIds = this.openedInfoProductIds.filter(pid => +pid !== +id);
        }
    }

    init() {
        this.products.forEach(p => p.productAttributes = p.rangeOfProducts.concat(p.nutritionalInformations));
        const categories = [];
        for (const product of this.products) {
            if (product.category && categories.findIndex(tag => tag.id === product.category.id) === -1) {
                categories.push(product.category);
            }
            if (product.countryOrigin && this.countryOptions.findIndex(tag => tag.id === product.countryOrigin.id) === -1) {
                this.countryOptions.push(product.countryOrigin);
            }
            for (const targetConsumer of product.targetConsumers) {
                if (this.targetConsumerOptions.findIndex(tag => tag.id === targetConsumer.id) === -1) {
                    this.targetConsumerOptions.push(targetConsumer);
                }
            }
            for (const lifestyle of product.rangeOfProducts) {
                if (this.productAttributesOptions.findIndex(tag => tag.id === lifestyle.id) === -1) {
                    this.productAttributesOptions.push(lifestyle);
                }
            }
            for (const nutritionalInformation of product.nutritionalInformations) {
                if (this.productAttributesOptions.findIndex(tag => tag.id === nutritionalInformation.id) === -1) {
                    this.productAttributesOptions.push(nutritionalInformation);
                }
            }
            for (const qualityLabel of product.qualityLabels) {
                if (this.productLabelOptions.findIndex(tag => tag.id === qualityLabel.id) === -1) {
                    this.productLabelOptions.push(qualityLabel);
                }
            }
        }

        this.hasMultipleFiltersCategory = categories.length > 1;

        for (const category of categories) {
            let entityParent = this.categoryOptions.find(c => c.id === category.parent.id);
            if (!entityParent) {
                this.categoryOptions.push(category.parent);
                entityParent = this.categoryOptions.find(c => c.id === category.parent.id);
            }
            entityParent.children.push(category);
        }
    }

    isProductSelected(idProduct: number) {
        return this.selectedProducts.findIndex(product => +product.id === +idProduct) !== -1;
    }

    handleProductSelection(product: Product) {
        this.isProductSelected(+product.iduserproduct) ?
            (this.selectedProducts = this.selectedProducts.filter(p => +p.iduserproduct !== +product.iduserproduct)) : this.selectedProducts.push(product);
        this.selectedProductsChanged.emit(this.selectedProducts);
    }
}
