import {Injectable} from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import {AuthService} from '../../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class BusinessMappingGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private router: Router
    ) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): UrlTree | boolean {
        const fromReward = this.router.getCurrentNavigation().extras.state?.fromReward;
        const completed = this.authService.getMember().businessMapping?.completed;

        if (route.url[0]?.path === 'create') {
            const urlTree = this.router.parseUrl('/business-mapping');
            if (fromReward) {
                urlTree.queryParams = this.router.getCurrentNavigation().extras.state;
            }
            return completed ? urlTree : true;
        }

        const urlTree = this.router.parseUrl('/business-mapping/create');
        if (fromReward) {
            urlTree.queryParams = this.router.getCurrentNavigation().extras.state;
        }
        return completed ? true : urlTree;
    }
}
