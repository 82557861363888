<div class="ndl-stepper">
    <ng-content select="[ndl-stepper-prefix]"></ng-content>
<ng-container *ngFor="let step of steps; index as index; last as last; first as first;">
        <ng-container *ngIf="step.stepLabel === undefined; else step.stepLabel?.template">
            <a mat-ripple class="ndl-step"
               [routerLink]="null"
               [class.done]="selectedIndex > index"
               [class.clickable]="step.editable"
               [class.selected]="selectedIndex === index"
               (click)="selectedIndex = index"
            >
                <div class="ndl-step-bullet">
                    <div class="ndl-stepper-divider-right" [class.none]="first">
                        <div class="ndl-stepper-done-item"></div>
                    </div>
                    <div class="ndl-step-bullet-container">
                        <div class="ndl-step-bullet-container-content">
                            <ng-container *ngIf="selectedIndex <= index">{{index + 1}}</ng-container>
                            <ng-container *ngIf="selectedIndex > index"><span class="material-icons">done</span></ng-container>
                        </div>
                    </div>
                    <div class="ndl-stepper-divider-left" [class.none]="last">
                        <div class="ndl-stepper-done-item"></div>
                    </div>
                </div>

                <div class="ndl-step-label">
                    {{step.label}}
                </div>
            </a>
            <div *ngIf="!last" class="ndl-stepper-divider" [class.done]="selectedIndex > index">
                <div class="ndl-stepper-done-item"></div>
            </div>
        </ng-container>
    </ng-container>
</div>
<div *ngFor="let step of steps; let i = index"
     class="ndl-stepper-content"
     [@horizontalStepTransition]="_getAnimationDirection(i)"
     [id]="_getStepContentId(i)"
     [attr.aria-labelledby]="_getStepLabelId(i)"
     [attr.aria-expanded]="selectedIndex === i">
    <ng-container *ngTemplateOutlet="step.content"></ng-container>
</div>
