import {
    Component, ElementRef,
    HostBinding,
    Input, ViewChild
} from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {NdlStatus} from '../core/types';

@Component({
    selector: 'button[ndlButton], button[ndl-button], a[ndlButton], a[ndl-button]',
    templateUrl: './button.component.html',
    host: {
        class: 'ndl-button'
    }
})
export class NdlButtonDirective {

    @Input() theme: NdlStatus = "primary";

    @HostBinding('style.width')
    @Input() width: string;

    @HostBinding('class.ndl-button-disabled')
    @Input() set disabled(disabled) {
        this._disabled = coerceBooleanProperty(disabled);
    }
    get disabled(): boolean {
        return this._disabled;
    }
    protected _disabled: boolean;

    @HostBinding('class.ndl-button-outlined')
    @Input() set outlined(outlined) {
        this._outlined = coerceBooleanProperty(outlined);
    }
    get outlined(): boolean {
        return this._outlined;
    }
    protected _outlined: boolean;

    @HostBinding('class.ndl-button-borderless')
    @Input() set borderless(borderless) {
        this._borderless = coerceBooleanProperty(borderless);
    }
    get borderless(): boolean {
        return this._borderless;
    }
    protected _borderless: boolean;

    @HostBinding('class.ndl-button-large')
    @Input() set large(large) {
        this._large = coerceBooleanProperty(large);
    }
    get large(): boolean {
        return this._large;
    }
    protected _large: boolean;

    @HostBinding('class.ndl-button-large-md')
    @Input() set largeMd(largeMd) {
        this._largeMd = coerceBooleanProperty(largeMd);
    }
    get largeMd(): boolean {
        return this._largeMd;
    }
    protected _largeMd: boolean;

    @HostBinding('class.ndl-button-small')
    @Input() set small(small) {
        this._small = coerceBooleanProperty(small);
    };
    get small(): boolean {
        return this._small;
    };
    protected _small: boolean;

    @HostBinding('class.ndl-button-radius')
    @Input() set radius(radius) {
        this._radius = coerceBooleanProperty(radius);
    }
    get radius(): boolean {
        return this._radius;
    }
    protected _radius: boolean = true;

    @HostBinding('class.ndl-button-block') _block: boolean;
    @Input() set block(block) {
        this._block = coerceBooleanProperty(block);
    }
    get block(): boolean {
        return this._block;
    }

    get rippleColor() {
        return this.outlined && this.container ? getComputedStyle(this.container.nativeElement).outlineColor.replace(')', ', 0.20)').replace('rgb', 'rgba') : '#FFFFFF25';
    }

    @ViewChild('ndlButtonContainer') container: ElementRef<HTMLSpanElement>;
}
