import {Directive} from '@angular/core';

@Directive({
    selector: '[ndl-button-prefix],[ndlButtonPrefix]',
    host: {
        class: 'ndl-button-prefix'
    }
})
export class NdlButtonPrefixDirective {
}
