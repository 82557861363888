import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NdlRadioFieldComponent} from './radio-field.component';
import {NdlRadioComponent} from './radio.component';
import {NeedlFormFieldModule} from '../form-field/form-field.module';
import {MatRadioModule} from '@angular/material/radio';


@NgModule({
    declarations: [
        NdlRadioComponent,
        NdlRadioFieldComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatRadioModule,
        NeedlFormFieldModule
    ],
    exports: [
        NdlRadioComponent
    ]
})
export class NeedlRadioModule {
}
