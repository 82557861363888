import {Component, OnDestroy, OnInit} from '@angular/core';
import {VideoMeetingService} from "../../../services/wabel-client/services/video_meeting.service";
import {VideoMeeting} from "../../../services/wabel-client/entities/video_meeting";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../services/auth.service";
import * as dayjs from 'dayjs';
import * as isBetween from 'dayjs/plugin/isBetween';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
import {LiveVideoMeetingService} from "../../../services/wabel-client/services/live_video_meeting.service";

@Component({
    selector: 'app-video-meeting',
    templateUrl: './video-meeting.component.html',
    styleUrls: ['./video-meeting.component.scss']
})
export class VideoMeetingComponent implements OnInit, OnDestroy {

    videoMeeting: VideoMeeting;
    dayjs = dayjs;

    constructor(public videoMeetingService: VideoMeetingService,
                private route: ActivatedRoute,
                public authService: AuthService,
                private router: Router,
                public liveVideoMeetingService: LiveVideoMeetingService) {
    }

    ngOnInit() {
        dayjs.extend(isBetween);
        dayjs.extend(utc);
        dayjs.extend(timezone);
        this.route.params.subscribe(
            params => {
                const id = +params['id'];
                if (this.liveVideoMeetingService.videoMeeting && this.liveVideoMeetingService.meetingJoined && +this.liveVideoMeetingService.videoMeeting.id === +id) {
                    this.videoMeeting = this.liveVideoMeetingService.videoMeeting;
                    setTimeout(() => {
                        this.liveVideoMeetingService.placeMeetingIframe();
                    }, 100);
                } else {
                    this.loadMeeting(id);
                }
            }
        );
    }

    ngOnDestroy() {
        this.liveVideoMeetingService.placeMeetingIframeAsFloating();
    }

    loadMeeting(id: number, firstLoad = true) {
        this.videoMeetingService.getVideoMeeting(id).subscribe((videoMeeting) => {
            this.videoMeeting = videoMeeting;
            if (firstLoad) {
                this.liveVideoMeetingService.videoMeeting = this.videoMeeting;
            }
        }, (e) => {
            console.error(e);
            this.router.navigate(['/video-meetings']);
        });
    }

    joinMeeting() {
        this.videoMeetingService.increaseMeetingJoinNumber(this.videoMeeting.id).subscribe(data => {
        }, (e) => {
            console.error(e);
        });
        setTimeout(() => {
            this.liveVideoMeetingService.videoMeeting = this.videoMeeting;
            this.liveVideoMeetingService.meetingJoined = true;
        }, 1);
        setTimeout(() => {
            this.liveVideoMeetingService.placeMeetingIframe();
        }, 2);
    }

    leaveMeeting() {
        this.liveVideoMeetingService.videoMeeting = null;
        this.liveVideoMeetingService.meetingJoined = false;
        this.liveVideoMeetingService.removeMeetingIframe();
    }
}
