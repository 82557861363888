<ndl-page>
    <ndl-stepper #stepper linear [orientation]="'horizontal'">
        <ndl-step #step1 label="Page 1" [completed]="false">
            <form [formGroup]="formGroup1">
                <input formControlName="nom" type="text"/>
                <input formControlName="prenom" type="text"/>
                <input formControlName="address" type="text"/>
                <button type="submit" (click)="step1.completed = true;stepper.next()">Next</button>
            </form>
        </ndl-step>
        <ndl-step label="Page 2" [completed]="false">
            <form *ndlStepContent [formGroup]="formGroups.form2">
                <input formControlName="companyname" type="text"/>
                <input formControlName="jobposition" type="text"/>
                <input formControlName="country" type="text"/>
            </form>
        </ndl-step>
        <ndl-step label="Page 3" [completed]="false">
            <form *ndlStepContent [formGroup]="formGroups.form3">
                <input formControlName="product" type="text"/>
                <input formControlName="product2" type="text"/>
                <input formControlName="product3" type="text"/>
            </form>
        </ndl-step>
    </ndl-stepper>
</ndl-page>
