import {Component, Input, OnInit} from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {BootstrapRowColsNumber} from '../../../core/types';

@Component({
    selector: 'ndl-list-placeholder',
    templateUrl: './list-placeholder.component.html',
    styleUrls: ['../../scss/placeholders.scss']
})
export class NdlListPlaceholderComponent {

    /** Number of rows */
    @Input() rows: number;

    /**
     * Width of the main line
     */
    @Input() width: string = '100%';

    /**
     * Height of the main line
     */
    @Input() height: string = '20px';

    /** If true, adds an image placeholder at the beginning of each row */
    private _prefix: boolean;
    get prefix(): boolean {
        return this._prefix;
    }

    /** If true, adds an image placeholder at the beginning of each row */
    @Input()
    set prefix(value: boolean) {
        this._prefix = coerceBooleanProperty(value);
    }

    /** Width and Height of prefix box */
    @Input() prefixSize: string = "40px";

    /**
     * If true, adds a sub row
     */
    private _subRow: boolean;
    get subRow(): boolean {
        return this._subRow;
    }

    @Input()
    set subRow(value: boolean) {
        this._subRow = coerceBooleanProperty(value);
    }

    /** Height of prefix box */
    subRowHeight: string = "16px";

    /**
     * If true, adds a cell at the end of each row
     */
    private _suffix: boolean;
    get suffix(): boolean {
        return this._suffix;
    }

    @Input()
    set suffix(value: boolean) {
        this._suffix = coerceBooleanProperty(value);
    }

    get rowArray(): null[] {
        return Array(this.rows);
    }
}
