import {WabelEntity} from "./wabel-entity.abstract";
import {Company} from "./company";

export class Banner extends WabelEntity {
    id: number;
    name: string;
    company: Company;

    constructor(obj: any) {
        super(obj);
        this.company = obj.company ? new Company(obj.company) : null;
    }
}
