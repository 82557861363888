import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {NeedlSecurityGuard} from '@wabel/needl-security';
import {IsLoggedGuard} from './guards/is-logged.guard';
import {RIGHT} from "./services/wabel-client/security/right.const";

// Do not put FrontPageModule and AuthModule in lazy loading, because components are linked together and can create inf. loop
const routes: Routes = [
    {
        path: 'business-mapping',
        canActivate: [IsLoggedGuard],
        loadChildren: () => import('./pages/business-mapping/business-mapping.module').then(m => m.BusinessMappingModule)
    },
    {
        path: 'sourcing',
        canActivate: [IsLoggedGuard],
        loadChildren: () => import('./pages/sourcing-project/sourcing-project.module').then(m => m.SourcingProjectModule)
    },
    {
        path: 'srm',
        canActivate: [IsLoggedGuard],
        loadChildren: () => import('./pages/sourcing-relationship-manager/sourcing-relationship-manager.module').then(m => m.SourcingRelationshipManagerModule)
    },
    {
        path: 'membership',
        canActivate: [IsLoggedGuard],
        loadChildren: () => import('./pages/membership/membership.module').then(m => m.MembershipModule)
    },
    {
        path: 'profile',
        canActivate: [IsLoggedGuard],
        loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule)
    },
    {
        path: 'my-prospects',
        canActivate: [IsLoggedGuard],
        loadChildren: () => import('./pages/prospects/prospects.module').then(m => m.ProspectsModule)
    },
    {
        path: '',
        loadChildren: () => import('./modules/company-profile-module/company-profile.module').then(m => m.CompanyProfileModule)
    },
    {
        path: '',
        loadChildren: () => import('./modules/my-profile-module/my-profile.module').then(m => m.MyProfileModule)
    },
    {
        path: '',
        loadChildren: () => import('./modules/product-profile-module/product-profile.module').then(m => m.ProductProfileModule)
    },
    {
        path: '',
        loadChildren: () => import('./modules/product-crud-module/product-crud.module').then(m => m.ProductCrudModule)
    },
    {
        path: '',
        loadChildren: () => import('./modules/eco-pack-project-module/eco-pack-project.module').then(m => m.EcoPackProjectModule)
    },
    {
        path: '',
        loadChildren: () => import('./modules/search-module/search.module').then(m => m.SearchModule)
    },
    {
        path: '',
        loadChildren: () => import('./modules/video-meeting-module/video-meeting.module').then(m => m.VideoMeetingModule)
    },
    {
        path: '',
        loadChildren: () => import('./modules/event-module/event.module').then(m => m.EventModule)
    },
    {
        path: '',
        loadChildren: () => import('./modules/institution-module/institution.module').then(m => m.InstitutionModule)
    },
    {
        path: '',
        loadChildren: () => import('./modules/sourcing-request-module/sourcing-request.module').then(m => m.SourcingRequestModule)
    },
    {
        path: '',
        loadChildren: () => import('./modules/customized-registrations-module/customized-registrations-module.module').then(m => m.CustomizedRegistrationsModuleModule)
    },
    {
        path: '',
        loadChildren: () => import('./modules/new-video-meeting-module/new-video-meeting.module').then(m => m.NewVideoMeetingModule)
    },
    {
        path: 'demo',
        canActivate: [IsLoggedGuard],
        children: [
            {
                path: '',
                canActivate: [NeedlSecurityGuard],
                data: {permissions: [RIGHT.CAN_ACCESS_UI_DEMO]},
                loadChildren: () => import('./modules/demo-module/demo.module').then(m => m.DemoModule)
            }
        ]
    }
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
})
export class AppRoutingModule {
}
