import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {NdlLoadingService} from './loading.service';

export abstract class NdlResolver<T> implements Resolve<T> {
    protected currentRoute: ActivatedRouteSnapshot;

    constructor(private _loadingService: NdlLoadingService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<T> {
        this._loadingService.load();
        this.currentRoute = route;
        return this.getResolveObservable().pipe(tap(() => this._loadingService.endLoading()));
    }

    abstract getResolveObservable(): Observable<T>;
}
