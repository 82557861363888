import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'ndl-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss'],
})
export class NdlSliderComponent {
    @Input() minValue = 0;
    @Input() maxValue = 100;
    @Input() defaultValue = 50;
    @Input() step = 1;
    @Input() labelLeft = null;
    @Input() labelRight = null;
    @Input() showPercentage = false;
    @Output() value = new EventEmitter<number>();
    @Input() keepSimpleDesign = false;

    updateValue(event) {
        this.value.emit(event.value);
    }
}
