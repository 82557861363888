import {ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output} from '@angular/core';


@Component({
  selector: 'ndl-filter-template',
  templateUrl: './filter-template.component.html',
  styleUrls: ['./filter-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class NdlFilterTemplateComponent {
    @Input() label = 'Choose an option';
    @Input() validationButtonLabel = 'Apply';
    @Input() clearButtonLabel = 'Clear';
    @Input() filterNumber = 0;
    @Input() forceUnDisabling = false;
    isListOpened = false;
    clickWasInsideComponent = false;

    @Output() applied = new EventEmitter();
    @Output() cleared = new EventEmitter();
    @Output() listClosed = new EventEmitter();

    constructor() {}

    @HostListener("click", ["$event"])
    clickInsideComponent(e) {
        this.clickWasInsideComponent = true;
    }

    @HostListener("document:click", ["$event"])
    clickOutsideComponent() {
        if (!this.clickWasInsideComponent) {
            this.closeList();
        }
        this.clickWasInsideComponent = false;
    }

    closeList(resetList = true) {
        this.isListOpened = false;
        if (resetList) {
            this.listClosed.emit();
        }
    }

    apply() {
        this.closeList(false);
        this.applied.emit();
    }

    clear() {
        this.closeList();
        this.cleared.emit();
    }
}
