import {Component, Directive, forwardRef, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {CdkStep, CdkStepperPrevious} from "@angular/cdk/stepper";

@Directive({
    selector: '[ndl-step-next]',
    providers: [
        {provide: CdkStepperPrevious, useExisting: NdlStepNewNextDirective}
    ]
})
export class NdlStepNewNextDirective extends CdkStepperPrevious {
}
