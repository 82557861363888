import {NgModule} from "@angular/core";
import {KitLineHiddingComponent} from './kit-html-elements/kit-line-hidding/kit-line-hidding.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ProgressIconComponent} from "./progress-icon/progress-icon.component";
import {KitFormCustomCheckboxComponent} from "./kit-form/kit-form-custom-checkbox/kit-form-custom-checkbox.component";
import {KitFormRadioButtonComponent} from "./kit-form/kit-form-radio-button/kit-form-radio-button.component";
import {KitFormInputComponent} from "./kit-form/kit-form-input/kit-form-input.component";
import {KitFormSelectComponent} from "./kit-form/kit-form-select/kit-form-select.component";
import {KitFormPhoneComponent} from "./kit-form/kit-form-phone/kit-form-phone.component";
import {KitFormTextareaComponent} from "./kit-form/kit-form-textarea/kit-form-textarea.component";
import {KitFormRadioInlineComponent} from "./kit-form/kit-form-radio-inline/kit-form-radio-inline.component";
import {KitFormImageUploadComponent} from "./kit-form/kit-form-image-upload/kit-form-image-upload.component";
import {KitFormCheckboxComponent} from "./kit-form/kit-form-checkbox/kit-form-checkbox.component";
import {KitFormRadioComponent} from "./kit-form/kit-form-radio/kit-form-radio.component";
import {KitFormCountryComponent} from "./kit-form/kit-form-country/kit-form-country.component";
import {KitFormCkeditorComponent} from "./kit-form/kit-form-ckeditor/kit-form-ckeditor.component";
import {KitFormAddressComponent} from "./kit-form/kit-form-address/kit-form-address.component";
import {KitFormInputLinkOptionComponent} from "./kit-form/kit-form-input-link-option/kit-form-input-link-option.component";
import {KitFormGroupedCheckboxesComponent} from "./kit-form/kit-form-grouped-checkboxes/kit-form-grouped-checkboxes.component";
import {KitFormInputFloatComponent} from "./kit-form/kit-form-input-float/kit-form-input-float.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {CKEditorModule} from "ngx-ckeditor";
import {CommonModule} from "@angular/common";
import {JoinNowBuyerComponent} from "./join-now-buyer/join-now-buyer.component";
import {CarouselComponent} from "./carousel/carousel.component";
import {FlickityModule} from 'ngx-flickity';
import {ClickStopPropagationDirective} from "../../directives/click-stop-propagation.directive";
import {TruncatePipe} from "../../pipes/truncate.pipe";
import {UrlEncodePipe} from "../../pipes/url_encode.pipe";
import {SafeUrlPipe, SafeBackgroundUrlPipe} from "../../pipes/safe_url.pipe";
import {DateFormatPipe} from "../../pipes/dateFormat.pipe";
import {SectionScrollDirective} from "../../directives/section-scroll.directive";
import {RetailGroupPopinCategoriesComponent} from "../company-profile-module/company-profile/retail-group/retail-group-popin-categories/retail-group-popin-categories.component";
import {ProductCardComponent} from "./product-card/product-card.component";
import {RouterModule} from "@angular/router";
import {NextSummitBannerComponent} from "./next-summit-banner/next-summit-banner.component";
import {SignupComponent} from "../../pages/signup/signup/signup.component";
import {SignupFormComponent} from "../../forms/signup-form/signup-form.component";
import {CardDocumentComponent} from "./card-document/card-document.component";
import {ContactButtonComponent} from "./contact-button/contact-button.component";
import {NgxPageScrollModule} from 'ngx-page-scroll';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import {LazyLoadImageModule} from "ng-lazyload-image";
import {CompanyCardComponent} from './company-card/company-card.component';
import {RelationshipStatusLabelComponent} from './relationship-status-label/relationship-status-label.component';
import {PaginatorComponent} from './paginator/paginator.component';
import {TooltipComponent} from './tooltip/tooltip.component';
import {SearchBarComponent} from './search-bar/search-bar.component';
import {DropdownRadioOrCheckboxComponent} from './dropdown-radio-or-checkbox/dropdown-radio-or-checkbox.component';
import {KitModalSimpleComponent} from "./kit-html-elements/kit-modal-simple/kit-modal-simple.component";
import {KitModalConfirmationComponent} from './kit-html-elements/kit-modal-confirmation/kit-modal-confirmation.component';
import {IfChangesDirective} from '../../directives/if-changes.directive';
import {ProfileProductCardComponent} from '../../pages/profile/products/profile-product-card/profile-product-card.component';
import {KitModalEmptyComponent} from './kit-html-elements/kit-modal-empty/kit-modal-empty.component';
import {KitSlidesTutoComponent} from './kit-html-elements/kit-slides-tuto/kit-slides-tuto.component';
import {LogOutboundClickDirective} from '../../directives/log-outbound-click.directive';
import {KitFormAttachmentComponent} from "./kit-form/kit-form-attachments/kit-form-attachment.component";
import {KitFormStarRatingComponent} from "./kit-form/kit-form-star-rating/kit-form-star-rating.component";
import { SingleDropdownComponent } from './single-dropdown/single-dropdown.component';
import {MatSelectModule} from "@angular/material/select";
import { SingleAutoComplateComponent } from './single-auto-complate/single-auto-complate.component';
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatInputModule} from "@angular/material/input";
import {MatChipsModule} from "@angular/material/chips";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {PlasticTypePopinPackagingMaterialComponent} from "./plastic-type-popin-packaging-material/plastic-type-popin-packaging-material.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { KitFormChipsComponent } from './kit-form/kit-form-chips/kit-form-chips.component';
import {MatCardModule} from "@angular/material/card";
import {KitProgressBarComponent} from "./kit-html-elements/kit-progress-bar/kit-progress-bar.component";
import {
    NeedlAlertModule,
    NeedlButtonsModule,
    NeedlSpinnersModule,
    NeedlInputPhoneModule,
    NeedlPlaceholdersModule,
    NeedlCheckboxesModule,
    NeedlSelectsModule,
    NeedlModalsModule,
    NeedlTagModule,
    NeedlCardsModule
} from 'needl-lib';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {VideoMeetingRequestComponent} from "./video-meeting-request-modal/video-meeting-request.component";
import {ThirdPartyCalendarListComponent} from "./third-party-calendar-list/third-party-calendar.component";
import {ExternalLinkDirective} from "../../directives/external-link.directive";
import {ScheduleVideoMeetingButtonComponent} from './schedule-video-meeting-button/schedule-video-meeting-button.component';
import {ProfileSubmissionComponent} from './company-profile-submission-modal/profile-submission.component';
import {CompanyOldSubmissionsRecapModalComponent} from './company-old-submissions-recap-modal/company-old-submissions-recap-modal.component';
import {MatStepperModule} from "@angular/material/stepper";
import {CompanyProfileStepperComponent} from "./company-profile-submission-modal/company-profile-stepper/company-profile-stepper.component";
import {SubmissionSubjectStepComponent} from "./company-profile-submission-modal/submission-subject-step/submission-subject-step.component";
import {SubmissionExportingCountriesStepComponent} from './company-profile-submission-modal/submission-exporting-countries-step/submission-exporting-countries-step.component';
import {SubmissionClientPartnerStepComponent} from './company-profile-submission-modal/submission-client-partner-step/submission-client-partner-step.component';
import {SubmissionCategoriesStepComponent} from './company-profile-submission-modal/submission-categories-step/submission-categories-step.component';
import {SubmissionProductsStepComponent} from './company-profile-submission-modal/submission-products-step/submission-products-step.component';
import {SubmissionDocumentsStepComponent} from './company-profile-submission-modal/submission-documents-step/submission-documents-step.component';
import {SubmissionPreviewStepComponent} from './company-profile-submission-modal/submission-preview-step/submission-preview-step.component';
import {TagPickerComponent} from "./tag-picker/tag-picker.component";
import {NeedlSecurityModule} from "@wabel/needl-security";
import {HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {ArrayToCommaSeparatedValuesPipe} from "../../pipes/array-to-comma-separated-values.pipe";
import {PremiumModalTemplateComponent} from "./premium-modal-template/premium-modal-template.component";
import {TourMatMenuModule} from "ngx-ui-tour-md-menu";
import {CompanyProfileFavoriteButtonComponent} from "../company-profile-module/blocks/company-profile-favorite-button/company-profile-favorite-button.component";
import {PremiumBaseModalTemplateComponent} from "./premium-base-modal-template/premium-base-modal-template.component";
import {Image404Directive} from "../../directives/image404.directive";
import {CurrencyUnitsMinimizePipe} from "../../pipes/currency-units-minimize.pipe";
import {MatTooltipModule} from "@angular/material/tooltip";
import {GoogleTagManagerModule} from '../../directives/gtm/gtm.module';

@NgModule({
    declarations: [
        KitLineHiddingComponent,
        ProgressIconComponent,
        KitFormCustomCheckboxComponent,
        KitFormRadioButtonComponent,
        KitFormInputComponent,
        KitFormSelectComponent,
        KitFormPhoneComponent,
        KitFormTextareaComponent,
        KitFormRadioInlineComponent,
        KitFormImageUploadComponent,
        KitFormCheckboxComponent,
        KitFormRadioComponent,
        KitFormCountryComponent,
        KitFormCkeditorComponent,
        KitFormAddressComponent,
        KitFormInputLinkOptionComponent,
        KitFormGroupedCheckboxesComponent,
        KitFormInputFloatComponent,
        JoinNowBuyerComponent,
        CarouselComponent,
        ClickStopPropagationDirective,
        SectionScrollDirective,
        TruncatePipe,
        DateFormatPipe,
        UrlEncodePipe,
        SafeUrlPipe,
        SafeBackgroundUrlPipe,
        RetailGroupPopinCategoriesComponent,
        ProductCardComponent,
        NextSummitBannerComponent,
        SignupComponent,
        SignupFormComponent,
        CardDocumentComponent,
        ContactButtonComponent,
        CompanyCardComponent,
        RelationshipStatusLabelComponent,
        PaginatorComponent,
        TooltipComponent,
        SearchBarComponent,
        DropdownRadioOrCheckboxComponent,
        KitModalSimpleComponent,
        KitModalConfirmationComponent,
        IfChangesDirective,
        ProfileProductCardComponent,
        KitModalEmptyComponent,
        KitSlidesTutoComponent,
        LogOutboundClickDirective,
        KitFormAttachmentComponent,
        KitFormStarRatingComponent,
        KitFormAttachmentComponent,
        SingleDropdownComponent,
        SingleAutoComplateComponent,
        PlasticTypePopinPackagingMaterialComponent,
        KitFormChipsComponent,
        KitProgressBarComponent,
        VideoMeetingRequestComponent,
        ExternalLinkDirective,
        ScheduleVideoMeetingButtonComponent,
        ProfileSubmissionComponent,
        ThirdPartyCalendarListComponent,
        CompanyOldSubmissionsRecapModalComponent,
        CompanyProfileStepperComponent,
        SubmissionSubjectStepComponent,
        SubmissionExportingCountriesStepComponent,
        SubmissionClientPartnerStepComponent,
        SubmissionCategoriesStepComponent,
        SubmissionProductsStepComponent,
        SubmissionDocumentsStepComponent,
        SubmissionPreviewStepComponent,
        TagPickerComponent,
        ArrayToCommaSeparatedValuesPipe,
        PremiumModalTemplateComponent,
        CompanyProfileFavoriteButtonComponent,
        PremiumBaseModalTemplateComponent,
        Image404Directive,
        CurrencyUnitsMinimizePipe
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        HttpClientJsonpModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        CKEditorModule,
        FlickityModule,
        NgxPageScrollCoreModule.forRoot(),
        LazyLoadImageModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatInputModule,
        MatChipsModule,
        MatIconModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatCardModule,
        NeedlButtonsModule,
        NeedlSpinnersModule,
        BsDropdownModule.forRoot(),
        NeedlPlaceholdersModule,
        NeedlAlertModule,
        NeedlInputPhoneModule,
        MatStepperModule,
        NeedlSecurityModule,
        NeedlCheckboxesModule,
        NeedlModalsModule,
        NeedlTagModule,
        NeedlCardsModule,
        CommonModule,
        TourMatMenuModule,
        MatTooltipModule,
        GoogleTagManagerModule
    ],
    entryComponents: [
        VideoMeetingRequestComponent
    ],
    exports: [
        KitLineHiddingComponent,
        ProgressIconComponent,
        KitFormCustomCheckboxComponent,
        KitFormRadioButtonComponent,
        KitFormInputComponent,
        KitFormSelectComponent,
        KitFormPhoneComponent,
        KitFormTextareaComponent,
        KitFormRadioInlineComponent,
        KitFormImageUploadComponent,
        KitFormCheckboxComponent,
        KitFormRadioComponent,
        KitFormCountryComponent,
        KitFormCkeditorComponent,
        KitFormAddressComponent,
        KitFormInputLinkOptionComponent,
        KitFormGroupedCheckboxesComponent,
        KitFormInputFloatComponent,
        JoinNowBuyerComponent,
        CarouselComponent,
        ClickStopPropagationDirective,
        SectionScrollDirective,
        TruncatePipe,
        DateFormatPipe,
        UrlEncodePipe,
        SafeUrlPipe,
        SafeBackgroundUrlPipe,
        RetailGroupPopinCategoriesComponent,
        ProductCardComponent,
        NextSummitBannerComponent,
        SignupComponent,
        SignupFormComponent,
        CardDocumentComponent,
        ContactButtonComponent,
        NgxPageScrollModule,
        LazyLoadImageModule,
        CompanyCardComponent,
        RelationshipStatusLabelComponent,
        PaginatorComponent,
        TooltipComponent,
        SearchBarComponent,
        DropdownRadioOrCheckboxComponent,
        KitModalSimpleComponent,
        KitModalConfirmationComponent,
        ProfileProductCardComponent,
        KitModalEmptyComponent,
        KitSlidesTutoComponent,
        LogOutboundClickDirective,
        KitFormAttachmentComponent,
        KitFormStarRatingComponent,
        SingleDropdownComponent,
        SingleAutoComplateComponent,
        PlasticTypePopinPackagingMaterialComponent,
        KitFormChipsComponent,
        KitProgressBarComponent,
        VideoMeetingRequestComponent,
        ExternalLinkDirective,
        ScheduleVideoMeetingButtonComponent,
        ProfileSubmissionComponent,
        ThirdPartyCalendarListComponent,
        CompanyOldSubmissionsRecapModalComponent,
        CompanyProfileStepperComponent,
        TagPickerComponent,
        SubmissionPreviewStepComponent,
        NeedlSecurityModule,
        NeedlCheckboxesModule,
        NeedlButtonsModule,
        NeedlSelectsModule,
        NeedlSpinnersModule,
        ArrayToCommaSeparatedValuesPipe,
        PremiumModalTemplateComponent,
        TourMatMenuModule,
        CompanyProfileFavoriteButtonComponent,
        PremiumBaseModalTemplateComponent,
        Image404Directive,
        CurrencyUnitsMinimizePipe
    ]
})
export class SharedModule {
}
