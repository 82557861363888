<div class="multi-select">
    <div class="input-trigger" [class.disabled]="selectionLimit <= selectedOptions.length"
         (click)="valueInput.focus()" [class.opened]="isListOpened && value && value.length" [class.error]="showError">
        <div class="input-tags-container">
            <div class="input-tag" *ngFor="let option of selectedOptions">
                <ndl-tag removable (removed)="removeOption(option); isListOpened = !isListOpened; showErrorTooManySelectionsAfterClick = false;">
                    <span class="tag-content" title="{{option}}">{{option.label}}</span>
                </ndl-tag>
            </div>
            <ng-container>
                <span class="input-trigger-open" [class.disabled]="selectionLimit <= selectedOptions.length"
                      (click)="checkSelectionsLimit($event)">
                    <span class="material-icons-outlined">add_circle_outline</span>
                    <input class="input-search-value" type="text" (ngModelChange)="valueChanged.emit($event)"
                           [disabled]="selectionLimit <= selectedOptions.length"
                           [title]="selectionLimit <= selectedOptions.length ? 'There is a limit of ' + selectionLimit + ' selections!' : ''"
                           [(ngModel)]="value" #valueInput placeholder="{{placeholder}}"
                           (keyup.enter)="selectOptionAsCustomStringValue(value)"/>
                </span>
            </ng-container>
            <span *ngIf="selectedOptions.length" (click)="clearList(); isListOpened = !isListOpened"
                  class="material-icons input-clear">clear</span>
        </div>
    </div>
    <div *ngIf="isListOpened && selectedOptions.length <= selectionLimit" class="input-list">
        <div *ngIf="value" class="input-list-item" title="{{value}}" (click)="selectOptionAsCustomStringValue(value)">
            <span class="material-icons-outlined">add_circle_outline</span>
            <span class="input-list-item-label">{{value}}</span>
        </div>
        <ng-container *ngIf="options && options.length">
            <span class="input-list-title">{{inputListTitle}}</span>
            <div class="input-list-items">
                <div *ngFor="let option of options" class="input-list-item col-6" title="{{option.label}}"
                     (click)="selectOption(option)">
                    <span class="material-icons-outlined">add_circle_outline</span>
                    <span class="input-list-item-label">{{option.label}}</span>
                </div>
            </div>
        </ng-container>
    </div>
    <p class="text-muted p-0 small">Select from list or start writing and click enter.</p>
    <p class="text-danger mt-2" *ngIf="showErrorTooManySelectionsAfterClick">
        There is a limit of {{selectionLimit}} selections. <br>Please remove some of your choices if you want to add
        more.</p>
    <p class="text-danger" *ngIf="showError">{{ errorText }}</p>
</div>
