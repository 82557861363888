import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from "@angular/forms";
import {NdlCollapseComponent} from "./collapse.component";
import {NdlCollapseHeaderDirective} from "./collapse-header.directive";
import {CdkAccordionModule} from '@angular/cdk/accordion';
import {MatIconModule} from '@angular/material/icon';
import {MatRippleModule} from '@angular/material/core';
import {NdlAccordionDirective} from './accordion.directive';

@NgModule({
    declarations: [
        NdlCollapseComponent,
        NdlCollapseHeaderDirective,
        NdlAccordionDirective
    ],
    imports: [
        CommonModule,
        FormsModule,
        CdkAccordionModule,
        MatIconModule,
        MatRippleModule
    ],
    exports: [
        NdlCollapseComponent,
        NdlCollapseHeaderDirective,
        NdlAccordionDirective
    ],
})
export class NeedlCollapsesModule { }
