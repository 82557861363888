import gql from "graphql-tag";

export class CompanyTypeGraphQL {
    static fragments = {};

    static queries = {
        companyTypesForBusinessMappingPriorities: gql`
            query companyTypesForBusinessMappingPriorities {
                companyTypesForBusinessMappingPriorities {
                    items {
                        idcompanytype,
                        name
                    }
                }
            }`,
    };

    static mutations = {};
}
