<ndl-form-field #formField [prefix]="prefix" [suffix]="suffix" [label]="label" [hint]="hint" [errors]="errors">
    <ng-template #prefix>
        <ng-content select="[ndl-prefix]"></ng-content>
        <mat-datepicker-toggle [for]="matDatepicker"></mat-datepicker-toggle>
    </ng-template>

    <input [matDatepicker]="matDatepicker" [min]="minDate" [max]="maxDate"
           [formControl]="innerFormControl"
           (click)="matDatepicker.open()" [required]="required" [name]="name"
           [disabled]="disabled" [placeholder]="placeholder" (blur)="onTouched()" matInput autocomplete="off"
           (change)="onChange($event.target.value)"
           (dateChange)="setUtcDateValue($event.target.value)">
    <mat-datepicker #matDatepicker></mat-datepicker>

    <ng-template #suffix>
        <button mat-icon-button type="button" *ngIf="!formField.empty && !disabled" (click)="clear()">
            <mat-icon>close</mat-icon>
        </button>
        <ng-content select="[ndl-suffix]"></ng-content>
    </ng-template>
    <ng-container *ngIf="!ngControl?.control?.errors?.required && error">
        <ndl-error>{{error}}</ndl-error>
    </ng-container>
</ndl-form-field>
