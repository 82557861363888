export const RIGHT = {
    ACCESS_BO : 'ACCESS_BO',
    CAN_ACCESS_MY_DASHBOARD  : 'CAN_ACCESS_MY_DASHBOARD',
    CAN_CONTACT_BUYERS : 'CAN_CONTACT_BUYERS',
    CAN_CONTACT_SUPPLIERS : 'CAN_CONTACT_SUPPLIERS',
    CAN_MANAGE_ALL_COMPANIES_PROFILE: 'CAN_MANAGE_ALL_COMPANIES_PROFILE',
    CAN_MANAGE_ALL_PRODUCTS: 'CAN_MANAGE_ALL_PRODUCTS',
    CAN_MANAGE_ALL_SOURCING_REQUESTS: 'CAN_MANAGE_ALL_SOURCING_REQUESTS',
    CAN_MANAGE_MY_COMPANY_PROFILE: 'CAN_MANAGE_MY_COMPANY_PROFILE',
    CAN_MANAGE_MY_FAVORITES: 'CAN_MANAGE_MY_FAVORITES',
    CAN_MANAGE_MY_PROSPECTS_LIST: 'CAN_MANAGE_MY_PROSPECTS_LIST',
    CAN_MANAGE_MY_MESSAGES: 'CAN_MANAGE_MY_MESSAGES',
    CAN_MANAGE_MY_COMPANY_MESSAGES: 'CAN_MANAGE_MY_COMPANY_MESSAGES',
    CAN_MANAGE_MY_PARTICIPATION: 'CAN_MANAGE_MY_PARTICIPATION',
    CAN_MANAGE_MY_PRODUCTS: 'CAN_MANAGE_MY_PRODUCTS',
    CAN_MANAGE_MY_RELATIONSHIPS : 'CAN_MANAGE_MY_RELATIONSHIPS',
    CAN_MANAGE_MY_COMPANY_RELATIONSHIPS : 'CAN_MANAGE_MY_COMPANY_RELATIONSHIPS',
    CAN_MANAGE_MY_SOURCING_REQUESTS_BUYER: 'CAN_MANAGE_MY_SOURCING_REQUESTS_BUYER',
    CAN_MANAGE_MY_SOURCING_REQUESTS_SUPPLIER: 'CAN_MANAGE_MY_SOURCING_REQUESTS_SUPPLIER',
    CAN_REGISTER_FOR_EVENT : 'CAN_REGISTER_FOR_EVENT',
    CAN_SEARCH_BUYERS : 'CAN_SEARCH_BUYERS',
    CAN_SEARCH_SUPPLIERS : 'CAN_SEARCH_SUPPLIERS',
    CAN_SEARCH_PACKAGING : 'CAN_SEARCH_PACKAGING',
    SUPER_ADMIN: 'SUPER_ADMIN',
    CAN_MANAGE_ROLES: 'CAN_MANAGE_ROLES',
    CAN_MANAGE_ADMIN_ROLES: 'CAN_MANAGE_ADMIN_ROLES',
    CAN_ACCESS_SEARCH : 'CAN_ACCESS_SEARCH',
    CAN_ACCESS_ANONYMOUS_EVENT_CATALOG_RETAILER : 'CAN_ACCESS_ANONYMOUS_EVENT_CATALOG_RETAILER',
    ACCESS_EVENT_CATALOG_PACKAGING_SUPPLIER : 'ACCESS_EVENT_CATALOG_PACKAGING_SUPPLIER',
    ACCESS_EVENT_CATALOG_MANUFACTURER : 'ACCESS_EVENT_CATALOG_MANUFACTURER',
    ACCESS_EVENT_CATALOG_RETAILER : 'ACCESS_EVENT_CATALOG_RETAILER',
    CAN_MANAGE_MY_SOURCING_NEEDS_BUYER: 'CAN_MANAGE_MY_SOURCING_NEEDS_BUYER',
    CAN_DO_EVENT_SELECTION: 'CAN_DO_EVENT_SELECTION',
    CAN_MANAGE_COMPANY_EVENT_PARTICIPANTS: 'CAN_MANAGE_COMPANY_EVENT_PARTICIPANTS',
    CAN_ACCESS_AGENDA_OF_MY_COMPANY: 'CAN_ACCESS_AGENDA_OF_MY_COMPANY',
    CAN_BE_SELECTED_EVENT: 'CAN_BE_SELECTED_EVENT',
    CAN_RATE_AS_BUYER: 'CAN_RATE_AS_BUYER',
    CAN_RATE_AS_SELLER: 'CAN_RATE_AS_SELLER',
    CAN_REQUEST_EVENT_SAMPLES: 'CAN_REQUEST_EVENT_SAMPLES',
    CAN_SEND_EVENT_SAMPLES: 'CAN_SEND_EVENT_SAMPLES',
    CAN_ACCESS_UI_DEMO: 'CAN_ACCESS_UI_DEMO',
    CAN_DO_EVENT_MEETING_CONFIRMATIONS: 'CAN_DO_EVENT_MEETING_CONFIRMATIONS',
    CAN_DISPLAY_FULL_SOURCING_NEEDS_INFO: 'CAN_DISPLAY_FULL_SOURCING_NEEDS_INFO',
    CAN_SUBMIT_MY_COMPANY_PROFILE: 'CAN_SUBMIT_MY_COMPANY_PROFILE',
    CAN_MANAGE_MY_SOURCING_NEEDS_SELLER: 'CAN_MANAGE_MY_SOURCING_NEEDS_SELLER',
    CAN_ACCESS_FULL_SEARCH_BUYERS: 'CAN_ACCESS_FULL_SEARCH_BUYERS',
    CAN_ACCESS_FULL_FILTERS_BUYERS: 'CAN_ACCESS_FULL_FILTERS_BUYERS',
    CAN_MANAGE_MY_ECO_PACK_PROJECTS: 'CAN_MANAGE_MY_ECO_PACK_PROJECTS',
    CAN_MANAGE_MY_BUYING_SCOPE: 'CAN_MANAGE_MY_BUYING_SCOPE',
    CAN_MANAGE_MY_SUBMISSIONS: 'CAN_MANAGE_MY_SUBMISSIONS',
    CAN_SEE_FULL_PRODUCT_PAGE: 'CAN_SEE_FULL_PRODUCT_PAGE',
    CAN_SEE_FULL_BUYER_COMPANY_PAGE: 'CAN_SEE_FULL_BUYER_COMPANY_PAGE',
    CAN_SEE_FULL_SELLER_COMPANY_PAGE: 'CAN_SEE_FULL_SELLER_COMPANY_PAGE',
    CAN_MANAGE_MY_COMPANY_MEMBERS: 'CAN_MANAGE_MY_COMPANY_MEMBERS',
    CAN_SEE_MY_COMPANY_MEMBERS: 'CAN_SEE_MY_COMPANY_MEMBERS',
    CAN_MANAGE_MY_COMPANY_ON_BOARDING: 'CAN_MANAGE_MY_COMPANY_ON_BOARDING',
    CAN_SEE_MY_FULL_PRODUCT_PAGE: 'CAN_SEE_MY_FULL_PRODUCT_PAGE',
    CAN_SEE_MY_FULL_SELLER_COMPANY_PAGE: 'CAN_SEE_MY_FULL_SELLER_COMPANY_PAGE',
    CAN_SEE_MY_FULL_BUYER_COMPANY_PAGE: 'CAN_SEE_MY_FULL_BUYER_COMPANY_PAGE',
    CAN_SEE_BUYERS_DOCUMENTS: 'CAN_SEE_BUYERS_DOCUMENTS',
    CAN_SEE_MY_BUYER_DOCUMENTS: 'CAN_SEE_MY_BUYER_DOCUMENTS',
    CAN_SEE_BUYER_GEOGRAPHICAL_BREAKDOWN: 'CAN_SEE_BUYER_GEOGRAPHICAL_BREAKDOWN',
    CAN_SEE_MY_GEOGRAPHICAL_BREAKDOWN: 'CAN_SEE_MY_GEOGRAPHICAL_BREAKDOWN',
    CAN_HAVE_MEMBERSHIP: 'CAN_HAVE_MEMBERSHIP',
    CAN_ACCESS_ANALYTICS_DASHBOARD_SELLER: 'CAN_ACCESS_ANALYTICS_DASHBOARD_SELLER',
    CAN_ACCESS_FULL_ANALYTICS_DASHBOARD_SELLER: 'CAN_ACCESS_FULL_ANALYTICS_DASHBOARD_SELLER',
    CAN_BUY: 'CAN_BUY',
    CAN_SELL: 'CAN_SELL',
    CAN_SEE_RATINGS: 'CAN_SEE_RATINGS',
    CAN_EDIT_MY_COMPANY_INFORMATION: 'CAN_EDIT_MY_COMPANY_INFORMATION',
    CAN_EDIT_MY_COMPANY_MARKETING: 'CAN_EDIT_MY_COMPANY_MARKETING',
    CAN_EDIT_MY_COMPANY_FACTS_AND_FIGURES: 'CAN_EDIT_MY_COMPANY_FACTS_AND_FIGURES',
    CAN_EDIT_MY_COMPANY_PRODUCTION: 'CAN_EDIT_MY_COMPANY_PRODUCTION',
    CAN_EDIT_MY_COMPANY_CERTIFICATIONS: 'CAN_EDIT_MY_COMPANY_CERTIFICATIONS',
    CAN_EDIT_MY_COMPANY_DISTRIBUTION: 'CAN_EDIT_MY_COMPANY_DISTRIBUTION',
    CAN_EDIT_MY_COMPANY_ECO_PACK_POLICY: 'CAN_EDIT_MY_COMPANY_ECO_PACK_POLICY',
    CAN_ACCESS_REWARDS_PAGE: 'CAN_ACCESS_REWARDS_PAGE',
    CAN_SEE_MY_COMPANY_INFORMATION_FORM: 'CAN_SEE_MY_COMPANY_INFORMATION_FORM',
    CAN_SEE_MY_COMPANY_MARKETING_FORM: 'CAN_SEE_MY_COMPANY_MARKETING_FORM',
    CAN_SEE_MY_COMPANY_FACTS_AND_FIGURES_FORM: 'CAN_SEE_MY_COMPANY_FACTS_AND_FIGURES_FORM',
    CAN_SEE_MY_COMPANY_PRODUCTION_FORM: 'CAN_SEE_MY_COMPANY_PRODUCTION_FORM',
    CAN_SEE_MY_COMPANY_CERTIFICATIONS_FORM: 'CAN_SEE_MY_COMPANY_CERTIFICATIONS_FORM',
    CAN_SEE_MY_COMPANY_DISTRIBUTION_FORM: 'CAN_SEE_MY_COMPANY_DISTRIBUTION_FORM',
    CAN_SEE_MY_COMPANY_ECO_PACK_POLICY_FORM: 'CAN_SEE_MY_COMPANY_ECO_PACK_POLICY_FORM',
    CAN_EDIT_MY_BUSINESS_MAPPING_PRIORITIES: 'CAN_EDIT_MY_BUSINESS_MAPPING_PRIORITIES',
    COULD_SEND_VIDEO_MEETING_REQUEST: 'COULD_SEND_VIDEO_MEETING_REQUEST',
    CAN_SEND_VIDEO_MEETING_REQUEST: 'CAN_SEND_VIDEO_MEETING_REQUEST',
    CAN_EDIT_MY_BUSINESS_MAPPING_MARKET: 'CAN_EDIT_MY_BUSINESS_MAPPING_MARKET',
    CAN_SEE_MY_BUSINESS_MAPPING: 'CAN_SEE_MY_BUSINESS_MAPPING',
    CAN_SEE_MY_COMPANY_CLIENTS_FORM: 'CAN_SEE_MY_COMPANY_CLIENTS_FORM',
    CAN_EDIT_MY_COMPANY_CLIENTS: 'CAN_EDIT_MY_COMPANY_CLIENTS',
    CAN_SEE_BUSINESS_MAPPING_RECOMMENDATIONS: 'CAN_SEE_BUSINESS_MAPPING_RECOMMENDATIONS',
    CAN_SEE_MY_PROSPECT_LIST: 'CAN_SEE_MY_PROSPECT_LIST',
    CAN_DO_EVENT_MEETING_RESCHEDULE: 'CAN_DO_EVENT_MEETING_RESCHEDULE',
    CAN_ACCESS_SRM: 'CAN_ACCESS_SRM',
    CAN_ACCESS_SPM: 'CAN_ACCESS_SPM',
    CAN_ACCESS_PACKAGING: 'CAN_ACCESS_PACKAGING',
};
