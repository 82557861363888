import {Component, Input, OnInit} from '@angular/core';
import {UtilsService} from "../../../../services/utils.service";
import {SearchService} from "../../../../services/search.service";

@Component({
  selector: 'app-search-item-suggestion',
  templateUrl: './search-item-suggestion.component.html',
  styleUrls: ['./search-item-suggestion.component.scss', '../search-suggestion/search-suggestion.component.scss']
})
export class SearchItemSuggestionComponent implements OnInit {

    @Input() suggestionData;
    @Input() companyType;
    @Input() suggestionType = 'company';
  constructor(
      public searchService: SearchService,
      public utilsService: UtilsService
  ) { }

  ngOnInit() {
  }

  resetAutoComplete() {
      this.searchService.loading = true;
      this.searchService.suggestionComponent.resetAutoComplete();
  }

}
