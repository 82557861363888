import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {Product} from './wabel-client/entities/product';
import {GraphQLService} from './wabel-client/services/graphql-service';
import {WabelService} from './wabel-client/services/wabel-service.abstract';
import {LoadingService} from './wabel-client/services/loading.service';
import {SearchGraphql} from './wabel-client/graphql/search.graphql';
import {AuthService} from './auth.service';
import {AlgoliaSearch} from './wabel-client/entities/algolia_search';
import {FilterService} from './filter.service';
import {AlgoliaFilter} from './wabel-client/entities/algolia_filter';
import {Company} from "./wabel-client/entities/company";
import {BuyingOffice} from "./wabel-client/entities/buying_office";
import {ISearchService} from "./wabel-client/interfaces/isearchservice";
import {Meeting} from "./wabel-client/entities/meeting";


interface Pagination {
    currentPage: number;
    count: number;
    limit: number;
}

export interface PaginatorList {
    foodServices?: Pagination;
    retailGroups?: Pagination;
    buyingAlliances?: Pagination;
    product?: Pagination;
    supplier?: Pagination;
    buyingOffice?: Pagination;
    importers?: Pagination;
    packaging?: Pagination;
}

export interface OrderByList {
    products?: number;
    suppliers?: number;
    retails?: number;
    buyingOffices?: number;
    importers?: number;
    buyingAlliances?: number;
    foodServices?: number;
    packaging?: number;
}

@Injectable({
    providedIn: 'root'
})
export class SearchService extends WabelService implements ISearchService {
    hideSuggestions = false;

    _searchTerm = '';

    // Observable string sources
    _searchTermObs = new Subject<string>();

    // Observable string streams
    searchTerm$ = this._searchTermObs.asObservable();

    askSampleList: Product[] = [];

    supplierProductDisplayInList = false;

    paginator: PaginatorList;

    searchCurrentTab = 'products';

    orderByList: OrderByList;

    favorite: any = [];

    searchResults: AlgoliaSearch;

    lastSearch: string;
    suggestionComponent: any;

    statusIsLoading: boolean = true;

    notify: any;
    allFilter: AlgoliaFilter[];

    nameByType = {
        3: 'Importers',
        5: 'Food service companies',
        6: 'Retail groups',
        7: 'Retail groups',
        8: 'Buying alliances'
    };
    searchTabByType = {
        3: 'importers',
        5: 'foodServices',
        6: 'retails',
        7: 'buyingOffices',
        8: 'buyingAlliances'
    };

    public loading: boolean = false;

    constructor(protected graphQLService: GraphQLService, loadingService: LoadingService, private authService: AuthService) {
        super(graphQLService, loadingService);
    }

    set searchTerm(search: string) {
        this._searchTerm = search;
        this._searchTermObs.next(search);
    }

    get searchTerm() {
        return this._searchTerm;
    }

    initPaginator(limit: number, forceReset: boolean = false) {
        if (!forceReset && this.paginator && Object.keys(this.paginator).length > 0) {
            return;
        }

        this.paginator = {
            product: {count: 0, currentPage: 1, limit: limit},
            supplier: {count: 0, currentPage: 1, limit: limit},
            packaging: {count: 0, currentPage: 1, limit: limit},
            buyingOffice: {count: 0, currentPage: 1, limit: limit},
            foodServices: {count: 0, currentPage: 1, limit: limit},
            retailGroups: {count: 0, currentPage: 1, limit: limit},
            importers: {count: 0, currentPage: 1, limit: limit},
            buyingAlliances: {
                count: 0, currentPage: 1, limit: limit
            }
        };
    }

    pushNotify(message, timeout = 5000) {
        this.notify = message;
        setTimeout(() => {
            this.notify = null;
        }, timeout);
    }


    askAAsampleRequest(userProductList: Array<number>, addressID: number) {
        return this.mutation(SearchGraphql.mutations.askSampleRequest, {
            products: userProductList,
            addressID: addressID
        });
    }


    askAAsampleRequestForMeeting(userProductList: Array<number>, addressID: number, idMeeting: number = null) {
        return this.mutation(SearchGraphql.mutations.askSampleRequestForMeeting, {
            products: userProductList,
            addressID: addressID,
            idMeeting
        }).pipe(map((data) => {
            return data.data ? new Meeting(data.data.askSampleRequestForMeeting) : null;
        }));
    }


    addProductToSampleList(product: Product) {
        if (this.askSampleList.find(p => p.iduserproduct === product.iduserproduct)) {
            return;
        }

        this.askSampleList.push(product);
    }

    isProductInSampleList(product: Product) {
        return this.askSampleList.find(p => p.iduserproduct === product.iduserproduct);
    }

    removeProductToSampleList(product: Product) {
        this.askSampleList = this.askSampleList.filter(p => p.iduserproduct !== product.iduserproduct);
    }

    closeModal(modalName: string) {
        const modal = document.getElementById(modalName);
        modal.style.display = 'none';
        document.querySelector('body').classList.remove('popin-open');
    }

    toObject(object) {
    }

    resetPaginator() {
        this.paginator = {
            product: {count: 0, currentPage: 1, limit: 16},
            supplier: {count: 0, currentPage: 1, limit: 16},
            packaging: {count: 0, currentPage: 1, limit: 16},
            buyingOffice: {count: 0, currentPage: 1, limit: 16},
            foodServices: {count: 0, currentPage: 1, limit: 16},
            retailGroups: {count: 0, currentPage: 1, limit: 16},
            importers: {count: 0, currentPage: 1, limit: 16},
            buyingAlliances: {
                count: 0, currentPage: 1, limit: 16
            }
        };
    }


    get buyerHasEventParticipationsThisYear(): boolean {
        return this.authService.getMember().company.isServiceProvider() || this.authService.getMember().company.hasEventParticipationsThisYear;
    }
}
