import {
    Component, Input, OnChanges, SimpleChanges
} from '@angular/core';
import {NdlAxis, NdlChartBaseDirective} from './chart-base.directive';
import {NDL_GREEN_INDEX, NdlColors} from '../core/color';
import {
    AxisRendererCircular,
    CandlestickSeries,
    CategoryAxis, ClockHand,
    ColumnSeries, ColumnSeries3D, ConeSeries, CurvedColumnSeries,
    DateAxis,
    DurationAxis, GaugeChart, LineSeries, OHLCSeries, StepLineSeries,
    ValueAxis
} from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';

@Component({
    selector: 'ndl-gauge-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.scss'],
    host: {
        'class': 'ndl-xy-chart'
    }
})
export class NdlGaugeChartComponent extends NdlChartBaseDirective<GaugeChart> implements OnChanges {
    type = GaugeChart;

    @Input() min = 0;
    @Input() max = 100;
    @Input() value: number;
    @Input() title = null;


    reloadSeries(): void {}

    setConfig() {
        this.chart.innerRadius = -20;

        const axis = this.chart.xAxes.push(new ValueAxis<AxisRendererCircular>());
        axis.min = this.min;
        axis.max = this.max;
        axis.strictMinMax = true;

        const gradient = new am4core.LinearGradient();
        gradient.addColor(am4core.color(NdlColors.PALETTE[NDL_GREEN_INDEX][0]));
        gradient.addColor(am4core.color(NdlColors.PALETTE[NDL_GREEN_INDEX][NdlColors.PALETTE[NDL_GREEN_INDEX].length - 1]));

        const range = axis.axisRanges.create();
        range.value = this.min;
        range.endValue = this.max;
        range.axisFill.fillOpacity = 1;
        range.axisFill.fill = gradient;
        range.axisFill.zIndex = -1;

        const label = this.chart.radarContainer.createChild(am4core.Label);
        label.horizontalCenter = "middle";
        label.text = this.title;

        const hand = this.chart.hands.push(new ClockHand());
        hand.pin.disabled = true;
        hand.value = this.value;
    }


    ngOnChanges(changes: SimpleChanges): void {
        if ((changes['value'] || changes['title']) && this.chart) {
            if (changes['value']) {
                this.chart.hands.clear();
                const hand = this.chart.hands.push(new ClockHand());
                hand.pin.disabled = true;
                hand.value = this.value;
            }
            if (changes['title']) {
                this.chart.invalidateLabels();
                for (const child of this.chart.radarContainer.children) {
                    if (child.className === 'Label') {
                        this.chart.radarContainer.children.removeValue(child);
                        const label = this.chart.radarContainer.createChild(am4core.Label);
                        label.horizontalCenter = "middle";
                        label.text = this.title;
                        return;
                    }
                }
            }
        }
    }
}
