import {Pipe, PipeTransform} from '@angular/core';
import * as dayjs from 'dayjs';
import * as advancedFormat from 'dayjs/plugin/advancedFormat';
import * as utc from "dayjs/plugin/utc";
import * as timezone from "dayjs/plugin/timezone";

@Pipe({
    name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
    transform(date: string, format: string, dateTimezone: string = null): string {
        dayjs.extend(advancedFormat);
        dayjs.extend(utc);
        dayjs.extend(timezone);
        return dateTimezone ? dayjs(date).tz(dateTimezone).format(format) : dayjs(date).format(format);
    }
}
