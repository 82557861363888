import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'kit-form-grouped-checkboxes',
    templateUrl: './kit-form-grouped-checkboxes.component.html',
    styleUrls: ['./kit-form-grouped-checkboxes.component.scss']
})

export class KitFormGroupedCheckboxesComponent implements OnInit {

    @Input() classDiv: string;
    @Input() classDivLabel: string;
    @Input() classLabel: string;
    @Input() classDivCheckbox: string;
    @Input() smallSpace: boolean = true;
    @Input() nbPerLine: number = 2;

    @Input() options: any[];
    @Input() values: any[];
    @Input() disabled: boolean = false;

    @Input() labelOptionAttribute: string = '';
    @Input() valueOptionAttribute: string = '';
    @Input() idElementOptionAttribute: string = '';

    @Output() elementHasBeenChecked: EventEmitter<any> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    returnElementHasBeenChecked(value) {
        this.elementHasBeenChecked.emit(value);
    }

}
