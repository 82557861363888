import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NdlPlaceholderComponent} from './components/placeholder/placeholder.component';
import {NdlFormPlaceholderComponent} from './components/form-placeholder/form-placeholder.component';
import {NdlGridPlaceholderComponent} from './components/grid-placeholder/grid-placeholder.component';
import {NdlTextPlaceholderComponent} from './components/text-placeholder/text-placeholder.component';
import {NdlListPlaceholderComponent} from './components/list-placeholder/list-placeholder.component';

@NgModule({
    declarations: [
        NdlPlaceholderComponent,
        NdlFormPlaceholderComponent,
        NdlGridPlaceholderComponent,
        NdlTextPlaceholderComponent,
        NdlListPlaceholderComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        NdlPlaceholderComponent,
        NdlFormPlaceholderComponent,
        NdlGridPlaceholderComponent,
        NdlTextPlaceholderComponent,
        NdlListPlaceholderComponent
    ]
})
export class NeedlPlaceholdersModule {
}
