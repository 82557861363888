import {Injectable, OnInit} from '@angular/core';
import {Company} from "./wabel-client/entities/company";
import {CompanyService} from "./wabel-client/services/company.service";
import {Subject} from "rxjs";
import {PredefinedMessagesTopics} from "./wabel-client/entities/predefined_messages_topics";
import {ResourceService} from "./wabel-client/services/resource.service";
import {TemplateService} from "./template.service";
import {CacheService} from "./cache.service";
import {SearchService} from './search.service';
import {AuthService} from "./auth.service";
import {MessageService} from "./wabel-client/services/message.service";
import {UtilsService} from "./utils.service";
import {NdlSnackbarService} from 'needl-lib';

@Injectable()
export class PrivateMessagesService implements OnInit {
    private predefinedMessagesTopics: PredefinedMessagesTopics[] = [];
    private companyUpdatedSubject: Subject<void> = new Subject<void>();
    private canUpdateCompanySubject: Subject<void> = new Subject<void>();
    messageSendSubject: Subject<any> = new Subject<any>();
    public predefinedChanged: Subject<PredefinedMessagesTopics> = new Subject<PredefinedMessagesTopics>();
    public predefinedSubjectsChanged: Subject<boolean> = new Subject<boolean>();
    private expandPopupSubject: Subject<void> = new Subject<void>();
    private company: Company[] = [];

    private open: boolean = false;
    private messageSent: boolean = false;
    private companyLoading: boolean = true;
    private companyRequest: any = null;
    private messageRequest: any = null;
    private idcompany: number = null;
    private error: string = null;
    private sendingMessageButtonState: string = 'before';
    private companyToLoad: any = null;

    constructor(
        private companyService: CompanyService,
        private resourceService: ResourceService,
        private templateService: TemplateService,
        private cacheService: CacheService,
        private snackbar: NdlSnackbarService,
        public searchService: SearchService,
        private authService: AuthService,
        private messageService: MessageService,
        private utilsService: UtilsService
    ) {
    }

    ngOnInit(): void {
        this.loadPredefinedTopics();
    }

    private loadPredefinedTopics(predefined: PredefinedMessagesTopics = null) {
        this.cacheService.getPredefinedMessages().subscribe(
            data => {
                if (!data) {
                    return;
                }
                this.predefinedMessagesTopics = data.items;
                this.predefinedSubjectsChanged.next(true);
                if (predefined !== null) {
                    this.predefinedChanged.next(predefined);
                }
            }, error => {
                this.error = this.utilsService.detectErrorMessages(error).join('. ');
            });
    }

    isOpen(): boolean {
        return this.open;
    }

    closePopup() {
        this.reset();
        this.open = false;
        this.templateService.unblockScroll();
    }

    openPopup(idcompany: number, company: Company = null, predefined: PredefinedMessagesTopics = null) {
        this.loadPredefinedTopics(predefined);
        if (this.messageSent === true) {
            this.companyUpdatedSubject.next(); // We force a reset of content and subject to avoid warning popup
            this.closePopup();
        }
        if (this.idcompany !== idcompany) {
            this.companyToLoad = {
                idcompany: idcompany,
                company: company
            };


            if (this.idcompany === null) {
                this.updateCompany();
            } else {
                this.canUpdateCompanySubject.next();
            }
        } else {
            this.expandPopupSubject.next();
        }
        if (predefined !== null) {
            setTimeout(() => {
                this.predefinedChanged.next(predefined);
            }, 300);

        }
    }


    openPopupMultiple(company: Company[] = [], predefined: PredefinedMessagesTopics = null) {
        if (this.messageSent === true) {
            this.companyUpdatedSubject.next(); // We force a reset of content and subject to avoid warning popup
            this.closePopup();
        }
        this.company = company;
        this.idcompany = null;

        this.open = true;
        if (predefined !== null) {
            setTimeout(() => {
                this.predefinedChanged.next(predefined);
            }, 300);

        }
    }


    updateCompany() {
        if (this.companyToLoad) {
            this.reset();
            this.idcompany = this.companyToLoad['idcompany'];
            this.open = true; // popup should be open in advance to reveive events
            if (window.innerWidth < 700) this.templateService.blockScroll();
            if (this.companyToLoad['company']) {

                this.company.push(this.companyToLoad['company']);
                this.companyLoading = false;
                this.companyUpdatedSubject.next();

            } else {
                this.loadCompanyDetails();
            }
            this.companyToLoad = null;
        }
    }

    isCompanyLoading(): boolean {
        return this.companyLoading;
    }

    private reset() {
        this.error = null;
        this.messageSent = false;
        this.sendingMessageButtonState = 'before';
        this.companyLoading = true;
        this.company = [];
        this.idcompany = null;
        if (this.companyRequest) {
            this.companyRequest.unsubscribe();
        }
        if (this.messageRequest) {
            this.messageRequest.unsubscribe();
        }
    }

    private loadCompanyDetails() {
        this.companyRequest = this.companyService.companyInfoPrivateMessages(this.idcompany).subscribe(
            data => {
                if (!data) {
                    return;
                }

                this.company.push(data);
                this.companyLoading = false;
                this.companyUpdatedSubject.next();
            }, error => {
                this.error = this.utilsService.detectErrorMessages(error).join('. ');
            });
    }

    companyUpdatedEvent() {
        return this.companyUpdatedSubject.asObservable();
    }

    canUpdateCompanyEvent() {
        return this.canUpdateCompanySubject.asObservable();
    }

    getCompany(): Company[] {
        return this.company;
    }

    sendingMessageState(): string {
        return this.sendingMessageButtonState;
    }

    sendMessageToCompany(subject: string, message: string, snackbarCallback: (id) => any): string {
        if (subject === '') {
            this.error = 'No subject entered';
            return 'no_subject';
        }
        if (message === '') {
            this.error = 'No message entered';
            return 'no_message';
        }
        if (!this.idcompany) {
            this.error = 'No company selected';
            return 'no_company';
        }
        this.sendingMessageButtonState = 'loading';
        this.messageRequest = this.messageService.sendPrivateMessageToCompany([this.idcompany], subject, message, null).subscribe(
            data => {
                if (!data) {
                    return;
                }
                this.messageSent = true;
                let idthread = null;
                if (data.length === 1) {
                    idthread = data[0].idthread;
                }
                snackbarCallback(idthread);
                this.messageSendSubject.next({'idcompany': this.idcompany, 'idthread': idthread});

            }, error => {
                this.sendingMessageButtonState = 'error';
                this.messageSent = false;
            });
        return 'message_sending';
    }

    sendMessageToCompanies(companies: Company[], subject: string, message: string): string {
        if (subject === '') {
            this.error = 'No subject entered';
            return 'no_subject';
        }
        if (message === '') {
            this.error = 'No message entered';
            return 'no_message';
        }
        if (companies.length !== 0) {
            this.error = 'No company selected';
            return 'no_company';
        }
        this.sendingMessageButtonState = 'loading';
        for (let company of companies) {
            this.messageRequest = this.messageService.sendPrivateMessageToCompany([company.idcompany], subject, message).subscribe(
                data => {
                    if (!data) {
                        return;
                    }
                    this.messageSent = true;
                }, error => {
                    this.sendingMessageButtonState = 'error';
                    this.messageSent = false;
                });
        }

        return 'message_sending';
    }

    isMessageSent(): boolean {
        return this.messageSent;
    }

    getError(): string {
        return this.error;
    }

    getPredefinedMessagesTopics(): PredefinedMessagesTopics[] {
        return this.predefinedMessagesTopics;
    }

    expandPopupEvent() {
        return this.expandPopupSubject.asObservable();
    }
}
