<div class="container-fluid">
    <div class="row">
        <div class="col-3">
            <h4>Faces 3</h4>
            <ndl-rating [(ngModel)]="rating"></ndl-rating>
        </div>
        <div class="col-3">
            <h4>Faces 5</h4>
            <ndl-rating [(ngModel)]="rating" [range]="5"></ndl-rating>
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <h4>Stars 3</h4>
            <ndl-rating [(ngModel)]="rating" type="stars"></ndl-rating>
        </div>
        <div class="col-3">
            <h4>Stars 5</h4>
            <ndl-rating [(ngModel)]="rating" type="stars" [range]="5"></ndl-rating>
        </div>
    </div>
    <div class="row">
        <div class="col">
            Value: {{this.rating}}
        </div>
    </div>
</div>
