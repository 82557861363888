import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NeedlFormFieldModule} from '../form-field/form-field.module';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from "@angular/material/form-field";
import {NdlCascaderComponent} from "./cascader.component";
import {MatMenuModule} from "@angular/material/menu";
import {MatSelectModule} from "@angular/material/select";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {NeedlCheckboxesModule} from "../../checkboxes/checkboxes.module";
import {MenupatchDirective} from "./menupatch.directive";

@NgModule({
    declarations: [
        NdlCascaderComponent,
        MenupatchDirective
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatChipsModule,
        MatIconModule,
        MatFormFieldModule,
        NeedlFormFieldModule,
        FormsModule,
        MatMenuModule,
        MatSelectModule,
        MatAutocompleteModule,
        NeedlCheckboxesModule
    ],
    exports: [
        NdlCascaderComponent,
        MenupatchDirective
    ]
})
export class NeedlCascaderModule {
}
