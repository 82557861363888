import {Injectable} from "@angular/core";
import {VideoMeeting} from "../entities/video_meeting";
import {VideoMeetingService} from "./video_meeting.service";

@Injectable({
    providedIn: 'root'
})
export class LiveVideoMeetingService {

    private _videoMeeting: VideoMeeting;
    meetingTimer = {minutes: 0, seconds: 0};
    timerInterval: any;
    meetingJoined = false;
    isFloating = false;

    videoMeetingForBanner: VideoMeeting;

    constructor(private videoMeetingService: VideoMeetingService) {
    }

    set videoMeeting(videoMeeting: VideoMeeting) {
        this._videoMeeting = videoMeeting;
        if (this.videoMeeting) {
            this.initTimer();
        }
    }

    get videoMeeting() {
        return this._videoMeeting;
    }

    initTimer() {
        if (!this.timerInterval) {
            if (this.videoMeeting.secondsBeforeEnd > 60) {
                this.meetingTimer.minutes = Math.floor(this.videoMeeting.secondsBeforeEnd / 60);
                this.meetingTimer.seconds = Math.floor(this.videoMeeting.secondsBeforeEnd % 60);
            } else {
                this.meetingTimer.minutes = 0;
                this.meetingTimer.seconds = this.videoMeeting.secondsBeforeEnd;
            }

            this.timerInterval = setInterval(() => {
                if (this.videoMeeting.secondsBeforeEnd > 0) {
                    this.videoMeeting.secondsBeforeEnd--;
                }
                this.setMeetingTimer();
            }, 1000);
        }
    }

    setMeetingTimer() {
        if (this.meetingTimer.seconds === 0 && this.meetingTimer.minutes > 0) {
            this.meetingTimer.seconds = 59;
            this.meetingTimer.minutes--;
        } else if (this.meetingTimer.seconds === 0 && this.meetingTimer.minutes === 0) {
            if (this.timerInterval) {
                clearInterval(this.timerInterval);
                this.timerInterval = undefined;
            }
            this.meetingJoined = false;
            if (this.videoMeetingForBanner.id === this.videoMeeting.id) {
                this.videoMeetingForBanner = null;
            }
            this.videoMeeting = null;
        } else {
            this.meetingTimer.seconds--;
        }
    }

    placeMeetingIframe() {
        this.isFloating = false;
        const liveMeetingIframeContainer = document.getElementById('live-video-meeting-container');
        const container = document.getElementById('video-meeting-container');

        if (liveMeetingIframeContainer && container) {
            liveMeetingIframeContainer.style.position = 'absolute';
            liveMeetingIframeContainer.style.height = container.offsetHeight + 'px';
            liveMeetingIframeContainer.style.width = container.offsetWidth + 'px';

            liveMeetingIframeContainer.style.top = (container.getBoundingClientRect().top + window.pageYOffset) + 'px';
            liveMeetingIframeContainer.style.left = (container.getBoundingClientRect().left + window.pageXOffset) + 'px';
            liveMeetingIframeContainer.style.right = 'auto';
            liveMeetingIframeContainer.style.bottom = 'auto';
        }
    }

    placeMeetingIframeAsFloating() {
        this.isFloating = true;
        const liveMeetingIframeContainer = document.getElementById('live-video-meeting-container');

        if (liveMeetingIframeContainer) {
            liveMeetingIframeContainer.style.position = 'fixed';
            liveMeetingIframeContainer.style.height = '169px';
            liveMeetingIframeContainer.style.width = '300px';

            liveMeetingIframeContainer.style.left = 'auto';
            liveMeetingIframeContainer.style.right = '15px';
            liveMeetingIframeContainer.style.top = 'auto';
            liveMeetingIframeContainer.style.bottom = '15px';
        }
    }

    removeMeetingIframe() {
        const liveMeetingIframeContainer = document.getElementById('live-video-meeting-container');

        if (liveMeetingIframeContainer) {
            liveMeetingIframeContainer.style.position = 'absolute';
            liveMeetingIframeContainer.style.height = '0';
            liveMeetingIframeContainer.style.width = '0';
        }
    }

    initVideoMeetingForBanner() {
        this.videoMeetingService.getCurrentOrNextVideoMeeting().subscribe((vm) => {
            if (vm) {
                setTimeout(() => {
                    this.videoMeetingForBanner = vm;
                }, vm.secondsBeforeStart ? vm.secondsBeforeStart * 1000 : 1);
                setTimeout(() => {
                    this.videoMeetingForBanner = null;
                    this.initVideoMeetingForBanner();
                }, vm.secondsBeforeEnd * 1000);
            }
        });
    }
}
