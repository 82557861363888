import { WabelEntity } from "./wabel-entity.abstract";
import { Tag } from "./tag";

export class TagType extends WabelEntity {
    constructor(obj: any) {
        super(obj);
        if (obj) {
            if (obj.tags) {
                if (obj.tags.items) {
                    this.tags = obj.tags.items.map(t => new Tag(t));
                } else {
                    this.tags = obj.tags.map(t => new Tag(t));
                }
            }
        }
    }

    id: number;
    name: string;
    internalName: string;
    tags: Tag[] = [];
}
