import {Component, OnInit} from '@angular/core';
import {SourcingPriorityService} from "../../../services/wabel-client/services/sourcing_priority.service";

@Component({
    selector: 'landing-sourcing-menu',
    templateUrl: './landing-sourcing-menu.component.html',
    styleUrls: ['./landing-sourcing-menu.component.scss'],
    host: {
        '(window:resize)': 'andXMoreCount()'
    }
})
export class LandingSourcingMenuComponent implements OnInit {

    options = [];
    types;
    showTuto: boolean = false;
    loading: boolean = true;

    constructor(public sourcingPriorityService: SourcingPriorityService) {

    }

    ngOnInit() {
        this.sourcingPriorityService.getSourcingPriorities().subscribe((data) => {
            if (!data) return;
            this.options = data;
            this.types = Array.from(new Set(this.options.map((item: any) => item.type)));
            this.types.sort(function (a, b) {
                if (a < b) return -1;
                if (a > b) return 1;
                return 0;
            });
            this.loading = false;
            setTimeout(() => {
                this.andXMoreCount();
            }, 1);
        });
    }

    ngAfterViewInit() {
    }

    andXMoreCount() {
        if (!this.loading) {
            let sourcingPriorities = document.querySelectorAll('.wb-items ul li') as NodeListOf<any>;
            let wbAndMore = document.querySelector('.wb-and-more') as HTMLElement;
            if (sourcingPriorities.length && wbAndMore) {
                let totalSourcingPriorities = sourcingPriorities.length;
                let firstSourcingPriorityOffsetTop = sourcingPriorities[0].offsetTop;
                let counter = 0;
                for (let i in sourcingPriorities) {
                    if (sourcingPriorities[i].offsetTop === firstSourcingPriorityOffsetTop) {
                        ++counter;
                    }
                }

                if ((totalSourcingPriorities - counter) > 0) {
                    document.querySelector('.wb-and-more span').innerHTML = 'And ' + (totalSourcingPriorities - counter) + ' more';
                    wbAndMore.style.display = 'inline-block';
                } else {
                    wbAndMore.style.display = 'none';
                }
            }
        }
    }

}
