import {animate, animateChild, AUTO_STYLE, group, query, state, style, transition, trigger} from "@angular/animations";

export const NDL_ROUTER_ANIMATION =
    trigger('ndl-router-animate', [
        transition('HomePage <=> AboutPage', [
            style({position: 'relative'}),
            query(':enter, :leave', [
                style({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%'
                })
            ]),
            query(':enter', [
                style({left: '-100%'})
            ]),
            query(':leave', animateChild()),
            group([
                query(':leave', [
                    animate('300ms ease-out', style({left: '100%'}))
                ]),
                query(':enter', [
                    animate('300ms ease-out', style({left: '0%'}))
                ])
            ]),
            query(':enter', animateChild()),
        ]),
        transition('* <=> FilterPage', [
            style({position: 'relative'}),
            query(':enter, :leave', [
                style({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%'
                })
            ]),
            query(':enter', [
                style({left: '-100%'})
            ]),
            query(':leave', animateChild()),
            group([
                query(':leave', [
                    animate('200ms ease-out', style({left: '100%'}))
                ]),
                query(':enter', [
                    animate('300ms ease-out', style({left: '0%'}))
                ])
            ]),
            query(':enter', animateChild()),
        ])
    ]);

export const NDL_COLLAPSE_ANIMATION =
        trigger('ndl-collapse-animate', [
            state('opened', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
            state('closed', style({ height: '0', visibility: 'hidden' })),
            transition('opened => closed', animate(200 + 'ms linear')),
            transition('closed => opened', animate(200 + 'ms linear'))
        ]);
