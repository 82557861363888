<div class="container-fluid">
    <div *ngFor="let theme of themes" class="row mb-5">
        <div class="col">
            <h1>{{theme | titlecase}}</h1>
            <ndl-tag [theme]="theme">My tag</ndl-tag>
            <ndl-tag [theme]="theme" removable>My removable tag</ndl-tag>
            <ndl-tag [theme]="theme" disabled>My disabled tag</ndl-tag>
        </div>
    </div>
</div>
