import {Component, Input, OnInit} from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Component({
    selector: 'ndl-text-placeholder',
    templateUrl: './text-placeholder.component.html',
    styleUrls: ['../../scss/placeholders.scss', './text-placeholder.component.scss']
})
export class NdlTextPlaceholderComponent {
    /** Number of lines placeholders displayed */
    @Input() lines = 4;

    /** Width of the whole paragraph */
    @Input() width: string = '100%';

    /** Height of each lines */
    @Input() height: string = '15px';

    /** Alignement of the placeholders inside of the component  */
    @Input() align: string = 'left';

    get rowArray(): null[] {
        return Array(this.lines);
    }

    getLabelWidth(index: number): string {
        if (index % 4 === 1) {
            return '80%';
        }
        if (index % 4 === 2) {
            return '95%';
        }
        if (index % 4 === 3) {
            return '70%';
        }
        return '90%';
    }
}
