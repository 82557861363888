import {Injectable} from "@angular/core";
import {WabelService} from "./wabel-service.abstract";
import {GraphQLService} from "./graphql-service";
import {LoadingService} from "./loading.service";
import {map} from "rxjs/operators";
import { VideoMeetingInvitations } from "../entities/video_meeting_invitations";
import { VideoMeetingInvitationGraphQL } from "../graphql/video_meeting_invitation.graphql";
import {FromToMemberType, userDefinedDataType} from "../custom_types";

@Injectable({
    providedIn: 'root'
})
export class VideoMeetingInvitationService extends WabelService {
    constructor(graphqlService: GraphQLService, loadingService: LoadingService) {
        super(graphqlService, loadingService);
    }

    private _currentZoomVideoMeeting: VideoMeetingInvitations;

    toArrayOfObjects(objects: any[]): VideoMeetingInvitations[] {
        return objects.map((invitation: any) => this.toObject(invitation));
    }

    toObject(invitation: any): VideoMeetingInvitations {
        return new VideoMeetingInvitations(invitation);
    }

    convertToString( data: any){
        let converted: string = JSON.stringify( data )
        return converted
    }

    updateStatusVideoMeetingInvitation( idvideomeetinginvitation: number , status: string , reason?: string) {
        return this.mutation( VideoMeetingInvitationGraphQL.mutations.updateStatusVideoMeetingInvitation, {idvideomeetinginvitation , status , reason})
            .pipe(map((data) => data.data && data.data.updateStatusVideoMeetingInvitation ? new VideoMeetingInvitations(data.data.updateStatusVideoMeetingInvitation) :  null ));
    }

    acceptSlotVideoMeetingInvitation( idvideomeetinginvitation: number , idinvitationslot: number) {
        return this.mutation( VideoMeetingInvitationGraphQL.mutations.acceptSlotVideoMeetingInvitation, {idvideomeetinginvitation , idinvitationslot})
            .pipe(map((data) => data.data && data.data.acceptSlotVideoMeetingInvitation ? new VideoMeetingInvitations(data.data.acceptSlotVideoMeetingInvitation) :  null ));
    }

    sendVideoMeetingInvitationToGuest(videoMeetingId: number, firstName: string, lastName: string, email: string) {
        return this.mutation(VideoMeetingInvitationGraphQL.mutations.sendVideoMeetingInvitationToGuest, {
            videoMeetingId,
            firstName,
            lastName,
            email
        })
            .pipe(map((data) => data.data && data.data.sendVideoMeetingInvitationToGuest ? new VideoMeetingInvitations(data.data.sendVideoMeetingInvitationToGuest) : null));
    }

    sendVideoMeetingInvitationToMember(videoMeetingId: number, memberId: number) {
        return this.mutation(VideoMeetingInvitationGraphQL.mutations.sendVideoMeetingInvitationToMember, {
            videoMeetingId,
            memberId
        })
            .pipe(map((data) => data.data && data.data.sendVideoMeetingInvitationToMember ? new VideoMeetingInvitations(data.data.sendVideoMeetingInvitationToMember) : null));
    }

    getVideoMeetingInvitationsForCalender( idmember?: number ) {
        return this.query( VideoMeetingInvitationGraphQL.queries.getVideoMeetingInvitationsForCalender , idmember && {idmember})
            .pipe(map((data) => data.data && data.data.videoMeetingInvitationsForCalender ? this.toArrayOfObjects(data.data.videoMeetingInvitationsForCalender) :  [] ));
    }

    getVideoMeetingInvitationsTemporaryBookedSlots( idmember?: number ) {
        return this.query( VideoMeetingInvitationGraphQL.queries.getVideoMeetingInvitationsTemporaryBookedSlots , idmember && {idmember})
            .pipe(map((data) => data.data && data.data.videoMeetingInvitationsTemporaryBookedSlots ? this.toArrayOfObjects(data.data.videoMeetingInvitationsTemporaryBookedSlots) :  [] ));
    }

    getVideoMeetingInvitationsById( idvideomeetinginvitation: number ) {
        return this.query( VideoMeetingInvitationGraphQL.queries.getVideoMeetingInvitationsById , {idvideomeetinginvitation})
            .pipe(map((data) => data.data && data.data.videoMeetingInvitationsById ? new VideoMeetingInvitations(data.data.videoMeetingInvitationsById) :  null ));
    }

    getVideoMeetingByHashID(hashId: string) {
        return this.query(VideoMeetingInvitationGraphQL.queries.getVideoMeetingByHashID, {hashId})
            .pipe(map((data) => data.data && data.data.videoMeetingByHashID ? new VideoMeetingInvitations(data.data.videoMeetingByHashID) : null));
    }

    rescheduleVideoMeetingInvitationSlots( idvideomeetinginvitation: number , invitation_data: userDefinedDataType ,fromToData:FromToMemberType) {
        return this.mutation( VideoMeetingInvitationGraphQL.mutations.rescheduleVideoMeetingInvitationSlots,
            {
                idvideomeetinginvitation ,
                invitation_data: this.convertToString(invitation_data),
                fromToData:this.convertToString(fromToData)
            })
            .pipe(map((data) => data.data && data.data.rescheduleVideoMeetingInvitationSlots ? new VideoMeetingInvitations(data.data.rescheduleVideoMeetingInvitationSlots) :  null ));
    }

    set currentZoomVideoMeeting(videoMeeting: VideoMeetingInvitations) {
        this._currentZoomVideoMeeting = videoMeeting;
    }

    get currentZoomVideoMeeting() {
        return this._currentZoomVideoMeeting;
    }

    getMyVideoUpcomingMeetings(limit: number = null, search: string, statuses: string[] = []) {
        return this.query(VideoMeetingInvitationGraphQL.queries.getMyVideoUpcomingMeetings, {limit, search, statuses})
            .pipe(map((data) => data.data && data.data && data.data.myUpcomingVideoMeetings.items ? this.toArrayOfObjects(data.data.myUpcomingVideoMeetings.items) : null));
    }
}
