import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NdlUtilsService {

    static cleanHtmlString(str: string = null): string {
        if (!str) {
            return '';
        }
        const element = document.createElement('div');

        if (str) {
            // strip script/html tags
            str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
            str = str.replace(/<style[^>]*>([\S\s]*?)<\/style>/gmi, '');
            str = str.replace(/(<([^>]+)>)/gi, '');
            str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
            element.innerHTML = str;
            str = element.textContent;
            element.textContent = '';
        }

        return str;
    }
}
