<ndl-form-field #formField [suffix]="suffix" [label]="label" [hint]="hint" [errors]="errors">

    <ndl-checkboxes-field [ngControl]="ngControl" [required]="required" [maxHeight]="maxHeight" [height]="height"
                          [errorState]="innerFormControl?.errors && innerFormControl?.touched"
                          [placeholder]="placeholder" [displayMode]="displayMode"
                          [identifier]="identifier" [options]="options" [groups]="groups" [searchable]="searchable"
                          (change)="onChange($event)" (blur)="onTouched()">
    </ndl-checkboxes-field>

    <ng-template #suffix>
        <ng-content select="[ndl-suffix]"></ng-content>
    </ng-template>
</ndl-form-field>
