import {
    Component, Input
} from '@angular/core';
import {NdlChartBaseDirective, NdlAxis} from './chart-base.directive';
import {NdlColors} from '../core/color';
import {
    CandlestickSeries,
    CategoryAxis,
    ColumnSeries, ColumnSeries3D, ConeSeries, CurvedColumnSeries,
    DateAxis,
    DurationAxis, LineSeries, OHLCSeries, StepLineSeries,
    ValueAxis,
    XYChart
} from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';

@Component({
    selector: 'ndl-xy-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.scss'],
    host: {
        'class': 'ndl-xy-chart'
    }
})
export class NdlXyChartComponent extends NdlChartBaseDirective<XYChart> {
    type = XYChart;

    @Input() x: NdlAxis;
    @Input() y: NdlAxis;
    @Input() dataConfigs: NdlXYDataConfig[];

    setConfig() {
        if (this.x) {
            const XAxis = this.chart.xAxes.push(this.getAxes(this.x));
            XAxis.title.text = this.x.title;
            XAxis.renderer.minGridDistance = 50;
            XAxis.renderer.grid.template.location = 0.5;
            XAxis.startLocation = 0.5;
            XAxis.endLocation = 0.5;
        } else {
            throw new Error("NdlXYChart: You must define at least one X Axis for the chart.");
        }
        if (this.y) {
            const YAxis = this.chart.yAxes.push(this.getAxes(this.y));
            YAxis.title.text = this.y.title;
        } else {
            throw new Error("NdlXYChart: You must define at least one Y Axis for the chart.");
        }

        if (this.dataConfigs) {
            const palette = NdlColors.getColorPalette(0, true, 0);

            this.dataConfigs.forEach((conf, index) => {
                const color = am4core.color(palette[index]);
                this.configureSeries(conf, color);
            });
        } else {
            throw new Error("NdlXYChart: You must define at least one dataConfig for the chart.");
        }
    }

    getAxes(axis: NdlAxis) {
        switch (axis.type) {
            case 'Category':
                const amAxis = new CategoryAxis();
                amAxis.dataFields.category = axis.type;
                return amAxis;
            case 'Date':
                const dateAxis = new DateAxis();
                dateAxis.dateFormats.setKey("day", "MM/dd");
                return dateAxis;
            case 'Duration':
                return new DurationAxis();
            case 'Value':
                return new ValueAxis();
        }
    }

    configureSeries(dataConfig: NdlXYDataConfig, color: am4core.Color) {
        let series;
        switch (dataConfig.type) {
            case 'Candlestick':
                series = this.chart.series.push(new CandlestickSeries());
                break;
            case 'Column':
                series = this.chart.series.push(new ColumnSeries());
                break;
            case 'Column3D':
                series = this.chart.series.push(new ColumnSeries3D());
                break;
            case 'Cone':
                series = this.chart.series.push(new ConeSeries());
                break;
            case 'CurvedColumn':
                series = this.chart.series.push(new CurvedColumnSeries());
                break;
            case 'StepLine':
                series = this.chart.series.push(new StepLineSeries());
                series.stroke = color;
                series.strokeWidth = 3;
                series.tensionX = 0.8;
                break;
            case 'OHLC':
                series = this.chart.series.push(new OHLCSeries());
                break;
            case 'Line':
            default:
                series = this.chart.series.push(new LineSeries());
                series.stroke = color;
                series.strokeWidth = 3;
                series.tensionX = 0.95;
                break;
        }

        const xParamName = this.x.type.toLowerCase() + 'X';
        const yParamName = this.y.type.toLowerCase() + 'Y';
        series.dataFields[xParamName] = dataConfig.xParam;
        series.dataFields[yParamName] = dataConfig.yParam;
        series.name = dataConfig.name;
    }

    reloadSeries(): void {}
}

export interface NdlXYDataConfig {
    type: "Candlestick"|"Column"|"Column3D"|"Cone"|"CurvedColumn"|"Line"|"StepLine"|"OHLC";
    name: string;
    xParam: string;
    yParam: string;
}
