import {Component, Input, ViewEncapsulation} from '@angular/core';
import {coerceBooleanProperty} from "@angular/cdk/coercion";

@Component({
    selector: 'ndl-hint',
    templateUrl: './hint.component.html'
})
export class NdlHintComponent {
    _closable: boolean;
    @Input('closable') set closable(closable) {
        this._closable = coerceBooleanProperty(closable);
    }
    closed = false;
}
