import {
    AfterContentInit,
    AfterViewInit,
    ContentChild,
    ContentChildren,
    Directive,
    DoCheck,
    EventEmitter,
    Input,
    IterableDiffers,
    OnInit,
    Output, QueryList
} from '@angular/core';
import {NdlPaginatedCallback, NdlDataSource, NdlFilterCallback, NdlSortingCallback, NdlSortEvent} from "./data-source";
import {NdlListItemDirective} from "./list-item.directive";
import {AbstractControl} from "@angular/forms";
import {NdlPaginatorBase} from "./base-paginator.component";
import {Observable} from "rxjs";
import {NdlListPlaceholderDirective} from './list-placeholder.directive';
import {CdkDragDrop, CdkDragSortEvent, moveItemInArray} from "@angular/cdk/drag-drop";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {SelectionModel} from '@angular/cdk/collections';
import {NdlListActionDirective} from './list-action.directive';
import {NdlListFilterDirective} from './list-filter.directive';
import * as XLSX from 'xlsx';

@Directive()
export abstract class NdlListBaseDirective<T = any> implements OnInit, AfterContentInit, AfterViewInit, DoCheck {
    _selection: SelectionModel<number>;
    dataSource: NdlDataSource<T>;

    iterableDiffer: any;

    private _dataProvider: T[] | Observable<T[]> | NdlPaginatedCallback<T>;

    get loading() {
        return this.dataSource.loading;
    }

    get selection(): T[] {
        return this._selection?.selected.map(i => this.dataSource.data[i]);
    }

    clearSelection() {
        this._selection.clear();
    }

    @Input() listClass: string;
    @Input() itemClass: string;

    @Input() get data() {
        return this._dataProvider;
    }

    set data(data: NdlPaginatedCallback<T> | Observable<T[]> | T[]) {
        this._dataProvider = data;
        if (this.dataSource) {
            this.dataSource.data$ = this._dataProvider;
        }
    }

    private _dragDrop = false;
    @Input() get dragDrop() {
        return this._dragDrop;
    }

    set dragDrop(dragDrop: boolean) {
        this._dragDrop = coerceBooleanProperty(dragDrop);
    }

    @Input() paginator: NdlPaginatorBase;
    @Input() filters: AbstractControl;
    @Input() filterCallback: NdlFilterCallback<T>;
    @Output() dragged = new EventEmitter<CdkDragSortEvent>();
    @ContentChild(NdlListPlaceholderDirective) placeholderTemplate: NdlListPlaceholderDirective;
    @ContentChild(NdlListFilterDirective) ndlFilter: NdlListFilterDirective;
    @ContentChildren(NdlListActionDirective) actions: QueryList<NdlListActionDirective>;

    constructor(private iterableDiffers: IterableDiffers) {
        this.iterableDiffer = iterableDiffers.find([]).create(null);
    }

    ngOnInit() {
        this.dataSource = new NdlDataSource<T>(this.data);
        this.dataSource.paginator = this.paginator;
        if (this.paginator) {
            this.paginator.dataSource = this.dataSource;
        }

        this._selection = new SelectionModel<number>(true);
    }

    ngDoCheck() {
        if (this.data instanceof Array) {
            if (this.iterableDiffer.diff(this.data)) {
                this.dataSource.data$ = this.data;
            }
        }
    }

    ngAfterContentInit() {
        this.dataSource.filterForm = this.ndlFilter?.form?.control ?? this.filters;
        this.dataSource.filterCallback = this.ndlFilter?._callback ?? this.filterCallback;
    }

    ngAfterViewInit() {
        this.paginator?.emitFirstPageEvent();
        this.filters?.updateValueAndValidity({onlySelf: false, emitEvent: true});
        this.dataSource?.refresh$.next();
    }

    showPlaceholder(i, data) {
        return data.loading && data.paginator && i <= (data.dataSource.render$.value.length % data.paginator.pageSize || data.paginator.pageSize);
    }

    select(index: number) {
        this._selection.select(index);
    }

    toggle(index: number) {
        return this._selection.toggle(index);
    }

    toggleAll() {
        if (!this.isAllSelected()) {
            this.dataSource.data.forEach((data, i) => this.select(i));
        } else {
            this.dataSource.data.forEach((data, i) => this.toggle(i));
        }
    }

    isSelected(index: number) {
        return this._selection.isSelected(index);
    }

    isAllSelected() {
        return this._selection.selected.length && this._selection.selected.length === this.dataSource.data.length;
    }

    update(item: T, index: number) {
        this.dataSource.data[index] = item;
    }

    drop(event: CdkDragDrop<string[]>) {
        if (this.data instanceof Array) {
            moveItemInArray(this.data, event.previousIndex, event.currentIndex);
        }
    }

    refresh() {
        this.dataSource.refresh$.next();
    }


    /**
     * @param format Callback that formats a data: T to an exportable JSON object
     * @param fileName
     * @param exportSelection
     */
    export(format: (data: T) => any, fileName = 'export', exportSelection = false) {
        /* generate worksheet */
        const formattedData = exportSelection ? this.selection.map(format) : this.dataSource.data.map(format);

        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(formattedData);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, fileName + '.xlsx');
    }
}
