import {Company} from "./company";
import {WabelEntity} from "./wabel-entity.abstract";
import {UserInfoTagManager} from "./user_info_tag_manager";
import {Registration} from "./registration";
import {FileToUpload} from "./fileToUpload";
import {Favorites} from "./favorites";
import {ChartResultItem} from "./chart_result_item";
import {CompanyRelationship} from "./company_relationship";
import {FilterSource} from "./filter_source";
import {Summit} from "./summit";
import {SummitRegistration} from './summit_registration';
import {EventRegistration} from "./event_registration";
import {MemberNotification} from "./member_notification";
import {PrivateMessageThread} from "./private_message_thread";
import {Address} from "./Address";
import {Tag} from "./tag";
import {File} from "./file";
import {WebRTCResult} from "./webrtc_test";
import {EventOrganizer} from "./event_organizer";
import {BusinessMapping} from './business_mapping';
import {VideoMeeting} from "./video_meeting";
import {SourcingProject} from './sourcing_project';
import {SourcingProjectFolder} from './sourcing_project_folder';

export class Member extends WabelEntity {
    constructor(obj: any) {
        super(obj);
        if (obj) {
            this.categories = obj.categories && obj.categories.items ? obj.categories.items.map(category => new Tag(category)) : (obj.categories && obj.categories.length ? obj.categories.map(category => new Tag(category)) : []);
            this.favorites = obj.favorites && obj.favorites.items ? obj.favorites.items.map(favorite => new Favorites(favorite)) : [];
            this.fileAvatar = obj.fileAvatar ? new File(obj.fileAvatar) : null;
            this.threadWithCompany = obj.threadWithCompany ? new PrivateMessageThread(obj.threadWithCompany) : null;
            this.company = obj.company ? new Company(obj.company) : null;
            this.status = obj.status ? obj.status : null;
            this.userInfoTagManager = obj.userDataLayerTagManager ? new UserInfoTagManager(obj.userDataLayerTagManager) : null;
            this.buyingScopeCategories = obj.buyingScopeCategories && obj.buyingScopeCategories.items ? obj.buyingScopeCategories.items.map(category => new Tag(category)) : [];
            this.buyingScopeActivities = obj.buyingScopeActivities && obj.buyingScopeActivities.items ? obj.buyingScopeActivities.items.map(activity => new Tag(activity)) : [];
            this.buyingScope = obj.buyingScope && obj.buyingScope.items ? obj.buyingScope.items.map(tag => new Tag(tag)) : [];
            this.summitRegistrations = obj.summitRegistrations && obj.summitRegistrations.items ? obj.summitRegistrations.items.map(sm => new SummitRegistration(sm)) : null;
            this.registrations = obj.registrations ? obj.registrations.map(registration => new Registration(registration)) : null;
            this.lastOpenedSelectionUpcomingRegistrationNotFinished = obj.lastOpenedSelectionUpcomingRegistrationNotFinished ? new Registration(obj.lastOpenedSelectionUpcomingRegistrationNotFinished) : null;
            this.relationshipsCount = obj.relationships && obj.relationships.count ? obj.relationships.count : null;
            this.relationshipsUpdatedByWabelCount = obj.relationshipsUpdatedByWabel && obj.relationshipsUpdatedByWabel.count ? obj.relationshipsUpdatedByWabel.count : null;
            this.certainEventRegistrations = obj.certainEventRegistrations && obj.certainEventRegistrations.items.length > 0 ? obj.certainEventRegistrations.items.map(reg => new EventRegistration(reg)) : null;
            this.notifications = obj.notifications && obj.notifications.length > 0 ? obj.notifications.map(notification => new MemberNotification(notification)) : [];
            this.privateMessagesThreads = obj.privateMessagesThreads && obj.privateMessagesThreads.items && obj.privateMessagesThreads.items.length && obj.privateMessagesThreads.items.length > 0 ? obj.privateMessagesThreads.items.map(privateMessagesThread => new PrivateMessageThread(privateMessagesThread)) : [];
            this.lastWebRTCResult = obj.lastWebRTCResult ? new WebRTCResult(obj.lastWebRTCResult) : null;
            this.wabel = obj.wabel ? new EventOrganizer(obj.wabel) : null;
            this.eventOrganizersWhereIParticipate = obj.eventOrganizersWhereIParticipate && obj.eventOrganizersWhereIParticipate.items ? obj.eventOrganizersWhereIParticipate.items.map(eo => new EventOrganizer(eo)) : [];
            this.businessMapping = obj.businessMapping ? new BusinessMapping(obj.businessMapping) : null;
            this.currentOrNextVideoMeeting = obj.currentOrNextVideoMeeting ? new VideoMeeting(obj.currentOrNextVideoMeeting) : null;
            this.sourcingProjectFolders = obj.sourcingProjectFolder?.items ? obj.sourcingProjectFolder.items.map(f => new SourcingProjectFolder(f)) : [];
        }
    }

    zohoId: string;
    joinMode: string;
    signupFinished: string;
    idmember: number;
    firstname: string;
    fullname: string;
    name: string;
    nameInitials: string;
    email: string;
    company: Company;
    title: string;
    locale: string;
    logoUrl: string;
    realLogoUrl: string;
    jobtitle: string;
    status: string;
    rejectionReason: string;
    profileScore: number;
    mobile: string;
    mobile_event: string;
    country: Tag;
    categories: Tag[];
    favorites: Favorites[];
    buyingScopeCategories: Tag[];
    buyingScopeActivities: Tag[];
    buyingScope: Tag[];
    summitRegistrations: SummitRegistration[];
    forcePasswordChange: boolean;
    hasPassword: boolean;
    isMasquerading: boolean;
    hasEmailVerified: boolean;
    userInfoTagManager: UserInfoTagManager;
    registrations: Registration[];
    lastOpenedSelectionUpcomingRegistrationNotFinished: Registration;
    isCompanyOwner: boolean;
    currentMemberLogo: File[] = [];
    relationshipsCounter: ChartResultItem[];
    relationshipsCount: number;
    relationshipsUpdatedByWabelCount: number;
    relationships: { count: number, items: CompanyRelationship[] };
    sourceFilters: FilterSource[];
    summitsRegistration: Summit[];
    certainEventRegistrations: EventRegistration[];
    notifications: MemberNotification[];
    permissions: string[];
    privateMessagesThreads: PrivateMessageThread[];
    countUnreadMessages: number;
    needlTcAccepted: boolean;
    eventOptinSms: boolean;
    eventOptinEmail: boolean;
    timezone: string;
    selectionToolSampleRequestAddress: Address;
    defaultDeliveryAddress: Address;
    hasBeenReactivated: boolean;
    hasOneParticipationToPackagingEvent: boolean;
    fileAvatar: File;
    // Temporary field to display ad on homepage & side menu my-profile
    hasCategoryHPC: boolean;
    // Fields used only for uploading files
    newMemberLogo: FileToUpload[] = [];
    activeSourcingNeedsCount: number;

    threadWithCompany: PrivateMessageThread;
    hasVideoMeetingsWithCompany: boolean;
    wabel: EventOrganizer;
    eventOrganizersWhereIParticipate: EventOrganizer[];

    lastWebRTCResult: WebRTCResult;
    businessMapping: BusinessMapping;

    currentOrNextVideoMeeting: VideoMeeting;

    hasMarketBusinessOpportunities: boolean;
    sourcingProjects: SourcingProject[];
    sourcingProjectFolders: SourcingProjectFolder[];

    get id(): number {
        return +this.idmember;
    }

    set id(id: number) {
        this.idmember = id;
    }

    get itemName() {
        return this.fullname;
    }

    get multiSelectGroup() {
        return null;
    }


    isBuyer(): boolean {
        return this.company.isBuyer();
    }

    isSupplier(): boolean {
        return this.company.isSupplier();
    }

    isImporter(): boolean {
        return this.company.isImporter();
    }

    isPackagingSupplier(): boolean {
        return this.company.isPackagingSupplier();
    }

    isServiceProvider(): boolean {
        return this.company.isServiceProvider();
    }

    isRetailGroup(): boolean {
        return this.company.isRetailGroup();
    }

    isBuyingOffice(): boolean {
        return this.company.isBuyingOffice();
    }

    isBuyingAlliance(): boolean {
        return this.company.isBuyingAlliance();
    }

    isCoPacking(): boolean {
        return this.company.isCoPacking();
    }

    getTypeOfMember(): string {
        return this.company.getTypeOfCompany();
    }

    isAdmin(): boolean {
        return this.permissions.indexOf('ACCESS_BO') !== -1;
    }

    isVerified(): boolean {
        return this.hasEmailVerified && ['accepted', 'auto_accepted'].indexOf(this.status) !== -1;
    }

    isUnverified(): boolean {
        return this.status === 'unverified' || !this.hasEmailVerified || this.status === 'rejected';
    }

    isRejected(): boolean {
        return this.status === 'rejected';
    }

    isWabelClubMember(): boolean {
        return this.company.isWabelClubMember;
    }

    hasNotificationAccepted(internalName: string): boolean {
        const index = this.notifications.findIndex(notif => notif.internalName === internalName);
        if (index !== -1) {
            return this.notifications[index].status === 'accepted';
        } else {
            return false;
        }
    }

    hasNotificationPending(internalName: string): boolean {
        const index = this.notifications.findIndex(notif => notif.internalName === internalName);
        if (index !== -1) {
            return this.notifications[index].status === 'pending';
        } else {
            return false;
        }
    }

    getNotification(internalName: string) {
        const notification = this.notifications.find(notif => notif.internalName === internalName);
        if (notification) {
            return notification;
        }
        return null;
    }

    changeNotificationStatusOrCreateOne(internalName: string, status: string) {
        const notification = this.notifications.find(notif => notif.internalName === internalName);
        if (notification) {
            notification.status = status;
        } else {
            const newNotification = new MemberNotification({
                internalName: internalName,
                idMember: this.idmember,
                status: status
            });
            this.notifications.push(newNotification);
        }
    }

    setNotifications(notifications: MemberNotification[]) {
        this.notifications = notifications;
    }

    hasProfilePicture() {
        return this.logoUrl;
    }

    setLogoUrl(logoUrl: string) {
        this.logoUrl = logoUrl;
    }

    isPremiumMember(): boolean {
        return this.company.activeMemberships.some(x => x.subscriptionPack.priority === 1);
    }

    isFreeTrialMember(): boolean {
        return this.company.activeMemberships.some(x => x.subscriptionPack.priority === 3);
    }

    isEssentialMember(): boolean {
        return this.company.activeMemberships.some(x => x.subscriptionPack.priority === 2);
    }

    isFreeMember(): boolean {
        return !this.company.activeMemberships.length;
    }
}
