import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Tag} from '../../../../services/wabel-client/entities/tag';
import {CompanyTypes} from '../../../../services/wabel-client/company_types';
import { RIGHT } from '../../../../services/wabel-client/security/right.const';


@Component({
    selector: 'app-business-mapping-priority-collapse-header',
    templateUrl: './business-mapping-priorities-collapse-header.component.html',
    styleUrls: ['./business-mapping-priorities-collapse-header.component.scss']
})
export class BusinessMappingPrioritiesCollapseHeaderComponent {
    RIGHT = RIGHT;
    @Input() index = 0;
    @Input() showDelete = false;
    @Input() showDragDrop = false;
    @Input() disableButtons = false;
    @Input() recapMode = false;
    @Input() showButtons = true;
    @Input() goal = null;
    @Input() region = null;
    @Input() targets: string[] = null;
    @Input() activities: string[] = null;
    @Input() hideCountries = false;

    @Output() deleted = new EventEmitter<any>();
    @Output() toggle = new EventEmitter<any>();
    @Output() unlockClicked = new EventEmitter<any>();
}
