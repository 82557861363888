import {
    Component, ElementRef, Input, ViewChild
} from '@angular/core';
import {NdlTextBaseDirective} from '../input-text-base.directive';
import {MatDatepicker} from "@angular/material/datepicker";

@Component({
    selector: 'ndl-date',
    templateUrl: './date.component.html',
    styleUrls: ['./date.component.scss'],
    host: {
        'class': 'ndl-date',
        '(blur)': "onTouched()"
    }
})
export class NdlDateComponent extends NdlTextBaseDirective {
    @Input() minDate: Date;
    @Input() maxDate: Date;
    @ViewChild('matDatepicker', { static: true }) matDatePicker: MatDatepicker<any>;

    clear() {
        super.clear();
        this.matDatePicker.select(null);
    }

    get error() {
        if (this.ngControl?.errors?.matDatepickerMin) {
            return "Please select a minimum valid date.";
        }
        return null;
    }

    setUtcDateValue(value: Date) {
        const offset = value.getTimezoneOffset() * 60000;
        const utcDate = new Date(value.getTime() - offset);
        this.innerFormControl.setValue(utcDate);
    }
}
