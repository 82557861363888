import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ImageService {

  getBlurredUrl(path: string): string {
    if (path === null || path === '' || !path) {
      return '/assetsFiles/404/image.jpg';
    }
    let pos = path.lastIndexOf('.');
    return path.substring(0, pos) + '.blur' + path.substring(pos);
  }

}
