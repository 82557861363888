import {WabelEntity} from "./wabel-entity.abstract";
import * as dayjs from 'dayjs';
import {Event} from "./event";
import {Tag} from "./tag";

export class Summit extends WabelEntity {
    constructor(obj: any) {
        super(obj);
        if (obj) {
            this.certainEvent = obj.certainEvent ? new Event(obj.certainEvent) : null;
            this.categoriesLevel2 = obj.categoriesLevel2 ? obj.categoriesLevel2.map(c => new Tag(c)) : [];
            this.categoriesLevel3 = obj.categoriesLevel3 ? obj.categoriesLevel3.map(c => new Tag(c)) : [];
            this.tags = obj.tags && obj.tags.items ? obj.tags.items.map(c => new Tag(c)) : [];
        }
    }

    name: string;
    dates: string;
    startDate: string;
    endDate: string;
    eventCode: string;
    city: string;
    bannerUrl: string;
    color: string;
    planningImageUrl: string;
    floorMapImageUrl: string;
    certainEvent: Event;
    summitCode: string;
    categoriesLevel2: Tag[] = [];
    categoriesLevel3: Tag[] = [];
    tags: Tag[] = [];
    preregisterSentence: string;
    marketingPageUrl: string;

    get id(): string {
        return this.summitCode;
    }

    set id(summitCode: string) {
        this.summitCode = summitCode;
    }

    get formattedStartDate(): string {
        return new Date(this.startDate).toLocaleDateString()
    }

    get formattedendDate(): string {
        return new Date(this.endDate).toLocaleDateString()
    }

    daysLitteral(separator: string = '&'): string {
        let day = dayjs(this.startDate).set('hour', 0).set('minute', 0).set('second', 0);
        let eDate = dayjs(this.endDate).set('hour', 0).set('minute', 0).set('second', 0);
        let days = [];
        while (day.isBefore(eDate)) {
            days.push(day.format('D'));
            day = day.add(1, 'day');
        }
        return days.join(', ') + (days.length ? ' ' + separator + ' ' : '') + eDate.format('D');
    }

    marketingPageUrlTrackingLink(medium: string, campaign: string) {
        return `${this.marketingPageUrl}?utm_source=needl&utm_medium=${medium}&utm_campaign=${campaign}&utm_id=needl_${campaign}_${this.summitCode}&utm_content=${this.summitCode}`;
    }
}
