import {Directive, Input, Self} from '@angular/core';
import {NdlFilterCallback} from './data-source';
import {AbstractControl, NgForm} from '@angular/forms';

@Directive({
    selector: 'form[ndlListFilter],form[ndlTableFilter]',
    host: {
        class: 'ndl-list-filter'
    }
})
export class NdlListFilterDirective<T = any, F = any> {

    _callback: NdlFilterCallback<T, F>;
    @Input('ndlListFilter') get listFilterCallback() {
        return this._callback;
    }
    set listFilterCallback(fn) {
        this._callback = fn;
    }
    @Input('ndlTableFilter') get tableFilterCallback() {
        return this._callback;
    }
    set tableFilterCallback(fn) {
        this._callback = fn;
    }

    constructor(@Self() public form: NgForm) {
    }
}
