import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {NdlTableComponent} from '../../lists/table.component';
import {combineLatest, Observable, of} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import {NgForm} from '@angular/forms';
import {NdlSortEvent} from '../../lists/data-source';

@Component({
    selector: 'ndl-table-demo',
    templateUrl: './table-demo.component.html'
})
export class NdlTableDemoComponent implements AfterViewInit {
    data: { id: number, name: string, website: string, status: string }[] = [
        {id: 1, name: 'Zgegou', website: 'http://www.site.com', status: 'on'},
        {id: 2, name: 'Pluplus', website: 'http://www.site.com', status: 'off'},
        {id: 3, name: 'Ploplo', website: 'http://www.site.com', status: 'on'},
        {id: 4, name: 'Bibibi', website: 'http://www.site.com', status: 'off'},
        {id: 1, name: 'Zgegou', website: 'http://www.site.com', status: 'on'},
        {id: 2, name: 'Pluplus', website: 'http://www.site.com', status: 'off'},
        {id: 3, name: 'Ploplo', website: 'http://www.site.com', status: 'on'},
        {id: 4, name: 'Bibibi', website: 'http://www.site.com', status: 'off'},
        {id: 1, name: 'Zgegou', website: 'http://www.site.com', status: 'on'},
        {id: 2, name: 'Pluplus', website: 'http://www.site.com', status: 'off'},
        {id: 3, name: 'Ploplo', website: 'http://www.site.com', status: 'on'},
        {id: 4, name: 'Bibibi', website: 'http://www.site.com', status: 'off'},
        {id: 1, name: 'Zgegou', website: 'http://www.site.com', status: 'on'},
        {id: 2, name: 'Pluplus', website: 'http://www.site.com', status: 'off'},
        {id: 3, name: 'Ploplo', website: 'http://www.site.com', status: 'on'},
        {id: 4, name: 'Bibibi', website: 'http://www.site.com', status: 'off'},
        {id: 1, name: 'Zgegou', website: 'http://www.site.com', status: 'on'},
        {id: 2, name: 'Pluplus', website: 'http://www.site.com', status: 'off'},
        {id: 3, name: 'Ploplo', website: 'http://www.site.com', status: 'on'},
        {id: 4, name: 'Bibibi', website: 'http://www.site.com', status: 'off'},
        {id: 1, name: 'Zgegou', website: 'http://www.site.com', status: 'on'},
        {id: 2, name: 'Pluplus', website: 'http://www.site.com', status: 'off'},
        {id: 3, name: 'Ploplo', website: 'http://www.site.com', status: 'on'},
        {id: 4, name: 'Bibibi', website: 'http://www.site.com', status: 'off'},
        {id: 1, name: 'Zgegou', website: 'http://www.site.com', status: 'on'},
        {id: 2, name: 'Pluplus', website: 'http://www.site.com', status: 'off'},
        {id: 3, name: 'Ploplo', website: 'http://www.site.com', status: 'on'},
        {id: 4, name: 'Bibibi', website: 'http://www.site.com', status: 'off'},
    ];

    sortColumn = "Name";
    sortOrder: "asc"|"desc" = "asc";

    @ViewChild(NdlTableComponent, {static: true}) table: NdlTableComponent;
    @ViewChild('filters', {read: NgForm, static: true}) filters: NgForm;

    data$: Observable<{ id: number, name: string, website: string, status: string }[]>;

    ngAfterViewInit() {
        this.data$ = of(this.data);
    }

    sort = (data: { id: number, name: string, website: string, status: string }[], event: NdlSortEvent) => {
        if (event) {
            const ref = event.sortColumn.toLowerCase();
            data.sort((a, b) => {
                if (a[ref] > b[ref]) {
                    return event.sortOrder === "asc" ? 1 : -1;
                }
                if (a[ref] < b[ref]) {
                    return event.sortOrder === "asc" ? -1 : 1;
                }

                return 0;
            });
        }

        return data;
    }

    filter = (data: { id: number, name: string, website: string, status: string }[], filters: any) => {
        if (filters?.status) {
            data = data.filter((d: { id: number, name: string, website: string, status: string }) => d.status === filters.status);
        }
        if (filters?.search) {
            const term = filters.search.toLowerCase();
            data = data.filter((d: { id: number, name: string, website: string, status: string }) => d.name.toLowerCase().includes(term) || d.website.includes(term));
        }

        return data;
    }
}
