import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NdlMenuComponent} from './menu.component';
import {NdlMenuItemComponent} from './menu-item.component';
import {RouterModule} from '@angular/router';
import {NdlSubmenuDirective} from "./submenu.directive";
import {NdlSubmenuItemComponent} from "./submenu-item.component";
import {MatIconModule} from "@angular/material/icon";


@NgModule({
    declarations: [
        NdlMenuComponent,
        NdlMenuItemComponent,
        NdlSubmenuDirective,
        NdlSubmenuItemComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        MatIconModule
    ],
    exports: [
        NdlMenuComponent,
        NdlMenuItemComponent,
        NdlSubmenuDirective,
        NdlSubmenuItemComponent
    ]
})
export class NeedlMenuModule {
}
