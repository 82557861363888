<ndl-form-field #formField [label]="label" [prefix]="marginPrefix" [suffix]="marginSuffix" [hint]="hint"
                [errors]="errors">
    <ng-template #marginPrefix></ng-template>

    <ndl-upload-field #field [ngControl]="ngControl" [required]="required"
                      [errorState]="innerFormControl?.errors && innerFormControl?.touched"
                      [multiple]="multiple" [type]="type" [fileTypes]="fileTypes"
                      [autoupload]="autoupload"
                      (change)="onChange($event)" (blur)="onTouched()" (dragHover)="isDragging = $event">
        <ng-template #prefix>
            <ng-content select="[ndl-prefix]"></ng-content>
        </ng-template>
    </ndl-upload-field>

    <ng-template #marginSuffix></ng-template>
</ndl-form-field>
