import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TemplateService} from "../../../../services/template.service";

@Component({
    selector: 'kit-modal-confirmation',
    templateUrl: './kit-modal-confirmation.component.html',
    styleUrls: ['../kit-modal-simple/kit-modal-simple.component.scss']
})
export class KitModalConfirmationComponent implements OnInit, OnDestroy {

    @Input() modalTitle: string;
    @Input() text: string;
    @Input() labelConfirmButton: string;
    @Input() labelCancelButton: string;
    @Input() hasCloseIcon = true;
    @Input() backdropClose = true;
    @Input() isFullscreen = false;
    @Input() isLoading = false;
    @Input() labelLoader = 'Loading...';

    @Output() onClose = new EventEmitter();
    @Output() onConfirmButtonClicked = new EventEmitter();
    @Output() onCancelButtonClicked = new EventEmitter();

    constructor(private templateService: TemplateService) {
    }

    ngOnInit() {
        this.templateService.blockScroll();
    }

    ngOnDestroy() {
        this.templateService.unblockScroll();
    }
}
