import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NdlButtonDirective } from './button.component';
import {NdlButtonPrefixDirective} from './button-prefix.directive';
import {NdlButtonSuffixDirective} from './button-suffix.directive';
import {MatRippleModule} from '@angular/material/core';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
    declarations: [
        NdlButtonDirective,
        NdlButtonPrefixDirective,
        NdlButtonSuffixDirective,
    ],
    imports: [
        CommonModule,
        MatRippleModule,
        MatButtonModule
    ],
    exports: [
        NdlButtonDirective,
        NdlButtonPrefixDirective,
        NdlButtonSuffixDirective,
    ]
})
export class NeedlButtonsModule { }
