import {
    Component,
} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {UtilsService} from '../../../services/utils.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-business-mapping-create',
    templateUrl: './business-mapping-create.component.html',
    styleUrls: ['./business-mapping-create.component.scss'],
})
export class BusinessMappingCreateComponent {
    constructor(public authService: AuthService, public utilsService: UtilsService, public router: Router) {
    }

    finish() {
        this.authService.getMember().businessMapping.completed = true;
        this.router.navigateByUrl('/business-mapping');
    }
}
