import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'fileSizeFormat'
})
export class NdlFileSizePipe implements PipeTransform {
    transform(size: number): string {
        return this._upgradeUnit(size);
    }

    private _upgradeUnit(number: number, iteration = 1): string {
        if (number >= 1024) {
            const newNumber = number / 1024;
            return this._upgradeUnit(newNumber, ++iteration)
        }
        return number.toFixed(2) + ' ' + NdlFileSizePipe._iterationToUnit(iteration);
    }

    private static _iterationToUnit(i: number): string {
        switch (i) {
            case 1:
                return 'b';
            case 2:
                return 'Kb';
            case 3:
                return 'Mb';
            case 4:
                return 'Gb';
            case 5:
                return 'Tb';
            case 6:
                return 'Pb';
            default:
                return '';
        }
    }
}
