import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {SignupService} from "../../../../services/wabel-client/services/signup.service";
import {RegistrationService} from "../../../../services/wabel-client/services/registration.service";
import {UtilsService} from "../../../../services/utils.service";
import {NdlSnackbarService} from "needl-lib";

@Component({
    selector: 'unlogged-registration',
    templateUrl: './unlogged-registration.component.html',
    styleUrls: ['./unlogged-registration.component.scss']
})
export class UnloggedRegistrationComponent implements OnInit {

    user = {
        email: '',
        title: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        tcAccepted: false,
        jobTitle: '',
        companyId: null,
        companyName: '',
        password: '',
        website: '',
    };
    saving = false;
    step: string = 'personalInfo';

    constructor(
        private signupService: SignupService,
        private registrationService: RegistrationService,
        private router: Router,
        private route: ActivatedRoute,
        private utilsService: UtilsService,
        private snackbar: NdlSnackbarService,
    ) {

    }

    ngOnInit() {
        this.user.email = this.registrationService.getEmailAfterRegistrationPopup();
    }

    nextStep(user: any) {
        switch (this.step) {
            case 'personalInfo':
                this.user.title = user.title;
                this.user.firstName = user.firstName;
                this.user.lastName = user.lastName;
                this.user.phoneNumber = user.phoneNumber && user.phoneNumber !== '' ? user.phoneNumber : user.phone;
                this.user.tcAccepted = user.tcAccepted;
                this.step = 'professionalInfo';
                break;
            case 'professionalInfo':
                this.user.jobTitle = user.jobTitle;
                this.user.companyName = user.companyName;
                this.user.companyId = user.companyId;
                this.step = 'password';
                break;
            case 'password':
                this.user.password = user.password;
                this.savePersonalInformation();
                break;
        }
        setTimeout(() => {
            this.utilsService.scrollToTop();
        }, 100);
    }

    savePersonalInformation() {
        this.saving = true;
        this.signupService.savePersonalInformation(
            this.user.email,
            this.user.title,
            this.user.firstName,
            this.user.lastName,
            this.user.phoneNumber,
            this.user.jobTitle,
            this.user.password,
            this.user.companyName,
            this.user.companyId,
            this.user.website,
            2
        ).subscribe((data) => {
            this.saving = false;
            const destination = this.route.snapshot.queryParamMap.get('destination');
            if (this.user.companyId) {
                if (destination) {
                    window.location.href = destination;
                } else {
                    this.router.navigate(['/home']);
                }
            } else {
                this.router.navigate(['/sign-up/manufacturer/company-info'], {queryParams: {destination: destination}});
            }
        }, (error) => {
            this.saving = false;
            this.snackbar.show(this.utilsService.detectErrorMessages(error).join('. '), 'error', null);
            console.error(error);
        });
    }
}
