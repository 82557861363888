import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Company} from "../../../../services/wabel-client/entities/company";
import {environment} from '../../../../../environments/environment';

@Component({
    selector: 'app-video-meeting-request-modal-step-confirmation',
    templateUrl: './video-meeting-request-modal-step-confirmation.component.html',
    styleUrls: ['./video-meeting-request-modal-step-confirmation.component.scss']
})

export class VideoMeetingRequestModalStepConfirmationComponent {
    // Need to use path in case the modal video meeting is displayed in event side
    needl_app_full_url: string = environment.needl_front_end_url;
    @Input() targetCompany: Company = null;
    @Output() linkClicked = new EventEmitter();

    constructor() {}
}
