import {Component} from '@angular/core';
import {NdlStatus} from '../../core/types';
import {NdlStatusColors} from '../../tooltips/status/status.component';

@Component({
    selector: 'ndl-status-demo',
    templateUrl: './status-demo.component.html'
})
export class NdlStatusDemoComponent {
    statuses: NdlStatusColors[] = ['green', 'yellow', 'blue', 'red', 'orange', 'dark-orange', 'grey', 'dark-green', 'gold', 'cyan', 'pink', 'white', 'dark-grey'];
}
