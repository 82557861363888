import {
    Component, Directive, Input, OnInit
} from '@angular/core';
import {Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {NdlTextBaseDirective} from '../input-text-base.directive';

@Directive()
export abstract class NdlPasswordBaseDirective extends NdlTextBaseDirective implements OnInit {
    type: "password" | "text" = "password";

    ngOnInit() {
        this._setPatternValidator();
    }

    toggleType() {
        this.type = this.type === "password" ? "text" : "password";
    }

    protected abstract _setPatternValidator();
}
