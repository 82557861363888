import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Product} from "../../../services/wabel-client/entities/product";
import {Tag} from '../../../services/wabel-client/entities/tag';

@Component({
    selector: 'app-product-category-tag',
    templateUrl: './product-category-tag.component.html',
    styleUrls: ['./product-category-tag.component.scss']
})
export class ProductCategoryTagComponent {
    @Input() category: Tag;
    @Input() showIcon = true;
    @Input() isCat2 = false;
}
