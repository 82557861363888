import {Component} from '@angular/core';
import {NdlStatus} from '../../core/types';

@Component({
    selector: 'ndl-banners-demo',
    templateUrl: './banners-demo.component.html'
})
export class NdlBannersDemoComponent {
    content = "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda, deleniti dolore moles...";
    themes: NdlStatus[] = ['default', 'primary', 'secondary', 'info', 'success', 'warning', 'danger', 'neutral'];
}
