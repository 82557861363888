import {
    AfterContentInit,
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    IterableDiffers,
    OnDestroy,
    Output,
    QueryList,
    ViewChild
} from '@angular/core';
import {NdlListBaseDirective} from "./list-base.component";
import {NdlListItemDirective} from './list-item.directive';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {NdlSortEvent, NdlSortingCallback} from './data-source';
import {filter, map} from 'rxjs/operators';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {CdkTable, STICKY_POSITIONING_LISTENER, StickyUpdate} from '@angular/cdk/table';
import {NdlStickyListener} from './sticky-listener';

const STICKY_SELECT_SIZE = 38;

@Component({
    selector: 'ndl-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    host: {
        'class': 'ndl-table'
    },
    providers: [
        {provide: NdlStickyListener, useClass: NdlStickyListener},
        {provide: STICKY_POSITIONING_LISTENER, useExisting: NdlStickyListener}
    ],
})
export class NdlTableComponent<T = any> extends NdlListBaseDirective implements AfterContentInit, OnDestroy {
    showHeader = false;
    columnNames: string[] = [];
    displayedColumns: string[] = [];
    columnSearch = new BehaviorSubject<string>(null);
    columnSearchResult$: Observable<string[]>;
    fixColumnOffsetSubscription: Subscription;
    fixColumnOffset: string = null;
    @Input() sortColumn: string;
    @Input() sortOrder: 'asc'|'desc';
    @Input() sortingCallback: NdlSortingCallback<T>;
    private _enableSelection = false;
    @Input() get enableSelection() {
        return this._enableSelection;
    }
    set enableSelection(enable) {
        this._enableSelection = coerceBooleanProperty(enable);
    }

    @Input() get enableColumnConfiguration() {
        return this._enableColumnConfiguration;
    }
    set enableColumnConfiguration(enable) {
        this._enableColumnConfiguration = coerceBooleanProperty(enable);
    }
    private _enableColumnConfiguration = false;

    @Input() get stickyHeader() {
        return this._stickyHeader;
    }
    set stickyHeader(enable) {
        this._stickyHeader = coerceBooleanProperty(enable);
    }
    private _stickyHeader = false;

    get allColumsDisplayed() {
        return this.columnNames.length === (this.displayedColumns.length - (this.actions?.length ? 1 : 0));
    }

    @Output() sorted = new EventEmitter<NdlSortEvent>();

    @ContentChildren(NdlListItemDirective) columns: QueryList<NdlListItemDirective>;
    @ViewChild(CdkTable, {static: true}) table: CdkTable<T>;

    constructor(private readonly _stickyPositioningListener: NdlStickyListener, private cdRef: ChangeDetectorRef, iterableDiffers: IterableDiffers) {
        super(iterableDiffers);
    }

    ngAfterContentInit() {
        super.ngAfterContentInit();

        this.columnSearchResult$ = this.columnSearch.asObservable().pipe(map(term => {
            return term?.length ? this.columnNames.filter(c => c.toLowerCase().includes(term.toLowerCase())) : this.columnNames;
        }));

        if (this.sortingCallback) {
            const currentSort = this.sortColumn ? {sortColumn: this.sortColumn, sortOrder: this.sortOrder ?? 'asc'} : null;
            this.dataSource.setSorting(this.sorted.asObservable(), currentSort, this.sortingCallback);
        }

        const stickyCols: NdlListItemDirective[] = [];

        this.columnNames = this.columns.map((column, index) => {
            if (column.name) {
                this.showHeader = true;
            } else {
                column.name = 'Column ' + (index + 1);
            }

            if (column.sticky) {
                stickyCols.push(column);
            }

            column.index = index;
            return column.name;
        });

        this.displayedColumns = Object.assign([], this.columnNames);

        // If there is actions, we activate the Checkboxes
        if (this.actions.length) {
            this.displayedColumns.unshift('select');
        }

        this.fixColumnOffsetSubscription = this._stickyPositioningListener.columnUpdates$.pipe(
            filter((data: StickyUpdate) => data?.sizes.length > 0 && this.actions.length > 0),
            map((data: StickyUpdate) => 'translateX(' + (-(data.sizes[0] - STICKY_SELECT_SIZE)) + 'px)')
        ).subscribe(data => {
            this.fixColumnOffset = data;
            this.cdRef.detectChanges();
        });
    }

    sort(sortColumn: string) {
        if (sortColumn === this.sortColumn) {
            this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
        } else {
            this.sortColumn = sortColumn;
            this.sortOrder = "asc";
        }

        this.sorted.emit({sortColumn: this.sortColumn, sortOrder: this.sortOrder});
    }

    toggleColumn(columnName: string) {
        let i = this.displayedColumns.findIndex(col => col === columnName);
        if (i === -1) {
            i = this.columnNames.findIndex(col => col === columnName) + 1;
            this.displayedColumns.splice(i, 0, columnName);
        } else {
            this.displayedColumns.splice(i, 1);
        }
    }

    toggleAllColumns() {
        if (this.allColumsDisplayed) {
            this.displayedColumns = this.actions?.length ? ['select'] : [];
        } else {
            this.displayedColumns = this.actions?.length ? ['select', ...this.columnNames] : [...this.columnNames];
        }
    }

    isColumnDisplayed(columnName: string) {
        return this.displayedColumns.findIndex(col => col === columnName) > -1;
    }

    ngOnDestroy() {
        this.fixColumnOffsetSubscription.unsubscribe();
    }
}
