import {Injectable} from "@angular/core";
import {Member} from "./wabel-client/entities/member";
import {Company} from "./wabel-client/entities/company";
import {NeedlSecurityService} from "@wabel/needl-security";
import {RIGHT} from "./wabel-client/security/right.const";

@Injectable({providedIn: 'root'})
export class MyNeedlProfileService {
    readonly RIGHT = RIGHT;

    constructor(private needlSecurityService: NeedlSecurityService) {
    }

    member: Member;
    companyForAdminMode: Company = null;
    leftCompanyEditionMobileLink: any = null;
    rightCompanyEditionMobileLink: any = null;

    get company() {
        return this.member ? this.member.company : this.companyForAdminMode;
    }

    toObject(object) {
    }

    get isAdminMode() {
        return this.companyForAdminMode !== null;
    }

    computeLinksForMobileMode(url: string) {
        this.leftCompanyEditionMobileLink = null;
        this.rightCompanyEditionMobileLink = null;
        if (!url.includes('company')) {
            return;
        }
        const link = '/profile/company' + (this.isAdminMode ? '/' + this.company.id : '');
        if (url.includes('information')) {
            if (this.isAdminMode || (!this.isAdminMode && this.needlSecurityService.hasRight(RIGHT.CAN_SEE_MY_COMPANY_MARKETING_FORM))) {
                this.rightCompanyEditionMobileLink = {'title': 'Marketing & PR', 'link': link + '/marketing'};
            }
        } else if (url.includes('marketing')) {
            if (this.isAdminMode || (!this.isAdminMode && this.needlSecurityService.hasRight(RIGHT.CAN_SEE_MY_COMPANY_INFORMATION_FORM))) {
                this.leftCompanyEditionMobileLink = {'title': 'Company information', 'link': link + '/information'};
            }
            if (this.isAdminMode || (!this.isAdminMode && this.needlSecurityService.hasRight(RIGHT.CAN_SEE_MY_COMPANY_FACTS_AND_FIGURES_FORM))) {
                this.rightCompanyEditionMobileLink = {'title': 'Facts & figures', 'link': link + '/facts-and-figures'};
            }
        } else if (url.includes('facts-and-figures')) {
            if (this.isAdminMode || (!this.isAdminMode && this.needlSecurityService.hasRight(RIGHT.CAN_SEE_MY_COMPANY_MARKETING_FORM))) {
                this.leftCompanyEditionMobileLink = {'title': 'Marketing & PR', 'link': link + '/marketing'};
            }
            if ((this.isAdminMode && (this.company.isManufacturer() || this.company.isPackagingSupplier()))
                || (!this.isAdminMode && this.needlSecurityService.hasRight(RIGHT.CAN_SEE_MY_COMPANY_PRODUCTION_FORM))) {
                this.rightCompanyEditionMobileLink = {'title': 'Production', 'link': link + '/production'};
            }
            if ((this.isAdminMode && !this.company.isManufacturer() && !this.company.isPackagingSupplier())
                || (!this.isAdminMode && this.needlSecurityService.hasRight(RIGHT.CAN_SEE_MY_COMPANY_ECO_PACK_POLICY_FORM) &&
                    !this.needlSecurityService.hasRight(RIGHT.CAN_SEE_MY_COMPANY_PRODUCTION_FORM))){
                this.rightCompanyEditionMobileLink = {'title': 'Eco-pack policy', 'link': link + '/eco-pack-policy'};
            }
        } else if (url.includes('production')) {
            if (this.isAdminMode || (!this.isAdminMode && this.needlSecurityService.hasRight(RIGHT.CAN_SEE_MY_COMPANY_FACTS_AND_FIGURES_FORM))) {
                this.leftCompanyEditionMobileLink = {'title': 'Facts & figures', 'link': link + '/facts-and-figures'};
            }
            if (this.isAdminMode || (!this.isAdminMode && this.needlSecurityService.hasRight(RIGHT.CAN_SEE_MY_COMPANY_CERTIFICATIONS_FORM))) {
                this.rightCompanyEditionMobileLink = {'title': 'Certifications', 'link': link + '/certifications'};
            }
        } else if (url.includes('certifications')) {
            if (this.isAdminMode || (!this.isAdminMode && this.needlSecurityService.hasRight(RIGHT.CAN_SEE_MY_COMPANY_PRODUCTION_FORM))) {
                this.leftCompanyEditionMobileLink = {'title': 'Production', 'link': link + '/production'};
            }
            if (this.isAdminMode || (!this.isAdminMode && this.needlSecurityService.hasRight(RIGHT.CAN_SEE_MY_COMPANY_DISTRIBUTION_FORM))) {
                this.rightCompanyEditionMobileLink = {'title': 'Distribution', 'link': link + '/distribution'};
            }
        } else if (url.includes('distribution')) {
            if (this.isAdminMode || (!this.isAdminMode && this.needlSecurityService.hasRight(RIGHT.CAN_SEE_MY_COMPANY_CERTIFICATIONS_FORM))) {
                this.leftCompanyEditionMobileLink = {'title': 'Certifications', 'link': link + '/certifications'};
            }
            if (this.isAdminMode || (!this.isAdminMode && this.needlSecurityService.hasRight(RIGHT.CAN_SEE_MY_COMPANY_ECO_PACK_POLICY_FORM))) {
                this.rightCompanyEditionMobileLink = {'title': 'Eco-pack policy', 'link': link + '/eco-pack-policy'};
            }
        } else if (url.includes('eco-pack-policy')) {
            if ((this.isAdminMode && (this.company.isManufacturer() || this.company.isPackagingSupplier()))
                || (!this.isAdminMode && (this.needlSecurityService.hasRight(RIGHT.CAN_SEE_MY_COMPANY_DISTRIBUTION_FORM)))) {
                this.leftCompanyEditionMobileLink = {'title': 'Distribution', 'link': link + '/distribution'};
            }

            if ((this.isAdminMode && !this.company.isManufacturer() && !this.company.isPackagingSupplier())
                || (!this.isAdminMode && this.needlSecurityService.hasRight(RIGHT.CAN_SEE_MY_COMPANY_FACTS_AND_FIGURES_FORM)
                    && !this.needlSecurityService.hasRight(RIGHT.CAN_SEE_MY_COMPANY_DISTRIBUTION_FORM))) {
                this.leftCompanyEditionMobileLink = {'title': 'Facts & figures', 'link': link + '/facts-and-figures'};
            }
        }
    }
}
