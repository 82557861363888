import {Directive, HostListener} from '@angular/core';
import {Subject} from 'rxjs';

@Directive({
    selector: '[ndlStepPrevious]'
})
export class NdlStepPreviousDirective {
    private _back: Subject<boolean> = new Subject<boolean>();
    get goBack() {
        return this._back.asObservable();
    }

    @HostListener('click') onClick() {
        this._back.next(false);
    }
}
