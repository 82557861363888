<ndl-form-field #formField [prefix]="prefix" [suffix]="suffix" [label]="label" [hint]="hint" [errors]="errors"
                [class.has-value]="showBasicTitle && value && value.length">
    <ng-template #prefix>
        <ng-content select="[ndl-prefix]"></ng-content>
    </ng-template>
    <div class="d-flex">
        <mat-select disableOptionCentering [multiple]="multiple" [required]="required" panelClass="ndl-select-panel"
                    [placeholder]="placeholder" (blur)="onTouched()"
                    (selectionChange)="onChange($event.value); searchValueChanged.emit(null)"
                    [disabled]="disabled" #matRef>
            <mat-select-trigger *ngIf="showBasicTitle">
                <span [class.has-value]="value && value.length">
                {{title}} {{value && value.length ? '(' + value.length + ')' : ''}}
                </span>
            </mat-select-trigger>
            <mat-select-trigger *ngIf="showCustomTitle">
                <ng-content select="[ndl-select-custom-title]"></ng-content>
            </mat-select-trigger>
            <mat-select-trigger *ngIf="useTagsAsSelectTrigger">
                <mat-chip-list multiple [formControl]="innerFormControl" [selectable]="false" [required]="required">
                    <ng-container *ngFor="let chip of chips | async">
                        <mat-chip *ngIf="chip.value" [value]="chip.value" removable (removed)="delete($event)"
                                   (blur)="onTouched()">
                            {{chip.label}}
                            <button matChipRemove (click)="onTouched()">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </mat-select-trigger>
            <mat-option *ngIf="search" class="ndl-select-field-search" disabled>
                <span class="material-icons">search</span>
                <input matInput [(ngModel)]="searchValue" (ngModelChange)="this._options.notifyOnChanges();searchValueChanged.emit($event)"
                       (keydown)="$event.stopPropagation()"
                       placeholder="{{searchPlaceholderValue}}" autocomplete="false"/>
            </mat-option>
            <mat-checkbox *ngIf="showSelectAll" [(ngModel)]="allSelected"
                          color="primary"
                          class="mat-option"
                          [ngModelOptions]="{standalone: true}"
                          [checked]="allChecked"
                          (change)="toggleAllSelection()">{{selectAllLabel}}</mat-checkbox>
            <ng-container *ngIf="options">
                <mat-option *ngFor="let option of options" [value]="option.value">
                    <div [innerHTML]="option.label"></div>
                </mat-option>
            </ng-container>
            <ng-container *ngIf="optionGroups">
                <mat-optgroup *ngFor="let optionGroup of optionGroups; let i = index;" [class.ndl-mat-optgroup-hide]="hideGroup(optionGroup)">
                    <p (click)="toggleOptGroup(i)" class="mb-0 mat-optgroup-label">
                        {{optionGroup.label}}
                        <span
                            class="material-icons">{{openOptGroups.indexOf(i) > -1 ? 'arrow_drop_up' : 'arrow_drop_down'}}</span>
                    </p>
                    <mat-option *ngFor="let option of optionGroup.options" class="ndl-mat-option-in-group"
                                [class.ndl-mat-option-in-group-hide]="hideOption(option)"
                                [value]="option.value" [class.opened]="openOptGroups.indexOf(i) > -1">
                        <div [innerHTML]="option.label"></div>
                    </mat-option>
                </mat-optgroup>
            </ng-container>
        </mat-select>
        <mat-icon *ngIf="clearable && value && value.length" (click)="clear(); $event.stopPropagation()">close</mat-icon>
    </div>
    <ng-template #suffix>
        <ng-content select="[ndl-suffix]"></ng-content>
    </ng-template>
</ndl-form-field>
