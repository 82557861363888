import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class ZoomService {

    constructor(private http: HttpClient) {
    }

    getZoomMeeting(idmeeting: number) {
        return this.http.post(environment.needl_back_end_url + '/get-zoom-meeting', {
            idmeeting: idmeeting
        }, {
            withCredentials: true,
            headers: {
                'ngsw-bypass': 'true'
            }
        });
    }

    getZoomVideoMeeting(idvideomeetinginvitation: number) {
        return this.http.post(environment.needl_back_end_url + '/get-zoom-video-meeting', {
            idvideomeetinginvitation
        }, {
            withCredentials: true,
            headers: {
                'ngsw-bypass': 'true'
            }
        });
    }

    getZoomNewVideoMeeting(id: number) {
        return this.http.post(environment.needl_back_end_url + '/get-zoom-new-video-meeting', {
            id
        }, {
            withCredentials: true,
            headers: {
                'ngsw-bypass': 'true'
            }
        });
    }

    getZoomSignature(meeting_number: number) {
        return this.http.post(environment.needl_back_end_url + '/get-zoom-signature', {
            meeting_number: meeting_number
        }, {
            withCredentials: true,
            headers: {
                'ngsw-bypass': 'true'
            }
        });
    }

    getZoomWebinarUrl(idWorkshop: number) {
        return this.http.get(environment.needl_back_end_url + '/zoom-webinar/' + idWorkshop);
    }
}
