import { WabelEntity } from "./wabel-entity.abstract";
import { map } from "rxjs/operators";
import { PackagingTags } from "../PackagingTags";
import { Company } from "./company";
import { Summit } from "./summit";
import { Tag } from "./tag";

export class PackagingInternalTag {
    constructor(obj: any) {
        if (obj) {
            if (obj.idecopackprojecttag) { this.idTag = obj.idecopackprojecttag; }
            if (obj.idparent) { this.idParent = obj.idparent; }
            if (obj.name) { this.name = obj.name; }
        }
    }

    idTag: string;
    idParent: number;
    name: string;
}

export class EcoPackProject extends WabelEntity {

    constructor(obj: any) {
        super(obj);
        if (obj) {

            this.consistency = obj.consistency && obj.consistency.items && obj.consistency.items.length > 0 ? obj.consistency.items[0] : null;
            this.packaging_purpose = obj.packagingPurpose && obj.packagingPurpose.items && obj.packagingPurpose.items.length > 0 ? obj.packagingPurpose.items[0] : null;
            this.project_timeline = obj.projectTimeLine && obj.projectTimeLine.items && obj.projectTimeLine.items.length > 0 ? obj.projectTimeLine.items[0] : null;

            if (obj.ecoProjectTags) {
                this.ecoProjectTags = obj.ecoProjectTags;
                this.packaging_categories = obj.ecoProjectTags.map((data) => data ? new PackagingInternalTag(data) : null).filter(s => s.idParent === PackagingTags.CATEGORIES).map(a => a.idTag);
                this.packaging_material = obj.ecoProjectTags.map((data) => data ? new PackagingInternalTag(data) : null).filter(s => s.idParent === PackagingTags.MATERIAL).map(a => a.idTag);
                this.eco_friendly_options = obj.ecoProjectTags.map((data) => data ? new PackagingInternalTag(data) : null).filter(s => s.idParent === PackagingTags.ECO_FRIENDLY).map(a => a.idTag);
                this.all_tags = obj.ecoProjectTags.map((data) => data ? new PackagingInternalTag(data) : null);
                this.sup_tags = obj.ecoProjectTags.map((data) => data ? new PackagingInternalTag(data) : null)
                    .filter(s => s.idParent != PackagingTags.CATEGORIES && s.idParent != PackagingTags.ECO_FRIENDLY && s.idParent != PackagingTags.MATERIAL);

                const groupByParent = {};
                const item_with_no_child = obj.ecoProjectTags.map((data) => data ? new PackagingInternalTag(data) : null)
                    .filter(s => s.idParent === PackagingTags.CATEGORIES || s.idParent === PackagingTags.MATERIAL);
                this.sup_tags.forEach(function (a) {
                    groupByParent[a.idParent] = groupByParent[a.idParent] || [];
                    groupByParent[a.idParent].push(a.idTag);
                    //groupByParent[a.idTag] = [];
                });
                item_with_no_child.forEach(function (a) {
                    groupByParent[a.idTag] = groupByParent[a.idTag] || [];
                    // groupByParent[a.idTag].push(a.idTag);
                    //groupByParent[a.idTag] = [];
                });
                this.sup_tags = groupByParent;
            }
            this.categories = obj.categories ? obj.categories.map(category => new PackagingInternalTag(category)) : [];
            this.material = obj.material ? obj.material.map(material => new PackagingInternalTag(material)) : [];
            this.ecoFriendlyFeatures = obj.ecoFriendlyFeatures ? obj.ecoFriendlyFeatures.map(ecoFriendlyFeature => new PackagingInternalTag(ecoFriendlyFeature)) : [];

        }
    }


    idecopackproject: number;
    name: string;


    packaging_purpose: Tag;
    consistency: Tag;
    project_timeline: Tag;

    eco_friendly_options: any;

    packaging_material: any;
    packaging_categories: any;

    notes: any;
    categories: PackagingInternalTag[];
    material: PackagingInternalTag[];
    ecoFriendlyFeatures: PackagingInternalTag[];

    sup_tags: any;

    all_tags: any;
    company: Company;

    ecoProjectTags: any;
    get id(): number {
        return this.idecopackproject;
    }

    set id(id: number) {
        this.idecopackproject = id;
    }
}
