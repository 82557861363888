<button *ngIf="data.closable" mat-icon-button type="button" class="ndl-modal-close" (click)="dialogRef.close()">
    <mat-icon>close</mat-icon>
</button>

<div class="ndl-modal-content" [class.ndl-modal-has-icon]="data.icon">
    <div *ngIf="data.icon" class="text-center ndl-modal-icon-mobile d-block d-sm-none">
        <mat-icon fontSet="material-icons-outlined">{{data.icon}}</mat-icon>
    </div>
    <ng-container *ngTemplateOutlet="data.content; context: {$implicit: data.context}"></ng-container>
</div>

<div *ngIf="data.icon" class="ndl-modal-icon d-none d-sm-block">
    <mat-icon fontSet="material-icons-outlined">{{data.icon}}</mat-icon>
</div>

