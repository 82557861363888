import {Component, OnInit, OnDestroy} from '@angular/core';
import {TemplateService} from "../../../services/template.service";
import {Title} from "@angular/platform-browser";
import {RegistrationService} from "../../../services/wabel-client/services/registration.service";
import {Router} from "@angular/router";
import {AuthService} from "../../../services/auth.service";

@Component({
    selector: 'registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit, OnDestroy {

    constructor(public templateService: TemplateService,
                private titleService: Title,
                private registrationService: RegistrationService,
                private router: Router,
                public authService: AuthService) {

    }

    ngOnInit() {
        if (!this.registrationService.getEmailAfterRegistrationPopup() && !this.authService.getMember()) {
            this.router.navigate(['/join']);
        }
        this.templateService.isDisplayFooter = false;
        this.templateService.isDisplayNavBarMenu = false;
        this.titleService.setTitle('Registration | needl.');
    }

    ngOnDestroy() {
        this.templateService.isDisplayFooter = true;
        this.templateService.isDisplayNavBarMenu = true;
    }

    exitFunnel() {
        this.authService.wantToBypassSupplierRegistrationFunnel = true;
        this.router.navigate(['/home']);
    }
}
