import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SignInComponent} from './sign-in.component';
import {SharedModule} from '../../modules/shared-module/shared.module';
import {NeedlAlertModule} from 'needl-lib';
import {GoogleTagManagerModule} from '../../directives/gtm/gtm.module';


@NgModule({
    declarations: [
        SignInComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule, // TODO: Catastroph !! Only used for [testCy] directive
        NeedlAlertModule,
        GoogleTagManagerModule
        // NeedlTextModule,
        // NeedlSelectModule
    ],
    exports: [
        SignInComponent,
    ]
})
export class SignInModule {
}
