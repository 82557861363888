import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Company} from "../../../../services/wabel-client/entities/company";
import {CompanyService} from "../../../../services/wabel-client/services/company.service";
import {Subscription} from "rxjs";
import {BusinessMappingPriority} from "../../../../services/wabel-client/entities/business_mapping_priority";
import {Tag} from "../../../../services/wabel-client/entities/tag";

@Component({
    selector: 'app-company-countries-of-activity',
    templateUrl: './company-countries-of-activity.component.html',
    styleUrls: ['./company-countries-of-activity.component.scss'],
})
export class CompanyCountriesOfActivityComponent implements OnInit, OnDestroy {
    company: Company;
    @Input() urlname;
    @Input() businessMappingPriority: BusinessMappingPriority = null;
    company$: Subscription;
    continentsOfActivity: Tag[];
    @Output() noCountriesOfActivity = new EventEmitter<any>();

    constructor(private companyService: CompanyService) {
    }

    ngOnInit() {
        this.company$ = this.companyService.getCompanyCountriesOfActivity(this.urlname, this.businessMappingPriority).subscribe(data => {
            this.continentsOfActivity = [];
            for (const country of data.exportCountries) {
                if (!country.tagLocation && !country.tagLocation.continent) {
                    continue;
                }
                let continent = this.continentsOfActivity.find(i => +i.id === +country.tagLocation.continent.id);
                if (!continent) {
                    this.continentsOfActivity.push(country.tagLocation.continent);
                    continent = this.continentsOfActivity.find(i => +i.id === +country.tagLocation.continent.id);
                    continent.children = [];
                }
                continent.children.push(country);
            }
            if (!this.continentsOfActivity.length) {
                this.noCountriesOfActivity.emit();
            }
        });
    }

    ngOnDestroy() {
        if (this.company$) {
            this.company$.unsubscribe();
        }
    }
}
