import {WabelEntity} from "./wabel-entity.abstract";
import {Member} from "./member";
import {Tag} from "./tag";

export class Score extends WabelEntity {
    constructor(obj: any) {
        super(obj);

        if (obj) {
            this.children = obj.children ? obj.children.map(child => new Score(child)) : [];
        }
    }

    name: string;
    value: number;
    percent: number;
    weight: number;
    isEliminatory: boolean;
    error: string;
    children: Score[];
    text: string;

    get id() {
        return this.name;
    }
}
