import {Injectable} from "@angular/core";
import {WabelService} from "./wabel-service.abstract";
import {GraphQLService} from "./graphql-service";
import {LoadingService} from "./loading.service";

import {Product} from "../entities/product";
import {ProductGraphQL} from "../graphql/product.graphql";
import {UtilsService} from "../../utils.service";
import {ProductFile} from "../entities/product_file";
import {FileToUpload} from "../entities/fileToUpload";
import {CacheService} from "../../cache.service";
import {map} from "rxjs/operators";
import {Tag} from "../entities/tag";

@Injectable({
    providedIn: 'root'
})
export class ProductService extends WabelService {

    constructor(protected graphQLService: GraphQLService, protected loadingService: LoadingService, protected utilsService: UtilsService, protected cacheService: CacheService) {
        super(graphQLService, loadingService);
    }

    toArrayOfObjects(objects: any[]): Product[] {
        return objects.map((product: any) => new Product(product));
    }

    toObject(object: any): Product {
        return new Product(object);
    }

    getProductPackagingFormatOptions() {
        let productPackagingFormatOptions = [
            {value: '1', label: 'Kilogram (Kg)', id: ''},
            {value: '2', label: 'Liter (l)', id: ''},
            {value: '3', label: 'Gram (g)', id: ''},
            {value: '4', label: 'Pound (Ib)', id: ''},
            {value: '6', label: 'Decilitre (dl)', id: ''},
            {value: '7', label: 'Millilitre (ml)', id: ''},
            {value: '8', label: 'Ounce (oz)', id: ''},
            {value: '9', label: 'Gallon (gal)', id: ''},
            {value: '10', label: 'Unit (u)', id: ''},
            {value: '11', label: 'Sheets (st)', id: ''}
        ];
        for (let productPackagingFormatOption of productPackagingFormatOptions) {
            productPackagingFormatOption.id = UtilsService.getMachineName(productPackagingFormatOption.label);
        }

        return productPackagingFormatOptions;
    }

    findById(iduserproduct: number, companyProductPublished: boolean = null, similarProduct: boolean = false, limitSimilarProduct: number = 5) {
        if (similarProduct) {
            return this.query(ProductGraphQL.queries.findByIdSimilarProductsOtherProducts, {
                iduserproduct: iduserproduct,
                published: companyProductPublished,
                limitSimilarProduct: limitSimilarProduct
            })
                .pipe(map((data) => data.data ? new Product(data.data.product) : null));
        }
        return this.query(ProductGraphQL.queries.findById, {
            iduserproduct: iduserproduct,
            published: companyProductPublished
        })
            .pipe(map((data) => data.data ? new Product(data.data.product) : null));
    }

    findOneForEdition(iduserproduct: number) {
        return this.query(ProductGraphQL.queries.findOneForEdition, {
            iduserproduct: iduserproduct
        }, 'no-cache')
            .pipe(map((data) => data.data ? new Product(data.data.product) : null));
    }

    getProductProfilePage(idproduct: number) {
        return this.query(ProductGraphQL.queries.productProfilePage, {idproduct: idproduct})
            .pipe(map((data) => data.data && data.data.product ? new Product(data.data.product) : null));
    }

    deleteProduct(product: Product) {
        return this.mutation(ProductGraphQL.mutations.deleteProduct, {
            id_product: +product.iduserproduct
        })
            .pipe(map((data) => data.data.deleteProduct));
    }

    deleteProducts(products: Product[]) {
        return this.mutation(ProductGraphQL.mutations.deleteProducts, {
            idProducts: products.map(p => +p.iduserproduct)
        })
            .pipe(map((data) => data.data.deleteProducts));
    }

    toggleVisibilityOfProducts(products: Product[]) {
        return this.mutation(ProductGraphQL.mutations.toggleVisibilityOfProducts, {
            idProducts: products.map(p => +p.iduserproduct)
        })
            .pipe(map((data) => data.data.toggleVisibilityOfProducts));
    }

    duplicateProduct(product: Product) {
        return this.mutation(ProductGraphQL.mutations.duplicateProduct, {
            id_product: +product.iduserproduct
        })
            .pipe(map((data) => data.data?.duplicateProduct ? new Product(data.data.duplicateProduct) : null));
    }

    toggleVisibility(product: Product) {
        return this.mutation(ProductGraphQL.mutations.toggleVisibility, {
            id_product: +product.iduserproduct
        })
            .pipe(map((data) => data.data.toggleVisibility));
    }

    saveProductsOrder(productsIdsOrdered: number[]) {
        return this.mutation(ProductGraphQL.mutations.saveProductsOrder, {
            productsIdsOrdered: productsIdsOrdered
        })
            .pipe(map((data) => data.data.saveProductsOrder));
    }

    similarProducts(idproduct: number) {
        return this.query(ProductGraphQL.queries.similarProducts, {idproduct: idproduct})
            .pipe(map((data) => data.data && data.data.product ? new Product(data.data.product) : null));
    }

    saveProduct(
        id: number,
        published: boolean,
        name: string,
        pricePositioningIds: number[],
        activityIds: number[],
        categoryId: number,
        description: string,
        consumerTargetIds: number[] = [],
        innovation: boolean,
        productLogo: any,
        productImages: any[] = [],
        exportCertificationIds: number[] = [],
        qualityLabelIds: number[] = [],
        sustainabilityIds: number[] = [],
        nutritionalInformationIds: number[] = [],
        healthAndWellnessIds: number[] = [],
        rangeOfProductsIds: number[] = [],
        dietaryIds: number[] = [],
        consumptionModeIds: number[] = [],
        temperatureControlledIds: number[] = [],
        composition: string = null,
        countryOriginId: number = null,
        files: any[] = [],
        manufacturingCountryId: number = null,
        packagingFormats: any[] = [],
        duplicateProduct: boolean = false,
        packaging: string = null,
        packagingProductMaterial: any[] = [],
        packagingProductCategories: any[] = [],
        packagingPurpose: any[] = [],
        packagingConsistency: any[] = [],
        packagingEcoFriendlyFeatures: any[] = [],
        shelfLife: number = null
    ) {
        return this.mutation(ProductGraphQL.mutations.saveProduct, {
            id: id,
            published: published,
            name: name,
            pricePositioningIds: pricePositioningIds,
            activityIds: activityIds,
            categoryId: categoryId,
            description: description,
            consumerTargetIds: consumerTargetIds,
            innovation: innovation,
            productLogo: productLogo,
            productImages: productImages,
            exportCertificationIds: exportCertificationIds,
            qualityLabelIds: qualityLabelIds,
            sustainabilityIds: sustainabilityIds,
            nutritionalInformationIds: nutritionalInformationIds,
            healthAndWellnessIds: healthAndWellnessIds,
            rangeOfProductsIds: rangeOfProductsIds,
            dietaryIds: dietaryIds,
            consumptionModeIds: consumptionModeIds,
            temperatureControlledIds: temperatureControlledIds,
            composition: composition,
            countryOriginId: countryOriginId,
            files: files,
            manufacturingCountryId: manufacturingCountryId,
            packagingFormats: packagingFormats,
            duplicateProduct: duplicateProduct,
            packaging: packaging,
            packagingProductMaterial: packagingProductMaterial,
            packagingProductCategories: packagingProductCategories,
            packagingPurpose: packagingPurpose,
            packagingConsistency: packagingConsistency,
            packagingEcoFriendlyFeatures: packagingEcoFriendlyFeatures,
            shelfLife: shelfLife
        })
            .pipe(map((data) => data.data.saveProduct));
    }
}
