import {
    Directive, OnInit
} from '@angular/core';
import {_MatPaginatorBase} from "@angular/material/paginator";
import {NdlDataSource} from "./data-source";

@Directive()
export abstract class NdlPaginatorBase<T = any> extends _MatPaginatorBase<any> implements OnInit {
    dataSource: NdlDataSource

    emitFirstPageEvent() {
        this.page.emit({
            previousPageIndex: null,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            length: this.length,
        });
    }

    ngOnInit() {
        super.ngOnInit();
    }

    displayStrategy(oldData: T[], newData: T[]) {
        return newData;
    }
}
