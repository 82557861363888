import {WabelEntity} from "./wabel-entity.abstract";
import {Company} from "./company";

export class CompanyVideo extends WabelEntity {
    constructor(obj?: any) {
        super(obj);
        if (obj) {
            this.company = obj.company ? new Company(obj.company) : null;
        }
    }

    id: number;
    url: string;
    name: string;
    priority: number;
    company: Company;
}
