import {Component} from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";

@Component({
  selector: 'ndl-legend-chart',
  templateUrl: './legend-chart.component.html',
  styleUrls: ['./legend-chart.component.scss']
})

export class NdlLegendChartComponent {
    static nextId = 1;

    id = "legend" + NdlLegendChartComponent.nextId++;
    legendContainer: any;

    constructor() {}

    init() {
        this.legendContainer = am4core.create(this.id, am4core.Container);
        this.legendContainer.width = am4core.percent(100);
        this.legendContainer.height = am4core.percent(100);
        return this.legendContainer;
    }
}
