import {WabelEntity} from "./wabel-entity.abstract";
import {Company} from "./company";
import {VideoMeetingParticipant} from "./video_meeting_participant";
import {environment} from "../../../../environments/environment";
import {Member} from "./member";

export class VideoMeeting extends WabelEntity {
    constructor(obj: any) {
        super(obj);
        this.counterPartCompany = obj.counterPartCompany ? new Company(obj.counterPartCompany) : null;
        this.counterPartMember = obj.counterPartMember ? new Member(obj.counterPartMember) : null;
        this.videoMeetingParticipants = obj.videoMeetingParticipants?.items ? obj.videoMeetingParticipants.items.map(vmp => new VideoMeetingParticipant(vmp)) : [];
    }

    id: number;
    provider: string;
    startDate: string;
    endDate: string;
    counterPartCompany: Company;
    counterPartMember: Member;
    status: 'scheduled'|'live'|'finished';
    videoMeetingParticipants: VideoMeetingParticipant[];
    secondsBeforeStart: number;
    secondsBeforeEnd: number;

    get videoMeetingUrl(): string {
        if (!this.provider || !this.id) {
            return null;
        }
        return environment.needl_back_end_url + '/' + this.provider + '-video-meeting/' + this.id;
    }

    get formattedStartDateInDate(): Date {
        return new Date(this.startDate);
    }
    get formattedEndDateInDate(): Date {
        return new Date(this.endDate);
    }
}
