import {WabelEntity} from "./wabel-entity.abstract";
import {Product} from "./product";
import {SourcingNeed} from "./sourcing_need";
import {SourcingNeedRecommendationMemberResponse} from "./sourcing_need_recommendation_member_response";

export class SourcingNeedRecommendation extends WabelEntity {
    constructor(obj: any) {
        super(obj);

        if (obj) {
            this.sourcingNeed = obj.sourcingNeed ? new SourcingNeed(obj.sourcingNeed) : null;
            this.product = obj.product ? new Product(obj.product) : null;
            this.mySourcingNeedRecommendationMemberResponse = obj.mySourcingNeedRecommendationMemberResponse ? new SourcingNeedRecommendationMemberResponse(obj.mySourcingNeedRecommendationMemberResponse) : null;
            this.applications = obj.applications && obj.applications.items ? obj.applications.items.map(application => new SourcingNeedRecommendationMemberResponse(application)) : [];
            this.mainApplication = obj.mainApplication ? new SourcingNeedRecommendationMemberResponse(obj.mainApplication) : null;
        }
    }

    id: number;
    sourcingNeed: SourcingNeed;
    product: Product;
    createdDate: string;
    matchingScore: number;
    isNew: boolean;
    apparitionDateSeller: string;
    mySourcingNeedRecommendationMemberResponse: SourcingNeedRecommendationMemberResponse;
    applications: SourcingNeedRecommendationMemberResponse[];
    mainApplication: SourcingNeedRecommendationMemberResponse;
}
