import {Injectable} from "@angular/core";
import {CanActivate} from '@angular/router';
import {map} from 'rxjs/operators';
import {AuthService} from "../services/auth.service";
import {PageErrorService} from "../services/page-error.service";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class IsNotLoggedGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private pageErrorService: PageErrorService
    ) {
    }

    public canActivate(): Observable<boolean> | boolean {
        if (this.authService.wasLoadedAtLeastOnce && !this.authService.getMember()) {
            return true;
        }

        return this.authService.getMainRequest().pipe(map(member => {
            if (member) {
                this.pageErrorService.goToHomePage();
                return false;
            }
            return true;
        }))
    }
}
