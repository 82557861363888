import {
    AfterContentInit, AfterViewInit,
    ChangeDetectorRef,
    Component, ContentChildren, ElementRef, HostListener, Input, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild
} from '@angular/core';
import {CdkStepper} from "@angular/cdk/stepper";
import {Directionality} from "@angular/cdk/bidi";
import {CdkStep} from "@angular/cdk/stepper/stepper";
import {NdlStepNewComponent} from "./step.component";
import {NavigationEnd, Router, RouterOutlet} from "@angular/router";
import {animate, animateChild, group, query, style, transition, trigger} from "@angular/animations";
import {MatStep, matStepperAnimations} from "@angular/material/stepper";
import {Subscription} from 'rxjs';

@Component({
    selector: 'ndl-stepper-new',
    templateUrl: './stepper.component.html',
    providers: [{provide: CdkStepper, useExisting: NdlStepperNewComponent}],
    host: {
        "class": "ndl-stepper-new"
    },
    animations: [
        matStepperAnimations.horizontalStepTransition
    ]
})
export class NdlStepperNewComponent extends CdkStepper implements AfterViewInit, OnDestroy {
    private selectionChangeSubscription: Subscription;
    private selectionHistory: number[] = [];
    private selectionHistoryIndex = 0;
    private historyOnGoing = false;

    labelPosition: "bottom" | "end" = "bottom";

    @ContentChildren(NdlStepNewComponent) steps: QueryList<NdlStepNewComponent>;

    // @HostListener('window:popstate', ['$event'])
    // onPopState(event: Event) {
    //     event.preventDefault();
    //     event.stopPropagation();
    //     if (this.back()) {
    //         event.preventDefault();
    //     }
    // }

    ngAfterViewInit() {
        super.ngAfterViewInit();

        this.selectionHistory.push(this.selectedIndex);
        if (this.selectionChangeSubscription) {
            this.selectionChangeSubscription.unsubscribe();
        }
        this.selectionChangeSubscription = this.selectionChange.subscribe(e => {
            if (this.historyOnGoing) {
                this.historyOnGoing = false;
            } else {
                this.selectionHistory.splice(this.selectionHistoryIndex, this.selectionHistory.length - (this.selectionHistoryIndex + 1));
                this.selectionHistory.push(e.selectedIndex);
                this.selectionHistoryIndex++;
            }
        })
    }

    goToId(id: string): void {
        const index = this.steps.toArray().findIndex(s => s.id === id);
        this.steps.forEach((s, i) => {
            if (i < index) {
                s.completed = true;
            }
        });
        this.steps.toArray()[index].select()
    }

    stepIsNavigable(index: number, step: NdlStepNewComponent): boolean {
        return step.completed || this.selectedIndex === index || !this.linear;
    }

    // back(): boolean {
    //     this.historyOnGoing = true;
    //     if (this.selectionHistoryIndex) {
    //         this.selectedIndex = this.selectionHistory[--this.selectionHistoryIndex];
    //         return !!this.selectionHistoryIndex;
    //     }
    //     return false;
    // }

    // Had to comment that because next button was not working anymore
    // next(): boolean {
    //     if (this.selectionHistoryIndex < this.selectionHistory.length - 1) {
    //         this.selectedIndex = this.selectionHistory[++this.selectionHistoryIndex];
    //         return !!this.selectionHistoryIndex;
    //     }
    //     return false;
    // }

    ngOnDestroy() {
        if (this.selectionChangeSubscription) {
            this.selectionChangeSubscription.unsubscribe();
        }
    }
}
