import {Component, forwardRef, Input, OnInit, SimpleChanges, EventEmitter, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CacheService} from "../../../../services/cache.service";
import {Tag} from "../../../../services/wabel-client/entities/tag";
import {UtilsService} from "../../../../services/utils.service";

@Component({
    selector: 'kit-form-country',
    templateUrl: './kit-form-country.component.html',
    styleUrls: ['./kit-form-country.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => KitFormCountryComponent),
        multi: true
    }]
})
export class KitFormCountryComponent implements ControlValueAccessor, OnInit {

    @Input() label: string;
    @Input() selectedCountry;
    @Output() selectedElementHasChanged: EventEmitter<any> = new EventEmitter();
    @Input() isRequired: boolean = false;
    @Input() showError: boolean = false;
    @Input() doCheck: boolean = false;
    @Input() customCountries: any;
    @Input() placeholder: string = 'All countries';
    @Input() unselectableMessage: string = null;
    @Input() errorMessage: string = 'This field is required';

    states: Tag[];

    propagateChange: any = () => {
    };

    constructor(private cacheService: CacheService, private utilsService: UtilsService) {
    }

    ngOnInit() {
        if (!this.customCountries || !this.customCountries.length) {
            this.cacheService.getLocation().subscribe(data => {
                if (!data) return;
                this.states = [];
                data.tags.forEach(continent => continent.children.forEach(region => region.children.forEach(country => this.states.push(country))));
                this.states = this.utilsService.sortListByProperty(this.states, 'name');
            });
        }
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }


    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(value: any): void {
        if (value && value != 0) {
            this.selectedCountry = value.toString();
        }
    }

    onChange() {
        if (!this.customCountries) {
            let country = this.states.filter(obj => {
                return +obj.id === +this.selectedCountry;
            });
            this.selectedElementHasChanged.emit(country);
            this.propagateChange(this.selectedCountry);
        } else {
            let country = this.customCountries.filter(obj => {
                return obj.id === this.selectedCountry;
            });
            this.selectedElementHasChanged.emit(country);
            this.propagateChange(this.selectedCountry);
        }
    }


    ngOnChanges(changes: SimpleChanges): void {
        if (changes.doCheck && changes.doCheck.currentValue)
            this.checkError();
    }


    checkError() {
        if (this.isRequired) {
            this.showError = this.isRequired && (!this.selectedCountry || this.selectedCountry == 0);
        }
    }

}
