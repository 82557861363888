<div class="container-fluid">
    <h1>Arrow Inside</h1>
    <div class="row">
        <div class="col-6 col-offset-3">
            <ndl-carousel-new>
                <ndl-slide>
                    <ndl-card>
                        <div class="px-2">
                            <h2>This is card 1</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam aut dolorum nemo, neque recusandae reprehenderit suscipit. Accusantium doloribus enim est illum ipsa neque porro, quidem ratione repudiandae, velit, veritatis vero.</p>
                        </div>
                    </ndl-card>
                </ndl-slide>
                <ndl-slide>
                    <ndl-card>
                        <div class="px-2">
                        <h2>This is card 2</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam aut dolorum nemo, neque recusandae reprehenderit suscipit. Accusantium doloribus enim est illum ipsa neque porro, quidem ratione repudiandae, velit, veritatis vero.</p>
                        </div>
                    </ndl-card>
                </ndl-slide>
                <ndl-slide>
                    <ndl-card>
                        <div class="px-2">
                        <h2>This is card 3</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam aut dolorum nemo, neque recusandae reprehenderit suscipit. Accusantium doloribus enim est illum ipsa neque porro, quidem ratione repudiandae, velit, veritatis vero.</p>
                        </div>
                    </ndl-card>
                </ndl-slide>
            </ndl-carousel-new>
        </div>
    </div>
    <h1>Arrow Bottom</h1>
    <div class="row">
        <div class="col-6 col-offset-3">
            <ndl-carousel-new arrowPosition="bottom">
                <ndl-slide>
                    <ndl-card>
                        <div class="px-2">
                            <h2>This is card 1</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam aut dolorum nemo, neque recusandae reprehenderit suscipit. Accusantium doloribus enim est illum ipsa neque porro, quidem ratione repudiandae, velit, veritatis vero.</p>
                        </div>
                    </ndl-card>
                </ndl-slide>
                <ndl-slide>
                    <ndl-card>
                        <div class="px-2">
                        <h2>This is card 2</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam aut dolorum nemo, neque recusandae reprehenderit suscipit. Accusantium doloribus enim est illum ipsa neque porro, quidem ratione repudiandae, velit, veritatis vero.</p>
                        </div>
                    </ndl-card>
                </ndl-slide>
                <ndl-slide>
                    <ndl-card>
                        <div class="px-2">
                        <h2>This is card 3</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam aut dolorum nemo, neque recusandae reprehenderit suscipit. Accusantium doloribus enim est illum ipsa neque porro, quidem ratione repudiandae, velit, veritatis vero.</p>
                        </div>
                    </ndl-card>
                </ndl-slide>
            </ndl-carousel-new>
        </div>
    </div>
    <h1>Arrow Dragging Disabled</h1>
    <div class="row">
        <div class="col-6 col-offset-3">
            <ndl-carousel-new arrowPosition="bottom" disableDragging>
                <ndl-slide>
                    <ndl-card>
                        <div class="px-2">
                            <h2>This is card 1</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam aut dolorum nemo, neque recusandae reprehenderit suscipit. Accusantium doloribus enim est illum ipsa neque porro, quidem ratione repudiandae, velit, veritatis vero.</p>
                        </div>
                    </ndl-card>
                </ndl-slide>
                <ndl-slide>
                    <ndl-card>
                        <div class="px-2">
                            <h2>This is card 2</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam aut dolorum nemo, neque recusandae reprehenderit suscipit. Accusantium doloribus enim est illum ipsa neque porro, quidem ratione repudiandae, velit, veritatis vero.</p>
                        </div>
                    </ndl-card>
                </ndl-slide>
                <ndl-slide>
                    <ndl-card>
                        <div class="px-2">
                            <h2>This is card 3</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam aut dolorum nemo, neque recusandae reprehenderit suscipit. Accusantium doloribus enim est illum ipsa neque porro, quidem ratione repudiandae, velit, veritatis vero.</p>
                        </div>
                    </ndl-card>
                </ndl-slide>
            </ndl-carousel-new>
        </div>
    </div>
</div>
