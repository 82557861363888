import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NdlDateComponent} from './date.component';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {NeedlFormFieldModule} from '../form-field/form-field.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import {NeedlFormModule} from "../forms.module";

@NgModule({
  declarations: [
      NdlDateComponent
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        NeedlFormFieldModule,
        MatDatepickerModule,
        MatNativeDateModule,
        NeedlFormModule
    ],
  exports: [
      NdlDateComponent
  ]
})
export class NeedlDateModule { }
