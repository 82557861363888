import {Directive} from '@angular/core';

@Directive({
    selector: '[ndl-card-status-outside],[ndlCardStatusOutside],ndl-card-status-outside',
    host: {
        class: 'ndl-card-status-outside'
    }
})
export class NdlCardStatusOutsideDirective {
}
