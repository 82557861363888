import {FilePreviewableInterface} from "./file-previewable.interface";

export class DummyFile implements FilePreviewableInterface {
    private _fileName: string;
    private _fileSize: number;
    private _fileExt: string;
    private _fileId: string;
    private _fileUrl: string;
    private _fileSelected: boolean = false;

    set fileUrl(value: string) {
        this._fileUrl = value;
    }

    set fileId(value: string) {
        this._fileId = value;
    }

    set fileExt(value: string) {
        this._fileExt = value;
    }

    set fileSize(value: number) {
        this._fileSize = value;
    }

    set fileName(value: string) {
        this._fileName = value;
    }

    set fileSelected(value: boolean) {
        this._fileSelected = value;
    }

    previewExtension(): string {
        return this._fileExt;
    }

    previewFileName(): string {
        return this._fileName;
    }

    previewFileSize(): number {
        return this._fileSize;
    }

    previewId(): string {
        return this._fileId;
    }

    previewSelected(): boolean {
        return false;
    }

    previewUrl(): string {
        return this._fileUrl;
    }
}
