import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}
// document.write('<script type="text/javascript" src="https://maps.google.com/maps/api/js?key='+environment.googleApiKeyMaps+'&libraries=places&language=en-US"></script>');

platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
    if ('serviceWorker' in navigator) {
        // && environment.production
        //navigator.serviceWorker.register('/app/ngsw-worker.js');
        navigator.serviceWorker.getRegistrations().then(function (registrations) {
            for (const registration of registrations) {
                // unregister service worker
                registration.unregister();
            }
        });
    }
}).catch(err => console.log(err));
