<div class="ndl-table-filter">
    <ng-content select="[ndlListFilter],[ndlTableFilter]"></ng-content>
</div>

<div *ngIf='_selection.selected.length && actions.length' class="ndl-list-actions">
    <ng-content select="[ndl-list-action]"></ng-content>
    <button mat-icon-button color="primary" type="button" class="ndl-actions-clear" (click)="_selection.clear()">
        <mat-icon>close</mat-icon>
    </button>
</div>

<cdk-table class="ndl-list" [ngClass]="listClass" [dataSource]="dataSource" cdkDropList (cdkDropListSorted)="dragged.emit($event)" (cdkDropListDropped)="drop($event)"
    [cdkDropListDisabled]="!dragDrop">
    <ng-container cdkColumnDef="item">
        <ng-container *cdkCellDef="let row; let i = index">
            <ng-container *ngTemplateOutlet="itemTemplate.content; context: {$implicit: row, index: i}"></ng-container>
        </ng-container>
    </ng-container>

    <ng-container cdkColumnDef="placeholder">
        <ng-container *cdkCellDef="let row">
            <ng-container *ngTemplateOutlet="placeholderTemplate.content; context: {$implicit: row}"></ng-container>
        </ng-container>
    </ng-container>

    <cdk-row class='ndl-list-item'
             cdkDrag
             *cdkRowDef="let row; columns: ['item']; let i = index"
             [style.flex-basis]="gridEnable ? (100/grid)+'%' : null"
             [ngClass]="getItemClasses(i)"></cdk-row>

</cdk-table>

<ng-content></ng-content>
