import {
    Component, ContentChild,
    forwardRef, Input, ViewChild
} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {NdlInputBaseDirective} from '../input-base.directive';
import {MatInput} from '@angular/material/input';
import {NdlTextBaseDirective} from '../input-text-base.directive';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Component({
    selector: 'ndl-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.scss'],
    host: {
        'class': 'ndl-textarea',
        '(blur)': "onTouched()"
    }
})
export class NdlTextAreaComponent extends NdlTextBaseDirective {
    @Input() minHeight = null;

    private _autoresize = false;
    @Input() get autoresize() {
        return this._autoresize;
    }
    set autoresize(autoresize) {
        this._autoresize = coerceBooleanProperty(autoresize);
    }
}
