import {
    Component, Input, OnInit
} from '@angular/core';
import {Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {NdlPasswordBaseDirective} from './password-base.directive';

@Component({
    selector: 'ndl-password',
    templateUrl: './password.component.html',
    styleUrls: ['./password.component.scss'],
    host: {
        'class': 'ndl-password',
        '(blur)': "onTouched()"
    }
})
export class NdlPasswordComponent extends NdlPasswordBaseDirective {
    _regexp = /.{8,}/;
    @Input() get regexp(): RegExp {
        return this._regexp;
    }

    set regexp(regexp) {
        this._regexp = regexp;
        this._setPatternValidator();
        this.updateValidators(this.validators);
    }

    protected _setPatternValidator() {
        this.validators = Validators.pattern(this.regexp);
    }
}
