import {Directive, Input, ViewChild} from '@angular/core';
import {NdlInputBaseDirective} from './input-base.directive';
import {MatInput} from '@angular/material/input';
import {coerceBooleanProperty} from "@angular/cdk/coercion";

@Directive()
export abstract class NdlTextBaseDirective extends NdlInputBaseDirective {
    override _value = '';
    @Input() maxLength;
    @ViewChild(MatInput, {static: true}) input: MatInput;

    _clearable: boolean;
    @Input()
    get clearable(): boolean {
        return this._clearable;
    }

    set clearable(value: boolean) {
        this._clearable = coerceBooleanProperty(value);
    }
}
