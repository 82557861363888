import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NdlWysiwygComponent} from './wysiwyg.component';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {NeedlFormFieldModule} from '../form-field/form-field.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {NdlWysiwygFieldComponent} from './wysiwyg-field.component';


@NgModule({
    declarations: [
        NdlWysiwygComponent,
        NdlWysiwygFieldComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CKEditorModule,
        NeedlFormFieldModule
    ],
    exports: [
        NdlWysiwygComponent
    ]
})
export class NeedlWysiwygModule {
}
