import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {NeedlFormFieldModule} from '../form-field/form-field.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {GooglePlaceModule} from "ngx-google-places-autocomplete";
import {NdlAddressComponent} from "./address.component";
import {NeedlFormModule} from "../forms.module";


@NgModule({
    declarations: [
        NdlAddressComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        NeedlFormFieldModule,
        GooglePlaceModule,
        NeedlFormModule
    ],
    exports: [
        NdlAddressComponent
    ]
})
export class NeedlAddressModule {
}
