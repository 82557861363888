import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SourcingPriorityService} from "../../../services/wabel-client/services/sourcing_priority.service";
import {SourcingPriority} from "../../../services/wabel-client/entities/sourcing_priority";
import {MemberService} from "../../../services/wabel-client/services/member.service";
import {Member} from "../../../services/wabel-client/entities/member";
import {CacheService} from "../../../services/cache.service";
import {Subscription} from "rxjs";
import {UtilsService} from "../../../services/utils.service";
import {Tag} from "../../../services/wabel-client/entities/tag";

@Component({
    selector: 'product-sourcing-priorities',
    templateUrl: './product-sourcing-priorities.component.html',
    styleUrls: ['./product-sourcing-priorities.component.scss']
})
export class ProductSourcingPrioritiesComponent implements OnInit, OnDestroy {

    @Input() step = 0;
    @Output() closeEvent: EventEmitter<string> = new EventEmitter<string>();

    options: SourcingPriority[] = [];
    selectedOptions: SourcingPriority[] = [];

    types = [];

    selectedOptionsLoaded = false;
    categoriesOptionsLoaded = false;
    countriesOptionsLoaded = false;
    targetConsumersLoaded = false;
    rangeOfProductsLoaded = false;
    nutritionnalInformationsLoaded = false;
    qualityLabelsLoaded = false;
    exportCertificationsLoaded = false;
    member: Member = null;

    sourcingPriorities$: Subscription;
    me$: Subscription;

    asynchronousButtonState = 'before';
    errorAfterSubmit: string = null;

    constructor(
        public sourcingPriorityService: SourcingPriorityService,
        private memberService: MemberService,
        private cacheService: CacheService,
        private utilsService: UtilsService
    ) {

    }

    ngOnInit() {
        this.sourcingPriorities$ = this.sourcingPriorityService.getSourcingPriorities().subscribe((data) => {
            if (!data) {
                return;
            }
            if (!this.selectedOptionsLoaded) {
                this.selectedOptions = data;
                this.selectedOptionsLoaded = true;
            }
        });

        this.me$ = this.memberService.getMe().subscribe((data) => {
            if (!data) {
                return;
            }
            this.member = data;
        });

        this.cacheService.getTargetConsumer().subscribe((data) => {
            if (!data) {
                return;
            }
            const tags = data.tags;
            if (!this.targetConsumersLoaded) {
                for (const tag of tags) {
                    const tagFormatted = {
                        id: null,
                        label: tag.name,
                        type: 'tag',
                        idRef: tag.id
                    } as SourcingPriority;
                    this.options.push(tagFormatted);
                }
                this.targetConsumersLoaded = true;
            }
        });

        this.cacheService.getRangeOfProduct().subscribe((data) => {
            if (!data) {
                return;
            }
            const tags = data.tags;
            if (!this.rangeOfProductsLoaded) {
                for (const tag of tags) {
                    const tagFormatted = {
                        id: null,
                        label: tag.name,
                        type: 'tag',
                        idRef: tag.id
                    } as SourcingPriority;
                    this.options.push(tagFormatted);
                }
                this.rangeOfProductsLoaded = true;
            }
        });

        this.cacheService.getNutritionalInformation().subscribe((data) => {
            if (!data) {
                return;
            }
            const tags = data.tags;
            if (!this.nutritionnalInformationsLoaded) {
                for (const tag of tags) {
                    const tagFormatted = {
                        id: null,
                        label: tag.name,
                        type: 'tag',
                        idRef: tag.id
                    } as SourcingPriority;
                    this.options.push(tagFormatted);
                }
                this.nutritionnalInformationsLoaded = true;
            }
        });

        this.cacheService.getQualityLabel().subscribe((data) => {
            if (!data) {
                return;
            }
            const tags = data.tags;
            if (!this.qualityLabelsLoaded) {
                for (const tag of tags) {
                    const tagFormatted = {
                        id: null,
                        label: tag.name,
                        type: 'tag',
                        idRef: tag.id
                    } as SourcingPriority;
                    this.options.push(tagFormatted);
                }
                this.qualityLabelsLoaded = true;
            }
        });

        this.cacheService.getExportCertification().subscribe((data) => {
            if (!data) {
                return;
            }
            const tags = data.tags;
            if (!this.exportCertificationsLoaded) {
                for (const tag of tags) {
                    const tagFormatted = {
                        id: null,
                        label: tag.name,
                        type: 'tag',
                        idRef: tag.id
                    } as SourcingPriority;
                    this.options.push(tagFormatted);
                }
                this.exportCertificationsLoaded = true;
            }
        });

        this.cacheService.getCategories().subscribe((data) => {
            if (!data) {
                return;
            }
            data.tags = data.tags.filter(tag => tag.machineName !== 'packaging');
            const categories = data.tags;
            if (!this.categoriesOptionsLoaded) {
                for (const category of categories) {
                    for (const subCategory of category.children) {
                        const subCategoryFormatted = {
                            id: null,
                            label: subCategory.name,
                            type: 'category',
                            idRef: subCategory.id
                        } as SourcingPriority;
                        this.options.push(subCategoryFormatted);
                        // this.categoriesLevel2.push(subCategory);
                        for (const subSubCategory of subCategory.children) {
                            const subSubCategoryFormatted = {
                                id: null,
                                label: subSubCategory.name,
                                type: 'category',
                                idRef: subSubCategory.id
                            } as SourcingPriority;
                            this.options.push(subSubCategoryFormatted);
                        }
                    }
                }
                this.categoriesOptionsLoaded = true;
            }
        });

        this.cacheService.getLocation().subscribe((data) => {
            if (!data) {
                return;
            }
            let countries: Tag[] = [];
            data.tags.forEach(continent => continent.children.forEach(region => region.children.forEach(country => countries.push(country))));
            if (!this.countriesOptionsLoaded) {
                for (const country of countries) {
                    const countryFormatted = {
                        id: null,
                        label: country.name,
                        type: 'country',
                        idRef: country.id
                    } as SourcingPriority;
                    this.options.push(countryFormatted);
                }
                this.countriesOptionsLoaded = true;
            }
        });

        this.onChange();
    }

    ngOnDestroy() {
        if (this.sourcingPriorities$) {
            this.sourcingPriorities$.unsubscribe();
        }
        if (this.me$) {
            this.me$.unsubscribe();
        }
    }

    get tagsOptionsLoaded() {
        return this.targetConsumersLoaded && this.rangeOfProductsLoaded && this.nutritionnalInformationsLoaded
            && this.qualityLabelsLoaded && this.exportCertificationsLoaded;
    }

    onClose(e: Event) {
        this.closeEvent.emit();
    }

    onSave(e: Event) {
        this.errorAfterSubmit = null;
        this.asynchronousButtonState = 'loading';
        this.sourcingPriorityService.saveSourcingPriorities(this.selectedOptions).subscribe(
            success => {
                this.asynchronousButtonState = 'after';
                this.refreshSelectedOptions();
            },
            error => {
                this.errorAfterSubmit = this.utilsService.detectErrorMessages(error).join('. ');
                this.asynchronousButtonState = 'before';
            }
        );
    }

    onChange(e: Event = null) {
        this.types = Array.from(new Set(this.selectedOptions.map((item: any) => item.type)));
        this.types.sort(function (a, b) {
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        });
    }

    removeFromSelection(e: Event) {
        const splittedEvent = e.toString().split("_", 2);
        const objectToRemove = this.selectedOptions.find(o => o.type === splittedEvent[0] && +o.idRef === +splittedEvent[1]);
        this.selectedOptions = this.selectedOptions.filter(obj => obj !== objectToRemove);
        this.onChange();
    }

    refreshSelectedOptions() {
        this.sourcingPriorityService.getSourcingPriorities().subscribe((data) => {
            if (!data) {
                return;
            }
        });
    }
}
