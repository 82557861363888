import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {debounceTime} from "rxjs/operators";
import {Subject} from "rxjs";
import {Subscription} from "rxjs/internal/Subscription";
import {CompanyService} from "../../../../../../services/wabel-client/services/company.service";
import {Company} from "../../../../../../services/wabel-client/entities/company";
import { UtilsService } from '../../../../../../services/utils.service';

@Component({
    selector: 'registration-professional-info',
    templateUrl: './registration-professional-info.component.html',
    styleUrls: ['./registration-professional-info.component.scss']
})
export class RegistrationProfessionalInfoComponent implements OnInit {

    @Input() user;

    formCompleted = false;
    formSubmitted = false;
    errorJobTitle = false;
    errorCompany = false;
    errorWebsite = false;
    displayWebsite = false;
    isWebsite = false;

    companySuggestionsListLoading = false;
    companySuggestionSearchChange = new Subject();
    companySearchSuggestionSubscription: Subscription;
    companySuggestionsList: Company[] = [];
    companySuggestionsListFormatted: string[] = [];

    scrollTo = null;

    @Output() stepFinished: EventEmitter<any> = new EventEmitter();

    constructor(private companyService: CompanyService) {

    }

    ngOnInit() {
        this.companySuggestionSearchChange.asObservable().pipe(debounceTime(500)).subscribe(
            (value: string) => {
                if (this.companySearchSuggestionSubscription) {
                    this.companySearchSuggestionSubscription.unsubscribe();
                }
                this.companySuggestionsList = [];
                this.displayWebsite = false;
                if (value && value.length) {
                    this.companySuggestionsListLoading = true;
                    this.companySearchSuggestionSubscription = this.companyService.getSimpleListByFilter(10, 0, 'name', 'ASC', value, [2]).subscribe((data) => {
                        this.companySuggestionsList = data.items;
                        this.companySuggestionsListFormatted = this.companySuggestionsList.map((c) => c.name);
                        this.displayWebsite = true;
                        this.companySuggestionsListLoading = false;
                    });
                }
            }
        );
    }

    submitForm() {
        this.formSubmitted = true;
        this.scrollTo = null;
        this.checkFormErrors();
        if (this.scrollTo) {
            UtilsService.scrollToElement(document.querySelector('#' + this.scrollTo));
        }
        if (!this.formCompleted) {
            return;
        }
        this.stepFinished.emit(this.user);
    }

    checkFormErrors() {
        if (!this.formSubmitted) {
            return;
        }
        this.formCompleted = true;
        let scrollTo = null;

        this.errorJobTitle = false;
        if (!this.user.jobTitle) {
            this.errorJobTitle = true;
            scrollTo = 'job-position';
            this.formCompleted = false;
        }

        this.errorCompany = false;
        if (!this.user.companyId && !this.user.companyName) {
            this.errorCompany = true;
            scrollTo = !scrollTo ? 'company-name' : scrollTo;
            this.formCompleted = false;
        }

        this.errorWebsite = false;
        if (this.user.companyName && !this.user.website && !this.isWebsite) {
            this.errorWebsite = true;
            scrollTo = !scrollTo ? 'company-website' : scrollTo;
            this.formCompleted = false;
        }
        this.scrollTo = scrollTo;
    }

    searchCompanyChanged(event) {
        this.companySuggestionSearchChange.next(event);
        this.user.companyId = null;
        this.user.companyName = event;
        this.checkFormErrors();
    }

    companySelectedFromSuggestions(event) {
        const company = this.companySuggestionsList.find((c) => c.name === event);
        if (company) {
            this.user.companyId = company.idcompany;
            this.user.companyName = null;
            this.user.website = null;
            this.displayWebsite = false;
        } else {
            this.user.companyId = null;
            this.user.companyName = event;
            this.displayWebsite = true;
        }
        this.checkFormErrors();
    }
}
