import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Member} from "../../../services/wabel-client/entities/member";
import {Company} from "../../../services/wabel-client/entities/company";
import {AuthService} from "../../../services/auth.service";
import {PrivateMessagesService} from "../../../services/private-messages.service";
import {NeedlSecurityService} from "@wabel/needl-security";
import {RIGHT} from "../../../services/wabel-client/security/right.const";

@Component({
    selector: 'contact-button',
    templateUrl: './contact-button.component.html',
    styleUrls: ['./contact-button.component.scss']
})
export class ContactButtonComponent implements OnInit {
    @Input() company: Company;
    @Input() buttonTrackOnGTM: string;
    @Input() buttonActionGTM: string;
    @Input() buttonCategoryGTM: string;
    @Input() paramsGTM: any = {};
    @Input() needCheckIsBuyer: boolean;

    isBuyer: boolean;
    isVerified: boolean;

    constructor(public authService: AuthService, private privateMessagesService: PrivateMessagesService, private router: Router, private needlSecurityService: NeedlSecurityService) {

    }

    ngOnInit() {
        this.isVerified = this.needlSecurityService.hasRight(RIGHT.CAN_CONTACT_SUPPLIERS);

        if (this.needCheckIsBuyer) {
            this.isBuyer = this.authService.isBuyer();
        }
    }
}
