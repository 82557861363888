import {
    AfterViewInit, ChangeDetectorRef, ContentChildren,
    Directive, EventEmitter, HostBinding,
    Input,
    OnInit, Optional, Output, QueryList, Self, ViewChild, ViewChildren
} from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl, NgControl, NgModel,
    ValidationErrors,
    Validator,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {MatFormFieldControl} from '@angular/material/form-field';
import {NdlErrorComponent} from './error.component';

@Directive()
export abstract class NdlInputBaseDirective<T = any> implements ControlValueAccessor, AfterViewInit {
    defaultValue: T;
    validators: ValidatorFn | ValidatorFn[];

    @Input() label: string;
    @Input() hint: string;
    @Input() placeholder = "";
    @Input() title = '';
    @Input() name = null;

    _value: T;
    @Input()
    get value(): T {
        return this.innerFormControl?.value ?? this._value;
    }

    set value(value: T) {
        if (this._value !== value) {
            this._value = value;
        }
    }

    @HostBinding('class.ndl-disabled') _disabled: boolean = false;
    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
    }

    _required: boolean;
    @Input()
    get required(): boolean {
        return this._required;
    }

    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);
    }

    get empty() {
        return this.value === this.defaultValue || this.value === null || this.value === undefined;
    }

    _control: FormControl;

    get innerFormControl(): FormControl {
        return this.ngControl?.control as FormControl ?? this._control;
    }

    @ContentChildren(NdlErrorComponent) errors: QueryList<NdlErrorComponent>;

    constructor(
        @Self() @Optional() public ngControl: NgControl,
        private _changeDetector: ChangeDetectorRef
    ) {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
        this.defaultValue = this._value;
    }

    ngAfterViewInit() {
        if (!this.ngControl) {
            this._control = new FormControl({value: this.value, disabled: this.disabled});
        }
        if (this.validators) {
            this.updateValidators(this.validators);
        }
    }

    @Output() change = new EventEmitter<T>();
    @ViewChild(MatFormFieldControl, {static: true}) matFormFieldControl: MatFormFieldControl<T>;

    onChange: (value: T) => void;
    onTouched: () => void;

    writeValue(value: T) {
        this.value = value;
        this._changeDetector.markForCheck();
    }

    registerOnChange(fn: (value: T) => void) {
        this.onChange = (value: T) => {
            this.change.emit(value);
            if (fn) {
                fn(value);
            }
        }
    }

    registerOnTouched(fn: () => void) {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean) {
        this.disabled = isDisabled;
        this._changeDetector.markForCheck();
    }

    updateValidators(validators: ValidatorFn | ValidatorFn[]) {
        if (this.innerFormControl) {
            this.innerFormControl.addValidators(validators);
        }
    }

    clear() {
        this.value = this.defaultValue;
        this.innerFormControl?.reset(this.defaultValue);
    }
}
