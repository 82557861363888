import {
    Component,
    OnInit,
} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';

@Component({
    selector: 'business-mapping',
    templateUrl: './business-mapping.component.html'
})
export class BusinessMappingComponent {

    cameFromRewardSystem = false;

    constructor(private route: ActivatedRoute,
                private authService: AuthService,
                private router: Router) {
        this.authService.user.businessMapping = this.route.snapshot.data.businessMapping;
        this.cameFromRewardSystem = this.route.snapshot.queryParams.fromReward ?? this.router.getCurrentNavigation().extras.state?.fromReward;
    }
}
