import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatChipInputEvent} from "@angular/material/chips";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {Company} from "../../../../services/wabel-client/entities/company";

export interface ChipTag {
    idpackagingproductreferencetag :number;
    tagvalue: string;
}
@Component({
  selector: 'app-kit-form-chips',
  templateUrl: './kit-form-chips.component.html',
  styleUrls: ['./kit-form-chips.component.scss']
})
export class KitFormChipsComponent implements OnInit {
    @Input() label=null;
    @Input() InitSelectedValues =[];
    @Output() selectedElementHasChanged: EventEmitter<any> = new EventEmitter();

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    chipData: ChipTag[] = [];

    ngOnInit() {
        if (this.InitSelectedValues.length != 0){
            this.chipData = this.InitSelectedValues;
        }
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // Add our fruit
        if ((value || '').trim()) {
            this.chipData.push({idpackagingproductreferencetag:null,tagvalue: value.trim()});
            this.selectedElementHasChanged.emit(this.chipData);
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    remove(tag: ChipTag): void {
        const index = this.chipData.indexOf(tag);

        if (index >= 0) {
            this.chipData.splice(index, 1);
            this.selectedElementHasChanged.emit(this.chipData);
        }
    }

}
