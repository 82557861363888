import gql from "graphql-tag";
import {TagTypeService} from "../services/tag_type.service";

export class VideoMeetingGraphql {
    static fragments = {};

    static queries = {
        myVideoMeetingAvailabilities: gql`
            query myVideoMeetingAvailabilities($onlyFutureAvailabilities: Boolean) {
                myVideoMeetingAvailabilities(onlyFutureAvailabilities: $onlyFutureAvailabilities) {
                    items {
                        id
                        startDate
                        endDate
                    }
                }
            }
        `,
        videoMeetingAvailabilitiesByMember: gql`
            query videoMeetingAvailabilitiesByMember($idMember: Int!, $onlyFutureAvailabilities: Boolean, $onlyAvailabilities4HoursAfterNow: Boolean) {
                videoMeetingAvailabilitiesByMember(idMember: $idMember, onlyFutureAvailabilities: $onlyFutureAvailabilities, onlyAvailabilities4HoursAfterNow: $onlyAvailabilities4HoursAfterNow) {
                    items {
                        id
                        startDate
                        endDate
                    }
                }
            }
        `,
        myVideoMeetingRequests: gql`
            query myVideoMeetingRequests($limit: Int, $offset: Int, $participants: String) {
                myVideoMeetingRequests(participants: $participants) {
                    count
                    items(limit: $limit, offset: $offset) {
                        id
                        status
                        statusReason
                        dateLastProposition
                        duration
                        initiatorIdMember {
                            idmember
                            fullname
                            logoUrl(size: "123", returnNull: true)
                            jobtitle
                            nameInitials
                            buyingScopeCategories: buyingScopeTags(tagTypeInternalName: "${TagTypeService.CATEGORY_INTERNAL_NAME}") {
                                items {
                                    name
                                }
                            }
                        }
                        responderIdMember {
                            idmember
                            fullname
                        }
                        counterPartCompany {
                            idcompany
                            name
                            urlname
                            logoUrl(size: "123")
                            nbSellingPoints {
                                name
                            }
                            type
                            ca {
                                name
                            }
                            country {
                                name
                                tagLocation {
                                    code
                                }
                            }
                            categories: tags(tagTypeInternalName: "${TagTypeService.CATEGORY_INTERNAL_NAME}") {
                                items {
                                    name
                                }
                            }
                            activities: tags(tagTypeInternalName: "${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
                                items {
                                    name
                                }
                            }
                            hasPendingVideoMeetingsWithCurrentMember
                        }
                        company {
                            idcompany
                        }
                        counterPartMember {
                            idmember
                            timezone
                        }
                        meeting {
                            id
                            status
                            startDate
                            endDate
                            videoMeetingParticipants {
                                items {
                                    member {
                                        idmember
                                        fullname
                                        logoUrl(size: "123", returnNull: true)
                                        nameInitials
                                        email
                                        jobtitle
                                        company {
                                            idcompany
                                            name
                                            urlname
                                            logoUrl(size: "123")
                                            country {
                                                name
                                                tagLocation {
                                                    code
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `,
        myVideoMeetingsForAvailabilities: gql`
            query myVideoMeetingsForAvailabilities($limit: Int, $offset: Int) {
                myVideoMeetings {
                    items(limit: $limit, offset: $offset) {
                        id
                        provider
                        startDate
                        endDate
                        counterPartCompany {
                            name
                        }
                    }
                }
            }
        `,

        videoMeeting: gql`
            query videoMeeting($id: Int!) {
                videoMeeting(id: $id) {
                    id
                    provider
                    startDate
                    endDate
                    secondsBeforeEnd
                    counterPartCompany {
                        idcompany
                        name
                        urlname
                        logoUrl(size: "123")
                        country {
                            name
                            tagLocation {
                                code
                            }
                        }
                        nbSellingPoints {
                            name
                        }
                        ca {
                            name
                        }
                    }
                    counterPartMember {
                        timezone
                    }
                }
            }
        `,

        currentOrNextVideoMeeting: gql`
            query currentOrNextVideoMeeting {
                me {
                    currentOrNextVideoMeeting {
                        id
                        counterPartCompany {
                            name
                        }
                        startDate
                        endDate
                        secondsBeforeStart
                        secondsBeforeEnd
                    }
                }
            }
        `,

        currentOrNextVideoMeetings: gql`
            query currentOrNextVideoMeetings($limit: Int) {
                me {
                    currentOrNextVideoMeetings {
                        items(limit:$limit) {
                            id
                            startDate
                            counterPartCompany {
                                logoUrl(size: "123")
                                name
                                citylocation {
                                    city
                                    country {
                                        tagLocation {
                                            code
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `,
    };

    static mutations = {
        addVideoMeetingAvailability: gql`
            mutation addVideoMeetingAvailability($startDate: String! , $endDate: String!) {
                addVideoMeetingAvailability(startDate: $startDate , endDate: $endDate) {
                    id
                    startDate
                    endDate
                }
            }
        `,

        editVideoMeetingAvailability: gql`
            mutation editVideoMeetingAvailability($id: Int!, $startDate: String! , $endDate: String!) {
                editVideoMeetingAvailability(id: $id, startDate: $startDate , endDate: $endDate) {
                    id
                    startDate
                    endDate
                }
            }
        `,

        deleteVideoMeetingAvailability: gql`
            mutation deleteVideoMeetingAvailability($id: Int!) {
                deleteVideoMeetingAvailability(id: $id) {
                    id
                }
            }
        `,

        saveVideoMeetingRequest: gql`
            mutation saveVideoMeetingRequest($idsCompanyTarget: [Int!]!, $duration: Int!, $eventCode: String) {
                saveVideoMeetingRequest(idsCompanyTarget: $idsCompanyTarget, duration: $duration, eventCode: $eventCode) {
                    id
                }
            }
        `,

        cancelVideoMeetingRequest: gql`
            mutation cancelVideoMeetingRequest($idVideoMeetingRequest: Int!, $statusReason: String!) {
                cancelVideoMeetingRequest(idVideoMeetingRequest: $idVideoMeetingRequest, statusReason: $statusReason) {
                    id
                    status
                    statusReason
                    counterPartCompany {
                        hasPendingVideoMeetingsWithCurrentMember
                    }
                }
            }
        `,

        proposeNewTimeslotsForVideoMeetingRequest: gql`
            mutation proposeNewTimeslotsForVideoMeetingRequest($idVideoMeetingRequest: Int!) {
                proposeNewTimeslotsForVideoMeetingRequest(idVideoMeetingRequest: $idVideoMeetingRequest) {
                    id
                    status
                }
            }
        `,

        createVideoMeeting: gql`
            mutation createVideoMeeting($idVideoMeetingRequest: Int!, $startDate: String!, $endDate: String!) {
                createVideoMeeting(idVideoMeetingRequest: $idVideoMeetingRequest, startDate: $startDate, endDate: $endDate) {
                    id
                    status
                    startDate
                    endDate
                    videoMeetingParticipants {
                        items {
                            member {
                                idmember
                                fullname
                                logoUrl(size: "123", returnNull: true)
                                nameInitials
                                company {
                                     name
                                }
                            }
                        }
                    }
                }
            }
        `,

        inviteColleagueToVideoMeeting: gql`
            mutation inviteColleagueToVideoMeeting($idVideoMeeting: Int!, $idMember: Int!) {
                inviteColleagueToVideoMeeting(idVideoMeeting: $idVideoMeeting, idMember: $idMember) {
                    member {
                        idmember
                        fullname
                        logoUrl(size: "123", returnNull: true)
                        nameInitials
                        email
                        jobtitle
                        company {
                            idcompany
                            name
                            urlname
                            logoUrl(size: "123")
                            country {
                                name
                                tagLocation {
                                    code
                                }
                            }
                        }
                    }
                }
            }
        `,

        inviteAnonymousColleagueToVideoMeeting: gql`
            mutation inviteAnonymousColleagueToVideoMeeting($idVideoMeeting: Int!, $firstName: String!, $lastName: String!, $email: String!, $jobTitle: String) {
                inviteAnonymousColleagueToVideoMeeting(idVideoMeeting: $idVideoMeeting, firstName: $firstName, lastName: $lastName, email: $email, jobTitle: $jobTitle) {
                    member {
                        idmember
                        fullname
                        logoUrl(size: "123", returnNull: true)
                        nameInitials
                        email
                        jobtitle
                        company {
                            idcompany
                            name
                            urlname
                            logoUrl(size: "123")
                            country {
                                name
                                tagLocation {
                                    code
                                }
                            }
                        }
                    }
                }
            }
        `,

        increaseMeetingJoinNumber: gql`
            mutation increaseMeetingJoinNumber($idVideoMeeting: Int!) {
                increaseMeetingJoinNumber(idVideoMeeting: $idVideoMeeting)
            }
        `,
    };
}
