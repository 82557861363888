import {Injectable} from "@angular/core";
import {WabelService} from "./wabel-service.abstract";
import {GraphQLService} from "./graphql-service";
import {LoadingService} from "./loading.service";
import {ResultIterator} from "../entities/result_iterator";
import {filter, map} from "rxjs/operators";
import {VideoMeetingGraphql} from "../graphql/video_meeting.graphql";
import {VideoMeetingMemberAvailability} from "../entities/video_meeting_member_availability";
import {VideoMeeting} from "../entities/video_meeting";
import {VideoMeetingRequest} from "../entities/video_meeting_request";
import {VideoMeetingParticipant} from "../entities/video_meeting_participant";

@Injectable({
    providedIn: 'root'
})
export class VideoMeetingService extends WabelService {

    constructor(protected graphQLService: GraphQLService, protected loadingService: LoadingService) {
        super(graphQLService, loadingService);
    }

    toObject(object) {
        return new VideoMeeting(object);
    }

    getMyVideoMeetingAvailabilities(onlyFutureAvailabilities = false) {
        return this.query(VideoMeetingGraphql.queries.myVideoMeetingAvailabilities, {
            onlyFutureAvailabilities: onlyFutureAvailabilities
        }, 'network-only')
          .pipe(map((data) => {
            return data.data?.myVideoMeetingAvailabilities?.items ? data.data.myVideoMeetingAvailabilities.items.map(a => new VideoMeetingMemberAvailability(a)) : [];
          }));
    }

    getVideoMeetingAvailabilitiesByMember(idMember: number, onlyFutureAvailabilities = false, onlyAvailabilities4HoursAfterNow = false) {
        return this.query(VideoMeetingGraphql.queries.videoMeetingAvailabilitiesByMember, {
            idMember: idMember,
            onlyFutureAvailabilities: onlyFutureAvailabilities,
            onlyAvailabilities4HoursAfterNow: onlyAvailabilities4HoursAfterNow
        })
          .pipe(map((data) => {
            return data.data?.videoMeetingAvailabilitiesByMember?.items ? data.data.videoMeetingAvailabilitiesByMember.items.map(a => new VideoMeetingMemberAvailability(a)) : [];
          }));
    }

    getMyVideoMeetingRequests(limit: number = null, offset: number = null, participants: string = null) {
        return this.query(VideoMeetingGraphql.queries.myVideoMeetingRequests, {
            limit: limit,
            offset: offset,
            participants: participants
        }).pipe(
            filter(data => data.data !== undefined),
            map(data => data.data.myVideoMeetingRequests ? new ResultIterator<VideoMeetingRequest>(data.data.myVideoMeetingRequests.count, data.data.myVideoMeetingRequests.items.map(vmr => new VideoMeetingRequest(vmr)) as VideoMeetingRequest[]) : null)
        );
    }

    getMyVideoMeetingsForAvailabilities() {
        return this.query(VideoMeetingGraphql.queries.myVideoMeetingsForAvailabilities, {})
          .pipe(map((data) => {
            return data.data?.myVideoMeetings?.items ? data.data.myVideoMeetings.items.map(vm => new VideoMeeting(vm)) : [];
          }));
    }

    addVideoMeetingAvailability(startDate: string, endDate: string) {
        return this.mutation(VideoMeetingGraphql.mutations.addVideoMeetingAvailability, {
            startDate: startDate,
            endDate: endDate
        })
          .pipe(map((data) => {
            return data.data?.addVideoMeetingAvailability ? new VideoMeetingMemberAvailability(data.data.addVideoMeetingAvailability) : null;
          }));
    }

    editVideoMeetingAvailability(id: number, startDate: string, endDate: string) {
        return this.mutation(VideoMeetingGraphql.mutations.editVideoMeetingAvailability, {
            id: id,
            startDate: startDate,
            endDate: endDate
        })
          .pipe(map((data) => {
            return data.data?.editVideoMeetingAvailability ? new VideoMeetingMemberAvailability(data.data.editVideoMeetingAvailability) : null;
          }));
    }

    deleteVideoMeetingAvailability(id: number) {
        return this.mutation(VideoMeetingGraphql.mutations.deleteVideoMeetingAvailability, {
            id: id
        })
          .pipe(map((data) => {
            return data.data?.deleteVideoMeetingAvailability ? new VideoMeetingMemberAvailability(data.data.deleteVideoMeetingAvailability) : null;
          }));
    }

    saveVideoMeetingRequest(idsCompanyTarget: number[], duration: number, eventCode: string = null) {
        return this.mutation(VideoMeetingGraphql.mutations.saveVideoMeetingRequest, {
            idsCompanyTarget: idsCompanyTarget,
            duration: duration,
            eventCode: eventCode
        })
            .pipe(map((data) => {
                return data.data?.saveVideoMeetingRequest ? new VideoMeetingRequest(data.data.saveVideoMeetingRequest) : null;
            }));
    }

    cancelVideoMeetingRequest(idVideoMeetingRequest: number, statusReason: string) {
        return this.mutation(VideoMeetingGraphql.mutations.cancelVideoMeetingRequest, {
            idVideoMeetingRequest: idVideoMeetingRequest,
            statusReason: statusReason
        })
            .pipe(map((data) => {
                return data.data?.cancelVideoMeetingRequest ? new VideoMeetingRequest(data.data.cancelVideoMeetingRequest) : null;
            }));
    }

    proposeNewTimeslotsForVideoMeetingRequest(idVideoMeetingRequest: number) {
        return this.mutation(VideoMeetingGraphql.mutations.proposeNewTimeslotsForVideoMeetingRequest, {
            idVideoMeetingRequest: idVideoMeetingRequest
        })
            .pipe(map((data) => {
                return data.data?.proposeNewTimeslotsForVideoMeetingRequest ? new VideoMeetingRequest(data.data.proposeNewTimeslotsForVideoMeetingRequest) : null;
            }));
    }

    createVideoMeeting(idVideoMeetingRequest: number, startDate: string, endDate: string) {
        return this.mutation(VideoMeetingGraphql.mutations.createVideoMeeting, {
            idVideoMeetingRequest: idVideoMeetingRequest,
            startDate: startDate,
            endDate: endDate
        })
            .pipe(map((data) => {
                return data.data?.createVideoMeeting ? new VideoMeeting(data.data.createVideoMeeting) : null;
            }));
    }

    inviteColleagueToVideoMeeting(idVideoMeeting: number, idMember: number) {
        return this.mutation(VideoMeetingGraphql.mutations.inviteColleagueToVideoMeeting, {
            idVideoMeeting: idVideoMeeting,
            idMember: idMember
        })
            .pipe(map((data) => {
                return data.data?.inviteColleagueToVideoMeeting ? new VideoMeetingParticipant(data.data.inviteColleagueToVideoMeeting) : null;
            }));
    }

    increaseMeetingJoinNumber(idVideoMeeting: number) {
        return this.mutation(VideoMeetingGraphql.mutations.increaseMeetingJoinNumber, {
            idVideoMeeting: idVideoMeeting
        })
            .pipe(map((data) => {
                return data.data?.increaseMeetingJoinNumber;
            }));
    }

    inviteAnonymousColleagueToVideoMeeting(idVideoMeeting: number, firstName: string, lastName: string, email: string, jobTitle: string = null) {
        return this.mutation(VideoMeetingGraphql.mutations.inviteAnonymousColleagueToVideoMeeting, {
            idVideoMeeting: idVideoMeeting,
            firstName: firstName,
            lastName: lastName,
            email: email,
            jobTitle: jobTitle
        })
            .pipe(map((data) => {
                return data.data?.inviteAnonymousColleagueToVideoMeeting ? new VideoMeetingParticipant(data.data.inviteAnonymousColleagueToVideoMeeting) : null;
            }));
    }

    getVideoMeeting(id: number) {
        return this.query(VideoMeetingGraphql.queries.videoMeeting, {
            id: id
        })
            .pipe(map((data) => {
                return data.data?.videoMeeting ? new VideoMeeting(data.data.videoMeeting) : null;
            }));
    }

    getCurrentOrNextVideoMeeting() {
        return this.query(VideoMeetingGraphql.queries.currentOrNextVideoMeeting, {})
            .pipe(map((data) => {
                return data.data?.me?.currentOrNextVideoMeeting ? new VideoMeeting(data.data.me.currentOrNextVideoMeeting) : null;
            }));
    }

    getCurrentOrNextVideoMeetings(limit: number = null) {
        return this.query(VideoMeetingGraphql.queries.currentOrNextVideoMeetings, {limit})
            .pipe(map((data) => {
                return data.data?.me?.currentOrNextVideoMeetings?.items ? data.data?.me?.currentOrNextVideoMeetings?.items.map(vm => new VideoMeeting(vm)) : [];
            }));
    }

    getDateWithTimezone(date, timezone: string): Date {
        return new Date(new Date(date).toLocaleString("en-US", {timeZone: timezone}));
    }
}
