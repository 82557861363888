import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Company} from "../../../../services/wabel-client/entities/company";
import {CompanyService} from "../../../../services/wabel-client/services/company.service";
import {Subscription} from "rxjs";
import {
    PurchasingGroupStoresCountryDetails
} from "../../../../services/wabel-client/entities/purchasing_group_stores_country_details";
import {Banner} from "../../../../services/wabel-client/entities/banner";

@Component({
    selector: 'app-company-geographical-breakdown',
    templateUrl: './company-geographical-breakdown.component.html',
    styleUrls: ['./company-geographical-breakdown.component.scss'],
})
export class CompanyGeographicalBreakdownComponent implements OnInit, OnDestroy {
    @Input() urlname;
    buyingOffices$: Subscription;
    storeDetails: PurchasingGroupStoresCountryDetails[];
    storeDetailsTotal: PurchasingGroupStoresCountryDetails;
    @Output() noGeographicalBreakDown = new EventEmitter<any>();

    constructor(private companyService: CompanyService) {
    }

    ngOnInit() {
        this.buyingOffices$ = this.companyService.getCompanyGeographicalBreakdown(this.urlname).subscribe(
            (company: Company) => {
                this.storeDetailsTotal = null;
                this.storeDetails = [];
                for (let i = 0; i < company.purchasingGroupStoresCountryDetails.length; i++) {
                    if (company.purchasingGroupStoresCountryDetails[i].countryName === 'Total') {
                        this.storeDetailsTotal = company.purchasingGroupStoresCountryDetails[i];
                    } else {
                        this.storeDetails.push(company.purchasingGroupStoresCountryDetails[i]);
                    }
                }
                if (this.storeDetails.length === 0) {
                    this.noGeographicalBreakDown.emit();
                }
            }
        );
    }

    displayBannersInline(companies: Banner[]): string {
        return companies.map(c => c.name).join(', ');
    }

    ngOnDestroy() {
        if (this.buyingOffices$) {
            this.buyingOffices$.unsubscribe();
        }
    }
}
