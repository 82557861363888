import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NdlBannerComponent} from "./banner.component";
import {NdlBannerTitleDirective} from "./banner-title.directive";
import {MatIconModule} from '@angular/material/icon';
import {NdlBannerPrefixDirective} from './banner-prefix.directive';
import {NdlBannerSuffixDirective} from './banner-suffix.directive';
import {MatButtonModule} from '@angular/material/button';
import {NdlBannerIconDirective} from './banner-icon.directive';

@NgModule({
    declarations: [
        NdlBannerComponent,
        NdlBannerTitleDirective,
        NdlBannerPrefixDirective,
        NdlBannerSuffixDirective,
        NdlBannerIconDirective
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule
    ],
    exports: [
        NdlBannerComponent,
        NdlBannerTitleDirective,
        NdlBannerPrefixDirective,
        NdlBannerSuffixDirective,
        NdlBannerIconDirective
    ]
})
export class NeedlBannersModule { }
