import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
interface Option {
    id: string;
    name: string;
}
@Component({
  selector: 'app-single-dropdown',
  templateUrl: './single-dropdown.component.html',
  styleUrls: ['./single-dropdown.component.scss'],
})
export class SingleDropdownComponent implements OnInit {
    @Input() selectedValue: string;
    @Input() options:Option[];
    @Input() label=null;
    @Input() required = false;
    @Output() selectedElementHasChanged: EventEmitter<any> = new EventEmitter();

    constructor() { }

  ngOnInit() {
  }

    onChange(){
      this.selectedElementHasChanged.emit(this.selectedValue);
  }

}
