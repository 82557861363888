import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs'
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class ZoomCallDockService {

    private isDocked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    private toggleDock: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    private _disconnectCall: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

    private showVideoCallIframe: boolean = false;
    private videoMeetingInvitationID: number;

    constructor(private http: HttpClient) {
    }

    public setShowVideoCallIframe(value: boolean): void {
        this.showVideoCallIframe = value;
    }

    public getShowVideoCallIframe(): boolean {
        return this.showVideoCallIframe;
    }

    public setVideoMeetingInvitationID(value: number): void {
        this.videoMeetingInvitationID = value;
    }

    public getVideoMeetingInvitationID(): number {
        return this.videoMeetingInvitationID;
    }

    public setDock(value: boolean): void {
        this.isDocked.next(value);
    }

    public getDock(): Observable<boolean> {
        return this.isDocked;
    }

    public setMaximize(value: boolean): void {
        this.toggleDock.next(value)
    }

    public getMaximize(): Observable<boolean> {
        return this.toggleDock;
    }

    public setDisconnect(value: boolean): void {
        this._disconnectCall.next(value);
    }

    public getDisconnect(): Observable<boolean> {
        return this._disconnectCall;
    }

    getZoomVideoMeeting(idvideomeetinginvitation: number) {
        return this.http.post(environment.needl_back_end_url + '/get-zoom-video-meeting', {
            idvideomeetinginvitation
        }, {
            withCredentials: true,
            headers: {
                'ngsw-bypass': 'true'
            }
        });
    }


}
