import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../../services/auth.service";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'email-verified',
    templateUrl: './email-verified.component.html',
    styleUrls: ['./email-verified.component.scss']
})
export class EmailVerifiedComponent implements OnInit {

    status: number = 1;
    errorCode: string = 'error';

    constructor(private route: ActivatedRoute, public authService: AuthService) {
        this.route.params.subscribe(params => {
            if (params.hasOwnProperty('status')) {
                let s = +params['status'];
                this.status = s >= 1 ? 1 : 0;
            }
        });
        this.route.queryParams.subscribe(params => {
            if (params.hasOwnProperty('error')) {
                this.errorCode = params['error'];
            }
        });
    }

    ngOnInit() {
    }

}
