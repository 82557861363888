import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CompanyBusinessInfoComponent} from './company-business-info.component';
import {NeedlPlaceholdersModule} from 'needl-lib';


@NgModule({
    declarations: [
        CompanyBusinessInfoComponent
    ],
    exports: [
        CompanyBusinessInfoComponent
    ],
    imports: [
        CommonModule,
        NeedlPlaceholdersModule
    ]
})
export class CompanyBusinessInfoModule {
}
