import {
    Component, ViewChild,
    Input, TemplateRef, ContentChild, Inject, forwardRef, SkipSelf, ViewContainerRef, Optional
} from '@angular/core';
import {MatStep, MatStepper, StepState} from '@angular/material/stepper';
import {FormGroup} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {STEPPER_GLOBAL_OPTIONS, StepperOptions} from '@angular/cdk/stepper';
import {NdlStepperComponent} from '../stepper/stepper.component';

@Component({
    selector: 'ndl-step',
    templateUrl: './step.component.html',
    providers: [
        {provide: MatStep, useExisting: NdlStepComponent},
    ],
})
export class NdlStepComponent extends MatStep {
    /** Main title of the step */
    @Input() title: string;

    /** Main subtitle of the step */
    @Input() subtitle: string;

    /** Main title of the step */
    @Input() id: string;

    /** Step number */
    index: number;

    @ViewChild(TemplateRef, {static: true}) public content: TemplateRef<any>;

    constructor(@Inject(forwardRef(() => NdlStepperComponent)) stepper: NdlStepperComponent,
                @SkipSelf() private errorStateMatcher: ErrorStateMatcher,
                private viewContainerRef: ViewContainerRef,
                @Optional() @Inject(STEPPER_GLOBAL_OPTIONS) stepperOptions?: StepperOptions) {
        super(stepper, errorStateMatcher, viewContainerRef, stepperOptions);
    }
}
