import {WabelEntity} from "./wabel-entity.abstract";
import {Tag} from "./tag";

export class Suggestion extends WabelEntity {

    constructor(obj: any) {
        super(obj);
        if (obj) {
            this.category = obj.category ? new Tag(obj.category) : null;
        }
    }

    idsuggestion: number;
    name: string;
    category: Tag;
    dateCreated: string;
    depth: number;

    get id(): number {
        return +this.idsuggestion;
    }

    set id(id: number) {
        this.idsuggestion = id;
    }
}
