import {WabelEntity} from "./wabel-entity.abstract";
import {Company} from "./company";
import {SubscriptionPack} from './subscription_pack';

export class CompanyMembership extends WabelEntity {
    subscriptionPack: SubscriptionPack;

    idsubscription: number;
    company: Company;
    startDate: Date;
    endDate: Date;
    status: string;
    resetDate: Date;

    constructor(obj: any) {
        super(obj);
        this.startDate = obj.startDate ? new Date(obj.startDate) : null;
        this.endDate = obj.endDate ? new Date(obj.endDate) : null;
        this.subscriptionPack = obj.subscriptionpack ? new SubscriptionPack(obj.subscriptionpack) : null;
        // this.company = obj.company ? new Company(obj.company)  : null;
        if (this.status == 'On' && this.startDate > new Date()) {
            this.status = 'Not started';
        }
    }

    get id(): number {
        return +this.idsubscription;
    }

    set id(id: number) {
        this.idsubscription = id;
    }
}
