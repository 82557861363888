import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'kit-form-ckeditor',
    templateUrl: './kit-form-ckeditor.component.html',
    styleUrls: ['./kit-form-ckeditor.component.scss']
})
export class KitFormCkeditorComponent implements OnInit, AfterViewInit {

    @Input() id: string;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() helper: string;
    @Input() showError: boolean = false;
    @Input() errorMessage: string = 'This field is required';
    @Input() readonly: boolean = false;
    loading: boolean = true;

    @Input() binding: string;
    @Output() bindingChange = new EventEmitter();
    @Input() isRequired: boolean = false;

    ckeditorConfig = {
        toolbar: [
            {name: 'styles', items: ['Bold', 'Italic', 'Underline', 'RemoveFormat']},
            {name: 'list', items: ['BulletedList']},
            {name: 'links', items: ['Link', 'Unlink']}
        ],
        removePlugins: 'elementspath',
        contentsCss: ['@font-face {font-family: "Nunito"; src: url( /assetsFiles/fonts/Nunito/Nunito-Regular.ttf ) format("truetype");} body {font: 400 15px Nunito;}']
    };

    constructor() {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.loading = false;
        }, 500);
    }

    onChange(newValue) {
        this.binding = newValue;
        this.bindingChange.emit(newValue);
    }

    onFocusOut(event) {
        if (this.isRequired && (!this.binding || this.binding === '')) {
            this.showError = true;
        } else {
            this.showError = false;
        }
    }
}
