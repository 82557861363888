import { EventParticipation } from './event_participation';
import {WabelEntity} from './wabel-entity.abstract';
import {Summit} from './summit';
import {Event} from './event';
import {Member} from "./member";

export class EventRegistration extends WabelEntity {
    constructor(obj: any) {
        super(obj);
        if (obj) {
            if (obj.summits) this.summits = obj.summits.map(summit => new Summit(summit));
            if (obj.registration) this.participation = obj.registration;
            this.member = obj.member ? new Member(obj.member) : null;
            this.meetingsNumber = obj.meetingsNumber;
            this.idcertaineventregistration = obj.idcertaineventregistration;
        }
    }

    event: Event;
    summits: Summit[];
    meetingsNumber: number;
    idcertaineventregistration: number;
    badgeUrl: string;
    participation: EventParticipation;
    member: Member;

    get id(): number {
        return this.idcertaineventregistration;
    }
}
