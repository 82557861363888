import {WabelEntity} from "./wabel-entity.abstract";
import { Tag } from "./tag";

export class BusinessMappingTag extends WabelEntity {
    constructor(obj: any) {
        super(obj);
        if (obj) {
            this.tag = obj.tag ? new Tag(obj.tag) : null;
        }
    }

    tag: Tag;
    type: string;

    get id() {
        return null;
    }
}
